import Risk_TranslationDTO from './risk_TranslationDTO';
import { RiskMethod } from 'models/risk';
import ControlDTO from './controlDTO';
import IdListDTO from './IdListDTO';
import ResourceLinkDTO from './resourceLinkDTO';

export default class RiskDTO {
  riskId: number;

  authSchemaId?: number;
  
  riskStateId: number;

  code: string;

  commentTrailId: number;

  auditTrailId: number;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  sortOrder: number;

  chanceStart: number;

  chanceCurrent: number;

  chanceGoal: number;

  impactStart: number;

  impactCurrent: number;

  impactGoal: number;

  analysisTaskid?: number;

  method: RiskMethod;

  latestChangeDateTime?: string;

  tagIds?: IdListDTO;

  trans: Risk_TranslationDTO[];

  controls?: ControlDTO[];

  resourceLinks?: ResourceLinkDTO[];

  classificationGroupIds?: IdListDTO;

  assetIds?: IdListDTO;

  normIds?: IdListDTO;

  constructor() {
    this.riskId = -1;
    this.riskStateId = -1;
    this.code = '';
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.sortOrder = -1;
    this.chanceStart = 0;
    this.chanceCurrent = 0;
    this.chanceGoal = 0;
    this.impactStart = 0;
    this.impactCurrent = 0;
    this.impactGoal = 0;
    this.trans = [];
    this.method = RiskMethod.Mitigate;
  }
}

export class RiskControlDTO {
  riskId: number;

  controlId: number;

  constructor() {
    this.riskId = -1;
    this.controlId = -1;
  }
}

export class RiskThemeDTO {
  riskId: number;

  themeId: number;

  constructor() {
    this.riskId = -1;
    this.themeId = -1;
  }
}

export class RiskProcessDTO {
  riskId: number;

  processId: number;

  constructor(_riskId: number = -1, _processId: number = -1) {
    this.riskId = _riskId;
    this.processId = _processId;
  }
}
