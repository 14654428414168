import {
  Image,
  IImageProps,
  Stack,
  Text,
  ImageFit,
  PrimaryButton,
  Link,
  IconButton,
  IContextualMenuProps,
  ContextualMenuItemType,
} from '@fluentui/react';
import AppContext, { IAppContext } from 'App/AppContext';
import { ISingleTag, SingleTag } from 'components/Tags/SingleTag';
import { globalFontBoldWeight, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import { StoreMgmtStates } from 'models/adminTenant';
import Package, {
  PackageCategories,
  PackageLogos,
  PackageStates,
  PackageTenantStates,
  PurchaseModes,
} from 'models/package';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { apiDownloadPackageLogo } from 'services/Api/packageService';
import { apiRequest } from 'services/Auth/authConfig';
import { toCurrency } from 'utils/string';
import { navigateToExternalUrl } from 'utils/url';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface IStoreItemProps {
  pack: Package;
  invokeBuyButton: (pack: Package) => void;
  invokeInstallTestPackageButton: (pack: Package) => void;
  invokeReleasePackageButton: (pack: Package) => void;
  invokeRejectPackageButton: (pack: Package) => void;
  invokeWithdrawPackageButton: (pack: Package) => void;
  invokeArchivePackageButton: (pack: Package) => void;
  invokeActivity?: (pack: Package) => void;
}

export const GetPackageCategoryColor = (id: number | undefined, darkMode: boolean): string => {
  switch (id) {
    case PackageCategories.Starter:
      return darkMode ? '#5e8265' : '#b6fcc5';
    case PackageCategories.AddOn:
      return darkMode ? '#7e855f' : '#f0fcb6';
    case PackageCategories.Service:
      return darkMode ? '#d95959' : '#fc9292';
  }

  return '';
};

export const getPackagePriceElement = (pack: Package, appContext: IAppContext, t: TFunction): JSX.Element => {
  if (pack.isPreview) {
    const tag: ISingleTag = {
      tagId: 'preview',
      tagName: t('store:TabStore.StoreItem.Preview'),
      tagColor: appContext.useDarkMode ? '#5e8265' : '#b6fcc5',
    };

    return <SingleTag compact tag={tag} maxWidth={120} />;
  } else {
    return <Text variant="medium">{GetPackagePriceText(pack, t)}</Text>;
  }
};

const GetPackagePriceText = (pack: Package, t: TFunction<string[]>): string => {
  let textPrice = '';

  if (pack.categoryId === PackageCategories.Service) {
    if (pack.price === 0) return '';
    textPrice = t('store:TabStore.StoreItem.FromPrice') + ' ';
    textPrice += toCurrency(pack.price);
  } else {
    if (pack.price === 0) return t('store:TabStore.StoreItem.Free');
    if (pack.purchaseMode === PurchaseModes.Order) {
      textPrice = t('store:TabStore.StoreItem.FromPrice') + ' ';
    }
    textPrice += toCurrency(pack.price);
  }

  return textPrice;
};

export const getBuyButtonText = (pack: Package, t: TFunction<string[]>): string => {
  if (pack.categoryId === PackageCategories.Service) {
    return pack.webURLName ?? t('package:Fields.WebURLName.ServiceDefault');
  } else {
    if (pack.tenants && pack.tenants.length > 0) {
      //customer has started the purchase process, show the next action
      const tenant = pack.tenants[0];
      switch (tenant.state) {
        case PackageTenantStates.Ordered:
          return t('store:TabStore.StoreItem.TenantState.WaitForPayment');
        case PackageTenantStates.Paid:
          return t('store:TabStore.StoreItem.TenantState.ReadyToActivate');
        case PackageTenantStates.Activated:
          return t('store:TabStore.StoreItem.TenantState.Activated');
        case PackageTenantStates.Installing:
        case PackageTenantStates.ContentUpload:
          return t('store:TabStore.StoreItem.TenantState.Installing');
      }
    }

    //customer has not yet started the purchase process
    if (pack.price === 0) {
      return t('store:TabStore.StoreItem.FreeButton');
    } else {
      switch (pack.purchaseMode) {
        case PurchaseModes.Buy:
          return t('store:TabStore.StoreItem.BuyButton');
        case PurchaseModes.Order:
          return t('store:TabStore.StoreItem.OrderButton');
        default:
          return '';
      }
    }
  }
};

const StoreItem = (props: IStoreItemProps) => {
  const { t } = useTranslation(['store', 'translation', 'package']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [packageLogo, setPackageLogo] = useState<string | undefined>(undefined);
  const [packageLogoType, setPackageLogoType] = useState<PackageLogos>(PackageLogos.SmallLight);

  useEffect(() => {
    getPackageLogo(appContext.useDarkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.useDarkMode]);

  const getImageProps = (): IImageProps => {
    return {
      src: packageLogo,
      imageFit: ImageFit.contain,
      width: 235,
      height: 50,
    };
  };

  const getPackageLogo = async (darkmode: boolean): Promise<void> => {
    try {
      if (isLoading) return;
      const logoType = Package.getLogoType(darkmode, false);
      if (!packageLogo || logoType !== packageLogoType) {
        const blob = await downloadPackageLogo(props.pack.packageId, logoType);
        const defaultLogo = Package.getDefaultLogo(logoType);
        const url = Package.getLogoImageSrc(blob, defaultLogo);
        setPackageLogoType(logoType);
        setPackageLogo(url);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const downloadPackageLogo = async (packageId: number, logoType: PackageLogos): Promise<Blob | undefined> => {
    try {
      if (isLoading || !props.pack) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const blob = await apiDownloadPackageLogo(packageId, logoType, accessToken);

      return blob;
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const invokeBuyButton = () => {
    props.invokeBuyButton(props.pack);
  };

  const invokeInstallTestPackageButton = () => {
    props.invokeInstallTestPackageButton(props.pack);
  };

  const invokeReleasePackageButton = () => {
    props.invokeReleasePackageButton(props.pack);
  };

  const invokeRejectPackageButton = () => {
    props.invokeRejectPackageButton(props.pack);
  };

  const invokeArchivePackageButton = () => {
    props.invokeArchivePackageButton(props.pack);
  };

  const invokeWithdrawPackageButton = () => {
    props.invokeWithdrawPackageButton(props.pack);
  };

  const invokeActivity = (pack: Package) => {
    if (props.invokeActivity) {
      props.invokeActivity(pack);
    }
  };

  const getManagerMenuItems = (pack: Package): IContextualMenuProps => {
    if (pack.categoryId === PackageCategories.Service) {
      switch (pack.state) {
        case PackageStates.Review:
          return {
            items: [
              {
                key: 'release',
                text: 'Release',
                onClick: () => invokeReleasePackageButton(),
              },
              {
                key: 'reject',
                text: 'Reject',
                onClick: () => invokeRejectPackageButton(),
              },
              {
                key: 'div',
                itemType: ContextualMenuItemType.Divider,
              },
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
        case PackageStates.ContentUpload:
        case PackageStates.Draft:
        case PackageStates.Archived:
        case PackageStates.Rejected:
          return {
            items: [
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
        case PackageStates.Live:
          return {
            items: [
              {
                key: 'archive',
                text: 'Archive',
                onClick: () => invokeArchivePackageButton(),
              },
              {
                key: 'withdraw',
                text: 'Withdraw',
                onClick: () => invokeWithdrawPackageButton(),
              },
              {
                key: 'div',
                itemType: ContextualMenuItemType.Divider,
              },
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
      }
    } else {
      switch (pack.state) {
        case PackageStates.Review:
          return {
            items: [
              {
                key: 'testinstall',
                text: 'Install',
                onClick: () => invokeInstallTestPackageButton(),
              },
              {
                key: 'release',
                text: 'Release',
                onClick: () => invokeReleasePackageButton(),
              },
              {
                key: 'reject',
                text: 'Reject',
                onClick: () => invokeRejectPackageButton(),
              },
              {
                key: 'div',
                itemType: ContextualMenuItemType.Divider,
              },
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
        case PackageStates.ContentUpload:
        case PackageStates.Draft:
        case PackageStates.Archived:
        case PackageStates.Rejected:
          return {
            items: [
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
        case PackageStates.Live:
          return {
            items: [
              {
                key: 'testinstall',
                text: 'Install',
                onClick: () => invokeInstallTestPackageButton(),
              },
              {
                key: 'archive',
                text: 'Archive',
                onClick: () => invokeArchivePackageButton(),
              },
              {
                key: 'withdraw',
                text: 'Withdraw',
                onClick: () => invokeWithdrawPackageButton(),
              },
              {
                key: 'div',
                itemType: ContextualMenuItemType.Divider,
              },
              {
                key: 'activity',
                text: 'Activity',
                onClick: () => invokeActivity(pack),
              },
            ],
          };
      }
    }
  };

  //
  // Main render
  //
  return (
    <Stack
      verticalFill
      tokens={globalStackTokensGapSmall}
      styles={{ root: { overflowY: 'scroll', overflowX: 'hidden' } }}
    >
      <Stack.Item>
        <Image {...getImageProps()} />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item grow>
            <Text styles={{ root: { fontWeight: globalFontBoldWeight, wordBreak: 'break-word' } }} variant="large">
              {props.pack.name +
                (appContext.user.login.storeMgmtState === StoreMgmtStates.Manager
                  ? ' (' + props.pack.version + ')'
                  : '')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <SingleTag
              compact
              tag={{
                tagId: props.pack.primaryLanguage ?? '',
                tagName: props.pack.primaryLanguage?.toUpperCase(),
                tagColor: appContext.useDarkMode ? '#5e5f82' : '#b6b8fc',
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {props.pack.categoryId !== undefined && (
          <SingleTag
            compact
            tag={{
              tagId: props.pack.categoryId ?? 0,
              tagName: Package.GetPackageCategoryText(props.pack.categoryId, t as TFunction<string[]>),
              tagColor: GetPackageCategoryColor(props.pack.categoryId, appContext.useDarkMode),
            }}
          />
        )}
        {props.pack.norm && (
          <SingleTag
            compact
            tag={{
              tagId: props.pack.norm.normId,
              tagName: props.pack.norm.name,
              tagColor: appContext.useDarkMode ? '#596e7d' : '#b6dffc',
            }}
          />
        )}
      </Stack.Item>
      <Stack.Item>
        <Text styles={{ root: { fontWeight: globalFontBoldWeight, wordBreak: 'break-word' } }} variant="medium">
          {props.pack.ownerName}
        </Text>
      </Stack.Item>
      <Stack.Item grow styles={{ root: { fontWeight: globalFontBoldWeight, wordBreak: 'break-word' } }}>
        <Text variant="small">{props.pack.shortDescription}</Text>
      </Stack.Item>
      {props.pack.categoryId !== PackageCategories.Service && props.pack.webURL && (
        <Stack.Item>
          <Link underline onClick={() => navigateToExternalUrl(props.pack.webURL || '', '', '')}>
            {props.pack.webURLName}
          </Link>
        </Stack.Item>
      )}
      <Stack.Item>{getPackagePriceElement(props.pack, appContext, t)}</Stack.Item>
      <Stack.Item>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <PrimaryButton
              className="redlab-usetiful-store-buy"
              onClick={() => invokeBuyButton()}
              disabled={
                props.pack.state !== PackageStates.Live ||
                appContext.user.login.storeMgmtState === StoreMgmtStates.Manager ||
                !hasUserFeatureGenericManager(appContext)
              }
            >
              {getBuyButtonText(props.pack, t as TFunction<string[]>)}
            </PrimaryButton>
          </Stack.Item>
          {appContext.user.login.storeMgmtState === StoreMgmtStates.Manager && (
            <Stack.Item>
              <Text styles={globalTextStylesBold}>
                {Package.GetPackageStateText(props.pack.state, t as TFunction<string[]>)}
              </Text>
            </Stack.Item>
          )}
          {appContext.user.login.storeMgmtState === StoreMgmtStates.Manager && (
            <Stack.Item>
              <IconButton menuProps={getManagerMenuItems(props.pack)} />
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default StoreItem;
