export default class AssigneeDTO {
  id?: string;

  name?: string;

  email?: string;

  type?: 'user' | 'group';

  constructor(_id?: string, _name?: string, _email?: string, _type?: 'user' | 'group') {
    this.id = _id;
    this.name = _name;
    this.email = _email;
    this.type = _type;
  }
}
