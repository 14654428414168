import Language from './language';

export default class Theme_Translation {
  themeId: number;

  languageId: number;

  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  outOfScopeReason?: string;

  lang?: Language;

  constructor() {
    this.themeId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
