import { newGuidNil } from 'utils/guid';
import ResourceLinkDTO from '../resourceLinkDTO';

export default class KPIDataTransformDTO {
  kpiId: number;

  rawEvidence?: string;

  evidence?: string;

  evidenceComment?: string;

  linkId?: number;

  templateId?: number;

  lastEventId?: number;

  arrayPath?: string;

  selectedPath?: string;

  ignoredPaths?: string[];

  extractedPath?: string;

  created: string;

  createdBy: string;

  createdById: string;

  link?: ResourceLinkDTO;

  constructor() {
    this.kpiId = 0;
    this.created = '';
    this.createdBy = '';
    this.createdById = newGuidNil();
  }
}
