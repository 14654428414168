import { IconButton, Label, Stack, TooltipHost, Text, Persona, PersonaSize } from '@fluentui/react';
import AppContext from 'App/AppContext';
import AuthSchemaPicker from 'components/Pickers/AuthSchemaPicker';
import UserPicker from 'components/Pickers/UserPicker';
import { hasTaskUpdatePermission, canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall } from 'globalStyles';
import Task from 'models/tasks/task';
import User from 'models/user';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGetAuthSchemaLines } from 'services/Api/authService';
import { apiRequest } from 'services/Auth/authConfig';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';

export interface ITaskAssignmentProps {
  orgTask: Task;
  task: Task;
  disabled?: boolean;
  canUpdate: boolean | undefined;
  isReadonlyPermissions?: boolean;
  isReadonlyAssignee?: boolean;
  onUpdate: (newTask: Task) => void;
  getErrorMessage?: (field: string) => string | undefined;
}

export const TaskAssignment = (props: ITaskAssignmentProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);
  const [authSchemaId, setAuthSchemaId] = useState<number | undefined>(undefined);
  const [authSchemaUsersLoading, setAuthSchemaUsersLoading] = useState<boolean>(false);
  const [authSchemaUsers, setAuthSchemaUsers] = useState<User[]>([]);
  const [canUpdate] = useState<boolean>(props.canUpdate ?? hasTaskUpdatePermission(props.orgTask, appContext));
  const isReadonlyPermissions =
    props.disabled ||
    props.isReadonlyPermissions ||
    !canUpdateTaskField(props.orgTask, canUpdate, TaskFieldTypes.Permissions, appContext);
  const isReadonlyAssignee =
    props.disabled ||
    props.isReadonlyAssignee ||
    !canUpdateTaskField(props.orgTask, canUpdate, TaskFieldTypes.Assignee, appContext);

  //
  // Effects
  //
  useEffect(() => {
    if (props.task.authSchemaId !== authSchemaId) {
      setAuthSchemaId(props.task.authSchemaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  useEffect(() => {
    getAuthSchemaUsers(authSchemaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSchemaId]);

  //
  // Load
  //
  const getAuthSchemaUsers = async (authSchemaId: number | undefined): Promise<User[]> => {
    if (authSchemaId) {
      let usersInSchema: string[] = [];
      try {
        setAuthSchemaUsersLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const lines = await apiGetAuthSchemaLines(authSchemaId, accessToken);
        usersInSchema = lines.map((l) => l.userId) as string[];
      } catch (err) {
        appContext.setError(err);
        usersInSchema = [];
      } finally {
        setAuthSchemaUsersLoading(false);
      }

      if (props.task.userId && !usersInSchema.includes(props.task.userId)) {
        //it can be the case that the assignee of the task has been removed from the auth schema
        //to show the assignee, add it to the allowed list for the user picker
        usersInSchema.push(props.task.userId);
      }

      const users = appContext.globalDataCache.users.getItemsForId(usersInSchema);
      setAuthSchemaUsers(users);

      return users;
    } else {
      setAuthSchemaUsers([]);
    }

    return [];
  };

  const getSchemaPicker = () => {
    if (!hasUserFeature(appContext, FeatureTypes.RBAC)) return null;
    if (isReadonlyPermissions && isReadonlyAssignee) {
      return (
        <Stack.Item
          styles={!isReadonlyAssignee ? { root: { width: '50%', paddingBottom: 10 } } : { root: { width: '50%' } }}
        >
          <Label>{t('translation:BulkActionsModal.Permissions.LabelSet')}</Label>
          <Text>
            {appContext.globalDataCache.authSchemas.get(props.task.authSchemaId).name ||
              t('adminAuth:AuthSchemaPicker.Placeholder')}
          </Text>
        </Stack.Item>
      );
    } else {
      return (
        <Stack.Item grow={1}>
          <AuthSchemaPicker
            label={t('translation:BulkActionsModal.Permissions.LabelSet')}
            selectedAuthSchemaId={authSchemaId}
            onSelect={(authSchema) => {
              const copyTask = props.task.clone();
              copyTask.authSchemaId = authSchema?.authSchemaId;
              props.onUpdate(copyTask);
            }}
            hideMembersOnHover={true}
            disabled={isReadonlyPermissions}
            showForUserId={props.task.userId}
            isOwner={props.task.ownerId === appContext.user.id}
            overrideAdmin={!isReadonlyPermissions} //not only admin can set auth schemas for tasks
          />
        </Stack.Item>
      );
    }
  };

  const getAssignToMe = () => {
    return (
      <Stack.Item>
        <TooltipHost content={t('task:Assignment.AssignMeInfo')}>
          <IconButton
            iconProps={{ iconName: 'UserFollowed' }}
            text=""
            onClick={() => {
              const copyTask = props.task.clone();
              copyTask.userId = appContext.user.id;
              if (copyTask.authSchemaId) {
                if (!appContext.globalDataCache.authSchemas.get(copyTask.authSchemaId).hasLine(copyTask.userId)) {
                  copyTask.authSchemaId = undefined;
                }
              }
              props.onUpdate(copyTask);
            }}
            disabled={authSchemaUsersLoading || appContext.user.id === props.task.userId}
          />
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAssigneePicker = () => {
    if (isReadonlyAssignee) {
      return (
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="end">
          <Stack.Item>
            <Label>{t('task:Assignment.Label')}</Label>
            <Persona
              size={PersonaSize.size24}
              text={appContext.globalDataCache.users.get(props.task.userId).name ?? '-'}
            />
          </Stack.Item>
          {getAssignToMe()}
        </Stack>
      );
    } else {
      return (
        <Fragment>
          <Stack.Item grow={2}>
            <Stack verticalFill>
              <Label>{t('task:Assignment.Label')}</Label>
              <UserPicker
                users={authSchemaUsers}
                disabled={authSchemaUsersLoading}
                selectedItemId={props.task.userId}
                onSelect={(item: User | undefined) => {
                  const copyTask = props.task.clone();
                  copyTask.userId = undefined;
                  copyTask.user = undefined;
                  if (item) {
                    const user = item;
                    copyTask.userId = user.id;
                    copyTask.user = user;
                  }
                  props.onUpdate(copyTask);
                }}
              />
              {props.getErrorMessage && (
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('assignment')}
                </Text>
              )}
            </Stack>
          </Stack.Item>
          {getAssignToMe()}
        </Fragment>
      );
    }
  };

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      verticalAlign={isReadonlyAssignee && isReadonlyPermissions ? 'start' : 'end'}
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapSmall}
    >
      {getSchemaPicker()}
      {getAssigneePicker()}
    </Stack>
  );
};
