import { IAppContext } from "App/AppContext";

export enum LocalStorageKeys {
  NotSet = '',
  UserName = 'REDLAB-Preferred-Username',
  DarkMode = 'REDLAB-DarkMode',
  DarkModeOutlook = 'REDLAB-DarkModeOutlook',
  LocalAccount = 'REDLAB-Local-Account',
  TaskViewType = 'REDLAB-Task-ViewType',
  SharepointOrg = 'REDLAB-SP-Org',
  ShowAllLinks = 'REDLAB-Link-ShowAll',
  QuickFilterAllTasks = 'REDLAB-QuickFilter-AllTasks',
  QuickFilterMyRequests = 'REDLAB-QuickFilter-MyRequests',
  QuickFilterMyTasks = 'REDLAB-QuickFilter-MyTasks',
  QuickFilterMonitoringTasks = 'REDLAB-QuickFilter-MonitoringTasks',
  QuickFilterRisks = 'REDLAB-QuickFilter-Risks',
  QuickFilterTaskContext = 'REDLAB-QuickFilterTaskContext',
  TeamsQuickFilterOnlyMyTasks = 'REDLAB-TeamsQuickFilter-OnlyMyTasks',
  DashboardSortOrderForMe = 'REDLAB-DashboardSortOrderForMe',
  DashboardSortOrderForMyTeams = 'REDLAB-DashboardSortOrderForMyTeams',
  ControlTreeListCache = 'REDLAB-ControlTreeListCache',
  NormTreeListCache = 'REDLAB-NormTreeListCache',
  ThemeTreeListCache = 'REDLAB-ThemeTreeListCache',
  ObjectiveTreeListCache = 'ObjectiveTreeListCache',
  ProcessTreeListCache = 'ProcessTreeListCache',
  AuditYear = 'REDLAB-AuditYear',
  MyTasksListSort = 'REDLAB-MyTasksListSort',
  MyRequestListSort = 'REDLAB-MyRequestListSort',
  AllTasksListSort = 'REDLAB-AllTasksListSort',
  RiskTaskListSort = 'REDLAB-RiskTaskListSort',
  ControlTaskListSort = 'REDLAB-ControlTaskListSort',
  ThemeTaskListSort = 'REDLAB-ThemeTaskListSort',
  TaskScheduleSort = 'REDLAB-TaskScheduleSort',
  ProcessTaskListSort = 'REDLAB-ProcessTaskListSort',
  ObjectiveTaskListSort = 'REDLAB-ObjectiveTaskListSort',
  SceneFilterTasks = 'REDLAB-SceneFilterTasks',
  SceneFilterSchedule = 'REDLAB-SceneFilterSchedule',
  ScheduleLevel = 'REDLAB-PlanningLevel',
  MyControlsListSort = 'REDLAB-MyControlsListSort',
  MyNormsListSort = 'REDLAB-MyNormsListSort',
  MyObjectivesListSort = 'REDLAB-MyObjectivesListSort',
  MyProcessesListSort = 'REDLAB-MyProcessesListSort',
  MyThemesListSort = 'REDLAB-MyThemesListSort',
  MyRiskListSort = 'REDLAB-RiskListSort',
  SceneFilterOrganization = 'REDLAB-SceneFilterOrganization',
  SceneFilterControl = 'REDLAB-SceneFilterControl',
  SceneFilterTheme = 'REDLAB-SceneFilterTheme',
  SceneFilterRisk = 'REDLAB-SceneFilterRisk',
  SceneFilterNorm = 'REDLAB-SceneFilterNorm',
  SceneFilterLibrary = 'REDLAB-SceneFilterLibrary',
  ShowResourcePanel = 'REDLAB-ShowResourcePanel',
  MainNavCollapsed = 'REDLAB-MainNavCollapsed',
  StandardElementViewMode = 'REDLAB-StandardElementViewMode',
  StandardElementShowInstructions = 'REDLAB-StandardElementShowInstructions',
  StandardElementShowBackground = 'REDLAB-StandardElementShowBackground',
  SceneFilterSentApproval = 'REDLAB-SceneFilterSentApproval',
  AssetViewMode = 'REDLAB-AssetViewMode',
  SceneFilterAsset = 'REDLAB-SceneFilterAsset',
  MyAssetListSort = 'REDLAB-MyAssetListSort',
  QuickFilterAssets = 'REDLAB-QuickFilterAssets',
  AssetTreeListCache = 'REDLAB-AssetTreeListCache',
  RiskSectionsExpanded = 'REDLAB-RiskSectionsExpanded',
  MyKPIListSort = 'REDLAB-MyKPIListSort',
  QuickFilterKPIs = 'REDLAB-QuickFilterKPIs',
  KPIPeriodSelector = 'REDLAB-KPIPeriodSelector',
  KPIAggregationSelector = 'REDLAB-KPIAggregationSelector',
  KPIAggregationIntervalSelector = 'REDLAB-KPIGroupingSelector',
  KPIChartSelector = 'REDLAB-KPIChartSelector',
  RiskColumns = 'REDLAB-RiskColumns',
  AssetColumns = 'REDLAB-AssetColumns',
  QuickFilterObjectives = 'REDLAB-QuickFilterObjectives',
  QuickFilterProcesses = 'REDLAB-QuickFilterProcesses',
  QuickFilterControls = 'REDLAB-QuickFilterControls',
  QuickFilterThemes = 'REDLAB-QuickFilterThemes',
  QuickFilterStandards = 'REDLAB-QuickFilterStandards',
  QuickFilterGeneric  = 'REDLAB-QuickFilterGeneric',
  TeamsAppKBOrgUnitMap = 'REDLAB-TeamsAppKBOrgUnitMap',
  TeamsAppMyTasksOrgUnitMap = 'REDLAB-TeamsAppMyTasksOrgUnitMap',
  LibraryColumns = 'REDLAB-LibraryColumns',
  MyNormsTabAuditStandardSelection = 'REDLAB-MyNormsTabAuditStandardSelection',
  MyNormsTabAuditAccordionImplementation = 'REDLAB-MyNormsTabAuditAccordionImplementation',
  MyNormsTabAuditAccordionContent = 'REDLAB-MyNormsTabAuditAccordionContent',
  ProcessColumns = 'REDLAB-ProcessColumns',
  ObjectiveColumns = 'REDLAB-ObjectiveColumns',
  ControlColumns = 'REDLAB-ControlColumns',
  ThemeColumns = 'REDLAB-ThemeColumns',
  NormColumns = 'REDLAB-NormColumns',
  MyNormsAnalyticsControlSort = 'REDLAB-MyNormsAnalyticsControlSort',
  FormsSort = 'REDLAB-FormsSort',
  GroupListSort = 'REDLAB-GroupListSort',
  AuthSchemaListSort = 'REDLAB-AuthSchemaListSort',
  TaskColumns = 'REDLAB-TaskColumns',
  TaskPeriodSelector = 'REDLAB-TaskPeriodSelector',
  MemberFilterTasks = 'REDLAB-MemberFilterTasks',
  AssetTaskListSort = 'REDLAB-AssetTaskListSort',
  FilterTasksObjective = 'REDLAB-FilterTasksObjective',
  FilterTasksProcess = 'REDLAB-FilterTasksProcess',
  FilterTasksAsset = 'REDLAB-FilterTasksAsset',
  FilterTasksControl = 'REDLAB-FilterTasksControl',
  FilterTasksTheme = 'REDLAB-FilterTasksTheme',
  FilterTasksRisk = 'REDLAB-FilterTasksRisk',
  QuickFilterForms = 'REDLAB-QuickFilterForms',
  RiskPeriodSelector = 'REDLAB-RiskPeriodSelector',
  TaskCompactView = 'REDLAB-TaskCompactView'
}

function getLocalStorageName(appContext: IAppContext | undefined, name: LocalStorageKeys): string {
  let storageName: string = name;
  if (appContext) {
    storageName += `-${appContext.user.tenant.id}`;
  }
  
  return storageName;
}

export function setLocalStorageData(appContext: IAppContext | undefined, name: LocalStorageKeys, value: string) {
  try {
    if (name === LocalStorageKeys.NotSet) return;
    const storageName = getLocalStorageName(appContext, name);
    window.localStorage.setItem(storageName, value);
  } catch (err) {
    // no-op
  }
}

export function getLocalStorageData(appContext: IAppContext | undefined, name: LocalStorageKeys): string | undefined {
  try {
    if (name === LocalStorageKeys.NotSet) return;

    const storageName = getLocalStorageName(appContext, name);
    let val = window.localStorage.getItem(storageName);
    if (val === null && storageName !== name) {
      val = window.localStorage.getItem(name);
    }

    if (!val) return undefined;

    return val;
  } catch (err) {
    return undefined;
  }
}

export function removeLocalStorageData(appContext: IAppContext | undefined, name: LocalStorageKeys) {
  try {
    if (name === LocalStorageKeys.NotSet) return;

    const storageName = getLocalStorageName(appContext, name);
    window.localStorage.removeItem(name);
    window.localStorage.removeItem(storageName);
  } catch (err) {
    // no-op
  }
}

export function removeAllLocalStorageData() {
  try {
    window.localStorage.clear();
  } catch (err) {
    // no-op
  }
}
