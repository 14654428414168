import KPI, { KPIAggregationMethods, KPIChartTypes } from 'models/kpi/kpi';
import {
  AreaChart,
  IChartProps,
  ILineChartPoints,
  ILineDataInVerticalBarChart,
  IVerticalBarChartDataPoint,
  IVerticalStackedChartProps,
  LineChart,
  VerticalBarChart,
  VerticalStackedBarChart,
} from '@fluentui/react-charting';
import { KPIGraphDataPoint, KPIGraphDataStackedPoint } from 'models/kpi/kpiGraphData';
import { toLocaleDateShort } from 'utils/datetime';
import { Spinner, SpinnerSize, Stack, getTheme, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface IKPIDataViewProps {
  kpi: KPI | undefined;
  aggregationMethod?: KPIAggregationMethods;
  chartType: KPIChartTypes;
  isLoading: boolean;
}

const KPIDataView = (props: IKPIDataViewProps) => {
  const { t } = useTranslation(['translation', 'kpi']);
  const theme = getTheme();
  const defChartColor = theme.palette.themePrimary;
  const defAvgLineColor = '#e8af33';

  const getDataVerticalBarChart = (data: KPIGraphDataPoint[] | undefined): IVerticalBarChartDataPoint[] => {
    if (!data || data.length === 0) return [];

    let lineData: ILineDataInVerticalBarChart | undefined = undefined;
    if (props.aggregationMethod === KPIAggregationMethods.Count) {
      const average = data.reduce((p, c) => p + (c.value ?? 0), 0) / data.length;
      lineData = { y: average };
    }

    return data.map((d) => {
      const item: IVerticalBarChartDataPoint = {
        x: toLocaleDateShort(d.date),
        y: d.value || 0,
        lineData: lineData,
      };

      return item;
    });
  };

  const getDataLineChartChart = (data: KPIGraphDataPoint[] | undefined): IChartProps => {
    if (!data || data.length === 0) return { lineChartData: [] };

    const points: ILineChartPoints[] = [
      {
        data: data.map((d) => {
          return {
            x: d.date || 0,
            y: d.value || 0,
          };
        }),
        legend: '',
        color: defChartColor,
      },
    ];

    return { lineChartData: points };
  };

  const getSumOfValues = (values: { [key: string]: number }): number => {
    let sum: number = 0;

    Object.keys(values).forEach((v) => {
      sum += values[v];
    });

    return sum;
  };

  const getDataStackedBarChart = (data: KPIGraphDataStackedPoint[] | undefined): IVerticalStackedChartProps[] => {
    if (!data || data.length === 0) return [];

    const average = data.reduce((p, c) => p + getSumOfValues(c.values), 0) / data.length;

    const output: IVerticalStackedChartProps[] = data.map((d) => {
      const point: IVerticalStackedChartProps = {
        chartData: Object.keys(d.values).map((v) => {
          return {
            legend: v,
            data: d.values[v],
          };
        }),
        lineData:
          props.aggregationMethod === KPIAggregationMethods.Count
            ? Object.keys(d.values).map((v) => {
                return {
                  y: average,
                  legend: '',
                  color: defAvgLineColor,
                };
              })
            : undefined,
        xAxisPoint: toLocaleDateShort(d.date) || 0,
      };

      return point;
    });

    return output;
  };

  const NoDataView = (): JSX.Element => {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('kpi:TabData.NoData')}</Text>
      </Stack>
    );
  }

  //
  // Main render
  //
  if (props.isLoading || !props.kpi) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  switch (props.chartType) {
    case KPIChartTypes.Bar:
      if (props.aggregationMethod === KPIAggregationMethods.Count && props.kpi.isStackedType()) {
        const stackedData = getDataStackedBarChart(props.kpi.graphData?.stackedPoints);
        if (stackedData?.length === 0) return NoDataView();

        return <VerticalStackedBarChart data={stackedData} hideLegend={true} />;
      } else {
        const barData = getDataVerticalBarChart(props.kpi.graphData?.points);
        if (barData?.length === 0) return NoDataView();

        return <VerticalBarChart data={barData} wrapXAxisLables={true} hideLegend={true} />;
      }
    case KPIChartTypes.Line:
      const lineData = getDataLineChartChart(props.kpi.graphData?.points);
      if (lineData.lineChartData?.length === 0) return NoDataView();

      return <LineChart data={lineData} wrapXAxisLables={true} hideLegend={true} />;
    case KPIChartTypes.Area:
      const areaData = getDataLineChartChart(props.kpi.graphData?.points);
      if (areaData.lineChartData?.length === 0) return NoDataView();

      return <AreaChart data={areaData} wrapXAxisLables={true} hideLegend={true} />;
    default:
      return null;
  }
};

export default KPIDataView;
