import { Stack, FontIcon } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapExtraSmall, globalStackTokensGapMedium, userIcon } from 'globalStyles';
import { useContext, useState } from 'react';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import { ITaskAssignmentProps, TaskAssignment } from './TaskAssignment';
import { canUpdateTaskField, hasTaskUpdatePermission, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';

interface ITaskDetailAssignmentProps extends ITaskAssignmentProps {}

export const TaskDetailAssignment = (props: ITaskDetailAssignmentProps) => {
  const appContext = useContext(AppContext);
  const [canUpdate] = useState<boolean>(props.canUpdate ?? hasTaskUpdatePermission(props.orgTask, appContext));
  const isReadonlyPermissions =
    props.disabled || !canUpdateTaskField(props.orgTask, canUpdate, TaskFieldTypes.Permissions, appContext);
  const isReadonlyAssignee =
    props.disabled || !canUpdateTaskField(props.orgTask, canUpdate, TaskFieldTypes.Assignee, appContext);

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonlyPermissions && isReadonlyAssignee ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
    >
      <Stack.Item>
        {!appContext.isMobileView && <FontIcon iconName={userIcon.iconName} style={iconFontSize} />}
      </Stack.Item>
      <Stack.Item grow>
        <TaskAssignment
          orgTask={props.orgTask}
          task={props.task}
          canUpdate={canUpdate}
          isReadonlyPermissions={isReadonlyPermissions}
          isReadonlyAssignee={isReadonlyAssignee}
          onUpdate={props.onUpdate}
          getErrorMessage={props.getErrorMessage}
        />
      </Stack.Item>
    </Stack>
  );
};
