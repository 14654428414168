import TaskTypeFormElementDTO from './taskTypeFormElementDTO';
import { TaskType_TranslationDTO } from './taskType_TranslationDTO';

export default class TaskTypeDTO {
  taskTypeId: number;

  authSchemaId?: number;

  systemTaskType?: number;

  isSystem: boolean;

  trans: TaskType_TranslationDTO[];

  transIdx: number;

  name: string;

  description?: string;

  form?: TaskTypeFormElementDTO[];

  constructor() {
    this.taskTypeId = 0;
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.isSystem = false;
  }
}
