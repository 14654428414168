import { i18nBase } from 'services/Localization/i18n';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Entity, { EntityTypes } from 'models/entity';
import { toLocaleDateTimeMedium } from 'utils/datetime';

export enum ActivityType {
  NotSet = 0,
  RecentlyChanged = 1,
  RecentlyViewed = 2, //future
  RecentlyClosed = 3,
  LibraryNewVersion = 4,
  OverDeadline = 5,
  Assigned = 6,
  Refused = 7,
  LibraryReadRequest = 8, //future
  LibraryReadResponse = 9,
  RefusedInstance = 10,
  Upcoming = 11,
  LibraryNewVersionOwner = 12,
  LinkedControlUpdated = 13,
}

export enum ActivityPriority {
  Informational = 0,
  Low = 1,
  Normal = 2,
  High = 3,
  Critical = 4,
}

export default class Activity {
  activityId: number;

  entity: Entity;

  typeOfActivity: ActivityType;

  created: Date;

  refDateTime?: Date;

  priority: ActivityPriority;

  userId: string;

  refUserId?: string;

  userEmail?: string;

  refUserEmail?: string;

  dismissed?: Date;

  refData?: string;

  primaryText?: string;

  secondaryText?: string;

  tooltipContent?: JSX.Element;

  constructor() {
    this.activityId = 0;
    this.entity = new Entity();
    this.typeOfActivity = ActivityType.NotSet;
    this.created = new Date();
    this.refDateTime = new Date();
    this.priority = ActivityPriority.Informational;
    this.userId = '';
  }

  getUserName(globalDataCache: GlobalDataCache): string {
    const user = globalDataCache.users.items.find((u) => u.id === this.userId);
    if (user === undefined) {
      return this.userEmail ? this.userEmail : '?';
    }

    return user.name;
  }

  getRefUserName(globalDataCache: GlobalDataCache): string {
    if (!this.refUserId) return '';
    const user = globalDataCache.users.items.find((u) => u.id === this.refUserId);
    if (user === undefined) {
      return this.refUserEmail ? this.refUserEmail : '?';
    }

    return user.name;
  }

  getTransParams(globalDataCache: GlobalDataCache) {
    return {
      userName: this.getUserName(globalDataCache),
      refUser: this.getRefUserName(globalDataCache),
      entityName: this.entity.entityName,
      created: toLocaleDateTimeMedium(this.created),
      refDate: toLocaleDateTimeMedium(this.refDateTime),
    };
  }

  getPrimaryText(globalDataCache: GlobalDataCache): string {
    switch (this.entity.typeOfEntity) {
      case EntityTypes.Task:
        switch (this.typeOfActivity) {
          case ActivityType.Assigned:
            return i18nBase.t('activity:Task.Assigned.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Task.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.RecentlyClosed:
            return i18nBase.t('activity:Task.RecentlyClosed.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.OverDeadline:
            return i18nBase.t('activity:Task.OverDeadline.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.Refused:
            return i18nBase.t('activity:Task.Refused.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.RefusedInstance:
            return i18nBase.t('activity:Task.RefusedInstance.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.Upcoming:
            return i18nBase.t('activity:Task.Upcoming.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Link:
        switch (this.typeOfActivity) {
          case ActivityType.LibraryNewVersionOwner:
            return i18nBase.t('activity:Link.LibraryNewVersionOwner.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Control:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Control.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          case ActivityType.LinkedControlUpdated:
            return i18nBase.t(
              'activity:Control.LinkedControlUpdated.PrimaryText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Requirement:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Requirement.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Risk:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Risk.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Process:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Process.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Objective:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Objective.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.KPI:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:KPI.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      case EntityTypes.Asset:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Asset.RecentlyChanged.PrimaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.PrimaryText.NotSet');
        }
      default:
        return i18nBase.t('activity:Activity.PrimaryText.NotSet');
    }
  }

  getActivityTabText(globalDataCache: GlobalDataCache): string {
    switch (this.entity.typeOfEntity) {
      case EntityTypes.Task:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Task.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          case ActivityType.RecentlyClosed:
            return i18nBase.t('activity:Task.RecentlyClosed.ActivityTabText', this.getTransParams(globalDataCache));
          case ActivityType.OverDeadline:
            return i18nBase.t('activity:Task.OverDeadline.ActivityTabText', this.getTransParams(globalDataCache));
          case ActivityType.Refused:
            return i18nBase.t('activity:Task.Refused.ActivityTabText', this.getTransParams(globalDataCache));
          case ActivityType.RefusedInstance:
            return i18nBase.t('activity:Task.RefusedInstance.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Control:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Control.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Requirement:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t(
              'activity:Requirement.RecentlyChanged.ActivityTabText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Risk:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Risk.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Process:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Process.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Objective:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t(
              'activity:Objective.RecentlyChanged.ActivityTabText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.KPI:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:KPI.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      case EntityTypes.Asset:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Asset.RecentlyChanged.ActivityTabText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
        }
      default:
        return i18nBase.t('activity:Activity.ActivityTabText.NotSet');
    }
  }

  getSecondaryText(globalDataCache: GlobalDataCache): string {
    switch (this.entity.typeOfEntity) {
      case EntityTypes.Task:
        switch (this.typeOfActivity) {
          case ActivityType.Assigned:
            return i18nBase.t('activity:Task.Assigned.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Task.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.RecentlyClosed:
            return i18nBase.t('activity:Task.RecentlyClosed.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.OverDeadline:
            return i18nBase.t('activity:Task.OverDeadline.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.Refused:
            return i18nBase.t('activity:Task.Refused.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.RefusedInstance:
            return i18nBase.t('activity:Task.RefusedInstance.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.Upcoming:
            return i18nBase.t('activity:Task.Upcoming.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Risk:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Risk.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Objective:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Objective.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Asset:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Asset.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Requirement:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t(
              'activity:Requirement.RecentlyChanged.SecondaryText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Link:
        switch (this.typeOfActivity) {
          case ActivityType.LibraryNewVersionOwner:
            return i18nBase.t(
              'activity:Link.LibraryNewVersionOwner.SecondaryText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Control:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Control.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          case ActivityType.LinkedControlUpdated:
            return i18nBase.t(
              'activity:Control.LinkedControlUpdated.SecondaryText',
              this.getTransParams(globalDataCache),
            );
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.Process:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:Process.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      case EntityTypes.KPI:
        switch (this.typeOfActivity) {
          case ActivityType.RecentlyChanged:
            return i18nBase.t('activity:KPI.RecentlyChanged.SecondaryText', this.getTransParams(globalDataCache));
          default:
            return i18nBase.t('activity:Activity.SecondaryText.NotSet');
        }
      default:
        return i18nBase.t('activity:Activity.SecondaryText.NotSet');
    }
  }
}
