import { TFunction } from 'i18next';

export const getProgressLabelInstallMaxSteps = 15;

export const getProgressLabelInstall = (progress: number | undefined, t: TFunction<string[]>): string => {
  let label = '';
  switch (progress) {
    case 1:
      label = t('store:TabStore.Install.Stages.Stage1');
      break;
    case 2:
      label = t('store:TabStore.Install.Stages.Stage2');
      break;
    case 3:
      label = t('store:TabStore.Install.Stages.Stage3');
      break;
    case 4:
      label = t('store:TabStore.Install.Stages.Stage4');
      break;
    case 5:
      label = t('store:TabStore.Install.Stages.Stage5');
      break;
    case 6:
      label = t('store:TabStore.Install.Stages.Stage6');
      break;
    case 7:
      label = t('store:TabStore.Install.Stages.Stage7');
      break;
    case 8:
      label = t('store:TabStore.Install.Stages.Stage8');
      break;
    case 9:
      label = t('store:TabStore.Install.Stages.Stage9');
      break;
    case 10:
      label = t('store:TabStore.Install.Stages.Stage10');
      break;
    case 11:
      label = t('store:TabStore.Install.Stages.Stage11');
      break;
    case 12:
      label = t('store:TabStore.Install.Stages.Stage12');
      break;
    case 13:
      label = t('store:TabStore.Install.Stages.Stage13');
      break;
    case 14:
      label = t('store:TabStore.Install.Stages.Stage14');
      break;
    case 15:
      label = t('store:TabStore.Install.Stages.Stage15');
      break;
    default:
      return '';
  }

  const percent = Math.floor((100 / getProgressLabelInstallMaxSteps) * (progress || 0));

  return label + ' (' + percent.toString() + '%)';
};
