export default class KPIDataTransformResponse {
  accepted: boolean;

  errorCode?: string;

  errorMsg?: string;

  resultNumber?: number;

  resultText?: string;

  resultEvidence?: string;

  constructor() {
    this.accepted = false;
  }
}
