import { Checkbox, FontIcon, Label, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import Task from 'models/tasks/task';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import { globalStackTokensGapExtraSmall, globalStackTokensGapMedium } from 'globalStyles';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';

interface ITaskDetailStatusActiveProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (newTask: Task) => void;
  checkOnly: boolean;
}

export const TaskDetailStatusActive = (props: ITaskDetailStatusActiveProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Status, appContext);

  const getCheck = () => {
    return (
      <Stack.Item>
        <Label>{t('task:Status.Label')}</Label>
        <Checkbox
          label={t('task:Status.Active')}
          checked={props.task.taskStateId !== props.task.getCompletedState()}
          onChange={(ev, checked) => {
            const newTask = props.task.clone();
            if (checked === true) {
              newTask.taskStateId = newTask.getFirstState();
            } else {
              newTask.taskStateId = newTask.getCompletedState();
            }
            props.onUpdate(newTask);
          }}
          disabled={isReadonly}
        />
      </Stack.Item>
    );
  };

  //
  // Main render
  //
  if (props.checkOnly) {
    return getCheck();
  } else {
    return (
      <Stack
        horizontal
        tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
        verticalAlign="start"
        styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
      >
        <Stack.Item>
          {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
        </Stack.Item>
        {getCheck()}
      </Stack>
    );
  }
};
