import http from './httpService';
import AppError from 'utils/appError';
import { AzureSubscription } from 'models/azure/azureSubscription';
import { AzureSubscriptionDTO } from 'models/dto/azure/azureSubscriptionDTO';
import { mapFromAzureSubscriptions } from 'mappings/azureMapping';

export async function apiGetAzureSubscriptions(accessToken: string): Promise<AzureSubscription[]> {
  try {
    const ar = await http.get<AzureSubscriptionDTO[]>(`azure/subscriptions`, http.getRequestConfig(accessToken));

    return mapFromAzureSubscriptions(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
