export const isAuthUserError = (error: Error): boolean => {
  //these user errors can be ignored
  if (!error.message || error.message.length <= 0) {
    return false;
  }

  return error.message.indexOf('user_cancelled') > -1 || error.message.indexOf('interaction_in_progress') > -1;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPopupWindowError = (error: any): boolean => {
  if (!error || !error.message) {
    return false;
  }

  return error.message.indexOf('popup_window_error') > -1 || error.message.indexOf('block_iframe_reload') > -1;
};

export const isAuthInteractionError = (error: Error): boolean => {
  //these errors indicate that user interaction is required
  if (!error.message || error.message.length <= 0) {
    return false;
  }

  return (
    error.message.indexOf('interaction_required') > -1 ||
    error.message.indexOf('consent_required') > -1 ||
    error.message.indexOf('login_required') > -1 ||
    error.message.indexOf('no_account_in_silent_request') > -1 ||
    error.message.indexOf('invalid_grant') > -1 ||
    error.message.indexOf('block_iframe_reload') > -1 ||
    error.message.indexOf('monitor_window_timeout') > -1 ||
    error.message.indexOf('endpoints_resolution_error') > -1
  );
};
