import { FunctionComponent, useContext } from 'react';
import {
  DetailsList,
  Text,
  IColumn,
  SelectionMode,
  Stack,
  Spinner,
  SpinnerSize,
  DetailsListLayoutMode,
  IconButton,
  Label,
  ActionButton,
  IContextualMenuItem,
  StackItem,
  DefaultPalette,
} from '@fluentui/react';
import React from 'react';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { apiGetLink, apiGetLinks } from 'services/Api/linkService';
import { apiRequest, graphSharepointLibraryRequest, graphSharepointPagesRequest } from 'services/Auth/authConfig';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import LibraryPicker from 'components/Pickers/LibraryPicker';
import ResourceList, { ResourceListType } from 'models/resourceList';
import { useTranslation } from 'react-i18next';
import { deleteIcon, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { GroupedVerticalBarChart, IGroupedVerticalBarChartData } from '@fluentui/react-charting';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { apiGetWidgetDataLibraryReadResponse } from 'services/Api/dashboardService';
import { Widget61DTO } from 'models/dto/widgets/widget61DTO';
import { apiGetActivities } from 'services/Api/activityService';
import Activity, { ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';
import { graphGetSitePageList } from 'services/Graph/graphServicePage';
import { apiUpdateList } from 'services/Api/listService';
import { ApprovalVersion } from 'models/approval';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import AppError from 'utils/appError';
import { sortOnString } from 'utils/sorting';

export class WidgetLibraryReadResponseConfig {
  linkId: number;

  constructor() {
    this.linkId = 0;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.linkId = newRawConfig.linkId ?? 0;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetLibraryReadResponseConfig {
    const newConfig = new WidgetLibraryReadResponseConfig();
    newConfig.linkId = this.linkId;

    return newConfig;
  }
}

interface IWidgetLibraryReadResponseProps extends IWidgetRendererProps {}

const WidgetLibraryReadResponse: FunctionComponent<IWidgetLibraryReadResponseProps> = (
  props: IWidgetLibraryReadResponseProps,
) => {
  const { t } = useTranslation(['translation', 'widgets', 'kb']);
  const appContext = React.useContext(AppContext);
  const [link, setLink] = React.useState<ResourceLink | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [approvalVersions, setApprovalVersions] = React.useState<ApprovalVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = React.useState<ApprovalVersion | undefined>(undefined);
  const [data, setData] = React.useState<Widget61DTO | undefined>(undefined);
  const [err, setErr] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (link) {
      loadDataVersion(link, selectedVersion?.activity?.activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion, link]);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetLibraryReadResponseConfig => {
    let newConfig = new WidgetLibraryReadResponseConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      setErr(undefined);
      const config = loadConfig();

      if (config && config.linkId) {
        //get link
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const link = await apiGetLink(config.linkId, accessToken, appContext.globalDataCache);
        if (link) {
          //get versions
          let graphInterfacePages = undefined;
          let graphInterfaceDocuments = undefined;

          if (link.list.listType === ResourceListType.DocumentLibrary) {
            graphInterfaceDocuments = await appContext.getGraphInterface(
              graphSharepointLibraryRequest.scopes,
              undefined,
            );
          } else {
            graphInterfacePages = await appContext.getGraphInterface(graphSharepointPagesRequest.scopes, undefined);
          }

          //get and set the list id of the page library when not set yet
          if (link.list.spSiteId && !link.list.spListId) {
            const pageList = await graphGetSitePageList(graphInterfacePages.client, link.list.spSiteId);
            link.list.spListId = pageList?.id;
            if (link.list.spListId) {
              const accessToken = await appContext.getAccessToken(apiRequest.scopes);
              await apiUpdateList(accessToken, link.list);
            }
          }

          await link.setMetaData(graphInterfacePages, graphInterfaceDocuments, appContext);

          //get activities for approvals
          const newVersionActivities = await apiGetActivities(
            ActivityType.LibraryNewVersion,
            EntityTypes.Link,
            link.linkId,
            accessToken,
          );

          //create match between versions and approvals
          let approvalVersions = ApprovalVersion.getApprovalVersions(link.versions, newVersionActivities);
          approvalVersions = approvalVersions.filter((a) => a.activity !== undefined);
          setApprovalVersions(approvalVersions);

          //init with latest version
          if (approvalVersions.length > 0) {
            setSelectedVersion(approvalVersions[0]);
          }
        }

        setLink(link);
      }
    } catch (err) {
      const appErr = AppError.fromGraphError(err);
      const graphErr = getGraphSharepointErrorMsg(appErr, t);
      if (graphErr) {
        setErr(graphErr);
      } else {
        appContext.setError(appErr);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadDataVersion = async (link: ResourceLink, activityId: number | undefined) => {
    try {
      if (!link) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const data = await apiGetWidgetDataLibraryReadResponse(link, activityId, accessToken);
      setData(data);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = (): IGroupedVerticalBarChartData[] => {
    const dataPoints: IGroupedVerticalBarChartData[] = [];
    if (!data || !data.groups) return dataPoints;

    if (data.groupCountTotal) {
      //add org group
      const item: IGroupedVerticalBarChartData = {
        name: t('kb:TeamView.OrgGroup'),
        series: [],
      };

      const licensedUsers = appContext.globalDataCache.users.items.filter(u => u.hasLicense);

      if (selectedVersion && data.groupCountVersion) {
        const percentageVersion = (data.groupCountVersion[0] / licensedUsers.length) * 100;
        item.series.push({
          key: 'o0',
          data: percentageVersion || 1,
          xAxisCalloutData: t('widgets:LibraryReadResponse.Version', { version: selectedVersion.version }),
          yAxisCalloutData: `${percentageVersion.toFixed(2)}%`,
          color: DefaultPalette.orangeLighter,
          legend: selectedVersion.version,
        });
      }

      const percentage = (data.groupCountTotal[0] / licensedUsers.length) * 100;
      item.series.push({
        key: 'o1',
        data: percentage,
        xAxisCalloutData: t('widgets:LibraryReadResponse.VersionAll'),
        yAxisCalloutData: `${percentage.toFixed(2)}%`,
        color: DefaultPalette.blue,
        legend: t('widgets:LibraryReadResponse.VersionAll'),
      });

      dataPoints.push(item);

      //add teams
      for (let idx = 0; idx < data.groups.length; idx++) {
        const group = appContext.globalDataCache.groups.get(data.groups[idx]);
        const item: IGroupedVerticalBarChartData = {
          name: group.name,
          series: [],
        };

        const groupMembers = data.groupMembers?.find((g) => g.groupId === group.id);
        if (groupMembers && groupMembers.members && data.groupCountTotal) {
          //add version series
          if (selectedVersion && data.groupCountVersion) {
            const percentageVersion = (data.groupCountVersion[idx] / groupMembers.members.length) * 100;
            item.series.push({
              key: 'o0',
              data: percentageVersion || 1,
              xAxisCalloutData: t('widgets:LibraryReadResponse.Version', { version: selectedVersion.version }),
              yAxisCalloutData: `${percentageVersion.toFixed(2)}%`,
              color: DefaultPalette.orangeLighter,
              legend: selectedVersion.version,
            });
          }

          //add allversion series
          const percentage = (data.groupCountTotal[idx] / groupMembers.members.length) * 100;
          item.series.push({
            key: 'o1',
            data: percentage,
            xAxisCalloutData: t('widgets:LibraryReadResponse.VersionAll'),
            yAxisCalloutData: `${percentage.toFixed(2)}%`,
            color: DefaultPalette.blue,
            legend: t('widgets:LibraryReadResponse.VersionAll'),
          });

          dataPoints.push(item);
        }
      }
    }

    return dataPoints;
  };

  const getVersionOptions = (): IContextualMenuItem[] => {
    if (!link) return [];

    return approvalVersions.map((v) => {
      return {
        key: (v.activity as Activity).activityId.toString(),
        text: `${v.version} (${toLocaleDateTimeMedium(v.date)})`,
        data: v,
        onClick: (ev, option) => {
          setSelectedVersion(option?.data);
        },
      };
    });
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (err) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{err}</Text>
      </Stack>
    );
  }

  if (!link) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:LibraryReadResponse.Config.Invalid')}</Text>
      </Stack>
    );
  }

  if (!data?.groups || data.groups.length === 0) {
    //there are no teams in the KB for this link
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:LibraryReadResponse.NoTeams')}</Text>
      </Stack>
    );
  }

  const points = getData();
  const hasData = points.length > 0;

  return (
    <Stack verticalFill>
      <Stack.Item>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
          <Stack.Item>
            <Text styles={globalTextStylesBold}>{link.linkName}</Text>
          </Stack.Item>
          {approvalVersions.length > 0 && (
            <StackItem>
              <ActionButton
                text={t('widgets:LibraryReadResponse.Version', { version: selectedVersion?.version })}
                menuProps={{
                  items: getVersionOptions(),
                }}
                styles={{ root: { height: 16 } }}
              />
            </StackItem>
          )}
        </Stack>
      </Stack.Item>
      {!hasData && (
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          <Text>{t('widgets:LibraryReadResponse.NoData')}</Text>
        </Stack>
      )}
      {hasData && (
        <Stack.Item grow>
          <GroupedVerticalBarChart height={220} width={280} data={points} wrapXAxisLables={true} hideLegend={true} />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default WidgetLibraryReadResponse;

//
// Config
//

interface IWidgetConfigLibraryReadResponseProps extends IWidgetConfigRendererProps {}

export const WidgetConfigLibraryReadResponse: FunctionComponent<IWidgetConfigLibraryReadResponseProps> = (
  props: IWidgetConfigLibraryReadResponseProps,
) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [links, setLinks] = React.useState<ResourceLink[]>([]);
  const [lists, setLists] = React.useState<ResourceList[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedLink, setSelectedLink] = React.useState<ResourceLink | undefined>(undefined);
  const [newLinks, setNewLinks] = React.useState<ResourceLink[]>([]);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetLibraryReadResponseConfig => {
    let newConfig = new WidgetLibraryReadResponseConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      await appContext.globalDataCache.lists.getItems(); //make sure the lists are loaded
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let links = await apiGetLinks(false, accessToken, appContext.globalDataCache);
      let lists = await appContext.globalDataCache.lists.getItems();

      //sort lists on name
      lists = lists
        .filter(
          (l) => l.listType === ResourceListType.DocumentLibrary || l.listType === ResourceListType.SitePageLibrary,
        )
        .sort((a: ResourceList, b: ResourceList) => {
          return sortOnString(a.name, b.name);
        });

      //sort on list name to divide in groups, within the group on link name
      links = links
        .filter(
          (l) =>
            l.list.listType === ResourceListType.DocumentLibrary ||
            l.list.listType === ResourceListType.SitePageLibrary,
        )
        .sort((a: ResourceLink, b: ResourceLink) => {
          if (a.listId !== b.listId) return a.list.name.localeCompare(b.list.name);

          return sortOnString(a.linkName, b.linkName);
        });

      setLists(lists);

      //now set the current config
      const config = loadConfig();
      const selectedLink = links.find((l) => config.linkId === l.linkId);
      setSelectedLink(selectedLink);

      //and filter out the current config from the main list
      setLinks(links.filter((l) => selectedLink?.linkId !== l.linkId));
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: IColumn[] = [
    {
      isMultiline: true,
      key: 'linkName',
      name: '',
      minWidth: 100,
      maxWidth: 600,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{item.linkName}</Text>;
      },
    },
    {
      key: 'delete',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item || !hasUserFeatureGenericManager(appContext)) return;

        return (
          <IconButton
            disabled={isLoading}
            iconProps={deleteIcon}
            onClick={() => {
              if (!item) return;
              onRemove(item);
            }}
            styles={{ root: { height: 18 } }}
          />
        );
      },
    },
  ];

  const onRemove = (item: ResourceLink) => {
    setSelectedLink(undefined);
    const newLinks = [...links, item];
    setLinks(newLinks);
  };

  const getConfig = (item: ResourceLink): WidgetLibraryReadResponseConfig => {
    const config = new WidgetLibraryReadResponseConfig();
    config.linkId = item.linkId;

    return config;
  };

  React.useEffect(() => {
    if (selectedLink) {
      const config = getConfig(selectedLink);
      props.onUpdateConfig(JSON.stringify(config), selectedLink !== undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLink]);

  React.useEffect(() => {
    if (newLinks && newLinks.length > 0) {
      const link = newLinks[0];
      setSelectedLink(link);
      const filteredLinks = links.filter((l) => link.linkId !== l.linkId);
      setLinks(filteredLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinks]);

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Label>
          {selectedLink
            ? t('widgets:LibraryReadResponse.Config.SelectedLinksLabel')
            : t('widgets:LibraryReadResponse.Config.SelectedLinksLabelEmpty')}
        </Label>
      </Stack.Item>
      {selectedLink && (
        <Stack.Item>
          <DetailsList
            columns={columns}
            compact
            layoutMode={DetailsListLayoutMode.justified}
            items={[selectedLink]}
            selectionMode={SelectionMode.none}
            isHeaderVisible={false}
          />
        </Stack.Item>
      )}
      {!selectedLink && (
        <Stack.Item grow>
          <LibraryPicker
            multiSelect={false}
            onSelect={setNewLinks}
            links={links}
            lists={lists}
            isLoading={isLoading}
            showHeader={false}
            disallowVirtual={true}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
