import {
  DefaultButton,
  Dropdown,
  FontIcon,
  IButtonStyles,
  IDropdownStyles,
  IFontStyles,
  IStackStyles,
  ITextStyles,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import RecurringPattern, { absoluteYearCycle, relativeYearCycle } from 'models/recurringPattern';
import { RecurringYearSelector } from './RecurringYearSelector';
import { globalStackTokensGapExtraSmall } from 'globalStyles';

export enum RecurringPatternSummaryStyle {
  text = 1,
  interactive = 2,
  icon = 3,
  edit = 4,
  button = 5,
}

interface IRecurringSummaryProps {
  pattern: RecurringPattern;
  onChange?: (year: number, checked: boolean) => void;
  summaryStyle: RecurringPatternSummaryStyle;
  variant?: keyof IFontStyles;
  stackStyles?: IStackStyles;
  textStyles?: ITextStyles;
  buttonStyles?: IButtonStyles;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const RecurringSummary = (props: IRecurringSummaryProps) => {
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: '80px' },
  };

  //
  // Render
  //
  if (props.summaryStyle === RecurringPatternSummaryStyle.text) {
    return (
      <Text styles={props.textStyles} variant={props.variant}>
        {props.pattern.getSummary()}
      </Text>
    );
  }

  if (props.summaryStyle === RecurringPatternSummaryStyle.icon) {
    return (
      <TooltipHost content={props.pattern.getSummary()}>
        <FontIcon iconName="Sync" />
      </TooltipHost>
    );
  }

  if (props.summaryStyle === RecurringPatternSummaryStyle.edit) {
    return (
      <Dropdown
        label={props.label}
        styles={dropdownStyles}
        calloutProps={{ calloutMaxHeight: 250 }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        options={[
          {
            key: 'summary',
            text: props.pattern ? props.pattern.getSummary() : '',
          },
        ]}
        selectedKey={props.pattern?.isActive === true ? 'summary' : undefined}
        onRenderCaretDown={() => {
          return <FontIcon iconName="More" />;
        }}
        onClick={props.onClick}
      />
    );
  }

  if (props.summaryStyle === RecurringPatternSummaryStyle.button) {
    return (
      <DefaultButton disabled={props.disabled} styles={props.buttonStyles} onClick={props.onClick}>
        {props.pattern.getShortSummary()}
      </DefaultButton>
    );
  }

  if (props.summaryStyle === RecurringPatternSummaryStyle.interactive) {
    if (props.pattern.repeatInterval === absoluteYearCycle || props.pattern.repeatInterval === relativeYearCycle) {
      return (
        <Stack styles={props.stackStyles} tokens={globalStackTokensGapExtraSmall}>
          <Stack.Item>
            <Text variant={props.variant}>{props.pattern.getShortSummary()}</Text>
          </Stack.Item>
          <Stack.Item>
            <RecurringYearSelector disabled={props.disabled} pattern={props.pattern} onChange={props.onChange} />
          </Stack.Item>
        </Stack>
      );
    }
  }

  return null;
};
