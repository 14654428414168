import StringValue from 'models/stringValue/stringValue';
import { ObjectiveDTO } from '../Objective/objectiveDTO';
import { Classification_TranslationDTO } from './classification_TranslationDTO';
import StringValueDTO from '../stringValue/stringValueDTO';

export default class ClassificationDTO {
  classificationId: number;

  value: number;

  color: string;

  group: StringValueDTO;

  inherited: boolean;

  trans: Classification_TranslationDTO[];

  objectives?: ObjectiveDTO[];

  constructor() {
    this.classificationId = 0;
    this.value = 0;
    this.group = new StringValue();
    this.trans = [];
    this.color = '#98cef5';
    this.inherited = false;
  }
}
