export class TeamsChannel {
  channelId: string;

  name: string;

  constructor() {
    this.channelId = '';
    this.name = '';
  }
}
