import { LayerHost, mergeStyles } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { useContext } from 'react';

const layerHostClassRightSideBar = mergeStyles({
  position: 'fixed',
  width: 0,
  height: 'calc(100% - 48px)',
  overflow: 'visible',
  zIndex: 800,
});

const layerMobileHostClassRightSideBar = mergeStyles({
  width: 0,
  height: 'calc(100% - 48px)',
  overflow: 'visible',
  zIndex: 800,
});

export default function AppRightSideBarLayerHost() {
  const appContext = useContext(AppContext);

  return (
    <LayerHost
      id="layerHostRightSideBar"
      className={appContext.isMobileView ? layerMobileHostClassRightSideBar : layerHostClassRightSideBar}
    ></LayerHost>
  );
}
