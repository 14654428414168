import {
  ScrollablePane,
  Stack,
  ScrollbarVisibility,
  Text,
  IColumn,
  DetailsList,
  SelectionMode,
  Icon,
  Selection,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  Link,
  DetailsListLayoutMode,
  ConstrainMode,
} from '@fluentui/react';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IList } from '../../services/Graph/SharepointInterfaces';

interface IMyListsProps {
  lists: IList[];
  onSelect: (item: IList) => void;
  isListLoading: boolean;
  onSelectListItemMode?: (item: IList) => void;
}

const MyLists = (props: IMyListsProps) => {
  const { t } = useTranslation(['sharepoint']);

  const columns: IColumn[] = [
    {
      key: 'icon',
      minWidth: 15,
      maxWidth: 15,
      onRender: () => {
        return (
          <Stack verticalAlign="center">
            <Icon iconName={'BulletedList2'} className={classes.icon} />
          </Stack>
        );
      },
      name: '',
    },
    {
      key: 'list',
      minWidth: 200,
      name: '',
      onRender: (item?: IList, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;
        if (props.onSelectListItemMode)
          return (
            <Text>
              <Link
                underline
                onClick={() => {
                  if (props.onSelectListItemMode) props.onSelectListItemMode(item);
                }}
              >
                {item.name}
              </Link>
            </Text>
          );

        return <Text>{item.name}</Text>;
      },
    },
  ];

  const classes = mergeStyleSets({
    icon: {
      fontSize: '16px',
    },
  });

  if (props.isListLoading) {
    return (
      <Stack verticalFill verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const selection = new Selection({
    onSelectionChanged: () => {
      const item = selection.getSelection()[0] as IList;
      props.onSelect(item);
    },
  });

  if (props.lists.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('sharepoint:SharepointPicker.NoData')}</Text>
      </Stack>
    );
  }

  return (
    <Stack grow styles={{ root: { position: 'relative' } }} verticalFill>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <Stack.Item tokens={globalStackTokensGapSmall} grow verticalFill>
          <DetailsList
            selection={selection}
            columns={columns}
            items={props.lists}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            isHeaderVisible={false}
            selectionMode={props.onSelectListItemMode ? SelectionMode.none : SelectionMode.single}
          />
        </Stack.Item>
      </ScrollablePane>
    </Stack>
  );
};

export default MyLists;
