export class ObjectiveLinkDTO {
  objectiveId: number;

  linkId: number;

  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    this.objectiveId = -1;
    this.linkId = -1;
    this.pinned = false;
    this.linkURLFragment = '';
  }
}
