import { CommentTrail, CommentTrailEntry } from 'models/comments';
import { CommentTrailDTO, CommentTrailEntryDTO } from 'models/dto/commentsDTO';
import AppError from 'utils/appError';
import http from './httpService';
import { mapFromComment, mapToComment, mapFromComments } from 'mappings/dataMapping';
import Entity from 'models/entity';

export async function apiGetComments(commentTrailId: number, accessToken: string): Promise<CommentTrail> {
  try {
    const ar = await http.get<CommentTrailDTO>(`/comments/${commentTrailId}`, http.getRequestConfig(accessToken));

    return mapFromComments(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateComment(
  commentTrailEntry: CommentTrailEntry,
  entity: Entity,
  accessToken: string,
): Promise<CommentTrailEntry> {
  let commentTrailEntryDTO = mapToComment(commentTrailEntry);
  commentTrailEntryDTO.entityType = entity.typeOfEntity;
  commentTrailEntryDTO.entityId = entity.entityId;

  const ar = await http.put<CommentTrailEntryDTO>(
    '/comments',
    commentTrailEntryDTO,
    http.getRequestConfig(accessToken),
  );

  return mapFromComment(ar.data);
}

export async function apiAddComment(
  commentTrailEntry: CommentTrailEntry,
  accessToken: string,
): Promise<CommentTrailEntry> {
  let commentTrailEntryDTO = mapToComment(commentTrailEntry);

  const ar = await http.post<CommentTrailEntryDTO>(
    '/comments',
    commentTrailEntryDTO,
    http.getRequestConfig(accessToken),
  );

  return mapFromComment(ar.data);
}

export async function apiAddCommentForSpecificTenant(
  tenantId: string,
  commentTrailEntry: CommentTrailEntry,
  accessToken: string,
): Promise<CommentTrailEntry> {
  let commentTrailEntryDTO = mapToComment(commentTrailEntry);

  const ar = await http.post<CommentTrailEntryDTO>(
    `/comments/${tenantId}`,
    commentTrailEntryDTO,
    http.getRequestConfig(accessToken),
  );

  return mapFromComment(ar.data);
}

export async function apiRemoveComment(
  commentTrailId: number,
  commentTrailEntryId: number,
  entity: Entity,
  accessToken: string,
): Promise<void> {
  try {
    let commentTrailEntryDTO = new CommentTrailEntryDTO();
    commentTrailEntryDTO.commentTrailId = commentTrailId;
    commentTrailEntryDTO.commentTrailEntryId = commentTrailEntryId;
    commentTrailEntryDTO.entityType = entity.typeOfEntity;
    commentTrailEntryDTO.entityId = entity.entityId;

    await http.post(`/comments/delete`, commentTrailEntryDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
