import { PrimaryButton, Text, DefaultButton, Modal, Stack, IconButton, IStackTokens, Separator } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { cancelIcon, globalStackTokensGapSmall } from 'globalStyles';
import { ReactNode } from 'react';

interface IGenericModalProps {
  onClose: () => void;
  onSave?: () => void | Promise<void>;
  isActionPending?: boolean;
  isOpen: boolean;
  title: string;
  subTitle?: string;
  hideFooter?: boolean;
  keepOpenAfterSave?: boolean;
  saveButtonText?: string;
  saveDisabled?: boolean;
  children?: ReactNode;
  maxHeight?: string | number;
  minHeight?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  width?: string | number;
  height?: string | number;
  verticalGap?: IStackTokens;
  optionalButtons?: JSX.Element;
  transition?: string;
}

const GenericModal = (props: IGenericModalProps) => {
  const { t } = useTranslation(['translation', 'task']);

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onClose}>
      <Stack
        verticalFill
        tokens={props.verticalGap ? props.verticalGap : undefined}
        styles={{
          root: {
            padding: 20,
            maxWidth: props.maxWidth ? props.maxWidth : 400,
            minWidth: props.minWidth ? props.minWidth : 300,
            minHeight: props.minHeight ? props.minHeight : undefined,
            maxHeight: props.maxHeight ? props.maxHeight : undefined,
            width: props.width ? props.width : undefined,
            height: props.height ? props.height : undefined,
            transition: props.transition,
          },
        }}
      >
        <Stack.Item>
          <Stack horizontal horizontalAlign={'space-between'} verticalAlign='start'>
            <Text variant="xLarge">{props.title}</Text>
            <IconButton iconProps={cancelIcon} onClick={props.onClose} />
          </Stack>
        </Stack.Item>
        {props.subTitle && (
          <Stack.Item styles={{root: {paddingTop: 5}}}>
            <Text variant="medium">{props.subTitle}</Text>
          </Stack.Item>
        )}
        <Stack.Item grow>{props.children}</Stack.Item>
        {!props.hideFooter && (
          <Stack.Item styles={{root: {paddingTop: 10}}}>
            <Stack tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <Separator />
              </Stack.Item>
              <Stack.Item>
                <Stack horizontal horizontalAlign={props.optionalButtons ? 'space-between' : 'end'}>
                  <Stack.Item>{props.optionalButtons}</Stack.Item>
                  <Stack horizontal tokens={globalStackTokensGapSmall}>
                    <PrimaryButton
                      disabled={props.saveDisabled || props.isActionPending}
                      onClick={async () => {
                        if (props.onSave) {
                          await props.onSave();
                        }
                        if (!props.keepOpenAfterSave) {
                          props.onClose();
                        }
                      }}
                      text={
                        props.onSave
                          ? props.saveButtonText
                            ? props.saveButtonText
                            : t('translation:General.Button.Save')
                          : t('translation:General.Button.Close')
                      }
                    />
                    {props.onSave && (
                      <DefaultButton
                        disabled={props.isActionPending}
                        onClick={() => {
                          props.onClose();
                        }}
                        text={t('translation:General.Button.Cancel')}
                      ></DefaultButton>
                    )}
                  </Stack>
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        )}
      </Stack>
    </Modal>
  );
};

export default GenericModal;
