import { newGuidNil } from 'utils/guid';
import { CommentTrail, CommentTrailEntry } from 'models/comments';
import { CommentTrailDTO, CommentTrailEntryDTO } from 'models/dto/commentsDTO';
import { fromApiDateTimeOptional, fromApiDateTimeRequired } from 'utils/datetime';

export function mapFromComments(commentsDTO: CommentTrailDTO): CommentTrail {
  let commentTrail = new CommentTrail();

  commentTrail.commentTrailId = commentsDTO.commentTrailId;
  commentTrail.entries = commentsDTO.entries.map((commentTrailEntryDTO) => {
    return mapFromComment(commentTrailEntryDTO);
  });

  return commentTrail;
}

export function mapFromComment(commentTrailEntryDTO: CommentTrailEntryDTO): CommentTrailEntry {
  let commentTrailEntry = new CommentTrailEntry();

  commentTrailEntry.commentTrailEntryId = commentTrailEntryDTO.commentTrailEntryId;
  commentTrailEntry.commentTrailId = commentTrailEntryDTO.commentTrailId;
  commentTrailEntry.comment = commentTrailEntryDTO.comment;
  commentTrailEntry.created = commentTrailEntryDTO.created
    ? fromApiDateTimeRequired(commentTrailEntryDTO.created)
    : new Date();
  commentTrailEntry.createdById = commentTrailEntryDTO.createdById || newGuidNil(); //nil guid should never happen
  commentTrailEntry.modified = fromApiDateTimeOptional(commentTrailEntryDTO.modified);
  commentTrailEntry.modifiedById = commentTrailEntryDTO.modifiedById;
  commentTrailEntry.modifiedBy = commentTrailEntryDTO.modifiedBy;
  commentTrailEntry.createdBy = commentTrailEntryDTO.createdBy;

  return commentTrailEntry;
}

export function mapToComment(comment: CommentTrailEntry): CommentTrailEntryDTO {
  let commentTrailEntryDTO = new CommentTrailEntryDTO();

  commentTrailEntryDTO.commentTrailId = comment.commentTrailId;
  commentTrailEntryDTO.commentTrailEntryId = comment.commentTrailEntryId;
  commentTrailEntryDTO.comment = comment.comment;
  commentTrailEntryDTO.createdBy = comment.createdBy;
  commentTrailEntryDTO.createdById = comment.createdById ? comment.createdById : undefined; //empty string should be converted to undefined
  commentTrailEntryDTO.modifiedById = comment.modifiedById;

  return commentTrailEntryDTO;
}
