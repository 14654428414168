import { PackageImportDTO, PackageTenantDTO } from './../models/dto/packageDTO';
import { mapFromNorm, mapFromResourceLists } from 'mappings/dataMapping';
import { PackageContentDTO } from 'models/dto/packageDTO';
import PackageDTO from 'models/dto/packageDTO';
import Package, { PackageContent, PackageImport, PackageTenant } from 'models/package';
import {
  fromApiDateTimeOptional,
  fromApiDateTimeRequired,
  toApiDateTimeOptional,
  toApiDateTimeRequired,
} from 'utils/datetime';
import { mapFromLanguages, mapToLanguages } from './langMapping';
import { mapFromResourceLinksWithLists } from './linkMapping';

export function mapFromPackages(packageDTOs: PackageDTO[]): Package[] {
  const packages: Package[] = [];

  for (let idx = 0; idx < packageDTOs.length; idx++) {
    const dto = packageDTOs[idx];
    const pack = mapFromPackage(dto);
    if (pack) packages.push(pack);
  }

  return packages;
}

export function mapFromPackage(packageDTO: PackageDTO | undefined): Package | undefined {
  if (!packageDTO) return undefined;
  const pack = new Package();
  pack.packageId = packageDTO.packageId;
  pack.categoryId = packageDTO.categoryId;
  pack.code = packageDTO.code;
  pack.name = packageDTO.name;
  pack.created = fromApiDateTimeRequired(packageDTO.created);
  pack.shortDescription = packageDTO.shortDescription;
  pack.description = packageDTO.description;
  pack.languages = packageDTO.languages ? mapFromLanguages(packageDTO.languages) : undefined;
  pack.modified = fromApiDateTimeOptional(packageDTO.modified);
  pack.modifiedBy = packageDTO.modifiedBy;
  pack.modifiedById = packageDTO.modifiedById;
  pack.ownerName = packageDTO.ownerName;
  pack.ownerTenantId = packageDTO.ownerTenantId;
  pack.ownerUserId = packageDTO.ownerUserId ? packageDTO.ownerUserId : '';
  pack.price = packageDTO.price;
  pack.purchaseURL = packageDTO.purchaseURL;
  pack.startLinkId = packageDTO.startLinkId;
  pack.webURL = packageDTO.webURL;
  pack.webURLName = packageDTO.webURLName;
  pack.state = packageDTO.state;
  pack.commentTrailId = packageDTO.commentTrailId;
  pack.auditTrailId = packageDTO.auditTrailId;
  pack.tenantId = packageDTO.tenantId ? packageDTO.tenantId : '';
  pack.version = packageDTO.version;
  pack.purchaseMode = packageDTO.purchaseMode;
  pack.norm = packageDTO.norm ? mapFromNorm(packageDTO.norm) : undefined;
  pack.primaryLanguage = packageDTO.primaryLanguage;
  pack.tenants = packageDTO.packageTenants ? mapFromPackageTenants(packageDTO.packageTenants) : [];
  pack.contents = packageDTO.packageContents ? mapFromPackageContent(packageDTO.packageContents) : [];
  pack.sharePointLists = packageDTO.sharePointLists ? mapFromResourceLists(packageDTO.sharePointLists) : [];
  pack.sharePointLinks = packageDTO.sharePointLinks
    ? mapFromResourceLinksWithLists(packageDTO.sharePointLinks, pack.sharePointLists)
    : [];
  pack.externalId = packageDTO.externalId;
  pack.notificationEmail = packageDTO.notificationEmail;
  pack.isoControls = [...(packageDTO.isoControls ?? [])];
  pack.isPreview = packageDTO.isPreview;
  pack.fromPackages = packageDTO.fromPackages ? mapFromPackages(packageDTO.fromPackages) : [];
  pack.toPackages = packageDTO.toPackages ? mapFromPackages(packageDTO.toPackages) : [];

  return pack;
}

export function mapToPackage(pack: Package): PackageDTO {
  const packageDTO = new PackageDTO();
  packageDTO.packageId = pack.packageId;
  packageDTO.code = pack.code;
  packageDTO.categoryId = pack.categoryId;
  packageDTO.name = pack.name;
  packageDTO.created = toApiDateTimeRequired(pack.created);
  packageDTO.shortDescription = pack.shortDescription;
  packageDTO.description = pack.description;
  packageDTO.languages = pack.languages ? mapToLanguages(pack.languages) : undefined;
  packageDTO.modified = toApiDateTimeOptional(pack.modified);
  packageDTO.modifiedBy = pack.modifiedBy;
  packageDTO.modifiedById = pack.modifiedById;
  packageDTO.ownerName = pack.ownerName;
  packageDTO.ownerTenantId = pack.ownerTenantId;
  packageDTO.ownerUserId = pack.ownerUserId ? pack.ownerUserId : undefined;
  packageDTO.price = pack.price;
  packageDTO.purchaseURL = pack.purchaseURL;
  packageDTO.startLinkId = pack.startLinkId;
  packageDTO.webURL = pack.webURL;
  packageDTO.webURLName = pack.webURLName;
  packageDTO.state = pack.state;
  packageDTO.commentTrailId = pack.commentTrailId;
  packageDTO.auditTrailId = pack.auditTrailId;
  packageDTO.tenantId = pack.tenantId;
  packageDTO.version = pack.version;
  packageDTO.purchaseMode = pack.purchaseMode;
  packageDTO.primaryLanguage = pack.primaryLanguage;
  packageDTO.externalId = pack.externalId;
  packageDTO.notificationEmail = pack.notificationEmail;
  packageDTO.isoControls = [...pack.isoControls];
  packageDTO.isPreview = pack.isPreview;
  packageDTO.fromPackages = pack.fromPackages?.map((p) => mapToPackage(p));
  packageDTO.toPackages = pack.toPackages?.map((p) => mapToPackage(p));

  return packageDTO;
}

export function mapFromPackageContent(contentDTO: PackageContentDTO[]): PackageContent[] {
  return contentDTO.map((c) => mapFromPackageContentItem(c));
}

export function mapFromPackageContentItem(contentItemDTO: PackageContentDTO): PackageContent {
  const contentItem = new PackageContent();
  contentItem.contentId = contentItemDTO.contentId;
  contentItem.contentParentId = contentItemDTO.contentParentId;
  contentItem.sourceTenantId = contentItemDTO.sourceTenantId;
  contentItem.sourceEntityType = contentItemDTO.sourceEntityType;
  contentItem.sourceEntityId = contentItemDTO.sourceEntityId;
  contentItem.name = contentItemDTO.name;
  contentItem.data1 = contentItemDTO.data1;
  contentItem.data2 = contentItemDTO.data2;
  contentItem.blob = undefined; //download of Blob is done via other API

  return contentItem;
}

export function mapToPackageContentItem(contentItem: PackageContent): PackageContentDTO {
  const contentItemDTO = new PackageContentDTO();
  contentItemDTO.contentId = contentItem.contentId;
  contentItemDTO.contentParentId = contentItem.contentParentId;
  contentItemDTO.sourceTenantId = contentItem.sourceTenantId;
  contentItemDTO.sourceEntityType = contentItem.sourceEntityType;
  contentItemDTO.sourceEntityId = contentItem.sourceEntityId;
  contentItemDTO.name = contentItem.name;
  contentItemDTO.data1 = contentItem.data1;
  contentItemDTO.data2 = contentItem.data2;
  contentItemDTO.blob = undefined; //upload of Blob is done via other API

  return contentItemDTO;
}

export function mapFromPackageTenants(packageTenantDtos: PackageTenantDTO[] | undefined): PackageTenant[] {
  if (!packageTenantDtos) return [];

  return packageTenantDtos.map((p) => mapFromPackageTenant(p));
}

export function mapFromPackageTenant(packageTenantDto: PackageTenantDTO): PackageTenant {
  const packageTenant = new PackageTenant();
  packageTenant.packageId = packageTenantDto.packageId;
  packageTenant.activateDate = fromApiDateTimeOptional(packageTenantDto.activateDate);
  packageTenant.purchaseDate = fromApiDateTimeOptional(packageTenantDto.purchaseDate);
  packageTenant.transactionId = packageTenantDto.transactionId;
  packageTenant.state = packageTenantDto.state;

  return packageTenant;
}

export function mapFromPackageImports(packageImportsDtos: PackageImportDTO[]): PackageImport[] {
  return packageImportsDtos.map((p) => mapFromPackageImport(p));
}

export function mapFromPackageImport(packageImportsDto: PackageImportDTO): PackageImport {
  const packageImport = new PackageImport();
  packageImport.packageId = packageImportsDto.packageId;
  packageImport.sourceEntityType = packageImportsDto.sourceEntityType;
  packageImport.sourceEntityId = packageImportsDto.sourceEntityId;
  packageImport.sourceEntityIdTo = packageImportsDto.sourceEntityIdTo;
  packageImport.targetEntityId = packageImportsDto.targetEntityId;
  packageImport.targetEntityIdTo = packageImportsDto.targetEntityIdTo;
  packageImport.state = packageImportsDto.state;
  packageImport.name = packageImportsDto.name;

  return packageImport;
}
