import Process from 'models/process/process';
import Risk from 'models/risk';

export class AssetContext {
  risks: Risk[];

  processes: Process[];

  constructor() {
    this.risks = [];
    this.processes = [];
  }
}
