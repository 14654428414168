import { ReactNode } from 'react';
import { MessageBar, MessageBarType, Text } from '@fluentui/react';

interface IWarningMessageProps {
  message?: string;
  onDismiss?: () => void;
  children?: ReactNode;
}

const WarningMessage = (props: IWarningMessageProps) => {
  if (props.message === undefined && props.children === undefined) {
    return null;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType.warning}
      onDismiss={props.onDismiss}
      isMultiline={true}
      truncated={false}
    >
      {props.message && (
        <Text styles={{ root: { color: 'black' } }} block>
          {props.message}
        </Text>
      )}
      {props.children}
    </MessageBar>
  );
};

export default WarningMessage;
