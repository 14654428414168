import AppContext from 'App/AppContext';
import { EntityTypes } from 'models/entity';
import KPI, { KPITypes } from 'models/kpi/kpi';
import KPIData from 'models/kpi/kpiData';
import TaskTypeFormElement, { TaskTypeFieldType } from 'models/tasks/taskTypeFormElement';
import Task from 'models/tasks/task';
import { useContext, useEffect, useState } from 'react';
import { apiGetTaskTypeFormAndData } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnNumber } from 'utils/sorting';
import TaskTypeFormRenderer from './TaskTypeFormRenderer';
import ResourceLink from 'models/resourceLink';
import { useTranslation } from 'react-i18next';
import AppError from 'utils/appError';
import { apiGetLinksForId } from 'services/Api/linkService';
import { isEmpty } from 'utils/string';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { CommandButton, DefaultButton, Stack, Text, getTheme } from '@fluentui/react';
import { globalStackTokensGapMedium } from 'globalStyles';
import { TaskTagList } from '../TaskTagList';
import { getTaskViewModalStackStylesFormMode } from '../View/TaskRenderHelpers';

interface ITaskTypeFormProps {
  isOpen: boolean;
  task: Task;
  links: ResourceLink[];
  compact: boolean;
  compactTaskView?: boolean;
  readonly: boolean;
  showErrors: string | undefined;
  formMode?: boolean;
  onUpdateData: (data: KPIData) => void;
  onSetData: (data: KPIData | KPIData[], instances?: Task[]) => void;
  onUpdateTaskForForm: (task: Task, validateForm: boolean) => void;
  addLinks: (links: ResourceLink[], validateForm: boolean) => void;
  setLinks: (links: ResourceLink[], validateForm: boolean) => void;
  removeLink: (link: ResourceLink, validateForm: boolean) => void;
  onValidate: (errors: TaskTypeFormElement[]) => void;
  onExitFormMode?: () => void;
  onClickFinish?: () => void;
}

//save the promise to local variable while loading async so that other async functions can wait for it
//-needed for validating the form async
//-put this outside of the component so this is not set on every re-render
let loadPromise: Promise<TaskTypeFormElement[]> | undefined;

const TaskTypeForm = (props: ITaskTypeFormProps) => {
  const { t } = useTranslation(['translation', 'forms']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<TaskTypeFormElement[]>([]);
  const [taskType, setTaskType] = useState<number>(0);
  const [taskId, setTaskId] = useState<number>(0);
  const [hasFormFeature] = useState<boolean>(hasUserFeature(appContext, FeatureTypes.TaskForm));
  const theme = getTheme();

  const topTaskButtonStyles = {
    root: {
      color: theme.palette.themeSecondary,
    },
  };

  useEffect(() => {
    //load data also when form is not open
    //only load when task or form changes
    if (props.task && props.task.taskTypeId && (props.task.taskTypeId !== taskType || props.task.taskId !== taskId)) {
      setTaskType(props.task.taskTypeId);
      setTaskId(props.task.taskId);
      loadPromise = loadData();
    } else if (formElements.length > 0) {
      const elements = calcKPIExpressions(props.task, formElements);
      setFormElements(elements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  useEffect(() => {
    if (props.showErrors) {
      validateForm();
    } else {
      formElements.forEach((elm) => (elm.errorMessage = undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showErrors]);

  //
  // Load
  //
  const loadData = async (): Promise<TaskTypeFormElement[]> => {
    try {
      if (isLoading || !props.task.taskTypeId) {
        return formElements;
      }

      setIsLoading(true);
      appContext.showContentLoader();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let elements = await apiGetTaskTypeFormAndData(
        props.task.taskTypeId,
        EntityTypes.Task,
        props.task.taskId,
        accessToken,
      );
      elements.sort((a, b) => sortOnNumber(a.sortOrder, b.sortOrder));

      //load the data from the task when there is data
      //otherwise get the data from the API
      //a form can only contain unique KPI's so we can find on criterium kpiId only
      const dataToSetOnTask: KPIData[] = [];

      elements.forEach((elm) => {
        if (elm.kpi) {
          const dataFromTask = props.task.kpiData?.find((d) => d.kpiId === elm.kpiId);
          if (dataFromTask) {
            //set unsaved data from the task
            elm.kpi.data = [dataFromTask];
            //update the KPI ref because the loaded data has another ref
            elm.kpi.data[0].kpi = elm.kpi;
          } else if (elm.kpi.data.length === 0) {
            //create a new empty data record
            const newData = new KPIData();
            newData.kpiId = elm.kpi.kpiId;
            elm.kpi.addData([newData]);

            if (elm.kpi.type === KPITypes.Expression) {
              //expressions cannot be set by the user
              //these records must therefore always be send to the task
              //other data records are send to the task when they are changed by the user
              dataToSetOnTask.push(newData);
            }
          } else {
            //there was data returned from the API
            //link the data record to the kpi
            //this is needed because the task object only contains KPIData objects
            //and to validate the KPIData objects, the KPI model is needed
            elm.kpi.data[0].kpi = elm.kpi;
            //push the data object to the task
            dataToSetOnTask.push(elm.kpi.data[0]);
          }
        }
      });

      //when this form has expression fields that need subtask data, make sure it is loaded
      let instancesToUpdate: Task[] = [];
      let taskToCalcExpressions: Task = props.task.clone();
      const needSubTaskData = elements.some(
        (e) => e.kpi?.type === KPITypes.Expression && KPI.expressionNeedsSubtasks(e.kpi?.defValue),
      );
      if (needSubTaskData) {
        instancesToUpdate = await getSubTaskKPIData(props.task);
        if (!taskToCalcExpressions.instances) taskToCalcExpressions.instances = [];

        for (let idx = 0; idx < instancesToUpdate.length; idx++) {
          const newInstance = instancesToUpdate[idx];
          const currentInstanceIdx = taskToCalcExpressions.instances?.findIndex((t) => t.id === newInstance.id);
          if (currentInstanceIdx !== undefined && currentInstanceIdx >= 0) {
            taskToCalcExpressions.instances[currentInstanceIdx] = newInstance;
          }
        }
      }

      //calculate expresions
      elements = calcKPIExpressions(taskToCalcExpressions, elements);

      //push changes to task
      props.onSetData(dataToSetOnTask, instancesToUpdate);

      //set state
      setFormElements(elements);

      return elements;
    } catch (err) {
      appContext.setError(err);

      return formElements;
    } finally {
      appContext.hideContentLoader();
      setIsLoading(false);
      loadPromise = undefined; //clear the shared promise so other processes can continue
    }
  };

  //
  // Expressions
  //
  const calcKPIExpressions = (task: Task, elements: TaskTypeFormElement[]): TaskTypeFormElement[] => {
    //calculate expressions
    if (!task.kpiData) return elements;
    const newElements = elements.map((e) => e.clone());

    const data = task.kpiData;
    const exprData = data.filter((e) => e.kpi?.type === KPITypes.Expression);

    exprData.forEach((e) => {
      if (e.kpi) {
        const result = KPI.calcExpression(task, e.kpi.defValue);

        //update the data on the task
        e.resultNumber = result; //this is used to save to back-end
        e.kpi.data[0].resultNumber = result; //this is used by the form

        //update the element on the form
        const elm = newElements.find((elm) => elm.kpiId === e.kpiId);
        if (elm && elm.kpi && elm.kpi.data.length > 0) {
          elm.kpi.data[0].resultNumber = result;
        }
      }
    });

    return newElements;
  };

  const getSubTaskKPIData = async (task: Task): Promise<Task[]> => {
    try {
      if (!task.instances || task.instances.length === 0) return [];

      const instancesToUpdate: Task[] = [];
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      for (let idx = 0; idx < task.instances.length; idx++) {
        const instance = task.instances[idx].clone();
        if (instance.taskTypeId) {
          //get all data for this instance
          const elements = await apiGetTaskTypeFormAndData(
            instance.taskTypeId,
            EntityTypes.Task,
            instance.taskId,
            accessToken,
          );
          if (!instance.kpiData) instance.kpiData = [];
          for (let elmIdx = 0; elmIdx < elements.length; elmIdx++) {
            const kpi = elements[elmIdx].kpi;
            if (kpi) {
              //when the data is not found in the instance, add it
              const dataFromTask = instance.kpiData?.find((d) => d.kpiId === kpi.kpiId);
              if (!dataFromTask && kpi.data.length > 0) {
                instance.kpiData.push(kpi.data[0]);
                if (instancesToUpdate.find((i) => i.id === instance.id) === undefined) {
                  instancesToUpdate.push(instance);
                }
              }
            }
          }
        }
      }

      return instancesToUpdate;
    } catch (err) {
      appContext.setError(err);

      return [];
    }
  };

  //
  // Validation
  //
  const validateForm = async () => {
    try {
      //wait for the loadData to be finished, otherwise there is no data to validate
      let elements = formElements;
      if (loadPromise) {
        elements = await loadPromise;
        loadPromise = undefined;
      }

      const errors: TaskTypeFormElement[] = [];

      for (let idx = 0; idx < elements.length; idx++) {
        const elm = elements[idx];
        if (await validateValue(elm)) {
          errors.push(elm);
        }
      }

      setFormElements([...elements]);
      props.onValidate(errors);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const validateValue = async (elm: TaskTypeFormElement): Promise<string | undefined> => {
    if (elm.kpi?.hasAutomatedEvidence) {
      return undefined;
    }

    elm.errorMessage = undefined;

    switch (elm.fieldType) {
      case TaskTypeFieldType.KPI:
        if (elm.kpi && elm.kpi.data.length > 0) {
          switch (elm.kpi.type) {
            case KPITypes.Choice:
            case KPITypes.Number:
            case KPITypes.SuccesError:
              if (elm.kpi.required && elm.kpi.data[0].resultNumber === undefined) {
                elm.errorMessage = t('forms:Errors.FieldRequired');
                break;
              }
              if (elm.kpi.minValue) {
                const min = Number.parseFloat(elm.kpi.minValue);
                if (elm.kpi.data[0].resultNumber === undefined || elm.kpi.data[0].resultNumber < min) {
                  elm.errorMessage = t('forms:Errors.NumberFieldTooShort', { value: min });
                  break;
                }
              }
              if (elm.kpi.maxValue) {
                const max = Number.parseFloat(elm.kpi.maxValue);
                if (elm.kpi.data[0].resultNumber !== undefined && elm.kpi.data[0].resultNumber > max) {
                  elm.errorMessage = t('forms:Errors.NumberFieldTooLong', { value: max });
                  break;
                }
              }
              break;
            case KPITypes.Date:
              if (elm.kpi.required && elm.kpi.data[0].resultDate === undefined) {
                elm.errorMessage = t('forms:Errors.FieldRequired');
                break;
              }
              break;
            case KPITypes.Html:
            case KPITypes.Text:
            case KPITypes.TextMultiline:
              if (elm.kpi.required && isEmpty(elm.kpi.data[0].resultText)) {
                elm.errorMessage = t('forms:Errors.FieldRequired');
                break;
              }
              if (elm.kpi.minValue) {
                const min = Number.parseInt(elm.kpi.minValue);
                if (
                  min > 0 &&
                  !isEmpty(elm.kpi.data[0].resultText) &&
                  elm.kpi.data[0].resultText &&
                  elm.kpi.data[0].resultText?.length < min
                ) {
                  elm.errorMessage = t('forms:Errors.TextFieldTooShort', {
                    length: min,
                    diff: min - (elm.kpi.data[0].resultText?.length ?? 0),
                  });
                  break;
                }
              }
              if (elm.kpi.maxValue) {
                const max = Number.parseInt(elm.kpi.maxValue);
                if (max > 0 && elm.kpi.data[0].resultText && elm.kpi.data[0].resultText?.length > max) {
                  elm.errorMessage = t('forms:Errors.TextFieldTooLong', {
                    length: max,
                    diff: elm.kpi.data[0].resultText?.length - max,
                  });
                  break;
                }
              }

              break;
          }
          if ((elm.kpi.attachmentMode ?? 0) > 0) {
            //there are required attachments
            const min = elm.kpi.getMinAttachments();
            const current = elm.kpi.getAttachments().length;
            if (current < min) {
              elm.errorMessage = t('forms:Errors.NotEnoughAttachments', { count: min - current });
            }
          }
          if ((elm.kpi.commentMode ?? 0) > 0) {
            //there is a required comment
            const min = elm.kpi.getMinComment();
            const current = elm.kpi.data[0].resultComment?.length ?? 0;
            if (current < min) {
              elm.errorMessage = t('forms:Errors.NotEnoughComment', { count: min - current });
            }
          }
        } else {
          throw new AppError('validateValue: KPI or KPI data is not set');
        }
        break;
      case TaskTypeFieldType.ContextAsset:
      case TaskTypeFieldType.ContextControl:
      case TaskTypeFieldType.ContextObjective:
      case TaskTypeFieldType.ContextProcess:
      case TaskTypeFieldType.ContextRequirement:
      case TaskTypeFieldType.ContextRisk:
        if ((elm.fieldMode ?? 0) > 0) {
          //there are required items
          const min = elm.getMinCount();
          const current = await elm.getCurrentContextCount(props.task, appContext);
          if (current < min) {
            elm.errorMessage = t('forms:Errors.NotEnoughContext', { count: min - current });
          }
        }
        break;
      case TaskTypeFieldType.AttachmentDocument:
      case TaskTypeFieldType.AttachmentListItem:
      case TaskTypeFieldType.AttachmentPage:
      case TaskTypeFieldType.AttachmentURL:
        if ((elm.fieldMode ?? 0) > 0) {
          //there are required items
          const links = await loadLinksForValidation(elm, props.links);
          const min = elm.getMinCount();
          const current = elm.getAttachments(links).length;
          if (current < min) {
            elm.errorMessage = t('forms:Errors.NotEnoughAttachments', { count: min - current });
          }
        }
        break;
    }

    return elm.errorMessage;
  };

  const loadLinksForValidation = async (elm: TaskTypeFormElement, links: ResourceLink[]): Promise<ResourceLink[]> => {
    try {
      if (!props.task) return [];
      if (props.task.resourceLinkIds && props.task.resourceLinkIds.length > links.length) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const attachments = await apiGetLinksForId(accessToken, props.task.resourceLinkIds, appContext.globalDataCache);
        const listType = elm.getListTypeOfAttachmentFieldType();

        return attachments.filter((a) => a.list.listType === listType);
      } else {
        return links;
      }
    } catch (err) {
      appContext.setError(err);

      return [];
    }
  };

  //
  // Update helpers
  //
  const updateKPI = (kpi: KPI) => {
    const idx = formElements.findIndex((e) => e.kpiId === kpi.kpiId);
    if (idx >= 0) {
      const newElements = [...formElements];
      newElements[idx].kpi = kpi;
      setFormElements(newElements);
      if (kpi.data.length > 0) {
        props.onUpdateData(kpi.data[0]);
      }
    }
  };

  const onUpdateField = (elm: TaskTypeFormElement) => {
    const newElements = formElements.map((e) => (e.key === elm.key ? elm : e));
    setFormElements(newElements);
  };

  const onUpdateValue = (kpi: KPI, data: KPIData) => {
    const newKPI = kpi.clone();
    if (newKPI.data.length > 0) {
      newKPI.data[0] = data;
      newKPI.data[0].kpi = newKPI;
      updateKPI(newKPI);
    }
  };

  const onUpdateKPI = (kpi: KPI) => {
    updateKPI(kpi);
  };

  const onSetKPI = (kpi: KPI) => {
    const idx = formElements.findIndex((e) => e.kpiId === kpi.kpiId);
    if (idx >= 0) {
      const newElements = [...formElements];
      newElements[idx].kpi = kpi;
      setFormElements(newElements);
      if (kpi.data.length > 0) {
        props.onSetData(kpi.data[0]);
      }
    }
  };

  const onUpdateTaskForForm = (task: Task) => {
    props.onUpdateTaskForForm(task, true);
  };

  const onAddLinks = (links: ResourceLink[]) => {
    props.addLinks(links, true);
  };

  const onSetLinks = (links: ResourceLink[]) => {
    props.setLinks(links, true);
  };

  const onRemoveLink = (link: ResourceLink) => {
    props.removeLink(link, true);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (props.onExitFormMode) props.onExitFormMode();
    }
  };

  //
  // Main render
  //
  if (!props.isOpen || !props.task.taskTypeId || !hasFormFeature) {
    return null;
  }

  if (props.formMode && !props.compactTaskView) {
    // Render in full screen mode over the parent task
    return (
      <Stack
        verticalFill
        onKeyDown={onKeyDown}
        styles={getTaskViewModalStackStylesFormMode(theme, appContext.isMobileView, appContext.useDarkMode)}
      >
        <Stack.Item grow>
          <Stack verticalFill tokens={globalStackTokensGapMedium} onKeyDown={onKeyDown}>
            <Stack.Item>
              <Stack horizontal wrap tokens={globalStackTokensGapMedium} verticalAlign="center">
                <Stack.Item>
                  <Text variant="xxLarge">{props.task.name}</Text>
                </Stack.Item>
                <Stack.Item>
                  <CommandButton
                    autoFocus
                    key={'exit'}
                    text={t('forms:View.ExitFormMode')}
                    iconProps={{ iconName: 'View' }}
                    onClick={() => {
                      if (props.onExitFormMode) props.onExitFormMode();
                    }}
                  />
                </Stack.Item>
                {!props.readonly && props.onClickFinish && props.task.taskId !== -1 && (
                  <Stack.Item>
                    <DefaultButton
                      styles={topTaskButtonStyles}
                      iconProps={{ iconName: 'FavoriteStarFill' }}
                      text={t('task:TopTasks.Finish')}
                      onClick={() => {
                        if (props.onExitFormMode) props.onExitFormMode();
                        if (props.onClickFinish) props.onClickFinish();
                      }}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TaskTagList
                tags={appContext.globalDataCache.tags.getItemsForId(props.task.tagIds)}
                isLoading={isLoading}
                readOnly={true}
                addTagToTaskState={() => {}}
                removeTagFromTaskState={() => {}}
              />
            </Stack.Item>
            <Stack.Item>
              <Text>{props.task.description}</Text>
            </Stack.Item>
            <Stack.Item grow>
              <TaskTypeFormRenderer
                task={props.task}
                links={props.links}
                formElements={formElements}
                isLoading={isLoading}
                allowEdit={false}
                compact={false}
                readonly={props.readonly}
                onUpdateKPI={onUpdateKPI}
                onSetKPI={onSetKPI}
                onUpdateValue={onUpdateValue}
                onUpdateTaskForForm={onUpdateTaskForForm}
                onUpdateField={onUpdateField}
                addLinks={onAddLinks}
                setLinks={onSetLinks}
                removeLink={onRemoveLink}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    );
  } else {
    //normal render
    return (
      <TaskTypeFormRenderer
        task={props.task}
        links={props.links}
        formElements={formElements}
        isLoading={isLoading}
        allowEdit={false}
        compact={props.compact}
        readonly={props.readonly}
        onUpdateKPI={onUpdateKPI}
        onSetKPI={onSetKPI}
        onUpdateValue={onUpdateValue}
        onUpdateTaskForForm={onUpdateTaskForForm}
        onUpdateField={onUpdateField}
        addLinks={onAddLinks}
        setLinks={onSetLinks}
        removeLink={onRemoveLink}
      />
    );
  }
};

export default TaskTypeForm;
