import { useContext } from 'react';
import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import { getCustomRolesDisplayString, getSystemRolesDisplayString } from 'globalFunctions';

interface IUserAccountAvatarProps {}

export const UserAccountAvatarSmall = (props: IUserAccountAvatarProps) => {
  const appContext = useContext(AppContext);

  // If a user avatar is available, return the persona with presence
  if (appContext.isAuthenticated) {
    return (
      <Persona
        imageUrl={appContext.user.getAvatarURL()}
        text={appContext.user.name}
        secondaryText=""
        tertiaryText=""
        optionalText=""
        size={PersonaSize.size32}
        presence={appContext.user.presence.getPersonaPresenceFromAvailability()}
        hidePersonaDetails={true}
        showInitialsUntilImageLoads={true}
      />
    );
  }

  // No avatar available, return a default icon
  return (
    <Persona
      text=""
      secondaryText=""
      tertiaryText=""
      optionalText=""
      size={PersonaSize.size32}
      hidePersonaDetails={true}
      presence={PersonaPresence.none}
    />
  );
};

export const UserAccountAvatarBig = (props: IUserAccountAvatarProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);

  // If a user avatar is available, return the persona with presence
  if (appContext.isAuthenticated) {
    const sr = appContext.user.login.userSystemRoles ?? [];
    const cr = appContext.globalDataCache.roles.getItemsForId(appContext.user.login.userCustomRoles);
    let roleStr = getSystemRolesDisplayString(sr, t);
    if (cr.length > 0) roleStr += ', ' + getCustomRolesDisplayString(cr);

    return (
      <Persona
        imageUrl={appContext.user.getAvatarURL()}
        text={appContext.user.name}
        secondaryText={appContext.user.email}
        tertiaryText={roleStr}
        optionalText=""
        size={PersonaSize.size72}
        presence={appContext.user.presence.getPersonaPresenceFromAvailability()}
        hidePersonaDetails={false}
        showInitialsUntilImageLoads={true}
      />
    );
  }

  // No avatar available, return a default icon
  return (
    <Persona
      text=""
      secondaryText=""
      tertiaryText=""
      optionalText=""
      size={PersonaSize.size100}
      hidePersonaDetails={false}
    />
  );
};
