import {
  DetailsList,
  IColumn,
  IDragDropContext,
  IDragDropEvents,
  mergeStyles,
  SelectionMode,
  Stack,
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  DetailsRow,
  IDetailsListProps,
  IDetailsRowStyles,
} from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewCheckListItem } from './AddNewCheckListItem';
import { CheckListItemRow } from './CheckListItemRow';
import EditTextFieldCallOut from 'components/CallOuts/EditTextFieldCallOut';
import { getGlobalStackItemStylesPaddingSceneScrollMinHeight, globalStackTokensGapSmall } from 'globalStyles';
import AppContext from 'App/AppContext';
import { TaskCheckList, TaskCheckListItem, TaskCheckListItemState } from 'models/tasks/taskHelperClasses';

interface ICheckList {
  allowEdit: boolean;
  allowFill: boolean;
  singleLineEdit?: boolean;
  checkList?: TaskCheckList;
  updateCheckList: (newCheckList: TaskCheckList) => void;
  onComment?: (item: TaskCheckListItem) => void;
}

export interface CalloutInformation {
  item: TaskCheckListItem;
  buttonId: string;
}

export const CheckList = (props: ICheckList) => {
  const { t } = useTranslation(['task', 'translation']);
  const appContext = useContext(AppContext);
  const [showIcon, setShowIcon] = useState<number | undefined>(undefined);
  const [_draggedItem, _setDraggedItem] = useState<TaskCheckListItem | undefined>(undefined);
  const [newCheckListDescription, setNewCheckListDescription] = useState<string>('');
  const [items, setItems] = useState<TaskCheckListItem[]>(props.checkList?.items ? props.checkList?.items : []);
  const [isCallOutOpen, setIsCallOutOpen] = useState<TaskCheckListItem | undefined>(undefined);
  const [editCallOutInfo, setEditCalloutInfo] = useState<CalloutInformation | undefined>(undefined);
  const [statusButtonIds, setStatusButtonIds] = useState<string[]>(
    props.checkList
      ? props.checkList.items.map((_itm) => {
          return 'RandomStatusButton' + Math.random().toString(36).substring(7);
        })
      : [],
  );

  const [editButtonIds, setEditButtonIds] = useState<string[]>(
    props.checkList
      ? props.checkList.items.map((_itm) => {
          return 'RandomEditButton' + Math.random().toString(36).substring(7);
        })
      : [],
  );

  useEffect(() => {
    setStatusButtonIds(
      props.checkList
        ? props.checkList.items.map((_itm) => {
            return 'RandomStatusButton' + Math.random().toString(36).substring(7);
          })
        : [],
    );
    setEditButtonIds(
      props.checkList
        ? props.checkList.items.map((_itm) => {
            return 'RandomEditButton' + Math.random().toString(36).substring(7);
          })
        : [],
    );
  }, [props.checkList]);

  const onComment = (item: TaskCheckListItem) => {
    if (!props.onComment) return;

    props.onComment(item);
  };

  const onDelete = (item: TaskCheckListItem) => {
    if (!props.checkList) return;

    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.filter((itm) => {
      return itm.id !== item.id;
    });

    props.updateCheckList(_newCheckList);
  };

  const onAddNewCheckListItem = () => {
    if (newCheckListDescription === '' || !props.checkList) return;

    const _newCheckList = props.checkList.clone();
    let _taskCheckListItem = new TaskCheckListItem();
    _taskCheckListItem.description = newCheckListDescription;
    _taskCheckListItem.state = TaskCheckListItemState.ToDo;
    _newCheckList.items.push(_taskCheckListItem);
    props.updateCheckList(_newCheckList);
    setNewCheckListDescription('');
  };

  const updateState = (item: TaskCheckListItem, state: TaskCheckListItemState) => {
    if (!props.checkList) return;

    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.map((_itm) => {
      if (_itm.id === item.id) {
        _itm.state = state;

        return _itm;
      } else return _itm;
    });
    props.updateCheckList(_newCheckList);
  };

  const updateDescription = (item: TaskCheckListItem, description: string) => {
    if (!props.checkList) return;
    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.map((_itm) => {
      if (_itm.id === item.id) {
        _itm.description = description;

        return _itm;
      } else return _itm;
    });
    props.updateCheckList(_newCheckList);
  };

  useEffect(() => {
    setItems(props.checkList?.items ? (props.checkList?.items ? props.checkList?.items : []) : []);
  }, [props.checkList]);

  const columns: IColumn[] = [
    {
      key: 'text',
      name: '',
      minWidth: 50,
      isMultiline: true,
      onRender: (item?: TaskCheckListItem, index?: number, column?: IColumn) => {
        if (!item || index === undefined) return;

        return (
          <CheckListItemRow
            allowEdit={props.allowEdit ?? true}
            allowFill={props.allowFill ?? true}
            index={index}
            setShowIcon={setShowIcon}
            statusButtonId={statusButtonIds[index]}
            editButtonId={editButtonIds[index]}
            showIcon={showIcon}
            isCallOutOpen={isCallOutOpen}
            setIsCallOutOpen={setIsCallOutOpen}
            item={item}
            onComment={onComment}
            onDelete={onDelete}
            updateState={updateState}
            callOutInfo={editCallOutInfo}
            setCallOutInfo={setEditCalloutInfo}
          />
        );
      },
    },
  ];

  const _insertBeforeItem = (item?: TaskCheckListItem) => {
    if (!item || !_draggedItem) return;

    const insertIndex = items.indexOf(item);
    const newItems = items.filter((itm) => {
      return itm.id !== _draggedItem.id;
    });

    newItems.splice(insertIndex, 0, _draggedItem);

    setItems(newItems);
    const _newCheckList = new TaskCheckList();
    _newCheckList.items = newItems;

    props.updateCheckList(_newCheckList);
  };

  const dragAndDropEventHandler: IDragDropEvents = {
    canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
      return true;
    },
    canDrag: (item?: TaskCheckListItem) => {
      if (props.allowEdit) return true;

      return false;
    },
    onDragEnter: (item?: TaskCheckListItem, event?: DragEvent) => {
      // return string is the css classes that will be added to the entering element.
      return mergeStyles({
        border: '2',
        borderBottom: 2,
        borderColor: 'blue',
      });
    },
    onDragLeave: (item?: TaskCheckListItem, event?: DragEvent) => {
      return;
    },
    onDrop: (item?: TaskCheckListItem, event?: DragEvent) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (
      item?: TaskCheckListItem,
      itemIndex?: number,
      selectedItems?: TaskCheckListItem[],
      event?: MouseEvent,
    ) => {
      _setDraggedItem(item);
    },
    onDragEnd: (item?: TaskCheckListItem, event?: DragEvent) => {
      _setDraggedItem(undefined);
    },
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.cellStyleProps) {
        props.cellStyleProps.cellLeftPadding = 5; //make a bit of room for the custom drag handle
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }

    return null;
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      {items.length === 0 && (
        <Stack grow horizontalAlign="center" verticalAlign="center">
          <Text>{t('task:CheckList.NoData')}</Text>
        </Stack>
      )}
      {items.length > 0 && (
        <Stack.Item grow styles={getGlobalStackItemStylesPaddingSceneScrollMinHeight(80)}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              compact
              items={items}
              columns={columns}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
              dragDropEvents={props.allowEdit ? dragAndDropEventHandler : undefined}
              onRenderRow={onRenderRow}
            />
          </ScrollablePane>
        </Stack.Item>
      )}
      {props.allowEdit && (
        <Stack.Item style={{ paddingLeft: '5px' }}>
          <AddNewCheckListItem
            newCheckListDescription={newCheckListDescription}
            setNewCheckListDescription={setNewCheckListDescription}
            onAddNewCheckListItem={onAddNewCheckListItem}
            allowEdit={props.allowEdit ? true : false}
            singleLineEdit={props.singleLineEdit || appContext.isMobileView}
          />
        </Stack.Item>
      )}
      <Stack.Item>
        {editCallOutInfo && (
          <EditTextFieldCallOut
            isVisible={editCallOutInfo !== undefined}
            targetId={editCallOutInfo.buttonId}
            value={editCallOutInfo.item.description}
            onClose={() => {
              setEditCalloutInfo(undefined);
            }}
            onUpdate={(newText: string) => {
              updateDescription(editCallOutInfo.item, newText);
            }}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default CheckList;
