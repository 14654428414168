import {
  DetailsListLayoutMode,
  IColumn,
  Label,
  Link,
  SelectionMode,
  Stack,
  Text,
  IconButton,
  Separator,
  FontIcon,
  IIconProps,
  SharedColors,
  getTheme,
  TooltipHost,
  ShimmeredDetailsList,
  ActionButton,
  DirectionalHint,
} from '@fluentui/react';
import KPI from 'models/kpi/kpi';
import TextInfo from 'components/Notification/Info';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext, useEffect, useState } from 'react';
import AddNewLink from 'components/Links/AddNewLink';
import ResourceLink from 'models/resourceLink';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import {
  deleteIcon,
  downArrowIcon,
  editIcon,
  globalStackStylesHeight100,
  globalStackTokensGapSmall,
  globalTextStylesBold,
  globalTextStylesError,
  newIcon,
  upArrowIcon,
} from 'globalStyles';
import GenericModal from 'components/Dialogs/GenericModal';
import { isEmpty } from 'utils/string';
import EditTextFieldCallOut from 'components/CallOuts/EditTextFieldCallOut';
import AppContext from 'App/AppContext';
import { EntityTypes } from 'models/entity';
import KPIDataContext from 'models/kpi/kpiDataContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetLinksForId } from 'services/Api/linkService';
import { navigateToExternalUrl } from 'utils/url';
import ResourceList from 'models/resourceList';
import TaskTypeFormElement, { TaskTypeFieldType } from 'models/tasks/taskTypeFormElement';
import { ISingleFormElementProps } from './SingleFormElement';

const SkypeCircleCheckIconSmall: IIconProps = {
  iconName: 'SkypeCircleCheck',
  style: {
    fontSize: 12,
    color: SharedColors.green20,
  },
};

const StatusErrorFullIconSmall: IIconProps = {
  iconName: 'StatusErrorFull',
  style: {
    fontSize: 12,
    color: SharedColors.red20,
  },
};

//
// Wrapper
//
export const SingleFormElementWrapper = (props: ISingleFormElementProps) => {
  const { t } = useTranslation(['translation', 'forms', 'kpi', 'kpis']);
  const appContext = useContext(AppContext);
  const [hover, setHover] = useState<boolean>(false);
  const theme = getTheme();

  const getStackStyles = () => {
    if (props.allowEdit && hover) {
      return {
        root: {
          margin: '1px',
          minHeight: 32,
          border: '1px solid grey',
          borderRadius: '3px',
          paddingTop: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '10px',
          background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighterAlt,
          '&:hover': {
            cursor: 'text',
            'border-color': theme.palette.themeSecondary,
            background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighterAlt,
            transition: 'all 0.3s ease',
          },
        },
      };
    } else {
      if (props.field.kpi?.hasAutomatedEvidence) {
        return {
          root: {
            margin: '1px',
            border: '1px solid #32a852',
            borderRadius: '3px',
            minHeight: 32,
            paddingTop: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
          },
        };
      } else if (props.field.errorMessage) {
        return {
          root: {
            margin: '1px',
            border: '1px solid #BC1E1E',
            borderRadius: '3px',
            minHeight: 32,
            paddingTop: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
          },
        };
      } else {
        return {
          root: {
            margin: '1px',
            border: '1px solid transparent',
            minHeight: 32,
            paddingTop: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
          },
        };
      }
    }
  };

  const getFieldTooltip = (): JSX.Element | undefined => {
    if (props.field.errorMessage) {
      return <Text styles={globalTextStylesError}>{props.field.errorMessage}</Text>;
    } else if (props.field.kpi?.hasAutomatedEvidence) {
      return <Text>{t('forms:Errors.HasAutomatedEvidence')}</Text>;
    } else {
      return undefined;
    }
  };

  //
  // Main render
  //
  return (
    <Stack
      tabIndex={0}
      onFocus={() => {
        setHover(true);
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      styles={getStackStyles()}
      horizontal
      horizontalAlign="space-between"
    >
      <Stack.Item grow>
        <TooltipHost
          calloutProps={{ directionalHint: DirectionalHint.topCenter, beakWidth: 0 }}
          hidden={!props.field.errorMessage && !props.field.kpi?.hasAutomatedEvidence}
          content={getFieldTooltip()}
        >
          <Stack>
            {props.children}
            {!props.hideAttachments && <SingleFormElementAttachmentsKPI {...props} />}
            <SingleFormElementComments {...props} />
          </Stack>
        </TooltipHost>
      </Stack.Item>
      <Stack.Item styles={{ root: { width: props.allowEdit ? 70 : 0 } }}>
        {props.allowEdit && hover && (
          <Stack horizontal styles={globalStackStylesHeight100} horizontalAlign="end">
            <Stack.Item>
              <Stack verticalFill horizontalAlign="end" verticalAlign="center">
                <TooltipHost content={t('forms:Dialogs.EditForm.Edit')}>
                  <IconButton
                    onClick={() => {
                      if (props.onEdit) props.onEdit(props.field);
                    }}
                    styles={{ root: { height: 24 } }}
                    iconProps={editIcon}
                  />
                </TooltipHost>
                <TooltipHost content={t('forms:Dialogs.EditForm.Remove')}>
                  <IconButton
                    onClick={() => {
                      if (props.onRemove) props.onRemove(props.field);
                    }}
                    styles={{ root: { height: 24 } }}
                    iconProps={deleteIcon}
                  />
                </TooltipHost>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack verticalFill horizontalAlign="end" verticalAlign="center">
                <TooltipHost content={t('forms:Dialogs.EditForm.MoveUp')}>
                  <IconButton
                    onClick={() => {
                      if (props.onMoveUp) props.onMoveUp(props.field);
                    }}
                    styles={{ root: { height: 24 } }}
                    iconProps={upArrowIcon}
                  />
                </TooltipHost>

                <TooltipHost content={t('forms:Dialogs.EditForm.MoveDown')}>
                  <IconButton
                    onClick={() => {
                      if (props.onMoveDown) props.onMoveDown(props.field);
                    }}
                    styles={{ root: { height: 24 } }}
                    iconProps={downArrowIcon}
                  />
                </TooltipHost>
              </Stack>
            </Stack.Item>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};

//
// Attachments KPI
//
export const SingleFormElementAttachmentsKPI = (props: ISingleFormElementProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'kpis']);
  const [showAttachmentPicker, setShowAttachmentPicker] = useState<boolean>(false);
  const [showAddAttachment, setShowAddAttachment] = useState<boolean>(false);

  const getStatusIcon = (kpi: KPI): JSX.Element | null => {
    const max = kpi.getMinAttachments();
    const current = kpi.attachments?.length ?? 0;
    if (kpi.attachmentMode && kpi.attachmentMode > 0) {
      if (current >= max) return <FontIcon {...SkypeCircleCheckIconSmall} />;
      if (current < max) return <FontIcon {...StatusErrorFullIconSmall} />;
    }

    return null;
  };

  const removeAttachment = (item: ResourceLink) => {
    if (!props.field.kpi) return;
    //remove from data contexts
    const newContexts = props.field.kpi?.data[0].contexts.filter(
      (c) => !(c.entityType === EntityTypes.KPILink && c.entityId === item.linkId),
    );
    props.field.kpi.data[0].contexts = newContexts;
    props.onUpdateValue(props.field.kpi, props.field.kpi.data[0]);
    //remove from the KPI attachment cache
    const newKpi = props.field.kpi?.clone();
    newKpi.attachments = props.field.kpi?.attachments?.filter((a) => a.linkId !== item.linkId);
    props.onUpdateKPI(newKpi);
  };

  const addAttachments = (links: ResourceLink[]) => {
    if (!props.field.kpi) return;
    //check max attachments and ignore if more are selected
    if (!props.field.kpi?.attachments) props.field.kpi.attachments = [];
    const max = props.field.kpi?.getMaxAttachments();
    if (props.field.kpi?.attachments.length + links.length > max) {
      links = links.slice(0, max - props.field.kpi?.attachments.length);
    }
    //create a new context item for each link
    //we assume here that the link itself was already saved
    links.forEach((link) => {
      if (props.field.kpi) {
        const newContext = new KPIDataContext();
        newContext.kpiId = props.field.kpi.kpiId;
        newContext.entityType = EntityTypes.KPILink;
        newContext.entityId = link.linkId;
        props.field.kpi?.data[0].contexts.push(newContext);
      }
    });

    //update the attachment KPI cache on the KPI itself
    props.field.kpi.attachments.push(...links);

    //push new data
    props.onUpdateValue(props.field.kpi, props.field.kpi?.data[0]);
  };

  const getDefList = (): ResourceList | undefined => {
    if (showAddAttachment && props.field.kpi?.listId && appContext.globalDataCache.lists.has(props.field.kpi?.listId)) {
      return appContext.globalDataCache.lists.get(props.field.kpi?.listId);
    } else {
      return undefined;
    }
  };

  const kpiHasNoValue = (kpi: KPI): boolean => {
    return kpi.data.length === 0 || !kpi.data[0].hasValue();
  };

  //
  // Main render
  //
  if (
    props.field.kpi?.attachmentMode &&
    props.field.kpi?.attachmentMode !== 0 &&
    !props.field.kpi.hasAutomatedEvidence
  ) {
    return (
      <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
        <Link onClick={() => setShowAttachmentPicker(true)} disabled={kpiHasNoValue(props.field.kpi)}>
          {props.field.kpi?.attachmentMode > 0
            ? t('kpis:Fields.AttachmentPicker.AttachmentsRequired', { count: props.field.kpi?.attachmentMode })
            : t('kpis:Fields.AttachmentPicker.AttachmentsOptional', {
                count: Math.abs(props.field.kpi?.attachmentMode),
              })}
        </Link>
        {getStatusIcon(props.field.kpi)}
        {props.field.kpi.listId && (
          <ActionButton
            disabled={props.readonly || kpiHasNoValue(props.field.kpi)}
            styles={{ root: { height: 24 } }}
            iconProps={newIcon}
            onClick={() => setShowAddAttachment(true)}
          />
        )}
        <AddNewLink
          addLinks={addAttachments}
          existingLinks={props.field.kpi?.attachments ?? []}
          disabled={false}
          allowCustomLists={true}
          allowDocumentLibraries={true}
          allowWebURLs={true}
          allowSitePages={true}
          entityContext={true}
          selectedList={getDefList()}
          hideButton={true}
          close={() => setShowAddAttachment(false)}
          hideWholeList={true}
        />
        <SingleFormElementAttachmentPickerKPI
          {...props}
          isOpen={showAttachmentPicker}
          onClose={() => setShowAttachmentPicker(false)}
          onAddAttachments={addAttachments}
          onRemoveAttachment={removeAttachment}
        />
      </Stack>
    );
  } else {
    return null;
  }
};

//
// Attachments Field
//
export const SingleFormElementAttachmentsField = (props: ISingleFormElementProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'forms']);
  const [showAttachmentPicker, setShowAttachmentPicker] = useState<boolean>(false);
  const [showAddAttachment, setShowAddAttachment] = useState<boolean>(false);

  const getStatusIcon = (field: TaskTypeFormElement): JSX.Element | null => {
    const max = field.getMinCount();
    const current = field.getAttachments(props.links).length ?? 0;
    if (field.fieldMode && field.fieldMode > 0) {
      if (current >= max) return <FontIcon {...SkypeCircleCheckIconSmall} />;
      if (current < max) return <FontIcon {...StatusErrorFullIconSmall} />;
    }

    return null;
  };

  const removeAttachment = (item: ResourceLink) => {
    props.removeLink(item);
  };

  const addAttachments = async (links: ResourceLink[]) => {
    //check max attachments and ignore if more are selected
    const attachments = props.field.getAttachments(props.links);
    const max = props.field.getMaxCount();
    if (attachments.length + links.length > max) {
      links = links.slice(0, max - attachments.length);
    }

    props.addLinks(links);
  };

  const getDefList = (): ResourceList | undefined => {
    if (showAddAttachment && props.field.listId && appContext.globalDataCache.lists.has(props.field.listId)) {
      return appContext.globalDataCache.lists.get(props.field.listId);
    } else {
      return undefined;
    }
  };

  //
  // Main render
  //
  if (props.field.fieldMode && props.field.fieldMode !== 0) {
    return (
      <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
        <Link onClick={() => setShowAttachmentPicker(true)}>
          {props.field.fieldMode > 0
            ? t('kpis:Fields.AttachmentPicker.AttachmentsRequired', { count: props.field.fieldMode })
            : t('kpis:Fields.AttachmentPicker.AttachmentsOptional', {
                count: Math.abs(props.field.fieldMode),
              })}
        </Link>
        {getStatusIcon(props.field)}
        {props.field.listId && (
          <ActionButton
            styles={{ root: { height: 24 } }}
            disabled={props.readonly}
            iconProps={newIcon}
            onClick={() => setShowAddAttachment(true)}
          />
        )}
        <AddNewLink
          addLinks={addAttachments}
          existingLinks={props.field.getAttachments(props.links)}
          disabled={false}
          allowCustomLists={props.field.fieldType === TaskTypeFieldType.AttachmentListItem}
          allowDocumentLibraries={props.field.fieldType === TaskTypeFieldType.AttachmentDocument}
          allowWebURLs={props.field.fieldType === TaskTypeFieldType.AttachmentURL}
          allowSitePages={props.field.fieldType === TaskTypeFieldType.AttachmentPage}
          entityContext={true}
          selectedList={getDefList()}
          hideButton={true}
          close={() => setShowAddAttachment(false)}
          hideWholeList={true}
        />
        <SingleFormElementAttachmentPickerField
          {...props}
          isOpen={showAttachmentPicker}
          onClose={() => setShowAttachmentPicker(false)}
          onAddAttachments={addAttachments}
          onRemoveAttachment={removeAttachment}
        />
      </Stack>
    );
  } else {
    return null;
  }
};

//
// Attachment Picker for KPI
//
interface ISingleFormElementAttachmentPickerKPIProps extends ISingleFormElementProps {
  onClose: () => void;
  isOpen: boolean;
  onRemoveAttachment: (item: ResourceLink) => void;
  onAddAttachments: (items: ResourceLink[]) => void;
}

export const SingleFormElementAttachmentPickerKPI = (props: ISingleFormElementAttachmentPickerKPIProps) => {
  const { t } = useTranslation(['translation', 'kpis']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isLoading &&
      props.field.kpi &&
      props.field.kpi.getAttachments().filter((c) => c.entityId > 0).length >
        (props.field.kpi?.attachments?.length ?? 0)
    ) {
      loadAttachmentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field]);

  const loadAttachmentDetails = async () => {
    try {
      if (!props.field.kpi) return;
      const attachmentContexts = props.field.kpi.getAttachments().filter((c) => c.entityId > 0);
      if (attachmentContexts && attachmentContexts.length > 0) {
        setIsLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const attachments = await apiGetLinksForId(
          accessToken,
          attachmentContexts.map((c) => c.entityId),
          appContext.globalDataCache,
        );

        const newKpi = props.field.kpi.clone();
        newKpi.attachments = attachments;
        props.onSetKPI(newKpi); //this sets tis initial data on the task model and original task so that they remain equal after loading
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const openInSharePoint = (item: ResourceLink) => {
    navigateToExternalUrl(item.linkURL, '', '', true);
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'name',
      minWidth: 150,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Text>
            <Link underline onClick={() => openInSharePoint(item)}>
              {item.linkName}
            </Link>
          </Text>
        );
      },
    },
    {
      key: 'remove',
      name: 'remove',
      minWidth: 50,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <IconButton disabled={props.readonly} iconProps={deleteIcon} onClick={() => props.onRemoveAttachment(item)} />
        );
      },
    },
  ];

  const getDefList = (): ResourceList | undefined => {
    if (props.field.kpi?.listId && appContext.globalDataCache.lists.has(props.field.kpi?.listId)) {
      return appContext.globalDataCache.lists.get(props.field.kpi?.listId);
    } else {
      return undefined;
    }
  };

  //
  // Main render
  //
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={t('kpis:Fields.AttachmentPicker.Title', { name: props.field.kpi?.name })}
      minWidth={'40vw'}
      minHeight={300}
    >
      <Stack verticalFill>
        <Separator />
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <AddNewLink
            selectedList={getDefList()}
            selectedListDisableOpen={(props.field.kpi?.getAttachments().length ?? 0) > 0}
            addLinks={props.onAddAttachments}
            existingLinks={props.field.kpi?.attachments ?? []}
            allowCustomLists={true}
            allowDocumentLibraries={true}
            allowWebURLs={true}
            allowSitePages={true}
            disabled={
              props.readonly ||
              isLoading ||
              ((props.field.kpi?.attachmentMode ?? 0) < 0 &&
                props.field.kpi?.getAttachments().length === Math.abs(props.field.kpi?.attachmentMode ?? 0)) ||
              props.field.kpi?.getAttachments().length === 5
            }
            entityContext={true}
            hideWholeList={true}
          />
          <Text>
            {props.field.kpi?.attachmentMode && props.field.kpi?.attachmentMode > 0
              ? t('kpis:Fields.AttachmentPicker.Required', { count: props.field.kpi?.attachmentMode })
              : t('kpis:Fields.AttachmentPicker.Optional', {
                  count: Math.abs(props.field.kpi?.attachmentMode ?? 0),
                })}
          </Text>
        </Stack>
        <Separator />
        {props.field.kpi?.getAttachments().length === 0 && (
          <Text>{t('kpis:Fields.AttachmentPicker.AttachmentsEmpty')}</Text>
        )}
        <ShimmeredDetailsList
          items={props.field.kpi?.attachments ?? []}
          columns={columns}
          compact
          enableShimmer={isLoading}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={false}
          onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
        />
      </Stack>
    </GenericModal>
  );
};

//
// Attachment Picker for Fields
//
interface ISingleFormElementAttachmentPickerFieldProps extends ISingleFormElementProps {
  onClose: () => void;
  isOpen: boolean;
  onRemoveAttachment: (item: ResourceLink) => void;
  onAddAttachments: (items: ResourceLink[]) => void;
}

export const SingleFormElementAttachmentPickerField = (props: ISingleFormElementAttachmentPickerFieldProps) => {
  const { t } = useTranslation(['translation', 'kpis']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isLoading &&
      props.task &&
      props.task.resourceLinkIds &&
      props.task.resourceLinkIds.filter((id) => id > 0).length > (props.field.getAttachments(props.links).length ?? 0)
    ) {
      loadAttachmentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field]);

  const loadAttachmentDetails = async () => {
    try {
      if (!props.task) return;
      if (props.task.resourceLinkIds && props.task.resourceLinkIds.length > 0) {
        setIsLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const attachments = await apiGetLinksForId(accessToken, props.task.resourceLinkIds, appContext.globalDataCache);
        const listType = props.field.getListTypeOfAttachmentFieldType();
        props.setLinks(attachments.filter((a) => a.list.listType === listType));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const openInSharePoint = (item: ResourceLink) => {
    navigateToExternalUrl(item.linkURL, '', '', true);
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'name',
      minWidth: 150,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Text>
            <Link underline onClick={() => openInSharePoint(item)}>
              {item.linkName}
            </Link>
          </Text>
        );
      },
    },
    {
      key: 'remove',
      name: 'remove',
      minWidth: 50,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <IconButton disabled={props.readonly} iconProps={deleteIcon} onClick={() => props.onRemoveAttachment(item)} />
        );
      },
    },
  ];

  const getDefList = (): ResourceList | undefined => {
    if (props.field.listId && appContext.globalDataCache.lists.has(props.field.listId)) {
      return appContext.globalDataCache.lists.get(props.field.listId);
    } else {
      return undefined;
    }
  };

  //
  // Main render
  //
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.field.name}
      minWidth={'40vw'}
      minHeight={300}
    >
      <Stack verticalFill>
        <Separator />
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <AddNewLink
            selectedList={getDefList()}
            selectedListDisableOpen={(props.field.getAttachments(props.links).length ?? 0) > 0}
            addLinks={props.onAddAttachments}
            existingLinks={props.field.getAttachments(props.links)}
            allowCustomLists={props.field.fieldType === TaskTypeFieldType.AttachmentListItem}
            allowDocumentLibraries={props.field.fieldType === TaskTypeFieldType.AttachmentDocument}
            allowWebURLs={props.field.fieldType === TaskTypeFieldType.AttachmentURL}
            allowSitePages={props.field.fieldType === TaskTypeFieldType.AttachmentPage}
            disabled={
              props.readonly ||
              isLoading ||
              ((props.field.fieldMode ?? 0) < 0 &&
                props.field.getAttachments(props.links).length === Math.abs(props.field.fieldMode ?? 0)) ||
              props.field.getAttachments(props.links).length === 10
            }
            entityContext={true}
            hideWholeList={true}
          />
          <Text>
            {props.field.fieldMode && props.field.fieldMode > 0
              ? t('kpis:Fields.AttachmentPicker.Required', { count: props.field.fieldMode })
              : t('kpis:Fields.AttachmentPicker.Optional', {
                  count: Math.abs(props.field.fieldMode ?? 0),
                })}
          </Text>
        </Stack>
        <Separator />
        {props.field.getAttachments(props.links).length === 0 && (
          <Text>{t('kpis:Fields.AttachmentPicker.AttachmentsEmpty')}</Text>
        )}
        <ShimmeredDetailsList
          items={props.field.getAttachments(props.links)}
          columns={columns}
          compact
          enableShimmer={isLoading}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={false}
          onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
        />
      </Stack>
    </GenericModal>
  );
};

//
// Comments
//
export const SingleFormElementComments = (props: ISingleFormElementProps) => {
  const { t } = useTranslation(['translation', 'kpis']);
  const [showCommentEditor, setShowCommentEditor] = useState<boolean>(false);

  const updateComment = (text: string) => {
    if (!props.field.kpi) return;
    const newData = props.field.kpi?.data[0];
    newData.resultComment = text;
    props.onUpdateValue(props.field.kpi, newData);
  };

  if (props.field.kpi?.commentMode && props.field.kpi?.commentMode !== 0 && !props.field.kpi.hasAutomatedEvidence) {
    return (
      <Stack>
        <Link id={`Q${props.field.kpi?.kpiId}CommentCallout`} onClick={() => setShowCommentEditor(true)}>
          {props.field.kpi?.commentMode > 0
            ? t('kpis:Fields.CommentsRequired', { count: props.field.kpi?.commentMode })
            : t('kpis:Fields.CommentsOptional', { count: Math.abs(props.field.kpi?.commentMode) })}
        </Link>
        <EditTextFieldCallOut
          isVisible={showCommentEditor}
          targetId={`Q${props.field.kpi?.kpiId}CommentCallout`}
          value={props.field.kpi?.data[0].resultComment}
          onClose={() => {
            setShowCommentEditor(false);
          }}
          onUpdate={(newText: string) => {
            updateComment(newText);
          }}
          required={props.field.kpi?.commentMode > 0}
          maxLenght={props.field.kpi?.commentMode < 0 ? props.field.kpi?.commentMode : 512}
          minLength={props.field.kpi?.commentMode > 0 ? props.field.kpi?.commentMode : 0}
          readOnly={props.readonly}
        />
      </Stack>
    );
  } else {
    return null;
  }
};

//
// Label with required indication and info on result and instruction texts for KPI
//
export const SingleFormElementInfoKPI = (props: ISingleFormElementProps) => {
  const { t } = useTranslation(['translation', 'kpis']);

  const getText = (): JSX.Element => {
    let frg1: JSX.Element | null = null;
    let frg2: JSX.Element | null = null;

    if (!isEmpty(props.field.kpi?.instruction)) {
      frg1 = (
        <Fragment>
          <Text styles={globalTextStylesBold} block>
            {t('kpis:Fields.Instruction')}
          </Text>
          <Text>{props.field.kpi?.instruction}</Text>
        </Fragment>
      );
    }

    if (!isEmpty(props.field.kpi?.expectedResult)) {
      frg2 = (
        <Fragment>
          <Text styles={globalTextStylesBold} block>
            {t('kpis:Fields.ExpectedResult')}
          </Text>
          <Text>{props.field.kpi?.expectedResult}</Text>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {frg1}
        {frg2 && <Separator />}
        {frg2}
      </Fragment>
    );
  };

  const hasInstructionOrExpectedResult = (): boolean => {
    return !isEmpty(props.field.kpi?.instruction) || !isEmpty(props.field.kpi?.expectedResult);
  };

  const hasText = hasInstructionOrExpectedResult();

  if (props.compact) {
    return (
      <Fragment>
        {hasText && (
          <TextInfo message={getText()}>
            <Stack horizontal>
              <Label>{props.field.kpi?.name}</Label>
              {props.field.kpi?.required && <SingleFormElementRequiredIndication />}
            </Stack>
          </TextInfo>
        )}
        {!hasText && (
          <Stack horizontal>
            <Label>{props.field.kpi?.name}</Label>
            {props.field.kpi?.required && <SingleFormElementRequiredIndication />}
          </Stack>
        )}
      </Fragment>
    );
  } else {
    return (
      <Stack styles={{ root: { marginBottom: 5 } }}>
        <Stack horizontal>
          <Label>{props.field.kpi?.name}</Label>
          {props.field.kpi?.required && <SingleFormElementRequiredIndication />}
        </Stack>
        <Text block>{props.field.kpi?.instruction}</Text>
        <Text>{props.field.kpi?.expectedResult}</Text>
      </Stack>
    );
  }
};

//
// Label with required indication and result and instruction texts for Fields
//
export const SingleFormElementInfoField = (props: ISingleFormElementProps) => {
  const { t } = useTranslation(['translation', 'kpis']);

  const getText = (): JSX.Element => {
    let frg1: JSX.Element | null = null;
    let frg2: JSX.Element | null = null;

    if (!isEmpty(props.field.instruction)) {
      frg1 = (
        <Fragment>
          <Text styles={globalTextStylesBold} block>
            {t('kpis:Fields.Instruction')}
          </Text>
          <Text>{props.field.instruction}</Text>
        </Fragment>
      );
    }

    if (!isEmpty(props.field.expectedResult)) {
      frg2 = (
        <Fragment>
          <Text styles={globalTextStylesBold} block>
            {t('kpis:Fields.ExpectedResult')}
          </Text>
          <Text>{props.field.expectedResult}</Text>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {frg1}
        {frg2 && <Separator />}
        {frg2}
      </Fragment>
    );
  };

  const hasInstructionOrExpectedResult = (): boolean => {
    return !isEmpty(props.field.instruction) || !isEmpty(props.field.expectedResult);
  };

  const hasText = hasInstructionOrExpectedResult();

  if (props.compact) {
    return (
      <Fragment>
        {hasText && (
          <TextInfo message={getText()}>
            <Stack horizontal>
              <Label>{props.field.name}</Label>
              {(props.field.fieldMode ?? 0) > 0 && <SingleFormElementRequiredIndication />}
            </Stack>
          </TextInfo>
        )}
        {!hasText && (
          <Stack horizontal>
            <Label>{props.field.name}</Label>
            {(props.field.fieldMode ?? 0) > 0 && <SingleFormElementRequiredIndication />}
          </Stack>
        )}
      </Fragment>
    );
  } else {
    return (
      <Stack styles={{ root: { marginBottom: 5 } }}>
        <Stack horizontal>
          <Label>{props.field.name}</Label>
          {(props.field.fieldMode ?? 0) > 0 && <SingleFormElementRequiredIndication />}
        </Stack>
        <Text block>{props.field.instruction}</Text>
        <Text>{props.field.expectedResult}</Text>
      </Stack>
    );
  }
};

//
// Required indication
//
export const SingleFormElementRequiredIndication = () => {
  return <Text styles={globalTextStylesError}>{'*'}</Text>;
};
