import { AlignmentType, BorderStyle, Footer, Header, PageNumber, PageOrientation, Paragraph, TextRun } from 'docx';

export enum DocumentColors {
  heading1 = '#6BA6EE',
  heading2 = '#383838',
  border = '#292929',
  cellShading1 = '#d9e8ff',
  cellShading2 = '#e8e8e8',
}

export enum DocumentFonts {
  main = 'Calibri',
}

export const pageLandscape = {
  page: {
    size: {
      orientation: PageOrientation.LANDSCAPE,
    },
  },
};

export const headers = {
  default: new Header({
    children: [],
  }),
};

export const footers = {
  default: new Footer({
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
            font: DocumentFonts.main,
          }),
        ],
      }),
    ],
  }),
};

export const paragraphStyles = [
  {
    id: 'Heading1',
    name: 'Heading 1',
    run: {
      size: 28,
      bold: true,
      color: DocumentColors.heading1,
      font: DocumentFonts.main,
    },
  },
  {
    id: 'Heading2',
    name: 'Heading 2',
    run: {
      size: 28,
      bold: true,
      color: DocumentColors.heading2,
      font: DocumentFonts.main,
    },
  },
  {
    id: 'Normal Text',
    name: 'Normal Text',
    run: {
      size: 22,
      font: DocumentFonts.main,
    },
  },
  {
    id: 'Title',
    name: 'Title',
    run: {
      size: 44,
      bold: true,
      color: '#6BA6EE',
      font: DocumentFonts.main,
    },
  },
  {
    id: 'TableHeading',
    name: 'Table Heading',
    run: {
      bold: true,
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
  {
    id: 'TableDetail',
    name: 'Table Detail',
    run: {
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
  {
    id: 'TableDetailBold',
    name: 'Table Detail Bold',
    run: {
      bold: true,
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
];

export const tableBorders = {
  top: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
  insideHorizontal: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
  insideVertical: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: DocumentColors.border,
  },
};
