import LanguageDTO from './languageDTO';

export default class isoControl_TranslationDTO {
  isoControlId: number;

  languageId: number;

  name: string;

  description?: string;

  instruction?: string;

  lang?: LanguageDTO;

  packageInstructions?: PackageInstructionDTO[];

  constructor() {
    this.name = '';
    this.languageId = 0;
    this.isoControlId = 0;
  }
}

export class PackageInstructionDTO {
  instruction: string;

  packageId: number;

  packageName: string;

  constructor() {
    this.instruction = '';
    this.packageId = 0;
    this.packageName = '';
  }
}
