import {
  DetailsListLayoutMode,
  IColumn,
  IImageProps,
  Image,
  ImageFit,
  Link,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Selection,
  IObjectWithKey,
  CommandBar,
  ICommandBarItemProps,
  IContextualMenuItem,
  ContextualMenuItemType,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import {
  deleteIcon,
  editIcon,
  globalStackItemStylesPaddingSceneScroll,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  newIcon,
  refreshIcon,
} from 'globalStyles';
import Task from 'models/tasks/task';
import Webhook from 'models/webhook';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from 'services/Auth/authConfig';
import { toLocaleDateTimeShort } from 'utils/datetime';
import { sortOnDate, sortOnString } from 'utils/sorting';
import logger from 'services/Logging/logService';
import {
  apiCopyWebhook,
  apiDeleteWebhook,
  apiGetWebhooks,
  apiGetWebhooksForType,
  apiTestWebhook,
  apiUpdateWebhook,
} from 'services/Api/webhookService';
import { DialogOk } from 'components/Dialogs/DialogOk';
import { navigateToExternalUrl } from 'utils/url';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import Config from 'services/Config/configService';
import { LearnMore } from 'components/Notification/Info';
import { globalKB_howToSetupWorkflows } from 'globalConstants';
import ModifyTextDialog from 'components/Dialogs/ModifyTextDialog';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import Delayed from 'components/Utils/Delayed';

interface ITaskWorkflowsProps {
  task: Task;
  onUpdateWorkflows?: (flows: Webhook[]) => void;
}

const TaskWorkflows = (props: ITaskWorkflowsProps) => {
  const { t } = useTranslation(['translation', 'task', 'webhook']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingWebhooks, setIsLoadingWebhooks] = useState<boolean>(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [newWebhookName, setNewWebhookName] = useState<string | undefined>(undefined);
  const [showUpdateNameDialog, setShowUpdateNameDialog] = useState<boolean>(false);
  const [workflows, setWorkflows] = useState<Webhook[]>([]);
  const [allWorkflows, setAllWorkflows] = useState<Webhook[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Webhook | undefined>(undefined);
  const [showWorkflowRunConfirmation, setShowWorkflowRunConfirmation] = useState<Webhook | undefined>(undefined);
  const [selection] = useState<Selection<Webhook>>(
    new Selection({
      selectionMode: SelectionMode.multiple,
      getKey: (item: Webhook) => {
        return item.webhookId;
      },
      onSelectionChanged: () => {
        const selectedItem = selection.getSelection()[0] as Webhook;
        setSelectedWorkflow(selectedItem);
      },
    }),
  );

  const imagePropsMsPALogo: IImageProps = {
    src: `${Config.getImageURL()}/ms-power-automate.png`,
    imageFit: ImageFit.contain,
    width: 128,
    height: 128,
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Load
  //
  const loadData = async () => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let flows: Webhook[] = [];

      flows = await apiGetWebhooksForType('task', props.task.taskId, accessToken);
      flows.sort((a, b) => sortOnDate(a.created, b.created));
      setWorkflows(flows);

      if (props.onUpdateWorkflows) props.onUpdateWorkflows(flows);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Columns
  //
  const columns: IColumn[] = [
    {
      key: 'type',
      name: t('task:Workflows.Columns.Type'),
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      isResizable: true,
      isSorted: false,
      onRender: (item?: Webhook, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text variant="medium">{Webhook.getWebhookTypeText(item?.eventType, t)}</Text>;
      },
    },
    {
      key: 'name',
      name: t('task:Workflows.Columns.Name'),
      minWidth: 100,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      isSorted: false,
      onRender: (item?: Webhook, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text variant="medium">{item?.workflowName}</Text>;
      },
    },
    {
      key: 'created',
      name: t('task:Workflows.Columns.Created'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: false,
      onRender: (item?: Webhook, index?: number, column?: IColumn) => {
        if (!item) return null;

        return <Text>{toLocaleDateTimeShort(item.created)}</Text>;
      },
    },
    {
      key: 'test',
      name: t('task:Workflows.Columns.Test'),
      minWidth: 80,
      maxWidth: 120,
      isResizable: true,
      isSorted: false,
      onRender: (item?: Webhook, index?: number, column?: IColumn) => {
        if (!item) return null;

        return (
          <PrimaryButton
            disabled={!hasUserFeatureGenericManager(appContext)}
            onClick={() => testFlow(item)}
            text={t('task:Workflows.Columns.TestButton')}
          />
        );
      },
    },
  ];

  const testFlow = async (hook: Webhook) => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiTestWebhook(hook, undefined, accessToken);
      setShowWorkflowRunConfirmation(hook);
    } catch (err) {
      logger.debug(t('task:Workflows.RunError'), err);
      appContext.showNotification(t('task:Workflows.RunError'), true);
    }
  };

  const getWorkflowLink = (webhook: Webhook | undefined): string => {
    //https://make.powerautomate.com/environments/Default-f3d70179-6e00-41c9-9c06-3b1771606b98/flows/ea9e984a-fbaf-4b3c-9a46-1987dd7e22fd/details
    return `https://make.powerautomate.com/environments/Default-${appContext.user.tenant.azureTenantId}`;
  };

  const getWebhooksForCopy = async (): Promise<Webhook[]> => {
    try {
      if (isLoadingWebhooks) {
        return allWorkflows;
      }

      setIsLoadingWebhooks(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let flows = await apiGetWebhooks(accessToken);

      flows = flows.filter((f) => Webhook.isTaskType(f.eventType));
      flows.sort((a, b) => sortOnString(a.workflowName, b.workflowName));
      setAllWorkflows(flows);

      return flows;
    } catch (err) {
      appContext.setError(err);

      return allWorkflows;
    } finally {
      setIsLoadingWebhooks(false);
    }
  };

  const removeWebhook = async () => {
    try {
      if (isLoadingWebhooks || !selectedWorkflow) {
        return allWorkflows;
      }

      setIsLoadingWebhooks(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiDeleteWebhook(selectedWorkflow, accessToken);

      const newAllFlows = allWorkflows.filter((w) => w.webhookId !== selectedWorkflow.webhookId);
      setAllWorkflows(newAllFlows);
      const newFlows = workflows.filter((w) => w.webhookId !== selectedWorkflow.webhookId);
      setWorkflows(newFlows);
      setSelectedWorkflow(undefined);
    } catch (err) {
      appContext.setError(err);

      return allWorkflows;
    } finally {
      setIsLoadingWebhooks(false);
    }
  };

  const updateWebhook = async () => {
    try {
      if (isLoadingWebhooks || !selectedWorkflow) {
        return allWorkflows;
      }

      setIsLoadingWebhooks(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      selectedWorkflow.workflowName = newWebhookName;
      await apiUpdateWebhook(selectedWorkflow, accessToken);

      const newAllFlows = allWorkflows.map((w) => (w.webhookId === selectedWorkflow.webhookId ? selectedWorkflow : w));
      setAllWorkflows(newAllFlows);
      const newFlows = workflows.map((w) => (w.webhookId === selectedWorkflow.webhookId ? selectedWorkflow : w));
      setWorkflows(newFlows);
    } catch (err) {
      appContext.setError(err);

      return allWorkflows;
    } finally {
      setIsLoadingWebhooks(false);
    }
  };

  const addWebhook = async (webhook: Webhook) => {
    try {
      if (isLoadingWebhooks) {
        return;
      }

      setIsLoadingWebhooks(true);

      webhook.eventTypeId = props.task.taskId;
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newWebhook = await apiCopyWebhook(webhook, accessToken);

      setWorkflows([...workflows, newWebhook]);
      setSelectedWorkflow(newWebhook);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingWebhooks(false);
    }
  };

  const getWebhookMenuItems = (allFlows: Webhook[], taskFlows: Webhook[]): IContextualMenuItem[] => {
    let menuItems: IContextualMenuItem[] = [];
    const flowsToSelect = allFlows.filter((a) => !taskFlows.some((w) => w.webhookId === a.webhookId));

    menuItems = [
      {
        key: 'header1',
        text: t('task:Workflows.AddHeader'),
        itemType: ContextualMenuItemType.Header,
      },
    ];

    if (flowsToSelect.length > 0) {
      menuItems.push(
        ...flowsToSelect.map((w: Webhook) => {
          return {
            key: w.webhookId.toString(),
            text: (w.workflowName || t('task:Workflows.NoName')) + ` (${toLocaleDateTimeShort(w.created)})`,
            iconProps: { iconName: 'Flow' },
            onClick: () => {
              addWebhook(w);
            },
          };
        }),
      );
    } else {
      menuItems.push({
        key: 'no-items',
        secondaryText: t('task:Workflows.NoItems'),
        onRender: () => {
          if (isLoadingWebhooks) {
            return (
              <Delayed>
                <Stack horizontal tokens={globalStackTokensGapSmall}>
                  <Spinner />
                  <Text>{t('translation:General.Notifications.Loading')}</Text>
                </Stack>
              </Delayed>
            );
          } else {
            return (
              <Link
                styles={{ root: { padding: 10 } }}
                onClick={() => {
                  navigateToExternalUrl(getWorkflowLink(undefined), '', '', true);
                }}
              >
                {t('task:Workflows.NoItems')}
              </Link>
            );
          }
        },
      });
    }

    return menuItems;
  };

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    let items: ICommandBarItemProps[] = [];

    items.push({
      key: 'add',
      disabled: false,
      text: t('task:Workflows.Commands.Add'),
      iconProps: newIcon,
      onKeyDown: () => {
        getWebhooksForCopy();
      },
      onMouseDown: () => {
        getWebhooksForCopy();
      },
      subMenuProps: {
        items: getWebhookMenuItems(allWorkflows, workflows),
      },
    });

    items.push({
      key: 'remove',
      disabled: selectedWorkflow === undefined,
      text: t('task:Workflows.Commands.Remove'),
      iconProps: deleteIcon,
      onClick: () => {
        setShowRemoveDialog(true);
      },
    });

    items.push({
      key: 'updateName',
      disabled: selectedWorkflow === undefined,
      text: t('task:Workflows.Commands.UpdateName'),
      iconProps: editIcon,
      onClick: () => {
        setNewWebhookName(selectedWorkflow?.workflowName);
        setShowUpdateNameDialog(true);
      },
    });

    items.push({
      key: 'refresh',
      disabled: false,
      text: t('task:Workflows.Commands.Refresh'),
      iconProps: refreshIcon,
      onClick: () => {
        loadData();
      },
    });

    return items;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (props.task.taskId === -1) {
    return (
      <Stack
        verticalFill
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { padding: 30 } }}
        tokens={globalStackTokensGapMedium}
      >
        <Image {...imagePropsMsPALogo} />
        <Stack>
          <Text>{t('task:Workflows.NewEventTemplateInfo')}</Text>
          <LearnMore learnMoreLink={globalKB_howToSetupWorkflows} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <CommandBar items={getCommandBarItems()} styles={{ root: { paddingLeft: 0 } }} />
      {props.task.taskId > 0 && workflows.length === 0 && (
        <Stack
          verticalFill
          verticalAlign="center"
          horizontalAlign="center"
          styles={{ root: { padding: 30 } }}
          tokens={globalStackTokensGapMedium}
        >
          <Image {...imagePropsMsPALogo} />
          <Stack>
            <Text>{t('task:Workflows.EditEventTemplateInfo')}</Text>
            <LearnMore learnMoreLink={globalKB_howToSetupWorkflows} />
          </Stack>
        </Stack>
      )}
      {props.task.taskId > 0 && workflows.length > 0 && (
        <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
            <ShimmeredDetailsList
              compact
              items={workflows}
              columns={columns}
              setKey="set"
              isHeaderVisible={true}
              enableShimmer={isLoading}
              selection={selection as Selection<IObjectWithKey>}
              selectionMode={SelectionMode.single}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            />
          </ScrollablePane>
        </Stack.Item>
      )}
      <DialogOk
        title={t('task:Workflows.Dialogs.TestRun.Title')}
        subText={t('task:Workflows.Dialogs.TestRun.SubText')}
        onOk={() => setShowWorkflowRunConfirmation(undefined)}
        hidden={showWorkflowRunConfirmation === undefined}
        optionalJSX={
          <Text>
            <Link onClick={() => navigateToExternalUrl(getWorkflowLink(showWorkflowRunConfirmation), '', '', true)}>
              {t('task:Workflows.Dialogs.TestRun.PowerAutomateLink')}
            </Link>
          </Text>
        }
        optionalJSXHeight={100}
      />
      <ModifyTextDialog
        isOpen={showUpdateNameDialog}
        value={newWebhookName}
        required={true}
        title={t('task:Workflows.Dialogs.UpdateName.Title')}
        placeholder={t('task:Workflows.Dialogs.UpdateName.Placeholder')}
        maxLength={512}
        width={300}
        onClose={() => {
          setShowUpdateNameDialog(false);
          setNewWebhookName(undefined);
        }}
        onSave={updateWebhook}
        onUpdate={(value: string) => setNewWebhookName(value)}
      />
      <DialogYesNo
        hidden={!showRemoveDialog}
        onYes={() => {
          setShowRemoveDialog(false);
          removeWebhook();
        }}
        onNo={() => setShowRemoveDialog(false)}
        title={t('task:Workflows.Dialogs.Remove.Title')}
        subText={t('task:Workflows.Dialogs.Remove.SubText')}
      ></DialogYesNo>
    </Stack>
  );
};

export default TaskWorkflows;
