import { PrimaryButton, Text, DefaultButton, DialogFooter, Modal, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalTextStylesValidationError } from 'globalStyles';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import TimePicker from 'components/Pickers/TimePicker';
import { useState } from 'react';

interface IModifyTextDialogProps {
  isActionPending?: boolean;
  value?: Date;
  isOpen: boolean;
  title: string;
  subTitle?: string;
  label?: string;
  allowChangeTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onClose: () => void;
  onUpdate: (newValue: Date) => void;
  onSave: () => void;
  onGetErrorMessage?: (value: Date | undefined) => string | undefined;
}

const ModifyDateDialog = (props: IModifyTextDialogProps) => {
  const { t } = useTranslation(['translation', 'task']);
  const [error, setError] = useState<string | undefined>(undefined);

  const cannotSave = (): boolean => {
    if (props.isActionPending) return true;
    if (props.onGetErrorMessage && props.onGetErrorMessage(props.value) !== undefined) return true;

    return false;
  };

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onClose}>
      <Stack
        verticalFill
        tokens={globalStackTokensGapMedium}
        styles={{ root: { padding: 20, maxWidth: 400, minWidth: 300 } }}
      >
        <Text variant="xLarge">{props.title}</Text>
        <Text variant="medium">{props.subTitle}</Text>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <Stack.Item grow>
            <LocalizedDatePicker
              label={props.label}
              value={props.value}
              minDate={props.minDate}
              maxDate={props.maxDate}
              showWeekNumbers={true}
              onDateChange={(value) => {
                if (value && props.value) {
                  const newDate = value;
                  newDate.setHours(props.value.getHours());
                  newDate.setMinutes(props.value.getMinutes());
                  props.onUpdate(newDate);
                  if (props.onGetErrorMessage) {
                    setError(props.onGetErrorMessage(newDate));
                  }
                }
              }}
            />
          </Stack.Item>
          {props.allowChangeTime && props.value && (
            <Stack.Item>
              <TimePicker
                pickerWidth={100}
                selectedTime={props.value}
                updateTime={(hours, mins) => {
                  if (props.value) {
                    const newDate = new Date(props.value);
                    newDate.setHours(hours);
                    newDate.setMinutes(mins);
                    props.onUpdate(newDate);
                    if (props.onGetErrorMessage) {
                      setError(props.onGetErrorMessage(newDate));
                    }
                  }
                }}
              />
            </Stack.Item>
          )}
        </Stack>
        <Stack.Item>
          <Text styles={globalTextStylesValidationError}>{error}</Text>
        </Stack.Item>
        <DialogFooter>
          <PrimaryButton
            disabled={cannotSave()}
            onClick={() => {
              props.onSave();
              props.onClose();
            }}
            text={t('translation:General.Button.Save')}
          />
          <DefaultButton
            disabled={props.isActionPending}
            onClick={() => {
              props.onClose();
            }}
            text={t('translation:General.Button.Cancel')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default ModifyDateDialog;
