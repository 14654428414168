export default class LanguageDTO {
  languageId: number;

  code: string;

  name: string;

  constructor() {
    this.languageId = 0;
    this.code = '';
    this.name = '';
  }
}
