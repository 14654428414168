import {
  Site,
  Drive,
  DriveItem,
  List,
  ListItem,
  ItemReference,
  NullableOption,
  IdentitySet,
  PublicationFacet,
} from 'microsoft-graph';

export interface IDrive extends Drive {
  tenantId?: string;
}

export interface IDriveItem extends DriveItem {
  tenantId?: string;
}

export interface IList extends List {
  tenantId?: string;
}

export interface IListItem extends ListItem {
  tenantId?: string;
}

export const getLinkNameFromListItem = (item: IListItem): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields: any = item.fields;

  if (fields && fields.LinkTitle) {
    return fields.LinkTitle;
  }

  if (fields && fields.Title) {
    return fields.Title;
  }

  return '';
};

export interface ISite extends Site {
  tenantId?: string;
}

export interface ISitePage {
  id?: string;
  name: string;
  title?: string;
  webUrl: string;
  tenantId?: string;
  parentReference?: NullableOption<ItemReference>;
  lastModifiedDateTime?: string;
  lastModifiedBy?: NullableOption<IdentitySet>;
  publishingState: PublicationFacet;
  pageLayout: string;
  canvasLayout?: ICanvasLayout;
}

export interface INewSitePage {
  '@odata.type': string;
  name: string;
  title: string;
  pageLayout: string;
  canvasLayout?: ICanvasLayout;
}

export interface IUpdateSitePage {
  '@odata.type': string;
  title?: string;
  canvasLayout?: ICanvasLayout;
}

export interface ICreateUploadSessionResponse {
  uploadUrl: string;
  expirationDateTime: string;
}

//
// TEMP BETA TYPES
//
export enum HorizontalSectionLayoutType {
  none = 'none',
  oneColumn = 'oneColumn',
  twoColumns = 'twoColumns',
  threeColumns = 'threeColumns',
  oneThirdLeftColumn = 'oneThirdLeftColumn',
  oneThirdRightColumn = 'oneThirdRightColumn',
  fullWidth = 'fullWidth',
  unknownFutureValue = 'unknownFutureValue',
}

export enum SectionEmphasisType {
  none = 'none',
  neutral = 'neutral',
  soft = 'soft',
  strong = 'strong',
  unknownFutureValue = 'unknownFutureValue',
}

export interface ICanvasLayout {
  horizontalSections?: IHorizontalSection[];
  verticalSection?: IVerticalSection;
  webParts?: ITextWebPart[]; //old structure of webparts in database
}

export interface IHorizontalSection {
  id?: string;
  layout: HorizontalSectionLayoutType;
  columns: IHorizontalSectionColumn[];
}

export interface IHorizontalSectionColumn {
  id?: string;
  width?: number;
  webparts: ITextWebPart[];
}

export interface IVerticalSection {
  id?: string;
  emphasis?: SectionEmphasisType;
  webparts: ITextWebPart[];
}

export interface ITextWebPart {
  '@odata.type': string;
  id?: string;
  innerHtml: string;
}

export interface IUpdatedTextWebPart extends ITextWebPart {
  updated?: boolean;
}
