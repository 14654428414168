import { FontIcon, Label, Stack, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import {
  globalStackTokensGapExtraSmall,
  globalStackTokensGapLargePlus5,
  globalStackTokensGapMedium,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import TimePicker from 'components/Pickers/TimePicker';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import { toLocaleDateLong, toLocaleTimeNoSeconds } from 'utils/datetime';

interface ITaskDetailStartProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (newTask: Task) => void;
  getErrorMessage: (field: string) => string | undefined;
  loadRescheduleRange?: () => void;
  hideEndTime?: boolean;
}

export const TaskDetailStart = (props: ITaskDetailStartProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Start, appContext);

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
    >
      <Stack.Item>{!appContext.isMobileView && <FontIcon iconName="ReminderTime" style={iconFontSize} />}</Stack.Item>
      <Stack.Item grow>
        <Stack
          horizontal
          tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
        >
          {isReadonly && (
            <Fragment>
              <Stack.Item styles={{ root: { width: '50%' } }}>
                <Label>{t('task:Start.Label')}</Label>
                <Text>{toLocaleDateLong(props.task.startDateTime)}</Text>
              </Stack.Item>
              <Stack.Item styles={{ root: { width: '50%' } }}>
                <Stack horizontal tokens={globalStackTokensGapLargePlus5} horizontalAlign="start">
                  <Stack.Item>
                    <Label>{t('task:Time.Label')}</Label>
                    <Text>{toLocaleTimeNoSeconds(props.task.startDateTime)}</Text>
                  </Stack.Item>
                  <Stack.Item>
                    <Label>{t('task:End.Label')}</Label>
                    <Text>{toLocaleTimeNoSeconds(props.task.endDateTime)}</Text>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Fragment>
          )}
          {!isReadonly && (
            <Fragment>
              <Stack.Item grow>
                <LocalizedDatePicker
                  label={t('task:Start.Label')}
                  showGoToToday={true}
                  showWeekNumbers={true}
                  value={props.task.startDateTime}
                  onDateChange={(newDate) => {
                    if (newDate) {
                      let copyTask = props.task.clone();
                      copyTask.setNewStartDate(newDate);
                      if (props.loadRescheduleRange) props.loadRescheduleRange();
                      props.onUpdate(copyTask);
                    }
                  }}
                  disabled={isReadonly}
                />
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('endDateTime')}
                </Text>
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('startDateTime')}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <TimePicker
                  selectedTime={props.task.startDateTime}
                  label={t('task:Time.Label')}
                  updateTime={(hours: number, mins: number) => {
                    const copyTask = props.task.clone();
                    copyTask.setNewStartTime(hours, mins);
                    if (props.loadRescheduleRange) props.loadRescheduleRange();
                    props.onUpdate(copyTask);
                  }}
                  disabled={isReadonly}
                />
              </Stack.Item>
              {!props.hideEndTime && (
                <Stack.Item>
                  <TimePicker
                    selectedTime={props.task.endDateTime}
                    label={t('task:End.Label')}
                    updateTime={(hours: number, mins: number) => {
                      const copyTask = props.task.clone();
                      copyTask.setNewEndTime(hours, mins);
                      if (props.loadRescheduleRange) props.loadRescheduleRange();
                      props.onUpdate(copyTask);
                    }}
                    disabled={isReadonly}
                  />
                </Stack.Item>
              )}
            </Fragment>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
