import { useContext, useEffect, useState } from 'react';
import { ScrollablePane, Stack, ScrollbarVisibility, IconButton } from '@fluentui/react';
import NavMain from './NavMain';
import AppContext from 'App/AppContext';
import { LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import NavRootOverlay from './NavRootOverlay';

interface INavRootProps {}

export const NavRoot = (props: INavRootProps) => {
  const appContext = useContext(AppContext);
  const [expandedOnHover, setExpandedOnHover] = useState<boolean>(false);

  useEffect(() => {
    setLocalStorageData(appContext, LocalStorageKeys.MainNavCollapsed, appContext.isMainNavCollapsed.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.isMainNavCollapsed]);

  if (expandedOnHover) {
    return (
      <Stack
        onMouseLeave={() => {
          setExpandedOnHover(false);
        }}
        verticalFill
        styles={{
          root: {
            position: 'relative',
            minWidth: 43,
            transition: 'all 0.3s ease',
          },
        }}
      >
        <NavRootOverlay onClose={() => setExpandedOnHover(false)} />
      </Stack>
    );
  } else {
    return (
      <Stack verticalFill>
        <IconButton
          iconProps={{ iconName: 'GlobalNavButton' }}
          onClick={() => appContext.toggleMainNavCollapsed()}
          styles={{ root: { marginLeft: 7 } }}
        ></IconButton>
        <Stack.Item
          grow
          onMouseOver={() => {
            if (appContext.isMainNavCollapsed) {
              setExpandedOnHover(true);
            }
          }}
          onMouseLeave={() => {
            if (appContext.isMainNavCollapsed) {
              setExpandedOnHover(false);
            }
          }}
          styles={{
            root: {
              position: 'relative',
              minWidth: appContext.isMainNavCollapsed && !expandedOnHover ? 43 : 193,
              transition: 'all 0.3s ease',
            },
          }}
        >
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <NavMain collapsed={appContext.isMainNavCollapsed} expandedOnHover={expandedOnHover} />
          </ScrollablePane>
        </Stack.Item>
      </Stack>
    );
  }
};

export default NavRoot;
