import Control from './control';
import KPI from './kpi/kpi';
import Objective from './objective/objective';
import Process from './process/process';
import Theme from './theme';

export default class ControlContext {
  parent: Control | undefined;

  children: Control[];

  controls: Control[];

  themes: Theme[];

  processes: Process[];

  objectives: Objective[];

  kpis: KPI[];

  constructor() {
    this.children = [];
    this.controls = [];
    this.themes = [];
    this.processes = [];
    this.objectives = [];
    this.kpis = [];
  }
}
