import { Fragment, useContext, useEffect, useState } from 'react';
import {
  PrimaryButton,
  Text,
  DefaultButton,
  DialogFooter,
  Modal,
  Stack,
  IconButton,
  Spinner,
  Link,
  Separator,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, cancelIcon, globalStackTokensGapSmall, sharepointIcon } from 'globalStyles';
import Package, { PackageCategories, PackageContent, PackageContentStates, PackageTenantStates } from 'models/package';
import { ISite } from 'services/Graph/SharepointInterfaces';
import AppContext from 'App/AppContext';
import {
  apiRequest,
  graphSharepointLibraryRequest,
  graphSharepointListRequest,
  graphSharepointManageRequest,
  graphSharepointPagesRequest,
} from 'services/Auth/authConfig';
import { EntityTypes } from 'models/entity';
import AppError from 'utils/appError';
import { ResourceListType } from 'models/resourceList';
import { graphGetPagesForSite } from 'services/Graph/graphServicePage';
import { graphGetAllListsForSite } from 'services/Graph/graphServiceList';
import {
  graphGetChildrenForDriveItem,
  graphGetDriveRoot,
  graphGetDrivesForSite,
} from 'services/Graph/graphServiceDrive';
import { Client } from '@microsoft/microsoft-graph-client';
import { DriveItem } from 'microsoft-graph';
import {
  freshdeskWidgetClose,
  freshdeskWidgetOpen,
  freshdeskWidgetSetContactInfo,
  freshdeskWidgetSetTranslations,
} from 'globalFunctions';
import { graphGetSite, graphGetSiteByWebUrl } from 'services/Graph/graphServiceSite';
import { apiSetSetting } from 'services/Api/settingService';
import { SharepointLibraries, SharepointLists, SharepointPages } from 'models/setting';
import { MsConsentButton } from 'components/Buttons/MsButtons';
import { DialogOk } from 'components/Dialogs/DialogOk';
import { globalAppName } from 'globalConstants';
import SharePointIntegrationTestModal from 'components/SharePoint/SharePointIntegrationTestModal';
import WarningMessage from 'components/Notification/WarningMessage';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import { deleteContent } from './DeleteContentFromSharePoint';
import {
  apiDeInstallPackage,
  apiGetInstallPackageContent,
  apiGetInstallPackageProgress,
  apiGetPackageImports,
  apiGetPackageRelationsPreview,
  apiGetPackageSharePointSite,
} from 'services/Api/packageService';
import OverlayLoader from 'components/Loading/OverlayLoader';
import StepIcon, { StepIconModes, StepIconSizes } from 'components/Utils/StepIcon';
import SharepointSitePicker from 'components/SharePoint/SharepointSitePicker';
import StoreItemSharePointInstallConflictsModal from './StoreItemSharePointInstallConflictsModal';
import { getProgressLabelDeInstall } from './DeInstallPackageHelpers';
import InfoMessage from 'components/Notification/InfoMessage';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';

interface IStoreItemSharePointInstallModalProps {
  pack: Package;
  isOpen: boolean;
  selectedSite: ISite | undefined;
  onSelectSite: (site: ISite | undefined) => void;
  onDismiss: () => void;
  onInstall: (contents: PackageContent[]) => void;
  onUpdatePackage?: (pack: Package) => void;
}

const StoreItemSharePointInstallModal = (props: IStoreItemSharePointInstallModalProps) => {
  const { t } = useTranslation(['store', 'translation', 'lists']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
  const [contents, setContents] = useState<PackageContent[]>([]);
  const [skipErrors, setSkipErrors] = useState<boolean>(false);
  const [showConflicts, setShowConflicts] = useState<boolean>(false);
  const [showAuthInfo, setShowAuthInfo] = useState<boolean>(false);
  const [hasManagerAuth, setHasManagerAuth] = useState<boolean | undefined>(undefined);
  const [testManagerAuth, setTestManagerAuth] = useState<boolean>(false);
  const [hasAuthError, setHasAuthError] = useState<boolean | undefined>(undefined);
  const [incompatibleSite, setIncompatibleSite] = useState<boolean>(true);
  const [showDeinstallPreview, setShowDeinstallPreview] = useState<boolean>(false);
  const [isDeInstalling, setIsDeInstalling] = useState<boolean>(false);
  const [deleteProgress, setRemoveProgress] = useState<number>(0);
  const [deleteProgressLabel, setRemoveProgressLabel] = useState<string | undefined>(undefined);
  const [packsToDelete, setPacksToDelete] = useState<Package[]>([]);
  const [showSitePicker, setShowSitePicker] = useState<boolean>(false);
  const [showManagerAuthSuccess, setShowManagerAuthSuccess] = useState<boolean>(false);
  const [packsDeleted, setPacksDeleted] = useState<boolean | undefined>(undefined);
  const [showSharePointPackDeletionError, setShowSharePointPackDeletionError] = useState<boolean>(false);
  const [showSharePointPackDeletionConfirmation, setShowSharePointPackDeletionConfirmation] = useState<boolean>(false);
  const [timerDeInstallProgress, setTimerDeInstallProgress] = useState<NodeJS.Timeout | undefined>(undefined);
  const [currentPackToDelete, setCurrentPackToDelete] = useState<Package | undefined>(undefined);
  const [addOnAutoSiteSelected, setAddOnAutoSiteSelected] = useState<boolean>(false);

  //
  // Effects
  //
  useEffect(() => {
    return () => clearDeInstallProgressTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clear(); //also clear on close
    if (props.isOpen) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.pack]);

  useEffect(() => {
    if (isDeInstalling) {
      resetDeInstallProgressTimer();
    } else {
      clearDeInstallProgressTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeInstalling]);

  //
  // Progress de-install
  //
  const resetDeInstallProgressTimer = () => {
    clearDeInstallProgressTimer();
    const timer = setTimeout(() => getDeInstallProgress(), 350);
    setTimerDeInstallProgress(timer);
  };

  const clearDeInstallProgressTimer = () => {
    if (timerDeInstallProgress) {
      clearTimeout(timerDeInstallProgress);
      setTimerDeInstallProgress(undefined);
    }
  };

  const getDeInstallProgress = async () => {
    try {
      if (!currentPackToDelete) return;

      //determine the target tenant Id for this installation
      const tenantId = appContext.user.login.tenantId;

      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const progress = await apiGetInstallPackageProgress(currentPackToDelete, tenantId, accessToken);
      if (progress) {
        setRemoveProgress(((progress ?? 0) / 10) * 100);
        setRemoveProgressLabel(getProgressLabelDeInstall(progress + 2, '', t));
        resetDeInstallProgressTimer();
      } else {
        setRemoveProgress(0);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  //
  // Load data
  //
  const loadData = async () => {
    if (isBusy()) return;

    try {
      setIsLoading(true);

      //Load packages to delete
      setPacksToDelete([]);
      setPacksDeleted(undefined);
      const newPacksToDelete: Package[] = [];

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const packRelations = await apiGetPackageRelationsPreview(props.pack, accessToken);

      for (let idx = 0; idx < packRelations.length; idx++) {
        const packRel = packRelations[idx];
        if (
          packRel.tenants &&
          packRel.tenants.length > 0 &&
          packRel.tenants[0].state === PackageTenantStates.Activated
        ) {
          newPacksToDelete.push(packRel);
        }
      }

      //sort packs: delete add-ons before starter packages
      newPacksToDelete.sort((a, b) => {
        if (a.categoryId === PackageCategories.AddOn && b.categoryId === PackageCategories.Starter) return -1;
        if (a.categoryId === PackageCategories.Starter && b.categoryId === PackageCategories.AddOn) return 1;

        return 0;
      });

      setPacksToDelete(newPacksToDelete);
      if (newPacksToDelete.length === 0) {
        setPacksDeleted(true);
      }

      //Load site for add-on
      if (props.pack.categoryId === PackageCategories.AddOn) {
        const siteWebUrl = await apiGetPackageSharePointSite(props.pack.packageId, accessToken);
        if (siteWebUrl) {
          const graphInterface = await appContext.getGraphInterface(graphSharepointPagesRequest.scopes, undefined);
          const site = await graphGetSiteByWebUrl(graphInterface.client, siteWebUrl);
          if (site) {
            setAddOnAutoSiteSelected(true);
            props.onSelectSite(site);
            await loadContentState(site);
          }
        }
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadContentState = async (selectedSite: ISite | undefined) => {
    if (isBusy()) return;
    if (!selectedSite?.id) return;

    try {
      setIsLoadingContent(true);

      const contents = [...props.pack.contents];
      contents.forEach((c) => {
        c.state = PackageContentStates.Ok;
        c.webUrl = undefined;
      });

      //get site, pages and drives
      let graphInterface = await appContext.getGraphInterface(
        graphSharepointPagesRequest.scopes,
        selectedSite.tenantId,
      );
      const site = await graphGetSite(graphInterface.client, selectedSite.id);
      const existingDrives = await graphGetDrivesForSite(graphInterface.client, selectedSite.id);
      const pages = await graphGetPagesForSite(graphInterface.client, selectedSite.id, false);

      //check page names
      //each page name should not exist
      for (let idx = 0; idx < contents.length; idx++) {
        const content = contents[idx];
        if (content.sourceEntityType === EntityTypes.Link) {
          const link = props.pack.sharePointLinks.find((l) => l.linkId.toString() === content.sourceEntityId);
          if (!link) {
            throw new AppError(`Package corrupt: cannot match content (${content.sourceEntityId}) with link for page`);
          }
          if (link.list.listType === ResourceListType.SitePageLibrary) {
            const existingPage = pages.find((p) => p.name.toLowerCase() === content.name.toLowerCase());
            if (existingPage) {
              content.state = PackageContentStates.NameConflict;
              content.webUrl = site.webUrl + '/' + existingPage.webUrl;
            }
          }
        }
      }

      //check lists
      //in SharePoint, each list must have a unique name.
      //Document libraries are also lists but they may exist because content is placed inside them
      graphInterface = await appContext.getGraphInterface(graphSharepointListRequest.scopes, selectedSite.tenantId);
      const lists = await graphGetAllListsForSite(graphInterface.client, selectedSite.id);

      for (let idx = 0; idx < contents.length; idx++) {
        const content = contents[idx];
        if (content.sourceEntityType === EntityTypes.List && !content.contentParentId) {
          const existingList = lists.find((l) => l.name?.toLowerCase() === content.name.toLowerCase());
          if (existingList && existingList.list?.template !== 'documentLibrary') {
            content.state = PackageContentStates.NameConflict;
            content.webUrl = existingList.webUrl || undefined;
          }
        }
      }

      //check document folder structure
      //document libraries may exist so content can be placed inside.
      //the root folders of the package should not exist
      graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes, selectedSite.tenantId);

      for (let idx = 0; idx < contents.length; idx++) {
        const content = contents[idx];
        if (content.sourceEntityType === EntityTypes.NotSet && !content.contentParentId) {
          const existingList = lists.find((d) => d.name?.toLowerCase() === content.name.toLowerCase());
          if (existingList) {
            //find the drive that belongs to the list
            const existingDrive = existingDrives.find((d) => d.name?.toLowerCase() === content.name.toLowerCase());
            if (existingDrive && existingDrive.id) {
              //get the root drive item to check the folder structure.
              const rootItem = await graphGetDriveRoot(graphInterface.client, existingDrive.id);
              if (rootItem) {
                await checkFolderStructure(graphInterface.client, rootItem, content);
              } else {
                throw new AppError('Could not get root item of drive: ' + content.name.toLowerCase());
              }
            } else {
              throw new AppError('Could not find existing drive for existing list: ' + content.name.toLowerCase());
            }
          }
        }
      }

      for (let idx = 0; idx < contents.length; idx++) {
        const content = contents[idx];
        if (content.hasParentConflict(contents)) {
          content.state = PackageContentStates.ParentHasConflict;
        }
      }

      //sort on errors on top
      contents.sort((a, b) => {
        const result = b.state - a.state;
        if (result !== 0) return result;

        return a.name.localeCompare(b.name);
      });

      setIncompatibleSite(false);
      setHasAuthError(false);
      setContents(contents);

      if (props.pack.categoryId === PackageCategories.AddOn) {
        setSkipErrors(true);
      }
    } catch (err) {
      if (err instanceof AppError && err.code === 'itemNotFound') {
        setIncompatibleSite(true);
      } else {
        const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
        if (graphErrorMsg) {
          appContext.showNotification(graphErrorMsg, true)
          setHasAuthError(true);
        } else {
          appContext.setError(err);
        }
      }
    } finally {
      setIsLoadingContent(false);
    }
  };

  const checkFolderStructure = async (client: Client, root: DriveItem, content: PackageContent) => {
    //the root folders should not exist.
    if (!root.id || !root.parentReference?.driveId) return;

    const childs = props.pack.contents.filter((c) => c.contentParentId === content.contentId);
    const driveItems = await graphGetChildrenForDriveItem(client, root.parentReference?.driveId, root.id);
    for (let idx = 0; idx < childs.length; idx++) {
      const child = childs[idx];
      const existingFolder = driveItems.find((d) => d.name?.toLowerCase() === child.name.toLowerCase());
      if (existingFolder) {
        child.state = PackageContentStates.NameConflict;
        child.webUrl = existingFolder.webUrl || undefined;
      }
    }
  };

  //
  // Install
  //
  const onInstall = async () => {
    //we now have manager rights on SharePoint
    //we should make sure that all SharePoint integrations are enabled
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    await enableSetting(SharepointLibraries, accessToken);
    await enableSetting(SharepointLists, accessToken);
    await enableSetting(SharepointPages, accessToken);

    props.onInstall(contents);
  };

  const enableSetting = async (setting: string, accessToken: string) => {
    try {
      const currentSetting = appContext.globalDataCache.settings.get(setting) as boolean;
      if (!currentSetting) {
        let newSetting = await apiSetSetting(setting, true, accessToken);
        if (newSetting.settingValue) {
          appContext.globalDataCache.settings.set(setting, true);
        }
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const openTicket = () => {
    if (props.isOpen) {
      freshdeskWidgetOpen();
    } else {
      freshdeskWidgetClose();
    }

    freshdeskWidgetSetContactInfo(appContext.user.name, appContext.user.email);
    freshdeskWidgetSetTranslations();
  };

  const consentManagerAuth = async () => {
    try {
      const graphInterface = await appContext.getGraphInterface(graphSharepointManageRequest.scopes, undefined);
      if (graphInterface) {
        setHasManagerAuth(true);
        setShowManagerAuthSuccess(true);
      } else {
        setHasManagerAuth(false);
      }
    } catch (err) {
      setHasManagerAuth(false);
    }
  };

  //
  // De-install
  //
  const setRemovePackageProgress = (progress: number) => {
    setRemoveProgress((progress / 10) * 100);
    setRemoveProgressLabel(getProgressLabelDeInstall(progress, '', t));
  };

  const setRemovePackageProgressLabelCallback = (label: string) => {
    setRemoveProgressLabel(getProgressLabelDeInstall(2, label, t));
  };

  const startDeInstallPreview = async () => {
    try {
      if (packsToDelete.length === 0) return;
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      for (let idx = 0; idx < packsToDelete.length; idx++) {
        let packRel = packsToDelete[idx];
        setRemovePackageProgress(1);
        setCurrentPackToDelete(packRel);

        //get package content
        packRel = await apiGetInstallPackageContent(packRel, accessToken);
        packRel.imports = await apiGetPackageImports(packRel, accessToken);

        //remove package from SharePoint
        setRemovePackageProgress(2);
        const hasError = await deleteContent(
          packRel,
          packRel.imports,
          packRel.contents,
          appContext,
          setRemovePackageProgressLabelCallback,
          t,
        );

        //remove package from back-end
        setIsDeInstalling(true);
        packRel = await apiDeInstallPackage(packRel, accessToken);

        //update package state in Store
        if (props.onUpdatePackage) {
          props.onUpdatePackage(packRel);
        }

        //update state in step
        if (hasError) {
          setShowSharePointPackDeletionError(true);
          setPacksDeleted(false);
        } else {
          setPacksDeleted(true);
        }
      }
    } catch (err) {
      appContext.setError(err);
      setPacksDeleted(false);
    } finally {
      setRemoveProgress(0);
      setIsDeInstalling(false);
    }
  };

  //
  // Status helpers
  //
  const clear = () => {
    setIncompatibleSite(false);
    setContents([]);
    setIsLoading(false);
    setIsLoadingContent(false);
    setSkipErrors(false);
    setHasAuthError(undefined);
    setHasManagerAuth(undefined);
    setCurrentPackToDelete(undefined);
    setRemoveProgress(0);
    setRemoveProgressLabel(undefined);
    setIsDeInstalling(false);
    setPacksDeleted(undefined);
    setPacksToDelete([]);
    setTimerDeInstallProgress(undefined);
    setAddOnAutoSiteSelected(false);
    props.onSelectSite(undefined);
  };

  const hasSiteErrors = () => {
    return (
      !isBusy() &&
      (incompatibleSite || hasAuthError || (!skipErrors && contents.some((c) => c.state !== PackageContentStates.Ok)))
    );
  };

  const hasSiteConflicts = () => {
    return !isBusy() && contents.some((c) => c.state !== PackageContentStates.Ok);
  };

  const isBusy = (): boolean => {
    return isLoading || isLoadingContent || deleteProgress > 0;
  };

  const canInstall = (): boolean => {
    if (hasManagerAuth !== true) return false; //no authorization
    if (packsToDelete.length > 0 && packsDeleted === undefined) return false; //removal preview package not run yet. does not matter whether success or error
    if (!props.selectedSite || hasSiteErrors()) return false; //no selected site or selected site has errors
    if (isBusy()) return false; //loading data, content or delete progress
    if (appContext.error) return false; //disable install when there is an unknown error registered at app level

    return true;
  };

  //
  // Main render
  //
  if (isLoading) {
    return <OverlayLoader text={t('translation:General.Notifications.Loading')} />;
  }

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onDismiss}>
      {deleteProgress > 0 && (
        <OverlayLoader
          progressPercent={deleteProgress / 100}
          showProgress={true}
          text={t('store:TabStore.Install.Dialogs.DeinstallPreview.Progress')}
          progressLabel={deleteProgressLabel}
        />
      )}
      <Stack
        verticalFill
        styles={{ root: { height: '90vh', width: 1000, minWidth: 320, maxWidth: '90vw', padding: 20 } }}
        tokens={globalStackTokensGapMedium}
      >
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xxLarge">{props.pack.name}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.onDismiss} />
        </Stack>
        <Stack.Item>
          <Text block>
            {t('store:TabStore.Install.Dialogs.SharePointConfig.Info', { appName: globalAppName })}&nbsp;
          </Text>
        </Stack.Item>
        <Separator />
        <Stack.Item>
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <StepIcon
                index={1}
                mode={
                  hasManagerAuth === undefined
                    ? StepIconModes.Active
                    : hasManagerAuth === true
                    ? StepIconModes.Success
                    : StepIconModes.Error
                }
                size={StepIconSizes.Medium}
              />
            </Stack.Item>
            <Stack.Item>
              <MsConsentButton
                disabled={false}
                onClick={consentManagerAuth}
                text={t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonConsent')}
              />
              <DialogOk
                hidden={!showManagerAuthSuccess}
                title={t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonConsentSuccessDialogTitle')}
                subText={t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonConsentSuccessDialogSubText', {
                  appName: globalAppName,
                })}
                onOk={() => setShowManagerAuthSuccess(false)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Link onClick={() => setShowAuthInfo(true)}>
                  {t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonInfoLink')}
                </Link>
                <DialogOk
                  hidden={!showAuthInfo}
                  title={t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonConsent')}
                  subText={t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonInfo', {
                    appName: globalAppName,
                  })}
                  onOk={() => setShowAuthInfo(false)}
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {packsToDelete.length > 0 && (
          <Fragment>
            <Separator />
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <StepIcon
                    index={2}
                    mode={
                      hasManagerAuth !== true
                        ? StepIconModes.NotReached
                        : packsDeleted === undefined
                        ? StepIconModes.Active
                        : packsDeleted === true
                        ? StepIconModes.Success
                        : StepIconModes.Error
                    }
                    size={StepIconSizes.Medium}
                  />
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton
                    iconProps={{ iconName: 'Preview' }}
                    disabled={hasManagerAuth !== true || deleteProgress > 0 || packsDeleted === true}
                    text={t('store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackages')}
                    styles={{ root: { height: 40 } }}
                    onClick={() => setShowSharePointPackDeletionConfirmation(true)}
                  ></PrimaryButton>
                  <DialogYesNo
                    hidden={!showSharePointPackDeletionConfirmation}
                    title={t('store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesConfirmDialogTitle')}
                    subText={t(
                      'store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesConfirmDialogSubText',
                    )}
                    optionalJSX={
                      <ul>
                        {packsToDelete.map((p) => {
                          return <li>{p.name}</li>;
                        })}
                      </ul>
                    }
                    onNo={() => setShowSharePointPackDeletionConfirmation(false)}
                    onYes={() => {
                      setShowSharePointPackDeletionConfirmation(false);
                      startDeInstallPreview();
                    }}
                  />
                  <DialogOk
                    hidden={!showSharePointPackDeletionError}
                    title={t('store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesErrorDialogTitle')}
                    subText={t(
                      'store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesErrorDialogSubText',
                    )}
                    onOk={() => setShowSharePointPackDeletionError(false)}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Fragment>
        )}
        <Separator />
        <Stack.Item grow>
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <StepIcon
                index={packsToDelete.length > 0 ? 3 : 2}
                mode={
                  hasManagerAuth !== true || packsDeleted === undefined
                    ? StepIconModes.NotReached
                    : !props.selectedSite || isBusy()
                    ? StepIconModes.Active
                    : hasSiteErrors()
                    ? StepIconModes.Error
                    : StepIconModes.Success
                }
                size={StepIconSizes.Medium}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Stack horizontal tokens={globalStackTokensGapSmall}>
                    <Stack.Item>
                      <PrimaryButton
                        disabled={hasManagerAuth !== true || packsDeleted === undefined}
                        iconProps={sharepointIcon}
                        text={t('store:TabStore.Install.Dialogs.SharePointConfig.SelectSite')}
                        styles={{ root: { height: 40 } }}
                        onClick={() => setShowSitePicker(true)}
                      ></PrimaryButton>
                      <SharepointSitePicker
                        isOpen={showSitePicker}
                        hideContent={true}
                        onSelect={(site: ISite) => {
                          props.onSelectSite(site);
                          setSkipErrors(false);
                          loadContentState(site);
                        }}
                        close={() => {
                          setShowSitePicker(false);
                          setTestManagerAuth(true);
                        }}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Stack>
                        <Stack.Item>
                          <Stack horizontal tokens={globalStackTokensGapSmall}>
                            <Stack.Item>
                              <Text>
                                <Link href={props.selectedSite?.webUrl ?? undefined} target="_blank">
                                  {props.selectedSite?.displayName}
                                </Link>
                              </Text>
                            </Stack.Item>
                            <Stack.Item>{isLoadingContent && <Spinner></Spinner>}</Stack.Item>
                          </Stack>
                        </Stack.Item>
                        <Link disabled={!props.selectedSite} onClick={() => setTestManagerAuth(true)}>
                          {t('store:TabStore.Install.Dialogs.SharePointConfig.AuthButtonTest')}
                        </Link>
                        <SharePointIntegrationTestModal
                          isOpen={testManagerAuth}
                          selectedSite={props.selectedSite}
                          onClose={() => setTestManagerAuth(false)}
                          onResult={(success: boolean) => setHasAuthError(!success)}
                        />
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {!isBusy() && incompatibleSite && (
                  <Stack.Item>
                    <WarningMessage
                      message={t('store:TabStore.Install.Dialogs.SharePointConfig.IncompatibleSiteWarning')}
                    />
                  </Stack.Item>
                )}
                {hasSiteConflicts() && props.pack.categoryId !== PackageCategories.AddOn && (
                  <Stack.Item>
                    <WarningMessage
                      message={t('store:TabStore.Install.Dialogs.SharePointConfig.ContentConflictWarning')}
                    >
                      <Link onClick={() => setShowConflicts(true)}>
                        {t('store:TabStore.Install.Dialogs.SharePointConfig.ShowContentDetails')}
                      </Link>
                    </WarningMessage>
                  </Stack.Item>
                )}
                {addOnAutoSiteSelected && (
                  <Stack.Item>
                    <InfoMessage
                      message={t('store:TabStore.Install.Dialogs.SharePointConfig.AddOnSiteMsg')}
                    ></InfoMessage>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Separator />
        <Stack.Item>
          <Link onClick={() => openTicket()}>{t('store:TabStore.Install.Dialogs.SharePointConfig.Servicedesk')}</Link>
        </Stack.Item>
        <DialogFooter>
          <PrimaryButton
            className="redlab-usetiful-store-install-activate"
            disabled={!canInstall()}
            onClick={() => {
              onInstall();
            }}
            text={t('store:TabStore.Install.Dialogs.SharePointConfig.ActivateButton')}
          />
          <DefaultButton
            disabled={false}
            onClick={() => {
              props.onDismiss();
            }}
            text={t('translation:General.Button.Close')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
      <DialogYesNo
        hidden={!showDeinstallPreview}
        title={t('store:TabStore.Install.Dialogs.DeinstallPreview.Title')}
        subText={t('store:TabStore.Install.Dialogs.DeinstallPreview.SubText', {
          name: packsToDelete.map((p) => p.name).join(', '),
        })}
        onNo={() => setShowDeinstallPreview(false)}
        onYes={() => {
          setShowDeinstallPreview(false);
          startDeInstallPreview();
        }}
      />
      <StoreItemSharePointInstallConflictsModal
        pack={props.pack}
        selectedSite={props.selectedSite}
        skipErrors={skipErrors}
        contents={contents}
        hasConflicts={hasSiteConflicts()}
        isOpen={showConflicts}
        onDismiss={() => setShowConflicts(false)}
        onRefresh={() => {
          loadContentState(props.selectedSite);
        }}
        onSkipErrors={setSkipErrors}
        isLoading={isBusy()}
      />
    </Modal>
  );
};

export default StoreItemSharePointInstallModal;
