import LanguageDTO from '../languageDTO';

export class Classification_TranslationDTO {
  classificationId: number;

  languageId: number;

  name: string;

  description?: string;

  examples?: string;

  lang?: LanguageDTO;

  constructor() {
    this.classificationId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
