import { fromApiDateTimeOptional, fromApiDateTimeRequired, toApiDateRequired } from 'utils/datetime';
import WebhookDTO, { WebhookRunDTO } from 'models/dto/webhookDTO';
import Webhook, { WebhookRun } from 'models/webhook';

export function mapFromWebhooks(webhooks: WebhookDTO[]): Webhook[] {
  return webhooks.map((w) => mapFromWebhook(w));
}

export function mapFromWebhookRuns(webhookRuns: WebhookRunDTO[]): WebhookRun[] {
  return webhookRuns.map((w) => mapFromWebhookRun(w));
}

export function mapFromWebhook(webhook: WebhookDTO): Webhook {
  const output = new Webhook();

  output.webhookId = webhook.webhookId;
  output.workflowName = webhook.workflowName;
  output.eventType = webhook.eventType;
  output.eventTypeId = webhook.eventTypeId;
  output.created = fromApiDateTimeRequired(webhook.created);
  output.active = webhook.active;
  output.lastRun = fromApiDateTimeOptional(webhook.lastRun);
  output.runCount = webhook.runCount;
  output.failedCount = webhook.failedCount;
  output.error = webhook.error;

  return output;
}

export function mapToWebhook(webhook: Webhook): WebhookDTO {
  const output = new WebhookDTO();

  output.webhookId = webhook.webhookId;
  output.workflowName = webhook.workflowName;
  output.eventType = webhook.eventType;
  output.eventTypeId = webhook.eventTypeId;
  output.active = webhook.active;
  output.created = toApiDateRequired(new Date());

  return output;
}

export function mapFromWebhookRun(webhookRun: WebhookRunDTO): WebhookRun {
  const output = new WebhookRun();

  output.webhookId = webhookRun.webhookId;
  output.webhookRunId = webhookRun.webhookRunId;
  output.created = fromApiDateTimeRequired(webhookRun.created);
  output.workflowId = webhookRun.workflowId;
  output.workflowRunId = webhookRun.workflowRunId;
  output.entityId = webhookRun.entityId;
  output.webhook = webhookRun.webhook ? mapFromWebhook(webhookRun.webhook) : undefined;

  return output;
}
