import { ControlAlertStats, ControlTaskStats } from './control';
import Risk from './risk';

export class RiskControlTasks {
  risk: Risk | undefined;

  controlTaskStats: ControlTaskStats[];

  controlAlertStats: ControlAlertStats[];

  constructor() {
    this.risk = undefined;
    this.controlTaskStats = [];
    this.controlAlertStats = [];
  }
}
