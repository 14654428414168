import { newGuidNil } from 'utils/guid';
import { SubscriptionTypes } from 'utils/subscription';
import { StoreMgmtStates } from './../adminTenant';
import SettingDTO from './settingDTO';

export default class TenantDTO {
  id: string;

  parentId?: string;

  topLevelParentTenantId: string;

  azureTenantId?: string;

  name: string;

  created?: Date;

  storeMgmtState: number;

  onboardingState?: TenantOnboardingDTO;

  subscriptionType: number;

  settings?: SettingDTO[];

  timeZoneCode?: string;

  currentUserLicensed?: boolean;

  logo?: Blob;

  constructor() {
    this.id = newGuidNil();
    this.topLevelParentTenantId = newGuidNil(); //default same as id
    this.name = 'unknown';
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
  }
}

export class TenantOnboardingDTO {
  packageIds: string[];

  leadSource?: string;

  onboardingSteps: TenantOnboardingStepsDTO;

  constructor() {
    this.packageIds = [];
    this.onboardingSteps = new TenantOnboardingStepsDTO();
  }
}

export class TenantOnboardingStepsDTO {
  step1: boolean;

  step2: boolean;

  step3: boolean;

  constructor() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
  }
}

export class ImportOrgUnitDTO {
  sourceId: string;

  targetId: string;

  constructor() {
    this.sourceId = '';
    this.targetId = '';
  }
}
