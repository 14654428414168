import User from 'models/user';
import { newGuidNil } from 'utils/guid';
import Activity from './activity';
import Group from './group';
import Language from './language';
import Task from './tasks/task';
import Widget from './widget';

export enum DashboardTypes {
  user = 0,
  team = 1,
  org = 2,
  process = 3,
  objective = 4,
  control = 5,
  risk = 6, //virtual, not known by back-end. contains widgets of all related dashboard of type 'control'
}

export default class Dashboard {
  dashboardId: number;

  name: string;

  description?: string;

  dashboardType: DashboardTypes;

  created: Date;

  createdById: string;

  createdBy?: User;

  sortOrder: number;

  widgets: Widget[];

  users?: User[];

  groups?: Group[];

  trans: Dashboard_Translation[];

  transIdx: number;

  isSystem: boolean;

  url?: string;

  constructor(type: DashboardTypes) {
    this.created = new Date();
    this.createdById = newGuidNil();
    this.dashboardId = 0;
    this.sortOrder = -1;
    this.name = '';
    this.dashboardType = type;
    this.widgets = [];
    this.transIdx = -1;
    this.trans = [];
    this.isSystem = false;
  }

  isShared(): boolean {
    if (this.dashboardType === DashboardTypes.team) {
      if (this.users && this.users.length > 0) return true;
    } else if (this.dashboardType === DashboardTypes.user) {
      if (this.users && this.users.length > 1) return true;
    }

    return false;
  }

  isSharedWithUser(userId: string): boolean {
    if (this.createdById === userId) return false;
    if (this.dashboardType !== DashboardTypes.org) {
      if (this.users && this.users.find((u) => u.id === userId)) return true;
    }

    return false;
  }

  clone(): Dashboard {
    const newDashboard = new Dashboard(this.dashboardType);
    newDashboard.created = new Date(this.created);
    newDashboard.createdBy = this.createdBy;
    newDashboard.createdById = this.createdById;
    newDashboard.sortOrder = this.sortOrder;
    newDashboard.dashboardId = this.dashboardId;
    newDashboard.description = this.description;
    newDashboard.name = this.name;
    newDashboard.groups = this.groups ? [...this.groups] : undefined;
    newDashboard.users = this.users ? [...this.users] : undefined;
    newDashboard.trans = [...this.trans];
    newDashboard.transIdx = this.transIdx;
    newDashboard.widgets = [...this.widgets];
    newDashboard.url = this.url;
    
    return newDashboard;
  }
}

export class Dashboard_Translation {
  dashboardId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.dashboardId = 0;
    this.languageId = 0;
    this.name = '';
  }
}

//
// Shared data sets
//
export enum DashboardSharedDataSets {
  Activities = 0,
  MyTasks = 1,
}

export class DashboardSharedData {
  activities?: Activity[];

  myTasks?: Task[];
}
