export class RiskLinkDTO {
  linkId: number;

  riskId: number;

  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    this.linkId = -1;
    this.riskId = -1;
    this.pinned = false;
  }
}
