import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DefaultButton,
  IconButton,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TooltipHost,
  Link,
  IPivotItemProps,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackItemStylesPaddingSmall,
  globalStackStylesHeight100,
  globalStackTokensGapExtraSmall,
  globalTextStylesBold,
} from 'globalStyles';
import Task, { TaskTypes, TaskWorkflowStatus } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { CheckList } from 'components/Checklist/Checklist';
import Joi from 'joi';
import ChecklistProgress from 'components/Checklist/ChecklistProgress';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AuditTrail from 'components/Audit/AuditTrail';
import TaskLinkList from 'components/Links/LinkLists/TaskLinkList';
import { ToastContainer } from 'react-toastify';
import { ISingleTaskTypeProps } from '../SingleTask';
import { CheckListFollowUp } from 'components/Checklist/ChecklistFollowUp';
import { globalToastDelay } from 'globalConstants';
import ActivityTrail from 'components/Activity/ActivityTrail';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { TaskContext } from '../TaskContext';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import TaskTopTasksBar from '../TaskTopTasksBar';
import TaskTypeForm from '../Forms/TaskTypeForm';
import { EventTaskDetail } from './EventTaskDetail';
import { SystemTaskTypes } from 'models/tasks/taskType';
import { ApprovalTaskDetail } from './SystemTaskTypes/ApprovalTaskDetail';
import InfoMessage from 'components/Notification/InfoMessage';
import WarningMessage from 'components/Notification/WarningMessage';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetWebhooksForType } from 'services/Api/webhookService';
import TaskWorkflowDetailsModal from './TaskWorkflowDetailsModal';
import { apiGetSingleTask } from 'services/Api/taskService';
import { DialogOk } from 'components/Dialogs/DialogOk';
import { TaskCheckListItem, TaskCheckList } from 'models/tasks/taskHelperClasses';
import { TaskDetailOwner } from './Details/TaskDetailOwner';
import { getTaskViewModalStackStyles, getTaskViewModalStyles } from '../View/TaskRenderHelpers';
import { canUpdateTaskField, TaskFieldTypes } from '../TaskAuthHelper';

interface IEventTaskProps extends ISingleTaskTypeProps {
  onSaveAsNewTask: () => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

const EventTask = (props: IEventTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [newEventHasWorkflows, setNewEventHasWorkflows] = useState<boolean>(false);
  const [err, setErr] = useState<Joi.ValidationError | undefined>(undefined);
  const [selectedPivot, setSelectedPivot] = useState<string>(props.selectedPivot ?? 'Checklist');
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [showWorkflowDetails, setShowWorkflowDetails] = useState<boolean>(false);
  const [webhooksLoadedForTemplateId, setWebhooksLoadedForTemplateId] = useState<number>(0);
  const [formMode, setFormMode] = useState<boolean | undefined>(props.formMode);
  const [showNotAllSubtasksCompletedDialog, setShowNotAllSubtasksCompletedDialog] = useState<boolean>(false);

  useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const loadWebhooks = async () => {
      try {
        if (!props.task.templateId) return;
        //check if the template that created this event has connected webhooks (which trigger a workflow)
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const webHooks = await apiGetWebhooksForType('task', props.task.templateId, accessToken);
        setNewEventHasWorkflows(webHooks.length > 0);
      } catch (err) {
        appContext.setError(err);
      }
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);

    if (props.task.taskId === -1 && props.task.templateId && props.task.templateId !== webhooksLoadedForTemplateId) {
      setWebhooksLoadedForTemplateId(props.task.templateId);
      loadWebhooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  useEffect(() => {
    if (appContext.isMobileView) {
      setSelectedPivot('TaskDetails');
      setFormMode(false);
    } else if (props.task.taskTypeId && props.hasFormFeature) {
      setSelectedPivot('TaskType');
    } else {
      setSelectedPivot('Checklist');
    }
  }, [appContext.isMobileView, props.hasFormFeature, props.task.taskTypeId]);

  useEffect(() => {
    //Single task requests to show a specific pivot
    if (props.selectedPivot) {
      setSelectedPivot(props.selectedPivot);
    }
  }, [props.selectedPivot]);

  useEffect(() => {
    props.onBeforeSaveCallback(onBeforeSave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Validate
  //
  const onBeforeSave = (task: Task): Task | undefined => {
    //when not all subtasks are completed, prevent completion of the event
    if (task.isCompleted() && task.isSeries() && task.instances) {
      for (let idx = 0; idx < task.instances.length; idx++) {
        if (!task.instances[idx].isCompleted()) {
          setShowNotAllSubtasksCompletedDialog(true);
          task.taskStateId = props.orgTask.taskStateId;

          return undefined;
        }
      }
    }

    return task;
  };

  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      return fieldError ? fieldError.message : undefined;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
  };

  const getIsOutlookCreateDisabled = (): boolean => {
    if (props.task.isSeries()) {
      const instancesCanBeScheduled = props.task.instances?.filter(
        (i) =>
          props.task.isAssigned() &&
          !props.task.isScheduled() &&
          canUpdateTaskField(i, props.canUpdate, TaskFieldTypes.OutlookCreate, appContext),
      );

      return (
        hasErrors() ||
        props.isActionPending ||
        instancesCanBeScheduled?.length === 0 || //at least one instance must be assigned and not scheduled yet
        (props.disallowSchedule ?? false)
      );
    } else {
      return (
        hasErrors() ||
        props.isActionPending ||
        !props.task.isAssigned() ||
        (props.disallowSchedule ?? false) ||
        (props.disallowSave ?? false) || //do not allow to schedule subtasks when the parent event is open: parent will schedule in outlook
        !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookRemove, appContext)
      );
    }
  };

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const addCommentFromCheckList = (item: TaskCheckListItem) => {
    setSelectedPivot('Comments');
  };

  const onUpdateCheckList = (newCheckList: TaskCheckList) => {
    let copyTask = props.task.clone();
    copyTask.checkList = newCheckList;
    props.onUpdateTask(copyTask);
  };

  const onToggleTaskFollowUp = () => {
    let copyTask = props.task.clone();
    copyTask.followUp = copyTask.followUp ? false : true;
    props.onUpdateTask(copyTask);
  };

  const getTaskDetails = () => {
    switch (props.task.systemTaskType) {
      case SystemTaskTypes.Approval:
        return (
          <ApprovalTaskDetail
            task={props.task}
            orgTask={props.orgTask}
            onUpdate={(newTask: Task) => {
              props.onUpdateTask(newTask);
            }}
            getErrorMessage={validate}
            isActionPending={props.isActionPending}
            tags={props.tags}
            addTagToTaskState={props.addTagToTaskState}
            removeTagFromTaskState={props.removeTagFromTaskState}
            onChangeCompletionDate={props.onChangeCompletionDate}
            windowLevel={props.windowLevel}
            canUpdate={props.canUpdate}
          />
        );
      default:
        return (
          <EventTaskDetail
            task={props.task}
            orgTask={props.orgTask}
            onUpdate={(newTask: Task) => {
              props.onUpdateTask(newTask);
            }}
            getErrorMessage={validate}
            isActionPending={props.isActionPending}
            tags={props.tags}
            addTagToTaskState={props.addTagToTaskState}
            removeTagFromTaskState={props.removeTagFromTaskState}
            onChangeCompletionDate={props.onChangeCompletionDate}
            windowLevel={props.windowLevel}
            navigateExternal={props.navigateExternal}
            canUpdate={props.canUpdate}
          />
        );
    }
  };

  const onClickFinish = () => {
    const newTask = props.task.clone();
    newTask.setComplete();
    props.onUpdateTask(newTask);
    props.onSave(newTask, false);
  };

  //
  // Render helpers
  //
  const getWorkflowTaskMessage = (newEventHasWorkflows: boolean): JSX.Element | null => {
    if (props.task.taskId === -1 && newEventHasWorkflows) {
      return <InfoMessage message={t('task:Workflows.Messages.Start')} />;
    } else {
      if (props.task.webhookStatus === TaskWorkflowStatus.Success) {
        return (
          <InfoMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text variant="small"> {t('task:Workflows.Messages.Success')} </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </InfoMessage>
        );
      } else if (props.task.webhookStatus === TaskWorkflowStatus.Failure) {
        return (
          <WarningMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text styles={{ root: { color: 'black' } }} variant="small">
                {t('task:Workflows.Messages.Failure')}
              </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </WarningMessage>
        );
      } else {
        return null;
      }
    }
  };

  const getOutlookButton = (): JSX.Element | null => {
    if (props.task.isSeries()) {
      //show create button when some subtasks are not scheduled yet
      if (props.task.instances?.some((i) => !i.eventId)) {
        //create for series
        //in this case, the back-end will schedule all subtasks in Outlook
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                className="redlab-usetiful-task-scheduleinoutlook"
                disabled={getIsOutlookCreateDisabled()}
                onClick={() => {
                  props.createInOutlook();
                }}
              >
                {t('task:Outlook.CreateOutlook')}
              </PrimaryButton>
            </Stack.Item>
          </Stack>
        );
      } else if (props.task.instances && props.task.instances.length > 0) {
        //remove for series, show when all subtasks are scheduled
        //in this case, the back-end will remove all subtasks from Outlook
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                text={t('task:Outlook.RemoveOutlook')}
                disabled={hasErrors() || props.isActionPending}
                onClick={() => {
                  props.removeFromOutlook();
                }}
              />
            </Stack.Item>
          </Stack>
        );
      }
    } else {
      if (!props.task.eventId && props.task.taskId !== -1) {
        //create for subtasks
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                className="redlab-usetiful-task-scheduleinoutlook"
                disabled={getIsOutlookCreateDisabled()}
                onClick={() => {
                  props.createInOutlook();
                }}
              >
                {t('task:Outlook.CreateOutlook')}
              </PrimaryButton>
            </Stack.Item>
          </Stack>
        );
      } else if (props.task.eventId && props.task.taskId !== -1) {
        //remove
        return (
          <Stack>
            <Stack.Item>
              <PrimaryButton
                text={t('task:Outlook.RemoveOutlook')}
                disabled={hasErrors() || props.isActionPending}
                onClick={() => {
                  props.removeFromOutlook();
                }}
              />
            </Stack.Item>
          </Stack>
        );
      }
    }

    return null;
  };

  const openEvent = async (taskMasterid: number | undefined) => {
    try {
      if (taskMasterid) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const event = await apiGetSingleTask(taskMasterid, true, accessToken, appContext.globalDataCache);
        if (event && event.tasks.length === 1) {
          props.initData(event.tasks[0]);
        }
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const onRenderTaskTypePivotItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    //itemScope contains true when in compact view
    if (!itemProps || !itemProps.itemKey || !defaultRender) return null;

    //render default for compact mode
    if (itemProps.itemScope === true) {
      return defaultRender(itemProps);
    }

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text variant="medium" styles={itemProps.itemKey === props.selectedPivot ? globalTextStylesBold : undefined}>
            {itemProps.headerText}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <TooltipHost content={t('forms:View.EnterFormMode')}>
            <IconButton iconProps={{ iconName: 'Fullscreen' }} onClick={() => props.setFormMode(true)} />
          </TooltipHost>
        </Stack.Item>
      </Stack>
    );
  };

  //
  // Footer
  //
  const footer = (
    <Stack horizontal={!appContext.isMobileView} verticalAlign="end" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            disabled={cannotSaveTask() || props.isActionPending}
            onClick={async () => {
              props.onSave(props.task, false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
      <Stack.Item grow>
        <Stack horizontal horizontalAlign={appContext.isMobileView ? undefined : 'space-around'}>
          {getOutlookButton()}
        </Stack>
      </Stack.Item>
    </Stack>
  );

  //
  // Main render
  //
  return (
    <Modal isOpen={props.isOpen} styles={getTaskViewModalStyles(props.windowLevel)}>
      <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="templateTask" />
      {props.isLoading && <OverlayLoader text={t('task:Progress.Save')} />}
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={getTaskViewModalStackStyles(appContext.isMobileView, false)}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack.Item>
              <Stack horizontal={!appContext.isMobileView} verticalAlign="center" tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <Text variant="xxLarge">
                    {props.task.taskId === -1 && props.task.isSeries() && t('task:Title.Event.Series.New')}
                    {props.task.taskId === -1 && !props.task.isSeries() && t('task:Title.Event.New')}
                    {props.task.taskId !== -1 && props.task.isSeries() && t('task:Title.Event.Series.Edit')}
                    {props.task.taskId !== -1 && !props.task.isSeries() && t('task:Title.Event.Edit')}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {!appContext.isMobileView && (
              <Stack.Item>
                <TaskTopTasksBar
                  task={props.task}
                  templateTypes={props.task.isSeries() ? undefined : [TaskTypes.Template]}
                  onClickTemplate={props.copyFromTemplate}
                  onClickFinish={onClickFinish}
                  onClickFollowUp={props.createFromTemplate}
                  onClickRemove={() => setShowRemoveDialog(true)}
                  onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
                  onSaveAsNewTask={props.onSaveAsNewTask}
                  onClickOpenEvent={!props.windowLevel ? openEvent : undefined}
                  navigateExternal={props.navigateExternal}
                  canUpdate={props.canUpdate}
                  canDelete={props.canDelete}
                  orgTask={props.orgTask}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <TaskDetailOwner
              orgTask={props.orgTask}
              task={props.task}
              onUpdate={props.onUpdateTask}
              canUpdate={props.canUpdate}
            />
          </Stack.Item>
          <Separator />
          {getWorkflowTaskMessage(newEventHasWorkflows)}
        </Stack.Item>
        {appContext.isMobileView && (
          <Stack.Item>
            <TaskTopTasksBar
              task={props.task}
              templateTypes={props.task.isSeries() ? undefined : [TaskTypes.Template]}
              onClickTemplate={props.copyFromTemplate}
              onClickFinish={onClickFinish}
              onClickFollowUp={props.createFromTemplate}
              onClickRemove={() => setShowRemoveDialog(true)}
              onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
              onSaveAsNewTask={props.onSaveAsNewTask}
              onClickOpenEvent={!props.windowLevel ? openEvent : undefined}
              navigateExternal={props.navigateExternal}
              canUpdate={props.canUpdate}
              canDelete={props.canDelete}
              orgTask={props.orgTask}
            />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            {!appContext.isMobileView && (
              <Fragment>
                <Stack.Item styles={{ root: { width: '50%' } }}>{getTaskDetails()}</Stack.Item>
                <Separator vertical></Separator>
              </Fragment>
            )}
            <Stack.Item styles={!appContext.isMobileView ? { root: { width: '50%' } } : { root: { width: '100%' } }}>
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Pivot
                    selectedKey={selectedPivot}
                    onLinkClick={(item) => setSelectedPivot(item?.props.itemKey!)}
                    overflowBehavior="menu"
                  >
                    {appContext.isMobileView && (
                      <PivotItem key="TaskDetails" itemKey="TaskDetails" headerText={t('task:Pivot.TaskDetails')} />
                    )}
                    {props.hasFormFeature && props.task.taskTypeId && props.task.taskTypeId > 0 && (
                      <PivotItem
                        key="TaskType"
                        itemKey="TaskType"
                        headerText={appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).name}
                        onRenderItemLink={appContext.isMobileView ? undefined : onRenderTaskTypePivotItem}
                      />
                    )}
                    {(!props.task.taskTypeId || props.task.taskTypeId === 0 || !props.hasFormFeature) && (
                      <PivotItem key="Checklist" itemKey="Checklist" headerText={t('task:Pivot.Checklist')} />
                    )}
                    <PivotItem key="Context" itemKey="Context" headerText={t('task:Pivot.Context')} />
                    <PivotItem key="Resources" itemKey="Resources" headerText={t('task:Pivot.Resources')} />
                    {props.task.commentTrailId > 0 && (
                      <PivotItem key="Activities" itemKey="Activities" headerText={t('task:Pivot.Activities')} />
                    )}
                    {props.task.auditTrailId > 0 && (
                      <PivotItem key="Audit" itemKey="Audit" headerText={t('task:Pivot.Audit')} />
                    )}
                  </Pivot>
                </Stack.Item>
                {selectedPivot === 'TaskDetails' && (
                  <Fragment>
                    <Stack.Item grow style={{ height: appContext.isMobileView ? '40vh' : 0 }}>
                      {getTaskDetails()}
                    </Stack.Item>
                  </Fragment>
                )}
                <TaskTypeForm
                  isOpen={selectedPivot === 'TaskType'}
                  task={props.task}
                  links={props.links}
                  compact={true}
                  compactTaskView={props.compactView}
                  readonly={props.task.isCompleted()}
                  onUpdateData={props.onUpdateKPIData}
                  onSetData={props.onSetKPIData}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinkToTaskState}
                  setLinks={props.setLinkToTaskState}
                  removeLink={props.removeLinkFromTaskState}
                  showErrors={props.showFormErrors}
                  onValidate={props.onValidateForm}
                  formMode={formMode}
                  onExitFormMode={() => setFormMode(false)}
                  onClickFinish={onClickFinish}
                ></TaskTypeForm>
                {selectedPivot === 'Checklist' && (
                  <Fragment>
                    <Stack.Item styles={globalStackItemStylesPaddingSmall}>
                      <ChecklistProgress
                        totalChecks={props.task.checkList.items ? props.task.checkList.items.length : 0}
                        completedChecks={props.task.checkList.getCompletedCheckCount()}
                        followUp={props.task.followUp ? true : false}
                      />
                    </Stack.Item>
                    <Stack.Item grow>
                      <CheckList
                        allowFill={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistFill,
                          appContext,
                        )}
                        allowEdit={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistEdit,
                          appContext,
                        )}
                        checkList={props.task.checkList}
                        updateCheckList={onUpdateCheckList}
                        onComment={addCommentFromCheckList}
                      />
                    </Stack.Item>
                    {props.task.taskId !== -1 && props.canUpdate && (
                      <Stack.Item style={{ paddingLeft: '5px' }}>
                        <CheckListFollowUp followUp={props.task.followUp} toggle={onToggleTaskFollowUp} />
                      </Stack.Item>
                    )}
                  </Fragment>
                )}
                {selectedPivot === 'Resources' && (
                  <TaskLinkList
                    taskLinks={props.links}
                    task={props.task}
                    addLinks={props.addLinkToTaskState}
                    setLinks={props.setLinkToTaskState}
                    removeLink={props.removeLinkFromTaskState}
                    navigateExternal={props.navigateExternal}
                  />
                )}
                {selectedPivot === 'Activities' && (
                  <Stack.Item grow>
                    <ActivityTrail
                      auditTrailId={props.task.auditTrailId}
                      commentTrailId={props.task.commentTrailId}
                      taskId={props.task.taskId}
                      authSchemaIds={[props.task.authSchemaId]}
                    />
                  </Stack.Item>
                )}
                {selectedPivot === 'Audit' && (
                  <Stack.Item grow>
                    <AuditTrail auditTrailId={props.task.auditTrailId} />
                  </Stack.Item>
                )}
                {selectedPivot === 'Context' && (
                  <Stack.Item grow>
                    <TaskContext
                      task={props.task}
                      updateTaskForForm={props.onUpdateTaskForForm}
                      updateTask={props.onUpdateTask}
                      allowEdit={canUpdateTaskField(
                        props.orgTask,
                        props.canUpdate,
                        TaskFieldTypes.ChecklistEdit,
                        appContext,
                      )}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
        </Stack.Item>
        <Stack.Item>{footer}</Stack.Item>
      </Stack>
      <DialogConfirmDelete
        onNo={() => {
          setShowRemoveDialog(false);
        }}
        onYes={() => {
          setShowRemoveDialog(false);
          props.onRemove();
        }}
        title={props.task.isSeries() ? t('task:Template.RemoveDialogTitle') : t('task:Remove.DialogTitle')}
        subText={props.task.isSeries() ? t('task:Template.RemoveDialogSubText') : t('task:Remove.DialogSubTitle')}
        hidden={!showRemoveDialog}
        confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
      />
      <DialogYesNo
        onNo={() => {
          setShowCancelDialog(false);
        }}
        onYes={() => {
          setShowCancelDialog(false);

          props.onCancel();
        }}
        title={t('task:Cancel.DialogTitle')}
        subText={t('task:Cancel.DialogSubTitle')}
        hidden={!showCancelDialog}
      />
      <DialogOk
        onOk={() => {
          setShowNotAllSubtasksCompletedDialog(false);
        }}
        title={t('task:NotAllSubtasksCompletedDialog.Title')}
        subText={t('task:NotAllSubtasksCompletedDialog.SubText')}
        hidden={!showNotAllSubtasksCompletedDialog}
      />
      <TaskWorkflowDetailsModal
        hidden={!showWorkflowDetails}
        task={props.task}
        onClose={() => setShowWorkflowDetails(false)}
      />
    </Modal>
  );
};

export default EventTask;
