import { TFunction } from 'i18next';
import { PermissionTypes } from 'models/auth/rolePermission';

export const getPermissionLabel = (pt: PermissionTypes,
    t: TFunction<string[]>): string => {
  switch (pt) {
    case PermissionTypes.ModuleOrg:
      return t('adminAuth:TabPermissions.Permissions.ModuleOrg.Label');
    case PermissionTypes.ModuleAdmin:
      return t('adminAuth:TabPermissions.Permissions.ModuleAdmin.Label');
    case PermissionTypes.ModuleAnnualPlan:
      return t('adminAuth:TabPermissions.Permissions.ModuleAnnualPlan.Label');
    case PermissionTypes.ModuleAssets:
      return t('adminAuth:TabPermissions.Permissions.ModuleAssets.Label');
    case PermissionTypes.ModuleControls:
      return t('adminAuth:TabPermissions.Permissions.ModuleControls.Label');
    case PermissionTypes.ModuleLibrary:
      return t('adminAuth:TabPermissions.Permissions.ModuleLibrary.Label');
    case PermissionTypes.ModulePartner:
      return t('adminAuth:TabPermissions.Permissions.ModulePartner.Label');
    case PermissionTypes.ModuleRequirements:
      return t('adminAuth:TabPermissions.Permissions.ModuleRequirements.Label');
    case PermissionTypes.ModuleRisks:
      return t('adminAuth:TabPermissions.Permissions.ModuleRisks.Label');
    case PermissionTypes.ModuleStandards:
      return t('adminAuth:TabPermissions.Permissions.ModuleStandards.Label');
    case PermissionTypes.ModuleTasks:
      return t('adminAuth:TabPermissions.Permissions.ModuleTasks.Label');
    case PermissionTypes.CreateAnnualPlanTask:
      return t('adminAuth:TabPermissions.Permissions.CreateAnnualPlanTask.Label');
    case PermissionTypes.CreateAsset:
      return t('adminAuth:TabPermissions.Permissions.CreateAsset.Label');
    case PermissionTypes.CreateForm:
      return t('adminAuth:TabPermissions.Permissions.CreateForm.Label');
    case PermissionTypes.CreateKPI:
      return t('adminAuth:TabPermissions.Permissions.CreateKPI.Label');
    case PermissionTypes.CreateLibraryCategory:
      return t('adminAuth:TabPermissions.Permissions.CreateLibraryCategory.Label');
    case PermissionTypes.CreateLibraryItem:
      return t('adminAuth:TabPermissions.Permissions.CreateLibraryItem.Label');
    case PermissionTypes.CreateObjective:
      return t('adminAuth:TabPermissions.Permissions.CreateObjective.Label');
    case PermissionTypes.CreateProcess:
      return t('adminAuth:TabPermissions.Permissions.CreateProcess.Label');
    case PermissionTypes.CreateRisk:
      return t('adminAuth:TabPermissions.Permissions.CreateRisk.Label');
    case PermissionTypes.CreateStandard:
      return t('adminAuth:TabPermissions.Permissions.CreateStandard.Label');
    case PermissionTypes.CreateStandardElement:
      return t('adminAuth:TabPermissions.Permissions.CreateStandardElement.Label');
    case PermissionTypes.CreateTask:
      return t('adminAuth:TabPermissions.Permissions.CreateTask.Label');
    default:
      return '';
  }
};
