import { SubscriptionTypes } from 'utils/subscription';
import PackageDTO from './packageDTO';
import { TenantOnboardingDTO } from './tenantDTO';
import NormDTO from './normDTO';

export default class AdminTenantDTO {
  tenantId: string;

  parentId?: string;

  topLevelParentId?: string;

  azureTenantId?: string;

  name: string;

  created: string;

  licenses: number;

  mgmtLicenses: number;

  trialStart?: string;

  enableEventSubscriptions: boolean;

  suspended: boolean;

  storeMgmtState?: number;

  onboardingState?: TenantOnboardingDTO;

  package?: PackageDTO;

  usedLicenses?: number;

  usedMgmtLicenses?: number;

  subscriptionType: number;

  leadSource?: string;

  partnerId?: string;

  norms?: NormDTO[];
  
  stripeCustomerId?: string;

  subscriptionEnd?: string;

  constructor() {
    this.tenantId = '';
    this.created = '';
    this.name = '';
    this.licenses = 0;
    this.mgmtLicenses = 0;
    this.enableEventSubscriptions = false;
    this.suspended = false;
    this.subscriptionType = SubscriptionTypes.Basic;
  }
}
