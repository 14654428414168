import isoControl_TranslationDTO from './isoControl_TranslationDTO';
import ControlDTO from 'models/dto/controlDTO';
import { ControlTypes } from 'models/control';
import { ISONormDTO } from './normDTO';

export default class ISOControlDTO {
  isoControlId: number;

  code: string;

  isoNormId?: number;

  parentISOControlId?: number;

  controlType: ControlTypes;

  changedAfterUpgrade: boolean;

  trans?: isoControl_TranslationDTO[];

  control?: ControlDTO;

  isoNorm?: ISONormDTO;

  isoControlISOControls?: ISOControlISOControlDTO[];

  constructor() {
    this.isoControlId = 0;
    this.changedAfterUpgrade = false;
    this.controlType = ControlTypes.Theme;
    this.code = '';
  }
}

export class ISOControlISOControlDTO {
  isoControlIdFrom: number;

  isoControlIdTo: number;

  status?: number;

  constructor() {
    this.isoControlIdFrom = 0;
    this.isoControlIdTo = 0;
  }
}

export class ISONormUpgradeCheckMapDTO {
  sourceISONormId: number;

  targetISONormId: number;

  checkISONormId: number;

  constructor() {
    this.sourceISONormId = 0;
    this.targetISONormId = 0;
    this.checkISONormId = 0;
  }
}

export class ISOControlTransMappingDTO {
  langCode: string;

  mapping: ISOControlTransMappingItemDTO[];

  constructor() {
    this.langCode = '';
    this.mapping = [];
  }
}

export class ISOControlTransMappingItemDTO {
  sourceId: number;

  targetId: number;

  sourceCode: string;

  targetCode: string;

  sourceName: string;

  targetName: string;

  constructor() {
    this.sourceId = 0;
    this.targetId = 0;
    this.sourceCode = '';
    this.targetCode = '';
    this.sourceName = '';
    this.targetName = '';
  }
}
