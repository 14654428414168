import { Stack, Text } from '@fluentui/react';
import RiskScoreModel from 'models/RiskScoreModel';
import Risk from 'models/risk';
import { globalTextStylesBoldBlack } from 'globalStyles';

interface IListRiskScoreProps {
  riskModel: RiskScoreModel;
  risk: Risk;
  timeline: 'start' | 'current' | 'goal';
}

const ListRiskScore = (props: IListRiskScoreProps) => {
  let score: number;

  switch (props.timeline) {
    case 'start':
      score = props.risk.getStartScore();
      break;
    case 'current':
      score = props.risk.getCurrentScore();
      break;
    case 'goal':
      score = props.risk.getGoalScore();
      break;
    default:
      return null;
  }

  return (
    <Stack
      horizontalAlign="center"
      styles={{
        root: { minWidth: 50, maxWidth: 50, backgroundColor: props.riskModel?.getColor(score) },
      }}
    >
      <Text styles={globalTextStylesBoldBlack}>{score}</Text>
    </Stack>
  );
};

export default ListRiskScore;
