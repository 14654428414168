import React from 'react';
import { IListProps, IPageProps, IPageSpecification, IRectangle, List, Spinner, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';

interface IInfiniteListProps extends IListProps {
  hasMoreData: boolean;
  pageSize?: number;
  listHeight?: number;
  onFetchMore: () => Promise<void>;
  reRender?: string;
}

let isLoading = false;

const InfiniteList = (props: IInfiniteListProps) => {
  const { t } = useTranslation('translation');

  const needMoreData = async () => {
    try {
      isLoading = true;
      await props.onFetchMore();
    } catch (err) {
      throw err;
    } finally {
      isLoading = false;
    }
  };

  const onRenderPage = (
    pageProps?: IPageProps,
    defaultRender?: (props?: IPageProps) => JSX.Element | null,
  ): JSX.Element | null => {
    const page = pageProps?.page;
    if (
      props.hasMoreData &&
      !isLoading &&
      page &&
      page.isSpacer === false &&
      page.isVisible === true &&
      page.startIndex + page.itemCount >= (props.items?.length ?? 0)
    ) {
      needMoreData();
    }

    if (defaultRender) {
      return defaultRender(pageProps);
    } else {
      return null;
    }
  };

  const onGetPageSpecification = (
    itemIndex?: number | undefined,
    visibleRect?: IRectangle | undefined,
  ): IPageSpecification => {
    return {
      itemCount: props.pageSize ?? 10,
      height: props.listHeight,
    };
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow>
        <List
          {...props}
          onRenderPage={onRenderPage}
          onShouldVirtualize={() => { return true }}
          getPageSpecification={onGetPageSpecification}
          version={props.reRender}
          usePageCache={true}
        />
      </Stack.Item>
      {isLoading && (
        <Stack horizontalAlign="center">
          <Spinner />
        </Stack>
      )}
      {props.items && props.items.length > (props.pageSize ?? 10) && !props.hasMoreData && (
        <Stack horizontalAlign="center">
          <Text styles={globalTextStylesBold}>{t('translation:General.Notifications.NoMoreItems')}</Text>
        </Stack>
      )}
    </Stack>
  );
};

export default InfiniteList;
