import Control from 'models/control';
import Risk from 'models/risk';
import Theme from 'models/theme';
import Process from './process';
import Asset from 'models/asset/asset';

export class ProcessContext {
  parent: Process | undefined;

  children: Process[];

  controls: Control[];

  themes: Theme[];

  risks: Risk[];

  assets: Asset[];

  constructor() {
    this.parent = new Process();
    this.children = [];
    this.controls = [];
    this.themes = [];
    this.risks = [];
    this.assets = [];
  }
}
