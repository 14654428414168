import { IAppContext } from '../../../App/AppContext';
import LanguageDTO from '../languageDTO';
import {
  ReportStorageList,
  ReportTemplateLink,
  ReportDefaultStorageBehavior,
  ReportDefaultOutputFormat,
  SharepointReports,
} from 'models/setting';
import ReportDefinition from 'models/reports/ReportDefinition';
import { SubscriptionTypes } from 'utils/subscription';
import { TFunction } from 'i18next';

export enum ReportOutputFormat {
  Word = 1,
  PDF = 2,
  Excel = 3,
}

export enum ReportStorageBehavior {
  Download = 1,
  SharePoint = 2,
}

export const GetGroupOrPermissionLabel = (appContext: IAppContext, t: TFunction<string[]>): string => {
  if (appContext.user.login.subscriptionType === SubscriptionTypes.Basic) {
    return t('adminAuth:AuthSchemaPicker.GroupLabel');
  } else {
    return t('adminAuth:AuthSchemaPicker.Label');
  }
};

export default class ReportRequestDTO {
  reportDefinition?: ReportDefinition;

  language: LanguageDTO;

  outputFormat: ReportOutputFormat;

  storageBehavior: ReportStorageBehavior;

  templateLinkId?: number;

  destinationListId?: number;

  fileName?: string;

  constructor(report: ReportRequestDTO | undefined) {
    this.outputFormat = ReportOutputFormat.Word;
    this.storageBehavior = ReportStorageBehavior.Download;
    this.language = new LanguageDTO();

    if (report) {
      this.applySettings(report);
    }
  }

  static getNewRequest(appContext: IAppContext): ReportRequestDTO {
    const report = new ReportRequestDTO(undefined);

    const sharePointEnabled = appContext.globalDataCache.settings.get(SharepointReports) as boolean;
    if (sharePointEnabled) {
      const listId = appContext.globalDataCache.settings.get(ReportStorageList) as number;
      report.destinationListId = listId;

      const linkId = appContext.globalDataCache.settings.get(ReportTemplateLink) as number;
      report.templateLinkId = linkId;

      const reportDefaultStorageBehavior = appContext.globalDataCache.settings.get(
        ReportDefaultStorageBehavior,
      ) as ReportStorageBehavior;
      report.storageBehavior = reportDefaultStorageBehavior;

      const reportDefaultOutputFormat = appContext.globalDataCache.settings.get(
        ReportDefaultOutputFormat,
      ) as ReportOutputFormat;
      report.outputFormat = reportDefaultOutputFormat;
    } else {
      report.destinationListId = undefined;
      report.templateLinkId = undefined;
      report.outputFormat = ReportOutputFormat.Word;
      report.storageBehavior = ReportStorageBehavior.Download;
    }

    report.language = new LanguageDTO();
    report.language.code = appContext.user.language.code;

    return report;
  }

  applySettings(report: ReportRequestDTO) {
    this.destinationListId = report.destinationListId;
    this.templateLinkId = report.templateLinkId;
    this.language = report.language;
    this.fileName = report.fileName;
    this.outputFormat = report.outputFormat;
    this.storageBehavior = report.storageBehavior;
    this.reportDefinition = report.reportDefinition;
  }

  clone(): ReportRequestDTO {
    const output = new ReportRequestDTO(this);

    return output;
  }
}
