import { FunctionComponent, useContext } from 'react';
import {
  DetailsList,
  Text,
  IColumn,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Stack,
  Spinner,
  SpinnerSize,
  DetailsListLayoutMode,
  IconButton,
  Separator,
} from '@fluentui/react';
import React from 'react';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { apiGetLinks, apiGetLinksForId } from 'services/Api/linkService';
import { apiRequest } from 'services/Auth/authConfig';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import LibraryPicker from 'components/Pickers/LibraryPicker';
import ResourceList from 'models/resourceList';
import { useTranslation } from 'react-i18next';
import { deleteIcon, globalStackTokensGapSmall } from 'globalStyles';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';

export class WidgetLibraryContentConfig {
  linkIds: number[];

  constructor() {
    this.linkIds = [];
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.linkIds = newRawConfig.linkIds ?? [];
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetLibraryContentConfig {
    const newConfig = new WidgetLibraryContentConfig();
    newConfig.linkIds = this.linkIds;

    return newConfig;
  }
}

interface IWidgetLibraryContentProps extends IWidgetRendererProps {}

const WidgetLibraryContent: FunctionComponent<IWidgetLibraryContentProps> = (props: IWidgetLibraryContentProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [links, setLinks] = React.useState<ResourceLink[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [linkToShow, setLinkToShow] = React.useState<ResourceLink | undefined>(undefined);

  React.useEffect(() => {
    const loadConfig = (): WidgetLibraryContentConfig => {
      let newConfig = new WidgetLibraryContentConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        const config = loadConfig();

        if (config && config.linkIds && config.linkIds.length > 0) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const links = await apiGetLinksForId(accessToken, config.linkIds, appContext.globalDataCache);
          setLinks(links);
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const columns: IColumn[] = [
    {
      key: 'link',
      name: '',
      minWidth: 50,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Text>
            <Link
              onClick={() => {
                if (!item) return;

                setLinkToShow(item);
              }}
            >
              {item.linkName}
            </Link>
          </Text>
        );
      },
    },
  ];

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (links.length === 0) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:LibraryContent.Config.Invalid')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            items={links}
            columns={columns}
            compact
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={false}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
          />
        </ScrollablePane>
      </Stack.Item>
      <LinkPreviewModalOrUrl
        isOpen={linkToShow !== undefined}
        onClose={() => setLinkToShow(undefined)}
        link={linkToShow}
        confirmOpen={false}
        enableLinks={true}
      />
    </Stack>
  );
};

export default WidgetLibraryContent;

//
// Config
//

interface IWidgetConfigLibraryContentProps extends IWidgetConfigRendererProps {}

export const WidgetConfigLibraryContent: FunctionComponent<IWidgetConfigLibraryContentProps> = (
  props: IWidgetConfigLibraryContentProps,
) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [links, setLinks] = React.useState<ResourceLink[]>([]);
  const [lists, setLists] = React.useState<ResourceList[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedLinks, setSelectedLinks] = React.useState<ResourceLink[]>([]);
  const [newLinks, setNewLinks] = React.useState<ResourceLink[]>([]);
  const maxLinksPerWidget = 8;

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetLibraryContentConfig => {
    let newConfig = new WidgetLibraryContentConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      await appContext.globalDataCache.lists.getItems(); //make sure the lists are loaded
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const links = await apiGetLinks(false, accessToken, appContext.globalDataCache);
      const lists = await appContext.globalDataCache.lists.getItems();

      //sort lists on name
      lists.sort((a: ResourceList, b: ResourceList) => {
        return a.name.localeCompare(b.name);
      });

      //sort on list name to divide in groups, within the group on link name
      links.sort((a: ResourceLink, b: ResourceLink) => {
        if (a.listId !== b.listId) return a.list.name.localeCompare(b.list.name);

        return a.linkName.localeCompare(b.linkName);
      });

      setLists(lists);

      //now set the current config
      const config = loadConfig();
      const selectedLinks = links.filter((l) => config.linkIds.includes(l.linkId));
      setSelectedLinks(selectedLinks);

      //and filter out the current config from the main list
      setLinks(links.filter((l) => !config.linkIds.includes(l.linkId)));
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: IColumn[] = [
    {
      isMultiline: true,
      key: 'linkName',
      name: '',
      minWidth: 100,
      maxWidth: 600,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{item.linkName}</Text>;
      },
    },
    {
      key: 'delete',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item || !hasUserFeatureGenericManager(appContext)) return;

        return (
          <IconButton
            disabled={isLoading}
            iconProps={deleteIcon}
            onClick={() => {
              if (!item) return;
              onRemove(item);
            }}
            styles={{ root: { height: 18 } }}
          />
        );
      },
    },
  ];

  const onRemove = (item: ResourceLink) => {
    setSelectedLinks(selectedLinks.filter((link) => link.linkId !== item.linkId));
    const newLinks = [...links, item];
    setLinks(newLinks);
  };

  const getConfig = (items: ResourceLink[]): WidgetLibraryContentConfig => {
    const config = new WidgetLibraryContentConfig();
    config.linkIds = items.map((l) => l.linkId);

    return config;
  };

  React.useEffect(() => {
    if (selectedLinks.length > 0) {
    }
    const config = getConfig(selectedLinks);
    props.onUpdateConfig(JSON.stringify(config), selectedLinks.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLinks]);

  React.useEffect(() => {
    const newSelectedLinks = [...selectedLinks];
    newSelectedLinks.push(...newLinks);
    setSelectedLinks(newSelectedLinks);
    const filteredLinks = links.filter((l) => !newSelectedLinks.some((s) => s.linkId === l.linkId));
    setLinks(filteredLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinks]);

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Text>
          {selectedLinks.length > 0
            ? t('widgets:LibraryContent.Config.SelectedLinksLabel', { max: maxLinksPerWidget })
            : t('widgets:LibraryContent.Config.SelectedLinksLabelEmpty')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <DetailsList
          columns={columns}
          compact
          layoutMode={DetailsListLayoutMode.justified}
          items={selectedLinks}
          selectionMode={SelectionMode.single}
          isHeaderVisible={false}
        />
        {selectedLinks.length > 0 && <Separator />}
      </Stack.Item>
      {selectedLinks.length < maxLinksPerWidget && (
        <Stack.Item grow>
          <LibraryPicker
            multiSelect={false}
            onSelect={setNewLinks}
            links={links}
            lists={lists}
            isLoading={isLoading}
            showHeader={false}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
