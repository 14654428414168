import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  PrimaryButton,
  IContextualMenuProps,
  Spinner,
  Icon,
  IIconProps,
  ActionButton,
  IContextualMenuItem,
  Link,
  FontIcon,
  ContextualMenuItemType,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import ResourceLink from 'models/resourceLink';
import LinkCreatorURL from 'components/Links/Creators/LinkCreatorURL';
import AppContext from 'App/AppContext';
import ResourceList, { ResourceListType } from 'models/resourceList';
import LinkCreatorCustomList from './Creators/LinkCreatorCustomList';
import LinkCreatorDocumentLibrary from './Creators/LinkCreatorDocumentLibrary';
import { apiGetLinksForList } from 'services/Api/linkService';
import { apiRequest } from 'services/Auth/authConfig';
import {
  globalStackTokensGapSmall,
  listTypeCustomListIcon,
  listTypeDocLibraryIcon,
  listTypeSitePageLibraryIcon,
  listTypeWebURLIcon,
  newIcon,
} from 'globalStyles';
import LinkCreatorSitePageLibrary from './Creators/LinkCreatorSitePageLibrary';
import { useHistory } from 'react-router-dom';
import { hasUserFeatureGenericManager, hasUserRolePermission } from 'services/Auth/featurePermissions';
import VirtualContentPicker from 'components/Pickers/VirtualContentPicker';
import { PermissionTypes } from 'models/auth/rolePermission';

interface IAddNewLink {
  addLinks: (links: ResourceLink[]) => void;
  existingLinks: ResourceLink[];
  disabled: boolean;
  allowCustomLists: boolean;
  allowDocumentLibraries: boolean;
  allowWebURLs: boolean;
  allowSitePages: boolean;
  allowAddCategory?: boolean;
  listFilter?: ResourceList[];
  entityContext: boolean;
  selectedList?: ResourceList;
  hideButton?: boolean;
  selectedListDisableOpen?: boolean;
  close?: () => void;
  showAsLink?: boolean;
  showAsLinkLabel?: string;
  hideWholeList?: boolean;
}

const getListIcon = (item: ResourceList): IIconProps | undefined => {
  if (item.listType === ResourceListType.CustomList) return listTypeCustomListIcon;
  if (item.listType === ResourceListType.DocumentLibrary) return listTypeDocLibraryIcon;
  if (item.listType === ResourceListType.WebURL) return listTypeWebURLIcon;
  if (item.listType === ResourceListType.SitePageLibrary) return listTypeSitePageLibraryIcon;

  return undefined;
};

export const AddNewLink = (props: IAddNewLink) => {
  const history = useHistory();
  const [menuPropsLoading, setMenuPropsLoading] = useState<boolean>(false);
  const [listsLoading, setListsLoading] = useState<boolean>(false);
  const [lists, setLists] = useState<ResourceList[]>([]);
  const [selectedListForMenu, setSelectedListForMenu] = useState<ResourceList | undefined>(undefined);
  const [listLinks, setListLinks] = useState<ResourceLink[]>([]);

  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'library']);

  const loadListLinks = async (list: ResourceList | undefined) => {
    if (listsLoading || !list) return;

    try {
      setListsLoading(true);

      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const _links = await apiGetLinksForList(list.listId, accessToken, appContext.globalDataCache);
      setListLinks(_links);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setListsLoading(false);
    }
  };

  const loadData = async () => {
    if (menuPropsLoading) return;
    try {
      setMenuPropsLoading(true);
      let _lists = await appContext.globalDataCache.lists.getItems();

      if (props.listFilter) {
        _lists = _lists.filter((l) => props.listFilter?.some((f) => f.listId === l.listId));
      } else {
        if (!props.allowCustomLists) {
          _lists = _lists.filter((l) => l.listType !== ResourceListType.CustomList);
        }
        if (!props.allowDocumentLibraries) {
          _lists = _lists.filter((l) => l.listType !== ResourceListType.DocumentLibrary);
        }
        if (!props.allowWebURLs) {
          _lists = _lists.filter((l) => l.listType !== ResourceListType.WebURL);
        }
        if (!props.allowSitePages) {
          _lists = _lists.filter((l) => l.listType !== ResourceListType.SitePageLibrary);
        }
      }

      setLists(_lists);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setMenuPropsLoading(false);
    }
  };

  //
  // Effects
  //
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadListLinks(selectedListForMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListForMenu]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  useEffect(() => {
    if (props.selectedList) {
      loadListLinks(props.selectedList);
      if (!props.selectedListDisableOpen) {
        setSelectedListForMenu(props.selectedList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedList]);

  useEffect(() => {
    if (!selectedListForMenu && props.close) {
      props.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListForMenu]);

  //
  // Helpers
  //
  const navigateToListsAndAddNew = () => {
    history.push('/library/lists#add');
  };

  const getMenuProps = (): IContextualMenuProps | undefined => {
    let items: IContextualMenuItem[] = [];

    if (!props.selectedList) {
      items = lists
        .sort((a, b) => {
          return a.name?.localeCompare(b.name);
        })
        .map((_item) => {
          return {
            key: _item.listId.toString(),
            text: _item.name,
            iconProps: getListIcon(_item),
            onClick: () => {
              setSelectedListForMenu(_item);
            },
          };
        });

      if ((props.allowAddCategory || items.length === 0) && hasUserFeatureGenericManager(appContext)) {
        if (items.length > 0) {
          items.push({
            key: 'div1',
            text: '-',
            itemType: ContextualMenuItemType.Divider,
          });
        }
        items.push({
          //empty state
          key: 'empty',
          onClick: () => {
            navigateToListsAndAddNew();
          },
          onRenderContent(props, defaultRenders) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <FontIcon {...newIcon} style={{ paddingLeft: 5 }} />
                <Link>{t('library:LinkComponents.AddNoLists')}</Link>
              </Stack>
            );
          },
        });
      } else if (items.length === 0) {
        //empty state for non manager users or when not allowed to add
        items.push({
          key: 'empty',
          text: t('library:LinkComponents.NoLists'),
        });
      }

      return {
        calloutProps: {
          calloutMaxHeight: 600,
        },
        items: items,
      };
    } else {
      return undefined;
    }
  };

  const getEditors = () => {
    if (selectedListForMenu?.isVirtual) {
      return (
        <VirtualContentPicker
          list={selectedListForMenu}
          isOpen={true}
          onClose={() => {
            setSelectedListForMenu(undefined);
            if (props.close) props.close();
          }}
          onSelect={(linkRows) => {
            props.addLinks(linkRows.map((l) => l.item));
            setSelectedListForMenu(undefined);
            if (props.close) props.close();
          }}
          existingLinks={props.existingLinks}
        />
      );
    }

    return (
      <Stack.Item>
        {selectedListForMenu?.listType === ResourceListType.WebURL && (
          <LinkCreatorURL
            isOpen={selectedListForMenu !== undefined}
            list={selectedListForMenu}
            dismiss={() => setSelectedListForMenu(undefined)}
            addLinks={props.addLinks}
            listLinks={listLinks}
            existingLinks={props.existingLinks.filter((l) => l.listId === selectedListForMenu.listId)}
            listLoading={listsLoading}
            allowPickExistingLink={props.entityContext}
            hideWholeList={props.hideWholeList}
          />
        )}
        {selectedListForMenu?.listType === ResourceListType.CustomList && (
          <LinkCreatorCustomList
            isOpen={selectedListForMenu !== undefined}
            list={selectedListForMenu}
            dismiss={() => setSelectedListForMenu(undefined)}
            addLinks={props.addLinks}
            listLinks={listLinks}
            existingLinks={props.existingLinks.filter((l) => l.listId === selectedListForMenu.listId)}
            listLoading={listsLoading}
            hideWholeList={props.hideWholeList}
          />
        )}
        {selectedListForMenu?.listType === ResourceListType.DocumentLibrary && (
          <LinkCreatorDocumentLibrary
            isOpen={selectedListForMenu !== undefined}
            list={selectedListForMenu}
            dismiss={() => setSelectedListForMenu(undefined)}
            addLinks={props.addLinks}
            listLinks={listLinks}
            existingLinks={props.existingLinks.filter((l) => l.listId === selectedListForMenu.listId)}
            listLoading={listsLoading}
            hideWholeList={props.hideWholeList}
          />
        )}
        {selectedListForMenu?.listType === ResourceListType.SitePageLibrary && (
          <LinkCreatorSitePageLibrary
            isOpen={selectedListForMenu !== undefined}
            list={selectedListForMenu}
            dismiss={() => setSelectedListForMenu(undefined)}
            addLinks={props.addLinks}
            listLinks={listLinks}
            existingLinks={props.existingLinks.filter((l) => l.listId === selectedListForMenu.listId)}
            listLoading={listsLoading}
            entityContext={props.entityContext}
            hideWholeList={props.hideWholeList}
          />
        )}
      </Stack.Item>
    );
  };

  //
  // Main render
  //

  if (props.showAsLink) {
    return (
      <Stack>
        {!props.hideButton && (
          <Stack.Item>
            <ActionButton
              iconProps={newIcon}
              className="redlab-usetiful-library-addnew"
              text={props.showAsLinkLabel ?? t('library:LinkComponents.Add')}
              menuProps={getMenuProps()}
              onClick={() => {
                if (!props.selectedList) return;
                setSelectedListForMenu(props.selectedList);
              }}
              disabled={
                props.disabled ||
                menuPropsLoading ||
                listsLoading ||
                !hasUserRolePermission(appContext, PermissionTypes.CreateLibraryItem)
              }
              onRenderMenuIcon={() => {
                if (props.selectedList) return null;
                if (menuPropsLoading) return <Spinner />;

                return <Icon iconName={'ChevronDown'} />;
              }}
            />
          </Stack.Item>
        )}
        {getEditors()}
      </Stack>
    );
  } else {
    return (
      <Stack>
        {!props.hideButton && (
          <Stack.Item>
            <PrimaryButton
              className="redlab-usetiful-library-addnew"
              text={t('library:LinkComponents.Add')}
              menuProps={getMenuProps()}
              onClick={() => {
                if (!props.selectedList) return;
                setSelectedListForMenu(props.selectedList);
              }}
              disabled={
                props.disabled ||
                menuPropsLoading ||
                listsLoading ||
                !hasUserRolePermission(appContext, PermissionTypes.CreateLibraryItem)
              }
              onRenderMenuIcon={() => {
                if (props.selectedList) return null;
                if (menuPropsLoading) return <Spinner />;

                return <Icon iconName={'ChevronDown'} />;
              }}
            />
          </Stack.Item>
        )}
        {getEditors()}
      </Stack>
    );
  }
};

export default AddNewLink;
