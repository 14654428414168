import {
  Dropdown,
  FontIcon,
  getTheme,
  IDropdownOption,
  IDropdownProps,
  Label,
  Stack,
  TextField,
  TooltipHost,
  Text,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import {
  globalStackTokensGapExtraSmall,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  infoIcon,
} from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dropdownStyles, durationValueOptions, iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import {
  toLocaleDateTimeMedium,
  setNewDateKeepTime,
  setNewTimeKeepDate,
  toLocaleDateLong,
  toLocaleTimeNoSeconds,
} from 'utils/datetime';
import TimePicker from 'components/Pickers/TimePicker';

interface ITaskDetailDeadlineProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (newTask: Task) => void;
  getErrorMessage: (field: string) => string | undefined;
}

const getUnit = (n?: number): 'hours' | 'days' => {
  if (!n) return 'hours';
  if (n < 24) return 'hours';

  return 'days';
};

export const TaskDetailDeadline = (props: ITaskDetailDeadlineProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const theme = getTheme();
  const [timeUnit, setTimeUnit] = useState<string>(getUnit(props.task.duration));
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Deadline, appContext);

  const onRenderMaximumDurationLabelWithInfo = (
    props?: IDropdownProps,
    defaultRender?: (props?: IDropdownProps) => JSX.Element | null,
  ): JSX.Element | null => {
    const label = t('task:Deadline.MaximumDuration');
    const info = t('task:Deadline.MaximumDurationInfo');

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
        <Label>{label}</Label>
        <TooltipHost content={info}>
          <FontIcon {...infoIcon}></FontIcon>
        </TooltipHost>
      </Stack>
    );
  };

  const durationTimeOptions: IDropdownOption[] = [
    {
      key: 'hours',
      text: t('task:Repeat.Hours'),
    },
    {
      key: 'days',
      text: t('task:Repeat.Days'),
    },
  ];

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace } } : undefined}
    >
      <Stack.Item>{!appContext.isMobileView && <FontIcon iconName="InfoSolid" style={iconFontSize} />}</Stack.Item>
      {props.task.recurringPattern.isActive && props.task.taskType === TaskTypes.Normal && (
        <Stack.Item grow>
          <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="end">
            <Stack.Item>
              <Dropdown
                options={durationValueOptions}
                dropdownWidth="auto"
                styles={dropdownStyles}
                calloutProps={{ calloutMaxHeight: 250 }}
                placeholder={t('task:Repeat.HourDropDownPlaceholder')}
                selectedKey={timeUnit === 'hours' ? props.task.duration : Math.floor(props.task.duration / 8)}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                  if (!option) return;
                  let copyTask = props.task.clone();
                  if (timeUnit === 'hours') {
                    copyTask.duration = +option.key;
                  } else {
                    copyTask.duration = +option.key * 8;
                  }
                  props.onUpdate(copyTask);
                }}
                disabled={isReadonly}
                onRenderLabel={onRenderMaximumDurationLabelWithInfo}
              />
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                options={durationTimeOptions}
                styles={dropdownStyles}
                calloutProps={{ calloutMaxHeight: 250 }}
                defaultSelectedKey={timeUnit}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                  if (!option) return;
                  setTimeUnit(option?.key.toString());
                  let copyTask = props.task.clone();
                  if (option?.key.toString() !== 'hours') {
                    copyTask.duration = props.task.duration * 8;
                  } else {
                    copyTask.duration = Math.floor(props.task.duration / 8);
                  }
                  props.onUpdate(copyTask);
                }}
                disabled={isReadonly}
              />
            </Stack.Item>
            {props.task.isInstance() && (
              <Stack.Item>
                <TextField
                  label={t('task:Deadline.Label')}
                  styles={{
                    fieldGroup: {
                      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
                    },
                  }}
                  readOnly
                  value={toLocaleDateTimeMedium(props.task.getDeadline())}
                ></TextField>
              </Stack.Item>
            )}
            {timeUnit !== 'hours' && (
              <Stack.Item>
                <TextField
                  label={t('task:Deadline.CalcHours')}
                  styles={{
                    fieldGroup: {
                      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
                    },
                    root: { width: 80 },
                  }}
                  readOnly
                  value={props.task.duration.toLocaleString()}
                ></TextField>
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      )}
      {(props.task.taskType !== TaskTypes.Normal || !props.task.recurringPattern.isActive) && (
        <Stack.Item grow>
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            {isReadonly && (
              <Fragment>
                <Stack.Item grow>
                  <Label>{t('task:Deadline.Label')}</Label>
                  <Text>{`${toLocaleDateLong(props.task.deadline)}, ${toLocaleTimeNoSeconds(
                    props.task.deadline,
                  )}`}</Text>
                </Stack.Item>
              </Fragment>
            )}
            {!isReadonly && (
              <Fragment>
                <Stack.Item grow>
                  <LocalizedDatePicker
                    label={t('task:Deadline.Label')}
                    showMonthPickerAsOverlay={false}
                    showWeekNumbers={true}
                    value={props.task.deadline}
                    minDate={props.task.endDateTime}
                    onDateChange={(date: Date | undefined) => {
                      if (!date) return;
                      const copyTask = props.task.clone();
                      copyTask.deadline = setNewDateKeepTime(date, copyTask.deadline);
                      props.onUpdate(copyTask);
                    }}
                  />
                  <Text variant="small" styles={{ root: { color: 'red' } }}>
                    {props.getErrorMessage('deadline')}
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <TimePicker
                    selectedTime={props.task.deadline}
                    label={t('task:EndTime.Label')}
                    updateTime={(hours: number, mins: number) => {
                      const copyTask = props.task.clone();
                      copyTask.deadline = setNewTimeKeepDate(copyTask.deadline, hours, mins);
                      props.onUpdate(copyTask);
                    }}
                  />
                </Stack.Item>
              </Fragment>
            )}
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};
