import { IAppContext } from './../App/AppContext';
import { IColumn } from '@fluentui/react';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from './localstorage';
import Logger from 'services/Logging/logService';

export const loadColumnsFromLocalStorage = (
  appContext: IAppContext,
  cacheKey: LocalStorageKeys,
  columnKeys: string[],
): string[] => {
  try {
    const storedData = getLocalStorageData(appContext, cacheKey);
    if (storedData) {
      return storedData.split(',');
    }
  } catch (err) {
    Logger.debug('loadSortFromLocalStorage', err);
  }

  return [];
};

export const saveColumnsToLocalStorage = (
  appContext: IAppContext,
  cacheKey: LocalStorageKeys,
  columnKeys: string[],
) => {
  setLocalStorageData(appContext, cacheKey, columnKeys.join(','));
};

export const filterColumnsFromLocalStorage = (appContext: IAppContext, cacheKey: LocalStorageKeys, columns: IColumn[]): IColumn[] => {
  const keys = columns.map((c) => c.key);
  const hiddenCols = loadColumnsFromLocalStorage(appContext, cacheKey, keys);
  const newCols = [...columns];

  return newCols.filter((col) => !hiddenCols.some((hiddenKey) => hiddenKey === col.key));
};
