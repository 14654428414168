import { Stack, IconButton, Separator, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import AppContext from 'App/AppContext';
import WarningMessage from 'components/Notification/WarningMessage';
import {
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  refreshIcon,
  sharepointIcon,
} from 'globalStyles';
import { ColumnDefinition } from 'microsoft-graph';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphSharepointListRequest } from 'services/Auth/authConfig';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import {
  graphGetListItemWithFields,
  graphGetFieldsToSelectFromColumn,
  graphGetList,
  graphGetListColumns,
  graphGetListItems,
  graphGetListsForSite,
} from 'services/Graph/graphService';
import AppError from 'utils/appError';
import DirectoryParents from './DirectoryParents';
import MyListItems from './MyListItems';
import MyLists from './MyLists';
import MySites, { MySiteAuthTypes } from './MySites';
import { IList, IListItem, ISite } from 'services/Graph/SharepointInterfaces';
import ResourceLink from 'models/resourceLink';
import { navigateToExternalUrl } from 'utils/url';

export interface ParentListInformation {
  tenantId?: string;
  siteId: string;
  listId: string;
}

interface ISharepointListItemPickerProps {
  invalidAuthToken: () => void;
  onSelect?: (items: IListItem[]) => void;
  parentList?: ParentListInformation;
  viewMode?: boolean;
  filter?: string;
  filterId?: string;
  existingLinks?: ResourceLink[];
  linkToEdit?: ResourceLink;
}

const SharepointListItemPicker = (props: ISharepointListItemPickerProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['sharepoint', 'translation']);
  const [selectedSite, setSelectedSite] = useState<ISite | undefined>(undefined);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [lists, setLists] = useState<IList[]>([]);
  const [selectedList, setSelectedList] = useState<IList | undefined>(undefined);
  const [listItems, setListItems] = useState<IListItem[]>([]);
  const [columnDefinitions, setColumnDefinitions] = useState<ColumnDefinition[]>([]);
  const [spError, setSPError] = useState<string | undefined>(undefined);

  //these are only to check whether input is changed to perform a reload
  const [parentInfo, setParentInfo] = useState<ParentListInformation | undefined>(undefined);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [filterId, setFilterId] = useState<string | undefined>(undefined);

  const checkToken = (token: string): boolean => {
    if (!token) {
      props.invalidAuthToken();
      appContext.showNotification(t('translation:General.Notifications.TryAgainAfterConsent'));

      return false;
    }

    return true;
  };

  const loadParentInformation = async () => {
    if (isBusy || !props.parentList) return;
    try {
      setIsBusy(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointListRequest.scopes,
        props.parentList.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      const _list: IList = await graphGetList(graphInterface.client, props.parentList.siteId, props.parentList.listId);
      _list.tenantId = props.parentList.tenantId;

      const _columnDefinitions = await graphGetListColumns(
        graphInterface.client,
        props.parentList.siteId,
        props.parentList.listId,
      );

      let _listItems: IListItem[] = [];
      if (props.filterId) {
        const li = await graphGetListItemWithFields(
          graphInterface.client,
          props.parentList.siteId,
          props.parentList.listId,
          props.filterId,
          graphGetFieldsToSelectFromColumn(_columnDefinitions),
        );
        if (li) _listItems.push(li);
      } else {
        _listItems = await graphGetListItems(
          graphInterface.client,
          props.parentList.siteId,
          props.parentList.listId,
          graphGetFieldsToSelectFromColumn(_columnDefinitions),
          props.filter,
        );
      }

      _listItems.forEach((l) => (l.tenantId = props.parentList?.tenantId));

      setSelectedList(_list);
      setListItems(_listItems);
      setColumnDefinitions(_columnDefinitions);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const fetchLists = async () => {
    if (isBusy || selectedSite === undefined || !selectedSite.id) return;
    try {
      setIsBusy(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointListRequest.scopes,
        selectedSite.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      const _lists: IList[] = await graphGetListsForSite(graphInterface.client, selectedSite.id);
      _lists.forEach((l) => (l.tenantId = selectedSite.tenantId));
      setLists(_lists);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const fetchItemsForLists = async () => {
    if (
      isBusy ||
      (!props.parentList && selectedSite === undefined) ||
      selectedList === undefined ||
      selectedList.id === undefined ||
      !selectedList.parentReference?.siteId
    )
      return;

    try {
      setIsBusy(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointListRequest.scopes,
        selectedList.tenantId,
      );

      if (!checkToken(graphInterface.accessToken)) return;
      const _columnDefinitions = await graphGetListColumns(
        graphInterface.client,
        selectedList.parentReference.siteId,
        selectedList.id,
      );

      let _listItems: IListItem[] = [];
      if (props.filterId) {
        const li = await graphGetListItemWithFields(
          graphInterface.client,
          selectedList.parentReference.siteId,
          selectedList.id,
          props.filterId,
          graphGetFieldsToSelectFromColumn(_columnDefinitions),
        );
        if (li) _listItems.push(li);
      } else {
        _listItems = await graphGetListItems(
          graphInterface.client,
          selectedList.parentReference.siteId,
          selectedList.id,
          graphGetFieldsToSelectFromColumn(_columnDefinitions),
          props.filter,
        );
      }

      _listItems.forEach((l) => (l.tenantId = props.parentList?.tenantId));

      setColumnDefinitions(_columnDefinitions);
      setListItems(_listItems);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const updateSiteSelection = (site?: ISite) => {
    setSelectedSite(site);
    setSelectedList(undefined);
    setListItems([]);
    setColumnDefinitions([]);
  };

  useEffect(() => {
    fetchItemsForLists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  useEffect(() => {
    if (props.parentList) {
      //changing the parentFolder prop triggers a reload: check whether this is not only a new object but has also different content
      if (
        props.parentList.siteId !== parentInfo?.siteId ||
        props.parentList.listId !== parentInfo?.listId ||
        props.parentList.tenantId !== parentInfo?.tenantId ||
        props.filter !== filter ||
        props.filterId !== filterId
      ) {
        setParentInfo(props.parentList);
        setFilter(props.filter);
        setFilterId(props.filterId);

        loadParentInformation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentList, props.filter, props.filterId]);

  useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  const onRefresh = () => {
    //Refresh Lists for Group
    if (selectedList) {
      fetchItemsForLists();

      return;
    }
    if (selectedSite) {
      fetchLists();

      return;
    }
  };

  const onOpenSharePoint = () => {
    if (!selectedList?.webUrl) return;
    navigateToExternalUrl(selectedList.webUrl, '', '');
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {/* Body Left and Right*/}
      <Stack.Item grow>
        <Stack horizontal styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          {/* Left Groups Part*/}
          {!props.parentList && (
            <Stack.Item styles={{ root: { width: '30%', padding: 5 } }}>
              <MySites
                onSelect={(item) => updateSiteSelection(item)}
                selectedSite={selectedSite}
                checkToken={checkToken}
                authType={MySiteAuthTypes.List}
              />
              <Separator vertical />
            </Stack.Item>
          )}
          {/* Right Part*/}
          <Stack.Item grow styles={{ root: { width: !props.parentList ? '70%' : '100%', padding: 5 } }}>
            <Stack verticalFill>
              {(props.parentList || selectedSite) && (
                <Stack.Item>
                  <Stack horizontal wrap horizontalAlign="space-between">
                    <Stack.Item>
                      <DirectoryParents
                        drive={selectedList}
                        parents={[]}
                        site={selectedSite}
                        onSelectDrive={() => {}}
                        onSelectDriveFolder={() => {}}
                        onBack={() => {}}
                        onSelectSite={updateSiteSelection}
                        parentExists={!!props.parentList}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      {selectedList && (
                        <TooltipHost content={t('sharepoint:CommandBar.OpenSharePoint')}>
                          <IconButton iconProps={sharepointIcon} onClick={onOpenSharePoint} />
                        </TooltipHost>
                      )}
                      <TooltipHost content={t('sharepoint:CommandBar.Refresh')}>
                        <IconButton iconProps={refreshIcon} onClick={onRefresh} />
                      </TooltipHost>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              )}
              {!selectedList && !selectedSite && isBusy && (
                <Stack verticalFill verticalAlign="center">
                  <Spinner size={SpinnerSize.large} />
                </Stack>
              )}
              {spError && !isBusy && (
                <Stack verticalFill>
                  <WarningMessage message={spError} onDismiss={undefined}></WarningMessage>
                </Stack>
              )}
              {!spError && selectedSite && selectedList === undefined && (
                <Stack.Item grow>
                  <MyLists
                    onSelectListItemMode={(item: IList) => setSelectedList(item)}
                    lists={lists}
                    onSelect={(item?: IList) => {
                      setSelectedList(item);
                    }}
                    isListLoading={isBusy}
                  />
                </Stack.Item>
              )}
              {!spError && selectedList !== undefined && (
                <Stack.Item grow>
                  <MyListItems
                    items={listItems}
                    columns={columnDefinitions}
                    loading={isBusy}
                    onSelect={(_items: IListItem[]) => {
                      if (props.onSelect) {
                        props.onSelect(_items);
                      }
                    }}
                    viewMode={props.viewMode}
                    existingLinks={props.existingLinks}
                    linkToEdit={props.linkToEdit}
                  />
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default SharepointListItemPicker;
