import http from './httpService';
import AppError from 'utils/appError';
import { toBlob } from 'utils/string';
import ReportRequestDTO, { ReportStorageBehavior } from 'models/dto/Reports/ReportRequestDTO';

export const apiGetReport = async (
  reportRequest: ReportRequestDTO,
  accessToken: string,
): Promise<Blob | string | undefined> => {
  try {
    if (!reportRequest.reportDefinition) throw new AppError('reportRequest.report is undefined');

    const ar = await http.post<string>(
      `/reports/${reportRequest.reportDefinition.id}`,
      reportRequest,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data || ar.data.length === 0) return undefined;

    if (reportRequest.storageBehavior === ReportStorageBehavior.SharePoint) {
      return ar.data;
    } else {
      return toBlob(ar.data);
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiCheckSharePointReportWriteAccess = async (accessToken: string): Promise<string> => {
  try {
    const ar = await http.get<string>(`/reports/haswriteaccess`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
