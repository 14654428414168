export class RiskObjectiveDTO {
  riskId: number;

  objectiveId: number;

  constructor(_riskId: number = -1, _objectiveId: number = -1) {
    this.riskId = _riskId;
    this.objectiveId = _objectiveId;
  }
}
