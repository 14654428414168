import Language from '../language';

export class Classification_Translation {
  classificationId: number;

  languageId: number;

  name: string;

  description?: string;

  examples?: string;

  lang?: Language;

  constructor() {
    this.classificationId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
