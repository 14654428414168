import { TFunction } from 'i18next';

export const getProgressLabelDeInstall = (
  step: number | undefined,
  item: string | undefined,
  t: TFunction<string[]>,
): string => {
  let label = '';
  switch (step) {
    case 1:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step1');
      break;
    case 2:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step2', { name: item });
      break;
    case 3:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step3');
      break;
    case 4:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step4');
      break;
    case 5:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step5');
      break;
    case 6:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step6');
      break;
    case 7:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step7');
      break;
    case 8:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step8');
      break;
    case 9:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step9');
      break;
    case 10:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step10');
      break;
    case 11:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step11');
      break;
    case 12:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step12');
      break;
    case 13:
      label = t('store:TabStore.Install.Dialogs.SharePointConfig.DeleteProgress.Step13');
      break;
    default:
      return '';
  }

  const percent = Math.floor((100 / 13) * (step || 0));

  return label + ' (' + percent.toString() + '%)';
};
