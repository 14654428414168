import {
  HoverCard,
  HoverCardType,
  IButtonStyles,
  IPlainCardProps,
  IconButton,
  Link,
  List,
  Persona,
  PersonaSize,
  Stack,
  Text,
  getTheme,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapExtraSmall,
  globalStackTokensGapSmall,
  globalTextStylesBold,
} from 'globalStyles';
import Group from 'models/group';
import UserTag from './UserTag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AppContext from 'App/AppContext';
import { useContext } from 'react';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { sortOnString } from 'utils/sorting';

interface IGroupTagProps {
  group: Group;
  compact?: boolean;
  hideBackground?: boolean;
  allowHoverCard: boolean;
  onRemove?: (item: Group) => void;
}

const GroupTag = (props: IGroupTagProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'adminGroups']);
  const theme = getTheme();
  const history = useHistory();
  const size = props.compact ? PersonaSize.size24 : PersonaSize.size40;
  const name = props.group.name + (props.allowHoverCard && props.group.users ? ` (${props.group.users.length})` : '');

  const tagButtonStyles: IButtonStyles = {
    root: {
      borderRadius: '10px',
      height: props.compact ? '24px' : '40px',
      width: props.compact ? '24px' : '40px',
    },
  };

  const navigateToGroups = () => {
    const url = '/admin/groups';
    history.push(url);
  };

  const onRenderGroupCard = (item: Group): JSX.Element => {
    return (
      <Stack
        styles={{
          root: { width: 300, minHeight: 100, height: 300, maxHeight: '50vh', padding: 20 },
        }}
      >
        <Stack
          verticalFill
          tokens={globalStackTokensGapSmall}
          styles={{
            root: { overflow: 'scroll' },
          }}
        >
          <Stack.Item>
            <Text styles={globalTextStylesBold}>{t('adminGroups:Sync.Members')}</Text>
          </Stack.Item>
          {item.users && item.users.length > 0 && (
            <Stack.Item>
              <List
                items={item.users.sort((a, b) => sortOnString(a.name, b.name))}
                onRenderCell={(user) => {
                  if (!user) return null;

                  return (
                    <Stack.Item key={user.id} styles={{ root: { paddingBottom: 3 } }}>
                      <UserTag user={user} compact={true} hideBackground={props.hideBackground} />
                    </Stack.Item>
                  );
                }}
              />
            </Stack.Item>
          )}
          {(!item.users || item.users.length === 0) && <Text>{t('adminGroups:Sync.NoMembers')}</Text>}
        </Stack>
        {hasUserFeature(appContext, FeatureTypes.AdminAuth) && (
          <Stack.Item>
            <Link onClick={() => navigateToGroups()}>
              {item.syncEnabled ? t('adminGroups:Sync.EnabledInfo') : t('adminGroups:Sync.DisabledInfo')}
            </Link>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderGroupCard,
    renderData: props.group,
  };

  return (
    <HoverCard
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={props.allowHoverCard && props.group.users !== undefined}
      shouldBlockHoverCard={() => !props.allowHoverCard || !props.group.users}
    >
      <Stack
        horizontal
        tokens={globalStackTokensGapExtraSmall}
        verticalAlign="center"
        styles={
          props.hideBackground !== true
            ? {
                root: {
                  background: appContext.useDarkMode ? theme.palette.neutralSecondary : theme.palette.neutralLight,
                  borderRadius: '10px',
                  height: 1 + (props.compact ? 24 : 40),
                },
              }
            : undefined
        }
      >
        <Stack.Item>
          <Persona text={name} secondaryText={props.group.email} size={size} hidePersonaDetails={false} />
        </Stack.Item>
        {props.onRemove && (
          <Stack.Item>
            <IconButton
              onClick={() => {
                if (props.onRemove) {
                  props.onRemove(props.group);
                }
              }}
              styles={tagButtonStyles}
              iconProps={cancelIcon}
            />
          </Stack.Item>
        )}
      </Stack>
    </HoverCard>
  );
};

export default GroupTag;
