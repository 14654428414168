import { getTheme, Icon, Stack, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colorMixer } from 'utils/color';

interface IGenericApprovalButtonProps {
  compact?: boolean;
  disabled?: boolean;
  icon: JSX.Element;
  sideText: string;
  selected: boolean;
  selectedColor: string;
  onClick: () => void;
}

const ApprovalButton = (props: IGenericApprovalButtonProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const getButtonBackground = (): string => {
    if (props.disabled && props.selected) {
      const baseColor = appContext.useDarkMode ? theme.palette.neutralDark : theme.palette.neutralLight;

      return colorMixer(baseColor, props.selectedColor, 0.5);
    } else if (props.disabled) {
      return appContext.useDarkMode ? theme.palette.neutralDark : theme.palette.neutralLight;
    } else if (props.selected) {
      return props.selectedColor;
    }

    if (hovered) return appContext.useDarkMode ? theme.palette.neutralPrimaryAlt : theme.palette.themeLight;

    return appContext.useDarkMode ? theme.palette.neutralPrimary : theme.palette.themeLighterAlt;
  };

  return (
    <Stack
      tabIndex={0}
      onKeyDown={(ev) => {
        if (!props.disabled && (ev.key === 'Enter' || ev.key === ' ')) {
          props.onClick();
        }
      }}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
      styles={{
        root: {
          width: props.compact ? 120 : 160,
          height: props.compact ? 32 : 36,
          borderWidth: 1,
          borderS: '1px solid grey',
          borderRadius: '3px',
          backgroundColor: getButtonBackground(),
          padding: 5,
        },
      }}
      onMouseOver={() => {
        if (!props.disabled) setHovered(true);
      }}
      onMouseLeave={() => {
        if (!props.disabled) setHovered(false);
      }}
    >
      <Stack.Item>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={props.compact ? globalStackTokensGapExtraSmall : globalStackTokensGapSmall}
        >
          <Stack.Item styles={{ root: { paddingTop: 2 } }}>{props.icon}</Stack.Item>
          <Stack.Item>
            <Text styles={globalTextStylesBold}>{props.sideText}</Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

interface IApprovalButtonProps {
  selected: boolean;
  disabled?: boolean;
  compact?: boolean;
  onClick: () => void;
}

export const ApprovalButtonApprove = (props: IApprovalButtonProps) => {
  const { t } = useTranslation(['library']);
  const iconSize = props.compact ? 18 : 24;

  return (
    <ApprovalButton
      compact={props.compact}
      disabled={props.disabled}
      onClick={props.onClick}
      icon={<Icon iconName="Checkmark" style={{ fontSize: iconSize }} />}
      selected={props.selected}
      sideText={t('library:ApprovalTasks.ApprovalTaskDetail.ApproveActions.Approve')}
      selectedColor={'#6fc98f'}
    />
  );
};

export const ApprovalButtonReject = (props: IApprovalButtonProps) => {
  const { t } = useTranslation(['library']);
  const iconSize = props.compact ? 18 : 24;

  return (
    <ApprovalButton
      compact={props.compact}
      disabled={props.disabled}
      onClick={props.onClick}
      icon={<Icon iconName="Cancel" style={{ fontSize: iconSize }} />}
      selected={props.selected}
      sideText={t('library:ApprovalTasks.ApprovalTaskDetail.ApproveActions.Reject')}
      selectedColor={'#eb5752'}
    />
  );
};
