import { Checkbox, Stack } from '@fluentui/react';
import { globalStackTokensGapSmall } from 'globalStyles';
import RecurringPattern from 'models/recurringPattern';

interface IRecurringYearSelectorProps {
  pattern: RecurringPattern;
  disabled?: boolean;
  onChange?: (year: number, checked: boolean) => void;
}

export const RecurringYearSelector = (props: IRecurringYearSelectorProps) => {
  const onChange = (year: number, checked: boolean) => {
    if (props.onChange && !props.disabled) {
      props.onChange(year, checked);
    }
  };

  const isLastCheck = (year: number, checked: boolean): boolean => {
    if (checked === true) return false;
    const newPattern = props.pattern.clone();
    newPattern.yearSelect[year] = 0;
    if (newPattern.yearSelect[0] === 0 && newPattern.yearSelect[1] === 0 && newPattern.yearSelect[2] === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeCheck = (
    year: number,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined,
  ) => {
    if (!isLastCheck(year, checked ?? false)) {
      onChange(year, checked ?? false);
    } else {
      ev?.preventDefault();
    }
  };

  return (
    <Stack horizontal tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Checkbox
          label={'1'}
          disabled={props.disabled}
          checked={props.pattern.yearSelect[0] === 1}
          onChange={(ev, checked) => onChangeCheck(0, ev, checked)}
        ></Checkbox>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label={'2'}
          disabled={props.disabled}
          checked={props.pattern.yearSelect[1] === 1}
          onChange={(ev, checked) => onChangeCheck(1, ev, checked)}
        ></Checkbox>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label={'3'}
          disabled={props.disabled}
          checked={props.pattern.yearSelect[2] === 1}
          onChange={(ev, checked) => onChangeCheck(2, ev, checked)}
        ></Checkbox>
      </Stack.Item>
    </Stack>
  );
};
