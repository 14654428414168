export class ObjectiveControlDTO {
  controlId: number;

  objectiveId: number;

  constructor(_controlId: number = -1, _objectiveId: number = -1) {
    this.controlId = _controlId;
    this.objectiveId = _objectiveId;
  }
}
