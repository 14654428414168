import { DetailsList, IColumn, SelectionMode, Stack, IGroup } from '@fluentui/react';
import ResourceLink from 'models/resourceLink';
import ResourceList, { ResourceListType } from 'models/resourceList';
import { sortOnString } from 'utils/sorting';
import SingleResourceLink from './SingleResourceLink';

interface ISingleTypeResourceList {
  type: ResourceListType;
  items: ResourceLink[];
  navigateExternal?: boolean;
  readonly: boolean;
  onDelete: (item: ResourceLink) => void;
  onPin?: (item: ResourceLink, pinned: boolean) => void;
}

export const SingleTypeResourceList = (props: ISingleTypeResourceList) => {
  const getHeader = (list: ResourceList): string => {
    return list.name;
  };

  const columns: IColumn[] = [
    {
      key: 'link',
      isMultiline: true,
      name: '',
      minWidth: 200,
      onRender: (item?: ResourceLink, index?: number, column?: IColumn) => {
        if (!item) return null;

        return (
          <SingleResourceLink
            type={props.type}
            link={item}
            onDelete={props.onDelete}
            onPin={props.onPin}
            navigateExternal={props.navigateExternal}
            readonly={props.readonly}
          />
        );
      },
    },
  ];

  const group: IGroup = {
    key: '',
    name: props.items.length > 0 ? getHeader(props.items[0].list) : '',
    startIndex: 0,
    level: 0,
    count: props.items.length,
  };

  //
  // Main render
  //
  return (
    <Stack>
      <Stack.Item>
        <DetailsList
          compact
          indentWidth={0}
          styles={{ root: { overflowX: 'hidden' } }}
          items={props.items
            .filter((n) => !n.list.isVirtual || !n.isWholeList()) //filter out whole list items for virtual lists
            .sort((a: ResourceLink, b: ResourceLink) => {
              return sortOnString(a.linkName, b.linkName);
            })}
          columns={columns}
          selectionMode={SelectionMode.none}
          groups={[group]}
          isHeaderVisible={false}
          groupProps={{
            showEmptyGroups: true,
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default SingleTypeResourceList;
