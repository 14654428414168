import React, { Fragment, useCallback, useState } from 'react';
import User from 'models/user';
import {
  ComboBox,
  DirectionalHint,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  IconButton,
  IStackItemStyles,
  Stack,
  Callout,
  Text,
  PrimaryButton,
  DefaultButton,
  ActionButton,
  IButtonStyles,
} from '@fluentui/react';
import {
  cancelIcon,
  downArrowIcon,
  globalComboContainerHeight,
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
} from 'globalStyles';
import UserPicker from 'components/Pickers/UserPicker';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import { useTranslation } from 'react-i18next';

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: 300,
  },
  ...globalComboContainerHeight,
};

interface IFilterAuditTrailProps {
  selectedUser: User | undefined;
  onSelectedUserChange: (user: User | undefined) => void;
  selectedAction: number | undefined;
  onSelectedActionChange: (id: number | undefined) => void;
  startDate: Date | undefined;
  onStartDateChange: (date: Date | undefined) => void;
  endDate: Date | undefined;
  onEndDateChange: (date: Date | undefined) => void;
  actionList: IComboBoxOption[];
  showDialog: string;
  onShowDialogChange: (filter: string) => void;
}

const filterStackItemStyles: IStackItemStyles = {
  root: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const filterFooterStackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

const filterButtonStyle: IButtonStyles = {
  label: {
    fontSize: '0.75rem',
    margin: '0 0.2rem',
    display: 'flex',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  icon: {
    fontSize: '0.75rem',
    margin: '0 0.1rem',
  },
};

const FilterAuditTrail = (props: IFilterAuditTrailProps) => {
  const { t } = useTranslation(['translation', 'auditTrail']);
  const [user, setUser] = useState<User | undefined>(props.selectedUser);
  const [actionID, setActionID] = useState<number | undefined>(props.selectedAction);
  const [startDate, setStartDate] = useState<Date | undefined>(props.startDate);
  const [endDate, setEndDate] = useState<Date | undefined>(props.endDate);

  const onChange = useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
      if (option) {
        setActionID(option.key as number);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dateChangeHandler = (date: Date | undefined, field: string) => {
    if (date) {
      const updateDate = field === 'startDate' ? setStartDate : setEndDate;
      updateDate(date);
    }
  };

  const dateClearHandler = (date: undefined, field: string) => {
    const updateDate = field === 'startDate' ? setStartDate : setEndDate;
    updateDate(date);
  };

  const filterClickHandler = useCallback(
    (filter: string) => {
      props.onShowDialogChange(props.showDialog === '' ? filter : props.showDialog === filter ? '' : filter);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.showDialog],
  );

  const saveFilter = () => {
    switch (props.showDialog) {
      case 'User': {
        props.onSelectedUserChange(user);
        break;
      }
      case 'Action': {
        props.onSelectedActionChange(actionID!);
        break;
      }
      case 'StartDateTime': {
        props.onStartDateChange(startDate);
        break;
      }
      case 'EndDateTime': {
        props.onEndDateChange(endDate);
        break;
      }
    }
    props.onShowDialogChange('');
  };

  const clearFilter = () => {
    switch (props.showDialog) {
      case 'User': {
        setUser(undefined);
        props.onSelectedUserChange(undefined);
        break;
      }
      case 'Action': {
        setActionID(undefined);
        props.onSelectedActionChange(undefined);
        break;
      }
      case 'StartDateTime': {
        setStartDate(undefined);
        props.onStartDateChange(undefined);
        break;
      }
      case 'EndDateTime': {
        setEndDate(undefined);
        props.onEndDateChange(undefined);
        break;
      }
    }
    props.onShowDialogChange('');
  };

  return (
    <Fragment>
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item
          styles={filterStackItemStyles}
          onClick={() => filterClickHandler('User')}
          className="calloutTargetUser"
        >
          <ActionButton iconProps={downArrowIcon} allowDisabledFocus styles={filterButtonStyle}>
            {t('auditTrail:AuditFilter.User')}
          </ActionButton>
        </Stack.Item>
        <Stack.Item
          styles={filterStackItemStyles}
          onClick={() => filterClickHandler('Action')}
          className="calloutTargetAction"
        >
          <ActionButton iconProps={downArrowIcon} allowDisabledFocus styles={filterButtonStyle}>
            {t('auditTrail:AuditFilter.Action')}
          </ActionButton>
        </Stack.Item>
        <Stack.Item
          styles={filterStackItemStyles}
          onClick={() => filterClickHandler('StartDateTime')}
          className="calloutTargetStartDateTime"
        >
          <ActionButton iconProps={downArrowIcon} allowDisabledFocus styles={filterButtonStyle}>
            {t('auditTrail:AuditFilter.StartDateTime')}
          </ActionButton>
        </Stack.Item>
        <Stack.Item
          styles={filterStackItemStyles}
          onClick={() => filterClickHandler('EndDateTime')}
          className="calloutTargetEndDateTime"
        >
          <ActionButton iconProps={downArrowIcon} allowDisabledFocus styles={filterButtonStyle}>
            {t('auditTrail:AuditFilter.EndDateTime')}
          </ActionButton>
        </Stack.Item>
      </Stack>
      <Callout
        alignTargetEdge={true}
        gapSpace={0}
        target={`.calloutTarget${props.showDialog}`}
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomLeftEdge}
        hidden={props.showDialog === ''}
      >
        <Stack
          styles={globalStackStylesHeight100PaddingSmall}
          style={{ width: '20rem', minWidth: 250, maxWidth: 300 }}
          tokens={globalStackTokensGapSmall}
        >
          <Stack.Item>
            <Text>
              {props.showDialog === 'User' && t('auditTrail:AuditFilter.FilterBy.User')}
              {props.showDialog === 'Action' && t('auditTrail:AuditFilter.FilterBy.Action')}
              {props.showDialog === 'StartDateTime' && t('auditTrail:AuditFilter.FilterBy.StartDateTime')}
              {props.showDialog === 'EndDateTime' && t('auditTrail:AuditFilter.FilterBy.EndDateTime')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            {props.showDialog === 'User' ? (
              <UserPicker
                users={[]}
                selectedItemId={user ? user.id : ''}
                onSelect={(item: User | undefined) => {
                  if (item) {
                    setUser(item);
                  } else {
                    setUser(undefined);
                  }
                }}
              />
            ) : props.showDialog === 'Action' ? (
              <ComboBox
                label=""
                onChange={onChange}
                selectedKey={[actionID!] || []}
                options={props.actionList}
                styles={comboBoxStyles}
              />
            ) : props.showDialog === 'StartDateTime' ? (
              <Stack horizontal>
                <Stack.Item grow>
                  <LocalizedDatePicker
                    showMonthPickerAsOverlay={false}
                    showWeekNumbers={true}
                    value={startDate!}
                    onDateChange={(date: Date | undefined) => dateChangeHandler(date, 'startDate')}
                  />
                </Stack.Item>
                {startDate && (
                  <Stack.Item>
                    <IconButton iconProps={cancelIcon} onClick={() => dateClearHandler(undefined, 'startDate')} />
                  </Stack.Item>
                )}
              </Stack>
            ) : (
              <Stack horizontal>
                <Stack.Item grow>
                  <LocalizedDatePicker
                    showMonthPickerAsOverlay={false}
                    showWeekNumbers={true}
                    value={endDate!}
                    onDateChange={(date: Date | undefined) => dateChangeHandler(date, 'endDate')}
                  />
                </Stack.Item>
                {endDate && (
                  <Stack.Item>
                    <IconButton iconProps={cancelIcon} onClick={() => dateClearHandler(undefined, 'endDate')} />
                  </Stack.Item>
                )}
              </Stack>
            )}
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapSmall} styles={filterFooterStackItemStyles}>
              <PrimaryButton text={t(`auditTrail:AuditFilter.Buttons.Ok`)} onClick={saveFilter} />
              <DefaultButton text={t(`auditTrail:AuditFilter.Buttons.Clear`)} onClick={clearFilter} />
              <DefaultButton
                text={t(`auditTrail:AuditFilter.Buttons.Cancel`)}
                onClick={() => props.onShowDialogChange('')}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </Callout>
    </Fragment>
  );
};

export default FilterAuditTrail;
