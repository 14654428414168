import Activity from 'models/activity';
import AuditTrailEntry from 'models/auditTrailEntry';
import { CommentTrailEntry } from 'models/comments';
import { SingleActivity } from './SingleActivity';
import { SingleAuditTrailEntry } from './SingleAuditTrailEntry';
import SingleComment from './SingleComment';

interface IActivityTrailItem {
  key: number;
  ActivityItem: CommentTrailEntry | Activity | AuditTrailEntry;
  onModifyComment: (existingComment: CommentTrailEntry, updatedComment: string) => void;
  onRemoveComment: (commentTrailId: number, commentTrailEntryId: number) => void;
  isActionPending: boolean;
  readOnly?: boolean;
}

export const ActivityTrailItem = (props: IActivityTrailItem) => {
  if (props.ActivityItem instanceof CommentTrailEntry) {
    return (
      <SingleComment
        comment={props.ActivityItem as CommentTrailEntry}
        onModify={props.onModifyComment}
        onRemove={props.onRemoveComment}
        isActionPending={props.isActionPending}
        readOnly={props.readOnly}
      />
    );
  } else if (props.ActivityItem instanceof AuditTrailEntry) {
    return <SingleAuditTrailEntry auditTrailEntry={props.ActivityItem} />;
  } else {
    return <SingleActivity activity={props.ActivityItem} />;
  }
};
