import { FunctionComponent } from 'react';
import Widget, { IWidgetCustomAction, WidgetTypes } from 'models/widget';
import WidgetLibraryContent from './Widgets/WidgetLibraryContent';
import WidgetMyOverdueTasks from './Widgets/WidgetMyOverdueTasks';
import WidgetMyUpcomingTasks from './Widgets/WidgetMyUpcomingTasks';
import WidgetSingleRisk from './Widgets/WidgetSingleRisk';
import WidgetMyRecentItems from './Widgets/WidgetMyRecentItems';
import WidgetMyActions from './Widgets/WidgetMyActions';
import WidgetRecentlyClosedTasks from './Widgets/WidgetRecentlyClosedTasks';
import WidgetOpenTasksPerUser from './Widgets/WidgetOpenTasksPerUser';
import WidgetNormCoverage from './Widgets/WidgetNormCoverage';
import WidgetCustomNormCoverage from './Widgets/WidgetCustomNormCoverage';
import WidgetRiskStatus from './Widgets/WidgetRiskStatus';
import WidgetRiskStatusNotAccepted from './Widgets/WidgetRiskStatusNotAccepted';
import WidgetCustomMyNormCoverage from './Widgets/WidgetCustomMyNormCoverage';
import WidgetMyNormCoverage from './Widgets/WidgetMyNormCoverage';
import WidgetSingleTask from './Widgets/WidgetSingleTask';
import WidgetAzureDefenderSecureScore from './Widgets/WidgetAzureDefenderSecureScore';
import WidgetTaskStatus from './Widgets/WidgetTaskStatus';
import WidgetSingleKPI from './Widgets/WidgetSingleKPI';
import WidgetSingleKPILastPeriod from './Widgets/WidgetSingleKPILastPeriod';
import WidgetLibraryReadResponse from './Widgets/WidgetLibraryReadResponse';
import WidgetTaskTag from './Widgets/WidgetTaskTag';
import WidgetLibraryReadStats from './Widgets/WidgetLibraryReadStats';
import { DashboardSharedData } from 'models/dashboard';

export interface IWidgetRendererProps {
  widget: Widget;
  onRegisterCustomActions?: (widget: Widget, customActions: IWidgetCustomAction[]) => void;
  sharedData?: DashboardSharedData;
  onRefreshSharedData?: () => Promise<void>;
}

const WidgetRenderer: FunctionComponent<IWidgetRendererProps> = (props: IWidgetRendererProps) => {
  switch (props.widget.widgetType) {
    case WidgetTypes.LibraryContent:
      return <WidgetLibraryContent {...props} />;
    case WidgetTypes.MyOverdueTasks:
      return <WidgetMyOverdueTasks {...props} />;
    case WidgetTypes.MyUpcomingTasks:
      return <WidgetMyUpcomingTasks {...props} />;
    case WidgetTypes.SingleRisk:
      return <WidgetSingleRisk {...props} />;
    case WidgetTypes.MyRecentItems:
      return <WidgetMyRecentItems {...props} />;
    case WidgetTypes.MyActions:
      return <WidgetMyActions {...props} />;
    case WidgetTypes.RecentlyClosedTasks:
      return <WidgetRecentlyClosedTasks {...props} />;
    case WidgetTypes.OpenTasksPerUser:
      return <WidgetOpenTasksPerUser {...props} />;
    case WidgetTypes.NormCoverage:
      return <WidgetNormCoverage {...props} />;
    case WidgetTypes.CustomNormCoverage:
      return <WidgetCustomNormCoverage {...props} />;
    case WidgetTypes.RiskStatus:
      return <WidgetRiskStatus {...props} />;
    case WidgetTypes.RiskStatusNotAccepted:
      return <WidgetRiskStatusNotAccepted {...props} />;
    case WidgetTypes.MyNormCoverage:
      return <WidgetMyNormCoverage {...props} />;
    case WidgetTypes.CustomMyNormCoverage:
      return <WidgetCustomMyNormCoverage {...props} />;
    case WidgetTypes.SingleTask:
      return <WidgetSingleTask {...props} />;
    case WidgetTypes.AzureDefenderSecureScore:
      return <WidgetAzureDefenderSecureScore {...props} />;
    case WidgetTypes.TaskStatus:
      return <WidgetTaskStatus {...props} />;
    case WidgetTypes.SingleKPI:
      return <WidgetSingleKPI {...props} />;
    case WidgetTypes.SingleKPILastPeriod:
      return <WidgetSingleKPILastPeriod {...props} />;
    case WidgetTypes.LibraryReadResponse:
      return <WidgetLibraryReadResponse {...props} />;
    case WidgetTypes.TaskTag:
      return <WidgetTaskTag {...props} />;
    case WidgetTypes.LibraryReadStats:
      return <WidgetLibraryReadStats {...props} />;
    default:
      return null;
  }
};

export default WidgetRenderer;
