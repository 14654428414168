import { Fragment, FunctionComponent, useContext, useState } from 'react';
import {
  Stack,
  Spinner,
  SpinnerSize,
  Text,
  Label,
  Dropdown,
  IDropdownOption,
  Callout,
  ICalloutContentStyles,
  PrimaryButton,
  ActionButton,
  DefaultButton,
  IChoiceGroupOption,
  ChoiceGroup,
  Separator,
  Link,
  getTheme,
  ColorPicker,
} from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import EntityPicker from 'components/Pickers/EntityPicker';
import Entity, { EntityTypes } from 'models/entity';
import { apiGetKPI, apiGetKPIs } from 'services/Api/kpiService';
import KPI, { KPIAggregationMethods, KPITypes } from 'models/kpi/kpi';
import { apiGetWidgetDataSingleKPILastPeriod } from 'services/Api/dashboardService';
import { getAggregationMethodOptions } from 'components/KPI/KPIHelpers';
import TextInfo from 'components/Notification/Info';
import { KPIGraphDataConfigDTO } from 'models/dto/kpi/kpiGraphDataDTO';
import KPIAlert, { KPIAlertPeriods } from 'models/kpi/KPIAlert';
import KPIDataContextFilter from 'components/KPI/KPIDataContextFilter';
import { KPIContextFilter } from 'models/kpi/kpiDataContext';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';
import { useHistory } from 'react-router-dom';
import { mapToKPIContextFilter } from 'mappings/kpiMapping';
import { NumericField } from 'components/Text/NumericField';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetSingleKPILastPeriodConfig {
  kpiId: number;

  aggregationMethod: KPIAggregationMethods;

  aggregationPeriod: KPIAlertPeriods;

  rawFilter: string;

  filter: KPIContextFilter;

  minValue: number;

  maxValue: number;

  tresholdHigh: number | undefined;

  tresholdLow: number | undefined;

  tresholdHighColor: string | undefined;

  tresholdMediumColor: string | undefined;

  tresholdLowColor: string | undefined;

  constructor() {
    this.kpiId = 0;
    this.aggregationMethod = KPIAggregationMethods.Count;
    this.aggregationPeriod = KPIAlertPeriods.Month;
    this.tresholdHigh = undefined;
    this.tresholdLow = undefined;
    this.tresholdHighColor = '';
    this.tresholdMediumColor = '';
    this.tresholdLowColor = '';
    this.rawFilter = '';
    this.filter = new KPIContextFilter();
    this.minValue = 0;
    this.maxValue = 100;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.kpiId = newRawConfig.kpiId ?? 0;
        this.aggregationMethod = newRawConfig.aggregationMethod ?? KPIAggregationMethods.Count;
        this.aggregationPeriod = newRawConfig.aggregationPeriod ?? KPIAlertPeriods.Month;
        this.tresholdHigh = newRawConfig.tresholdHigh;
        this.tresholdLow = newRawConfig.tresholdLow;
        this.tresholdHighColor = newRawConfig.tresholdHighColor;
        this.tresholdMediumColor = newRawConfig.tresholdMediumColor;
        this.tresholdLowColor = newRawConfig.tresholdLowColor;
        this.rawFilter = newRawConfig.rawFilter;
        this.minValue = newRawConfig.minValue ?? 0;
        this.maxValue = newRawConfig.maxValue ?? 100;
        if (this.rawFilter) {
          this.filter = KPIContextFilter.fromJSON(this.rawFilter);
        }
      } catch (err) {
        //ignore
      }
    }
  }

  clone(): WidgetSingleKPILastPeriodConfig {
    const newConfig = new WidgetSingleKPILastPeriodConfig();
    newConfig.kpiId = this.kpiId;
    newConfig.aggregationMethod = this.aggregationMethod;
    newConfig.aggregationPeriod = this.aggregationPeriod;
    newConfig.tresholdHigh = this.tresholdHigh;
    newConfig.tresholdLow = this.tresholdLow;
    newConfig.tresholdHighColor = this.tresholdHighColor;
    newConfig.tresholdMediumColor = this.tresholdMediumColor;
    newConfig.tresholdLowColor = this.tresholdLowColor;
    newConfig.filter = this.filter;
    newConfig.rawFilter = this.rawFilter;
    newConfig.minValue = this.minValue;
    newConfig.maxValue = this.maxValue;

    return newConfig;
  }
}

//segment color config
const defColorTresholdHigh = '#D14222';
const defColorTresholdMedium = '#E1D045';
const defColorTresholdLow = '#3DAA47';

const getSegmentColors = (config: WidgetSingleKPILastPeriodConfig): string[] | undefined => {
  if (getSegmentStops(config) !== undefined) {
    const colorLow = config.tresholdLowColor || defColorTresholdLow;
    const colorMedium = config.tresholdMediumColor || defColorTresholdMedium;
    const colorHigh = config.tresholdHighColor || defColorTresholdHigh;

    return [colorLow, colorMedium, colorHigh];
  }

  return [config.tresholdLowColor || defColorTresholdLow];
};

//segment stops config
const getSegmentStops = (config: WidgetSingleKPILastPeriodConfig): number[] | undefined => {
  if (config.tresholdHigh !== undefined && config.tresholdLow !== undefined) {
    return [config.minValue, config.tresholdLow, config.tresholdHigh, config.maxValue];
  }

  return undefined;
};

//value format
const getValueFormat = (kpi: KPI, aggregationMethod: KPIAggregationMethods): string => {
  if (kpi.type === KPITypes.SuccesError && aggregationMethod === KPIAggregationMethods.Avg) {
    return `.0%`;
  }

  return `.${kpi.decimalCount ?? 0}f`;
};

//mapping function for back-end
export const mapToKPIGraphDataConfigFromSingleKPIWidgetConfig = (
  config: WidgetSingleKPILastPeriodConfig,
): KPIGraphDataConfigDTO => {
  const output = new KPIGraphDataConfigDTO();

  output.kpiId = config.kpiId;
  output.aggregationPeriod = config.aggregationPeriod;
  output.aggregationMethod = config.aggregationMethod;
  output.contextFilter = mapToKPIContextFilter(config.filter);

  return output;
};

interface IWidgetSingleKPILastPeriodProps extends IWidgetRendererProps {}

const WidgetSingleKPILastPeriod: FunctionComponent<IWidgetSingleKPILastPeriodProps> = (
  props: IWidgetSingleKPILastPeriodProps,
) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const history = useHistory();
  const appContext = React.useContext(AppContext);
  const [kpi, setKPI] = React.useState<KPI | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [config, setConfig] = useState<WidgetSingleKPILastPeriodConfig | undefined>(undefined);
  const theme = getTheme();

  React.useEffect(() => {
    const loadConfig = (): WidgetSingleKPILastPeriodConfig => {
      const newConfig = new WidgetSingleKPILastPeriodConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);
        setKPI(undefined);
        setConfig(undefined);

        const config = loadConfig();
        setConfig(config);

        if (config && config.kpiId) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const kpi = await apiGetKPI(config.kpiId, accessToken, undefined);
          const graphConfig = mapToKPIGraphDataConfigFromSingleKPIWidgetConfig(config);
          const data = await apiGetWidgetDataSingleKPILastPeriod(graphConfig, accessToken);
          kpi.graphData = data;
          setKPI(kpi);
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!kpi || !kpi.graphData || !kpi.graphData.points.length) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:SingleKPILastPeriod.Config.Invalid')}</Text>
      </Stack>
    );
  }

  const navigateToKPIData = () => {
    const url = `/kpi/${kpi.kpiId}/data`;
    history.push(url);
  };

  const textColor = appContext.useDarkMode ? theme.palette.white : theme.palette.black;

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall} horizontalAlign="center">
      <Stack.Item styles={{ root: { paddingTop: 15 } }}>
        <ReactSpeedometer
          segments={config ? (getSegmentStops(config) === undefined ? 1 : 3) : undefined}
          minValue={config?.minValue}
          maxValue={config?.maxValue}
          value={kpi.graphData?.points[0].value}
          width={300}
          height={200}
          needleColor={'#3366ff'}
          segmentColors={config ? getSegmentColors(config) : undefined}
          ringWidth={45}
          valueTextFontSize="18"
          valueTextFontWeight="500"
          needleHeightRatio={0.8}
          textColor={textColor}
          needleTransitionDuration={500}
          needleTransition={Transition.easeBackOut}
          valueFormat={getValueFormat(kpi, config?.aggregationMethod ?? KPIAggregationMethods.Count)}
          customSegmentStops={config ? getSegmentStops(config) : undefined}
        />
      </Stack.Item>
      <Link onClick={() => navigateToKPIData()}>{kpi.name}</Link>
    </Stack>
  );
};

export default WidgetSingleKPILastPeriod;

//
// Config
//

interface IWidgetConfigSingleKPILastPeriodProps extends IWidgetConfigRendererProps {}

export const WidgetConfigSingleKPILastPeriod: FunctionComponent<IWidgetConfigSingleKPILastPeriodProps> = (
  props: IWidgetConfigSingleKPILastPeriodProps,
) => {
  const { t } = useTranslation(['kpi', 'kpis', 'translation', 'widgets']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kpis, setKPIs] = useState<KPI[]>([]);
  const [kpiEntities, setKPIEntities] = useState<Entity[]>([]);
  const [selectedKPI, setSelectedKPI] = useState<KPI | undefined>(undefined);
  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(undefined);
  const [colorCallout, setColorCallouth] = useState<number>(0);
  const [currentWidgetId, setCurrentWidgetId] = useState<number>(-1);
  const [showCallOut, setShowCallOut] = React.useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState<number>(KPIAlertPeriods.Month);
  const [colorTarget, setColorTarget] = useState<string>('dummy');
  const theme = getTheme();

  const loadConfig = (): WidgetSingleKPILastPeriodConfig => {
    const newConfig = new WidgetSingleKPILastPeriodConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const [config, setConfig] = useState<WidgetSingleKPILastPeriodConfig>(loadConfig());

  React.useEffect(() => {
    if (currentWidgetId === -1 || currentWidgetId !== props.widget.widgetId) {
      setCurrentWidgetId(props.widget.widgetId);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadData = async () => {
    try {
      if (isLoading || currentWidgetId === props.widget.widgetId) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let kpis = await apiGetKPIs(accessToken, undefined);
      kpis = kpis.filter((k) => k.isCalcType());
      setKPIs(kpis);

      const entities = getEntitiesFromKPIs(kpis);
      setKPIEntities(entities);

      if (config.kpiId) {
        const entity = entities.find((e) => e.entityId === config.kpiId);
        setSelectedEntity(entity);
        setSelectedKPI(kpis.find((k) => k.kpiId === config.kpiId));
      }

      setSelectedPeriod(config.aggregationPeriod);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getEntitiesFromKPIs = (kpis: KPI[]): Entity[] => {
    const entities: Entity[] = [];

    entities.push(
      ...kpis.map((c) => {
        const newEntity = new Entity();
        newEntity.entityId = c.kpiId;
        newEntity.typeOfEntity = EntityTypes.KPI;
        newEntity.entityName = c.name;

        return newEntity;
      }),
    );

    return entities;
  };

  const isConfigValid = (config: WidgetSingleKPILastPeriodConfig): boolean => {
    //kpi must be selected
    if (config.kpiId <= 0) return false;

    return true;
  };

  const updateConfig = (config: WidgetSingleKPILastPeriodConfig) => {
    const newConfig = config.clone();
    newConfig.rawFilter = JSON.stringify(newConfig.filter);
    setConfig(newConfig);
    props.onUpdateConfig(JSON.stringify(newConfig), isConfigValid(newConfig));
  };

  const onAddSelectedEntity = (entity: Entity) => {
    setSelectedEntity(entity);
    config.kpiId = entity.entityId;

    const kpi = kpis.find((k) => k.kpiId === entity.entityId);
    setSelectedKPI(kpi);

    //after selecting a KPI, initialize the min and max values if they are set on the KPI
    if (kpi?.minValue) config.minValue = Number.parseFloat(kpi.minValue);
    if (kpi?.maxValue) config.maxValue = Number.parseFloat(kpi.maxValue);

    updateConfig(config);
  };

  const onRemoveSelectedEntity = () => {
    setSelectedEntity(undefined);
    config.kpiId = 0;
    updateConfig(config);
  };

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: '10px 12px',
      maxWidth: 600,
    },
  };

  const periodOptions: IChoiceGroupOption[] = [
    { key: KPIAlertPeriods.Week.toString(), text: t('translation:PeriodSelector.LastWeek') },
    { key: KPIAlertPeriods.Month.toString(), text: t('translation:PeriodSelector.LastMonth') },
    { key: KPIAlertPeriods.Quarter.toString(), text: t('translation:PeriodSelector.LastQuarter') },
    { key: KPIAlertPeriods.Year.toString(), text: t('translation:PeriodSelector.LastYear') },
  ];

  const onChangePeriod = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    const period = Number.parseInt(option?.key ?? KPIAlertPeriods.Week.toString());
    setSelectedPeriod(period);
  };

  const onApply = () => {
    const newConfig = config.clone();
    newConfig.aggregationPeriod = selectedPeriod;
    updateConfig(newConfig);
    setShowCallOut(false);
  };

  const getColorToEdit = (): string => {
    switch (colorCallout) {
      case 1:
        return config.tresholdLowColor ?? defColorTresholdLow;
      case 2:
        return config.tresholdMediumColor ?? defColorTresholdMedium;
      case 3:
        return config.tresholdHighColor ?? defColorTresholdHigh;
    }

    return defColorTresholdHigh;
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Label>{t('widgets:SingleKPILastPeriod.Config.KPI.Label')}</Label>
        <EntityPicker
          entities={kpiEntities}
          isLoading={isLoading}
          addSelectedEntity={onAddSelectedEntity}
          clearSearchText={onRemoveSelectedEntity}
          loadData={loadData}
          setSearchTextToSelectedEntity={true}
          selectedEntity={selectedEntity}
          isOnPanel={true}
        ></EntityPicker>
      </Stack.Item>
      {selectedEntity && selectedKPI && (
        <Fragment>
          <Stack.Item>
            <Dropdown
              label={t('widgets:SingleKPILastPeriod.Config.AggregationMethod.Label')}
              selectedKey={config.aggregationMethod}
              options={getAggregationMethodOptions(t)}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                config.aggregationMethod = option?.key as KPIAggregationMethods;
                updateConfig(config);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapMedium} verticalAlign="end">
              <Stack.Item>
                <TextInfo message={t('kpi:TabAlerts.Fields.AggregationPeriod.Info')}>
                  <Label>{t('kpi:TabAlerts.Fields.AggregationPeriod.Label')}</Label>
                </TextInfo>
                <DefaultButton
                  id="kpialert-period-selector-callout"
                  text={t('kpi:TabAlerts.Fields.AggregationPeriod.Button', {
                    period: KPIAlert.getAggregationPeriodText(config.aggregationPeriod, t),
                  })}
                  onClick={() => setShowCallOut(true)}
                />
                <Callout
                  hidden={!showCallOut}
                  styles={calloutStyle}
                  setInitialFocus
                  gapSpace={0}
                  target={'#kpialert-period-selector-callout'}
                  onDismiss={() => {
                    setShowCallOut(false);
                  }}
                >
                  <Stack tokens={globalStackTokensGapSmall}>
                    <Stack.Item>
                      <Label>{t('translation:PeriodSelector.Period')}</Label>
                    </Stack.Item>
                    <Stack.Item>
                      <ChoiceGroup
                        selectedKey={selectedPeriod.toString()}
                        options={periodOptions}
                        onChange={onChangePeriod}
                      />
                    </Stack.Item>
                    <Separator />
                    <Stack horizontal horizontalAlign="end" tokens={globalStackTokensGapSmall}>
                      <Stack.Item>
                        <PrimaryButton text={t('translation:General.Button.Select')} onClick={() => onApply()} />
                      </Stack.Item>
                      <Stack.Item>
                        <DefaultButton
                          text={t('translation:General.Button.Cancel')}
                          onClick={() => setShowCallOut(false)}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </Callout>
              </Stack.Item>
              <Stack.Item>
                <KPIDataContextFilter
                  kpi={selectedKPI}
                  filter={config.filter}
                  onUpdate={(newFilter) => {
                    const newConfig = config.clone();
                    newConfig.filter = newFilter;
                    updateConfig(newConfig);
                  }}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <TextInfo message={t('widgets:SingleKPILastPeriod.Config.Meter.Info')}>
              <Label>{t('widgets:SingleKPILastPeriod.Config.Meter.Label')}</Label>
            </TextInfo>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={globalStackTokensGapMedium}
              horizontalAlign="space-between"
              styles={{
                root: {
                  padding: 10,
                  background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighter,
                },
              }}
            >
              <Stack.Item styles={{ root: { width: '28%' } }}>
                <Stack tokens={globalStackTokensGapSmall}>
                  <Stack.Item>
                    <NumericField
                      label={t('widgets:SingleKPILastPeriod.Config.Meter.TresholdLow')}
                      value={config.tresholdLow}
                      minValue={config.minValue}
                      maxValue={config.tresholdHigh ?? config.maxValue}
                      decimalCount={selectedKPI.decimalCount}
                      allowEmpty={true}
                      onChange={(val) => {
                        const newConfig = config.clone();
                        newConfig.tresholdLow = val;
                        if (newConfig.tresholdHigh === undefined) newConfig.tresholdHigh = val;
                        updateConfig(newConfig);
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <ActionButton
                      id="color_callout_1"
                      iconProps={{ iconName: 'Color' }}
                      text={t('widgets:SingleKPILastPeriod.Config.Meter.TresholdColorLow')}
                      onClick={() => {
                        setColorTarget('color_callout_1');
                        setColorCallouth(1);
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <NumericField
                      label={t('widgets:SingleKPILastPeriod.Config.Meter.MinValue')}
                      value={config.minValue}
                      minValue={selectedKPI.minValue ? Number.parseFloat(selectedKPI.minValue ?? '0') : undefined}
                      maxValue={config.tresholdLow ?? config.tresholdHigh ?? config.maxValue}
                      decimalCount={selectedKPI.decimalCount}
                      allowEmpty={false}
                      onChange={(val) => {
                        const newConfig = config.clone();
                        newConfig.minValue = val ?? 0;
                        updateConfig(newConfig);
                      }}
                    />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item styles={{ root: { width: '35%' } }}>
                <Stack tokens={globalStackTokensGapSmall} horizontalAlign="center">
                  <Stack.Item>
                    <ActionButton
                      id="color_callout_2"
                      iconProps={{ iconName: 'Color' }}
                      text={t('widgets:SingleKPILastPeriod.Config.Meter.TresholdColorMedium')}
                      onClick={() => {
                        setColorTarget('color_callout_2');
                        setColorCallouth(2);
                      }}
                    />
                  </Stack.Item>
                  {!appContext.isMobileView && (
                    <Stack.Item>
                      <ReactSpeedometer
                        forceRender={true}
                        segments={config ? (getSegmentStops(config) === undefined ? 1 : 3) : undefined}
                        minValue={config.minValue}
                        maxValue={config.maxValue}
                        value={(config.minValue + config.maxValue) / 2}
                        width={200}
                        height={130}
                        needleColor={'#3366ff'}
                        segmentColors={getSegmentColors(config)}
                        ringWidth={30}
                        valueTextFontSize="18"
                        valueTextFontWeight="500"
                        needleHeightRatio={0.7}
                        needleTransitionDuration={0}
                        needleTransition={Transition.easeBackOut}
                        valueFormat={getValueFormat(selectedKPI, config.aggregationMethod)}
                        customSegmentStops={config ? getSegmentStops(config) : undefined}
                      />
                    </Stack.Item>
                  )}
                </Stack>
              </Stack.Item>
              <Stack.Item styles={{ root: { width: '28%' } }}>
                <Stack tokens={globalStackTokensGapSmall}>
                  <Stack.Item>
                    <NumericField
                      label={t('widgets:SingleKPILastPeriod.Config.Meter.TresholdHigh')}
                      value={config.tresholdHigh}
                      minValue={config.tresholdLow ?? config.minValue}
                      maxValue={config.maxValue}
                      decimalCount={selectedKPI.decimalCount}
                      allowEmpty={true}
                      onChange={(val) => {
                        const newConfig = config.clone();
                        newConfig.tresholdHigh = val;
                        if (newConfig.tresholdLow === undefined) newConfig.tresholdLow = val;
                        updateConfig(newConfig);
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <ActionButton
                      id="color_callout_3"
                      iconProps={{ iconName: 'Color' }}
                      text={t('widgets:SingleKPILastPeriod.Config.Meter.TresholdColorHigh')}
                      onClick={() => {
                        setColorTarget('color_callout_3');
                        setColorCallouth(3);
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <NumericField
                      label={t('widgets:SingleKPILastPeriod.Config.Meter.MaxValue')}
                      value={config.maxValue}
                      minValue={config.tresholdHigh ?? config.tresholdLow ?? config.minValue}
                      maxValue={selectedKPI.maxValue ? Number.parseFloat(selectedKPI.maxValue ?? '1000') : undefined}
                      decimalCount={selectedKPI.decimalCount}
                      allowEmpty={false}
                      onChange={(val) => {
                        const newConfig = config.clone();
                        newConfig.maxValue = val ?? 0;
                        updateConfig(newConfig);
                      }}
                    />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Callout
            styles={calloutStyle}
            hidden={!colorCallout}
            setInitialFocus
            gapSpace={0}
            target={`#${colorTarget}`}
            onDismiss={() => setColorCallouth(0)}
            dismissOnTargetClick
          >
            <ColorPicker
              color={getColorToEdit()}
              onChange={(ev, color) => {
                const newConfig = config.clone();
                switch (colorCallout) {
                  case 1:
                    newConfig.tresholdLowColor = '#' + color.hex;
                    break;
                  case 2:
                    newConfig.tresholdMediumColor = '#' + color.hex;
                    break;
                  case 3:
                    newConfig.tresholdHighColor = '#' + color.hex;
                    break;
                }
                updateConfig(newConfig);
              }}
              alphaType={'none'}
              showPreview
            />
            <PrimaryButton autoFocus onClick={() => setColorCallouth(0)}>
              {t('translation:General.Button.Close')}
            </PrimaryButton>
          </Callout>
        </Fragment>
      )}
    </Stack>
  );
};
