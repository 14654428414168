import { CommandBar, ICommandBarItemProps, IContextualMenuItem } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface IAddEditRemoveButtonsProps {
  id?: string;
  onClickNew?: () => void;
  newDisabled?: boolean;
  newLabel?: string;
  newTooltip?: string;
  newIcon?: string;
  onClickEdit?: () => void;
  editDisabled?: boolean;
  editLabel?: string;
  onClickRemove?: () => void;
  removeDisabled?: boolean;
  onClickSave?: () => void;
  saveDisabled?: boolean;
  onClickDiscard?: () => void;
  discardDisabled?: boolean;
  viewItems?: IContextualMenuItem[];
  extraCommandButtonProps?: ICommandBarItemProps[];
  overflowCommandBarButtonProps?: ICommandBarItemProps[];
  farCommandBarButtonProps?: ICommandBarItemProps[];
  noLeftPadding?: boolean;
  overrideManager?: boolean;
}

const AddEditRemoveButtons = (props: IAddEditRemoveButtonsProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);
  const isManager = hasUserFeatureGenericManager(appContext);

  let commandButtons: ICommandBarItemProps[] = [];

  //
  // Default edit buttons are for manager roles only
  //
  if (isManager || props.overrideManager) {
    if (props.onClickSave) {
      commandButtons.push({
        className: 'redlab-usetiful-command-save',
        key: 'save',
        text: t('translation:General.Button.Save'),
        iconProps: { iconName: 'Save' },
        onClick: props.onClickSave,
        disabled: props.saveDisabled,
      });
    }

    if (props.onClickDiscard) {
      commandButtons.push({
        className: 'redlab-usetiful-command-discard',
        key: 'discard',
        text: t('translation:General.Button.Discard'),
        iconProps: { iconName: 'Undo' },
        onClick: props.onClickDiscard,
        disabled: props.discardDisabled,
      });
    }

    if (props.onClickNew) {
      commandButtons.push({
        className: 'redlab-usetiful-command-add',
        key: 'new',
        text: props.newLabel || t('translation:General.Button.Add'),
        title: props.newTooltip,
        iconProps: { iconName: `${props.newIcon || 'Add'}` },
        onClick: props.onClickNew,
        disabled: props.newDisabled,
      });
    }

    if (props.onClickEdit) {
      commandButtons.push({
        className: 'redlab-usetiful-command-edit',
        key: 'edit',
        text: props.editLabel || t('translation:General.Button.Edit'),
        iconProps: { iconName: 'Edit' },
        onClick: props.onClickEdit,
        disabled: props.editDisabled,
      });
    }

    if (props.onClickRemove) {
      commandButtons.push({
        className: 'redlab-usetiful-command-remove',
        key: 'remove',
        text: t('translation:General.Button.Remove'),
        iconProps: { iconName: 'Delete' },
        onClick: props.onClickRemove,
        disabled: props.removeDisabled,
      });
    }
  }

  //
  // Extra buttons, should be authorized by the caller
  //
  if (props.extraCommandButtonProps) {
    commandButtons = [...commandButtons, ...props.extraCommandButtonProps];
  }

  //
  // The far items should contain view options, so no authorization
  //
  const farItems: IContextualMenuItem[] = props.farCommandBarButtonProps ?? [];
  if (props.viewItems && props.viewItems.length > 0) {
    farItems.push({
      key: 'view',
      text: t('translation:General.Button.View'),
      iconProps: { iconName: 'View' },
      subMenuProps: {
        items: props.viewItems,
      },
    });
  }

  if (commandButtons.length === 0 && farItems.length === 0) {
    return null;
  } else {
    return (
      <CommandBar
        items={commandButtons}
        id={props.id}
        overflowItems={props.overflowCommandBarButtonProps}
        farItems={farItems}
        styles={{ root: { paddingLeft: props.noLeftPadding ? 0 : undefined } }}
      />
    );
  }
};

export default AddEditRemoveButtons;
