import React, { createRef, useState } from 'react';
import { Stack, TextField, PrimaryButton, Separator, ITextField, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall } from 'globalStyles';

interface IAddNewCheckListItem {
  newCheckListDescription: string;
  setNewCheckListDescription: (_newCheckListDescription: string) => void;
  allowEdit: boolean;
  singleLineEdit?: boolean;
  onAddNewCheckListItem: () => void;
}

export const AddNewCheckListItem = (props: IAddNewCheckListItem) => {
  const { t } = useTranslation(['task']);
  const checkInputRef = createRef<ITextField>();
  const [checkInputHasFocus, setCheckInputHasFocus] = useState<boolean>(false);

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'Enter') {
      props.onAddNewCheckListItem();
      e.preventDefault();
    }
  };

  return (
    <Stack tokens={globalStackTokensGapSmall} horizontal={props.singleLineEdit}>
      {!props.singleLineEdit && <Separator />}
      <Stack.Item grow>
        <TextField
          onFocus={() => setCheckInputHasFocus(true)}
          onBlur={() => setCheckInputHasFocus(false)}
          onKeyDown={onKeyPress}
          itemRef=""
          componentRef={checkInputRef}
          value={props.newCheckListDescription}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue) {
              props.setNewCheckListDescription('');
            } else props.setNewCheckListDescription(newValue);
          }}
          placeholder={t('task:CheckList.AddPlaceHolder')}
          style={{ resize: 'vertical', maxHeight: 200 }}
          multiline={!props.singleLineEdit}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <PrimaryButton
            disabled={props.newCheckListDescription === '' || !props.allowEdit}
            onClick={() => {
              checkInputRef.current?.focus();
              props.onAddNewCheckListItem();
            }}
          >
            {t('task:CheckList.Add')}
          </PrimaryButton>
          {!props.singleLineEdit && checkInputHasFocus && <Text variant="small">{t('task:CheckList.KeyTipNew')}</Text>}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
