import { useContext, useEffect, useState } from 'react';
import { Stack, FontIcon, Label, Text, Link, Spinner } from '@fluentui/react';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalStackTokensGapExtraSmall } from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { ApprovalButtonApprove, ApprovalButtonReject } from 'components/Buttons/ApprovalButton';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetLink } from 'services/Api/linkService';
import ResourceLink from 'models/resourceLink';
import { ApprovalState } from 'models/approval';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';

interface IApprovalTaskDetailItems {
  task: Task;
  compact?: boolean;
  navigateExternal?: boolean;
  onUpdate: (newTask: Task) => void;
}

export const ApprovalTaskDetailItems = (props: IApprovalTaskDetailItems) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['library', 'translation']);
  const [approvalItemLoading, setApprovalItemLoading] = useState<boolean>(false);
  const [approvalItem, setApprovalItem] = useState<ResourceLink | undefined>(undefined);
  const [currentTaskId, setCurrentTaskId] = useState<number | undefined>(undefined);
  const [showApprovalItem, setShowApprovalItem] = useState<boolean>(false);
  const iconFontSize = { fontSize: '20px', marginTop: '5px' };

  useEffect(() => {
    if (props.task.taskId !== currentTaskId || !approvalItem) {
      setCurrentTaskId(props.task.taskId);
      loadApprovalItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  const loadApprovalItem = async () => {
    try {
      if (approvalItemLoading || !props.task.resourceLinkIds || props.task.resourceLinkIds?.length === 0) return;
      setShowApprovalItem(false);
      setApprovalItemLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const linkId = props.task.resourceLinkIds[0];
      const link = await apiGetLink(linkId, accessToken, appContext.globalDataCache);
      setApprovalItem(link);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setApprovalItemLoading(false);
    }
  };

  const isApprovedOrRejected = (): boolean => {
    return props.task.approved !== ApprovalState.Pending;
  };

  //
  // Main render
  //
  return (
    <Stack tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack
          horizontal
          tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
          verticalAlign="start"
        >
          <Stack.Item>
            {!appContext.isMobileView && <FontIcon iconName="DocumentSet" style={iconFontSize} />}
          </Stack.Item>
          <Stack.Item>
            <Label>{t('library:ApprovalTasks.ApprovalTaskDetail.ItemToApprove')}</Label>
            {approvalItemLoading && <Spinner />}
            {!approvalItemLoading && (
              <Link
                onClick={() => {
                  setShowApprovalItem(true);
                }}
              >
                {approvalItem?.linkName}
              </Link>
            )}
            {!approvalItemLoading && !approvalItem && (
              <Text>{t('library:ApprovalTasks.ApprovalTaskDetail.ItemToApproveEmpty')}</Text>
            )}
            <LinkPreviewModalOrUrl
              isOpen={showApprovalItem}
              onClose={() => {
                setShowApprovalItem(false);
              }}
              link={approvalItem}
              confirmOpen={props.navigateExternal}
              enableLinks={true}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack
          horizontal
          tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
          verticalAlign="start"
        >
          <Stack.Item>
            {!appContext.isMobileView && <FontIcon iconName="TextDocument" style={iconFontSize} />}
          </Stack.Item>
          <Stack.Item grow>
            <Label>{t('library:ApprovalTasks.ApprovalTaskDetail.Instruction')}</Label>
            <Text>
              {props.task.description
                ? props.task.description
                : t('library:ApprovalTasks.ApprovalTaskDetail.InstructionEmpty')}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack
          horizontal
          tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
          verticalAlign="start"
          styles={{ root: { paddingTop: 10 } }}
        >
          <Stack.Item>
            {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
          </Stack.Item>
          <Stack.Item grow>
            <Stack
              horizontal
              wrap
              tokens={globalStackTokensGapSmall}
              verticalAlign="center"
              styles={{ root: { paddingRight: 5 } }}
            >
              <Stack.Item>
                <ApprovalButtonApprove
                  compact={props.compact}
                  selected={props.task.approved === ApprovalState.Approved}
                  disabled={appContext.user.id !== props.task.userId}
                  onClick={() => {
                    const copyTask = props.task.clone();
                    copyTask.approved = ApprovalState.Approved;
                    props.onUpdate(copyTask);
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                <ApprovalButtonReject
                  compact={props.compact}
                  selected={props.task.approved === ApprovalState.Rejected}
                  disabled={appContext.user.id !== props.task.userId}
                  onClick={() => {
                    const copyTask = props.task.clone();
                    copyTask.approved = ApprovalState.Rejected;
                    props.onUpdate(copyTask);
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                <Link
                  disabled={appContext.user.id !== props.task.userId || !isApprovedOrRejected()}
                  onClick={() => {
                    const copyTask = props.task.clone();
                    copyTask.approved = ApprovalState.Pending;
                    copyTask.taskStateId = copyTask.getFirstState();
                    props.onUpdate(copyTask);
                  }}
                >
                  {t('library:ApprovalTasks.ApprovalTaskDetail.ResetAnswer')}
                </Link>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
