import { useContext, useEffect, useState } from 'react';
import User from 'models/user';
import {
  IBasePickerSuggestionsProps,
  IInputProps,
  IPersonaProps,
  IPickerItemProps,
  ISuggestionItemProps,
  NormalPeoplePicker,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalFilterDelay, globalSuggestionsMaxRecords } from 'globalConstants';
import AppContext from 'App/AppContext';
import Group from 'models/group';
import { sortOnString } from 'utils/sorting';
import UserTag from 'components/Utils/UserTag';
import GroupTag from 'components/Utils/GroupTag';
import { Role } from 'models/auth/role';
import { overflow } from 'utils/string';
import RoleTag from 'components/Utils/RoleTag';
import { appRoles, appRoleSets, appRoleSetTypes } from 'services/Auth/appRoles';

//
// Interfaces
//
interface IPickerProps extends IPersonaProps {
  itemData?: IUserRoleGroup;
}

export interface IUserRoleGroup {
  id: string;
  name: string;
  sub: string;
  data: User | Role | Group;
}

interface IUserRoleGroupPickerProps {
  allowedItems: (User | Role | Group)[];
  itemsToExclude?: (User | Role | Group | undefined)[];
  selectedItems: (User | Role | Group)[];
  onSelect?: (items: (User | Role | Group)[]) => void;
  onRemove?: (item: User | Role | Group) => void;
  itemLimit?: number;
  disabled?: boolean;
  placeholder?: string | undefined;
  allowUsers: boolean;
  allowGroups: boolean;
  allowRoles: boolean;
  allowSystemRoles?: boolean;
  isMaxSystemRoles?: boolean;
}

//
// Static functions
//
export const renderUserRoleGroupItem = (
  item: IUserRoleGroup | undefined,
  compact: boolean,
  onRemove: ((item: User | Role | Group) => void) | undefined,
  allowHoverCard: boolean,
  padding?: number,
  isMaxSystemRoles?: boolean,
  hideBackground?: boolean,
): JSX.Element => {
  const defItemPadding = 3;

  if (!item) return <span />;

  if (item.data instanceof User) {
    return (
      <Stack key={item.id} styles={{ root: { padding: padding ?? defItemPadding } }}>
        <UserTag
          user={item.data}
          compact={compact}
          onRemove={onRemove ? () => onRemove(item.data) : undefined}
          hideBackground={hideBackground}
        />
      </Stack>
    );
  } else if (item.data instanceof Role) {
    return (
      <Stack key={item.id} styles={{ root: { padding: padding ?? defItemPadding } }}>
        <RoleTag
          role={item.data}
          compact={compact}
          onRemove={onRemove ? () => onRemove(item.data) : undefined}
          allowHoverCard={allowHoverCard}
          isMaxSystemRoles={isMaxSystemRoles}
          hideBackground={hideBackground}
        />
      </Stack>
    );
  } else {
    return (
      <Stack key={item.id} styles={{ root: { padding: padding ?? defItemPadding } }}>
        <GroupTag
          group={item.data}
          compact={compact}
          onRemove={onRemove ? () => onRemove(item.data) : undefined}
          allowHoverCard={allowHoverCard}
          hideBackground={hideBackground}
        />
      </Stack>
    );
  }
};

export const getUserRoleGroupItem = (item: User | Role | Group): IUserRoleGroup => {
  if (item instanceof Role) {
    return {
      id: item.roleId.toString(),
      name: item.name,
      sub: overflow(item.description, 50),
      data: item,
    };
  } else {
    return {
      id: item.id,
      name: item.name,
      sub: item.email,
      data: item,
    };
  }
};

const getPersonas = (items: IUserRoleGroup[]): IPickerProps[] => {
  const personaProps: IPickerProps[] = [];

  for (let item of items) {
    let personaProp: IPickerProps = {
      text: item.name,
      key: item.id,
      secondaryText: item.sub,
      itemData: item,
    };
    personaProps.push(personaProp);
  }

  personaProps.sort((a, b) => {
    return sortOnString(a.text, b.text);
  });

  return personaProps;
};

const filterAllItemsByText = (items: IUserRoleGroup[], filterText: string): IUserRoleGroup[] => {
  return items.filter(
    (item) =>
      item.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 ||
      item.sub.toLowerCase().indexOf(filterText.toLowerCase()) >= 0,
  );
};

//
// Component
//
const UserRoleGroupPicker = (props: IUserRoleGroupPickerProps) => {
  const { t } = useTranslation('translation');
  const appContext = useContext(AppContext);
  const [items, setItems] = useState<IUserRoleGroup[]>([]);

  //
  // Constants
  //
  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    onRenderNoResultFound: () => {
      return (
        <Stack styles={{ root: { padding: 10 } }}>
          <Text>{t('translation:General.Suggestions.NoData')}</Text>
        </Stack>
      );
    },
  };

  //
  // Effects
  //
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowedItems]);

  const getItems = (selectedItems: (User | Role | Group)[]): IPickerProps[] => {
    if (items.length === 0) return [];

    //look-up all the selected items in the array of loaded items.
    //this way we ensure that we show the latest data from the global data cache
    const selectedUserRoleGroupItems = selectedItems.map((s) => getUserRoleGroupItem(s));
    const itemsFromCache = items.filter((i) => selectedUserRoleGroupItems.some((s) => s.id === i.id));

    return getPersonas(itemsFromCache);
  };

  const loadData = () => {
    let items: IUserRoleGroup[] = [];

    if (props.allowedItems.length === 0) {
      if (props.allowUsers) {
        const users = appContext.globalDataCache.users.items.filter((u) => u.hasLicense);
        items.push(...users.map((i) => getUserRoleGroupItem(i)));
      }

      if (props.allowGroups) {
        const groups = appContext.globalDataCache.groups.items;
        items.push(...groups.map((i) => getUserRoleGroupItem(i)));
      }

      if (props.allowRoles) {
        const roles = appContext.globalDataCache.roles.items.filter((i) => i.systemRoleId === undefined);
        items.push(...roles.map((i) => getUserRoleGroupItem(i)));
      }

      if (props.allowSystemRoles) {
        const roles = appContext.globalDataCache.roles.items.filter(
          (i) =>
            i.systemRoleId !== undefined &&
            i.systemRoleId !== appRoles.OrgAdmin &&
            i.systemRoleId !== appRoles.Consultant &&
            i.systemRoleId !== appRoles.User &&
            i.systemRoleId < 900,
        );
        items.push(...roles.map((i) => getUserRoleGroupItem(i)));
      }

      items.sort((a, b) => sortOnString(a.name, b.name));
    } else {
      items = props.allowedItems.map((i) => getUserRoleGroupItem(i));
    }

    setItems(items);
  };

  const onSuggestion = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredItems = filterAllItemsByText(items, filterText);
      let filteredPersonas: IPickerProps[] = getPersonas(filteredItems);
      filteredPersonas = filteredPersonas.filter((f) => !currentPersonas?.some((c) => c.key === f.key));

      if (props.itemsToExclude && props.itemsToExclude.length > 0) {
        const exclude = props.itemsToExclude
          .filter((a) => a !== undefined)
          .map((e) => getUserRoleGroupItem(e as User | Group | Role));
        filteredPersonas = filteredPersonas.filter((i) => !exclude.some((e) => e.id === i.itemData?.id));
      }

      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;

      return filteredPersonas;
    } else {
      return [];
    }
  };

  const onChange = (personas: IPersonaProps[] | undefined): void => {
    let items = personas?.map((persona) => {
      return (persona as IPickerProps).itemData?.data as User | Role | Group;
    });

    if (props.isMaxSystemRoles) {
      //filter out the licensed system roles
      items = items?.filter((i) =>
        i instanceof Role ? !appRoleSets[appRoleSetTypes.Licensed].includes(i.systemRoleId ?? 0) : true,
      );
    }

    if (props.onSelect) {
      props.onSelect(items ?? []);
    }
  };

  const onRenderItem = (itemProps: IPickerItemProps<IPersonaProps>): JSX.Element => {
    const modifiedProps: IPickerProps = itemProps.item as IPickerProps;

    const res = renderUserRoleGroupItem(modifiedProps.itemData, true, props.onRemove, true);

    return res;
  };

  const onRenderSuggestionsItem = (
    personaProps: IPersonaProps,
    itemProps: ISuggestionItemProps<IPersonaProps>,
  ): JSX.Element => {
    const modifiedProps = personaProps as IPickerProps;

    return renderUserRoleGroupItem(modifiedProps.itemData, false, undefined, false, undefined, props.isMaxSystemRoles, true);
  };

  const onEmptyResolveSuggestions = (selectedItems?: IPersonaProps[] | undefined): IPersonaProps[] => {
    let filteredPersonas: IPickerProps[] = getPersonas(items);
    filteredPersonas = filteredPersonas.filter((f) => !selectedItems?.some((c) => c.key === f.key));
    filteredPersonas = filteredPersonas.slice(0, 30);

    return filteredPersonas;
  };

  const inputProps: IInputProps = {
    placeholder: props.placeholder || t('translation:General.Search.Placeholder'),
  };

  //
  // Main render
  //
  return (
    <NormalPeoplePicker
      itemLimit={props.itemLimit || 1}
      onResolveSuggestions={onSuggestion}
      pickerSuggestionsProps={suggestionProps}
      selectedItems={getItems(props.selectedItems)}
      onChange={onChange}
      resolveDelay={globalFilterDelay}
      onRenderSuggestionsItem={onRenderSuggestionsItem}
      onEmptyResolveSuggestions={onEmptyResolveSuggestions}
      onRenderItem={onRenderItem}
      disabled={props.disabled}
      styles={{ itemsWrapper: { height: 30 }, input: { height: 30 } }}
      inputProps={inputProps}
    />
  );
};

export default UserRoleGroupPicker;
