import { useContext } from 'react';
import { PrimaryButton, Text, DefaultButton, DialogFooter, Modal, Stack, IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { globalStackTokensGapMedium, cancelIcon } from 'globalStyles';
import StoreItemDetails from 'components/Store/StoreItemDetails';
import Package, { PackageStates } from 'models/package';
import { getBuyButtonText } from 'components/Store/StoreItem';
import AppContext from 'App/AppContext';
import { StoreMgmtStates } from 'models/adminTenant';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface IStoreItemDetailsModalProps {
  pack: Package;
  isOpen: boolean;
  onDismiss: () => void;
  onInstall: (pack: Package) => void;
}

const StoreItemDetailsModal = (props: IStoreItemDetailsModalProps) => {
  const { t } = useTranslation(['store', 'translation']);
  const appContext = useContext(AppContext);

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Stack
        styles={{ root: { height: '90vh', width: 1000, minWidth: 320, maxWidth: '90vw', padding: 20 } }}
        tokens={globalStackTokensGapMedium}
      >
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xxLarge">{props.pack.name + ' (' + props.pack.version + ')'}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.onDismiss} />
        </Stack>
        <StoreItemDetails pack={props.pack} />
        <DialogFooter>
          <PrimaryButton
            disabled={
              props.pack.state !== PackageStates.Live ||
              appContext.user.login.storeMgmtState === StoreMgmtStates.Manager ||
              !hasUserFeatureGenericManager(appContext)
            }
            onClick={() => {
              props.onInstall(props.pack);
            }}
            text={getBuyButtonText(props.pack, t as TFunction<string[]>)}
          />
          <DefaultButton
            disabled={false}
            onClick={() => {
              props.onDismiss();
            }}
            text={t('translation:General.Button.Close')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default StoreItemDetailsModal;
