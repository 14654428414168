import { IDropdownOption, IDropdownStyles } from '@fluentui/react';

export const iconFontSize = { fontSize: '20px', marginTop: '5px' };

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { minWidth: '80px' },
};

export const durationValueOptions: IDropdownOption[] = Array.from({ length: 99 }, (_, i) => {
  return {
    key: i + 1,
    text: (i + 1).toString(),
  };
});

export const readOnlyRowSpace = 20;