import Widget, { WidgetTypes } from 'models/widget';
import { IconButton, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { WidgetConfigMyUpcomingTasks, WidgetMyUpcomingTasksConfig } from './Widgets/WidgetMyUpcomingTasks';
import { SkypeCircleCheckIcon } from 'components/Checklist/CheckListStyles';
import { WidgetConfigSingleRisk, WidgetSingleRiskConfig } from './Widgets/WidgetSingleRisk';
import { WidgetLibraryContentConfig, WidgetConfigLibraryContent } from './Widgets/WidgetLibraryContent';
import { WidgetConfigNormCoverage, WidgetNormCoverageConfig } from './Widgets/WidgetNormCoverage';
import { WidgetConfigCustomNormCoverage, WidgetCustomNormCoverageConfig } from './Widgets/WidgetCustomNormCoverage';
import {
  WidgetConfigCustomMyNormCoverage,
  WidgetCustomMyNormCoverageConfig,
} from './Widgets/WidgetCustomMyNormCoverage';
import { WidgetConfigMyNormCoverage, WidgetMyNormCoverageConfig } from './Widgets/WidgetMyNormCoverage';
import { WidgetConfigSingleTask, WidgetSingleTaskConfig } from './Widgets/WidgetSingleTask';
import {
  WidgetAzureDefenderSecureScoreConfig,
  WidgetConfigAzureDefenderSecureScore,
} from './Widgets/WidgetAzureDefenderSecureScore';
import { WidgetConfigRiskStatus, WidgetRiskStatusConfig } from './Widgets/WidgetRiskStatus';
import {
  WidgetRiskStatusNotAcceptedConfig,
  WidgetConfigRiskStatusNotAccepted,
} from './Widgets/WidgetRiskStatusNotAccepted';
import { WidgetConfigOpenTasksPerUser, WidgetOpenTasksPerUserConfig } from './Widgets/WidgetOpenTasksPerUser';
import { WidgetConfigTaskStatus, WidgetTaskStatusConfig } from './Widgets/WidgetTaskStatus';
import { WidgetConfigSingleKPI, WidgetSingleKPIConfig } from './Widgets/WidgetSingleKPI';
import { WidgetConfigSingleKPILastPeriod, WidgetSingleKPILastPeriodConfig } from './Widgets/WidgetSingleKPILastPeriod';
import { WidgetConfigLibraryReadResponse, WidgetLibraryReadResponseConfig } from './Widgets/WidgetLibraryReadResponse';
import { WidgetConfigTaskTag, WidgetTaskTagConfig } from './Widgets/WidgetTaskTag';
import { WidgetConfigLibraryReadStats, WidgetLibraryReadStatsConfig } from './Widgets/WidgetLibraryReadStats';

export interface IWidgetConfigRendererProps {
  editmode: boolean;
  widget: Widget;
  onUpdateConfig: (config: string, isValid: boolean) => void;
}

const WidgetConfigRenderer = (props: IWidgetConfigRendererProps) => {
  const { t } = useTranslation(['dashboard', 'translation']);

  if (!props.widget.hasConfig()) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <IconButton iconProps={SkypeCircleCheckIcon} />
        <Text>
          {props.editmode ? t('dashboard:EditWidgetPanel.NoConfig1') : t('dashboard:AddWidgetPanel.NoConfig1')}
        </Text>
        <Text>
          {props.editmode ? t('dashboard:EditWidgetPanel.NoConfig2') : t('dashboard:AddWidgetPanel.NoConfig2')}
        </Text>
      </Stack>
    );
  }

  switch (props.widget.widgetType) {
    case WidgetTypes.MyUpcomingTasks:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetMyUpcomingTasksConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigMyUpcomingTasks
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.SingleRisk:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetSingleRiskConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigSingleRisk widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );
    case WidgetTypes.LibraryContent:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetLibraryContentConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigLibraryContent
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.NormCoverage:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetNormCoverageConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigNormCoverage
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.CustomNormCoverage:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetCustomNormCoverageConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigCustomNormCoverage
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.MyNormCoverage:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetMyNormCoverageConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigMyNormCoverage
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.CustomMyNormCoverage:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetCustomMyNormCoverageConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigCustomMyNormCoverage
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.SingleTask:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetSingleTaskConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigSingleTask widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );
    case WidgetTypes.AzureDefenderSecureScore:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetAzureDefenderSecureScoreConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigAzureDefenderSecureScore
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.RiskStatus:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetRiskStatusConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigRiskStatus widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );
    case WidgetTypes.RiskStatusNotAccepted:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetRiskStatusNotAcceptedConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigRiskStatusNotAccepted
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );

    case WidgetTypes.OpenTasksPerUser:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetOpenTasksPerUserConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigOpenTasksPerUser
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );

    case WidgetTypes.TaskStatus:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetTaskStatusConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigTaskStatus widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );

    case WidgetTypes.TaskTag:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetTaskTagConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigTaskTag widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );

    case WidgetTypes.SingleKPI:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetSingleKPIConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigSingleKPI widget={props.widget} onUpdateConfig={props.onUpdateConfig} editmode={props.editmode} />
      );

    case WidgetTypes.SingleKPILastPeriod:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetSingleKPILastPeriodConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigSingleKPILastPeriod
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.LibraryReadResponse:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetLibraryReadResponseConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), false);
      }

      return (
        <WidgetConfigLibraryReadResponse
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    case WidgetTypes.LibraryReadStats:
      //create the default config when its empty
      if (!props.widget.widgetConfig) {
        const defConfig = new WidgetLibraryReadStatsConfig();
        props.onUpdateConfig(JSON.stringify(defConfig), true);
      }

      return (
        <WidgetConfigLibraryReadStats
          widget={props.widget}
          onUpdateConfig={props.onUpdateConfig}
          editmode={props.editmode}
        />
      );
    default:
      return null;
  }
};

export default WidgetConfigRenderer;
