import { useContext } from 'react';
import { Stack, FontIcon, Label, ScrollablePane, ScrollbarVisibility, Text } from '@fluentui/react';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackTokensGapExtraSmall,
  globalStackItemStylesPaddingSceneScroll,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { ApprovalState } from 'models/approval';
import { ApprovalTaskDetailItems } from './ApprovalTaskDetailItems';
import { TaskDetailName } from '../Details/TaskDetailName';
import { TaskDetailTags } from '../Details/TaskDetailTags';
import { TaskDetailStart } from '../Details/TaskDetailStart';

interface IApprovalTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  windowLevel?: number;
  navigateExternal?: boolean;
  canUpdate: boolean;
}

export const ApprovalTaskDetail = (props: IApprovalTaskDetail) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation', 'library']);
  const iconFontSize = { fontSize: '20px', marginTop: '5px' };

  const isApprovedOrRejected = (): boolean => {
    return props.task.approved !== ApprovalState.Pending;
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <ApprovalTaskDetailItems
              task={props.task}
              onUpdate={props.onUpdate}
              navigateExternal={props.navigateExternal}
            />
            <TaskDetailStart
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate && !isApprovedOrRejected()}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <Stack
              horizontal
              tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
              verticalAlign="start"
            >
              <Stack.Item>
                {!appContext.isMobileView && <FontIcon iconName="InfoSolid" style={iconFontSize} />}
              </Stack.Item>
              <Stack.Item grow>
                <Label>{t('task:Deadline.Label')}</Label>
                <Text>{toLocaleDateTimeMedium(props.task.deadline)}</Text>
              </Stack.Item>
            </Stack>
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
