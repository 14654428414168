import {
  ScrollablePane,
  Stack,
  ScrollbarVisibility,
  Text,
  Link,
  IColumn,
  DetailsList,
  SelectionMode,
  Icon,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  DetailsListLayoutMode,
  ConstrainMode,
} from '@fluentui/react';
import { IDrive } from '../../services/Graph/SharepointInterfaces';

interface IMyDrivesProps {
  drives: IDrive[];
  onSelect: (item: IDrive) => void;
  isDriveLoading: boolean;
}

const MyDrives = (props: IMyDrivesProps) => {
  const columns: IColumn[] = [
    {
      key: 'icon',
      minWidth: 15,
      maxWidth: 15,
      onRender: () => {
        return (
          <Stack verticalAlign="center">
            <Icon iconName={'BulletedList2'} className={classes.icon} />
          </Stack>
        );
      },
      name: '',
    },
    {
      key: 'drive',
      minWidth: 200,
      name: '',
      onRender: (item?: IDrive, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item) return;

        return (
          <Text>
            <Link underline onClick={() => props.onSelect(item)} disabled={props.isDriveLoading}>
              {item.name}
            </Link>
          </Text>
        );
      },
    },
  ];

  const classes = mergeStyleSets({
    icon: {
      fontSize: '16px',
    },
  });

  if (props.isDriveLoading) {
    return (
      <Stack verticalAlign="center" grow verticalFill>
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            columns={columns}
            items={props.drives}
            isHeaderVisible={false}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            selectionMode={SelectionMode.none}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default MyDrives;
