import { PackageInstallPrecheckResult, PackageLogos, PackageTenantStates } from 'models/package';
import IdListDTO from 'models/dto/IdListDTO';
import http from './httpService';
import AppError from 'utils/appError';
import Package, { PackageContent, PackageImport, PackageTenant } from 'models/package';
import {
  mapFromPackage,
  mapFromPackageContent,
  mapFromPackageContentItem,
  mapFromPackageImport,
  mapFromPackageImports,
  mapFromPackages,
  mapFromPackageTenant,
  mapFromPackageTenants,
  mapToPackage,
  mapToPackageContentItem,
} from 'mappings/packageMapping';
import PackageDTO, { PackageContentDTO, PackageTenantDTO } from 'models/dto/packageDTO';
import { toBlob } from 'utils/string';
import { EntityTypes } from 'models/entity';

//
// Create
//
export async function apiGetPackage(accessToken: string): Promise<Package | undefined> {
  try {
    const ar = await http.get<PackageDTO>(`/packages`, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPerformPackagePreChecks(accessToken: string): Promise<number> {
  try {
    const ar = await http.get<number>(`/packages/precheck`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreatePackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const packageDTO = mapToPackage(pack);
    const ar = await http.post<PackageDTO>(`/packages`, packageDTO, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data) || pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdatePackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const packageDTO = mapToPackage(pack);
    const ar = await http.put<PackageDTO>(`/packages`, packageDTO, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data) || pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSubmitPackage(pack: Package, accessToken: string): Promise<PackageContent[]> {
  try {
    const packageDTO = mapToPackage(pack);
    const ar1 = await http.put<PackageDTO>(`/packages`, packageDTO, http.getRequestConfig(accessToken));
    const ar2 = await http.post<PackageContentDTO[]>(`/packages/submit`, ar1.data, http.getRequestConfig(accessToken));

    return mapFromPackageContent(ar2.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/packages/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageCodeVersion(accessToken: string, code: string, version: string): Promise<number> {
  try {
    const idListDto = new IdListDTO([code, version]);
    const ar = await http.post<number>(`/packages/version`, idListDto, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForRelation(pack: Package, accessToken: string): Promise<Package[]> {
  try {
    const packageDto = mapToPackage(pack);
    const ar = await http.post<PackageDTO[]>(`/packages/relations`, packageDto, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageForState(state: PackageTenantStates, accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/state/${state}`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageRelationsPreview(pack: Package, accessToken: string): Promise<Package[]> {
  try {
    const packageDto = mapToPackage(pack);
    const ar = await http.post<PackageDTO[]>(
      `/packages/relations/preview`,
      packageDto,
      http.getRequestConfig(accessToken),
    );

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageWithRelations(id: number, accessToken: string): Promise<Package | undefined> {
  try {
    const ar = await http.get<PackageDTO>(`/packages/relations/${id}`, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageForExternalId(
  externalId: string,
  accessToken: string,
): Promise<Package | undefined> {
  try {
    const ar = await http.get<PackageDTO>(`/packages/externalid/${externalId}`, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUploadPackageContent(item: PackageContent, accessToken: string): Promise<PackageContent> {
  try {
    const itemDTO = mapToPackageContentItem(item);
    const ar = await http.post<PackageContentDTO>(
      `/packages/submit/content`,
      itemDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromPackageContentItem(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUploadPackageContentBlob(
  item: PackageContent,
  blob: Blob,
  accessToken: string,
): Promise<void> {
  try {
    let formData = new FormData();
    formData.append('blob', blob, item.contentId.toString());

    await http.post(`/packages/submit/content/upload`, formData, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiFinishUploadPackageContent(accessToken: string): Promise<Package | undefined> {
  try {
    const ar = await http.post<PackageDTO>(`/packages/submit/finish`, null, http.getRequestConfig(accessToken));

    return mapFromPackage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//
// Store
//
export async function apiGetPackagesForStore(checkOrgLangs: boolean, accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/store?checkOrgLangs=${checkOrgLangs}`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForStoreId(ids: number[], accessToken: string): Promise<Package[]> {
  try {
    const idList = new IdListDTO(ids.map((id) => id.toString()));
    const ar = await http.post<PackageDTO[]>(`/packages/store/id`, idList, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForReview(accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/store/review`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForManager(accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/store/manager`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForOwner(accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/owner`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForTenant(tenantId: string, accessToken: string): Promise<PackageTenant[]> {
  try {
    const ar = await http.get<PackageTenantDTO[]>(`/packages/tenant/${tenantId}`, http.getRequestConfig(accessToken));

    return mapFromPackageTenants(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackagesForAssign(accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/assign`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//
// Install
//

export async function apiInstallTestPackage(pack: Package, isVirtual: boolean, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/install/test/${pack.packageId}?isVirtual=${isVirtual}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
      pack.imports = await apiGetPackageImports(pack, accessToken);
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiInstallPackage(pack: Package, isVirtual: boolean, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/install/${pack.packageId}?isVirtual=${isVirtual}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
      pack.imports = await apiGetPackageImports(pack, accessToken);
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiFinishInstallPackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/install/finish/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateNewVersionFromPackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/createversion/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
      pack.imports = await apiGetPackageImports(pack, accessToken);
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetInstallPackageProgress(
  pack: Package,
  tenantId: string,
  accessToken: string,
): Promise<number | undefined> {
  try {
    const ar = await http.get<number | undefined>(
      `/packages/install/${pack.packageId}/progress/${tenantId}`,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    return undefined;
  }
}

export async function apiDeInstallPackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/deinstall/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRelatedPackagePrecheckResult(
  pack: Package,
  accessToken: string,
): Promise<PackageInstallPrecheckResult> {
  try {
    const ar = await http.get<number>(
      `/packages/install/precheck/${pack.packageId}`,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetInstallPackageContent(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.get<PackageDTO>(
      `/packages/install/content/${pack.packageId}`,
      http.getRequestConfig(accessToken),
    );

    const newPack = mapFromPackage(ar.data);
    if (newPack) {
      pack.sharePointLinks = newPack?.sharePointLinks;
      pack.sharePointLists = newPack?.sharePointLists;
      pack.contents = newPack.contents;
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiOrderPackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageTenantDTO>(
      `/packages/order/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    const packageTenantDto = ar.data;
    if (packageTenantDto) {
      pack.tenants = [mapFromPackageTenant(packageTenantDto)];
    }

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiReleasePackage(pack: Package, tenantId: string, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageDTO>(
      `/packages/release/${pack.packageId}/${tenantId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    //update the tenant and state
    const newPack = ar.data;
    pack.tenantId = newPack.tenantId || '';
    pack.state = newPack.state;

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiWithdrawPackage(pack: Package, tenantId: string, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageDTO>(
      `/packages/withdraw/${pack.packageId}/${tenantId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    //update the tenant and state
    const newPack = ar.data;
    pack.tenantId = newPack.tenantId || '';
    pack.state = newPack.state;

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRejectPackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageDTO>(
      `/packages/reject/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    //update the state
    const newPack = ar.data;
    pack.state = newPack.state;

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiArchivePackage(pack: Package, accessToken: string): Promise<Package> {
  try {
    const ar = await http.post<PackageDTO>(
      `/packages/archive/${pack.packageId}`,
      null,
      http.getRequestConfig(accessToken),
    );

    //update the state
    const newPack = ar.data;
    pack.state = newPack.state;

    return pack;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageImports(pack: Package, accessToken: string): Promise<PackageImport[]> {
  try {
    const ar = await http.get<PackageImport[]>(
      `/packages/install/imports/${pack.packageId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromPackageImports(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageImport(
  packageId: number,
  type: EntityTypes,
  sourceId: number,
  sourceIdTo: number,
  accessToken: string,
): Promise<PackageImport> {
  try {
    const ar = await http.get<PackageImport>(
      `/packages/install/imports/${packageId}/${type}/${sourceId}/${sourceIdTo}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromPackageImport(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDownloadPackageContentBlob(
  packageId: number,
  contentId: number,
  accessToken: string,
): Promise<Blob> {
  try {
    const ar = await http.get<string>(
      `/packages/install/content/${packageId}/download/${contentId}`,
      http.getRequestConfig(accessToken),
    );

    const blob = toBlob(ar.data);
    if (blob) {
      return blob;
    } else {
      throw new AppError('Blob download contains no data');
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAssignPackageTenants(
  tenantId: string,
  packages: number[],
  accessToken: string,
): Promise<void> {
  try {
    const idListDto = new IdListDTO(packages.map((id) => id.toString()));

    await http.post<PackageTenant[]>(`/packages/assign/${tenantId}`, idListDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDownloadPackageLogo(
  packageId: number,
  logoType: PackageLogos,
  accessToken: string,
): Promise<Blob | undefined> {
  try {
    const ar = await http.get<string>(`/packages/${packageId}/logo/${logoType}`, http.getRequestConfig(accessToken));

    return toBlob(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUploadPackageLogo(
  logoType: PackageLogos,
  blob: Blob | undefined,
  accessToken: string,
): Promise<void> {
  try {
    let formData = new FormData();
    if (blob) {
      formData.append('blob', blob, logoType.toString());
    }

    await http.post(`/packages/logo/${logoType}`, blob ? formData : undefined, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetPackageStateToPaid(
  tenantId: string,
  packageId: number,
  accessToken: string,
): Promise<void> {
  try {
    await http.post(`/packages/${packageId}/state/${tenantId}/paid`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetPackageStateToOrdered(
  tenantId: string,
  packageId: number,
  accessToken: string,
): Promise<void> {
  try {
    await http.post(`/packages/${packageId}/state/${tenantId}/ordered`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageAdsForControl(id: number, accessToken: string): Promise<Package[]> {
  try {
    const ar = await http.get<PackageDTO[]>(`/packages/ads/control/${id}`, http.getRequestConfig(accessToken));

    return mapFromPackages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPackageSharePointSite(packageId: number, accessToken: string): Promise<string | undefined> {
  try {
    const ar = await http.get<string>(`/packages/${packageId}/site`, http.getRequestConfig(accessToken));

    return ar.data ?? undefined;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
