import GenericModal from 'components/Dialogs/GenericModal';
import { ILinkPreviewProps, LinkPreview } from './LinkPreview';
import { Separator, Stack } from '@fluentui/react';
import { globalStackTokensGapExtraSmall } from 'globalStyles';

export interface ILinkPreviewModalProps extends ILinkPreviewProps {
  isOpen: boolean;
  onClose: () => void; //override and make mandatory
}

const LinkPreviewModal = (props: ILinkPreviewModalProps) => {
  if (!props.link) return null;

  return (
    <GenericModal
      title={props.link.linkName}
      isOpen={props.isOpen}
      onClose={props.onClose}
      minHeight={300}
      minWidth={200}
      height={'90vh'}
      width={'90vw'}
      maxWidth={'90vw'}
      maxHeight={'90vh'}
      hideFooter={true}
      verticalGap={globalStackTokensGapExtraSmall}
    >
      <Stack verticalFill>
        <Separator />
        <Stack.Item grow>
          <LinkPreview {...props} hideCommandbar={true} />
        </Stack.Item>
      </Stack>
    </GenericModal>
  );
};

export default LinkPreviewModal;
