import Process from 'models/process/process';
import Asset from '../asset/asset';
import Control from '../control';
import Risk from '../risk';
import Theme from '../theme';
import Objective from 'models/objective/objective';
import { TaskTask } from './taskTask';

export class TaskContext {
  controls: Control[];

  themes: Theme[];

  processes: Process[];

  objectives: Objective[];

  risks: Risk[];

  assets: Asset[];

  relations: TaskTask[];

  constructor() {
    this.controls = [];
    this.themes = [];
    this.risks = [];
    this.processes = [];
    this.risks = [];
    this.objectives = [];
    this.assets = [];
    this.relations = [];
  }
}
