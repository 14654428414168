import Task from './task';

export class TemplateStats {
  count: number;

  lastUsed: Date;

  constructor() {
    this.count = 0;
    this.lastUsed = new Date();
  }
}

export default class TaskTemplateScene {
  tasks: Task[];

  templateStats: { [key: number]: TemplateStats };

  constructor() {
    this.tasks = [];
    this.templateStats = {};
  }
}
