import React, { useState, useEffect, Fragment } from 'react';

interface IDelayedProps {
  children: React.ReactNode;
  delay?: number;
}

const Delayed = (props: IDelayedProps): JSX.Element | null => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, props.delay ?? 300);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.delay]);

  if (!isShown) return null;

  return <Fragment>{props.children}</Fragment>
};

export default Delayed;
