export default class ControlISOControlDTO {
  isoControlId: number;

  controlId: number;

  constructor(isoControlId?: number, controlId?: number) {
    this.isoControlId = isoControlId || 0;
    this.controlId = controlId || 0;
  }
}
