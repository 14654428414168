import React from 'react';
import { FontIcon, TooltipHost } from '@fluentui/react';
import { copyIcon, validIcon } from 'globalStyles';
import { useTranslation } from 'react-i18next';

interface ICopyIdProps {
  id: string | number;
}

const CopyId = (props: ICopyIdProps) => {
  const { t } = useTranslation('translation');
  const [copied, setCopied] = React.useState<boolean>(false);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | undefined>(undefined);

  React.useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyId = () => {
    navigator.clipboard.writeText(props.id.toString());
    setCopied(true);
    setTimer(setTimeout(() => setCopied(false), 2000));
  };

  return (
    <TooltipHost
      content={t('translation:CopyId.Tooltip', { id: props.id })}
      styles={{
        root: {
          selectors: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      }}
    >
      {!copied && <FontIcon {...copyIcon} onClick={copyId}></FontIcon>}
      {copied && <FontIcon {...validIcon}></FontIcon>}
    </TooltipHost>
  );
};

export default CopyId;
