import LanguageDTO from './languageDTO';

export default class Norm_TranslationDTO {
  normId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.name = '';
    this.normId = 0;
    this.languageId = 0;
  }
}

export class ISONorm_TranslationDTO {
  isoNormId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.name = '';
    this.isoNormId = 0;
    this.languageId = 0;
  }
}
