import LanguageDTO from '../languageDTO';

export class KPI_TranslationDTO {
  kpiId: number;

  languageId: number;

  name: string;

  description?: string;

  instruction?: string;

  expectedResult?: string;

  lang?: LanguageDTO;

  constructor() {
    this.kpiId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
