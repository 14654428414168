import { useContext, useEffect, useState } from 'react';
import { apiGetAuthSchemaForUser } from 'services/Api/authService';
import { apiRequest } from 'services/Auth/authConfig';
import { AuthSchemaLineEditor } from './AuthSchemaLineEditor';
import AppContext from 'App/AppContext';
import { AuthSchema } from 'models/auth/authSchema';
import { Stack, Spinner, SpinnerSize } from '@fluentui/react';
import { sortOnString } from 'utils/sorting';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'components/Notification/InfoMessage';

interface IAuthSchemaInfoProps {
  authSchemaId: number | undefined;
  showNoMemberMsg: boolean;
}

export const AuthSchemaInfo = (props: IAuthSchemaInfoProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'adminAuth']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authSchema, setAuthSchema] = useState<AuthSchema | undefined>(undefined);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authSchemaId]);

  const loadData = async () => {
    try {
      setAuthSchema(undefined);
      if (!props.authSchemaId) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const schema = await apiGetAuthSchemaForUser(props.authSchemaId, accessToken);

      if (schema) {
        schema.lines.sort((a, b) => {
          const memberA = a.getMember(appContext)?.name;
          const memberB = b.getMember(appContext)?.name;

          return sortOnString(memberA, memberB);
        });
      }

      setAuthSchema(schema);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!authSchema && props.showNoMemberMsg) {
    return <InfoMessage>{t('adminAuth:AuthSchemaPicker.NoMemberCannotSeeContent')}</InfoMessage>;
  }

  if (!authSchema) {
    return null;
  }

  return <AuthSchemaLineEditor lines={authSchema.lines} readonly={true} />;
};
