import { ReactNode } from 'react';
import { Text, Image, IImageProps, ImageFit } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import Config from 'services/Config/configService';
import ScenePivot, { IScenePivotItemProps } from 'components/SceneBar/ScenePivot';
import {
  globalTextStylesPaddingSmall,
  globalTextStylesBoldPaddingSmall,
  globalStackItemStylesPaddingScene,
  globalStackTokensGapSmall,
  globalPaddingScene,
} from 'globalStyles';
import PinnedLinkTabs from 'components/Links/PinnedLinkTabs';
import ResourceLink from 'models/resourceLink';
import CopyLink from 'components/Utils/CopyLink';
import { globalNavBarHeight } from 'globalConstants';

interface ISceneBarProps {
  title: string;
  subtitle: string;
  image: string;
  pivotItems: IScenePivotItemProps[] | undefined;
  selectedPivotKey: string | undefined;
  pinnedLinks?: ResourceLink[];
  selectedPinnedLink?: ResourceLink;
  onSelectPivot?: (item: IScenePivotItemProps) => void;
  onSelectPinnedLink?: (link: ResourceLink) => void;
  linkUrl?: string;
  children?: ReactNode;
}

const SceneBar = (props: ISceneBarProps) => {
  const imagePropsSceneTitle: IImageProps = {
    src: `${Config.getImageURL()}/${props.image}`,
    imageFit: ImageFit.centerContain,
    width: globalNavBarHeight,
    height: globalNavBarHeight,
  };

  return (
    <Stack horizontal wrap verticalAlign="center" styles={{ root: { minHeight: 64 } }}>
      <Stack.Item>
        <Stack
          horizontal
          verticalAlign="center"
          styles={globalStackItemStylesPaddingScene}
          tokens={globalStackTokensGapSmall}
        >
          <Stack.Item>
            <Image {...imagePropsSceneTitle}></Image>
          </Stack.Item>
          <Stack.Item>
            <Text styles={globalTextStylesBoldPaddingSmall} block nowrap variant="large">
              {props.title}
            </Text>
            <Stack horizontal>
              <Stack.Item>
                <Text styles={globalTextStylesPaddingSmall} block variant="small">
                  {props.subtitle}
                </Text>
              </Stack.Item>
              {props.linkUrl && (
                <Stack.Item>
                  <CopyLink url={props.linkUrl}></CopyLink>
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {props.pivotItems && (
        <Stack.Item grow={1}>
          <ScenePivot
            items={props.pivotItems}
            selectedKey={props.selectedPivotKey}
            onSelectPivot={props.onSelectPivot}
          />
        </Stack.Item>
      )}
      {props.pinnedLinks && props.onSelectPinnedLink && (
        <Stack.Item grow>
          <PinnedLinkTabs
            links={props.pinnedLinks}
            selectedLink={props.selectedPinnedLink}
            onClick={props.onSelectPinnedLink}
          ></PinnedLinkTabs>
        </Stack.Item>
      )}
      {props.children && (
        <Stack.Item grow={5}>
          <Stack horizontal tokens={globalStackTokensGapSmall} styles={{ root: { paddingLeft: globalPaddingScene } }}>
            {props.children}
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default SceneBar;
