import React, { ReactNode } from 'react';
import AuthService from 'services/Auth/authService';
import { PublicClientApplication } from '@azure/msal-browser';
import { apiRequest, msalConfigCommon } from 'services/Auth/authConfig';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import { globalDesktopSize, globalDesktopHeight } from 'globalConstants';
import { toast } from 'react-toastify';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { apiGetTenantsForUser } from 'services/Api/tenantService';
import { isValidGuid } from 'utils/guid';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import Tenant from 'models/tenant';
import { SubscriptionTypes } from 'utils/subscription';
import {
  setSessionStorageData,
  SessionStorageKeys,
  getSessionStorageData,
  removeSessionStorageData,
} from 'utils/sessionStorage';
import { DefLanguageCode } from 'models/setting';
import UserLanguage from 'models/userLanguage';
import { i18nBase } from 'services/Localization/i18n';
import logger from 'services/Logging/logService';
import AppContext, { IAppContext } from './AppContext';
import { Client } from '@microsoft/microsoft-graph-client';
import AdminTenant from 'models/adminTenant';
import { ErrorMessage } from 'components/Notification/ErrorMessage';
import { IImageProps, ImageFit, Stack, Image } from '@fluentui/react';
import Config from 'services/Config/configService';

//
// Types
//
export type AuthStateUpdate = (
  isAuthenticated?: boolean | undefined,
  user?: CurrentUser | undefined,
  isAuthInProgress?: boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | undefined,
) => void;

export type SetGlobalDataCache = (globalDataCache: GlobalDataCache) => void;

export type IGraphInterface = {
  accessToken: string;
  client: Client;
};

//Main logo
export const imagePropsLogo: IImageProps = {
  src: `${Config.getImageURL()}/logo.png`,
  imageFit: ImageFit.contain,
  width: 400,
  height: 200,
};

//
// Global vars
//
export let globalOrgUnitId: string | undefined = undefined;
export let globalUserLang: string | undefined = undefined;
export let globalDefOrgLang: string | undefined = undefined;
export let globalCustomerId: string | undefined = undefined;

interface IAppContextProps {
  children?: ReactNode;
}

class AppContextProvider extends React.Component<IAppContextProps, IAppContext> {
  private publicClientApplication: PublicClientApplication;

  constructor(props: IAppContextProps) {
    super(props);

    this.state = {
      useDarkMode: false,
      setUseDarkMode: this.setUseDarkMode,
      isContentLoading: false,
      showContentLoader: this.showContentLoader,
      hideContentLoader: this.hideContentLoader,
      unhandledError: undefined,
      error: undefined,
      setError: this.setErrorMessage,
      showNotification: this.showNotification,
      hasScopes: this.hasScopes,
      getAccessToken: this.getAccessToken,
      setUserProfile: this.setUserProfile,
      isAuthenticated: false,
      isAuthInProgress: true, // set to true because we start with a silent SSO request
      user: CurrentUser.getEmptyUser(),
      showResourcePanel: false,
      toggleResourcePanel: this.toggleResourcePanel,
      isMainNavCollapsed: false,
      toggleMainNavCollapsed: this.toggleIsMainNavCollapsed,
      globalDataCache: new GlobalDataCache(),
      cacheMiss: this.cacheMiss,
      getGraphInterface: this.getGraphInterface,
      windowSize: globalDesktopSize,
      isMobileView: false,
      toggleSideBarPanel: this.toggleSideBarPanel,
      isOpenSideBarPanel: false,
      refreshTenantList: this.refreshTenantList,
      windowHeight: globalDesktopHeight,
      setShowResourcePanel: this.setShowResourcePanel,
      isOpenHelpPanel: false,
      setHelpPanel: this.setHelpPanel,
      adminConsent: this.adminConsent,
      adminConsentScope: this.adminConsentScope,
      startQuery: undefined,
      clearStartQuery: this.clearStartQuery,
      changeUserLanguage: this.changeUserLanguage,
      setLogo: this.setLogo,
      login: this.login,
      logout: this.logout,
      switchOrg: this.switchOrg,
      switchOrgUnit: this.switchOrgUnit,
      switchCustomer: this.switchCustomer,
    };

    this.publicClientApplication = new PublicClientApplication(msalConfigCommon);
  }

  //
  // Startup
  //
  componentDidMount() {
    try {
      //Set initial query
      let startQuery: string | undefined;
      if (window.location.search) {
        startQuery = window.location.search;
        //save the query params to the session state to apply again after auth redirect
        setSessionStorageData(SessionStorageKeys.StartQuery, startQuery);
        this.setState({ startQuery: startQuery });
      } else {
        const query = getSessionStorageData(SessionStorageKeys.StartQuery);
        if (query) {
          //apply any previously saved start query and remove it
          removeSessionStorageData(SessionStorageKeys.StartQuery);
          this.setState({ startQuery: query });
          startQuery = query;
        }
      }

      // Set the light or dark mode
      const useDarkMode = getLocalStorageData(this.state, LocalStorageKeys.DarkMode) === 'true';
      this.setState({
        useDarkMode: useDarkMode,
      });

      // Set show resource panel
      const showResourcePanel = getLocalStorageData(this.state, LocalStorageKeys.ShowResourcePanel);
      this.setState({
        showResourcePanel: !showResourcePanel || showResourcePanel === 'true',
      });

      // Set main menu collapsed
      const mainNavCollapsed = getLocalStorageData(this.state, LocalStorageKeys.MainNavCollapsed);
      this.setState({
        isMainNavCollapsed: mainNavCollapsed === 'true',
      });

      // Add main window resize event
      window.addEventListener('resize', this.onResize);
      this.onResize();

      //start the authentication process
      this.initializeAuth(startQuery);
    } catch (err) {
      this.setErrorMessage(err);
    }
  }

  initializeAuth = async (startQuery: string | undefined) => {
    // Initialize the MSAL application object
    await this.publicClientApplication.initialize();

    // Try to login silently
    await AuthService.ssoSilent(
      this.publicClientApplication,
      this.getTenantFromRoute(startQuery),
      this.authStateUpdate,
      this.setGlobalDataCache,
    );
  };

  componentWillUnmount() {
    //remove resize event
    window.removeEventListener('resize', this.onResize);
  }

  clearStartQuery = () => {
    if (this.state.startQuery) {
      this.setState({ startQuery: undefined });
    }
  };

  getTenantFromRoute = (startQuery: string | undefined): string | undefined => {
    try {
      logger.debug('Start query: ' + startQuery);
      const params = new URLSearchParams(startQuery);

      let urlOrgUnitParam = params.get('ouid') || undefined;
      if (isValidGuid(urlOrgUnitParam)) {
        this.setOrgUnit(urlOrgUnitParam);
        this.clearStartQuery();
      } else {
        urlOrgUnitParam = undefined;
      }

      const urlTenantParam = params.get('tid') || undefined;
      if (isValidGuid(urlTenantParam)) {
        //the tid must be the Azure Tenant Id
        //When a specific tenant is requested but no OU is specified, it is expected that the user will be logged into the top level tenant.
        //However, the API will automatically log into the OU that was last accessed by the user, unless an OU is specified
        //We can set the OU to the same Id as the top level tenant to signal the API to log into the top level tenant
        if (!urlOrgUnitParam) {
          this.setOrgUnit(urlTenantParam);
          this.clearStartQuery();
        }

        return urlTenantParam;
      }

      return undefined;
    } catch (err) {
      return undefined;
    }
  };

  //
  // Loader functions
  //
  showContentLoader = () => this.setState({ isContentLoading: true });

  hideContentLoader = () => this.setState({ isContentLoading: false });

  //
  // Light and dark mode functions
  //
  setUseDarkMode = (useDarkMode: boolean) => {
    setLocalStorageData(this.state, LocalStorageKeys.DarkMode, useDarkMode.toString());
    this.setState({ useDarkMode: useDarkMode });
  };

  //
  // Preview featuresd
  //
  enablePreviewFeatures = (type: SubscriptionTypes) => {
    //enable preview languages
  };

  //
  // Authentication service wrapper functions
  //
  authStateUpdate = (
    isAuthenticated?: boolean | undefined,
    user?: CurrentUser | undefined,
    isAuthInProgress?: boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | undefined,
  ) => {
    //when authentication is done and we have a user
    if (user && !isAuthInProgress) {
      //enable preview features
      if (user.login.subscriptionType) {
        this.enablePreviewFeatures(user.login.subscriptionType);
      }

      //apply the user language
      logger.debug('Setting user language to: ' + (user.login.userLanguageCode ?? user.language.code));
      if (user.login.userLanguageCode) {
        // Language setting from database overrides the Microsoft account setting
        user.language = new UserLanguage(user.login.userLanguageCode);
      }
      i18nBase.changeLanguage(user.language.code);

      // set language headers
      this.setLanguages(user);

      // let the global data cache know so it can change the language
      this.state.globalDataCache.setCurrentUserLanguage(user);

      //update the global state for the organizational unit when user has logged into one
      if (user.login.isOrgUnit) {
        this.setOrgUnit(user.login.tenantId);
      } else {
        this.setOrgUnit(undefined);
      }
    }

    // Usetiful integration
    if (user) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const win = window as any;
      win['usetifulTags'] = {
        tenantId: user.login.tenantId,
        userId: user.id,
        language: user.language.code,
        email: user.email,
        name: user.name,
        subscription: user.login.subscriptionType.toString(),
        trial: user.login.isInTrial().toString(),
        leadSource: user.login.leadSource,
      };
    }

    // Update the state with 1 statement, otherwise timing issues will arrise
    // So for each state property to set, check if it's defined and if so, set it, otherwise set it to the current state
    const newState = {
      isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : this.state.isAuthenticated,
      isAuthInProgress: isAuthInProgress !== undefined ? isAuthInProgress : this.state.isAuthInProgress,
      user: user !== undefined ? user : this.state.user,
      error: error !== undefined ? error : this.state.error,
    };

    this.setState(newState);
  };

  login = async () => {
    this.setOrgUnit(undefined);

    return await AuthService.login(
      this.publicClientApplication,
      undefined,
      this.authStateUpdate,
      this.setGlobalDataCache,
    );
  };

  logout = async () => {
    this.setOrgUnit(undefined);

    return await AuthService.logout(this.publicClientApplication, this.authStateUpdate);
  };

  switchOrg = async (item: Tenant) => {
    if (item.parentId) {
      //request for a direct switch to an org unit within a guest
      this.setOrgUnit(item.id);
    } else {
      //set the org unit to the same Id as the Azure tenant Id
      //so that the back-end knows to login into the top level tenant instead of the last known org unit
      this.setOrgUnit(item.id);
    }

    await AuthService.switchTenant(this.publicClientApplication, this.authStateUpdate, this.setGlobalDataCache, item);
  };

  switchOrgUnit = async (item: Tenant) => {
    this.setOrgUnit(item.id);
    await AuthService.setUserProfile(this.publicClientApplication, this.authStateUpdate, this.setGlobalDataCache);
  };

  setOrgUnit = (orgUnitId: string | undefined) => {
    if (orgUnitId) {
      globalOrgUnitId = orgUnitId;
    } else {
      globalOrgUnitId = undefined;
    }
  };

  switchCustomer = async (item: AdminTenant) => {
    this.setCustomer(item.tenantId);
    await this.state.globalDataCache.refresh();
  };

  setCustomer = (customerId: string | undefined) => {
    if (customerId) {
      globalCustomerId = customerId;
    } else {
      globalCustomerId = undefined;
    }
  };

  changeUserLanguage = async (code: string): Promise<string> => {
    const newUser = this.state.user;

    if (code === '') {
      //revert language to Microsoft account
      newUser.language = new UserLanguage(newUser.accountLanguageCode);
    } else if (!UserLanguage.getSupportedLanguages().has(code)) {
      //revert to the fallback language
      newUser.language = new UserLanguage(UserLanguage.getFallBack());
    } else {
      newUser.language = new UserLanguage(code);
    }

    //inform the localization service to change the language
    await i18nBase.changeLanguage(newUser.language.codeWithCulture);

    //re-initialize the global data cache with the new language
    this.state.globalDataCache.setCurrentUserLanguage(newUser);

    //re-initialize the global language variables for sending in headers
    this.setLanguages(newUser);

    //update the global state
    this.setState({ user: newUser });

    return newUser.language.code;
  };

  setLanguages = (user: CurrentUser) => {
    globalUserLang = user.language.code;
    globalDefOrgLang = this.state.globalDataCache.settings.get(DefLanguageCode) as string;
  };

  adminConsent = (redirectUrl: string) => {
    return AuthService.adminConsent(this.publicClientApplication, this.authStateUpdate, redirectUrl);
  };

  adminConsentScope = (clientId: string, scopes: string[], redirectUrl: string) => {
    const scopeStr = scopes.join(' ');

    return AuthService.adminConsentScope(
      clientId,
      scopeStr,
      this.publicClientApplication,
      this.authStateUpdate,
      redirectUrl,
    );
  };

  hasScopes = async (scopes: string[]) => {
    return await AuthService.hasScopes(this.publicClientApplication, scopes);
  };

  getAccessToken = async (scopes: string[]) => {
    return await AuthService.getAccessToken(this.publicClientApplication, scopes);
  };

  setUserProfile = async () => {
    return await AuthService.setUserProfile(
      this.publicClientApplication,
      this.authStateUpdate,
      this.setGlobalDataCache,
    );
  };

  getGraphInterface = async (scopes: string[], tenantId?: string): Promise<IGraphInterface> => {
    //Get a graph client interface for the requested scopes
    //When a tenantId is supplied, check if the current user has a valid license and try to get an access token.
    //This may result in that the user must login to that tenant
    let tenant: Tenant | undefined = undefined;
    if (tenantId) {
      tenant = this.state.user.login.tenants?.find((t) => t.azureTenantId === tenantId);
      if (!tenant) {
        logger.debug(`Requested Graph interface for tenant not found: ${tenantId}`);
      }
    }

    const accessToken = await AuthService.getAccessToken(this.publicClientApplication, scopes, tenant);
    const client = AuthService.getGraphClient(accessToken);
    const graph: IGraphInterface = { accessToken, client };

    return graph;
  };

  refreshTenantList = async () => {
    try {
      const newUser = this.state.user.clone();
      const accessToken = await AuthService.getAccessToken(this.publicClientApplication, apiRequest.scopes);
      const newOrgUnitsList = await apiGetTenantsForUser(accessToken);
      newUser.login.tenants = newOrgUnitsList;
      this.setState({ user: newUser });
    } catch (err) {
      this.setErrorMessage(err);
    }
  };

  //
  // Global cache functions
  //
  setLogo = (logo: Blob) => {
    const newUser = this.state.user.clone();
    newUser.tenant.appLogo = logo;
    this.setState({ user: newUser });
  };

  setGlobalDataCache = (globalDataCache: GlobalDataCache) => {
    globalDataCache.setAppContext(this.state);
    this.setState({ globalDataCache: globalDataCache.clone() });
  };

  cacheMiss = async (count: number) => {
    //disable cache refresh for now. It can cause infinite render loops
    //if (count < 5) {
    //await this.state.globalDataCache.refresh();
    //} else {
    //this.setErrorMessage('Too many cache misses detected. Please refresh the browser (F5).');
    //}
  };

  //
  // Global error handling
  //
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    let newUnhandledError = this.normalizeError(error);

    if (!newUnhandledError) {
      newUnhandledError = new AppError('Unknown error');
    }

    //try to refresh the browser for certain errors
    let resolveByReload = false;

    //1. When Babel cannot load a module: this can be the case after a live update
    resolveByReload =
      newUnhandledError.message?.includes('Loading chunk') && newUnhandledError.message?.includes('failed');

    //reload or show error
    if (resolveByReload) {
      window.location.reload();
    } else {
      newUnhandledError.code = '999';
      newUnhandledError.debug = errorInfo.componentStack || '';

      this.setState({
        unhandledError: newUnhandledError,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrorMessage = (error: string | any) => {
    this.setState({
      error: this.normalizeError(error),
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizeError = (error: string | any): AppError | undefined => {
    if (!error) {
      return undefined;
    }
    let normalizedError: AppError;

    //when the error is already an AppError, return that
    if (error instanceof AppError) {
      normalizedError = error;
    } else if (typeof error === 'string') {
      //for raw strings, split on | and create a new AppError
      let errParts = error.split('|');
      normalizedError = errParts.length > 1 ? new AppError(errParts[1], '', errParts[0]) : new AppError(error);
    } else {
      //else, create a new AppError and try to add properties like message, code and stack
      normalizedError = new AppError(
        error.message,
        error.code ? error.code : '',
        JSON.stringify(error),
        error.stack ? error.stack : '',
      );
    }

    //when this instance is the same as the instance in the state, return the state
    if (normalizedError.isEqual(this.state.error)) {
      return this.state.error;
    } else {
      return normalizedError;
    }
  };

  showNotification = (msg: string, isError: boolean = false) => {
    if (isError) {
      toast.error(msg);
    } else {
      toast(msg);
    }
  };

  //
  // Panel functions
  //
  toggleResourcePanel = () => {
    this.setState({ showResourcePanel: !this.state.showResourcePanel });
  };

  setShowResourcePanel = (enable: boolean) => {
    this.setState({ showResourcePanel: enable });
  };

  toggleIsMainNavCollapsed = () => {
    this.setState({ isMainNavCollapsed: !this.state.isMainNavCollapsed });
  };

  toggleSideBarPanel = () => {
    this.setState({ isOpenSideBarPanel: !this.state.isOpenSideBarPanel });
  };

  setHelpPanel = (enable: boolean) => {
    this.setState({ isOpenHelpPanel: enable });
  };

  //
  // Mobile functions
  //
  getWindowWidth = (): number => {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  };

  getWindowHeight = (): number => {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  };

  onResize = () => {
    try {
      const width = this.getWindowWidth();
      const height = this.getWindowHeight();
      this.setState({
        windowSize: width,
        windowHeight: height,
        isMobileView: width >= globalDesktopSize ? false : true,
      });
    } catch {
      //ignore
    }
  };

  render() {
    if (this.state.unhandledError) {
      return (
        <Stack horizontalAlign="center">
          <ErrorMessage error={this.state.unhandledError} />
          <Image {...imagePropsLogo} />
        </Stack>
      );
    } else {
      return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
    }
  }
}

export default AppContextProvider;
