import { FontIcon, Stack, TooltipHost, Text, Link, getTheme } from '@fluentui/react';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall, infoIcon } from 'globalStyles';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import { ReactNode, useContext } from 'react';

interface ILearnMoreProps {
  learnMoreLink?: string;
}

export const LearnMore = (props: ILearnMoreProps) => {
  const { t } = useTranslation(['translation']);
  const theme = getTheme();

  if (!props.learnMoreLink) return null;

  return (
    <Stack
      horizontal
      tokens={globalStackTokensGapExtraSmall}
      verticalAlign="center"
      styles={{ root: { paddingTop: 5 } }}
    >
      <FontIcon iconName="OpenInNewTab" style={{ color: theme.palette.themePrimary }} />
      <Text>
        <Link href={props.learnMoreLink} target="_blank">
          {t('translation:General.Notifications.LearnMore')}
        </Link>
      </Text>
    </Stack>
  );
};

interface IInfoProps {
  message: string | JSX.Element;
  learnMoreLink?: string;
}

export const Info = (props: IInfoProps) => {
  const appContext = useContext(AppContext);

  const getJSX = (): JSX.Element | null => {
    if (typeof props.message !== 'string') {
      return props.message as JSX.Element;
    } else {
      return null;
    }
  };

  const getMessageJSX = (): JSX.Element => {
    return (
      <Stack>
        <Stack.Item>
          {typeof props.message === 'string' && <Text>{props.message}</Text>}
          {getJSX()}
        </Stack.Item>
        <Stack.Item>
          <LearnMore learnMoreLink={props.learnMoreLink} />
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <TooltipHost content={getMessageJSX()} styles={{ root: { cursor: 'pointer' } }} calloutProps={{ beakWidth: 0 }}>
      <FontIcon {...infoIcon} style={appContext.useDarkMode ? { color: 'lightblue' } : { color: 'blue' }}></FontIcon>
    </TooltipHost>
  );
};

interface ITextInfoProps {
  message?: string | JSX.Element;
  learnMoreLink?: string;
  children: ReactNode;
}

export const TextInfo = (props: ITextInfoProps) => {
  if (!props.message) {
    return <Stack>{props.children}</Stack>;
  } else {
    return (
      <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
        {props.children}
        <Info message={props.message} learnMoreLink={props.learnMoreLink} />
      </Stack>
    );
  }
};

export default TextInfo;
