import {
  Callout,
  Stack,
  Persona,
  IPersonaStyles,
  PersonaSize,
  DetailsList,
  IDetailsListProps,
  IDetailsRowStyles,
  getTheme,
  DetailsRow,
  IColumn,
  SelectionMode,
  DetailsListLayoutMode,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { globalStackTokensGapSmall } from 'globalStyles';
import User from 'models/user';
import AppContext from 'App/AppContext';

interface IUserMentionPickerCallOut {
  targetId: string;
  onClose: () => void;
  searchText: string | undefined;
  isVisible: boolean;
  onChange: (user: User | undefined) => void;
  onSelect: (user: User | undefined) => void;
  selectedUser: User | undefined;
}

export const UserMentionPickerCallOut = (props: IUserMentionPickerCallOut) => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const appContext = useContext(AppContext);
  const theme = getTheme();

  useEffect(() => {
    const loadData = (text: string | undefined) => {
      if (text && text.length > 1) {
        const allUsers = appContext.globalDataCache.users.items;
        const textLower = text.toLowerCase().substring(1);
        const selectedUsers = allUsers.filter(
          (u) => u.email.toLowerCase().indexOf(textLower) >= 0 || u.name.toLowerCase().indexOf(textLower) >= 0,
        );
        setUsers(selectedUsers.slice(0, 5));
        if (selectedUsers.length > 0) {
          props.onSelect(selectedUsers[0]);
        }
      } else if (text) {
        const allUsers = appContext.globalDataCache.users.items;
        setUsers(allUsers.slice(0, 5));
        if (allUsers.length > 0) {
          props.onSelect(allUsers[0]);
        }
      }
    };

    loadData(props.searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.globalDataCache.users.items, props.selectedUser, props.searchText]);

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (rowProps) => {
    let customStyles: Partial<IDetailsRowStyles> = {};

    if (rowProps?.item.id === props.selectedUser?.id) {
      customStyles = {
        root: {
          backgroundColor: theme.palette.themeSecondary,
          borderRadius: '3px',
          selectors: {
            '&:hover': {
              backgroundColor: theme.palette.themeSecondary,
            },
          },
        },
      };
    }

    if (rowProps) {
      return <DetailsRow {...rowProps} styles={customStyles} />;
    }

    return null;
  };

  const activeItemChanged = (
    item: User,
    index: number | undefined,
    ev: React.FocusEvent<HTMLElement> | undefined,
  ): void => {
    props.onSelect(item);
  };

  const getColumns = (): IColumn[] => {
    return [
      {
        key: 'user',
        name: 'user',
        minWidth: 100,
        onRender: (_user: User, index: number | undefined) => (
          <Persona
            styles={personaStyle}
            size={PersonaSize.size40}
            text={_user.name}
            secondaryText={_user.email}
            onClick={() => props.onChange(_user)}
          />
        ),
      },
    ];
  };

  return (
    <Callout
      hidden={!props.isVisible}
      styles={{
        root: {
          padding: 10,
          width: 300,
          maxHeight: 300,
        },
      }}
      target={`.${props.targetId}`}
      onDismiss={props.onClose}
      beakWidth={16}
    >
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
          <DetailsList
            items={users || []}
            columns={getColumns()}
            compact={true}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            isHeaderVisible={false}
            onActiveItemChanged={activeItemChanged}
            onRenderRow={onRenderRow}
          />
          {/* </ScrollablePane> */}
        </Stack.Item>
      </Stack>
    </Callout>
  );
};

export default UserMentionPickerCallOut;
