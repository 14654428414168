import {
  ShimmeredDetailsList,
  IColumn,
  SelectionMode,
  Selection,
  Stack,
  Text,
  Spinner,
  SpinnerSize,
  ScrollablePane,
  ScrollbarVisibility,
  DetailsListLayoutMode,
  ConstrainMode,
  IObjectWithKey,
  DirectionalHint,
  IDetailsRowProps,
  TooltipHost,
} from '@fluentui/react';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { ColumnDefinition } from 'microsoft-graph';
import { useTranslation } from 'react-i18next';
import { onRenderForColumn } from './ColumnRenderers';
import { getLinkNameFromListItem, IListItem } from '../../services/Graph/SharepointInterfaces';
import { graphHasValidDataType } from 'services/Graph/graphServiceList';
import ResourceLink from 'models/resourceLink';
import { useEffect, useState } from 'react';

interface IMyListItemsProps {
  items: IListItem[];
  columns: ColumnDefinition[];
  loading: boolean;
  onSelect: (items: IListItem[]) => void;
  viewMode?: boolean;
  existingLinks?: ResourceLink[];
  linkToEdit?: ResourceLink;
}

const convertToRenderColumn = (columns: ColumnDefinition[]): IColumn[] => {
  return columns.map((columnDef: ColumnDefinition) => ({
    key: `${columnDef.name}`,
    name: `${columnDef.displayName}`,
    minWidth: 100,
    isResizable: true,
    isMultiline: true,
    onRender: (_item?: IListItem, index?: number, column?: IColumn) => {
      if (!_item || !columnDef) return;

      return onRenderForColumn(_item, columnDef);
    },
  }));
};

//Filter only supported columns : boolean, dateTime, personOrGroup , text , currency ,number , choice
const filterSupportedColumns = (items: ColumnDefinition[]): ColumnDefinition[] => {
  return items.filter((item) => {
    return graphHasValidDataType(item);
  });
};

const MyListItems = (props: IMyListItemsProps) => {
  const [columns, setColumns] = useState<IColumn[]>([]);
  const { t } = useTranslation(['sharepoint']);

  useEffect(() => {
    const cols = convertToRenderColumn(filterSupportedColumns(props.columns));
    setColumns(cols);
  }, [props.columns, props.items]);

  const selection = new Selection<IListItem>({
    canSelectItem(item) {
      const itemName = getLinkNameFromListItem(item);

      return (
        item.id !== undefined &&
        !props.existingLinks?.find(
          (existingLink) =>
            props.linkToEdit?.linkId !== existingLink.linkId &&
            (existingLink.listItemId === item.id || existingLink.linkName.toLowerCase() === itemName.toLowerCase()),
        )
      );
    },
    getKey(item) {
      return item.id || '';
    },
    onSelectionChanged: () => {
      const items = selection.getSelection() as IListItem[];
      props.onSelect(items);
    },
  });

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (rowProps?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (defaultRender && rowProps?.item && !selection.canSelectItem(rowProps.item) && !rowProps.item.folder) {
      rowProps.disabled = true;

      return (
        <TooltipHost content={t('sharepoint:SharepointPicker.ItemExists')} directionalHint={DirectionalHint.topLeftEdge}>
          {defaultRender(rowProps)}
        </TooltipHost>
      );
    }

    if (defaultRender) {
      return defaultRender(rowProps);
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  if (props.loading) {
    return (
      <Stack verticalFill verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (props.items.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('sharepoint:SharepointPicker.NoData')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            columns={columns}
            compact
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            items={props.items}
            selectionMode={props.viewMode ? SelectionMode.none : SelectionMode.multiple}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            enableShimmer={props.loading}
            shimmerLines={4}
            selection={selection as Selection<IObjectWithKey>}
            onRenderRow={onRenderRow}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default MyListItems;
