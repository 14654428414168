import RisksDTO from 'models/dto/risksDTO';
import Risks from 'models/risks';
import http from './httpService';
import AppError from 'utils/appError';
import {
  mapFromRisk,
  mapToRisk,
  mapFromRisks,
  mapFromRiskControlTasks,
  mapFromRiskContext,
} from 'mappings/dataMapping';
import Risk from 'models/risk';
import RiskDTO, { RiskControlDTO, RiskProcessDTO, RiskThemeDTO } from 'models/dto/riskDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { RiskControlTasksDTO } from 'models/dto/RiskControlTasksDTO';
import { RiskObjectiveDTO } from 'models/dto/Objective/objectiveRiskDTO';
import { RiskControlTasks } from 'models/riskControlTasks';
import { RiskContext } from 'models/riskContext';
import { RiskContextDTO } from 'models/dto/riskContextDTO';
import { AssetRiskDTO } from 'models/dto/asset/assetDTO';
import { toApiDateTimeRequired } from 'utils/datetime';

export async function apiGetRisks(accessToken: string, globalDataCache: GlobalDataCache): Promise<Risks> {
  try {
    const ar = await http.get<RisksDTO>(`/risks`, http.getRequestConfig(accessToken));

    return mapFromRisks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetNewRiskCode(accessToken: string, code: string): Promise<string> {
  try {
    const ar = await http.post<string>(`/risks/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateRisk(
  editRisk: Risk,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Risk> {
  try {
    const riskDTO = mapToRisk(editRisk);
    const ar = await http.put<RiskDTO>(`/risks`, riskDTO, http.getRequestConfig(accessToken));

    return mapFromRisk(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddRisk(newRisk: Risk, sourceRiskId: number | undefined, accessToken: string, globalDataCache: GlobalDataCache): Promise<Risk> {
  try {
    const riskDTO = mapToRisk(newRisk);
    const ar = await http.post<RiskDTO>(`/risks?sourceRiskId=${sourceRiskId ?? 0}`, riskDTO, http.getRequestConfig(accessToken));

    return mapFromRisk(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveRisk(risk: Risk, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/risks/delete/${risk.riskId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRisk(
  riskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Risks> {
  try {
    const ar = await http.get<RisksDTO>(`/risks/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromRisks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRisksSimilarToScore(
  score: number,
  editMode: 'initial' | 'current' | 'goal' | undefined,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Risks> {
  try {
    let phase: number = 0;
    switch (editMode) {
      case 'initial':
        phase = 1;
        break;
      case 'current':
        phase = 2;
        break;
      case 'goal':
        phase = 3;
        break;
    }

    const ar = await http.get<RisksDTO>(`/risks/score/around/${score}/${phase}`, http.getRequestConfig(accessToken));
    ar.data.risks = ar.data.risks.map((_risk) => _risk ?? new RiskDTO());

    return mapFromRisks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//method of fetching risks for Board
export async function apiGetRisksBoard(accessToken: string, globalDataCache: GlobalDataCache): Promise<Risks> {
  try {
    const ar = await http.get<RisksDTO>(`/risks/board`, http.getRequestConfig(accessToken));

    return mapFromRisks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateRiskKanbanState(risk: Risk, accessToken: string): Promise<Risk> {
  try {
    await http.put<RiskDTO>(
      `/risks/${risk.riskId}/?riskState=${risk.riskStateId}&sortOrder=${risk.sortOrder}`,
      {},
      http.getRequestConfig(accessToken),
    );

    return risk;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveControlFromRisk(riskId: number, controlId: number, accessToken: string): Promise<void> {
  try {
    const body = new RiskControlDTO();
    body.riskId = riskId;
    body.controlId = controlId;
    await http.post<RiskControlDTO>(`/risks/control?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddControlToRisk(riskId: number, controlId: number, accessToken: string): Promise<void> {
  try {
    const body = new RiskControlDTO();
    body.riskId = riskId;
    body.controlId = controlId;
    await http.post<RiskControlDTO>(`/risks/control?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRisksForControl(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Risks> {
  try {
    const ar = await http.get<RisksDTO>(`/risks/control/${controlId}`, http.getRequestConfig(accessToken));

    return mapFromRisks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRiskControlsTaskKPI(
  riskId: number,
  start: Date,
  end: Date,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<RiskControlTasks> {
  try {
    const ar = await http.get<RiskControlTasksDTO>(
      `/risks/${riskId}/controls/tasks?start=${toApiDateTimeRequired(start)}&end=${toApiDateTimeRequired(end)}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromRiskControlTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRiskControlsTask(
  riskId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<RiskControlTasks> {
  try {
    const ar = await http.get<RiskControlTasksDTO>(
      `/risks/${riskId}/controls/tasks`,
      http.getRequestConfig(accessToken),
    );

    return mapFromRiskControlTasks(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveThemeFromRisk(riskId: number, themeId: number, accessToken: string): Promise<boolean> {
  try {
    const body = new RiskThemeDTO();
    body.riskId = riskId;
    body.themeId = themeId;
    await http.post<RiskThemeDTO>(`/risks/theme?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddThemeToRisk(riskId: number, themeId: number, accessToken: string): Promise<boolean> {
  try {
    const body = new RiskThemeDTO();
    body.riskId = riskId;
    body.themeId = themeId;
    await http.post<RiskThemeDTO>(`/risks/theme?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Objective
export async function apiAddObjectiveToRisk(
  riskId: number,
  objectiveIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new RiskObjectiveDTO(riskId, objectiveIdTo);
    await http.post<RiskObjectiveDTO>(`/risks/objective?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveObjectiveFromRisk(
  riskId: number,
  objectiveIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new RiskObjectiveDTO(riskId, objectiveIdTo);
    await http.post<RiskObjectiveDTO>(`/risks/objective?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Process
export async function apiAddProcessToRisk(riskId: number, processIdTo: number, accessToken: string): Promise<boolean> {
  try {
    const body = new RiskProcessDTO(riskId, processIdTo);
    await http.post<RiskProcessDTO>(`/risks/process?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveProcessFromRisk(
  riskId: number,
  processIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new RiskProcessDTO(riskId, processIdTo);
    await http.post<RiskProcessDTO>(`/risks/process?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Asset
export async function apiAddRiskToAsset(assetId: number, riskId: number, accessToken: string): Promise<boolean> {
  try {
    const body = new AssetRiskDTO(assetId, riskId);
    await http.post<AssetRiskDTO>(`/assets/risk?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveRiskFromAsset(assetId: number, riskId: number, accessToken: string): Promise<boolean> {
  try {
    const body = new AssetRiskDTO(assetId, riskId);
    await http.post<AssetRiskDTO>(`/assets/risk?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Context
export const apiGetRiskContext = async (
  assetId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<RiskContext> => {
  try {
    const ar = await http.get<RiskContextDTO>(`/risks/context/${assetId}`, http.getRequestConfig(accessToken));

    return mapFromRiskContext(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
