import { globalStackTokensGapSmall, globalTextStylesCard } from 'globalStyles';
import { FontIcon, ProgressIndicator, SharedColors, Stack, Text } from '@fluentui/react';

interface IChecklistProgressProps {
  compact?: boolean;
  totalChecks: number;
  completedChecks: number;
  followUp: boolean;
}

const ChecklistProgress = (props: IChecklistProgressProps) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign={'center'} grow>
      <Stack.Item>
        <Text block variant={props.compact ? 'small' : 'medium'} styles={globalTextStylesCard}>
          {`${props.completedChecks}/${props.totalChecks}`}
        </Text>
      </Stack.Item>
      <Stack.Item grow>
        <ProgressIndicator
          barHeight={props.compact ? 5 : 8}
          percentComplete={props.totalChecks > 0 ? props.completedChecks / props.totalChecks : 0}
        />
      </Stack.Item>
      <Stack.Item>
        {props.followUp && (
          <FontIcon
            iconName="NavigateForward"
            style={{ fontSize: `${props.compact ? '12px' : '20px'}`, color: SharedColors.yellow10 }}
          />
        )}
        {!props.followUp && props.totalChecks > 0 && props.totalChecks === props.completedChecks && (
          <FontIcon
            iconName="SkypeCircleCheck"
            style={{ fontSize: `${props.compact ? '12px' : '20px'}`, color: SharedColors.green20 }}
          />
        )}
        {!props.followUp && (props.totalChecks === 0 || props.totalChecks !== props.completedChecks) && (
          <FontIcon iconName="CircleRing" style={{ fontSize: `${props.compact ? '12px' : '20px'}` }} />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default ChecklistProgress;
