export default class GroupChannelsLinksDTO {
  groupId: string;

  linkIds: number[];

  channelIds: string[];

  pinned?: boolean;

  constructor() {
    this.groupId = '';
    this.linkIds = [];
    this.channelIds = [];
  }
}
