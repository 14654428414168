import { IconButton, Separator, Stack, Text } from '@fluentui/react';
import { globalStackTokensGapSmall, globalTextStylesDisabled } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { getDateDiffDays } from 'utils/datetime';

export enum DateDividerType {
  None = 0,
  Today = 1,
  Yesterday = 2,
  LastWeek = 3,
  LastMonth = 4,
  Older = 9,
  Tomorrow = 10,
  NextWeek = 11,
  NextMonth = 12,
  Newer = 19,
}

export type DateComparer<T> = (a: T) => Date;
export type DataGroupComparer<T> = (a: T, b: T) => boolean;
export type DataGroupGetName<T> = (a: T) => string;

export const insertDividersAndGroupsInList = <T extends unknown>(
  list: T[],
  getCompareDate: DateComparer<T>,
  groupComparer?: DataGroupComparer<T>,
  getGroupName?: DataGroupGetName<T>,
): (T | DateDivider | DataGroup<T>)[] => {
  const newList: (T | DateDivider | DataGroup<T>)[] = [];
  const now = new Date();
  const minGroupCount = 4;
  let currentDivider: DateDividerType | undefined;
  let currentGroup = new DataGroup<T>();

  const addOrRemoveGroup = (
    list: (T | DateDivider | DataGroup<T>)[],
    group: DataGroup<T>,
    item: T | undefined,
  ): DataGroup<T> => {
    //create a new group
    const newGroup = new DataGroup<T>();
    if (item && getGroupName) {
      newGroup.text = getGroupName(item);
    }

    if (!groupComparer || group.items.length < minGroupCount) {
      //discard group add items from group to list
      list.push(...group.items);
    } else {
      //add group itself to list
      list.push(group);
    }

    return newGroup;
  };

  for (let idx = 0; idx < list.length; idx++) {
    const item = list[idx];

    //determine divider type
    let diffDays = getDateDiffDays(now, getCompareDate(item));
    let newDivider = new DateDivider();

    if (diffDays > 31) {
      newDivider.dividerType = DateDividerType.Older;
    } else if (diffDays > 7 && diffDays <= 31) {
      newDivider.dividerType = DateDividerType.LastMonth;
    } else if (diffDays > 1 && diffDays <= 7) {
      newDivider.dividerType = DateDividerType.LastWeek;
    } else if (diffDays > 0 && diffDays <= 1) {
      newDivider.dividerType = DateDividerType.Yesterday;
    } else if (diffDays === 0) {
      newDivider.dividerType = DateDividerType.Today;
    } else if (diffDays < 0 && diffDays >= -1) {
      newDivider.dividerType = DateDividerType.Tomorrow;
    } else if (diffDays < -1 && diffDays >= -7) {
      newDivider.dividerType = DateDividerType.NextWeek;
    } else if (diffDays < -7 && diffDays >= -31) {
      newDivider.dividerType = DateDividerType.NextMonth;
    } else if (diffDays < -31) {
      newDivider.dividerType = DateDividerType.Newer;
    }

    if (currentDivider !== newDivider.dividerType && newDivider.dividerType !== DateDividerType.None) {
      //when there should be a new divider, create a new group and add the divider
      currentGroup = addOrRemoveGroup(newList, currentGroup, item);
      currentDivider = newDivider.dividerType;
      newList.push(newDivider);
    } else {
      if (idx > 0 && groupComparer) {
        //determine whether this item should be in a new group or the current group
        const cmpResult = groupComparer(list[idx - 1], item);
        if (!cmpResult) {
          currentGroup = addOrRemoveGroup(newList, currentGroup, item);
        }
      }
    }

    currentGroup.items.push(item);
  }

  addOrRemoveGroup(newList, currentGroup, undefined);

  return newList;
};

//
// Dividers
//
export class DateDivider {
  dividerType: DateDividerType;

  constructor() {
    this.dividerType = DateDividerType.None;
  }
}

export const DateDividerListItem = (props: DateDivider): JSX.Element | null => {
  const { t } = useTranslation(['translation']);
  let msg: string = '';

  switch (props.dividerType) {
    case DateDividerType.Today:
      msg = t('translation:ListDateDividers.Today');
      break;
    case DateDividerType.Yesterday:
      msg = t('translation:ListDateDividers.Yesterday');
      break;
    case DateDividerType.LastWeek:
      msg = t('translation:ListDateDividers.LastWeek');
      break;
    case DateDividerType.LastMonth:
      msg = t('translation:ListDateDividers.LastMonth');
      break;
    case DateDividerType.Older:
      msg = t('translation:ListDateDividers.Older');
      break;
    case DateDividerType.Tomorrow:
      msg = t('translation:ListDateDividers.Tomorrow');
      break;
    case DateDividerType.NextWeek:
      msg = t('translation:ListDateDividers.NextWeek');
      break;
    case DateDividerType.NextMonth:
      msg = t('translation:ListDateDividers.NextMonth');
      break;
    case DateDividerType.Newer:
      msg = t('translation:ListDateDividers.Newer');
      break;
    default:
      return null;
  }

  return (
    <Stack
      horizontal
      styles={{ root: { paddingBottom: 6, paddingRight: 10 } }}
      tokens={globalStackTokensGapSmall}
      verticalAlign="center"
    >
      <Stack.Item grow>
        <Separator />
      </Stack.Item>
      <Stack.Item>
        <Text variant="small" styles={globalTextStylesDisabled}>
          {msg}
        </Text>
      </Stack.Item>
      <Stack.Item grow>
        <Separator />
      </Stack.Item>
    </Stack>
  );
};

//
// Group
//
export class DataGroup<T> {
  items: T[];

  text: string;

  onExpand?: (items: T[]) => void;

  constructor() {
    this.items = [];
    this.text = '';
  }
}

export const DataGroupListItem = <T extends unknown>(props: DataGroup<T>): JSX.Element | null => {
  const { t } = useTranslation(['translation']);

  return (
    <Stack
      horizontal
      styles={{ root: { paddingLeft: 5, paddingBottom: 6, paddingRight: 10, cursor: 'pointer' } }}
      tokens={{ childrenGap: 15 }}
      verticalAlign="center"
      onClick={() => {
        if (props.onExpand) props.onExpand(props.items);
      }}
    >
      <Stack.Item>
        <IconButton iconProps={{ iconName: 'CaretRightSolid8' }} />
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <Stack.Item>
            <Text>{props.text}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="small" styles={globalTextStylesDisabled}>
              {t('translation:General.List.ItemCount', { count: props.items.length })}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default DateDividerListItem;
