import http from './httpService';
import AppError from 'utils/appError';
import ControlDTO from 'models/dto/controlDTO';
import Control from 'models/control';
import ControlSceneDTO from 'models/dto/controlSceneDTO';
import ControlScene from 'models/controlScene';
import {
  mapFromControls,
  mapFromControl,
  mapToControl,
  mapFromControlScene,
  mapFromControlContext,
  mapFromDashboard,
} from 'mappings/dataMapping';
import NormControlDTO from 'models/dto/normControlDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import ControlContextDTO from 'models/dto/controlContextDTO';
import ControlContext from 'models/controlContext';
import EntityRelationDTO from 'models/dto/entityRelationDTO';
import { ObjectiveControlDTO } from 'models/dto/Objective/objectiveControlDTO';
import { ProcessControlDTO } from 'models/dto/Process/processControlDTO';
import Dashboard from 'models/dashboard';
import DashboardDTO from 'models/dto/dashboardDTO';
import { KPIControlDTO } from 'models/dto/kpi/kpiControlDTO';
import { ControlThemeInheritInfoDTO } from 'models/dto/controlThemeInheritInfoDTO';

export async function apiGetControls(accessToken: string, globalDataCache: GlobalDataCache): Promise<Control[]> {
  try {
    let ar = await http.get<ControlDTO[]>(`/controls`, http.getRequestConfig(accessToken));

    return mapFromControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlsForStandard(
  isoNormId: Number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control[]> {
  try {
    const res = await http.get<ControlDTO[]>(`/controls/standard/${isoNormId}`, http.getRequestConfig(accessToken));

    return mapFromControls(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlsForCustomStandard(
  normId: Number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control[]> {
  try {
    const res = await http.get<ControlDTO[]>(`/controls/custom/${normId}`, http.getRequestConfig(accessToken));

    return mapFromControls(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlsForRisk(
  riskId: Number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control[]> {
  try {
    const res = await http.get<ControlDTO[]>(`/controls/risk/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromControls(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlsForTask(
  taskId: Number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control[]> {
  try {
    const res = await http.get<ControlDTO[]>(`/controls/task/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromControls(res.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlChildren(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control[]> {
  try {
    let ar = await http.get<ControlDTO[]>(`/controls/${controlId}/children`, http.getRequestConfig(accessToken));

    return mapFromControls(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControl(
  controlId: number,
  includeISOControls: boolean,
  includePackageInstructions: boolean,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    if (isNaN(controlId)) return undefined;
    const ar = await http.get<ControlDTO>(
      `/controls/${controlId}?includeISOControls=${includeISOControls}&includePackageInstructions=${includePackageInstructions}`,
      http.getRequestConfig(accessToken),
    );
    if (!ar.data) return undefined;

    return mapFromControl(ar.data, globalDataCache) as Control;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlOrTheme(
  id: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    if (isNaN(id)) return undefined;
    const ar = await http.get<ControlDTO>(`/controls/themes/${id}`, http.getRequestConfig(accessToken));
    if (!ar.data) return undefined;

    return mapFromControl(ar.data, globalDataCache) as Control;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/controls/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddControl(
  control: Control,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control> {
  try {
    let controlDTO = mapToControl(control);
    let ar = await http.post<ControlDTO>(`/controls`, controlDTO, http.getRequestConfig(accessToken));

    return mapFromControl(ar.data, globalDataCache) as Control;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateControl(
  control: Control,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control> {
  try {
    let controlDTO: ControlDTO = mapToControl(control) as ControlDTO;
    let ar = await http.put<ControlDTO>('/controls', controlDTO, http.getRequestConfig(accessToken));

    return mapFromControl(ar.data, globalDataCache) as Control;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveControl(control: Control, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/controls/delete/${control.controlId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlScene(accessToken: string, globalDataCache: GlobalDataCache): Promise<ControlScene> {
  try {
    let ar = await http.get<ControlSceneDTO>(`/controls/scene`, http.getRequestConfig(accessToken));

    return mapFromControlScene(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlContext(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ControlContext> {
  try {
    let ar = await http.get<ControlContextDTO>(`/controls/context/${controlId}`, http.getRequestConfig(accessToken));

    return mapFromControlContext(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetCustomNorm(
  normId: number,
  controlId: number,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const normControlDTO: NormControlDTO = new NormControlDTO(normId, controlId);
    await http.post(`/controls/norms/?add=${add}`, normControlDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveControlFromControl(
  controlIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = controlIdFrom;
    body.idTo = controlIdTo;
    await http.post<EntityRelationDTO>(`/controls/control?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddControlToControl(
  controlIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new EntityRelationDTO();
    body.idFrom = controlIdFrom;
    body.idTo = controlIdTo;
    await http.post<EntityRelationDTO>(`/controls/control?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Objective
export async function apiAddObjectiveToControl(
  objectiveIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveControlDTO(controlIdTo, objectiveIdFrom);
    await http.post<ObjectiveControlDTO>(`/objectives/control?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveObjectiveFromControl(
  objectiveIdFrom: number,
  controlId: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveControlDTO(controlId, objectiveIdFrom);
    await http.post<ObjectiveControlDTO>(`/objectives/control?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//process

export async function apiAddProcessToControl(
  processIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessControlDTO(processIdFrom, controlIdTo);

    await http.post<ProcessControlDTO>(`/processes/control?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveProcessFromControl(
  processIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessControlDTO(processIdFrom, controlIdTo);
    await http.post<ProcessControlDTO>(`/processes/control?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//KPI
export async function apiAddKPIToControl(
  kpiIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new KPIControlDTO(kpiIdFrom, controlIdTo);
    await http.post<KPIControlDTO>(`/kpis/control?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveKPIFromControl(
  kpiIdFrom: number,
  controlIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new KPIControlDTO(kpiIdFrom, controlIdTo);
    await http.post<KPIControlDTO>(`/kpis/control?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//dashboard
export async function apiCreateControlDashboard(
  controlId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard> {
  try {
    const ar = await http.post<DashboardDTO>(
      `/controls/${controlId}/dashboard`,
      null,
      http.getRequestConfig(accessToken),
    );

    return mapFromDashboard(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetNextControl(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    const ar = await http.get<ControlDTO>(`/controls/${controlId}/next`, http.getRequestConfig(accessToken));

    return mapFromControl(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPrevControl(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    const ar = await http.get<ControlDTO>(`/controls/${controlId}/prev`, http.getRequestConfig(accessToken));

    return mapFromControl(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlInheritOptions(
  controlId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Control[]> {
  try {
    const ar = await http.get<ControlDTO[]>(`/controls/${controlId}/inherit`, http.getRequestConfig(accessToken));

    return mapFromControls(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetControlInheritance(accessToken: string, cache: GlobalDataCache): Promise<Control[]> {
  try {
    const ar = await http.get<ControlDTO[]>(`/controls/inherit`, http.getRequestConfig(accessToken));

    return mapFromControls(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateControlInheritOptions(
  sourceControlId: number,
  orgUnitId: string,
  controlId: number,
  copyImpl: boolean,
  overwriteImpl: boolean,
  copyStat: boolean,
  copyAppl: boolean,
  copyCust: boolean,
  copyLib: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Control | undefined> {
  try {
    const dto = new ControlThemeInheritInfoDTO();
    dto.orgUnitId = orgUnitId;
    dto.id = controlId;
    dto.copyImplementation = copyImpl;
    dto.overwriteImplementation = overwriteImpl;
    dto.copyStatus = copyStat;
    dto.copyApplicability = copyAppl;
    dto.copyCustomStandards = copyCust;
    dto.copyLibraryItems = copyLib;

    const ar = await http.post<ControlDTO>(
      `/controls/${sourceControlId}/inherit`,
      dto,
      http.getRequestConfig(accessToken),
    );

    return mapFromControl(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
