import IdListDTO from 'models/dto/IdListDTO';
import { ProcessContextDTO } from 'models/dto/Process/processContextDTO';
import { ProcessDTO } from 'models/dto/Process/processDTO';
import { Process_TranslationDTO } from 'models/dto/Process/process_TranslationDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Process from 'models/process/process';
import { ProcessContext } from 'models/process/processContext';
import { Process_Translation } from 'models/process/process_Translation';
import {
  mapFromThemes,
  mapFromRisk,
  mapFromObjective,
  mapToObjective,
  mapFromLanguage,
  mapToLanguage,
  mapToTask,
  mapFromControls,
  mapFromDashboard,
  mapToDashboard,
} from './dataMapping';
import { mapFromAsset } from './assetMapping';

export const mapFromProcess_Translation = (trans: Process_TranslationDTO): Process_Translation => {
  const output = new Process_Translation();
  output.description = trans.description ?? '';
  output.name = trans.name ?? '';
  output.languageId = trans.languageId;
  output.lang = mapFromLanguage(trans.lang);
  output.processId = trans.processId;

  return output;
};

export const mapFromProcess_Translations = (trans: Process_TranslationDTO[]): Process_Translation[] => {
  if (!trans) return [];

  return trans.map((item) => mapFromProcess_Translation(item));
};

export const mapToProcess_Translation = (trans: Process_Translation): Process_TranslationDTO => {
  const output = new Process_TranslationDTO();
  output.description = trans.description;
  output.name = trans.name;
  output.languageId = trans.languageId;
  output.lang = mapToLanguage(trans.lang);
  output.processId = trans.processId;

  return output;
};

export const mapFromProcesses = (processes: ProcessDTO[] | undefined, globalCache: GlobalDataCache): Process[] => {
  if (!processes) return [];

  return processes.map((item) => mapFromProcess(item, globalCache));
};

export const mapToProcesses = (processes: Process[] | undefined): ProcessDTO[] => {
  if (!processes) return [];

  return processes.map((item) => mapToProcess(item));
};

export const mapFromProcess = (processDTO: ProcessDTO, globalCache: GlobalDataCache): Process => {
  const output = new Process();
  output.processId = processDTO.processId;
  output.authSchemaId = processDTO.authSchemaId;
  output.code = processDTO.code;
  output.parentProcessId = processDTO.parentProcessId;
  output.commentTrailId = processDTO.commentTrailId;
  output.auditTrailId = processDTO.auditTrailId;
  output.groupId = processDTO.groupId;
  output.ownerId = processDTO.ownerId;
  output.ownerRoleId = processDTO.ownerRoleId;
  output.state = processDTO.state;
  output.trans = mapFromProcess_Translations(processDTO.trans);
  output.tagIds = processDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.objectives = processDTO.objectives
    ? processDTO.objectives.map((item) => mapFromObjective(item, globalCache))
    : [];
  output.dashboard = processDTO.dashboard ? mapFromDashboard(processDTO.dashboard, globalCache) : undefined;

  if (processDTO.trans && processDTO.trans.length > 0) {
    output.transIdx = 0;
    output.name = processDTO.trans[0].name ?? '';
    output.description = processDTO.trans[0].description ?? '';
  }

  return output;
};

export const mapToProcess = (process: Process): ProcessDTO => {
  const output = new ProcessDTO();

  output.processId = process.processId;
  output.authSchemaId = process.authSchemaId;
  output.code = process.code;
  output.parentProcessId = process.parentProcessId;
  output.commentTrailId = process.commentTrailId;
  output.auditTrailId = process.auditTrailId;
  output.groupId = process.groupId;
  output.ownerId = process.ownerId;
  output.ownerRoleId = process.ownerRoleId;
  output.state = process.state;
  output.tasks = process.tasks.map((item) => mapToTask(item));
  output.tagIds = new IdListDTO(process.tagIds.map((item) => `${item}`));
  output.objectives = process.objectives.map((item) => mapToObjective(item));
  output.dashboard = process.dashboard ? mapToDashboard(process.dashboard) : undefined;
  output.trans = process.trans?.map((item) => mapToProcess_Translation(item)) ?? [];

  if (output.trans && process.transIdx >= 0) {
    output.trans[process.transIdx].name = process.name;
    output.trans[process.transIdx].description = process.description;
  }

  return output;
};

export const mapFromProcessContext = (context: ProcessContextDTO, globalCache: GlobalDataCache): ProcessContext => {
  const output = new ProcessContext();
  output.parent = context.parent ? mapFromProcess(context.parent, globalCache) : undefined;
  output.children = mapFromProcesses(context.children, globalCache);
  output.controls = mapFromControls(context.controls, globalCache);
  output.themes = mapFromThemes(context.themes, globalCache);
  output.risks = context.risks.map((item) => mapFromRisk(item, globalCache));
  output.assets = context.assets.map((item) => mapFromAsset(item, globalCache));

  return output;
};
