import { EntityTypes } from "models/entity";

export class CommentTrailDTO {
  commentTrailId: number;

  entries: CommentTrailEntryDTO[];

  constructor() {
    this.commentTrailId = 0;
    this.entries = [];
  }
}

export class CommentTrailEntryDTO {
  commentTrailId: number;

  commentTrailEntryId: number;

  comment: string;

  created?: string;

  createdById?: string;

  createdBy?: string;

  modified?: string;

  modifiedById?: string;

  modifiedBy?: string;

  entityType?: EntityTypes;

  entityId?: number;

  constructor() {
    this.commentTrailId = 0;
    this.commentTrailEntryId = 0;
    this.comment = '';
  }
}
