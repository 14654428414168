export class ObjectiveThemeDTO {
  themeId: number;

  objectiveId: number;

  constructor(_themeId: number = -1, _objectiveId: number = -1) {
    this.themeId = _themeId;
    this.objectiveId = _objectiveId;
  }
}
