export default class NormThemeDTO {
  normId: number;

  themeId: number;

  constructor(normId?: number, themeId?: number) {
    this.normId = normId || 0;
    this.themeId = themeId || 0;
  }
}
