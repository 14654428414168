import { Stack, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { globalStackItemStylesPaddingSceneScroll, globalStackTokensGapSmall } from 'globalStyles';
import Task from 'models/tasks/task';
import Tag from 'models/tag';
import { TaskDetailAssignment } from './Details/TaskDetailAssignment';
import { TaskDetailName } from './Details/TaskDetailName';
import { TaskDetailTags } from './Details/TaskDetailTags';
import { TaskDetailDescription } from './Details/TaskDetailDecription';
import { TaskDetailStatusActive } from './Details/TaskDetailStatusActive';

interface ITemplateTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  canUpdate: boolean;
}

export const TemplateTaskDetail = (props: ITemplateTaskDetail) => {
  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <TaskDetailDescription
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
            <TaskDetailAssignment
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailStatusActive
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              checkOnly={false}
            />
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
