import { Fragment, useContext, useEffect } from 'react';
import { Checkbox, ComboBox, IComboBox, IComboBoxOption, Link, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalComboContainerHeight, globalStackTokensGapMedium, globalTextStylesBold } from 'globalStyles';
import { useHistory } from 'react-router-dom';
import { TenantOnboarding } from 'models/tenant';
import Language from 'models/language';
import { MsConsentButton } from 'components/Buttons/MsButtons';
import { globalAppName, globalHelpLink, supportedUILanguages } from 'globalConstants';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { navigateToExternalUrl } from 'utils/url';

interface ITrialWelcomeOptionsWithoutPackageProps {
  onboardingState: TenantOnboarding;
  updateOnboardingState: (onboardingState: TenantOnboarding) => void;
  updateOrgLang: (langCode: string) => void;
  languages: Language[];
  adminConsentResult: boolean | undefined;
  selectedLang: Language | undefined;
}

const TrialWelcomeOptionsWithoutPackage = (props: ITrialWelcomeOptionsWithoutPackageProps) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (props.adminConsentResult !== undefined) {
      onStep3(props.adminConsentResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminConsentResult]);

  useEffect(() => {
    loadActivatedStandards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActivatedStandards = async () => {
    try {
      if (!props.onboardingState) return;

      await appContext.globalDataCache.norms.getItems();
      const standards = appContext.globalDataCache.norms.getItemsISO();

      if (standards && standards.length > 0) {
        let newState = props.onboardingState;
        if (newState.onboardingSteps.step2 === false) {
          newState.onboardingSteps.step2 = true;
          props.updateOnboardingState(newState);
        }
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const openHelpPanel = () => {
    appContext.setHelpPanel(true);
  };

  const onStep1 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step1 = checked;
    props.updateOnboardingState(newState);
  };

  const onStep2 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step2 = checked;
    props.updateOnboardingState(newState);
  };

  const onStep3 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step3 = checked;
    props.updateOnboardingState(newState);
  };

  const openGeneralSettings = () => {
    const url = '/admin/settings/general';
    history.push(url);
  };

  const openIntegrationSettings = () => {
    const url = '/admin/settings/integration';
    history.push(url);
  };

  const getLanguagesOptions = (): IComboBoxOption[] => {
    return props.languages
      .filter((l) => supportedUILanguages.has(l.code))
      .map((l) => {
        return {
          key: l.code,
          text: l.name,
        };
      });
  };

  const setOrgLang = (key: string | undefined) => {
    if (!key) return;
    props.updateOrgLang(key);
    onStep1(true);
  };

  const navigateToStandards = () => {
    const url = '/admin/standards';
    history.push(url);
  };

  const navigateToStore = () => {
    const url = '/admin/store/content';
    history.push(url);
  };

  const navigateToKB = () => {
    navigateToExternalUrl(globalHelpLink, '', '', true);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium} styles={{ root: { maxWidth: 800 } }}>
      <Stack.Item>
        <Stack horizontal verticalAlign="center">
          <Stack.Item>
            <Checkbox
              disabled={!hasUserFeatureGenericManager(appContext)}
              onChange={(ev, checked) => onStep1(checked ?? false)}
              checked={props.onboardingState.onboardingSteps?.step1}
            ></Checkbox>
          </Stack.Item>
          <Stack.Item>
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.NoPackages.Step1')}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Text variant="medium">
          {t('dashboard:TrialWelcome.NoPackages.Info1', {
            appName: globalAppName,
            currentLang: props.selectedLang?.name,
          })}
          &nbsp;
        </Text>
        <Link onClick={() => openGeneralSettings()}>{t('dashboard:TrialWelcome.NoPackages.Info1a')}</Link>
      </Stack.Item>
      <Stack.Item>
        <ComboBox
          className="redlab-usetiful-welcome-language"
          disabled={!hasUserFeatureGenericManager(appContext)}
          allowFreeInput
          options={getLanguagesOptions()}
          selectedKey={props.selectedLang?.code}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) =>
            setOrgLang(option?.key as string | undefined)
          }
          styles={globalComboContainerHeight}
          useComboBoxAsMenuWidth
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal verticalAlign="center">
          <Stack.Item>
            <Checkbox
              disabled={!hasUserFeatureGenericManager(appContext)}
              onChange={(ev, checked) => onStep2(checked ?? false)}
              checked={props.onboardingState.onboardingSteps?.step2}
            ></Checkbox>
          </Stack.Item>
          <Stack.Item>
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.NoPackages.Step2')}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Text variant="medium">{t('dashboard:TrialWelcome.NoPackages.Info2')}&nbsp;</Text>
        <Link onClick={() => navigateToStandards()}>{t('dashboard:TrialWelcome.NoPackages.Info2a')}&nbsp;</Link>
        <Text variant="medium">{t('dashboard:TrialWelcome.NoPackages.Info2b')}&nbsp;</Text>
        <Link onClick={() => openHelpPanel()}>{t('dashboard:TrialWelcome.ServicedeskLink')}</Link>
      </Stack.Item>
      {!appContext.user.login.isOrgUnit && (
        <Fragment>
          <Stack.Item>
            <Stack horizontal verticalAlign="center">
              <Stack.Item>
                <Checkbox
                  onChange={(ev, checked) => onStep3(checked ?? false)}
                  checked={props.onboardingState.onboardingSteps?.step3}
                ></Checkbox>
              </Stack.Item>
              <Stack.Item>
                <Text variant="large" styles={globalTextStylesBold}>
                  {t('dashboard:TrialWelcome.NoPackages.Step3')}
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Text variant="medium">
              {t('dashboard:TrialWelcome.NoPackages.Info3', { appName: globalAppName })}&nbsp;
            </Text>
            <Link onClick={() => openIntegrationSettings()}>{t('dashboard:TrialWelcome.NoPackages.Info3a')}</Link>
          </Stack.Item>
          <Stack.Item className="redlab-usetiful-welcome-authorization">
            <MsConsentButton onClick={() => appContext.adminConsent('dashboard/welcome')}></MsConsentButton>
          </Stack.Item>
        </Fragment>
      )}
      <Stack.Item>
        <Text variant="large" styles={globalTextStylesBold}>
          {t('dashboard:TrialWelcome.NoPackages.Step4')}
        </Text>
      </Stack.Item>
      <Stack.Item styles={{ root: { paddingBottom: 30 } }}>
        <Text variant="medium">{t('dashboard:TrialWelcome.NoPackages.Info4')}&nbsp;</Text>
        <Link onClick={() => navigateToStore()}>{t('dashboard:TrialWelcome.InfoStoreLink')}&nbsp;</Link>
        <Text variant="medium">{t('dashboard:TrialWelcome.NoPackages.Info4a', { appName: globalAppName })}&nbsp;</Text>
        <Link onClick={() => navigateToKB()}>{t('dashboard:TrialWelcome.KBLink')}</Link>
      </Stack.Item>
    </Stack>
  );
};

export default TrialWelcomeOptionsWithoutPackage;
