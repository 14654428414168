import Activity from 'models/activity';
import { WidgetTypes } from 'models/widget';
import LanguageDTO from './languageDTO';

export default class WidgetDTO {
  widgetId: number;

  dashboardId: number;

  name: string;

  widgetType: WidgetTypes;

  widgetConfig?: string;

  sortOrder: number;

  activities?: Activity[];

  trans: Widget_TranslationDTO[];

  constructor() {
    this.widgetId = 0;
    this.dashboardId = 0;
    this.name = '';
    this.widgetType = WidgetTypes.NotSet;
    this.sortOrder = 0;
    this.trans = [];
  }
}

export class Widget_TranslationDTO {
  widgetId: number;

  languageId: number;

  name: string;

  lang?: LanguageDTO;

  constructor() {
    this.widgetId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
