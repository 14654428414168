export default class EntityRelationDTO {
  idFrom: number;

  idTo: number;

  constructor() {
    this.idFrom = 0;
    this.idTo = 0;
  }
}
