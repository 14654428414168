import { newGuidNil } from 'utils/guid';

export default class KPIDataTransformLog {
  kpiDataTransformLogId: number;

  kpiId: number;

  resultEvidence: string;

  resultComment?: string;

  created: Date;

  createdBy: string;

  createdById: string;

  taskId?: number;

  errorCode?: string;

  errorMsg?: string;

  kpiDataId?: number;

  constructor() {
    this.kpiDataTransformLogId = 0;
    this.kpiId = 0;
    this.created = new Date();
    this.createdBy = '';
    this.createdById = newGuidNil();
    this.resultEvidence = '';
  }
}
