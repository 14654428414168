import { Stack, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalTextStylesBold } from 'globalStyles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface INewVersionNotificationProps {}

export const NewVersionNotificationHeight = 20;
export const NewVersionNotificationWidth = 60;

const NewVersionNotification = (props: INewVersionNotificationProps) => {
  const { t } = useTranslation(['translation', 'library']);
  const appContext = useContext(AppContext);

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      styles={{
        root: {
          borderRadius: '3px',
          width: NewVersionNotificationWidth,
          height: NewVersionNotificationHeight,
          maxWidth: NewVersionNotificationWidth,
          maxHeight: NewVersionNotificationHeight,
          padding: 3,
          background: appContext.useDarkMode ? '#36b357' : '#7ed999',
          opacity: 0.8,
        },
      }}
    >
      <Stack.Item>
        <Text variant="small" styles={globalTextStylesBold}>
          {t('library:Preview.NewVersion')}
        </Text>
      </Stack.Item>
    </Stack>
  );
};

export default NewVersionNotification;
