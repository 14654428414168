import ResourceLinkDTO from '../resourceLinkDTO';

export enum WidgetLibraryReadStatsConfigViewType {
  Best = 0,
  Worst = 1,
}

export class Widget63DTO {
  viewMode?: WidgetLibraryReadStatsConfigViewType;

  listId?: number

  links?: ResourceLinkDTO[];

  maxReadCount?: number;

  linkReadCount?: number[];

  linkUniqueUserCount?: number[];
}
