export class ProcessTagDTO {
  tagId: number;

  processId: number;

  constructor(_tagId: number = -1, _processIdId: number = -1) {
    this.tagId = _tagId;
    this.processId = _processIdId;
  }
}
