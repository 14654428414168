import LanguageDTO from './languageDTO';

export default class Theme_TranslationDTO {
  themeId: number;

  languageId: number;

  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  outOfScopeReason?: string;

  lang?: LanguageDTO;

  constructor() {
    this.themeId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
