export class ObjectiveTagDTO {
  tagId: number;

  objectiveId: number;

  constructor(_tagId: number = -1, _objectiveId: number = -1) {
    this.tagId = _tagId;
    this.objectiveId = _objectiveId;
  }
}
