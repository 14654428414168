import parse from 'html-react-parser';
import { truncate } from './string';

export const stripParagraph = (html: string | undefined): string | undefined => {
  if (html?.startsWith('<p>') && html.endsWith('</p>')) {
    return html.substring(3, html.length - 4);
  } else {
    return html;
  }
};

export const getHtmlJSX = (
  html: string | undefined,
  stripContainingParagraph?: boolean,
): JSX.Element | JSX.Element[] | string | null => {
  if (stripContainingParagraph) {
    html = stripParagraph(html);
  }
  if (!html) return null;

  return parse(html);
};

export const exportHTMLString = (content: string): string => {
  let output = content.replace(/<img[^>]*>/g, '');

  return truncate(output, 32767);
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  let text = doc.documentElement.textContent || '';
  text = text.replaceAll('.', '. ');

  return text;
};
