import { IIconProps, SharedColors } from '@fluentui/react';

export const SkypeCircleCheckIcon: IIconProps = {
  iconName: 'SkypeCircleCheck',
  styles: {
    root: {
      fontSize: 20,
      color: SharedColors.green20,
      selectors: {
        '&:hover': {
          color: SharedColors.green10,
        },
      },
    },
  },
};

export const StatusErrorFullIcon: IIconProps = {
  iconName: 'StatusErrorFull',
  styles: {
    root: {
      fontSize: 20,
      color: SharedColors.red20,
      selectors: {
        '&:hover': {
          color: SharedColors.red10,
        },
      },
    },
  },
};

export const CircleRingIcon: IIconProps = {
  iconName: 'CircleRing',
  styles: {
    root: {
      fontSize: 20,
      color: SharedColors.gray20,
      selectors: {
        '&:hover': {
          color: SharedColors.gray10,
        },
      },
    },
  },
};

export const SkypeCircleClock: IIconProps = {
  iconName: 'SkypeCircleClock',
  styles: {
    root: {
      fontSize: 20,
      color: SharedColors.gray20,
      selectors: {
        '&:hover': {
          color: SharedColors.gray10,
        },
      },
    },
  },
};

export const NavigateForwardIcon: IIconProps = {
  iconName: 'NavigateForward',
  styles: {
    root: {
      fontSize: 22,
      color: SharedColors.yellow10,
    },
  },
};

export const CallOutRowStyle = {
  root: {
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        color: SharedColors.gray20,
      },
    },
  },
};
