import { apiSetUserSetting } from 'services/Api/settingService';
import { IAppContext } from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { toBool } from '../utils/string';
import AppError from 'utils/appError';

export const HomeAccount = 'HomeAccount';
export const DontUseIFrameForSharePointWebPages = 'DontUseIFrameForSharePointWebPages';
export const AnnualPlanAskBeforeOpenInstance = 'AnnualPlanAskBeforeOpenInstance';
export const NotificationsEmailTaskAssign = 'NotificationsEmailTaskAssign';
export const NotificationsEmailTaskOverDeadline = 'NotificationsEmailTaskOverDeadline';
export const NotificationsEmailTaskUpcoming = 'NotificationsEmailTaskUpcoming';
export const TaskTagFilterGroupAnd = 'TaskTagFilterGroupAnd';

export const Names = {
  HomeAccount,
  DontUseIFrameForSharePointWebPages,
  AnnualPlanAskBeforeOpenInstance,
  NotificationsEmailTaskAssign,
  NotificationsEmailTaskOverDeadline,
  NotificationsEmailTaskUpcoming,
  TaskTagFilterGroupAnd,
};

export class UserSettings {
  items: UserSetting[];

  appContext?: IAppContext;

  constructor() {
    this.items = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(name: string): any {
    const userSetting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (userSetting) {
      return userSetting.getValue();
    } else {
      return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(name: string, value: any) {
    const userSetting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (userSetting) {
      userSetting.setValue(userSetting.settingName, value);
    } else {
      return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async save(name: string, value: any) {
    const userSetting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (userSetting) {
      userSetting.setValue(userSetting.settingName, value);

      if (this.appContext) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          await apiSetUserSetting(name, value, accessToken);
        } catch (err) {
          this.appContext.setError(err);
        }
      }
    } else {
      return undefined;
    }
  }

  clone(): UserSettings {
    const newSettings = new UserSettings();
    newSettings.items = this.items.map((s) => s.clone());

    return newSettings;
  }
}

export default class UserSetting {
  settingName: string;

  settingValue: string | undefined;

  constructor() {
    this.settingName = '';
    this.settingValue = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue(): any {
    switch (this.settingName) {
      case HomeAccount:
        return this.settingValue;
      case DontUseIFrameForSharePointWebPages:
        return toBool(this.settingValue);
      case AnnualPlanAskBeforeOpenInstance:
        return toBool(this.settingValue);
      case NotificationsEmailTaskAssign:
        return toBool(this.settingValue);
      case NotificationsEmailTaskOverDeadline:
        return toBool(this.settingValue);
      case NotificationsEmailTaskUpcoming:
        return toBool(this.settingValue);
      case TaskTagFilterGroupAnd:
        return toBool(this.settingValue);
      default:
        throw new AppError('Setting not found: ' + this.settingName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue(setting: string, value: any) {
    if (value === undefined) {
      throw new AppError('Setting value is empty: ' + setting);
    }
    switch (setting) {
      case HomeAccount:
        this.settingValue = value.toString();
        break;
      case DontUseIFrameForSharePointWebPages:
        this.settingValue = value.toString();
        break;
      case AnnualPlanAskBeforeOpenInstance:
        this.settingValue = value.toString();
        break;
      case NotificationsEmailTaskAssign:
        this.settingValue = value.toString();
        break;
      case NotificationsEmailTaskOverDeadline:
        this.settingValue = value.toString();
        break;
      case NotificationsEmailTaskUpcoming:
        this.settingValue = value.toString();
        break;
      case TaskTagFilterGroupAnd:
        this.settingValue = value.toString();
        break;
      default:
        throw new AppError('Setting not found: ' + setting);
    }
    this.settingName = setting;
  }

  clone(): UserSetting {
    const newSetting = new UserSetting();
    newSetting.settingName = this.settingName;
    newSetting.settingValue = this.settingValue;

    return newSetting;
  }
}
