import { useContext, useEffect, useState } from 'react';
import { Checkbox, Separator, Stack } from '@fluentui/react';
import { LocalStorageKeys, setLocalStorageData, getLocalStorageData } from 'utils/localstorage';
import { FilterRiskGroupKeys } from 'components/Risk/FilterRiskGroupKeys';
import { RiskStateId } from 'models/riskState';
import { AssetState } from 'models/asset/asset';
import { FilterAssetGroupKeys } from 'components/Asset/FilterAssetGroupKeys';
import { FilterTaskGroupKeys } from 'components/Tasks/Filter/FilterTaskGroupKeys';
import AppContext, { IAppContext } from 'App/AppContext';

const defQuickFilterCount = 5;

export interface IQuickFilterItemRenderer {
  filter: string;
}

export enum QuickFilterContext {
  allTasks = 1,
  myTasks = 2,
  myRequest = 3,
  scheduleTasks = 4,
  risks = 5,
  assets = 6,
  kpis = 8,
  processes = 9,
  objectives = 10,
  controls = 11,
  themes = 12,
  standards = 13,
  taskContext = 14,
  forms = 15,
  generic = 99,
}

interface ISavedQuickFilter {
  filter: string;
  usedCount: number;
}

const getQuickFilterStorageName = (context: QuickFilterContext): LocalStorageKeys => {
  switch (context) {
    case QuickFilterContext.allTasks:
      return LocalStorageKeys.QuickFilterAllTasks;
    case QuickFilterContext.myRequest:
      return LocalStorageKeys.QuickFilterMyRequests;
    case QuickFilterContext.myTasks:
      return LocalStorageKeys.QuickFilterMyTasks;
    case QuickFilterContext.scheduleTasks:
      return LocalStorageKeys.QuickFilterMonitoringTasks;
    case QuickFilterContext.taskContext:
      return LocalStorageKeys.QuickFilterTaskContext;
    case QuickFilterContext.risks:
      return LocalStorageKeys.QuickFilterRisks;
    case QuickFilterContext.assets:
      return LocalStorageKeys.QuickFilterAssets;
    case QuickFilterContext.kpis:
      return LocalStorageKeys.QuickFilterKPIs;
    case QuickFilterContext.forms:
      return LocalStorageKeys.QuickFilterForms;
    case QuickFilterContext.objectives:
      return LocalStorageKeys.QuickFilterObjectives;
    case QuickFilterContext.processes:
      return LocalStorageKeys.QuickFilterProcesses;
    case QuickFilterContext.controls:
      return LocalStorageKeys.QuickFilterControls;
    case QuickFilterContext.themes:
      return LocalStorageKeys.QuickFilterThemes;
    case QuickFilterContext.standards:
      return LocalStorageKeys.QuickFilterStandards;
    case QuickFilterContext.generic:
      return LocalStorageKeys.QuickFilterGeneric;
  }
};

export const saveQuickFilter = (
  filters: ISavedQuickFilter[],
  context: QuickFilterContext,
  appContext: IAppContext | undefined,
) => {
  const storageKeyName = getQuickFilterStorageName(context);
  const newSavedQuickFilter = encodeQuickFilter(filters);
  setLocalStorageData(appContext, storageKeyName, newSavedQuickFilter);
};

export const loadQuickFilter = (
  context: QuickFilterContext,
  appContext: IAppContext | undefined,
): ISavedQuickFilter[] => {
  const storageKeyName = getQuickFilterStorageName(context);
  const savedQuickFilter = getLocalStorageData(appContext, storageKeyName);
  const quickFilter = decodeSavedQuickFilter(savedQuickFilter || '');

  return quickFilter;
};

const encodeQuickFilter = (filters: ISavedQuickFilter[]): string => {
  let encodedFilters: string = '';
  for (let idx = 0; idx < filters.length; idx++) {
    const filter = filters[idx];
    encodedFilters = encodedFilters + '@' + filter.filter + '#' + filter.usedCount.toString();
  }

  return encodedFilters.slice(1);
};

const decodeSavedQuickFilter = (encodedFilter: string): ISavedQuickFilter[] => {
  if (encodedFilter.length === 0) return [];
  const combinedfilters = encodedFilter.split('@');

  const filters: ISavedQuickFilter[] = [];
  for (let idx = 0; idx < combinedfilters.length; idx++) {
    const combinedfilter = combinedfilters[idx];
    const newFilter: ISavedQuickFilter = {
      filter: combinedfilter.split('#')[0],
      usedCount: Number(combinedfilter.split('#')[1]),
    };
    filters.push(newFilter);
  }

  return filters;
};

const removeQuickFilter = (filters: ISavedQuickFilter[], filter: string): ISavedQuickFilter[] => {
  return filters.filter((f) => f.filter !== filter);
};

const getNewQuickFilter = (filter: string): ISavedQuickFilter => {
  return {
    filter: filter,
    usedCount: 1,
  };
};

export const applyNewQuickFilters = (
  currentQuickFilters: ISavedQuickFilter[],
  newFilters: string[],
  maxFilters: number,
): ISavedQuickFilter[] => {
  //the current quick filters are from the current filters stored in a storageKey
  //the new filters are applied so that the new quick filters contain the 5 last used filters
  //rules:
  //1. when a new filter is not present in the current quick filters: add it to the back when count < max
  //2. when a new filter is not present in the current quick filters: when count = max replace the least used one starting from the end

  let newQuickFilters: ISavedQuickFilter[] = [...currentQuickFilters];

  for (let idx = 0; idx < newFilters.length; idx++) {
    const newFilter = newFilters[idx];
    const existingFilter = currentQuickFilters.find((f) => f.filter === newFilter);
    if (!existingFilter) {
      if (newQuickFilters.length < maxFilters) {
        newQuickFilters.push(getNewQuickFilter(newFilter));
      } else {
        const leastUsedCount = Math.min(...newQuickFilters.map((q) => q.usedCount));
        const leastUsedIdx: number = newQuickFilters.reverse().findIndex((f) => f.usedCount === leastUsedCount);
        newQuickFilters[newQuickFilters.length - leastUsedIdx - 1] = getNewQuickFilter(newFilter);
      }
    } else {
      existingFilter.usedCount += 1;
    }
  }

  if (newQuickFilters.length > maxFilters) {
    newQuickFilters = newQuickFilters.slice(0, maxFilters);
  }

  return newQuickFilters;
};

//
// Quick filter item
//
interface IQuickFilterItemProps {
  filter: string;
  checked: boolean;
  onCheck: (quickFilter: string, checked: boolean) => void;
  context: QuickFilterContext;
  itemRender: (props: IQuickFilterItemRenderer) => JSX.Element | null;
  onRemoveItem: (context: QuickFilterContext, filter: string) => void;
}

const QuickFilterItem = (props: IQuickFilterItemProps) => {
  //Get the JSX to render as filter item contents
  //When this returns null, the filter cannot be rendered due to any reason (e.g. label / user is deleted)
  //In that case, signal to the QuickFilter that this filter must be deleted
  const quickFilterItemRenderResult = props.itemRender({ filter: props.filter });

  if (!quickFilterItemRenderResult) {
    props.onRemoveItem(props.context, props.filter);

    return null;
  }

  return (
    <Stack horizontal verticalAlign="center">
      <Stack.Item styles={{ root: { paddingLeft: 5, paddingRight: 5 } }}>
        <Separator vertical></Separator>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={props.checked}
          onChange={(ev, checked) => {
            props.onCheck(props.filter, checked !== undefined ? checked : false);
          }}
        />
      </Stack.Item>
      <Stack.Item
        styles={{
          root: {
            selectors: {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        }}
        onClick={() => props.onCheck(props.filter, !props.checked)}
      >
        {quickFilterItemRenderResult}
      </Stack.Item>
    </Stack>
  );
};

//
// Quick filter
//
interface IQuickFilterProps {
  context: QuickFilterContext;
  filters: string[];
  onChange: (quickFilter: string, checked: boolean) => void;
  onFilterRender: (props: IQuickFilterItemRenderer) => JSX.Element | null;
  enabled: boolean;
  maxFilters?: number;
}

const QuickFilter = (props: IQuickFilterProps) => {
  const appContext = useContext(AppContext);
  const [quickFilters, setQuickFilters] = useState<ISavedQuickFilter[]>([]);

  const removeItem = (context: QuickFilterContext, filter: string) => {
    const currentQuickFilters = loadQuickFilter(context, appContext);
    const newQuickFilter = removeQuickFilter(currentQuickFilters, filter);
    saveQuickFilter(newQuickFilter, props.context, appContext);
  };

  useEffect(() => {
    const getDefaultFiltersForContext = (): ISavedQuickFilter[] => {
      switch (props.context) {
        case QuickFilterContext.allTasks:
          return [
            getNewQuickFilter(FilterTaskGroupKeys.assignment + 'notassigned'),
            getNewQuickFilter(FilterTaskGroupKeys.status + 'open'),
            getNewQuickFilter(FilterTaskGroupKeys.deadline + 'deadlineTooLate'),
          ];
        case QuickFilterContext.myRequest:
        case QuickFilterContext.myTasks:
          return [
            getNewQuickFilter(FilterTaskGroupKeys.startDate + 'startToday'),
            getNewQuickFilter(FilterTaskGroupKeys.deadline + 'deadlineTooLate'),
          ];
        case QuickFilterContext.scheduleTasks:
          return [getNewQuickFilter(FilterTaskGroupKeys.assignment + 'notassigned')];
        case QuickFilterContext.risks:
          return [getNewQuickFilter(FilterRiskGroupKeys.status + RiskStateId.NotAccepted)];
        case QuickFilterContext.assets:
          return [getNewQuickFilter(FilterAssetGroupKeys.state + AssetState.Active)];
        case QuickFilterContext.taskContext:
          return [getNewQuickFilter(FilterTaskGroupKeys.status + 'open')];
        default:
          return [];
      }
    };

    let filters = loadQuickFilter(props.context, appContext);

    if (filters.length === 0) {
      filters = getDefaultFiltersForContext();
    }

    const newQuickFilter = applyNewQuickFilters(filters, props.filters, props.maxFilters ?? defQuickFilterCount);
    saveQuickFilter(newQuickFilter, props.context, appContext);

    setQuickFilters(newQuickFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.context, props.filters, props.maxFilters]);

  //
  // Main render
  //
  if (!props.enabled || quickFilters.length === 0) return null;

  return (
    <Stack horizontal wrap verticalAlign="center">
      {quickFilters.map((quickFilter, index) => {
        return (
          <Stack.Item key={quickFilter.filter}>
            <QuickFilterItem
              filter={quickFilter.filter}
              onCheck={props.onChange}
              checked={props.filters.includes(quickFilter.filter)}
              context={props.context}
              itemRender={props.onFilterRender}
              onRemoveItem={removeItem}
            />
          </Stack.Item>
        );
      })}
    </Stack>
  );
};
export default QuickFilter;
