import 'react-toastify/dist/ReactToastify.css';
import AppRouting from './AppRouting';
import AppContextProvider from './AppContextProvider';

export interface IAppProps {}

const App = (props: IAppProps) => {
  //
  // Main render
  //
  return (
    <AppContextProvider>
      <AppRouting></AppRouting>
    </AppContextProvider>
  );
};

export default App;
