import {
  Image,
  IImageProps,
  Stack,
  Text,
  ImageFit,
  Link,
  Label,
  ScrollablePane,
  ScrollbarVisibility,
  Separator,
} from '@fluentui/react';
import { SingleTag } from 'components/Tags/SingleTag';
import { getGlobalStackItemStylesPaddingSceneScrollMinHeight, globalStackTokensGapSmall } from 'globalStyles';
import Package, { PackageCategories, PackageLogos } from 'models/package';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { GetPackageCategoryColor, getPackagePriceElement } from './StoreItem';
import { navigateToExternalUrl } from 'utils/url';
import AppContext from 'App/AppContext';
import { useContext, useEffect, useState } from 'react';
import { apiDownloadPackageLogo } from 'services/Api/packageService';
import { apiRequest } from 'services/Auth/authConfig';
import { getHtmlJSX } from 'utils/html';

interface IStoreItemDetailsProps {
  pack: Package;
}

const StoreItemDetails = (props: IStoreItemDetailsProps) => {
  const { t } = useTranslation(['store', 'translation', 'package']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [packageLogo, setPackageLogo] = useState<string | undefined>(undefined);
  const [packageLogoType, setPackageLogoType] = useState<PackageLogos>(PackageLogos.LargeLight);

  useEffect(() => {
    getPackageLogo(props.pack.packageId, appContext.useDarkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.useDarkMode]);

  const getImageProps = (isMobileView: boolean): IImageProps => {
    return {
      src: packageLogo,
      imageFit: ImageFit.contain,
      height: isMobileView ? 50 : 70,
      styles: { root: { padding: 10, boxShadow: '3px 3px 7px 0px #D3CDCE' } },
    };
  };

  const getPackageLogo = async (packageId: number, darkmode: boolean): Promise<void> => {
    try {
      if (isLoading) return;
      const logoType = Package.getLogoType(darkmode, true);
      if (!packageLogo || logoType !== packageLogoType) {
        const blob = await downloadPackageLogo(packageId, logoType);
        const defaultLogo = Package.getDefaultLogo(logoType);
        const url = Package.getLogoImageSrc(blob, defaultLogo);
        setPackageLogoType(logoType);
        setPackageLogo(url);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const downloadPackageLogo = async (packageId: number, logoType: PackageLogos): Promise<Blob | undefined> => {
    try {
      if (isLoading || !props.pack) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const blob = await apiDownloadPackageLogo(packageId, logoType, accessToken);

      return blob;
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack horizontal>
        <Stack.Item grow>
          <Image {...getImageProps(appContext.isMobileView)} />
        </Stack.Item>
      </Stack>
      <Stack.Item>
        {!appContext.isMobileView && (
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            <Stack.Item grow>
              <Stack>
                <Stack.Item styles={{ root: { height: 50 } }}>
                  <Label>{t('store:TabStore.StoreItemDetails.PrimaryLanguage')}</Label>
                  <SingleTag
                    width={150}
                    maxWidth={600}
                    compact
                    tag={{
                      tagId: props.pack.primaryLanguage,
                      tagName: props.pack.primaryLanguage.toUpperCase(),
                      tagColor: appContext.useDarkMode ? '#5e5f82' : '#b6b8fc',
                    }}
                  />
                </Stack.Item>
                <Stack.Item styles={{ root: { height: 50 } }}>
                  <Label>{t('store:TabStore.StoreItemDetails.Category')}</Label>
                  {props.pack.categoryId !== undefined && (
                    <SingleTag
                      width={150}
                      maxWidth={600}
                      compact
                      tag={{
                        tagId: props.pack.categoryId ?? 0,
                        tagName: Package.GetPackageCategoryText(props.pack.categoryId, t as TFunction<string[]>),
                        tagColor: GetPackageCategoryColor(props.pack.categoryId, appContext.useDarkMode),
                      }}
                    />
                  )}
                  {props.pack.categoryId === undefined && (
                    <Text>{t('store:TabStore.StoreItemDetails.CategoryEmpty')}</Text>
                  )}
                </Stack.Item>
                <Stack.Item styles={{ root: { height: 50 } }}>
                  <Label>{t('store:TabStore.StoreItemDetails.DependentStandards')}</Label>
                  {props.pack.norm && (
                    <SingleTag
                      width={150}
                      maxWidth={600}
                      compact
                      tag={{
                        tagId: props.pack.norm.normId,
                        tagName: props.pack.norm.name,
                        tagColor: appContext.useDarkMode ? '#596e7d' : '#b6dffc',
                      }}
                    />
                  )}
                  {props.pack.norm === undefined && (
                    <Text>{t('store:TabStore.StoreItemDetails.DependentStandardsEmpty')}</Text>
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item grow>
              <Stack>
                <Stack.Item styles={{ root: { height: 50 } }}>
                  <Label>{t('store:TabStore.StoreItemDetails.Owner')}</Label>
                  <Text styles={{ root: { wordBreak: 'break-word' } }} variant="medium">
                    {props.pack.ownerName}
                  </Text>
                </Stack.Item>
                {props.pack.categoryId !== PackageCategories.Service && props.pack.webURL && (
                  <Stack.Item styles={{ root: { height: 50 } }}>
                    <Label>{t('store:TabStore.StoreItemDetails.WebLink')}</Label>
                    <Link underline onClick={() => navigateToExternalUrl(props.pack.webURL || '', '', '')}>
                      {props.pack.webURLName}
                    </Link>
                  </Stack.Item>
                )}
                <Stack.Item styles={{ root: { height: 50 } }}>
                  <Label>{t('store:TabStore.StoreItemDetails.Price')}</Label>
                  {getPackagePriceElement(props.pack, appContext, t)}
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        )}
      </Stack.Item>
      <Separator />
      <Stack.Item grow styles={getGlobalStackItemStylesPaddingSceneScrollMinHeight(200)}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <div style={{ borderWidth: '5px' }}>{getHtmlJSX(props.pack.description)}</div>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default StoreItemDetails;
