import { Stack, Text, Link, ICalloutContentStyles, Callout } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { OwnerPicker } from 'components/Pickers/OwnerPicker';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import CopyId from 'components/Utils/CopyId';
import CopyLink from 'components/Utils/CopyLink';
import { globalStackTokensGapSmall } from 'globalStyles';
import { Role } from 'models/auth/role';
import { Owner } from 'models/owner';
import Task from 'models/tasks/task';
import User from 'models/user';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ITaskDetailOwnerProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  onUpdate: (task: Task) => void;
}

export const TaskDetailOwner = (props: ITaskDetailOwnerProps) => {
  const { t } = useTranslation(['translation', 'task']);
  const appContext = useContext(AppContext);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Owner, appContext);

  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: 20,
      maxWidth: 250,
    },
  };

  const updateOwner = (item: User | Role | undefined) => {
    if (item && item instanceof User) {
      const newItem = props.task.clone();
      Owner.applyItem(newItem, item);
      newItem.owner = item.clone();
      props.onUpdate(newItem);
      setShowPicker(false);
    }
  };

  const getOwnerName = (task: Task): string => {
    const user = appContext.globalDataCache.users.get(task.ownerId);

    if (user.id === appContext.user.id) {
      return t('task:Owner.You');
    } else {
      return user.name || t('task:Owner.System');
    }
  };

  //
  // Main render
  //
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Stack.Item>
        {!isReadonly && (
          <Stack horizontal verticalAlign="center">
            <Text variant="small">{t('task:Owner.OwnedBy')}</Text>&nbsp;
            <Link id="task-ownerpicker-callout" onClick={() => setShowPicker(true)}>
              {getOwnerName(props.task)}
            </Link>
          </Stack>
        )}
        {isReadonly && (
          <Stack horizontal verticalAlign="center">
            <Text variant="small">{t('task:Owner.OwnedBy')}</Text>&nbsp;
            <Text id="task-ownerpicker-callout">{getOwnerName(props.task)}</Text>
          </Stack>
        )}
      </Stack.Item>
      {props.task.taskId !== -1 && (
        <Fragment>
          <Stack.Item>
            <CopyId id={props.task.taskId}></CopyId>
          </Stack.Item>
          <Stack.Item>
            <CopyLink url={`/tasks/${props.task.taskId}`}></CopyLink>
          </Stack.Item>
        </Fragment>
      )}
      {showPicker && (
        <Callout
          hidden={!showPicker}
          styles={calloutStyle}
          setInitialFocus
          gapSpace={0}
          target={'#task-ownerpicker-callout'}
          onDismiss={() => {
            setShowPicker(false);
          }}
        >
          <OwnerPicker onSelect={updateOwner} disallowRoles={true} />
        </Callout>
      )}
    </Stack>
  );
};
