import { SpinButton } from '@fluentui/react';
import { globalKPIDecimal18c8 } from 'globalConstants';
import { FunctionComponent } from 'react';

interface INumericFieldProps {
  label?: string | undefined;
  value?: number | undefined;
  minValue?: number | undefined;
  maxValue?: number | undefined;
  decimalCount?: number | undefined;
  allowEmpty?: boolean | undefined;
  onChange: (newValue: number | undefined) => void;
}

export const NumericField: FunctionComponent<INumericFieldProps> = (props: INumericFieldProps) => {
  const validateMinValue = (prevValue: number | undefined, newValue: string | undefined): string | undefined => {
    if (newValue === undefined || newValue.trim() === '') return undefined;
    const def = Number.parseFloat(newValue);
    if (Number.isNaN(def)) return prevValue?.toString();
    if (props.minValue !== undefined) {
      if (def < props.minValue) return prevValue?.toString();
    }

    return def.toFixed(props.decimalCount);
  };

  const validateMaxValue = (prevValue: number | undefined, newValue: string | undefined): string | undefined => {
    if (newValue === undefined || newValue.trim() === '') return undefined;
    const def = Number.parseFloat(newValue);
    if (Number.isNaN(def)) return prevValue?.toString();
    if (props.maxValue !== undefined) {
      if (def > props.maxValue) return prevValue?.toString();
    }

    return def.toFixed(props.decimalCount);
  };

  //
  // Main render
  //
  return (
    <SpinButton
      label={props.label}
      value={props.value !== undefined ? props.value.toFixed(props.decimalCount) : ''}
      min={props.minValue ?? -globalKPIDecimal18c8}
      max={props.maxValue ?? globalKPIDecimal18c8}
      onIncrement={(newValue) => {
        if (!newValue) newValue = '1';
        newValue = (Number.parseFloat(newValue) + 1).toFixed(props.decimalCount);

        return validateMaxValue(props.value, newValue);
      }}
      onDecrement={(newValue) => {
        if (!newValue) newValue = '1';
        newValue = (Number.parseFloat(newValue) - 1).toFixed(props.decimalCount);

        return validateMinValue(props.value, newValue);
      }}
      onValidate={
        props.allowEmpty === true
          ? (newValue) => {
              //normally empty values are discarded as not valid
              //also return empty strings to reset the field
              return newValue;
            }
          : undefined
      }
      onChange={(ev, newValue) => {
        let valStr = validateMinValue(props.minValue, newValue);
        valStr = validateMaxValue(props.maxValue, valStr);
        const val = valStr ? Number.parseFloat(valStr) : undefined;
        props.onChange(val);
      }}
    ></SpinButton>
  );
};
