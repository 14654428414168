import { Element } from 'html-react-parser';
import { IRichTextEditorSpecialLinkType } from './RichTextEditor';

export const createBadgeHTML = (text: string): string => {
  const newPara = createBadgeElement(text);

  return newPara.outerHTML;
};

export const createBadgeJSX = (text: string, clickEvent?: (text: string) => void): JSX.Element => {
  const jsxElem = (
    <span
      onClick={
        !clickEvent
          ? undefined
          : (ev) => {
              ev.stopPropagation();
              clickEvent(text);
            }
      }
      style={{
        display: 'inline-block',
        border: '1px solid #2276d2',
        borderRadius: '5px',
        padding: '2px 5px',
        margin: '0 2px',
        color: '#2276d2',
        cursor: 'pointer',
      }}
    >
      {text}
    </span>
  );

  return jsxElem;
};

export const createBadgeElement = (text: string): HTMLElement => {
  const newPara = window.document.createElement('p');
  const newSpan = newPara.appendChild(window.document.createElement('span'));
  newSpan.setAttribute(
    'style',
    'display: inline-block; border: 1px solid #2276d2; border-radius: 5px; padding: 2px 5px; margin: 0px 2px; color: #2276d2; cursor: pointer;',
  );
  newSpan.textContent = text;

  return newPara;
};

export const isBadgeElement = (node: Element): boolean => {
  if (
    node &&
    node.name &&
    node.name.toLowerCase() === 'span' &&
    node.attributes &&
    node.attributes.length > 0 &&
    node.attributes[0].value &&
    (node.attributes[0].value.includes('color: #2276d2') ||
      node.attributes[0].value.includes('color: rgb(34, 118, 210)'))
  ) {
    return true;
  } else {
    return false;
  }
};

export const getBadgeTextFromElement = (node: Element): string | undefined => {
  if (node.children && node.children.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const textNode = node.children[0] as any;
    if (textNode.data) {
      const badgeText = textNode.data as string;

      return badgeText;
    }
  }

  return undefined;
};

export const isBadgeHTMLElement = (node: HTMLElement): boolean => {
  if (
    node.nodeName.toLowerCase() === 'span' &&
    node.attributes &&
    node.attributes.length > 0 &&
    node.attributes[0].value &&
    (node.attributes[0].value.includes('color: #2276d2') ||
      node.attributes[0].value.includes('color: rgb(34, 118, 210)'))
  ) {
    return true;
  } else {
    return false;
  }
};

//
// Special links
//
export const getSpecialLinkElement = (node: Element): IRichTextEditorSpecialLinkType => {
  if (
    node &&
    node.name &&
    node.name.toLowerCase() === 'a' &&
    node.attributes &&
    node.attributes.length > 0 &&
    node.attributes[0].value &&
    node.attributes[0].value.startsWith('isocontrol://')
  ) {
    return IRichTextEditorSpecialLinkType.ISOControl;
  }

  return IRichTextEditorSpecialLinkType.None;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSpecialLinkHTMLElement = (node: any): IRichTextEditorSpecialLinkType => {
  if (node.nodeName && node.nodeName.toLowerCase() === 'a') {
    if (node.href.startsWith('isocontrol://')) {
      return IRichTextEditorSpecialLinkType.ISOControl;
    }
  }

  return IRichTextEditorSpecialLinkType.None;
};

export const createSpecialLinkJSX = (
  type: IRichTextEditorSpecialLinkType,
  text: string,
  href: string,
  clickEvent?: (type: IRichTextEditorSpecialLinkType, text: string, href: string) => void,
): JSX.Element => {
  const jsxElem = (
    <a
      href={href}
      onClick={
        !clickEvent
          ? undefined
          : (ev) => {
              ev.stopPropagation();
              clickEvent(type, text, href);
            }
      }
    >
      {text}
    </a>
  );

  return jsxElem;
};

export const getSpecialLinkHrefFromElement = (node: Element): string | undefined => {
  if (node && node.attributes && node.attributes.length > 0 && node.attributes[0].value) {
    return node.attributes[0].value;
  }

  return undefined;
};

export const getSpecialLinkTextFromElement = (node: Element): string | undefined => {
  if (node.children && node.children.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const textNode = node.children[0] as any;
    if (textNode.data) {
      const badgeText = textNode.data as string;

      return badgeText;
    }
  }

  return undefined;
};
