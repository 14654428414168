import { useContext, useEffect, useState } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  Link,
  FontIcon,
  getTheme,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { apiRequest } from 'services/Auth/authConfig';
import AppContext from 'App/AppContext';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { apiGetWebhookRunsForTask } from 'services/Api/webhookService';
import { WebhookRun } from 'models/webhook';
import { sortOnDate } from 'utils/sorting';
import GenericModal from 'components/Dialogs/GenericModal';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import Task from 'models/tasks/task';
import { navigateToExternalUrl } from 'utils/url';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';

interface IEventTaskWorkflowDetailsModalProps {
  hidden: boolean;
  task: Task;
  onClose: () => void;
}

const TaskWorkflowDetailsModal = (props: IEventTaskWorkflowDetailsModalProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['kb', 'translation']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [runs, setRuns] = useState<WebhookRun[]>([]);
  const theme = getTheme();

  useEffect(() => {
    if (!props.hidden) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hidden]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const runs = await apiGetWebhookRunsForTask(props.task.taskId, accessToken);
      runs.sort((a, b) => sortOnDate(a.created, b.created));
      setRuns(runs);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'created',
      name: t('task:Workflows.Dialogs.EventWorkflowDetails.Columns.Created'),
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
      onRender: (item?: WebhookRun, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{toLocaleDateTimeMedium(item.created)}</Text>;
      },
    },
    {
      key: 'workflow',
      name: t('task:Workflows.Dialogs.EventWorkflowDetails.Columns.Workflow'),
      isMultiline: true,
      minWidth: 150,
      isResizable: true,
      onRender: (item?: WebhookRun, index?: number, column?: IColumn) => {
        if (!item) return;
        if (item.workflowId && item.workflowRunId) {
          return (
            <Stack
              horizontal
              tokens={globalStackTokensGapExtraSmall}
              verticalAlign="center"
              styles={{ root: { paddingTop: 5 } }}
            >
              <FontIcon iconName="OpenInNewTab" style={{ color: theme.palette.themePrimary }} />
              <Text>
                <Link onClick={() => navigateToWorkflowDetails(item)}>{item?.webhook?.workflowName}</Link>
              </Text>
            </Stack>
          );
        } else {
          return <Text>{item?.webhook?.workflowName}</Text>;
        }
      },
    },
  ];

  const navigateToWorkflowDetails = (item: WebhookRun) => {
    if (item.workflowId && item.workflowRunId) {
      const url = `https://make.powerautomate.com/environments/Default-${appContext.user.tenant.azureTenantId}/flows/${item.workflowId}/runs/${item.workflowRunId}`;
      navigateToExternalUrl(url, '', '', true);
    }
  };

  //
  // Main render
  //
  return (
    <GenericModal
      title={t('task:Workflows.Dialogs.EventWorkflowDetails.Title')}
      subTitle={t('task:Workflows.Dialogs.EventWorkflowDetails.SubTitle')}
      isOpen={!props.hidden}
      onClose={props.onClose}
      height={'50vh'}
      minHeight={300}
      maxHeight={'90vh'}
      width={'40vw'}
      minWidth={250}
      maxWidth={'95vw'}
    >
      <Stack verticalFill>
        <ScrollableStackItem>
          <ShimmeredDetailsList
            shimmerLines={3}
            enableShimmer={isLoading}
            compact
            items={runs}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            selectionPreservedOnEmptyClick={true}
          />
        </ScrollableStackItem>
      </Stack>
    </GenericModal>
  );
};

export default TaskWorkflowDetailsModal;
