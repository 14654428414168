import LanguageDTO from '../languageDTO';

export class Objective_TranslationDTO {
  objectiveId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.objectiveId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
