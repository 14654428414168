import { KPIContextFilterDTO } from './kpiGraphDataDTO';

export default class KPIAlertDTO {
  kpiId: number;

  kpiAlertId: number;

  operator: number;

  treshold: number;

  aggregationMethod: number;

  aggregationPeriod: number;

  templateId: number;

  filter?: KPIContextFilterDTO;

  lastAlert?: string;

  constructor() {
    this.kpiId = 0;
    this.kpiAlertId = 0;
    this.operator = 0;
    this.treshold = 0;
    this.aggregationMethod = 0;
    this.aggregationPeriod = 0;
    this.templateId = 0;
  }
}
