import Control from './control';
import Objective from './objective/objective';
import Process from './process/process';
import Risk from './risk';
import Theme from './theme';

export default class ThemeContext {
  parent: Theme | undefined;

  children: Theme[];

  controls: Control[];

  themes: Theme[];

  risks: Risk[];

  processes: Process[];

  objectives: Objective[];

  constructor() {
    this.children = [];
    this.controls = [];
    this.themes = [];
    this.risks = [];
    this.processes = [];
    this.objectives = [];
  }
}
