import { ReactNode, useContext } from 'react';
import { getTheme, MessageBar, MessageBarType, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';

interface IInfoMessageProps {
  message?: string;
  onDismiss?: () => void;
  children?: ReactNode;
}

export const InfoMessage = (props: IInfoMessageProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  if (props.message === undefined && props.children === undefined) {
    return null;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType.info}
      onDismiss={props.onDismiss}
      isMultiline={true}
      truncated={false}
      styles={{
        root: {
          background: appContext.useDarkMode ? theme.palette.neutralPrimary : theme.palette.themeLighterAlt,
        },
      }}
    >
      {props.message && (
        <Text variant="small" block>
          {props.message}
        </Text>
      )}
      {props.children}
    </MessageBar>
  );
};

export default InfoMessage;
