import {
  Stack,
  IconButton,
  Separator,
  Spinner,
  SpinnerSize,
  Modal,
  Text,
  DefaultButton,
  PrimaryButton,
  TooltipHost,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import WarningMessage from 'components/Notification/WarningMessage';
import {
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  refreshIcon,
  sharepointIcon,
} from 'globalStyles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphSharepointPagesRequest } from 'services/Auth/authConfig';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import { graphGetPagesForSite } from 'services/Graph/graphService';
import AppError from 'utils/appError';
import DirectoryParents from './DirectoryParents';
import MySitePages from './MySitePages';
import MySites, { MySiteAuthTypes } from './MySites';
import { ISitePage, ISite } from '../../services/Graph/SharepointInterfaces';
import { navigateToExternalUrl } from 'utils/url';

interface ISharepointSitePickerProps {
  isOpen: boolean;
  hideContent?: boolean;
  close: () => void;
  onSelect: (item: ISite) => void;
}

const SharepointSitePicker = (props: ISharepointSitePickerProps) => {
  const [selectedSite, setSelectedSite] = useState<ISite | undefined>(undefined);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [selectedSitePage, setSelectedSitePage] = useState<ISitePage | undefined>(undefined);
  const [sitePages, setSitePages] = useState<ISitePage[]>([]);
  const [spError, setSPError] = useState<string | undefined>(undefined);

  const appContext = useContext(AppContext);

  const { t } = useTranslation(['sharepoint', 'translation']);

  const checkToken = (token: string): boolean => {
    if (!token) {
      appContext.showNotification(t('translation:General.Notifications.TryAgainAfterConsent'));

      return false;
    }

    return true;
  };

  const fetchPages = async () => {
    if (isBusy || selectedSite === undefined || !selectedSite.id) return;
    try {
      setIsBusy(true);
      setSPError(undefined);

      const graphInterface = await appContext.getGraphInterface(
        graphSharepointPagesRequest.scopes,
        selectedSite.tenantId,
      );
      if (!checkToken(graphInterface.accessToken)) return;
      const _pages: ISitePage[] = await graphGetPagesForSite(graphInterface.client, selectedSite.id, false);
      _pages.forEach((l) => (l.tenantId = selectedSite.tenantId));
      setSitePages(_pages);
    } catch (err) {
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);
      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const updateSiteSelection = (site?: ISite) => {
    setSelectedSite(site);
    setSelectedSitePage(undefined);
    setSitePages([]);
  };

  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  const onRefresh = () => {
    if (selectedSite) {
      fetchPages();

      return;
    }
  };

  const onOpenSharePoint = () => {
    if (!selectedSite?.webUrl) return;
    navigateToExternalUrl(selectedSite.webUrl + '/SitePages', '', '');
  };

  //
  // Main render
  //
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.close} isBlocking>
      <Stack
        verticalFill
        styles={{
          root: { height: '80vh', width: props.hideContent ? 500 : 1000, minWidth: 320, maxWidth: '90vw', padding: 20 },
        }}
      >
        {/* Title and Close button*/}
        <Stack horizontal styles={{ root: { justifyContent: 'space-between' } }}>
          <Stack.Item>
            <Text variant="xLarge">{t('sharepoint:SharepointPicker.SelectSite')}</Text>
          </Stack.Item>
          <Stack.Item>
            <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} onClick={() => props.close()} />
          </Stack.Item>
        </Stack>
        <Separator />
        <Stack verticalFill>
          {/* Body Left and Right*/}
          <Stack.Item grow>
            <Stack horizontal styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
              {/* Left Groups Part*/}
              <Stack.Item styles={{ root: { width: props.hideContent ? '100%' : '30%', padding: 5 } }}>
                <MySites
                  onSelect={(item) => updateSiteSelection(item)}
                  selectedSite={selectedSite}
                  checkToken={checkToken}
                  authType={MySiteAuthTypes.List}
                />
                <Separator vertical />
              </Stack.Item>
              {/* Right Part*/}
              {!props.hideContent && (
                <Stack.Item grow styles={{ root: { width: '70%', padding: 5 } }}>
                  <Stack verticalFill>
                    {selectedSite && (
                      <Stack.Item>
                        <Stack horizontal wrap horizontalAlign="space-between">
                          <Stack.Item>
                            <DirectoryParents
                              drive={selectedSitePage}
                              parents={[]}
                              site={selectedSite}
                              onSelectDrive={() => {}}
                              onSelectDriveFolder={() => {}}
                              onBack={() => {}}
                              onSelectSite={updateSiteSelection}
                            />
                          </Stack.Item>
                          <Stack.Item>
                            {selectedSite && (
                              <TooltipHost content={t('sharepoint:CommandBar.OpenSharePoint')}>
                                <IconButton iconProps={sharepointIcon} onClick={onOpenSharePoint} />
                              </TooltipHost>
                            )}
                            <TooltipHost content={t('sharepoint:CommandBar.Refresh')}>
                              <IconButton iconProps={refreshIcon} onClick={onRefresh} />
                            </TooltipHost>
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    )}
                    {!selectedSitePage && !selectedSite && isBusy && (
                      <Stack verticalFill verticalAlign="center">
                        <Spinner size={SpinnerSize.large} />
                      </Stack>
                    )}
                    {spError && !isBusy && (
                      <Stack verticalFill>
                        <WarningMessage message={spError} onDismiss={undefined}></WarningMessage>
                      </Stack>
                    )}
                    {!spError && selectedSite && selectedSitePage === undefined && (
                      <Stack.Item grow>
                        <MySitePages pages={sitePages} isListLoading={isBusy} existingLinks={[]} />
                      </Stack.Item>
                    )}
                  </Stack>
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
        <Separator />
        <Stack horizontalAlign="end" horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            text={t('translation:General.Button.Select')}
            disabled={selectedSite === undefined}
            onClick={() => {
              if (selectedSite) {
                props.onSelect(selectedSite);
                props.close();
              }
            }}
          />
          <DefaultButton text={t('translation:General.Button.Cancel')} onClick={() => props.close()} />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default SharepointSitePicker;
