import ModifyDateDialog from 'components/Dialogs/ModifyDateDialog';
import Task from 'models/tasks/task';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toLocaleDateTimeMedium } from 'utils/datetime';

interface ITaskModifyCompletionDateProps {
  task: Task;
  isOpen: boolean;
  onClose: () => void;
  onSave: (completionDate: Date) => void;
}

const TaskModifyCompletionDate = (props: ITaskModifyCompletionDateProps) => {
  const { t } = useTranslation(['task', 'translation']);
  const [newCompletionDate, setNewCompletionDate] = useState<Date | undefined>(props.task.completed);

  return (
    <ModifyDateDialog
      title={t('task:Completed.DialogChangeTitle')}
      subTitle={t('task:Completed.DialogChangeSubTitle')}
      isOpen={props.isOpen}
      value={newCompletionDate}
      allowChangeTime={true}
      onSave={() => {
        if (newCompletionDate) props.onSave(newCompletionDate);
      }}
      maxDate={new Date()}
      onUpdate={setNewCompletionDate}
      onClose={() => props.onClose()}
      onGetErrorMessage={(newDate) => {
        if (!newDate) return undefined;
        //use the orgTask here: new completion date must be before the unmodified start date
        if (newDate <= props.task.startDateTime) {
          return t('task:Completed.DialogChangeError', {
            startDate: toLocaleDateTimeMedium(props.task.startDateTime),
          });
        }
      }}
    />
  );
};

export default TaskModifyCompletionDate;
