import { useContext } from 'react';
import { DonutChart, IChartDataPoint } from '@fluentui/react-charting';
import randomColor from 'randomcolor';
import { navigateToExternalUrl } from 'utils/url';
import { FontIcon, Link, Stack, Text } from '@fluentui/react';
import { globalStackStylesHeight100, globalStackTokensGapSmall } from 'globalStyles';
import AppContext from 'App/AppContext';
import { useHistory } from 'react-router-dom';

export interface IPieChartDataPoint {
  name: string;
  value: number;
  color?: string;
  link?: string;
  linkExternal?: boolean;
}

export interface IPieChartStdProps {
  width?: number;
  height?: number;
  data: IPieChartDataPoint[];
  label?: string;
  hideTooltip?: boolean;
  hideLegend?: boolean;
  customLegend?: boolean;
}

export const FluentUIPieChart = (props: IPieChartStdProps) => {
  const history = useHistory();

  const getChartData = (data: IPieChartDataPoint[]): IChartDataPoint[] => {
    return data.map((item) => {
      return {
        color: item.color ?? randomColor(),
        data: item.value,
        legend: item.name,
        onClick: () => {
          if (item.link) {
            if (item.linkExternal) {
              navigateToExternalUrl(item.link, '', '');
            } else {
              history.push(item.link);
            }
          }
        },
      };
    }).filter(d => d.data > 0);;
  };

  return (
    <Stack horizontal styles={globalStackStylesHeight100} tokens={{ childrenGap: 0 }} verticalAlign="center">
      <Stack.Item grow>
        <DonutChart
          data={{ chartTitle: props.label, chartData: getChartData(props.data) }}
          innerRadius={props.height ? props.height / 4 : 40}
          hideLegend={props.hideLegend || props.customLegend}
          valueInsideDonut={props.label}
          width={props.width && props.hideLegend ? props.width / 2 : props.width}
          height={props.height}
          hideTooltip={props.hideTooltip}
          enabledLegendsWrapLines={true}
        />
      </Stack.Item>
      {!props.hideLegend && props.customLegend && (
        <Stack.Item>
          <Stack tokens={globalStackTokensGapSmall}>
            {props.data.map((item) => (
              <SingleLegend {...item} key={item.name} />
            ))}
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

const SingleLegend = (props: IPieChartDataPoint) => {
  const appContext = useContext(AppContext);

  const text = `${props.name} : ${props.value}`;
  let itemToDisplay = <Text styles={{ root: { color: props.color } }}>{text}</Text>;

  if (props.link)
    itemToDisplay = (
      <Link
        underline
        onClick={() => {
          if (props.link)
            navigateToExternalUrl(
              props.link,
              props.linkExternal ? '' : appContext.user.tenant.azureTenantId,
              props.linkExternal ? '' : appContext.user.login.tenantId,
            );
        }}
      >
        <Text styles={{ root: { color: props.color } }}>{text}</Text>
      </Link>
    );

  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <FontIcon iconName="CircleShapeSolid" style={{ color: props.color }} />
      {itemToDisplay}
    </Stack>
  );
};
