import {
  Stack,
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  TooltipHost,
  DirectionalHint,
  IGroup,
  IDetailsGroupRenderProps,
  Text,
  ConstrainMode,
  IDetailsList,
  ScrollToMode,
} from '@fluentui/react';
import { IObjectWithKey, Selection, SelectionMode } from '@fluentui/utilities';
import AppContext from 'App/AppContext';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { globalStackItemStylesScroll } from 'globalStyles';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';

export interface ItemTypeBase {
  id: string;
}

export type ScrollCallback<ItemType> = (index: number, select?: boolean, newItems?: ItemType[]) => void;

interface IBaseTableListListProps<ItemType = ItemTypeBase> {
  items: ItemType[];
  columns: IColumn[];
  groups?: IGroup[];
  groupProps?: IDetailsGroupRenderProps;
  selectionMode?: SelectionMode;
  selectionResetKey?: string;
  layoutMode?: DetailsListLayoutMode;
  cacheKeySort?: LocalStorageKeys;
  isLoading?: boolean;
  compact?: boolean;
  disableSort?: boolean;
  disableCannotSelectTooltip?: boolean;
  disableEmptyState?: boolean;
  setColumns: (newColumns: IColumn[]) => void;
  updateSelection?: (items: ItemType[]) => void;
  compare?: (a: ItemType, b: ItemType, columnKey: string) => number;
  setScrollCallback?: (callback: ScrollCallback<ItemType>) => void;
  onRowDbClick?: (item: ItemType) => void;
  onRenderCol: (item: ItemType, column: IColumn) => JSX.Element | null;
  onRowClick?: (item: ItemType) => void;
  onCanSelect?: (item: ItemType) => boolean;
}

const BaseTableList = <ItemType extends unknown>(props: IBaseTableListListProps<ItemType>) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const root = useRef<IDetailsList>(null);
  const [showItems, setShowItems] = useState<ItemType[]>([]);
  const [sortLoaded, setSortLoaded] = useState<boolean>(false);
  const [selectionResetKey, setSelectionResetKey] = useState<string>('set');

  //
  // Handlers react to changed input of items
  //
  useEffect(() => {
    let newItems: ItemType[] = [];

    if (props.items?.length > 0 && !props.disableSort) {
      newItems = applySortFromLocalStorage(props.items);
    } else {
      newItems = props.items;
    }

    setShowItems(newItems);
    selection.setItems(newItems as ItemTypeBase[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  useEffect(() => {
    if (props.setScrollCallback) {
      props.setScrollCallback(scroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.selectionResetKey) {
      setSelectionResetKey(props.selectionResetKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectionResetKey]);

  //
  // Selection
  //
  const selection = useMemo(
    () =>
      new Selection<ItemTypeBase>({
        selectionMode: props.selectionMode ?? SelectionMode.none,
        getKey: (item: ItemTypeBase) => {
          return item.id;
        },
        canSelectItem: (item) => {
          if (props.selectionMode !== SelectionMode.none && props.onCanSelect) {
            return props.onCanSelect(item as ItemType);
          } else {
            return true;
          }
        },
        onSelectionChanged: () => {
          if (props.selectionMode !== SelectionMode.none && props.updateSelection) {
            props.updateSelection(selection.getSelection() as ItemType[]);
          }
        },
      }),
    [props],
  );

  //
  // Sorting
  //
  const compare = (a: ItemType, b: ItemType, key: string, isSortDescending?: boolean): number => {
    if (props.compare) {
      const output = props.compare(a, b, key);

      return isSortDescending ? -output : output;
    } else {
      return 0;
    }
  };

  const applySortFromLocalStorage = (items: ItemType[]): ItemType[] => {
    if (sortLoaded) {
      return applyCurrentSort(items, props.columns);
    } else {
      //first run
      setSortLoaded(true);
      const newColumns = props.columns;
      //load sort or apply current sort
      if (props.cacheKeySort) {
        const storedSort = getLocalStorageData(appContext, props.cacheKeySort);
        if (storedSort) {
          try {
            const values = storedSort.split(',');
            const colKey = values[0];
            const descending = values[1] === '1';
            const currColumn: IColumn | undefined = newColumns.find((currCol) => colKey === currCol.key);

            if (currColumn) {
              return applySort(items, newColumns, currColumn, descending);
            }
          } catch {
            //ignore
          }
        }
      }

      return applyCurrentSort(items, newColumns);
    }
  };

  const applySort = (
    items: ItemType[],
    columns: IColumn[],
    column?: IColumn,
    isForceDescendingEnabled?: boolean,
  ): ItemType[] => {
    if (!column) return items;

    const newColumns = [...columns];
    const currColumn: IColumn = newColumns.filter((c) => column.key === c.key)[0];
    if (isForceDescendingEnabled !== undefined) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = isForceDescendingEnabled;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
    } else {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
    }

    const newSortedItems = items.sort((a, b) => compare(a, b, currColumn.key, currColumn.isSortedDescending));
    saveSortToLocalStorage(column.key, currColumn.isSortedDescending || false);
    props.setColumns(newColumns);

    return newSortedItems;
  };

  const applyCurrentSort = (items: ItemType[], newColumns: IColumn[]): ItemType[] => {
    const curCol = newColumns.find((c) => c.isSorted);
    const descending = curCol?.isSortedDescending ?? false;

    return applySort(items, newColumns, curCol, descending);
  };

  const saveSortToLocalStorage = (key: string, descending: boolean) => {
    if (props.cacheKeySort) {
      const values = [key, descending ? '1' : '0'];
      setLocalStorageData(appContext, props.cacheKeySort, values.join(','));
    }
  };

  const onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
    if (props.disableSort) return;
    const newItems = applySort(showItems, props.columns, column);
    setShowItems(newItems);
    selection.setItems(newItems as ItemTypeBase[]);
  };

  //
  // Render helpers
  //
  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (rowProps && defaultRender) {
      if (!props.disableCannotSelectTooltip && !selection.canSelectItem(rowProps.item)) {
        return (
          <TooltipHost
            content={t('translation:General.List.CannotSelectItem')}
            directionalHint={DirectionalHint.topLeftEdge}
          >
            {defaultRender(rowProps)}
          </TooltipHost>
        );
      } else {
        return (
          <Stack
            tokens={{ childrenGap: 0 }}
            onDoubleClick={() => {
              if (props.onRowDbClick) props.onRowDbClick(rowProps.item);
            }}
          >
            {defaultRender(rowProps)}
          </Stack>
        );
      }
    }

    return null;
  };

  const onRenderItemColumn = (item?: ItemType, index?: number | undefined, column?: IColumn | undefined) => {
    if (item && column) {
      return props.onRenderCol(item, column);
    }

    return null;
  };

  const scroll = (index: number, select?: boolean, items?: ItemType[]): void => {
    if (items) {
      selection.setItems(items as ItemTypeBase[], false);
    }
    if (select) {
      selection.setIndexSelected(index, true, false);
    }
    root.current?.scrollToIndex(index, undefined, ScrollToMode.center);
  };

  //
  // Main render
  //
  if (!props.disableEmptyState && !props.isLoading && showItems.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center" styles={{ root: { minHeight: 200 } }}>
        <Text>{t('translation:General.Notifications.NoItemsFilter')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill styles={{ root: { minHeight: 200 } }}>
      <Stack.Item grow styles={globalStackItemStylesScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            componentRef={root}
            enableShimmer={props.isLoading ?? false}
            columns={props.columns}
            groups={props.groups}
            groupProps={props.groupProps}
            selection={selection as Selection<IObjectWithKey>}
            items={showItems}
            //setKey={props.selectionResetKey ?? "set"}
            setKey={selectionResetKey}
            compact={props.compact}
            selectionPreservedOnEmptyClick={true}
            selectionMode={props.selectionMode ?? SelectionMode.none}
            layoutMode={props.layoutMode ?? DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
            onRenderRow={onRenderRow}
            onRenderItemColumn={onRenderItemColumn}
            onColumnHeaderClick={onColumnClick}
            onActiveItemChanged={(item) => {
              if (props.onRowClick) props.onRowClick(item);
            }}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default BaseTableList;
