import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Callout,
  CommandBarButton,
  DirectionalHint,
  FontIcon,
  Separator,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from '@fluentui/react';
import { IIconProps } from '@fluentui/react';
import { DefaultButton, IconButton, Label, Link, Text } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react';
import { navigateToExternalUrl } from 'utils/url';
import { useHistory } from 'react-router-dom';
import { UserAccountAvatarSmall, UserAccountAvatarBig } from './UserAccountAvatar';
import { navBarItemStyles } from './NavBarStyles';
import {
  globalStackStylesHeight100PaddingMedium,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalTextStylesNoWrap,
  guestIcon,
  orgIcon,
} from 'globalStyles';
import AppContext from 'App/AppContext';
import { darkTheme, lightTheme } from 'globalThemes';
import { globalNavBarHeight } from 'globalConstants';
import TenantList from './TenantList';
import Tenant from 'models/tenant';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';

//
// Callout that renders when the AuthNavItem is clicked
//
interface IUserAccountDialogProps {
  isOpen: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  toggleDialog: () => void;
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
}

const UserAccountDialog = (props: IUserAccountDialogProps) => {
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const appContext = useContext(AppContext);

  const styles = mergeStyleSets({
    callout: {
      maxWidth: 500,
    },
    button: {
      border: 0,
    },
  });

  const gotoSubscription = () => {
    history.push('/admin/subscription');
    props.toggleDialog();
  };

  const onRefreshOrgs = async () => {
    try {
      appContext.showContentLoader();
      await appContext.refreshTenantList();
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onSwitchOrg = async (tenant: Tenant) => {
    try {
      appContext.showContentLoader();
      props.toggleDialog();
      await props.switchOrg(tenant);
      history.replace('/');
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onSwitchOrgUnit = async (tenant: Tenant) => {
    try {
      appContext.showContentLoader();
      props.toggleDialog();
      await props.switchOrgUnit(tenant);
      history.replace('/');
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onLogin = async () => {
    await props.login();
    history.push('/');
  };

  return (
    <Callout
      className={styles.callout}
      alignTargetEdge={false}
      gapSpace={0}
      target=".calloutTargetAuthButton"
      isBeakVisible={false}
      directionalHint={DirectionalHint.bottomRightEdge}
      hidden={!props.isOpen}
      onDismiss={props.toggleDialog}
      calloutMinWidth={250}
    >
      <Stack verticalFill styles={globalStackStylesHeight100PaddingMedium} tokens={globalStackTokensGapMedium}>
        <Stack horizontal horizontalAlign="space-between" tokens={globalStackTokensGapMedium}>
          <TooltipHost content={appContext.user.tenant.name}>
            <Label>
              {appContext.user.tenant.name}
              {appContext.user.login.isGuest ? t('translation:NavBar.UserDialog.Guest') : ''}
            </Label>
          </TooltipHost>

          <DefaultButton className={styles.button} onClick={props.logout}>
            {t('translation:NavBar.UserDialog.Button-SignOut')}
          </DefaultButton>
        </Stack>

        <UserAccountAvatarBig />

        <Stack>
          <Link underline onClick={() => navigateToExternalUrl(appContext.user.getMyAccountURL(), '', '')}>
            {t('translation:NavBar.UserDialog.ViewAccount')}
          </Link>
          <Link underline onClick={onLogin}>
            {t('translation:NavBar.UserDialog.SwitchAccount')}
          </Link>
          {hasUserFeature(appContext, FeatureTypes.AdminSubscription) && (
            <Link underline onClick={() => gotoSubscription()}>
              {t('translation:NavBar.UserDialog.ViewSubscription')}
            </Link>
          )}
        </Stack>
        
        {/* Show the org(unit) list when 1) user is licensed and 2) the tenant has org units or when the user has guest accounts */}
        {appContext.user.login.userLicensed === true &&
          appContext.user.login.tenants &&
          appContext.user.login.tenants.length > 1 && (
            <Stack.Item grow>
              <Separator styles={{ root: { height: 1 } }}></Separator>
              <TenantList
                tenants={appContext.user.login.tenants}
                switchOrg={onSwitchOrg}
                switchOrgUnit={onSwitchOrgUnit}
                onRefresh={onRefreshOrgs}
              ></TenantList>
            </Stack.Item>
          )}
      </Stack>
    </Callout>
  );
};

//
// Component on the NavBar
//
interface AuthNavItemProps {
  toggleDialog: () => void;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  isOpen: boolean;
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
}

const AuthNavItem = (props: AuthNavItemProps) => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  useEffect(() => {
    document.title = appContext.user.tenant.name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.user]);

  const onLogin = () => {
    props.login();
    history.push('/');
  };

  const signInIcon: IIconProps = { iconName: 'Signin' };

  // If authenticated, return a call-out with the user profile
  if (appContext.isAuthenticated) {
    return (
      <Fragment>
        <CommandBarButton
          styles={{
            root: {
              height: globalNavBarHeight,
              background: appContext.useDarkMode ? darkTheme.palette?.neutralLight : lightTheme.palette?.themeLight,
            },
          }}
          className="calloutTargetAuthButton"
          onClick={props.toggleDialog}
        >
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            {!appContext.isMobileView && (
              <Stack horizontalAlign="start" verticalAlign="start">
                <Stack horizontal tokens={globalStackTokensGapSmall}>
                  <Text styles={globalTextStylesNoWrap} variant="small">
                    {appContext.user.name}
                  </Text>
                  {appContext.user.login.isGuest && <FontIcon {...guestIcon} style={{ color: 'green' }} />}
                  {appContext.user.login.isOrgUnit && <FontIcon {...orgIcon} style={{ color: 'green' }} />}
                </Stack>
                <Text styles={globalTextStylesNoWrap} variant="small">
                  {appContext.user.tenant.name}
                </Text>
              </Stack>
            )}
            <UserAccountAvatarSmall />
          </Stack>
        </CommandBarButton>
        <UserAccountDialog
          login={props.login}
          logout={props.logout}
          isOpen={props.isOpen}
          toggleDialog={props.toggleDialog}
          switchOrg={props.switchOrg}
          switchOrgUnit={props.switchOrgUnit}
        />
      </Fragment>
    );
  }

  // Not authenticated, return a sign in link or a spinner when authentication is in progress
  return (
    <Fragment>
      {!appContext.isAuthInProgress && (
        <IconButton styles={navBarItemStyles} onClick={onLogin} iconProps={signInIcon}></IconButton>
      )}
      {appContext.isAuthInProgress && (
        <IconButton styles={navBarItemStyles}>
          <Spinner size={SpinnerSize.medium} />
        </IconButton>
      )}
    </Fragment>
  );
};

export default AuthNavItem;
