export class AuthSchemaLineDTO {
  authSchemaId: number;

  authSchemaLineId: number;

  userId?: string;

  groupId?: string;

  roleId?: string;

  opUpdate: boolean;

  opDelete: boolean;

  constructor() {
    this.authSchemaId = 0;
    this.authSchemaLineId = 0;
    this.opUpdate = false;
    this.opDelete = false;
  }
}
