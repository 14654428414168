import { IAppContext } from 'App/AppContext';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import Task, { TaskTypes } from 'models/tasks/task';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';

// Task Auth Rules
// ---------------
// 1. Assignees are allowed to update all fields except:
//    a. Owner
//    b. Start date and time
//    c. Recurrence
//    .even without update permission in the sharing permissions.
// 2. Owners are allowed to read their tasks, even without an entry in the sharing permissions.
// 3. When a user has read-only permissions, due to:
//    a. Sharing permissions with update permission set to false
//    b. User is the assignee
//    -> Then, the user can assign the task to him/herself
// In addition, each field level function can hold additional business rules why a field can/cannot be updated
//

export enum TaskFieldTypes {
  Name = 1,
  Description = 2,
  Owner = 3,
  Tags = 4,
  Assignee = 5,
  Status = 6,
  Start = 7,
  Deadline = 8,
  Recurrence = 9,
  ChecklistEdit = 10,
  Context = 11,
  Permissions = 12,
  OutlookCreate = 13,
  OutlookRemove = 14,
  Library = 15,
  ChecklistFill = 16,
}

export const canUpdateTasks = (tasks: Task[], appContext: IAppContext): boolean => {
  //returns true when the user can update all tasks
  return tasks.some(
    (task) =>
      task.userId === appContext.user.id ||
      hasUserDataPermission(appContext, [task.authSchemaId], AuthSchemaLineOperation.Update),
  );
};

export const canDeleteTasks = (tasks: Task[], appContext: IAppContext): boolean => {
  //returns true when the user can delete all tasks
  return tasks.some((task) => hasUserDataPermission(appContext, [task.authSchemaId], AuthSchemaLineOperation.Delete));
};

export const hasTaskUpdatePermission = (task: Task, appContext: IAppContext): boolean => {
  //returns true when the user has update permission according to the schema
  return hasUserDataPermission(appContext, [task.authSchemaId], AuthSchemaLineOperation.Update);
};

export const hasTaskDeletePermission = (task: Task, appContext: IAppContext): boolean => {
  //returns true when the user has delete permission according to the schema
  return hasUserDataPermission(appContext, [task.authSchemaId], AuthSchemaLineOperation.Delete);
};

export const canUpdateTaskField = (
  task: Task,
  canUpdateTask: boolean,
  taskFieldTypes: TaskFieldTypes,
  appContext: IAppContext,
): boolean => {
  // no restrictions on new tasks
  if (task.taskId <= 0) return true;

  const isAssignee = task.userId === appContext.user.id;

  switch (taskFieldTypes) {
    case TaskFieldTypes.Name:
      if (task.taskType === TaskTypes.Normal && task.isInstance()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Description:
      if (task.taskType === TaskTypes.Normal && task.isInstance()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Owner:
      return canUpdateTask;
    case TaskFieldTypes.Tags:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Permissions:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Assignee:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Status:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Start:
      return canUpdateTask;
    case TaskFieldTypes.Deadline:
      if (task.taskType === TaskTypes.Normal && task.isInstance()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Recurrence:
      if (task.isInstance()) return false;

      return canUpdateTask;
    case TaskFieldTypes.ChecklistFill:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.ChecklistEdit:
      if (task.taskType === TaskTypes.Normal && task.isInstance()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Context:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.Library:
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.OutlookCreate:
      if (!task.isAssigned()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    case TaskFieldTypes.OutlookRemove:
      if (!task.isScheduled()) return false;
      if (isAssignee) return true;

      return canUpdateTask;
    default:
      return true;
  }
};
