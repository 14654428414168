import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import http from './httpService';
import AppError from 'utils/appError';
import { ObjectiveDTO } from 'models/dto/Objective/objectiveDTO';
import Objective from 'models/objective/objective';
import { mapFromObjective, mapFromObjectiveContext, mapToObjective } from 'mappings/objectiveMapping';
import ObjectiveContextDTO from 'models/dto/Objective/objectiveContextDTO';
import ObjectiveContext from 'models/objective/objectiveContext';
import { mapFromDashboard } from 'mappings/dashboardMapping';
import Dashboard from 'models/dashboard';
import DashboardDTO from 'models/dto/dashboardDTO';
import { ObjectiveKPIDTO } from 'models/dto/Objective/objectiveKPIDTO';

export async function apiGetObjectives(accessToken: string, globalDataCache: GlobalDataCache): Promise<Objective[]> {
  try {
    const ar = await http.get<ObjectiveDTO[]>(`/objectives`, http.getRequestConfig(accessToken));

    return ar.data.map((item) => mapFromObjective(item, globalDataCache));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetObjective(
  objectiveId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Objective | undefined> {
  try {
    const ar = await http.get<ObjectiveDTO>(`/objectives/${objectiveId}`, http.getRequestConfig(accessToken));
    if (!ar.data) return undefined;

    return mapFromObjective(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetObjectivesForRisk(riskId: number, accessToken: string, globalDataCache: GlobalDataCache) {
  try {
    const ar = await http.get<ObjectiveDTO[]>(`/objectives/risk/${riskId}`, http.getRequestConfig(accessToken));

    return ar.data.map((item) => mapFromObjective(item, globalDataCache));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddObjective(
  objective: Objective,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Objective> {
  try {
    const objectiveDTO = mapToObjective(objective);
    const ar = await http.post<ObjectiveDTO>(`/objectives`, objectiveDTO, http.getRequestConfig(accessToken));

    return mapFromObjective(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveObjective(objectiveId: number, accessToken: string): Promise<boolean> {
  try {
    await http.post<ObjectiveDTO>(`/objectives/delete/${objectiveId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateObjective(
  objective: Objective,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<Objective> {
  try {
    const objectiveDTO = mapToObjective(objective);
    const ar = await http.put<ObjectiveDTO>(`/objectives`, objectiveDTO, http.getRequestConfig(accessToken));

    return mapFromObjective(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetObjectiveCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/objectives/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetObjectiveContext(
  controlId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<ObjectiveContext> {
  try {
    const ar = await http.get<ObjectiveContextDTO>(
      `/objectives/context/${controlId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromObjectiveContext(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateObjectiveDashboard(
  objectiveId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard> {
  try {
    const ar = await http.post<DashboardDTO>(
      `/objectives/${objectiveId}/dashboard`,
      null,
      http.getRequestConfig(accessToken),
    );

    return mapFromDashboard(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddKPIToObjective(
  objectiveIdFrom: number,
  kpiIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveKPIDTO(objectiveIdFrom, kpiIdTo);

    await http.post<ObjectiveKPIDTO>(`/objectives/kpi?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveKPIFromObjective(
  objectiveIdFrom: number,
  kpiIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ObjectiveKPIDTO(objectiveIdFrom, kpiIdTo);

    await http.post<ObjectiveKPIDTO>(`/objectives/kpi?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
