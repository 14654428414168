export default class TimeZone {
  timeZoneId: number;

  timeZoneCode: string;

  timeZoneName: string;

  constructor() {
    this.timeZoneId = 0;
    this.timeZoneCode = '';
    this.timeZoneName = '';
  }
}
