import { Stack, Text, Link, IconButton } from '@fluentui/react';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { BaseItem, DriveItem } from 'microsoft-graph';
import { IDrive, ISite } from '../../services/Graph/SharepointInterfaces';

interface IDirectoryParents {
  parents: DriveItem[];
  site?: ISite;
  drive?: BaseItem;
  onSelectDriveFolder: (parent: BaseItem, isChild: boolean) => void;
  onSelectDrive: (item: IDrive) => void;
  onBack: () => void;
  onSelectSite: (site?: ISite) => void;
  parentExists?: boolean;
}
const DirectoryParents = (props: IDirectoryParents) => {
  const parentsNoRoot = props.parents.filter((p) => !p.root);

  return (
    <Stack horizontal tokens={globalStackTokensGapExtraSmall} verticalAlign="center">
      <IconButton
        iconProps={{ iconName: 'NavigateBack' }}
        onClick={props.onBack}
        disabled={(parentsNoRoot.length === 0 && props.drive === undefined) || props.parentExists}
      />

      {props.site && (
        <Text block nowrap onClick={() => props.onSelectSite(props.site)}>
          <Link
            underline={parentsNoRoot.length !== 0 || props.drive !== undefined}
            disabled={parentsNoRoot.length === 0 && !props.drive}
          >
            {props.site.displayName}
          </Link>
          {(parentsNoRoot.length !== 0 || props.drive) && '  >'}
        </Text>
      )}

      {props.drive && (
        <Text
          block
          nowrap
          onClick={() => {
            if (props.drive && parentsNoRoot.length !== 0) props.onSelectDrive(props.drive);
          }}
        >
          <Link underline={parentsNoRoot.length !== 0} disabled={parentsNoRoot.length === 0}>
            {props.drive.name}
          </Link>
          {parentsNoRoot.length !== 0 && '  >'}
        </Text>
      )}

      {parentsNoRoot.map((parent: BaseItem, index: number) => {
        if (index === parentsNoRoot.length - 1) {
          return (
            <Text block nowrap key={index}>
              <Link onClick={() => props.onSelectDriveFolder(parent, false)} disabled>
                <Text>{parent.name}</Text>
              </Link>
            </Text>
          );
        }

        return (
          <Text block nowrap key={index}>
            <Link underline onClick={() => props.onSelectDriveFolder(parent, false)}>
              {parent.name}
            </Link>
            {'  >'}
          </Text>
        );
      })}
    </Stack>
  );
};

export default DirectoryParents;
