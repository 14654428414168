import IdListDTO from '../IdListDTO';
import { ProcessDTO } from '../Process/processDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { Objective_TranslationDTO } from './objective_TranslationDTO';
import DashboardDTO from '../dashboardDTO';
import KPIDTO from '../kpi/kpiDTO';

export class ObjectiveDTO {
  objectiveId: number;

  authSchemaId?: number;
  
  code: string;

  parentObjectiveId?: number;

  commentTrailId: number;

  auditTrailId: number;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  state: number;

  dashboard?: DashboardDTO;

  processes: ProcessDTO[];

  kpis: KPIDTO[];

  tasks?: TaskDTO[];

  tagIds: IdListDTO;

  trans?: Objective_TranslationDTO[];

  constructor() {
    this.objectiveId = -1;
    this.code = '';
    this.parentObjectiveId = -1;
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.state = -1;
    this.tasks = [];
    this.tagIds = new IdListDTO([]);
    this.processes = [];
    this.kpis = [];
  }
}
