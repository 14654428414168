import { IHSL } from '@fluentui/react';
import { colorChannelMixer, IRGBToHEX, HEXToIRGB, HEXToHSL, HSLToHEX } from './colorHelpers';

//rgbA and rgbB are hex strings, amountToMix ranges from 0.0 to 1.0
export function colorMixer(rgbA: string, rgbB: string, amountToMix: number) {
  const a = HEXToIRGB(rgbA);
  const b = HEXToIRGB(rgbB);

  let newR = colorChannelMixer(a.r, b.r, amountToMix);
  let newG = colorChannelMixer(a.g, b.g, amountToMix);
  let newB = colorChannelMixer(a.b, b.b, amountToMix);

  let newColor = IRGBToHEX({ r: newR, g: newG, b: newB });

  return newColor;
}

//create color gradient between 2 colors
export function createColorGradient(startColor: string, endColor: string, count: number): string[] {
  if (count === 1) return [startColor];

  const c1 = HEXToIRGB(endColor);
  const c2 = HEXToIRGB(startColor);
  let alpha = 0.0;
  const result: string[] = [];

  for (let i = 0; i < count; i++) {
    const rawColor: number[] = [];
    alpha += 1.0 / count;

    rawColor[0] = c1.r * alpha + (1 - alpha) * c2.r;
    rawColor[1] = c1.g * alpha + (1 - alpha) * c2.g;
    rawColor[2] = c1.b * alpha + (1 - alpha) * c2.b;

    const newColor = IRGBToHEX({ r: rawColor[0], g: rawColor[1], b: rawColor[2] });
    result.push(newColor);
  }

  return result;
}

//lighten or darken a color
export function lightenColor(color: string, percent: number): string {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  return IRGBToHEX({ r: R, g: G, b: B });
}

//generate a palette of x colors based on starting color
export function generatePalette(numColors: number, startingColor: string): string[] {
  const palette: IHSL[] = [];
  const hsl = HEXToHSL(startingColor);
  palette.push(hsl);
  let color = hsl;
  let count = 0;

  while (palette.length < numColors && count++ < 100) {
    let newColor = nextHslColor(color);
    palette.push(newColor);
    color = newColor;
  }

  return palette.map((color) => HSLToHEX(color));
}

//calc a next color based on previous color and steps
function nextHslColor(base: IHSL): IHSL {
  const hueStep = 30;
  const satStep = 10;
  const lumStep = -5;

  return {
    h: base.h + hueStep > 359 ? hueStep : base.h + hueStep,
    s: Math.min(base.s + satStep, 100),
    l: Math.max(base.l + lumStep, 30),
  };
}

// Inverts the given HSL color
export function invertColor(hsl: IHSL): IHSL {
  let invertedH = (hsl.h + 180) % 360;
  let invertedS = 100 - hsl.s;
  let invertedL = 100 - hsl.l;

  return { h: invertedH, s: invertedS, l: invertedL };
}
