import { Fragment, useContext, useEffect, useState } from 'react';
import {
  Panel,
  PanelType,
  PrimaryButton,
  DefaultButton,
  Separator,
  TextField,
  DetailsListLayoutMode,
  SelectionMode,
  Text,
  Checkbox,
  IColumn,
  Persona,
  PersonaSize,
  ShimmeredDetailsList,
  Label,
  Toggle,
} from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalLayerRightSideBarProps,
  globalStackTokensGapSmall,
  globalStackStylesPanel,
  globalStackTokensGapMedium,
} from 'globalStyles';
import Dashboard, { DashboardTypes } from 'models/dashboard';
import AppContext from 'App/AppContext';
import { apiGetGroupMembership } from 'services/Api/userService';
import { apiRequest } from 'services/Auth/authConfig';
import Group from 'models/group';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { GroupMember } from 'models/groupMembers';
import TextInfo from 'components/Notification/Info';
import { globalKB_howToWorkWithPowerBI } from 'globalConstants';
import { isEmpty } from 'utils/string';
import { PowerBI } from 'models/setting';
import { hasUserFeature, FeatureTypes } from 'services/Auth/featurePermissions';

interface IEditDashboardPanelProps {
  currentDashboard: Dashboard | undefined;
  dashboards: Dashboard[];
  isOpen: boolean;
  onSave: (dashboard: Dashboard) => void;
  onClose: () => void;
}

const EditDashboardPanel = (props: IEditDashboardPanelProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'dashboard']);
  const [nameError, setNameError] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined);
  const [groupsMember, setGroupsMember] = useState<GroupMember[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPowerBI, setIsPowerBI] = useState<boolean>(false);
  const [powerBIEnabled, setPowerBIEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      loadData();
      setDashboard(props.currentDashboard);
      setIsPowerBI(!isEmpty(props.currentDashboard?.url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentDashboard, props.isOpen]);

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      if (props.currentDashboard?.dashboardType === DashboardTypes.team) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const groupsMembers = await apiGetGroupMembership(accessToken, false);
        const groups = groupsMembers.map((g) => appContext.globalDataCache.groups.get(g.groupId));
        const groupsNoMember = props.currentDashboard?.groups?.filter(
          (g) => !groupsMembers.some((m) => m.groupId === g.id),
        );
        if (groupsNoMember) groups.push(...groupsNoMember);
        groups.sort((a, b) => a.name.localeCompare(b.name));
        setGroups(groups);
        setGroupsMember(groupsMembers);
      }

      const powerBIsetting = appContext.globalDataCache.settings.get(PowerBI) as boolean;
      setPowerBIEnabled(powerBIsetting);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setDashboardName = (value: string | undefined) => {
    if (!dashboard) return;
    const dashboardClone = dashboard.clone();
    dashboardClone.name = value ?? '';
    setDashboard(dashboardClone);
  };

  const setDashboardUrl = (value: string | undefined) => {
    if (!dashboard) return;
    const dashboardClone = dashboard.clone();
    dashboardClone.url = value ?? '';
    setDashboard(dashboardClone);
  };

  const onSave = () => {
    if (!dashboard) return;
    props.onSave(dashboard);
  };

  const hasError = (): boolean => {
    if (props.currentDashboard?.dashboardType === DashboardTypes.team && dashboard?.groups?.length === 0) return true;
    if (isPowerBI && isEmpty(dashboard?.url)) return true;

    return nameError;
  };

  const onGetDashboardNameError = (value: string | undefined): string | undefined => {
    if (value) {
      if (
        value !== props.currentDashboard?.name &&
        props.dashboards.find((d) => d.name.toLocaleLowerCase() === value.toLowerCase())
      ) {
        return t('dashboard:Panels.Edit.ErrorNameExists');
      }

      setNameError(false);

      return undefined;
    } else {
      setNameError(true);

      return t('dashboard:Panels.Edit.ErrorNameRequired');
    }
  };

  const columns: IColumn[] = [
    {
      key: 'checked',
      name: t('dashboard:Panels.Edit.List.Checked'),
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      onRender: (item?: Group, index?: number, column?: IColumn) => {
        if (!item) return;
        let checked: boolean = false;
        const noMember: boolean = !groupsMember.some((g) => g.groupId === item.id);
        if (dashboard && dashboard.groups) {
          checked = dashboard.groups.findIndex((d) => d.id === item.id) >= 0;
        }

        return (
          <Checkbox
            disabled={noMember}
            checked={checked}
            onChange={(ev, checked) => onSelectTeam(item, checked ?? false)}
          />
        );
      },
    },
    {
      isMultiline: true,
      key: 'name',
      name: t('dashboard:Panels.Edit.List.Group'),
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      onRender: (item?: Group, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Persona text={item.name} secondaryText={item.email} size={PersonaSize.size40} />;
      },
    },
  ];

  const onSelectTeam = (group: Group, checked: boolean) => {
    if (!dashboard) return;
    const newDashboard = dashboard.clone();
    if (checked) {
      newDashboard.groups?.push(group);
    } else {
      newDashboard.groups = newDashboard.groups?.filter((g) => g.id !== group.id);
    }
    setDashboard(newDashboard);
  };

  //
  // Render
  //

  const onRenderFooterContentConfiguredWidget = () => {
    return (
      <Stack horizontal styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <PrimaryButton onClick={() => onSave()} disabled={hasError()}>
            {t('translation:General.Button.Save')}
          </PrimaryButton>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton onClick={props.onClose}>{t('translation:General.Button.Cancel')}</DefaultButton>
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <Panel
      headerText={t('dashboard:Panels.Edit.Title')}
      type={PanelType.medium}
      isBlocking={true}
      isHiddenOnDismiss={false}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      isFooterAtBottom={true}
      layerProps={globalLayerRightSideBarProps}
      onRenderFooterContent={onRenderFooterContentConfiguredWidget}
    >
      <Stack verticalFill styles={globalStackStylesPanel} tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <TextField
            label={t('dashboard:Panels.Edit.NameLabel')}
            autoFocus={!appContext.isMobileView}
            required
            value={dashboard?.name ?? ''}
            onChange={(ev, newValue) => setDashboardName(newValue)}
            onGetErrorMessage={onGetDashboardNameError}
            maxLength={128}
            placeholder={t('dashboard:Panels.Edit.NamePlaceholder')}
          />
          <Separator />
        </Stack.Item>
        {props.currentDashboard?.dashboardType === DashboardTypes.team && (
          <Fragment>
            <Stack.Item>
              <Text>{t('dashboard:Panels.Edit.TeamInfo')}</Text>
            </Stack.Item>
            <Stack.Item>
              <ShimmeredDetailsList
                shimmerLines={5}
                enableShimmer={isLoading}
                onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
                compact
                layoutMode={DetailsListLayoutMode.justified}
                items={groups}
                columns={columns}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
              />
            </Stack.Item>
          </Fragment>
        )}
        {hasUserFeature(appContext, FeatureTypes.PowerBI) && (
          <Fragment>
            <Stack.Item>
              <Toggle
                checked={isPowerBI}
                disabled={!powerBIEnabled}
                label={
                  <TextInfo message={t('dashboard:PowerBI.Info')} learnMoreLink={globalKB_howToWorkWithPowerBI}>
                    <Label>{t('dashboard:PowerBI.Label')}</Label>
                  </TextInfo>
                }
                onChange={(ev, checked) => {
                  setIsPowerBI(checked ?? false);
                  if (!checked) {
                    setDashboardUrl(undefined);
                  }
                }}
              ></Toggle>
            </Stack.Item>
            <Stack.Item>
              <TextInfo message={t('dashboard:PowerBI.UrlInfo')}>
                <Label>{t('dashboard:PowerBI.UrlLabel')}</Label>
              </TextInfo>
              <TextField
                autoFocus={!appContext.isMobileView}
                required={isPowerBI}
                disabled={!isPowerBI}
                multiline
                style={{
                  resize: 'vertical',
                  maxHeight: 100,
                }}
                value={dashboard?.url ?? ''}
                onChange={(ev, newValue) => setDashboardUrl(newValue)}
                maxLength={2000}
                placeholder={t('dashboard:PowerBI.UrlPlaceholder')}
              ></TextField>
            </Stack.Item>
          </Fragment>
        )}
      </Stack>
    </Panel>
  );
};

export default EditDashboardPanel;
