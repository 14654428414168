import { useContext, useEffect, useState } from 'react';
import {
  CommandButton,
  ContextualMenuItemType,
  IconButton,
  IContextualMenuProps,
  IPivotItemProps,
  Pivot,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  SpinnerSize,
  Spinner,
} from '@fluentui/react';
import Dashboard, { DashboardSharedData, DashboardTypes, Dashboard_Translation } from 'models/dashboard';
import DashboardInstance from './DashboardInstance';
import {
  deleteIcon,
  editIcon,
  globalStackItemStylesScroll,
  globalStackTokensGapSmall,
  leftArrowIcon,
  newIcon,
  rightArrowIcon,
  shareIcon,
} from 'globalStyles';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import DashboardScene from 'models/dashboardScene';
import { useTranslation } from 'react-i18next';
import {
  apiAddDashboard,
  apiAddWidgetToDashboard,
  apiDeleteDashboard,
  apiGetDashboardsForType,
  apiUpdateDashboard,
} from 'services/Api/dashboardService';
import Widget, { WidgetTypes, Widget_Translation } from 'models/widget';
import Language from 'models/language';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import { overflow } from 'utils/string';
import { fromStringToNumberArray, numberArrayToString } from 'utils/array';
import AddDashboardPanel from './AddDashboardPanel';
import EditDashboardPanel from './EditDashboardPanel';
import ShareDashboardModal from './ShareDashboardModal';
import Norm from 'models/norm';
import { WidgetNormCoverageConfig } from './Widgets/WidgetNormCoverage';
import { WidgetCustomNormCoverageConfig } from './Widgets/WidgetCustomNormCoverage';
import { WidgetMyUpcomingTasksConfig } from './Widgets/WidgetMyUpcomingTasks';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { useLocation } from 'react-router-dom';

interface IDashboardCollectionProps {
  dashboardScene: DashboardScene | undefined;
  dashboardType: DashboardTypes;
  onRefreshSharedData?: () => Promise<void>;
}

const DashboardCollection = (props: IDashboardCollectionProps) => {
  //load some common translation files upfront
  const { t } = useTranslation([
    'translation',
    'dashboard',
    'widgets',
    'task',
    'tasks',
    'risk',
    'control',
    'theme',
    'objective',
    'process',
    'kpi',
    'asset',
  ]);
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [selectedPivot, setSelectedPivot] = useState<string | undefined>(undefined);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addDashboard, setAddDashboard] = useState<Dashboard | undefined>(undefined);
  const [editDashboard, setEditDashboard] = useState<Dashboard | undefined>(undefined);
  const [deleteDashboard, setDeleteDashboard] = useState<Dashboard | undefined>(undefined);
  const [shareDashboard, setShareDashboard] = useState<Dashboard | undefined>(undefined);
  const [currentType, setCurrentType] = useState<number | undefined>(undefined);
  const [sharedData, setSharedData] = useState<DashboardSharedData | undefined>(undefined);
  const maxDashboardsPerType = 8;

  useEffect(() => {
    const refreshSharedData = () => {
      const newSharedData = new DashboardSharedData();
      newSharedData.activities = props.dashboardScene?.activities;
      newSharedData.myTasks = props.dashboardScene?.tasks;
      setSharedData(newSharedData);
    };

    const loadData = async () => {
      try {
        await appContext.globalDataCache.norms.getItems();
        refreshSharedData();

        if (props.dashboardType !== currentType) {
          setIsLoading(true);
          setCurrentType(props.dashboardType);
          //full load
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          let newDashboards = await apiGetDashboardsForType(
            props.dashboardType,
            accessToken,
            appContext.globalDataCache,
          );

          //The first dashboard is always the system dashboard and cannot be changed
          //This dashboard also has the default widgets. The user can add widgets so these are merged into the default dashboard from API
          if (props.dashboardType === DashboardTypes.user) {
            if (newDashboards.length === 0) {
              //add the system dashboard with default widgets
              let newMyDashboard = getMyDefaultDashboard(props.dashboardScene);
              const createdDashboard = await apiAddDashboard(newMyDashboard, accessToken, appContext.globalDataCache);
              createdDashboard.widgets = newMyDashboard.widgets;
              for (let idx = 0; idx < createdDashboard.widgets.length; idx++) {
                let widget = createdDashboard.widgets[idx];
                widget.dashboard = createdDashboard;
                widget.dashboardId = createdDashboard.dashboardId;
                widget = await apiAddWidgetToDashboard(widget, accessToken, appContext.globalDataCache);
              }
              createdDashboard.isSystem = true;
              createdDashboard.widgets = getMySystemWidgets(newMyDashboard);
              newDashboards.push(createdDashboard);
            } else {
              //add the system widgets to the first dashboard
              //sort by id to make sure the first created dashboard is always at the start which is the system dashboard
              newDashboards.sort((a, b) => a.dashboardId - b.dashboardId);
              const myDashboard = newDashboards[0];
              myDashboard.isSystem = true;
              myDashboard.widgets.push(...getMySystemWidgets(myDashboard));
              myDashboard.name = t('dashboard:DefaultName');
            }
          } else if (props.dashboardType === DashboardTypes.org) {
            if (newDashboards.length === 0 && hasUserFeatureGenericManager(appContext)) {
              //create a default organization dashboard when no organization dashboard exists
              const norms = appContext.globalDataCache.norms.items.sort((a, b) => a.name.localeCompare(b.name));
              const newOrgDashboard = getOrgDefaultDashboard(norms, props.dashboardScene);
              //add the default dashboard and the widgets
              const createdDashboard = await apiAddDashboard(newOrgDashboard, accessToken, appContext.globalDataCache);
              for (let idx = 0; idx < newOrgDashboard.widgets.length; idx++) {
                const widget = newOrgDashboard.widgets[idx];
                widget.dashboard = createdDashboard;
                widget.dashboardId = createdDashboard.dashboardId;
                await apiAddWidgetToDashboard(widget, accessToken, appContext.globalDataCache);
                //don't need to add the widget to the dashboard. widgets will be loaded in the dashboard instance component
              }
              newDashboards.push(createdDashboard);
            }
          }

          //set the sort order
          if (props.dashboardType === DashboardTypes.org) {
            newDashboards.sort((a, b) => a.sortOrder - b.sortOrder);
          } else {
            newDashboards = setSortOrderFromStorage(newDashboards, props.dashboardType);
          }

          setDashboards(newDashboards);
          setSelectedDashboardFromUrl(newDashboards);
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (props.dashboardScene) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardType, props.dashboardScene]);

  useEffect(() => {
    setSelectedDashboardFromUrl(dashboards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setSelectedDashboardFromUrl = (dashboards: Dashboard[]) => {
    //select from url
    const urlHash = window.location.hash;
    if (urlHash) {
      const dashboard = dashboards.find((d) => '#' + d.dashboardId.toString() === urlHash);
      if (dashboard) {
        const key = getKey(dashboard);
        if (key !== selectedPivot) {
          setSelectedPivot(getKey(dashboard));
        }

        return;
      }
    }

    //select first
    if (dashboards && dashboards.length > 0) {
      setSelectedPivot(getKey(dashboards[0]));
    }
  };

  const setSortOrderFromStorage = (dashboards: Dashboard[], type: DashboardTypes): Dashboard[] => {
    let key: LocalStorageKeys;
    switch (type) {
      case DashboardTypes.user:
        key = LocalStorageKeys.DashboardSortOrderForMe;
        break;
      case DashboardTypes.team:
        key = LocalStorageKeys.DashboardSortOrderForMyTeams;
        break;
      default:
        dashboards.sort((a, b) => a.name.localeCompare(b.name));

        return dashboards;
    }

    const orderStr = getLocalStorageData(appContext, key);
    if (orderStr) {
      const order = fromStringToNumberArray(orderStr);
      let newDashboardOrder: Dashboard[] = [];
      for (let idx = 0; idx < order.length; idx++) {
        const dashboard = dashboards.find((d) => d.dashboardId === order[idx]);
        if (dashboard) {
          newDashboardOrder.push(dashboard);
        }
      }
      const dashboardsNotInStorage = dashboards.filter((d) => !order.includes(d.dashboardId));
      if (dashboardsNotInStorage.length > 0) {
        dashboardsNotInStorage.sort((a, b) => a.name.localeCompare(b.name));
        newDashboardOrder.push(...dashboardsNotInStorage);
      }
      newDashboardOrder = moveSystemDashboardToFront(newDashboardOrder);
      saveSortOrderToStorage(newDashboardOrder, type);

      return newDashboardOrder;
    } else {
      dashboards.sort((a, b) => a.name.localeCompare(b.name));
      dashboards = moveSystemDashboardToFront(dashboards);
      saveSortOrderToStorage(dashboards, type);

      return dashboards;
    }
  };

  const moveSystemDashboardToFront = (dashboards: Dashboard[]): Dashboard[] => {
    const idx = dashboards.findIndex((d) => d.isSystem);
    if (idx > 0) {
      const systemDashboard = dashboards[idx];
      dashboards.splice(idx, 1);

      return [systemDashboard, ...dashboards];
    }

    return dashboards;
  };

  const saveSortOrderToApi = async (dashboard: Dashboard) => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateDashboard(dashboard, accessToken, appContext.globalDataCache);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveSortOrderToStorage = (dashboards: Dashboard[], type: DashboardTypes) => {
    let storageKey: LocalStorageKeys;
    switch (type) {
      case DashboardTypes.user:
        storageKey = LocalStorageKeys.DashboardSortOrderForMe;
        break;
      case DashboardTypes.team:
        storageKey = LocalStorageKeys.DashboardSortOrderForMyTeams;
        break;
      default:
        return;
    }

    const orderStr = numberArrayToString(dashboards.map((d) => d.dashboardId));
    setLocalStorageData(appContext, storageKey, orderStr);
  };

  const onDeleteDashboard = async () => {
    try {
      if (isLoading || !deleteDashboard) return;
      setIsLoading(true);
      setDeleteDashboard(undefined);
      appContext.showContentLoader();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiDeleteDashboard(deleteDashboard, accessToken);

      const newDashboards = dashboards.filter((d) => d.dashboardId !== deleteDashboard.dashboardId);

      setDashboards(newDashboards);
      saveSortOrder(deleteDashboard, newDashboards, props.dashboardType, false);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
      appContext.hideContentLoader();
    }
  };

  const onCreateNewDashboard = async (newDashboard: Dashboard) => {
    try {
      if (isLoading || !addDashboard) return;
      setIsLoading(true);
      setAddDashboard(undefined);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      newDashboard = await apiAddDashboard(newDashboard, accessToken, appContext.globalDataCache);

      const idx = dashboards.findIndex((d) => d.dashboardId === addDashboard.dashboardId);
      const before = dashboards.slice(0, idx + 1);
      const after = dashboards.slice(idx + 1);
      const newDashboards = [...before, newDashboard, ...after];

      setDashboards(newDashboards);
      saveSortOrder(newDashboard, newDashboards, props.dashboardType, false);
      setSelectedPivot(getKey(newDashboard));
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onEditDashboard = async (updatedDashboard: Dashboard) => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      setEditDashboard(undefined);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateDashboard(updatedDashboard, accessToken, appContext.globalDataCache);

      if (shareDashboard) {
        appContext.showNotification(t('dashboard:Share.Success'));
        setShareDashboard(undefined);
      }

      setDashboards(dashboards.map((d) => (d.dashboardId === updatedDashboard.dashboardId ? updatedDashboard : d)));
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Default dashboards
  //
  const getMyDefaultDashboard = (dashboardScene: DashboardScene | undefined): Dashboard => {
    const newDashboard = new Dashboard(DashboardTypes.user);
    newDashboard.dashboardId = -1; //set to -1 to indicate system dashboard
    newDashboard.name = t('dashboard:DefaultName');
    newDashboard.trans = [new Dashboard_Translation()];
    newDashboard.trans[0].lang = new Language();
    newDashboard.trans[0].lang.code = appContext.user.language.code;
    newDashboard.transIdx = 0;

    const myUpcomingTasksWidget = new Widget();
    myUpcomingTasksWidget.dashboard = newDashboard;
    myUpcomingTasksWidget.dashboardId = newDashboard.dashboardId;
    myUpcomingTasksWidget.sortOrder = 0;
    myUpcomingTasksWidget.name = t('widgets:MyUpcomingTasks.Name');
    myUpcomingTasksWidget.widgetType = WidgetTypes.MyUpcomingTasks;
    myUpcomingTasksWidget.trans = [new Widget_Translation()];
    myUpcomingTasksWidget.trans[0].lang = new Language();
    myUpcomingTasksWidget.trans[0].lang.code = appContext.user.language.code;
    myUpcomingTasksWidget.transIdx = 0;
    const config = new WidgetMyUpcomingTasksConfig();
    config.period = 14;
    myUpcomingTasksWidget.widgetConfig = JSON.stringify(config);
    newDashboard.widgets.push(myUpcomingTasksWidget);

    const myOverdueTasksWidget = new Widget();
    myOverdueTasksWidget.dashboard = newDashboard;
    myOverdueTasksWidget.dashboardId = newDashboard.dashboardId;
    myOverdueTasksWidget.sortOrder = 1;
    myOverdueTasksWidget.name = t('widgets:MyOverdueTasks.Name');
    myOverdueTasksWidget.widgetType = WidgetTypes.MyOverdueTasks;
    myOverdueTasksWidget.trans = [new Widget_Translation()];
    myOverdueTasksWidget.trans[0].lang = new Language();
    myOverdueTasksWidget.trans[0].lang.code = appContext.user.language.code;
    myOverdueTasksWidget.transIdx = 0;
    newDashboard.widgets.push(myOverdueTasksWidget);

    return newDashboard;
  };

  const getMySystemWidgets = (dashboard: Dashboard): Widget[] => {
    const defWidgets: Widget[] = [];

    const myRecentItemsWidget = new Widget();
    myRecentItemsWidget.dashboard = dashboard;
    myRecentItemsWidget.dashboardId = dashboard.dashboardId;
    myRecentItemsWidget.widgetId = -2;
    myRecentItemsWidget.sortOrder = -2;
    myRecentItemsWidget.name = t('dashboard:RecentItems.MyRecentItems');
    myRecentItemsWidget.widgetType = WidgetTypes.MyRecentItems;
    defWidgets.push(myRecentItemsWidget);

    const myActionsWidget = new Widget();
    myActionsWidget.dashboard = dashboard;
    myActionsWidget.dashboardId = dashboard.dashboardId;
    myActionsWidget.widgetId = -1;
    myRecentItemsWidget.sortOrder = -1;
    myActionsWidget.name = t('dashboard:Action.ActionRequired');
    myActionsWidget.widgetType = WidgetTypes.MyActions;
    defWidgets.push(myActionsWidget);

    return defWidgets;
  };

  const getOrgDefaultDashboard = (norms: Norm[], dashboardScene: DashboardScene | undefined): Dashboard => {
    const newDashboard = new Dashboard(DashboardTypes.org);
    newDashboard.name = appContext.user.tenant.name;
    newDashboard.trans = [new Dashboard_Translation()];
    newDashboard.trans[0].lang = new Language();
    newDashboard.trans[0].lang.code = appContext.user.language.code;
    newDashboard.transIdx = 0;

    const riskStatusWidget = new Widget();
    riskStatusWidget.dashboard = newDashboard;
    riskStatusWidget.dashboardId = newDashboard.dashboardId;
    riskStatusWidget.sortOrder = 0;
    riskStatusWidget.name = t('widgets:RiskStatus.Name');
    riskStatusWidget.widgetType = WidgetTypes.RiskStatus;
    riskStatusWidget.trans = [new Widget_Translation()];
    riskStatusWidget.trans[0].lang = new Language();
    riskStatusWidget.trans[0].lang.code = appContext.user.language.code;
    riskStatusWidget.transIdx = 0;
    newDashboard.widgets.push(riskStatusWidget);

    for (let idx = 0; idx < norms.length; idx++) {
      const norm = norms[idx];
      const normWidget = new Widget();
      normWidget.dashboard = newDashboard;
      normWidget.dashboardId = newDashboard.dashboardId;
      normWidget.sortOrder = idx + 1;
      normWidget.trans = [new Widget_Translation()];
      normWidget.trans[0].lang = new Language();
      normWidget.trans[0].lang.code = appContext.user.language.code;
      normWidget.transIdx = 0;

      if (norm.isoNormId) {
        normWidget.name = t('widgets:NormCoverage.Name');
        normWidget.widgetType = WidgetTypes.NormCoverage;
        const config = new WidgetNormCoverageConfig();
        config.isoNormId = norm.isoNormId;
        normWidget.widgetConfig = JSON.stringify(config);
      } else {
        normWidget.name = t('widgets:CustomNormCoverage.Name');
        normWidget.widgetType = WidgetTypes.CustomNormCoverage;
        const config = new WidgetCustomNormCoverageConfig();
        config.customNormId = norm.normId;
        normWidget.widgetConfig = JSON.stringify(config);
      }

      newDashboard.widgets.push(normWidget);
    }

    const openTasksWidget = new Widget();
    openTasksWidget.dashboard = newDashboard;
    openTasksWidget.dashboardId = newDashboard.dashboardId;
    openTasksWidget.sortOrder = newDashboard.widgets.length;
    openTasksWidget.name = t('widgets:OpenTasksPerUser.Name');
    openTasksWidget.widgetType = WidgetTypes.OpenTasksPerUser;
    openTasksWidget.trans = [new Widget_Translation()];
    openTasksWidget.trans[0].lang = new Language();
    openTasksWidget.trans[0].lang.code = appContext.user.language.code;
    openTasksWidget.transIdx = 0;
    newDashboard.widgets.push(openTasksWidget);

    return newDashboard;
  };

  //
  // Menu items
  //
  const handleLinkClick = (item: PivotItem | undefined) => {
    if (item && item.props.itemKey) {
      setSelectedPivot(item.props.itemKey);
      window.location.hash = getDashboardFromPivotKey(item.props.itemKey)?.dashboardId.toString();
    } else {
      setSelectedPivot(undefined);
    }
  };

  const getKey = (dashBoard: Dashboard | undefined): string | undefined => {
    if (!dashBoard) return undefined;

    return 'dashboard' + dashBoard.dashboardId.toString();
  };

  const getId = (pivotKey: string): number => {
    const id = pivotKey.slice('dashboard'.length - pivotKey.length);

    return Number.parseInt(id);
  };

  const getDashboardFromPivotKey = (pivotKey: string): Dashboard => {
    const id = getId(pivotKey);

    return dashboards.find((d) => d.dashboardId === id) || new Dashboard(props.dashboardType);
  };

  const getDashboardIndexFromPivotKey = (pivotKey: string): number => {
    const id = getId(pivotKey);

    return dashboards.findIndex((d) => d.dashboardId === id);
  };

  const saveSortOrder = (dashboard: Dashboard, dashboards: Dashboard[], type: DashboardTypes, isMove: boolean) => {
    switch (type) {
      case DashboardTypes.user:
      case DashboardTypes.team:
        saveSortOrderToStorage(dashboards, type);
        break;
      case DashboardTypes.org:
        //inserts/deletes are handled by the Api at the time of insert/delete
        if (isMove) saveSortOrderToApi(dashboard);
        break;
      default:
        return;
    }
  };

  const moveLeft = (pivotKey: string) => {
    const id = getId(pivotKey);
    const newDashBoards = [...dashboards];
    const idx = newDashBoards.findIndex((d) => d.dashboardId === id);
    if (idx > 0) {
      const dashboard = newDashBoards[idx];
      dashboard.sortOrder--;
      [newDashBoards[idx - 1], newDashBoards[idx]] = [newDashBoards[idx], newDashBoards[idx - 1]];
      setDashboards(newDashBoards);
      saveSortOrder(dashboard, newDashBoards, props.dashboardType, true);
    }
  };

  const moveRight = (pivotKey: string) => {
    const id = getId(pivotKey);
    const newDashBoards = [...dashboards];
    const idx = dashboards.findIndex((d) => d.dashboardId === id);
    if (idx >= 0 && idx < newDashBoards.length - 1) {
      const dashboard = newDashBoards[idx];
      dashboard.sortOrder++;
      [newDashBoards[idx + 1], newDashBoards[idx]] = [newDashBoards[idx], newDashBoards[idx + 1]];
      setDashboards(newDashBoards);
      saveSortOrder(dashboard, newDashBoards, props.dashboardType, true);
    }
  };

  const canAddDashboard = (pivotKey: string): boolean => {
    const dashboard = getDashboardFromPivotKey(pivotKey);

    //need manager/admin role to add dashboards other then user-type
    if (dashboard.dashboardType !== DashboardTypes.user && !hasUserFeatureGenericManager(appContext)) {
      return false;
    }

    //max count reached
    if (dashboards.length < maxDashboardsPerType) {
      return true;
    }

    return false;
  };

  const canMoveLeft = (pivotKey: string): boolean => {
    const idx = getDashboardIndexFromPivotKey(pivotKey);
    const dashboard = getDashboardFromPivotKey(pivotKey);

    if (dashboard.dashboardType === DashboardTypes.user) {
      return idx > 1; //cannot move before user system dashboard
    } else {
      return idx > 0;
    }
  };

  const canMoveRight = (pivotKey: string): boolean => {
    const idx = getDashboardIndexFromPivotKey(pivotKey);
    const dashboard = getDashboardFromPivotKey(pivotKey);

    if (dashboard.dashboardType === DashboardTypes.user) {
      return idx > 0 && idx < dashboards.length - 1; //cannot move user system dashboard
    } else {
      return idx < dashboards.length - 1;
    }
  };

  const canShare = (pivotKey: string): boolean => {
    const dashboard = getDashboardFromPivotKey(pivotKey);

    return dashboard.dashboardType !== DashboardTypes.org && !isSystem(pivotKey);
  };

  const isSystem = (pivotKey: string): boolean => {
    const dashboard = getDashboardFromPivotKey(pivotKey);

    return dashboard.isSystem;
  };

  const isSharedWithMe = (pivotKey: string): boolean => {
    return getDashboardFromPivotKey(pivotKey).isSharedWithUser(appContext.user.id);
  };

  const isReadOnly = (dashboard: Dashboard): boolean => {
    // 1. dashboard is shared
    // 2. dashboard type is team or org and and has not roles
    return (
      dashboard.isSharedWithUser(appContext.user.id) ||
      (dashboard.dashboardType !== DashboardTypes.user && !hasUserFeatureGenericManager(appContext))
    );
  };

  const getMenuItems = (pivotKey: string): IContextualMenuProps => {
    return {
      items: [
        {
          key: 'add',
          disabled: !canAddDashboard(pivotKey),
          data: pivotKey,
          iconProps: newIcon,
          text: t('dashboard:Menu.New'),
          onClick: () => setAddDashboard(getDashboardFromPivotKey(pivotKey)),
        },
        { key: 'd1', itemType: ContextualMenuItemType.Divider },
        {
          key: 'moveleft',
          disabled: !canMoveLeft(pivotKey),
          data: pivotKey,
          iconProps: leftArrowIcon,
          text: t('dashboard:Menu.MoveLeft'),
          onClick: () => moveLeft(pivotKey),
        },
        {
          key: 'moveright',
          disabled: !canMoveRight(pivotKey),
          data: pivotKey,
          iconProps: rightArrowIcon,
          text: t('dashboard:Menu.MoveRight'),
          onClick: () => moveRight(pivotKey),
        },
        { key: 'd3', itemType: ContextualMenuItemType.Divider },
        {
          key: 'edit',
          disabled: isSystem(pivotKey) || isSharedWithMe(pivotKey),
          data: pivotKey,
          iconProps: editIcon,
          text: t('dashboard:Menu.Edit'),
          onClick: () => setEditDashboard(getDashboardFromPivotKey(pivotKey)),
        },
        {
          key: 'delete',
          disabled: isSystem(pivotKey) || isSharedWithMe(pivotKey),
          data: pivotKey,
          iconProps: deleteIcon,
          text: t('dashboard:Menu.Delete'),
          onClick: () => setDeleteDashboard(getDashboardFromPivotKey(pivotKey)),
        },
        { key: 'd2', data: pivotKey, itemType: ContextualMenuItemType.Divider },
        {
          key: 'share',
          data: pivotKey,
          disabled: !canShare(pivotKey),
          iconProps: shareIcon,
          text: t('dashboard:Menu.Share'),
          onClick: () => setShareDashboard(getDashboardFromPivotKey(pivotKey)),
        },
      ],
    };
  };

  //
  // Render
  //

  const renderItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (!itemProps || !itemProps.itemKey || !defaultRender) return null;

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        {defaultRender(itemProps)}
        {(props.dashboardType === DashboardTypes.user || hasUserFeatureGenericManager(appContext)) && (
          <Stack.Item>
            <IconButton menuProps={getMenuItems(itemProps.itemKey)} />
          </Stack.Item>
        )}
        {getDashboardFromPivotKey(itemProps.itemKey).isSharedWithUser(appContext.user.id) && (
          <Stack.Item>
            <IconButton
              iconProps={{ iconName: 'ReminderPerson' }}
              onClick={() => setShareDashboard(getDashboardFromPivotKey(itemProps.itemKey ?? ''))}
            />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!addDashboard && dashboards.length === 0) {
    return (
      <CommandButton
        disabled={!hasUserFeatureGenericManager(appContext)}
        text={t('dashboard:Menu.New')}
        iconProps={newIcon}
        onClick={() => setAddDashboard(new Dashboard(props.dashboardType))}
      />
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item grow styles={globalStackItemStylesScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Pivot overflowBehavior="menu" onLinkClick={handleLinkClick} selectedKey={selectedPivot}>
            {dashboards.map((dashboard) => {
              return (
                <PivotItem
                  key={getKey(dashboard)}
                  headerText={overflow(dashboard.name, 32)}
                  itemKey={getKey(dashboard)}
                  onRenderItemLink={renderItem}
                >
                  <DashboardInstance
                    dashboard={dashboard}
                    sharedData={sharedData}
                    readonly={isReadOnly(dashboard)}
                    onRefreshSharedData={props.onRefreshSharedData}
                  />
                </PivotItem>
              );
            })}
          </Pivot>
        </ScrollablePane>
      </Stack.Item>
      <DialogConfirmDelete
        onNo={() => {
          setDeleteDashboard(undefined);
        }}
        onYes={() => {
          onDeleteDashboard();
        }}
        title={t('dashboard:Dialogs.Delete.Title')}
        subText={t('dashboard:Dialogs.Delete.SubTitle')}
        hidden={deleteDashboard === undefined}
        confirmText={t('dashboard:Dialogs.Delete.ConfirmText')}
      />
      <AddDashboardPanel
        isOpen={addDashboard !== undefined}
        dashboards={dashboards}
        currentDashboard={addDashboard}
        onSave={onCreateNewDashboard}
        onClose={() => {
          setAddDashboard(undefined);
        }}
      />
      <EditDashboardPanel
        isOpen={editDashboard !== undefined}
        dashboards={dashboards}
        currentDashboard={editDashboard}
        onSave={onEditDashboard}
        onClose={() => {
          setEditDashboard(undefined);
        }}
      />
      <ShareDashboardModal
        currentDashboard={shareDashboard}
        isOpen={shareDashboard !== undefined}
        onSave={onEditDashboard}
        onClose={() => setShareDashboard(undefined)}
      />
    </Stack>
  );
};

export default DashboardCollection;
