import IdListDTO from './IdListDTO';

export default class GroupDTO {
  groupId: string;

  name: string;

  email: string;

  syncEnabled: boolean;

  syncStatus?: number;

  syncError?: string;

  syncMemberCount?: number;

  taskFilterEnabled?: boolean;

  userIds?: IdListDTO;

  constructor(_groupId?: string, _name?: string, _email?: string) {
    this.groupId = _groupId ? _groupId : '';
    this.name = _name ? _name : '';
    this.email = _email ? _email : '';
    this.syncEnabled = false;
  }
}
