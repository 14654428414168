import { Languages } from 'models/language';
import http from './httpService';
import LanguageDTO from 'models/dto/languageDTO';
import { mapFromLanguages } from 'mappings/langMapping';
import AppError from 'utils/appError';
import IdListDTO from 'models/dto/IdListDTO';

export async function apiGetLanguages(accessToken: string): Promise<Languages> {
  try {
    const ar = await http.get<LanguageDTO[]>('/languages', http.getRequestConfig(accessToken));

    return mapFromLanguages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLanguagesForCodes(codes: string[], accessToken: string): Promise<Languages> {
  try {
    const idListDTO = new IdListDTO(codes);
    const ar = await http.post<LanguageDTO[]>('/languages/codes', idListDTO, http.getRequestConfig(accessToken));

    return mapFromLanguages(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
