import ISOControl_Translation, { PackageInstruction } from './isoControl_Translation';
import Joi from 'joi';
import { getLocalizedMessageOptions } from '../services/Localization/joiValidation';
import Control, { ControlTypes } from './control';
import { ISONorm } from './norm';

export default class ISOControl {
  isoControlId: number;

  code: string;

  isoNormId?: number;

  parentISOControlId?: number;

  controlType: ControlTypes;

  changedAfterUpgrade: boolean;

  trans?: ISOControl_Translation[];

  control?: Control;

  isoNorm?: ISONorm;

  transIdx: number;

  isoControlISOControls?: ISOControlISOControl[];

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  instruction?: string;

  packageInstructions?: PackageInstruction[];

  constructor() {
    this.isoControlId = 0;
    this.changedAfterUpgrade = false;
    this.controlType = ControlTypes.Theme;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
  }

  clone(): ISOControl {
    const newControl = new ISOControl();

    newControl.isoControlId = this.isoControlId;
    newControl.controlType = this.controlType;
    newControl.parentISOControlId = this.parentISOControlId;
    newControl.name = this.name;
    newControl.description = this.description;
    newControl.instruction = this.instruction;
    newControl.code = this.code;
    newControl.changedAfterUpgrade = this.changedAfterUpgrade;
    newControl.isoNormId = this.isoNormId;
    newControl.transIdx = this.transIdx;
    newControl.trans = this.trans ? [...this.trans] : undefined;
    newControl.control = this.control?.clone();

    return newControl;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name }, { abortEarly: false });
  }
}

export enum ISOControlISOControlStatus {
  Ok = 0,
  Check = 1,
}

export class ISOControlISOControl {
  isoControlIdFrom: number;

  isoControlIdTo: number;

  status?: ISOControlISOControlStatus;

  constructor() {
    this.isoControlIdFrom = 0;
    this.isoControlIdTo = 0;
  }
}

export class ISOControlTransMapping {
  langCode: string;

  mapping: ISOControlTransMappingItem[];

  constructor() {
    this.langCode = '';
    this.mapping = [];
  }
}

export class ISOControlTransMappingItem {
  sourceId: number;

  targetId: number;

  sourceCode: string;

  targetCode: string;

  sourceName: string;

  targetName: string;

  constructor() {
    this.sourceId = 0;
    this.targetId = 0;
    this.sourceCode = '';
    this.targetCode = '';
    this.sourceName = '';
    this.targetName = '';
  }
}
