import { useContext } from 'react';
import { getTheme, Stack, Persona, PersonaSize, Text, TooltipHost } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapSmall } from 'globalStyles';
import AuditTrailEntry from 'models/auditTrailEntry';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
interface ISingleAuditTrailEntry {
  auditTrailEntry: AuditTrailEntry;
}

export const SingleAuditTrailEntry = (props: ISingleAuditTrailEntry) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'auditTrail', 'control', 'risk']);
  const theme = getTheme();

  const expl = props.auditTrailEntry.getActivityText(t as TFunction<string[]>, appContext);

  return (
    <Stack>
      <Stack
        horizontal
        tokens={globalStackTokensGapSmall}
        styles={{
          root: {
            borderWidth: 1,
            borderColor: appContext.useDarkMode ? theme.palette.themeDark : theme.palette.themeLight,
            background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
            borderStyle: 'solid',
            borderRadius: '3px',
            marginBottom: 10,
            padding: 2,
          },
        }}
      >
        <Stack.Item>
          <Persona text={props.auditTrailEntry.userName} hidePersonaDetails={true} size={PersonaSize.size24} />
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <Text block nowrap variant="small">
                {toLocaleDateTimeMedium(props.auditTrailEntry.created)}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <TooltipHost content={expl}>
                <Text block>{expl}</Text>
              </TooltipHost>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
