import AuditTrailEntry from 'models/auditTrailEntry';
import Activity from 'models/activity';
import { EntityTypes } from 'models/entity';
import { CommentTrail } from 'models/comments';

export default class ActivityTrail {
  typeOfEntity: EntityTypes;

  entityId: number;

  commentTrailId: number;

  auditTrailId: number;

  activityCount?: number;

  activities: Activity[];

  auditTrailEntries: AuditTrailEntry[];

  commentTrail: CommentTrail;

  constructor() {
    this.entityId = 0;
    this.typeOfEntity = EntityTypes.NotSet;
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.activityCount = 5;
    this.activities = [];
    this.commentTrail = new CommentTrail();
    this.auditTrailEntries = [];
  }
}
