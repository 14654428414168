import { toBool } from '../utils/string';
import WorkingHoursPattern from 'models/WorkingHoursPattern';
import RiskScoreModel from 'models/RiskScoreModel';
import { toInteger } from 'lodash';
import AppError from 'utils/appError';

//general settings
export const AutoUserLicense = 'AutoUserLicense';
export const DefLanguageCode = 'DefLanguageCode';
export const AuditYearStart = 'AuditYearStart';
export const RiskScoreDef = 'RiskScoreDef';
export const WorkingHours = 'WorkingHours';
export const OrgLanguageCodes = 'OrgLanguageCodes';
export const ModuleRisks = 'ModuleRisks';
export const ModuleOrg = 'ModuleOrg';
export const ModuleThemes = 'ModuleThemes';
export const ModuleControls = 'ModuleControls';
export const ModuleAssets = 'ModuleAssets';
export const ModuleStandards = 'ModuleStandards';
export const LibraryOpenInModal = 'LibraryOpenInModal';
export const ShowISOElementAds = 'ShowISOElementAds';
export const LegacyOutlookSeriesEnabled = 'LegacyOutlookSeriesEnabled';

//report settings
export const ReportStorageList = 'ReportStorageList';
export const ReportTemplateLink = 'ReportTemplateLink';
export const ReportDefaultStorageBehavior = 'ReportDefaultStorageBehavior';
export const ReportDefaultOutputFormat = 'ReportDefaultOutputFormat';
export const ReportAllowUserSettingOverride = 'ReportAllowUserSettingOverride';
export const ReportKeepWordNextToPDF = 'ReportKeepWordNextToPDF';

//auth settings
export const AzureAD = 'AzureAD';
export const AzureADGroupSync = 'AzureADGroupSync';
export const OutlookTasksAdvanced = 'OutlookTasksAdvanced';
export const OutlookTasksBasic = 'OutlookTasksBasic';
export const SharepointLibraries = 'SharepointLibraries';
export const SharepointLists = 'SharepointLists';
export const SharepointPages = 'SharepointPages';
export const SharepointReports = 'SharepointReports';
export const TeamsKB = 'TeamsKB';
export const PowerBI = 'PowerBI';
export const PowerBIService = 'PowerBIService';
export const TeamsKBTaskTemplate = 'TeamsKBTaskTemplate';
export const TeamsCollaboration = 'TeamsCollaboration';

//automation settings
export const AzureDefender = 'AzureDefender';
export const Office365Defender = 'Office365Defender';
export const PowerAutomateSharePoint = 'PowerAutomateSharePoint';

export const Names = {
  AutoUserLicense,
  DefLanguageCode,
  AuditYearStart,
  WorkingHours,
  RiskScoreDef,
  OrgLanguageCodes,
  SharepointLibraries,
  SharepointLists,
  SharepointPages,
  SharepointReports,
  TeamsKB,
  TeamsKBTaskTemplate,
  TeamsCollaboration,
  ModuleRisks,
  ModuleOrg,
  ModuleThemes,
  ModuleControls,
  ModuleAssets,
  LibraryOpenInModal,
  OutlookTasksAdvanced,
  OutlookTasksBasic,
  AzureAD,
  AzureADGroupSync,
  ReportStorageList,
  ReportTemplateLink,
  ReportDefaultStorageBehavior,
  ReportDefaultOutputFormat,
  ReportAllowUserSettingOverride,
  ReportKeepWordNextToPDF,
  AzureDefender,
  PowerAutomateSharePoint,
  PowerBI,
  PowerBIService,
  LegacyOutlookSeriesEnabled
};

export class Settings {
  items: Setting[];

  constructor() {
    this.items = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(name: string): any {
    const setting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (setting) {
      return setting.getValue();
    } else {
      return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(name: string, value: any) {
    const setting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (setting) {
      setting.setValue(setting.settingName, value);
    } else {
      return undefined;
    }
  }

  clone(): Settings {
    const newSettings = new Settings();
    newSettings.items = this.items.map((s) => s.clone());

    return newSettings;
  }
}

export default class Setting {
  settingName: string;

  settingValue: string | undefined;

  constructor() {
    this.settingName = '';
    this.settingValue = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue(): any {
    switch (this.settingName) {
      case AutoUserLicense:
        return toBool(this.settingValue);
      case DefLanguageCode:
        return this.settingValue;
      case AuditYearStart:
        return this.settingValue;
      case RiskScoreDef:
        const riskScoreModel: RiskScoreModel = new RiskScoreModel();
        riskScoreModel.fromJSON(this.settingValue);

        return riskScoreModel;
      case WorkingHours:
        const workingHours: WorkingHoursPattern = new WorkingHoursPattern();
        workingHours.fromJSON(this.settingValue);

        return workingHours;
      case OrgLanguageCodes:
        if (!this.settingValue) return null;
        const OrgLanguages: string[] = JSON.parse(this.settingValue);

        return OrgLanguages;
      case SharepointLibraries:
        return toBool(this.settingValue);
      case SharepointLists:
        return toBool(this.settingValue);
      case SharepointPages:
        return toBool(this.settingValue);
      case SharepointReports:
        return toBool(this.settingValue);
      case TeamsKB:
        return toBool(this.settingValue);
      case TeamsCollaboration:
        return toBool(this.settingValue);
      case TeamsKBTaskTemplate:
        return Number.parseInt(this.settingValue ?? '');
      case ModuleRisks:
        return toBool(this.settingValue);
      case ModuleOrg:
        return toBool(this.settingValue);
      case ModuleThemes:
        return toBool(this.settingValue);
      case ModuleControls:
        return toBool(this.settingValue);
      case ModuleAssets:
        return toBool(this.settingValue);
      case ModuleStandards:
        return toBool(this.settingValue);
      case OutlookTasksAdvanced:
        return toBool(this.settingValue);
      case OutlookTasksBasic:
        return toBool(this.settingValue);
      case AzureAD:
        return toBool(this.settingValue);
      case AzureADGroupSync:
        return toBool(this.settingValue);
      case AzureDefender:
        return toBool(this.settingValue);
      case PowerAutomateSharePoint:
        return toBool(this.settingValue);
      case Office365Defender:
        return toBool(this.settingValue);
      case LibraryOpenInModal:
        return toBool(this.settingValue);
      case ReportStorageList:
        return toInteger(this.settingValue);
      case ReportTemplateLink:
        return toInteger(this.settingValue);
      case ReportDefaultStorageBehavior:
        return toInteger(this.settingValue);
      case ReportDefaultOutputFormat:
        return toInteger(this.settingValue);
      case ReportAllowUserSettingOverride:
        return toBool(this.settingValue);
      case ReportKeepWordNextToPDF:
        return toBool(this.settingValue);
      case ShowISOElementAds:
        return toBool(this.settingValue);
      case PowerBI:
        return toBool(this.settingValue);
      case PowerBIService:
        return toBool(this.settingValue);
      case LegacyOutlookSeriesEnabled:
        return toBool(this.settingValue);
      default:
        throw new AppError('Setting not found: ' + this.settingName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue(setting: string, value: any) {
    switch (setting) {
      case AutoUserLicense:
        this.settingValue = value.toString();
        break;
      case DefLanguageCode:
        this.settingValue = value.toString();
        break;
      case AuditYearStart:
        this.settingValue = value.toString();
        break;
      case RiskScoreDef:
        this.settingValue = (value as RiskScoreModel).toJSON();
        break;
      case WorkingHours:
        this.settingValue = (value as WorkingHoursPattern).toJSON();
        break;
      case OrgLanguageCodes:
        this.settingValue = JSON.stringify(value);
        break;
      case SharepointLibraries:
        this.settingValue = value.toString();
        break;
      case SharepointLists:
        this.settingValue = value.toString();
        break;
      case SharepointPages:
        this.settingValue = value.toString();
        break;
      case SharepointReports:
        this.settingValue = value.toString();
        break;
      case TeamsKB:
        this.settingValue = value.toString();
        break;
      case TeamsCollaboration:
        this.settingValue = value.toString();
        break;
      case TeamsKBTaskTemplate:
        this.settingValue = value.toString();
        break;
      case ModuleRisks:
        this.settingValue = value.toString();
        break;
      case ModuleOrg:
        this.settingValue = value.toString();
        break;
      case ModuleThemes:
        this.settingValue = value.toString();
        break;
      case ModuleControls:
        this.settingValue = value.toString();
        break;
      case ModuleAssets:
        this.settingValue = value.toString();
        break;
      case ModuleStandards:
        this.settingValue = value.toString();
        break;
      case OutlookTasksAdvanced:
        this.settingValue = value.toString();
        break;
      case OutlookTasksBasic:
        this.settingValue = value.toString();
        break;
      case AzureAD:
        this.settingValue = value.toString();
        break;
      case AzureADGroupSync:
        this.settingValue = value.toString();
        break;
      case AzureDefender:
        this.settingValue = value.toString();
        break;
      case PowerAutomateSharePoint:
        this.settingValue = value.toString();
        break;
      case Office365Defender:
        this.settingValue = value.toString();
        break;
      case LibraryOpenInModal:
        this.settingValue = value.toString();
        break;
      case ReportStorageList:
        this.settingValue = value.toString();
        break;
      case ReportTemplateLink:
        this.settingValue = value.toString();
        break;
      case ReportDefaultStorageBehavior:
        this.settingValue = value.toString();
        break;
      case ReportDefaultOutputFormat:
        this.settingValue = value.toString();
        break;
      case ReportAllowUserSettingOverride:
        this.settingValue = value.toString();
        break;
      case ReportKeepWordNextToPDF:
        this.settingValue = value.toString();
        break;
      case ShowISOElementAds:
        this.settingValue = value.toString();
        break;
      case PowerBI:
        this.settingValue = value.toString();
        break;
      case PowerBIService:
        this.settingValue = value.toString();
        break;
      case LegacyOutlookSeriesEnabled:
        this.settingValue = value.toString();
        break;
      default:
        throw new AppError('Setting not found: ' + setting);
    }
    this.settingName = setting;
  }

  clone(): Setting {
    const newSetting = new Setting();
    newSetting.settingName = this.settingName;
    newSetting.settingValue = this.settingValue;

    return newSetting;
  }
}
