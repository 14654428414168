import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Stack,
  DefaultButton,
  Callout,
  ICalloutContentStyles,
  ChoiceGroup,
  IChoiceGroupOption,
  Separator,
  PrimaryButton,
  CommandBarButton,
  Label,
  DirectionalHint,
  IChoiceGroupOptionProps,
  Dropdown,
  IDropdownOption,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, globalSceneBarItemStyles } from 'globalStyles';
import AppContext from 'App/AppContext';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import {
  addDateDays,
  addDateMonths,
  addDateYears,
  fromApiDateOptional,
  getAuditStart,
  getAuditStartForSetting,
  getCurrentAuditYear,
  getDateTimeDiffDays,
  toApiDateOptional,
  toLocaleDateShort,
} from 'utils/datetime';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { darkTheme, lightTheme } from 'globalThemes';
import { AuditYearStart } from 'models/setting';

export enum PeriodFilterDataOption {
  NotSet = '',
  All = 'All',
  AuditYear = 'AuditYear',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastQuarter = 'LastQuarter',
  LastYear = 'LastYear',
  Custom = 'Custom',
}

export interface IPeriodFilterData {
  option?: PeriodFilterDataOption;
  customStart?: string;
  customEnd?: string;
}

interface IPeriodFilterProps {
  storageKey: LocalStorageKeys;
  label?: string;
  showAll?: boolean;
  onUpdatePeriod: (start: Date, end: Date) => void;
  onUpdatePeriodAll?: () => void;
}

const PeriodFilter = (props: IPeriodFilterProps) => {
  const { t } = useTranslation(['translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [showCallOut, setShowCallOut] = useState<boolean>(false);
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const [selectedOption1, setSelectedOption1] = useState<PeriodFilterDataOption | undefined>(undefined);
  const [selectedOption2, setSelectedOption2] = useState<PeriodFilterDataOption | undefined>(undefined);
  const [customStart, setCustomStart] = useState<Date | undefined>(undefined);
  const [customEnd, setCustomEnd] = useState<Date | undefined>(undefined);
  const [periodStart, setPeriodStart] = useState<Date | undefined>(undefined);
  const [periodEnd, setPeriodEnd] = useState<Date | undefined>(undefined);
  const [selectedAuditYear, setSelectedAuditYear] = useState<number | undefined>(undefined);

  //
  // Effects
  //
  useEffect(() => {
    initFromCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Helpers
  //
  const initFromCache = () => {
    let start: Date | undefined;
    let end: Date | undefined;
    let customStart: Date | undefined;
    let customEnd: Date | undefined;
    let option1: PeriodFilterDataOption = PeriodFilterDataOption.NotSet;
    let option2: PeriodFilterDataOption = PeriodFilterDataOption.NotSet;

    //init last used audit year from storage
    //get the last used audit year
    const auditDateSetting: string = appContext.globalDataCache.settings.get(AuditYearStart);
    const yearFromStorageRaw = getLocalStorageData(appContext, LocalStorageKeys.AuditYear);
    const yearFromStorage = yearFromStorageRaw ? Number.parseInt(yearFromStorageRaw) : undefined;
    const auditYear = yearFromStorage ?? getCurrentAuditYear(auditDateSetting);
    setSelectedAuditYear(auditYear);

    //init period from storage
    const value = getLocalStorageData(appContext, props.storageKey);
    if (value) {
      const data: IPeriodFilterData = JSON.parse(value);

      //determine saved options
      if (
        data.option === PeriodFilterDataOption.AuditYear ||
        data.option === PeriodFilterDataOption.Custom ||
        data.option === PeriodFilterDataOption.All
      ) {
        option2 = data.option;
      } else if (data.option) {
        option1 = data.option;
      } else {
        option2 = PeriodFilterDataOption.AuditYear;
      }

      //always set saved custom dates for when the user selects custom => show the latest saved values
      customStart = fromApiDateOptional(data.customStart);
      customEnd = fromApiDateOptional(data.customEnd);
      setShowCustom(data.option === PeriodFilterDataOption.Custom);
    } else {
      //no value stored yet, create default
      if (props.showAll) {
        option1 = PeriodFilterDataOption.NotSet;
        option2 = PeriodFilterDataOption.All;
      } else {
        option1 = PeriodFilterDataOption.NotSet;
        option2 = PeriodFilterDataOption.AuditYear;
      }
    }

    //set saved/default options
    setSelectedOption1(option1);
    setSelectedOption2(option2);

    //set saved/default custom dates
    if (!customStart) customStart = addDateYears(new Date(), -1);
    if (!customEnd) customEnd = new Date();
    setCustomStart(customStart);
    setCustomEnd(customEnd);

    //calculate period
    start = getStartDate(option1, option2, auditYear, customStart);
    end = getEndDate(option2, auditYear, customEnd);

    //always call onUpdate with the loaded/default settings
    updatePeriod(start, end);
  };

  const updatePeriod = (start: Date | undefined, end: Date | undefined) => {
    if (
      !periodStart ||
      !periodEnd ||
      !start ||
      !end ||
      getDateTimeDiffDays(start, periodStart) !== 0 ||
      getDateTimeDiffDays(end, periodEnd) !== 0
    ) {
      setPeriodStart(start);
      setPeriodEnd(end);
      if (start && end) {
        props.onUpdatePeriod(start, end);
      } else if (props.showAll && props.onUpdatePeriodAll) {
        props.onUpdatePeriodAll();
      }
    }
  };

  const getSummary = (periodStart: Date | undefined, periodEnd: Date | undefined): string => {
    const period = props.label ?? t('translation:PeriodSelector.Period');
    if (!periodStart || !periodEnd) {
      return `${period}: ${t('translation:PeriodSelector.All')}`;
    } else {
      const start = toLocaleDateShort(periodStart);
      const end = toLocaleDateShort(periodEnd);
      const to = t('translation:PeriodSelector.To');

      return `${period}: ${start} ${to} ${end}`;
    }
  };

  const onChange1 = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    setSelectedOption1(option?.key as PeriodFilterDataOption);
    setSelectedOption2(undefined);
    setShowCustom(false);
  };

  const onChange2 = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    setSelectedOption1(undefined);
    setSelectedOption2(option?.key as PeriodFilterDataOption);
    if (option?.key === PeriodFilterDataOption.Custom) {
      setCustomStart(customStart ?? periodStart);
      setCustomEnd(customEnd ?? periodEnd);
      setShowCustom(true);
    } else {
      setShowCustom(false);
    }
  };

  //
  // Audit year selection
  //
  const yearStartsInJan = (auditDateSetting?: string): boolean => {
    if (!auditDateSetting) return false;
    const month = getAuditStartForSetting(1, auditDateSetting).getMonth();

    return month === 0;
  };

  const getYearText = (yearIdx: number, auditDateSetting?: string) => {
    if (yearStartsInJan(auditDateSetting)) {
      return t('tasks:TaskView.Commands.AuditYear.ItemStartInJan', { year: yearIdx });
    } else {
      return t('tasks:TaskView.Commands.AuditYear.Item', { year: yearIdx, nextYear: yearIdx + 1 });
    }
  };

  const getYearItems = (selectedYear: number): IDropdownOption[] => {
    return [
      {
        key: 'header1',
        text: t('tasks:TaskView.Commands.AuditYear.Header1'),
        itemType: SelectableOptionMenuItemType.Header,
      },
      {
        key: 'yearMinus1' + (selectedYear - 1).toString(),
        text: getYearText(selectedYear - 1),
        data: selectedYear - 1,
      },
      {
        key: 'yearCurrent' + selectedYear.toString(),
        text: getYearText(selectedYear),
        data: selectedYear,
      },
      {
        key: 'yearPlus1' + (selectedYear + 1).toString(),
        text: getYearText(selectedYear + 1),
        data: selectedYear + 1,
      },
      {
        key: 'header2',
        text: t('tasks:TaskView.Commands.AuditYear.Header2'),
        itemType: SelectableOptionMenuItemType.Header,
      },
    ];
  };

  const getAuditYearOptions = (selectedYear: number): IDropdownOption[] => {
    const yearMenuProps: IDropdownOption[] = [
      ...getYearItems(selectedYear),
      ...Array.from({ length: 21 }, (_, i) => {
        const yearIdx: number = i + selectedYear - 10;

        return {
          key: 'year' + yearIdx.toString(),
          text: getYearText(yearIdx),
          onClick: () => setSelectedAuditYear(yearIdx),
          data: yearIdx,
        };
      }),
    ];

    return yearMenuProps;
  };

  const onRenderAuditYear = (
    itemProps?: IChoiceGroupOption & IChoiceGroupOptionProps,
    defaultRender?: (props?: IChoiceGroupOption & IChoiceGroupOptionProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (!defaultRender || !selectedAuditYear) return null;
    if (itemProps && itemProps.key === PeriodFilterDataOption.AuditYear && itemProps.checked) {
      return (
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>{defaultRender(itemProps)}</Stack.Item>
          <Stack.Item>
            <Dropdown
              selectedKey={'yearCurrent' + selectedAuditYear}
              options={getAuditYearOptions(selectedAuditYear)}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(ev, option) => setSelectedAuditYear(option?.data)}
            />
          </Stack.Item>
        </Stack>
      );
    } else {
      return defaultRender(itemProps);
    }
  };

  //
  // Calc result
  //
  const getStartDate = (
    option1: PeriodFilterDataOption | undefined,
    option2: PeriodFilterDataOption | undefined,
    selectedAuditYear: number | undefined,
    customStart: Date | undefined,
  ): Date | undefined => {
    let start: Date | undefined = new Date();

    if (option1) {
      switch (option1) {
        case PeriodFilterDataOption.LastWeek:
          start = addDateDays(start, -7);
          break;
        case PeriodFilterDataOption.LastMonth:
          start = addDateMonths(start, -1);
          break;
        case PeriodFilterDataOption.LastQuarter:
          start = addDateMonths(start, -3);
          break;
        case PeriodFilterDataOption.LastYear:
          start = addDateYears(start, -1);
          break;
      }
    } else if (option2) {
      switch (option2) {
        case PeriodFilterDataOption.All:
          start = undefined;
          break;
        case PeriodFilterDataOption.AuditYear:
          start = getAuditStart(appContext, selectedAuditYear);
          break;
        case PeriodFilterDataOption.Custom:
          start = customStart ?? new Date();
          break;
      }
    }

    return start;
  };

  const getEndDate = (
    option2: string | undefined,
    selectedAuditYear: number | undefined,
    customEnd: Date | undefined,
  ): Date | undefined => {
    let end: Date | undefined = new Date();

    switch (option2) {
      case PeriodFilterDataOption.All:
        end = undefined;
        break;
      case PeriodFilterDataOption.AuditYear:
        const start = getAuditStart(appContext, selectedAuditYear);
        end = addDateYears(start, 1);
        break;
      case PeriodFilterDataOption.Custom:
        end = customEnd ?? new Date();
        break;
    }

    return end;
  };

  const onApply = () => {
    const start = getStartDate(selectedOption1, selectedOption2, selectedAuditYear, customStart);
    const end = getEndDate(selectedOption2, selectedAuditYear, customEnd);

    updatePeriod(start, end);
    setShowCallOut(false);

    const data: IPeriodFilterData = {
      option: selectedOption1 || selectedOption2,
      customStart: toApiDateOptional(customStart),
      customEnd: toApiDateOptional(customEnd),
    };

    setLocalStorageData(appContext, props.storageKey, JSON.stringify(data));

    if (selectedAuditYear && selectedOption2 === PeriodFilterDataOption.AuditYear) {
      setLocalStorageData(appContext, LocalStorageKeys.AuditYear, selectedAuditYear.toString());
    }
  };

  //
  // Options
  //
  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: 20,
      minWidth: 350,
      maxWidth: 600,
      background: appContext.useDarkMode ? darkTheme.palette?.white : lightTheme.palette?.white,
    },
  };

  const getOptions1 = (): IChoiceGroupOption[] => {
    return [
      { key: PeriodFilterDataOption.LastWeek, text: t('translation:PeriodSelector.LastWeek') },
      { key: PeriodFilterDataOption.LastMonth, text: t('translation:PeriodSelector.LastMonth') },
      { key: PeriodFilterDataOption.LastQuarter, text: t('translation:PeriodSelector.LastQuarter') },
      { key: PeriodFilterDataOption.LastYear, text: t('translation:PeriodSelector.LastYear') },
    ];
  };

  const getOptions2 = (): IChoiceGroupOption[] => {
    const options: IChoiceGroupOption[] = [];
    if (props.showAll) {
      options.push({ key: PeriodFilterDataOption.All, text: t('translation:PeriodSelector.All') });
    }

    options.push(
      ...[
        {
          key: PeriodFilterDataOption.AuditYear,
          text: t('translation:PeriodSelector.AuditYear'),
          onRenderField: onRenderAuditYear,
        },
        { key: PeriodFilterDataOption.Custom, text: t('translation:PeriodSelector.Custom') },
      ],
    );

    return options;
  };

  //
  // Main render
  //
  return (
    <Fragment>
      <CommandBarButton
        iconProps={{ iconName: 'Calendar' }}
        id="period-selector-callout"
        onClick={() => setShowCallOut(!showCallOut)}
        text={getSummary(periodStart, periodEnd)}
        styles={globalSceneBarItemStyles}
      />
      <Callout
        hidden={!showCallOut}
        styles={calloutStyle}
        setInitialFocus
        gapSpace={0}
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomRightEdge}
        target={'#period-selector-callout'}
        onDismiss={() => {
          setShowCallOut(false);
        }}
      >
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Label>{props.label ?? t('translation:PeriodSelector.Period')}</Label>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <ChoiceGroup selectedKey={selectedOption1} options={getOptions1()} onChange={onChange1} />
              </Stack.Item>
              <Separator vertical />
              <Stack.Item>
                <Stack tokens={globalStackTokensGapSmall}>
                  <ChoiceGroup selectedKey={selectedOption2} options={getOptions2()} onChange={onChange2} />
                  {showCustom && (
                    <Stack tokens={globalStackTokensGapSmall}>
                      <Stack.Item>
                        <LocalizedDatePicker
                          showMonthPickerAsOverlay={false}
                          showWeekNumbers={true}
                          value={customStart}
                          maxDate={periodEnd}
                          onDateChange={(date: Date | undefined) => {
                            if (date) setCustomStart(date);
                          }}
                          format="short"
                          styles={{ root: { minWidth: 150 } }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <LocalizedDatePicker
                          showMonthPickerAsOverlay={false}
                          showWeekNumbers={true}
                          value={customEnd}
                          minDate={periodStart}
                          onDateChange={(date: Date | undefined) => {
                            if (date) setCustomEnd(date);
                          }}
                          format="short"
                          styles={{ root: { minWidth: 150 } }}
                        />
                      </Stack.Item>
                    </Stack>
                  )}
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Separator />
          <Stack horizontal horizontalAlign="end" tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <PrimaryButton text={t('translation:General.Button.Select')} onClick={() => onApply()} />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton text={t('translation:General.Button.Cancel')} onClick={() => setShowCallOut(false)} />
            </Stack.Item>
          </Stack>
        </Stack>
      </Callout>
    </Fragment>
  );
};

export default PeriodFilter;
