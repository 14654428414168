export class ThemeLinkDTO {
  linkId: number;

  themeId: number;

  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    this.linkId = -1;
    this.themeId = -1;
    this.pinned = false;
  }
}
