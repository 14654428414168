import { useContext } from 'react';
import {
  Text,
  Stack,
  IconButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  FontIcon,
  SharedColors,
  ScrollablePane,
  Link,
  Checkbox,
  TooltipHost,
  Separator,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackItemStylesPaddingSceneScroll,
  refreshIcon,
  sharepointIcon,
  globalStackStylesHeight100,
} from 'globalStyles';
import Package, { PackageContent, PackageContentStates } from 'models/package';
import { ISite } from 'services/Graph/SharepointInterfaces';
import AppContext from 'App/AppContext';
import { graphSharepointListRequest } from 'services/Auth/authConfig';
import { EntityTypes } from 'models/entity';
import { ResourceListType } from 'models/resourceList';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { navigateToExternalUrl } from 'utils/url';
import { graphGetSiteRoot } from 'services/Graph/graphServiceSite';
import GenericModal from 'components/Dialogs/GenericModal';

interface IStoreItemSharePointInstallConflictsModalProps {
  pack: Package;
  isOpen: boolean;
  selectedSite: ISite | undefined;
  contents: PackageContent[];
  hasConflicts: boolean;
  skipErrors: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  onRefresh: () => void;
  onSkipErrors: (skip: boolean) => void;
}

const StoreItemSharePointInstallConflictsModal = (props: IStoreItemSharePointInstallConflictsModalProps) => {
  const { t } = useTranslation(['store', 'translation', 'lists']);
  const appContext = useContext(AppContext);

  const onRefresh = () => {
    props.onRefresh();
  };

  const onOpenSharePoint = () => {
    if (!props.selectedSite?.webUrl) return;
    navigateToExternalUrl(props.selectedSite.webUrl, '', '');
  };

  const navigateToMainSharePointUrl = async () => {
    try {
      const graphInterface = await appContext.getGraphInterface(graphSharepointListRequest.scopes);
      const org = await graphGetSiteRoot(graphInterface.client);
      const url = `https://${org.siteCollection?.hostname}/_layouts/15/sharepoint.aspx`;

      navigateToExternalUrl(url, '', '');
    } catch (err) {
      appContext.setError(err);
    }
  };

  const getListTypeName = (content: PackageContent): string => {
    let listType: ResourceListType = ResourceListType.WebURL;

    if (content.sourceEntityType === EntityTypes.Link) {
      const link = props.pack.sharePointLinks.find((l) => l.linkId.toString() === content.sourceEntityId);
      if (link) {
        listType = link.list.listType;
      }
    }

    if (content.sourceEntityType === EntityTypes.List) {
      const list = props.pack.sharePointLists.find((l) => l.listId.toString() === content.sourceEntityId);
      if (list) {
        listType = list.listType;
      }
    }

    if (content.sourceEntityType === EntityTypes.NotSet) {
      listType = ResourceListType.DocumentLibrary;
    }

    let typeName: string = '';

    if (listType === ResourceListType.DocumentLibrary) typeName = t('lists:List.Type.DocumentLibrary');
    if (listType === ResourceListType.CustomList) typeName = t('lists:List.Type.CustomList');
    if (listType === ResourceListType.SitePageLibrary) typeName = t('lists:List.Type.SitePageLibrary');

    if (content.sourceEntityType === EntityTypes.Link || content.sourceEntityType === EntityTypes.NotSet) {
      typeName += ' - Item';
    }

    return typeName;
  };

  const GetContentState = (content: PackageContent): string => {
    switch (content.state) {
      case PackageContentStates.Ok:
        return t('store:TabStore.Install.Dialogs.SharePointConfig.States.Ok');
      case PackageContentStates.NameConflict:
        return t('store:TabStore.Install.Dialogs.SharePointConfig.States.NameConflict');
      case PackageContentStates.ParentHasConflict:
        return t('store:TabStore.Install.Dialogs.SharePointConfig.States.ParentHasConflict');
      default:
        return '';
    }
  };

  const columns: IColumn[] = [
    {
      key: 'type',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      name: t('store:TabStore.Install.Dialogs.SharePointConfig.Columns.Type'),
      onRender: (item?: PackageContent, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{getListTypeName(item)}</Text>;
      },
    },
    {
      key: 'item',
      minWidth: 200,
      maxWidth: 500,
      isResizable: true,
      isMultiline: true,
      name: t('store:TabStore.Install.Dialogs.SharePointConfig.Columns.Item'),
      onRender: (item?: PackageContent, index?: number, column?: IColumn) => {
        if (!item) return;

        if (item.webUrl) {
          return (
            <Text>
              <Link onClick={() => navigateToExternalUrl(item.webUrl || '', '', '', true)}>{item.name}</Link>
            </Text>
          );
        } else {
          return <Text>{item.name}</Text>;
        }
      },
    },
    {
      key: 'state',
      minWidth: 150,
      maxWidth: 150,
      name: t('store:TabStore.Install.Dialogs.SharePointConfig.Columns.State'),
      onRender: (item?: PackageContent, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <FontIcon
                iconName={item.state !== PackageContentStates.Ok ? 'StatusErrorFull' : 'SkypeCircleCheck'}
                style={{ color: item.state !== PackageContentStates.Ok ? SharedColors.red20 : SharedColors.green20 }}
              />
            </Stack.Item>
            <Stack.Item>
              <Text>{GetContentState(item)}</Text>
            </Stack.Item>
          </Stack>
        );
      },
    },
  ];

  //
  // Main render
  //
  return (
    <GenericModal
      onClose={props.onDismiss}
      isOpen={props.isOpen}
      title={props.selectedSite?.displayName ?? ''}
      height={'70vh'}
      width={800}
      minWidth={320}
      maxWidth={'90vw'}
    >
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Separator />
        <Stack.Item>
          <Text>{t('store:TabStore.Install.Dialogs.SharePointConfig.ConflictModalInfo')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Stack.Item>
              <Checkbox
                className="redlab-usetiful-store-install-skiperrors"
                disabled={!props.hasConflicts || props.isLoading}
                checked={props.skipErrors}
                onChange={(ev, checked) => props.onSkipErrors(checked ?? false)}
                label={t('store:TabStore.Install.Dialogs.SharePointConfig.SkipErrors')}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <TooltipHost content={t('store:TabStore.Install.Dialogs.SharePointConfig.OpenSharePoint')}>
                    <IconButton
                      iconProps={sharepointIcon}
                      onClick={props.selectedSite ? onOpenSharePoint : navigateToMainSharePointUrl}
                      disabled={props.isLoading}
                    />
                  </TooltipHost>
                </Stack.Item>
                <Stack.Item>
                  <TooltipHost content={t('store:TabStore.Install.Dialogs.SharePointConfig.Refresh')}>
                    <IconButton iconProps={refreshIcon} onClick={onRefresh} disabled={props.isLoading} />
                  </TooltipHost>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {props.isLoading && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Spinner size={SpinnerSize.large} />
          </Stack>
        )}
        {!props.isLoading && (
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            <Stack.Item grow>
              <Stack verticalFill>
                <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
                  <ScrollablePane>
                    <DetailsList
                      compact
                      items={props.contents}
                      columns={columns}
                      selectionMode={SelectionMode.none}
                      isHeaderVisible={true}
                      layoutMode={DetailsListLayoutMode.justified}
                      onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
                    />
                  </ScrollablePane>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        )}
      </Stack>
    </GenericModal>
  );
};

export default StoreItemSharePointInstallConflictsModal;
