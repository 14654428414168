export class ProcessThemeDTO {
  processId: number;

  themeId: number;

  constructor(processId?: number, themeId?: number) {
    this.processId = processId ?? -1;
    this.themeId = themeId ?? -1;
  }
}
