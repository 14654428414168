import { FontIcon, Label, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapExtraSmall, globalStackTokensGapMedium } from 'globalStyles';
import { useContext } from 'react';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import { TaskTagList } from 'components/Tasks/TaskTagList';
import Tag from 'models/tag';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';

interface ITaskDetailTagsProps {
  orgTask: Task;
  task: Task;
  tags: Tag[];
  isActionPending: boolean;
  canUpdate: boolean;
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
}

export const TaskDetailTags = (props: ITaskDetailTagsProps) => {
  const { t } = useTranslation(['tags', 'translation']);
  const appContext = useContext(AppContext);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Tags, appContext)

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="center"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace / 2 } } : undefined}
    >
      <Stack.Item>{!appContext.isMobileView && <FontIcon iconName="Tag" style={iconFontSize} />}</Stack.Item>
      <Stack.Item grow>
        {props.tags.length === 0 && isReadonly && <Label>{t('tags:General.NoData')}</Label>}
        {(props.tags.length !== 0 || !isReadonly) && (
          <TaskTagList
            tags={props.tags}
            isLoading={props.isActionPending}
            addTagToTaskState={props.addTagToTaskState}
            removeTagFromTaskState={props.removeTagFromTaskState}
            readOnly={isReadonly}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};
