import LanguageDTO from '../languageDTO';

export class TaskTypeFormElement_TranslationDTO {
  taskTypeId: number;

  fieldId: number;

  languageId: number;

  name: string;

  description?: string;

  expectedResult?: string;

  instruction?: string;

  lang?: LanguageDTO;

  constructor() {
    this.taskTypeId = -1;
    this.fieldId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
