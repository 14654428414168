import { useContext, useState } from 'react';
import { IImageProps, ImageFit, Image, Text } from '@fluentui/react';
import { Stack, IStackStyles } from '@fluentui/react';
import AppContext from 'App/AppContext';
import AuthNavItem from './AuthNavItem';
import SettingsNavItem from './SettingsNavItem';
import HelpNavItem from './HelpNavItem';
import { darkTheme, lightTheme } from '../../globalThemes';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import MobileNavRoot from '../NavMain/MobileNavRoot';
import { globalAppName, globalNavBarHeight } from 'globalConstants';
import Tenant from 'models/tenant';
import { hasUserFeature, FeatureTypes } from 'services/Auth/featurePermissions';

interface INavBarProps {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  switchOrg: (tenant: Tenant) => Promise<void>;
  switchOrgUnit: (tenant: Tenant) => Promise<void>;
}

const GetStackStyles = (useDarkMode: boolean) => {
  const stackStyles: IStackStyles = {
    root: {
      background: useDarkMode ? darkTheme.palette?.neutralLight : lightTheme.palette?.themeLight,
      height: globalNavBarHeight,
    },
  };

  return stackStyles;
};

const MobileNavBar = (props: INavBarProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);

  const [isOpenAuthDialog, setIsOpenAuthDialog] = useState<boolean>(false);
  const [isOpenSettingsPanel, setIsOpenSettingsPanel] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string>('');

  const toggleAuthDialog = () => {
    setIsOpenAuthDialog(!isOpenAuthDialog);
  };

  const toggleSettingsPanel = () => {
    setIsOpenSettingsPanel(!isOpenSettingsPanel);
    setIsOpenAuthDialog(false);

    if (!isOpenSettingsPanel) {
      appContext.setHelpPanel(false);
    }
  };

  const toggleHelpPanel = () => {
    setIsOpenSettingsPanel(!appContext.isOpenHelpPanel ? false : isOpenSettingsPanel);
    setIsOpenAuthDialog(false);
    appContext.setHelpPanel(!appContext.isOpenHelpPanel);
  };

  const getTenantLogoProps = (logo: Blob): IImageProps => {
    let url = logoUrl;
    if (!url) {
      url = URL.createObjectURL(logo);
      setLogoUrl(url);
    }

    return {
      src: url,
      imageFit: ImageFit.contain,
      height: globalNavBarHeight,
    };
  };

  return (
    <Stack horizontal styles={GetStackStyles(appContext.useDarkMode)} tokens={globalStackTokensGapSmall}>
      <Stack.Item grow>
        <Stack horizontal verticalAlign="center">
          <Stack.Item>
            <MobileNavRoot />
          </Stack.Item>
          {hasUserFeature(appContext, FeatureTypes.CustomLogo) && appContext.user.tenant.appLogo && (
            <Stack.Item
              styles={{ root: { maxHeight: globalNavBarHeight, maxWidth: globalNavBarHeight * 3, overflow: 'hidden' } }}
            >
              <Image {...getTenantLogoProps(appContext.user.tenant.appLogo)} />
            </Stack.Item>
          )}
          <Stack.Item verticalFill>
            <Text nowrap styles={globalTextStylesBold} variant="large">
              {t('translation:App.Title', { appName: globalAppName })}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal horizontalAlign="end">
          {appContext.isAuthenticated && (
            <Stack.Item>
              <SettingsNavItem togglePanel={toggleSettingsPanel} isOpen={isOpenSettingsPanel}></SettingsNavItem>
            </Stack.Item>
          )}
          <Stack.Item>
            <HelpNavItem togglePanel={toggleHelpPanel} isOpen={appContext.isOpenHelpPanel}></HelpNavItem>
          </Stack.Item>
          <Stack.Item>
            <AuthNavItem
              login={props.login}
              logout={props.logout}
              toggleDialog={toggleAuthDialog}
              isOpen={isOpenAuthDialog}
              switchOrg={props.switchOrg}
              switchOrgUnit={props.switchOrgUnit}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default MobileNavBar;
