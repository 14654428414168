import {
  graphSharepointLibraryRequest,
  graphSharepointListRequest,
  graphSharepointManageRequest,
  graphSharepointPagesRequest,
} from 'services/Auth/authConfig';
import { graphGetDrivesForSite, graphCheckWriteAccessInDocumentLibrary } from 'services/Graph/graphServiceDrive';
import { graphGetAllListsForSite } from 'services/Graph/graphServiceList';
import { graphCreateSitePage, graphDeleteSitePage, graphGetPagesForSite } from 'services/Graph/graphServicePage';
import { HorizontalSectionLayoutType, ISite, ISitePage } from 'services/Graph/SharepointInterfaces';
import { IAppContext } from 'App/AppContext';
import { newGuid } from 'utils/guid';

export enum TestIntegrationStatus {
  NotRun = 0,
  Succeeded = 1,
  Failed = 2,
}

export enum TestIntegrationType {
  Manager = 1,
  Lists = 2,
  Pages = 3,
  Libraries = 4,
}

export interface ITestIntegration {
  id: TestIntegrationType;
  name: string;
  permissions: string;
  status: TestIntegrationStatus;
  enabled: boolean;
  test: (appContext: IAppContext, site: ISite) => Promise<boolean>;
}

export const getTests = (names: Record<number, string>): ITestIntegration[] => {
  const integrationCol: ITestIntegration[] = [];

  if (names[TestIntegrationType.Manager]) {
    const integrationSharePointManager: ITestIntegration = {
      id: TestIntegrationType.Manager,
      name: names[TestIntegrationType.Manager],
      permissions: 'Sites.Manage.All',
      status: TestIntegrationStatus.NotRun,
      enabled: true,
      test: testSpManager,
    };
    integrationCol.push(integrationSharePointManager);
  }

  if (names[TestIntegrationType.Lists]) {
    const integrationSharePointList: ITestIntegration = {
      id: TestIntegrationType.Lists,
      name: names[TestIntegrationType.Lists],
      permissions: 'Sites.Read.All',
      status: TestIntegrationStatus.NotRun,
      enabled: true,
      test: testSpLists,
    };
    integrationCol.push(integrationSharePointList);
  }

  if (names[TestIntegrationType.Pages]) {
    const integrationSharePointPages: ITestIntegration = {
      id: TestIntegrationType.Pages,
      name: names[TestIntegrationType.Pages],
      permissions: 'Sites.Read.All',
      status: TestIntegrationStatus.NotRun,
      enabled: true,
      test: testSpPages,
    };
    integrationCol.push(integrationSharePointPages);
  }

  if (names[TestIntegrationType.Libraries]) {
    const integrationSharePointLibrary: ITestIntegration = {
      id: TestIntegrationType.Libraries,
      name: names[TestIntegrationType.Libraries],
      permissions: 'Files.ReadWrite.All',
      status: TestIntegrationStatus.NotRun,
      enabled: true,
      test: testSpLibrary,
    };
    integrationCol.push(integrationSharePointLibrary);
  }

  return integrationCol;
};

//
// Tests
//
const testSpManager = async (appContext: IAppContext, site: ISite): Promise<boolean> => {
  //test whether we can create and delete a dummy page in the site
  try {
    if (site && site.id) {
      const graphInterface = await appContext.getGraphInterface(graphSharepointManageRequest.scopes, site.tenantId);
      const dummyName = newGuid() + '.aspx';

      //dummy page
      const page: ISitePage = {
        name: dummyName,
        title: 'test',
        webUrl: '',
        publishingState: {
          level: undefined,
          versionId: undefined,
        },
        pageLayout: 'article',
        canvasLayout: {
          horizontalSections: [
            {
              id: '1',
              layout: HorizontalSectionLayoutType.oneColumn,
              columns: [
                {
                  id: '1',
                  webparts: [
                    {
                      '@odata.type': '#microsoft.graph.textWebPart',
                      innerHtml: '<p>test</p>',
                    },
                  ],
                },
              ],
            },
          ],
        },
      };

      const newPage = await graphCreateSitePage(graphInterface.client, site.id, page);
      if (newPage && newPage.id) {
        await graphDeleteSitePage(graphInterface.client, site.id, newPage.id);
      }

      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

const testSpLists = async (appContext: IAppContext, site: ISite): Promise<boolean> => {
  //test whether we can read all lists from a random site
  try {
    if (site && site.id) {
      const graphInterface = await appContext.getGraphInterface(graphSharepointListRequest.scopes, site.tenantId);
      await graphGetAllListsForSite(graphInterface.client, site.id);

      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

const testSpPages = async (appContext: IAppContext, site: ISite): Promise<boolean> => {
  //test wheter we can read all pages from the root site
  try {
    if (site && site.id) {
      const graphInterface = await appContext.getGraphInterface(graphSharepointPagesRequest.scopes, site.tenantId);
      await graphGetPagesForSite(graphInterface.client, site.id, false);

      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

const testSpLibrary = async (appContext: IAppContext, site: ISite): Promise<boolean> => {
  //test whether we are able to create a file and delete a file again
  try {
    if (site && site.id) {
      const graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes, site.tenantId);
      const drives = await graphGetDrivesForSite(graphInterface.client, site.id);
      if (drives && drives.length > 0 && drives[0].id) {
        const result = await graphCheckWriteAccessInDocumentLibrary(graphInterface.client, drives[0].id);
        if (result) return true;
      }
    }

    return false;
  } catch (err) {
    return false;
  }
};
