import { FunctionComponent, useContext, useState } from 'react';
import {
  Stack,
  Spinner,
  SpinnerSize,
  Text,
  Link,
  Label,
  Persona,
  PersonaSize,
  TooltipHost,
  DirectionalHint,
  Checkbox,
} from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { overflow } from 'utils/string';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import EntityPicker from 'components/Pickers/EntityPicker';
import Entity, { EntityTypes } from 'models/entity';
import { apiGetSingleTask, apiGetTaskInstancesByDate, apiGetTasks } from 'services/Api/taskService';
import Task from 'models/tasks/task';
import SingleTask from 'components/Tasks/SingleTask';
import { TaskDateLinkWithHoverCard } from 'components/Tasks/View/TaskDateLinkWithHoverCard';
import { addDateYears, toLocaleDateTimeMedium } from 'utils/datetime';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { TaskViewScheduleLevel } from 'components/Tasks/View/TasksView';

export class WidgetSingleTaskConfig {
  taskId: number;

  showBasics: boolean;

  showDescription: boolean;

  showSchedule: boolean;

  constructor() {
    this.taskId = 0;
    this.showBasics = true;
    this.showDescription = true;
    this.showSchedule = true;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.taskId = newRawConfig.taskId ?? 0;
        this.showBasics = newRawConfig.showBasics ?? false;
        this.showDescription = newRawConfig.showDescription ?? false;
        this.showSchedule = newRawConfig.showSchedule ?? false;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetSingleTaskConfig {
    const newConfig = new WidgetSingleTaskConfig();
    newConfig.taskId = this.taskId;
    newConfig.showBasics = this.showBasics;
    newConfig.showDescription = this.showDescription;
    newConfig.showSchedule = this.showSchedule;

    return newConfig;
  }
}

interface IWidgetSingleTaskProps extends IWidgetRendererProps {}

const WidgetSingleTask: FunctionComponent<IWidgetSingleTaskProps> = (props: IWidgetSingleTaskProps) => {
  const { t } = useTranslation(['task', 'translation', 'control']);
  const appContext = React.useContext(AppContext);
  const [task, setTask] = React.useState<Task | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [taskInstances, setTaskInstances] = React.useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);
  const [config, setConfig] = useState<WidgetSingleTaskConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadConfig = (): WidgetSingleTaskConfig => {
      const newConfig = new WidgetSingleTaskConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);
        setTask(undefined);
        setConfig(undefined);

        const config = loadConfig();
        setConfig(config);

        if (config && config.taskId) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const taskCol = await apiGetSingleTask(config.taskId, true, accessToken, appContext.globalDataCache);
          if (taskCol && taskCol.tasks.length > 0) {
            const task = taskCol.tasks[0];
            setTask(task);
            if (task.isSeries()) {
              //get all instances in the current year
              const start = new Date();
              start.setMonth(0);
              start.setDate(0);
              const end = addDateYears(start, 1);
              const tasks = await apiGetTaskInstancesByDate(
                task.taskId,
                start,
                end,
                12,
                accessToken,
                appContext.globalDataCache,
              );
              setTaskInstances(tasks);
            }
          }
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const saveTask = (updatedTask: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled && !isNew) {
      if (task && task.taskId === updatedTask.taskId) {
        setTask(updatedTask);
      }
      const newInstances = taskInstances.map((i) => (i.taskId === updatedTask.taskId ? updatedTask : i));
      setTaskInstances(newInstances);
    }
  };

  const removeTask = (task: Task) => {
    setTaskInstances(taskInstances.filter((t) => t.taskId !== task.taskId));
    setSelectedTask(undefined);
  };

  const updateTask = async (task: Task) => {
    setSelectedTask(undefined);
  };

  const openTask = (task: Task) => {
    setSelectedTask(task);
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!task) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:SingleTask.Config.Invalid')}</Text>
      </Stack>
    );
  }

  const onRenderPrimaryText = () => {
    return (
      <Text>
        <Link onClick={() => openTask(task)}>{overflow(task.name, 80)}</Link>
      </Text>
    );
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      {config && config.showBasics && (
        <Stack.Item>
          <TooltipHost
            directionalHint={DirectionalHint.bottomLeftEdge}
            content={task.isAssigned() ? appContext.globalDataCache.users.get(task.userId).name : undefined}
          >
            <Persona
              size={PersonaSize.size40}
              text={appContext.globalDataCache.users.get(task.userId).name}
              onRenderPrimaryText={onRenderPrimaryText}
              secondaryText={
                task.isSeries()
                  ? task.recurringPattern.getShortSummary()
                  : appContext.globalDataCache.taskStates.get(task.taskStateId).state
              }
            />
          </TooltipHost>
        </Stack.Item>
      )}
      {config && config.showDescription && (
        <Stack.Item>
          <Text>{overflow(task.description, 200)}</Text>
        </Stack.Item>
      )}
      {config && config.showSchedule && task.isSeries() && (
        <ScrollableStackItem isOnPanel={false}>
          {taskInstances.map((task: Task) => {
            return (
              <TaskDateLinkWithHoverCard
                key={task.taskId}
                task={task}
                onTaskClick={openTask}
                level={TaskViewScheduleLevel.Month}
              />
            );
          })}
        </ScrollableStackItem>
      )}
      {config && config.showSchedule && !task.isSeries() && (
        <Stack.Item grow>
          <Label>{t('widgets:SingleTask.Start')}</Label>
          <Text>{toLocaleDateTimeMedium(task.startDateTime)}</Text>
          <Label>{t('widgets:SingleTask.Deadline')}</Label>
          <Text>{toLocaleDateTimeMedium(task.deadline)}</Text>
        </Stack.Item>
      )}
      {selectedTask && (
        <SingleTask
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          task={selectedTask}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
    </Stack>
  );
};

export default WidgetSingleTask;

//
// Config
//

interface IWidgetConfigSingleTaskProps extends IWidgetConfigRendererProps {}

export const WidgetConfigSingleTask: FunctionComponent<IWidgetConfigSingleTaskProps> = (
  props: IWidgetConfigSingleTaskProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskEntities, setTaskEntities] = useState<Entity[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(undefined);

  const loadConfig = (): WidgetSingleTaskConfig => {
    const newConfig = new WidgetSingleTaskConfig();
    newConfig.load(props.widget.widgetConfig);

    return newConfig;
  };

  const [config, setConfig] = useState<WidgetSingleTaskConfig>(loadConfig());

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const taskCol = await apiGetTasks(false, undefined, undefined, accessToken, appContext.globalDataCache);

      const entities = getEntitiesFromTasks(taskCol.tasks);
      setTaskEntities(entities);

      if (config.taskId) {
        const entity = entities.find((e) => e.entityId === config.taskId);
        setSelectedEntity(entity);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getEntitiesFromTasks = (tasks: Task[]): Entity[] => {
    const entities: Entity[] = [];

    entities.push(
      ...tasks.map((c) => {
        const newEntity = new Entity();
        newEntity.entityId = c.taskId;
        newEntity.typeOfEntity = EntityTypes.Task;
        newEntity.entityName = c.name;

        return newEntity;
      }),
    );

    return entities;
  };

  const isConfigValid = (config: WidgetSingleTaskConfig): boolean => {
    if (config.taskId <= 0) return false;

    return true;
  };

  const updateConfig = (config: WidgetSingleTaskConfig) => {
    const newConfig = config.clone();
    setConfig(newConfig);
    props.onUpdateConfig(JSON.stringify(newConfig), isConfigValid(newConfig));
  };

  const onAddSelectedEntity = (entity: Entity) => {
    setSelectedEntity(entity);
    config.taskId = entity.entityId;
    updateConfig(config);
  };

  const onRemoveSelectedEntity = () => {
    setSelectedEntity(undefined);
    config.taskId = 0;
    updateConfig(config);
  };

  const setShowBasics = (checked: boolean | undefined) => {
    if (checked === undefined) return;
    config.showBasics = checked;
    updateConfig(config);
  };

  const setShowDescription = (checked: boolean | undefined) => {
    if (checked === undefined) return;
    config.showDescription = checked;
    updateConfig(config);
  };

  const setShowSchedule = (checked: boolean | undefined) => {
    if (checked === undefined) return;
    config.showSchedule = checked;
    updateConfig(config);
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Checkbox
          disabled={isLoading}
          label={t('widgets:SingleTask.Config.ShowBasics.Label')}
          checked={config.showBasics}
          onChange={(ev, checked) => setShowBasics(checked)}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          disabled={isLoading}
          label={t('widgets:SingleTask.Config.ShowDescription.Label')}
          checked={config.showDescription}
          onChange={(ev, checked) => setShowDescription(checked)}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          disabled={isLoading}
          label={t('widgets:SingleTask.Config.ShowSchedule.Label')}
          checked={config.showSchedule}
          onChange={(ev, checked) => setShowSchedule(checked)}
        />
      </Stack.Item>
      <Stack.Item>
        <Label>{t('widgets:SingleTask.Config.Task.Label')}</Label>
        <EntityPicker
          entities={taskEntities}
          isLoading={isLoading}
          addSelectedEntity={onAddSelectedEntity}
          clearSearchText={onRemoveSelectedEntity}
          loadData={loadData}
          setSearchTextToSelectedEntity={true}
          selectedEntity={selectedEntity}
          isOnPanel={true}
        ></EntityPicker>
      </Stack.Item>
    </Stack>
  );
};
