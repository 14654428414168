import { appRoles } from "services/Auth/appRoles";
import { Role_TranslationDTO } from "./role_TranslationDTO";
import IdListDTO from "../IdListDTO";

export class RoleDTO {
    roleId: string;

    systemRoleId?: appRoles;
  
    linkId?: number;

    userIds?: IdListDTO;

    groupIds?: IdListDTO;

    trans: Role_TranslationDTO[];

    constructor() {
      this.roleId = '';
      this.trans = [];
    }
  }