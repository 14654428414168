import { FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Text, Link, Label } from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { overflow } from 'utils/string';
import { apiGetRiskControlsTask, apiGetRisks } from 'services/Api/riskService';
import { FluentUIPieChart } from 'components/Charts/PieChart';
import { RiskControlTasks } from 'models/riskControlTasks';
import { IPieChartDataPoint } from 'components/Charts/PieChart';
import { globalStackTokensGapSmall } from 'globalStyles';
import { PDCAState } from 'models/pdca';
import { useTranslation } from 'react-i18next';
import Control from 'models/control';
import { useHistory } from 'react-router-dom';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import EntityPicker from 'components/Pickers/EntityPicker';
import Entity, { EntityTypes } from 'models/entity';
import Risk from 'models/risk';
import ListRiskScore from 'components/Risk/ListRiskScore';
import RiskScoreModel from 'models/RiskScoreModel';
import { RiskScoreDef } from 'models/setting';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetSingleRiskConfig {
  riskId: number;

  constructor() {
    this.riskId = 0;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.riskId = newRawConfig.riskId ?? 0;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetSingleRiskConfig {
    const newConfig = new WidgetSingleRiskConfig();
    newConfig.riskId = this.riskId;

    return newConfig;
  }
}

interface IWidgetSingleRiskProps extends IWidgetRendererProps {}

const WidgetSingleRisk: FunctionComponent<IWidgetSingleRiskProps> = (props: IWidgetSingleRiskProps) => {
  const { t } = useTranslation(['control', 'controlRisks']);
  const history = useHistory();
  const appContext = React.useContext(AppContext);
  const [riskControlTasks, setRiskControlTasks] = React.useState<RiskControlTasks | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IPieChartDataPoint[]>([]);
  const riskModel: RiskScoreModel | undefined = appContext.globalDataCache.settings.get(RiskScoreDef) as RiskScoreModel;

  React.useEffect(() => {
    const loadConfig = (): WidgetSingleRiskConfig => {
      let newConfig = new WidgetSingleRiskConfig();
      newConfig.load(props.widget.widgetConfig);

      return newConfig;
    };

    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        const config = loadConfig();

        if (config && config.riskId) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const riskAndControls = await apiGetRiskControlsTask(config.riskId, accessToken, appContext.globalDataCache);
          setRiskControlTasks(riskAndControls);
          setData(getRiskChartData(riskAndControls));
        }
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const getRiskChartData = (riskControlTasks: RiskControlTasks): IPieChartDataPoint[] => {
    let data: IPieChartDataPoint[] = [];

    if (!riskControlTasks || !riskControlTasks.risk) return data;

    if (riskControlTasks && riskControlTasks.risk.controls) {
      const item1: IPieChartDataPoint = {
        name: Control.getControlStateText(PDCAState.Plan, t),
        value: riskControlTasks.risk.controls.filter((c) => c.state === PDCAState.Plan).length,
        color: '#ad2b41',
      };
      const item2: IPieChartDataPoint = {
        name: Control.getControlStateText(PDCAState.Do, t),
        value: riskControlTasks.risk.controls.filter((c) => c.state === PDCAState.Do).length,
        color: '#5a942b',
      };
      const item3: IPieChartDataPoint = {
        name: Control.getControlStateText(PDCAState.Check, t),
        value: riskControlTasks.risk.controls.filter((c) => c.state === PDCAState.Check).length,
        color: '#dade62',
      };
      const item4: IPieChartDataPoint = {
        name: Control.getControlStateText(PDCAState.Act, t),
        value: riskControlTasks.risk.controls.filter((c) => c.state === PDCAState.Act).length,
        color: '#62a4de',
      };

      if (item1.value > 0) data.push(item1);
      if (item2.value > 0) data.push(item2);
      if (item3.value > 0) data.push(item3);
      if (item4.value > 0) data.push(item4);
    }

    return data;
  };

  const navigateToRisk = () => {
    if (!riskControlTasks || !riskControlTasks.risk) return;

    const url = `/risk/${riskControlTasks?.risk.riskId}`;
    history.push(url);
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!riskControlTasks || !riskControlTasks.risk) {
    //config is invalid
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:SingleRisk.Config.Invalid')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Text>
          <Link onClick={() => navigateToRisk()}>{overflow(riskControlTasks.risk.name, 80)}</Link>
        </Text>
      </Stack.Item>
      <Stack.Item grow>
        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
          {data.length > 0 && (
            <FluentUIPieChart
              width={280}
              height={150}
              label={riskControlTasks?.controlTaskStats.length.toString()}
              data={data}
            />
          )}
          {data.length === 0 && <Text>{t('widgets:SingleRisk.NoControls')}</Text>}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal horizontalAlign="center" tokens={globalStackTokensGapSmall}>
          <Text>{t('widgets:SingleRisk.Status')}</Text>
          {riskControlTasks.risk.getCurrentScore() > 0 && (
            <ListRiskScore riskModel={riskModel} risk={riskControlTasks.risk} timeline="current" />
          )}
          <Text>{appContext.globalDataCache.riskStates.get(riskControlTasks.risk.riskStateId).state}</Text>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default WidgetSingleRisk;

//
// Config
//

interface IWidgetConfigSingleRiskProps extends IWidgetConfigRendererProps {}

export const WidgetConfigSingleRisk: FunctionComponent<IWidgetConfigSingleRiskProps> = (
  props: IWidgetConfigSingleRiskProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [riskEntities, setRiskEntities] = useState<Entity[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetSingleRiskConfig => {
    let newRawConfig = new WidgetSingleRiskConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const config = loadConfig();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const riskCol = await apiGetRisks(accessToken, appContext.globalDataCache);

      const entities = getEntitiesFromRisks(riskCol.risks);
      setRiskEntities(entities);

      if (config.riskId) {
        const entity = entities.find((e) => e.entityId === config.riskId);
        setSelectedEntity(entity);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getEntitiesFromRisks = (risks: Risk[]): Entity[] => {
    const entities: Entity[] = [];

    entities.push(
      ...risks.map((c) => {
        const newEntity = new Entity();
        newEntity.entityId = c.riskId;
        newEntity.typeOfEntity = EntityTypes.Risk;
        newEntity.entityName = c.name;
        newEntity.entityCode = c.code;

        return newEntity;
      }),
    );

    return entities;
  };

  const getConfig = (entity: Entity): WidgetSingleRiskConfig => {
    const config = new WidgetSingleRiskConfig();
    config.riskId = entity.entityId;

    return config;
  };

  const onAddSelectedEntity = (entity: Entity) => {
    setSelectedEntity(entity);
    const config = getConfig(entity);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onRemoveSelectedEntity = () => {
    setSelectedEntity(undefined);
    const config = new WidgetSingleRiskConfig();
    props.onUpdateConfig(JSON.stringify(config), false);
  };

  return (
    <Stack verticalFill>
      <Stack.Item>
        <Label>{t('widgets:SingleRisk.Config.Risk.Label')}</Label>
        <EntityPicker
          entities={riskEntities}
          isLoading={isLoading}
          addSelectedEntity={onAddSelectedEntity}
          clearSearchText={onRemoveSelectedEntity}
          loadData={loadData}
          setSearchTextToSelectedEntity={true}
          selectedEntity={selectedEntity}
          isOnPanel={true}
        ></EntityPicker>
      </Stack.Item>
    </Stack>
  );
};
