import React, { Fragment, useContext } from 'react';
import { Stack, FontIcon, Label, ScrollablePane, ScrollbarVisibility, Checkbox } from '@fluentui/react';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackTokensGapExtraSmall,
  globalStackItemStylesPaddingSceneScroll,
  userIcon,
} from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import User from 'models/user';
import MultiSelectUserPicker from 'components/Pickers/MultiSelectUserPicker';
import AuthSchemaPicker from 'components/Pickers/AuthSchemaPicker';
import { TaskDetailName } from '../Details/TaskDetailName';
import { TaskDetailTags } from '../Details/TaskDetailTags';
import { TaskDetailDescription } from '../Details/TaskDetailDecription';
import { TaskDetailStatusActive } from '../Details/TaskDetailStatusActive';
import { newGuid, newGuidNil } from 'utils/guid';

interface IApprovalTemplateTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  windowLevel?: number;
  canUpdate: boolean;
}

export const ApprovalTemplateTaskDetail = (props: IApprovalTemplateTaskDetail) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation']);

  const iconFontSize = { fontSize: '20px', marginTop: '5px' };

  //
  // Workflow steps
  //
  const getUsersFromInstances = (): User[] => {
    const userIds = props.task.instances?.filter((i) => i.userId).map((i) => i.userId as string);
    if (userIds) {
      return appContext.globalDataCache.users.getItemsForId(userIds);
    } else {
      return [];
    }
  };

  const createInstancesForUsers = (users: User[] | undefined) => {
    let newInstances: Task[] = props.task.instances ? [...props.task.instances] : [];

    if (users) {
      for (let idx = 0; idx < users.length; idx++) {
        const user = users[idx];
        if (!newInstances.some((i) => i.userId === user.id)) {
          //create a new instance for this user
          const newSubTask = new Task();
          newSubTask.id = newGuid();
          newSubTask.name = user.name;
          newSubTask.taskType = TaskTypes.EventTemplate;
          newSubTask.taskTypeId = props.task.taskTypeId;
          newSubTask.taskMasterId = props.task.taskId;
          newSubTask.taskStates = appContext.globalDataCache.taskStates.items;
          newSubTask.taskStateId = newSubTask.getFirstState();
          newSubTask.userId = user.id;
          newSubTask.user = user;
          newSubTask.creatorId = user.id;
          newSubTask.creator = user;
          newSubTask.ownerId = user.id;
          newSubTask.owner = user;
          newInstances.push(newSubTask);
        }
      }
    }

    //remove instances for deleted users
    const instancesToRemove: string[] = [];

    if (users) {
      for (let idx = 0; idx < newInstances.length; idx++) {
        const instance = newInstances[idx];
        if (instance.id && instance.userId !== undefined && !users.some((u) => u.id === instance.userId)) {
          instancesToRemove.push(instance.id);
        }
      }
      for (let idx = 0; idx < instancesToRemove.length; idx++) {
        const idxToRemove = newInstances.findIndex((i) => i.id === instancesToRemove[idx]);
        newInstances.splice(idxToRemove, 1);
      }
    } else {
      //remove all instances
      newInstances = [];
    }

    const newTask = props.task.clone();
    newTask.instances = newInstances;
    props.onUpdate(newTask);
  };

  const setInstanceForOwner = (enabled: boolean) => {
    let newInstances: Task[] = props.task.instances ? [...props.task.instances] : [];

    if (enabled && !newInstances.some((i) => i.userId === newGuidNil())) {
      //create a new instance for an owner
      //this is indicated with an unassigned instance
      const newSubTask = new Task();
      newSubTask.name = 'Owner';
      newSubTask.taskType = TaskTypes.EventTemplate;
      newSubTask.taskTypeId = props.task.taskTypeId;
      newSubTask.taskMasterId = props.task.taskId;
      newSubTask.taskStates = appContext.globalDataCache.taskStates.items;
      newSubTask.taskStateId = newSubTask.getFirstState();
      newSubTask.userId = undefined;
      newSubTask.creatorId = props.task.creatorId;
      newSubTask.creator = props.task.creator;
      newSubTask.ownerId = props.task.ownerId;
      newSubTask.owner = props.task.owner;
      newInstances.push(newSubTask);
    } else if (!enabled) {
      newInstances = newInstances.filter((i) => i.userId !== undefined);
    }

    const newTask = props.task.clone();
    newTask.instances = newInstances;
    props.onUpdate(newTask);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <TaskDetailDescription
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
            <Fragment>
              <Stack
                horizontal
                tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                verticalAlign="start"
              >
                <Stack.Item>
                  {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
                </Stack.Item>
                <Stack.Item>
                  <AuthSchemaPicker
                    selectedAuthSchemaId={props.task.authSchemaId}
                    onSelect={(schema) => {
                      const copyTask = props.task.clone();
                      copyTask.authSchemaId = schema?.authSchemaId;
                      props.onUpdate(copyTask);
                    }}
                  />
                </Stack.Item>
                <TaskDetailStatusActive
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  checkOnly={true}
                />
              </Stack>
              <Stack.Item>
                <Stack
                  horizontal
                  tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                  verticalAlign="start"
                >
                  <Stack.Item>
                    {!appContext.isMobileView && <FontIcon iconName={userIcon.iconName} style={iconFontSize} />}
                  </Stack.Item>
                  <Stack.Item grow>
                    <Stack tokens={globalStackTokensGapSmall}>
                      <Stack>
                        <Label>{t('task:TaskTypes.ApprovalTemplate.Approvers')}</Label>
                        <MultiSelectUserPicker
                          users={[]}
                          itemLimit={5}
                          selectedUsers={getUsersFromInstances()}
                          onMultipleSelect={(items: User[] | undefined) => {
                            createInstancesForUsers(items);
                          }}
                        />
                      </Stack>
                      <Checkbox
                        checked={props.task.instances?.some((i) => i.userId === undefined)}
                        label={t('task:TaskTypes.ApprovalTemplate.IncludeOwners')}
                        onChange={(ev, checked) => {
                          setInstanceForOwner(checked ?? false);
                        }}
                      />
                      <Checkbox
                        checked={props.task.instancesEnabled}
                        label={t('task:TaskTypes.ApprovalTemplate.UserCanChangeApprovers')}
                        onChange={(ev, checked) => {
                          const copyTask = props.task.clone();
                          copyTask.instancesEnabled = checked;
                          props.onUpdate(copyTask);
                        }}
                      />
                    </Stack>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Fragment>
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
