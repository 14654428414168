import NormDTO from './normDTO';
import { EntityTypes } from 'models/entity';
import { PackageStates, PackageTenantStates, PurchaseModes } from 'models/package';
import Language from './languageDTO';
import ResourceLinkDTO from './resourceLinkDTO';
import ResourceListDTO from './resourceListDTO';

export default class PackageDTO {
  packageId: number;

  tenantId: string;

  externalId: string;

  version: string;

  code: string;

  categoryId?: number;

  created: string;

  ownerTenantId: string;

  ownerUserId?: string;

  ownerName: string;

  state: PackageStates;

  commentTrailId: number;

  auditTrailId: number;

  name: string;

  shortDescription: string;

  description: string;

  price: number;

  modified?: string;

  modifiedBy?: string;

  modifiedById?: string;

  webURLName?: string;

  webURL?: string;

  purchaseURL?: string;

  purchaseMode: number;

  startLinkId?: number;

  notificationEmail?: string;

  languages?: Language[];

  isoControls: number[];

  primaryLanguage: string;

  isPreview: boolean;

  norm?: NormDTO;

  packageTenants: PackageTenantDTO[];

  packageContents: PackageContentDTO[];

  sharePointLists: ResourceListDTO[];

  sharePointLinks: ResourceLinkDTO[];

  imports: PackageImportDTO[];

  fromPackages: PackageDTO[];

  toPackages: PackageDTO[];

  logoSmallLight?: Blob;

  logoSmallDark?: Blob;

  logoLargeLight?: Blob;

  logoLargeDark?: Blob;

  constructor() {
    this.packageId = 0;
    this.version = '';
    this.code = '';
    this.created = '';
    this.ownerTenantId = '';
    this.ownerName = '';
    this.state = PackageStates.Draft;
    this.name = '';
    this.shortDescription = '';
    this.description = '';
    this.price = 0;
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.purchaseMode = PurchaseModes.Buy;
    this.primaryLanguage = '';
    this.packageTenants = [];
    this.packageContents = [];
    this.sharePointLinks = [];
    this.sharePointLists = [];
    this.imports = [];
    this.tenantId = '';
    this.externalId = '';
    this.isoControls = [];
    this.isPreview = false;
    this.fromPackages = [];
    this.toPackages = [];
  }
}

export class PackageTenantDTO {
  packageId: number;

  purchaseDate?: string;

  activateDate?: string;

  transactionId?: string;

  state: number;

  packageImports: PackageImportDTO[];

  constructor() {
    this.packageId = 0;
    this.state = PackageTenantStates.Available;
    this.packageImports = [];
  }
}

export class PackageContentDTO {
  contentId: number;

  contentParentId: number;

  sourceTenantId: string;

  sourceEntityType: number;

  sourceEntityId: string;

  name: string;

  data1?: string;

  data2?: string;

  blob?: string;

  constructor() {
    this.contentId = 0;
    this.contentParentId = 0;
    this.sourceTenantId = '';
    this.sourceEntityId = '';
    this.sourceEntityType = EntityTypes.NotSet;
    this.name = '';
  }
}

export class PackageImportDTO {
  packageId: number;

  sourceEntityType: number;

  sourceEntityId: string;

  sourceEntityIdTo: string;

  targetEntityId: string;

  targetEntityIdTo: string;

  state: number;

  name?: string;

  constructor() {
    this.packageId = 0;
    this.sourceEntityType = 0;
    this.sourceEntityId = '';
    this.sourceEntityIdTo = '';
    this.targetEntityId = '';
    this.targetEntityIdTo = '';
    this.state = 0;
  }
}
