import IdListDTO from '../IdListDTO';
import KPIAlertDTO from './KPIAlertDTO';
import KPIDataDTO from './kpiDataDTO';
import { KPI_TranslationDTO } from './kpi_TranslationDTO';
import KPIGraphDataDTO from './kpiGraphDataDTO';
import StringValueDTO from '../stringValue/stringValueDTO';

export default class KPIDTO {
  kpiId: number;

  authSchemaId?: number;
  
  auditTrailId?: number;

  type: number;

  minValue: string | undefined;

  maxValue: string | undefined;

  decimalCount: number | undefined;

  defValue: string | undefined;

  required: boolean;

  attachmentMode?: number;

  listId?: number;

  commentMode?: number;

  systemKPIType?: number;

  tagIds: IdListDTO;

  data?: KPIDataDTO[];

  graphData?: KPIGraphDataDTO;

  alerts: KPIAlertDTO[];

  trans: KPI_TranslationDTO[];

  hasAutomatedEvidence: boolean;

  choices?: StringValueDTO[];

  taskTypeIds?: IdListDTO;

  dashboardIds?: IdListDTO;

  constructor() {
    this.kpiId = 0;
    this.trans = [];
    this.auditTrailId = 0;
    this.type = 0;
    this.required = false;
    this.tagIds = new IdListDTO([]);
    this.alerts = [];
    this.hasAutomatedEvidence = false;
  }
}
