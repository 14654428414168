import { Stack, PrimaryButton, DefaultButton, Separator, TooltipHost } from '@fluentui/react';
import { globalStackTokensGapSmall } from 'globalStyles';

interface IDialogFooterProps {
  onSubmit: () => void;
  onCancel?: () => void;
  onTertiary?: () => void;
  buttonPosition: 'end' | 'start';
  secondaryText?: string;
  primaryText: string;
  tertiaryText?: string;
  tertiaryTooltip?: string;
  disablePrimary?: () => boolean;
  disableTertiary?: () => boolean;
}

const ButtonDialogFooter = (props: IDialogFooterProps) => {
  return (
    <Stack tokens={globalStackTokensGapSmall}>
      <Separator />
      <Stack.Item>
        <Stack horizontal wrap horizontalAlign={props.buttonPosition} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <PrimaryButton
              text={props.primaryText}
              onClick={props.onSubmit}
              disabled={props.disablePrimary ? props.disablePrimary() : undefined}
            />
          </Stack.Item>
          {props.onCancel && (
            <Stack.Item>
              <DefaultButton text={props.secondaryText} onClick={props.onCancel} />
            </Stack.Item>
          )}
          {props.tertiaryText && (
            <Stack.Item>
              <TooltipHost content={props.tertiaryTooltip}>
                <DefaultButton
                  text={props.tertiaryText}
                  onClick={props.onTertiary}
                  disabled={props.disableTertiary ? props.disableTertiary() : undefined}
                />
              </TooltipHost>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default ButtonDialogFooter;
