import { Client } from '@microsoft/microsoft-graph-client';
import { IAppContext } from 'App/AppContext';
import { EntityTypes } from 'models/entity';
import Package, { PackageContent, PackageImport, PackageTenantStates } from 'models/package';
import ResourceList, { ResourceListType } from 'models/resourceList';
import { TFunction } from 'i18next';
import { apiRequest, graphSharepointManageRequest } from 'services/Auth/authConfig';
import { graphGetChildrenForDriveItem, graphDeleteFile, graphGetDriveItem } from 'services/Graph/graphServiceDrive';
import { graphDeleteList, graphDeleteListItem, graphGetListItems } from 'services/Graph/graphServiceList';
import { graphDeleteSitePage } from 'services/Graph/graphServicePage';
import { apiGetLink } from 'services/Api/linkService';
import { DriveItem } from 'microsoft-graph';
import { apiGetPackageForState, apiGetPackageImports } from 'services/Api/packageService';

//
// Delete SharePoint content
//

export type SharePointDeleteProgressCallBack = (label: string) => void;

const setDeleteProgress = (label: string, progressCallback: SharePointDeleteProgressCallBack) => {
  progressCallback(label);
};

export const deleteContent = async (
  pack: Package,
  importsToDelete: PackageImport[],
  content: PackageContent[],
  appContext: IAppContext,
  progressCallback: SharePointDeleteProgressCallBack,
  t: TFunction<string[]>,
): Promise<boolean> => {
  //init
  await appContext.globalDataCache.lists.getItems();
  let hasError: boolean = false;

  //get authorization
  const graphInterface = await appContext.getGraphInterface(graphSharepointManageRequest.scopes, undefined);
  const accessToken = await appContext.getAccessToken(apiRequest.scopes);

  //progress
  const linksToDelete = importsToDelete.filter((i) => i.sourceEntityType === EntityTypes.Link);
  const listsToDelete = importsToDelete.filter((i) => i.sourceEntityType === EntityTypes.List);

  //Build a hash table of all import keys of all installed packages
  //With this table, the de-install process can determine whether the item to delete was also needed by another package.
  //When this is the case, the item must not be deleted.
  //This implies that when the last package that uses an item is de-installed, the item is deleted
  const starterPacksImportKeys = new Set();
  const activePacks = await apiGetPackageForState(PackageTenantStates.Activated, accessToken);

  for (let idx = 0; idx < activePacks.length; idx++) {
    const starterPack = activePacks[idx];
    //get imports and add to hash table if not exist
    let imports = await apiGetPackageImports(starterPack, accessToken);
    imports = imports.filter(
      (i) => i.sourceEntityType !== EntityTypes.Control && i.sourceEntityType !== EntityTypes.Requirement,
    );

    for (let importIdx = 0; importIdx < imports.length; importIdx++) {
      const importItem = imports[importIdx];
      const key = `${importItem.sourceEntityType}-${importItem.targetEntityId}`;
      starterPacksImportKeys.add(key);
    }
  }

  //delete all links
  for (let idx = 0; idx < linksToDelete.length; idx++) {
    const item = linksToDelete[idx];
    //get the actual link to delete
    try {
      //check if this import key exists in any of the related starter packages
      const key = `${item.sourceEntityType}-${item.targetEntityId}`;
      if (starterPacksImportKeys.has(key)) continue;
      //check if the link exists
      const link = await apiGetLink(Number.parseInt(item.targetEntityId), accessToken, appContext.globalDataCache);
      if (link && !link.list.isVirtual) {
        //when the link exists, delete it based on the category type
        switch (link.list.listType) {
          case ResourceListType.CustomList:
            if (!link.list.spSiteId || !link.list.spListId || !link.listItemId) continue;
            await graphDeleteListItem(graphInterface.client, link.list.spSiteId, link.list.spListId, link.listItemId);
            break;
          case ResourceListType.DocumentLibrary:
            if (!link.list.spDriveId || !link.driveItemId) continue;
            await graphDeleteFile(graphInterface.client, link.list.spDriveId, link.driveItemId);
            break;
          case ResourceListType.SitePageLibrary:
            if (!link.list.spSiteId || !link.pageId) continue;
            await graphDeleteSitePage(graphInterface.client, link.list.spSiteId, link.pageId);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      hasError = true;
    }

    setDeleteProgress(item.name ?? '', progressCallback);
  }

  //delete custom lists and document libraries
  for (let idx = 0; idx < listsToDelete.length; idx++) {
    try {
      const item = listsToDelete[idx];
      //check if this import key exists in any of the related starter packages
      const key = `${item.sourceEntityType}-${item.targetEntityId}`;
      if (starterPacksImportKeys.has(key)) continue;
      //check if the link exists
      const list = appContext.globalDataCache.lists.get(item.targetEntityId);
      if (list && list.listId > 0 && !list.isVirtual) {
        switch (list.listType) {
          case ResourceListType.CustomList:
            if (!list.spSiteId || !list.spListId) continue;
            //delete the list when it's empty
            const items = await graphGetListItems(graphInterface.client, list.spSiteId, list.spListId);
            if (item && items.length === 0) {
              await graphDeleteList(graphInterface.client, list.spSiteId, list.spListId);
            }
            break;
          case ResourceListType.DocumentLibrary:
            if (!list.spDriveId) continue;
            //find the content item in the package based on the import item
            const contentItem = content.find(
              (c) => c.sourceEntityType === EntityTypes.List && c.sourceEntityId === item.sourceEntityId,
            );
            if (!contentItem) continue;
            //find the folder root item
            const rootItem = content.find(
              (c) =>
                c.data1 === contentItem?.data1 &&
                c.data2 === contentItem?.data2 &&
                !c.contentParentId &&
                c.sourceEntityType === EntityTypes.NotSet,
            );
            if (!rootItem) continue;
            hasError = await deleteListFolderStructure(graphInterface.client, list, rootItem, content);
            break;
          default:
            break;
        }
      }

      setDeleteProgress(item.name ?? '', progressCallback);
    } catch (err) {
      hasError = true;
    }
  }

  return hasError;
};

const deleteListFolderStructure = async (
  client: Client,
  list: ResourceList,
  rootItem: PackageContent,
  content: PackageContent[],
): Promise<boolean> => {
  if (list.spDriveId && list.spDriveItemId) {
    //get the actual starting point of this document library from SharePoint
    let driveRoot: DriveItem | undefined = undefined;
    try {
      driveRoot = await graphGetDriveItem(client, list.spDriveId, list.spDriveItemId);
    } catch (err) {
      //item does not exist on SharePoint so is deleted already
      return false;
    }
    if (driveRoot) {
      //get the items that should exist in the driveRoot so we can try to delete them
      const subItems = content.filter(
        (c) => c.sourceEntityType === EntityTypes.NotSet && c.contentParentId === rootItem.contentId,
      );

      return await deleteListFolderStructureRecursive(client, list, driveRoot, subItems, content);
    }
  }

  //id's or drive root is missing so maybe not everyhing is deleted
  return true;
};

const deleteListFolderStructureRecursive = async (
  client: Client,
  list: ResourceList,
  currentItem: DriveItem,
  childs: PackageContent[],
  content: PackageContent[],
): Promise<boolean> => {
  if (!list.spDriveId || !currentItem.id) return true;
  const items = await graphGetChildrenForDriveItem(client, list.spDriveId, currentItem.id);

  for (let idx = 0; idx < childs.length; idx++) {
    const item = childs[idx];
    const existingItem = items.find((i) => i.folder && i.name?.toLowerCase() === item.name.toLowerCase());
    if (existingItem) {
      //go to the leaf nodes
      const subItems = content.filter(
        (c) => c.sourceEntityType === EntityTypes.NotSet && c.contentParentId === item.contentId,
      );
      await deleteListFolderStructureRecursive(client, list, existingItem, subItems, content);
    }
  }

  try {
    const items = await graphGetChildrenForDriveItem(client, list.spDriveId, currentItem.id);
    if (items.length === 0) {
      if (!currentItem.root) {
        await graphDeleteFile(client, list.spDriveId, currentItem.id);
      }

      return false;
    }

    //not all items are deleted
    return true;
  } catch (err) {
    //error while deleting items
    return true;
  }
};
