import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import RecurringPattern from 'models/recurringPattern';
import { useTranslation } from 'react-i18next';
import { getWeekIndex } from 'utils/datetime';

interface IRecurringRelativeOptionsProps {
  recurring: RecurringPattern;
  onChange: (recurring: RecurringPattern) => void;
}

const RecurringRelativeOptions = (props: IRecurringRelativeOptionsProps) => {
  const { t } = useTranslation(['translation', 'control']);

  const getSelectedOption = (): string => {
    const index = props.recurring.index;

    switch (props.recurring.repeatInterval) {
      case 'absoluteMonthly':
      case 'absoluteYearly':
      case 'absoluteYearCycle':
        return 'abs';
      case 'relativeMonthly':
      case 'relativeYearly':
      case 'relativeYearCycle':
        if (props.recurring.index === 'last') {
          return 'last';
        } else {
          return 'rel';
        }
    }

    return index;
  };

  const getOptions = (): IChoiceGroupOption[] => {
    const options: IChoiceGroupOption[] = [];

    let optionText: string = props.recurring.getAbsRelDateString(props.recurring, undefined, false);

    const weekIndex = getWeekIndex(props.recurring.startDate);
    let occurenceText = props.recurring.getOccurenceString(weekIndex);

    const absoluteOption: IChoiceGroupOption = { key: 'abs', text: optionText };
    options.push(absoluteOption);

    if (weekIndex !== 'last') {
      optionText = props.recurring.getAbsRelDateString(props.recurring, occurenceText, true);
      const relativeOption1: IChoiceGroupOption = { key: 'rel', text: optionText };
      options.push(relativeOption1);
    }

    if (weekIndex === 'fourth' || weekIndex === 'last') {
      occurenceText = t('control:TabDetails.Fields.Recurring.Occurences.Last');
      optionText = props.recurring.getAbsRelDateString(props.recurring, occurenceText, true);
      const lastOption: IChoiceGroupOption = { key: 'last', text: optionText };
      options.push(lastOption);
    }

    return options;
  };

  const onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ) => {
    if (!option) return;
    const newRecurring = props.recurring.clone();

    //switch for relative to absolute or vica versa
    switch (option.key) {
      case 'abs':
        if (props.recurring.repeatInterval === 'relativeYearly') {
          newRecurring.repeatInterval = 'absoluteYearly';
        } else if (props.recurring.repeatInterval === 'relativeMonthly') {
          newRecurring.repeatInterval = 'absoluteMonthly';
        } else if (props.recurring.repeatInterval === 'relativeYearCycle') {
          newRecurring.repeatInterval = 'absoluteYearCycle';
        }
        break;
      case 'rel':
      case 'last':
        if (props.recurring.repeatInterval === 'absoluteYearly') {
          newRecurring.repeatInterval = 'relativeYearly';
        } else if (props.recurring.repeatInterval === 'absoluteMonthly') {
          newRecurring.repeatInterval = 'relativeMonthly';
        } else if (props.recurring.repeatInterval === 'absoluteYearCycle') {
          newRecurring.repeatInterval = 'relativeYearCycle';
        }
        break;
    }

    if (option.key === 'last') {
      newRecurring.index = 'last';
    } else {
      newRecurring.index = getWeekIndex(props.recurring.startDate);
    }

    props.onChange(newRecurring);
  };

  return <ChoiceGroup selectedKey={getSelectedOption()} options={getOptions()} onChange={onChange} />;
};

export default RecurringRelativeOptions;
