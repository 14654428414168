import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { SubscriptionTypes } from 'utils/subscription';
import Package from './package';
import { TenantOnboarding } from './tenant';
import Dashboard from './dashboard';
import Norm from './norm';

export enum StoreMgmtStates {
  Customer = 0,
  Creator = 1,
  Manager = 2,
  Live = 3,
}

export default class AdminTenant {
  tenantId: string;

  parentId?: string;

  topLevelParentId?: string;

  azureTenantId?: string;

  name: string;

  displayName: string;

  created: Date;

  licenses: number;

  mgmtLicenses: number;

  trialStart?: Date;

  enableEventSubscriptions: boolean;

  suspended: boolean;

  storeMgmtState: StoreMgmtStates;

  onboardingState?: TenantOnboarding;

  package?: Package;

  usedLicenses?: number;

  usedMgmtLicenses?: number;

  subscriptionType: number;

  leadSource?: string;

  partnerId?: string;

  dashboardId?: number;

  dashboard?: Dashboard;

  norms: Norm[];
  
  stripeCustomerId?: string;

  subscriptionEnd?: Date;

  constructor() {
    this.tenantId = '';
    this.created = new Date();
    this.name = '';
    this.displayName = '';
    this.licenses = 10;
    this.mgmtLicenses = 1;
    this.enableEventSubscriptions = false;
    this.suspended = false;
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
    this.norms = [];
  }

  clone() {
    const output = new AdminTenant();
    output.parentId = this.parentId;
    output.topLevelParentId = this.topLevelParentId;
    output.tenantId = this.tenantId;
    output.name = this.name;
    output.displayName = this.displayName;
    output.created = this.created;
    output.licenses = this.licenses;
    output.mgmtLicenses = this.mgmtLicenses;
    output.trialStart = this.trialStart;
    output.storeMgmtState = this.storeMgmtState;
    output.enableEventSubscriptions = this.enableEventSubscriptions;
    output.suspended = this.suspended;
    output.package = this.package;
    output.usedLicenses = this.usedLicenses;
    output.usedMgmtLicenses = this.usedMgmtLicenses;
    output.subscriptionType = this.subscriptionType;
    output.azureTenantId = this.azureTenantId;
    output.leadSource = this.leadSource;
    output.partnerId = this.partnerId;
    output.dashboard = this.dashboard?.clone();
    output.dashboardId = this.dashboardId;
    
    return output;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      id: Joi.string().guid().required().label(localizedFields['id']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
      azureTenantId: Joi.string().guid().label(localizedFields['azureTenantId']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, id: this.tenantId }, { abortEarly: false });
  }
}
