import { useEffect, useContext, useState } from 'react';
import {
  CommandBar,
  DetailsList,
  DetailsListLayoutMode,
  DialogFooter,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  Modal,
  PrimaryButton,
  SelectionMode,
  Spinner,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  cancelIcon,
  errorIcon,
  globalStackTokensGapExtraSmall,
  globalStackTokensGapMedium,
  pendingIcon,
  validIcon,
} from 'globalStyles';
import AppContext from 'App/AppContext';
import AppError from 'utils/appError';
import { ISite } from 'services/Graph/SharepointInterfaces';
import { getTests, ITestIntegration, TestIntegrationStatus, TestIntegrationType } from './SharePointTest';

interface ISharePointIntegrationTestModalProps {
  isOpen: boolean;
  selectedSite?: ISite;
  onClose: () => void;
  onResult: (success: boolean) => void;
}

const SharePointIntegrationTestModal = (props: ISharePointIntegrationTestModalProps) => {
  const { t } = useTranslation(['adminCompany', 'translation', 'sharepoint']);
  const appContext = useContext(AppContext);
  const [integrations, setIntegrations] = useState<ITestIntegration[]>([]);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      const names: Record<number, string> = {};
      names[TestIntegrationType.Manager] = t('adminCompany:Tabs.Integration.SharepointManager.Label');
      names[TestIntegrationType.Libraries] = t('adminCompany:Tabs.Integration.SharepointLibraries.Label');
      names[TestIntegrationType.Pages] = t('adminCompany:Tabs.Integration.SharepointPages.Label');
      names[TestIntegrationType.Lists] = t('adminCompany:Tabs.Integration.SharepointLists.Label');
      const integrationCol = getTests(names);
      setIntegrations(integrationCol);
      runTests(integrationCol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const updateIntegrationStatus = (
    integrations: ITestIntegration[],
    id: TestIntegrationType,
    newStatus: TestIntegrationStatus,
  ) => {
    const integration = integrations.find((i) => i.id === id);
    if (!integration) throw new AppError('Integration not found');

    integration.status = newStatus;
  };

  const runTests = async (integrations: ITestIntegration[]) => {
    try {
      setIsRunning(true);
      const updatedIntegrations = integrations;
      let result: boolean = true;

      if (props.selectedSite) {
        for (let idx = 0; idx < integrations.length; idx++) {
          const integration = integrations[idx];
          const subresult = await integration.test(appContext, props.selectedSite);
          updateIntegrationStatus(
            updatedIntegrations,
            integration.id,
            subresult ? TestIntegrationStatus.Succeeded : TestIntegrationStatus.Failed,
          );
          if (subresult === false) result = false;
        }
      }

      setIntegrations([...updatedIntegrations]);
      props.onResult(result);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsRunning(false);
    }
  };

  const getCommandBarItems = (): ICommandBarItemProps[] => {
    let items: ICommandBarItemProps[] = [];

    items = [
      {
        key: 'test',
        disabled: isRunning,
        text: t('adminCompany:Tabs.Integration.Test.Commands.Start') + ': ' + props.selectedSite?.displayName,
        iconProps: { iconName: 'Play' },
        onClick: (ev, item) => {
          runTests(integrations);
        },
      },
    ];

    return items;
  };

  const getStatusIcon = (state?: TestIntegrationStatus): JSX.Element => {
    if (state === TestIntegrationStatus.Succeeded)
      return <FontIcon {...validIcon} style={{ color: 'green', fontSize: 18 }} />;
    if (state === TestIntegrationStatus.Failed)
      return <FontIcon {...errorIcon} style={{ color: 'red', fontSize: 18 }} />;
    if (state === TestIntegrationStatus.NotRun)
      return <FontIcon {...pendingIcon} style={{ color: 'grey', fontSize: 18 }} />;

    return <p />;
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('adminCompany:Tabs.Integration.Test.Columns.Name'),
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item?: ITestIntegration, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{item.name}</Text>;
      },
    },
    {
      key: 'permission',
      name: t('adminCompany:Tabs.Integration.Test.Columns.Permissions'),
      minWidth: 250,
      maxWidth: 350,
      isResizable: true,
      onRender: (item?: ITestIntegration, index?: number, column?: IColumn) => {
        if (!item) return;

        return <Text>{item.permissions}</Text>;
      },
    },
    {
      key: 'status',
      name: t('adminCompany:Tabs.Integration.Test.Columns.Status'),
      minWidth: 120,
      maxWidth: 120,
      isResizable: false,
      onRender: (item?: ITestIntegration, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapExtraSmall}>
            {getStatusIcon(item.status)}
            {isRunning && <Spinner />}
            {!isRunning && <Text>{getStatusText(item.status)}</Text>}
          </Stack>
        );
      },
    },
  ];

  const getStatusText = (status: TestIntegrationStatus): string => {
    switch (status) {
      case TestIntegrationStatus.NotRun:
        return t('adminCompany:Tabs.Integration.Test.Status.NotRun');
      case TestIntegrationStatus.Succeeded:
        return t('adminCompany:Tabs.Integration.Test.Status.Succeeded');
      case TestIntegrationStatus.Failed:
        return t('adminCompany:Tabs.Integration.Test.Status.Failed');
    }
  };

  //
  // Main render
  //
  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onClose}>
      <Stack verticalFill styles={{ root: { padding: 20, maxWidth: '90vw' } }} tokens={globalStackTokensGapMedium}>
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xLarge">{t('adminCompany:Tabs.Integration.Test.Title')}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.onClose} />
        </Stack>
        <CommandBar styles={{ root: { padding: 0 } }} items={getCommandBarItems()}></CommandBar>
        <Stack.Item>
          <Text>{t('adminCompany:Tabs.Integration.Test.Info')}</Text>
        </Stack.Item>
        <Stack.Item>
          <DetailsList
            items={integrations}
            columns={columns}
            compact
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
          />
        </Stack.Item>
        <DialogFooter>
          <PrimaryButton onClick={() => props.onClose()}>{t('translation:General.Button.Close')}</PrimaryButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default SharePointIntegrationTestModal;
