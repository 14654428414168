import { ApprovalMainStatus, ApprovalState } from 'models/approval';
import ResourceLink from 'models/resourceLink';
import { getDateTimeDiffMinute } from 'utils/datetime';
import { newGuidNil } from 'utils/guid';

export enum KPIDataTransformStatus {
  None = 0,
  New = 1,
  Selected = 2,
  ApprovalPending = 3,
  Approved = 10,
}

export default class KPIDataTransform {
  kpiId: number;

  rawEvidence?: string;

  evidence?: string;

  evidenceComment?: string;

  linkId?: number;

  templateId?: number;

  lastEventId?: number;

  arrayPath?: string;

  selectedPath?: string;

  ignoredPaths?: string[];

  extractedPath?: string;

  created: Date;

  createdBy: string;

  createdById: string;

  approval?: ApprovalMainStatus;

  link?: ResourceLink;

  constructor() {
    this.kpiId = 0;
    this.created = new Date();
    this.createdBy = '';
    this.createdById = newGuidNil();
  }

  clone(): KPIDataTransform {
    const output = new KPIDataTransform();
    output.kpiId = this.kpiId;
    output.created = new Date(this.created);
    output.createdBy = this.createdBy;
    output.createdById = this.createdById;
    output.rawEvidence = this.rawEvidence;
    output.evidence = this.evidence;
    output.linkId = this.linkId;
    output.arrayPath = this.arrayPath;
    output.selectedPath = this.selectedPath;
    output.ignoredPaths = this.ignoredPaths;
    output.extractedPath = this.extractedPath;
    output.templateId = this.templateId;
    output.approval = this.approval;
    output.link = this.link?.clone();

    return output;
  }

  getStatus(): KPIDataTransformStatus {
    let status = KPIDataTransformStatus.None;

    if (this.kpiId > 0 && this.rawEvidence) {
      if ((!this.linkId || !this.approval) && !this.evidence) {
        status = KPIDataTransformStatus.New;
      } else if ((!this.linkId || !this.approval) && this.evidence) {
        status = KPIDataTransformStatus.Selected;
      } else if (this.linkId && this.evidence && this.approval && this.approval?.status !== ApprovalState.Approved) {
        status = KPIDataTransformStatus.ApprovalPending;
      } else if (this.linkId && this.evidence && this.approval && this.approval?.status === ApprovalState.Approved) {
        status = KPIDataTransformStatus.Approved;
      }

      if (status === KPIDataTransformStatus.Approved && this.link?.changed && this.approval?.date) {
        //check whether the evidence has been changed after the approval data
        const minutes = getDateTimeDiffMinute(this.link.changed, this.approval.date);
        if (minutes > 0) {
          //revert to selected state so a new approval process can be started
          status = KPIDataTransformStatus.Selected;
        }
      }
    }

    return status;
  }
}
