export default class FeedbackDTO {
  subject: string;

  message: string;

  constructor() {
    this.subject = '';
    this.message = '';
  }
}
