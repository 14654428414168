import { Separator, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useContext, useEffect, useState } from 'react';
import { powerBIClientRequest } from 'services/Auth/authConfig';
import { isValidGuid } from 'utils/guid';
import { useTranslation } from 'react-i18next';
import { powerBI_dark } from './PowerBIReportThemes';
import './PowerBIReport.css';

interface IPowerBIReportPros {
  url: string;
}

const PowerBIReport = (props: IPowerBIReportPros) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'dashboard']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<IReportEmbedConfiguration | undefined>(undefined);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIdFromUrl = (url: string): string | undefined => {
    if (url.includes('?')) {
      const idx = url.indexOf('?');
      const query = url.substring(idx);
      const queryParams = new URLSearchParams(query);
      if (queryParams.has('reportId')) {
        //https://app.powerbi.com/reportEmbed?reportId={id}&autoAuth=true&ctid=f3d70179-6e00-41c9-9c06-3b1771606b98
        return queryParams.get('reportId') || undefined;
      }
    }

    if (isValidGuid(url)) {
      //Just the report Id
      return url;
    } else {
      return undefined;
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);

      const token = await appContext.getAccessToken(powerBIClientRequest.scopes);
      const reportId = getIdFromUrl(props.url);

      let newTheme: models.IReportTheme | undefined = undefined;
      if (appContext.useDarkMode) {
        newTheme = powerBI_dark;
      }

      if (token && reportId) {
        const embedConfig: IReportEmbedConfiguration = {
          type: 'report',
          id: reportId,
          embedUrl: 'https://app.powerbi.com/reportEmbed',
          accessToken: token,
          tokenType: models.TokenType.Aad,
          theme: { themeJson: newTheme },
          settings: {
            localeSettings: { language: appContext.user.language.codeWithCulture },
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToPage,
            },
            bars: {
              actionBar: {
                visible: true,
              },
              statusBar: {
                visible: true,
              },
            },
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
              pageNavigation: {
                position: models.PageNavigationPosition.Bottom,
              },
            },
            persistentFiltersEnabled: true,
            personalBookmarksEnabled: true,
          },
        };

        setConfig(embedConfig);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (!config) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('dashboard:InvalidReportId')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <Stack.Item>
        <Separator />
      </Stack.Item>
      <Stack.Item grow>
        <PowerBIEmbed embedConfig={config} cssClassName={'reportClass'} />
      </Stack.Item>
    </Stack>
  );
};

export default PowerBIReport;
