import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Label, Modal, Separator, Stack, Text, TextField } from '@fluentui/react';
import ResourceList from 'models/resourceList';
import { cancelIcon, globalStackTokensGapSmall, globalTextStylesCardRed } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import ButtonDialogFooter from 'components/Footer/ButtonDialogFooter';
import AppContext from 'App/AppContext';
import ResourceLink from 'models/resourceLink';
import { getFilenameFromURL } from 'utils/url';
import LinkPicker, { ILinkTag } from '../Pickers/LinkPicker';

interface ILinkCreatorURLProps {
  isOpen: boolean;
  list: ResourceList;
  dismiss: () => void;
  addLinks: (links: ResourceLink[]) => void;
  listLinks: ResourceLink[];
  existingLinks: ResourceLink[];
  listLoading: boolean;
  allowPickExistingLink: boolean;
  linkToEdit?: ResourceLink;
  hideWholeList?: boolean;
}

const LinkCreatorURL = (props: ILinkCreatorURLProps) => {
  const { t } = useTranslation(['library', 'translation']);
  const appContext = useContext(AppContext);
  const [selectedLink, setSelectedLink] = useState<ResourceLink | undefined>(undefined);

  const getInitialNewLink = (): ResourceLink | undefined => {
    if (props.linkToEdit) return props.linkToEdit?.clone();

    if (props.list.webURL) {
      //create a new link with a pre-populated web-url
      const link = new ResourceLink();
      link.linkURL = props.list.webURL;
      link.list = props.list;
      link.listId = props.list.listId;

      return link;
    }

    return undefined;
  };

  const [newLink, setNewLink] = useState<ResourceLink | undefined>(getInitialNewLink());
  const [linkPickError, setLinkPickError] = useState<boolean>(false);
  const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>('');
  const [urlErrorMessage, setUrlErrorMessage] = useState<string | undefined>('');

  const httpsPrefix = 'https://';

  useEffect(() => {
    if (newLink) {
      if (newLink.linkName === '' && newLink.linkURL === '') {
        setNameErrorMessage('');
        setUrlErrorMessage('');
      } else {
        setNameErrorMessage(validate('name'));
        setUrlErrorMessage(validate('url'));

        //check if the name and url already exists, skip the current link that is updated
        const existingLinkWithName = props.listLinks.find((_itm) => {
          if (props.linkToEdit && props.linkToEdit.linkId === _itm.linkId) return false;

          return _itm.linkName.toLowerCase() === newLink.linkName.toLowerCase();
        });
        if (existingLinkWithName) {
          setNameErrorMessage(t('library:LinkComponents.Validate.NameUniqueError'));
        }

        const existingLinkWithrl = props.listLinks.find((_itm) => {
          if (props.linkToEdit && props.linkToEdit.linkId === _itm.linkId) return false;

          return _itm.linkURL.toLowerCase() === newLink.linkURL.toLowerCase();
        });

        if (existingLinkWithrl) {
          setUrlErrorMessage(t('library:LinkComponents.Validate.URLUniqueError'));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLink]);

  const _localizedFields = {
    name: t('library:LinkComponents.Validate.name'),
    url: t('library:LinkComponents.Validate.url'),
  };

  const validate = (fieldName: string) => {
    if (newLink) {
      const result = newLink.validate(_localizedFields);
      if (result.error) {
        const fieldError = result.error.details.find((e) => {
          return e.context?.key === fieldName;
        });

        return fieldError ? fieldError.message : undefined;
      }
    }

    return undefined;
  };

  const onSubmitWholeListItem = () => {
    const existingLink = props.listLinks.find((l) => l.isWholeList());
    let newLink: ResourceLink;
    if (existingLink) {
      newLink = existingLink;
    } else {
      newLink = new ResourceLink();
      newLink.setWholeList(props.list);
    }
    props.addLinks([newLink]);
    props.dismiss();
  };

  const onSubmit = async () => {
    if (disableAdd()) return;

    try {
      appContext.showContentLoader();

      if (selectedLink) {
        //user selected an existing link
        props.addLinks([selectedLink]);
      } else if (newLink) {
        //user created a new link
        props.addLinks([newLink]);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      props.dismiss();
    }
  };

  const onPickLink = (item: ResourceLink | undefined) => {
    setSelectedLink(item);
    setNewLink(undefined);
    setLinkPickError(false);
    setNameErrorMessage(undefined);
    setUrlErrorMessage(undefined);
  };

  const CreateOrUpdateNewLink = (name: string | undefined, url: string | undefined) => {
    let link: ResourceLink;

    if (!newLink) {
      if (props.linkToEdit) {
        link = props.linkToEdit.clone();
      } else {
        link = new ResourceLink();
      }
      link.list = props.list;
      link.listId = props.list.listId;
    } else {
      link = newLink.clone();
    }

    if (name !== undefined) {
      link.linkName = name;
    }
    if (url !== undefined) {
      link.linkURL = url;
    }

    setNewLink(link);

    if (selectedLink) {
      setSelectedLink(undefined);
      setLinkPickError(false);
    }
  };

  const disableAdd = () => {
    if (!selectedLink && !newLink) return true;

    if (linkPickError || nameErrorMessage || urlErrorMessage) {
      return true;
    }

    return false;
  };

  const getLinkTagFromLink = (link: ResourceLink): ILinkTag => {
    const lt: ILinkTag = {
      tagId: link.linkId,
      tagName: link.linkName,
      link: link,
    };

    return lt;
  };

  const onCancel = () => {
    props.dismiss();
  };

  const getAddressValueFromNewLink = (): string => {
    if (newLink) {
      if (newLink.linkURL.startsWith(httpsPrefix)) {
        return newLink.linkURL.substring(httpsPrefix.length);
      } else {
        return newLink.linkURL;
      }
    } else {
      return '';
    }
  };

  return (
    <Modal isBlocking={true} isOpen={props.isOpen} onDismiss={onCancel}>
      <Stack
        styles={{
          root: {
            height: '50vh',
            width: '50vw',
            minWidth: 320,
            minHeight: 300,
            maxWidth: '90vw',
            padding: 20,
          },
        }}
      >
        {/*Header*/}
        <Stack.Item>
          <Stack horizontal horizontalAlign={'space-between'}>
            <Stack.Item>
              {!props.linkToEdit && (
                <Text variant="xLarge">{t('library:LinkComponents.AddItemOf', { name: props.list.name })}</Text>
              )}
              {props.linkToEdit && <Text variant="large">{props.linkToEdit.linkName}</Text>}
            </Stack.Item>
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Separator />
        {/*Body */}
        <Stack.Item grow>
          <Stack tokens={globalStackTokensGapSmall}>
            {props.allowPickExistingLink && (
              <Stack.Item grow>
                <Label>{t('library:LinkComponents.ExistingOne')}</Label>
                <LinkPicker
                  itemLimit={1}
                  disabled={!props.allowPickExistingLink || props.listLoading}
                  selectedTags={selectedLink ? [getLinkTagFromLink(selectedLink)] : []}
                  tags={props.listLinks
                    .filter(
                      (listLink) =>
                        props.existingLinks.find(
                          (existing) => listLink.linkId === existing.linkId || listLink.linkName === existing.linkName,
                        ) === undefined,
                    )
                    .map((link) => {
                      return getLinkTagFromLink(link);
                    })}
                  onAdd={(tag: ILinkTag, isNew: boolean) => {
                    onPickLink(tag.link);
                  }}
                  onRemove={(tag: ILinkTag) => {
                    onPickLink(undefined);
                  }}
                />
              </Stack.Item>
            )}
            {linkPickError && (
              <Stack.Item>
                <Text styles={globalTextStylesCardRed}>{t('library:LinkComponents.LinkPickError')}</Text>
              </Stack.Item>
            )}
            {props.allowPickExistingLink && <Label>{t('library:LinkComponents.CreateNewOne')}</Label>}
            <Stack.Item>
              <TextField
                required
                autoFocus={!props.allowPickExistingLink && !appContext.isMobileView}
                prefix={httpsPrefix}
                maxLength={790}
                label={t('library:LinkComponents.Address')}
                value={getAddressValueFromNewLink()}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  if (!newValue?.startsWith(httpsPrefix)) {
                    newValue = httpsPrefix + newValue;
                  }
                  CreateOrUpdateNewLink(undefined, newValue);
                }}
                onBlur={() => {
                  if (newLink && newLink.linkName === '') {
                    const fileName = getFilenameFromURL(newLink.linkURL);
                    CreateOrUpdateNewLink(fileName, undefined);
                  }
                }}
                errorMessage={urlErrorMessage}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                required
                maxLength={512}
                label={t('library:LinkComponents.AddressDisplay')}
                value={newLink ? newLink.linkName : ''}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  CreateOrUpdateNewLink(newValue, undefined);
                }}
                errorMessage={nameErrorMessage}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {/*Footer*/}
        <Stack.Item>
          <ButtonDialogFooter
            primaryText={props.linkToEdit ? t('translation:General.Button.Save') : t('translation:General.Button.Add')}
            secondaryText={t('translation:General.Button.Cancel')}
            onSubmit={() => {
              onSubmit();
            }}
            onCancel={onCancel}
            buttonPosition="end"
            disablePrimary={disableAdd}
            tertiaryText={props.hideWholeList ? undefined : t('library:LinkComponents.WholeList')}
            tertiaryTooltip={
              props.existingLinks.find((l) => l.isWholeList()) !== undefined
                ? t('library:LinkComponents.Validate.ItemExists')
                : undefined
            }
            disableTertiary={() => props.existingLinks.find((l) => l.isWholeList()) !== undefined}
            onTertiary={onSubmitWholeListItem}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default LinkCreatorURL;
