import ResourceLink from 'models/resourceLink';
import { IListItem, ISitePage } from 'services/Graph/SharepointInterfaces';
import AppError from 'utils/appError';
import http from 'services/Api/httpService';
import { ColumnDefinition } from 'microsoft-graph';
import ResourceList from 'models/resourceList';

export interface FileGuidInfo {
  id: string;
  linkId: number;
  mimeType: string;
  url: string;
}

export const GetContentPage = async (link: ResourceLink, accessToken: string): Promise<ISitePage | undefined> => {
  try {
    const ar = await http.get<ISitePage>(`/content/${link.linkId}/page`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const GetContentFileInfo = async (
  link: ResourceLink,
  accessToken: string,
): Promise<FileGuidInfo | undefined> => {
  try {
    const ar = await http.get<FileGuidInfo>(`/content/${link.linkId}/file`, http.getRequestConfig(accessToken));

    return ar.data || undefined;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const GetContentListColumnDef = async (list: ResourceList, accessToken: string): Promise<ColumnDefinition[]> => {
  try {
    const ar = await http.get<ColumnDefinition[]>(
      `/content/${list.listId}/list/columndef`,
      http.getRequestConfig(accessToken),
    );

    return ar.data || [];
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const GetContentListItem = async (link: ResourceLink, accessToken: string): Promise<IListItem> => {
  try {
    const ar = await http.get<IListItem>(`/content/${link.linkId}/list/item`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const GetContentListItems = async (list: ResourceList, accessToken: string): Promise<IListItem[]> => {
  try {
    const ar = await http.get<string[]>(`/content/${list.listId}/list/items`, http.getRequestConfig(accessToken));

    if (ar.data) {
      return ar.data.map((a) => JSON.parse(a));
    } else {
      return [];
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
