import http from './httpService';
import AppError from 'utils/appError';
import Webhook, { WebhookRun } from 'models/webhook';
import { mapFromWebhook, mapFromWebhookRuns, mapFromWebhooks, mapToWebhook } from 'mappings/webhookMapping';
import WebhookDTO, { WebhookRunDTO } from 'models/dto/webhookDTO';
import { TaskWorkflowStatus } from 'models/tasks/task';

export const apiGetWebhooks = async (accessToken: string): Promise<Webhook[]> => {
  try {
    const ar = await http.get<WebhookDTO[]>(`/webhooks`, http.getRequestConfig(accessToken));

    return mapFromWebhooks(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetWebhooksForType = async (
  entityType: string,
  eventTypeId: number,
  accessToken: string,
): Promise<Webhook[]> => {
  try {
    const ar = await http.get<WebhookDTO[]>(
      `/webhooks/${entityType}/${eventTypeId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromWebhooks(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiSetWebhookStatus = async (webhook: Webhook, status: boolean, accessToken: string): Promise<void> => {
  try {
    await http.put(`/webhooks/${webhook.webhookId}/status/${status}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiCopyWebhook = async (webhook: Webhook, accessToken: string): Promise<Webhook> => {
  try {
    const webhookDto = mapToWebhook(webhook);
    const ar = await http.post(`/webhooks/${webhook.webhookId}/copy`, webhookDto, http.getRequestConfig(accessToken));

    return mapFromWebhook(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateWebhook = async (webhook: Webhook, accessToken: string): Promise<Webhook> => {
  try {
    const webhookDto = mapToWebhook(webhook);
    const ar = await http.put(`/webhooks`, webhookDto, http.getRequestConfig(accessToken));

    return mapFromWebhook(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiDeleteWebhook = async (webhook: Webhook, accessToken: string): Promise<void> => {
  try {
    await http.post(`/webhooks/${webhook.webhookId}/delete`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiTestWebhook = async (
  webhook: Webhook,
  payloadId: number | undefined,
  accessToken: string,
): Promise<void> => {
  try {
    let url: string;
    if (payloadId) {
      url = `/webhooks/${webhook.webhookId}/test?payloadId=${payloadId}`;
    } else {
      url = `/webhooks/${webhook.webhookId}/test`;
    }
    await http.post(url, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetWebhookRunsForTask = async (taskId: number, accessToken: string): Promise<WebhookRun[]> => {
  try {
    const ar = await http.get<WebhookRunDTO[]>(`/webhooks/runs/task/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromWebhookRuns(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiRunWebhooksForTask = async (taskId: number, accessToken: string): Promise<TaskWorkflowStatus> => {
  try {
    const url = `/webhooks/run/${taskId}`;
    const ar = await http.post<TaskWorkflowStatus>(url, null, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
