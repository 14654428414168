import Language from '../language';

export class Objective_Translation {
  objectiveId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor(_objectiveId: number = 0, _languageId: number = 0) {
    this.objectiveId = _objectiveId;
    this.languageId = _languageId;
    this.name = '';
  }

  clone(): Objective_Translation {
    const output = new Objective_Translation();
    output.objectiveId = this.objectiveId;
    output.languageId = this.languageId;
    output.name = this.name;
    output.description = this.description;
    output.lang = this.lang?.clone();

    return output;
  }
}
