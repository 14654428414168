import { useContext, useState } from 'react';
import { Stack, Panel, PanelType, PrimaryButton, DefaultButton, Text, Separator, TextField } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalLayerRightSideBarProps,
  globalStackTokensGapSmall,
  globalStackStylesPanel,
  globalStackTokensGapMedium,
  globalTextStylesBold,
} from 'globalStyles';
import Widget, { getWidgetTypes } from 'models/widget';
import WidgetConfigRenderer from './WidgetConfigRenderer';
import AppContext from 'App/AppContext';

interface IEditWidgetPanelProps {
  widget: Widget;
  isOpen: boolean;
  onEdit: (widget: Widget) => void;
  onClose: () => void;
}

const EditWidgetPanel = (props: IEditWidgetPanelProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const [configuredWidget, setConfiguredWidget] = useState<Widget>(props.widget.clone());
  const [nameError, setNameError] = useState<boolean>(false);
  const [configError, setConfigError] = useState<boolean>(false);

  const onUpdateConfig = (config: string, isValid: boolean) => {
    if (!configuredWidget) return;

    if (isValid) {
      const newWidget = configuredWidget.clone();
      newWidget.widgetConfig = config;
      setConfiguredWidget(newWidget);
    }

    setConfigError(!isValid);
  };

  const onEditWidget = () => {
    props.onEdit(configuredWidget);
  };

  const onSetWidgetName = (value: string | undefined) => {
    if (!configuredWidget) return;
    const widgetClone = configuredWidget.clone();
    widgetClone.name = value?.trimStart() ?? '';
    setConfiguredWidget(widgetClone);
  };

  const hasError = (): boolean => {
    return nameError || configError;
  };

  const getTypeName = (): string => {
    const widgets = getWidgetTypes(t);
    const type = widgets.find((w) => w.type === configuredWidget.widgetType);

    return type?.name ?? '';
  };

  const getTypeDescription = (): string => {
    const widgets = getWidgetTypes(t);
    const type = widgets.find((w) => w.type === configuredWidget.widgetType);

    return type?.description ?? '';
  };

  const onGetWidgetNameError = (value: string | undefined): string | undefined => {
    if (value) {
      setNameError(false);

      return undefined;
    } else {
      setNameError(true);

      return t('dashboard:EditWidgetPanel.NameRequired');
    }
  };

  //
  // Render
  //

  const onRenderFooterContentConfiguredWidget = () => {
    return (
      <Stack horizontal styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <PrimaryButton onClick={() => onEditWidget()} disabled={hasError()}>
            {t('translation:General.Button.Save')}
          </PrimaryButton>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton onClick={props.onClose}>{t('translation:General.Button.Cancel')}</DefaultButton>
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <Panel
      headerText={t('dashboard:EditWidgetPanel.Title')}
      type={PanelType.medium}
      isBlocking={true}
      isHiddenOnDismiss={false}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      isFooterAtBottom={true}
      layerProps={globalLayerRightSideBarProps}
      onRenderFooterContent={onRenderFooterContentConfiguredWidget}
    >
      <Stack verticalFill styles={globalStackStylesPanel} tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Stack horizontal verticalAlign="start">
            <Stack.Item>
              <Stack>
                <Text variant="large" styles={globalTextStylesBold}>
                  {getTypeName()}
                </Text>
                {!appContext.isMobileView && <Text variant="medium"> {getTypeDescription()}</Text>}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={t('dashboard:EditWidgetPanel.NameLabel')}
            autoFocus={!appContext.isMobileView}
            required
            value={configuredWidget.name}
            onChange={(ev, newValue) => onSetWidgetName(newValue)}
            onGetErrorMessage={onGetWidgetNameError}
            maxLength={128}
          />
          <Separator />
        </Stack.Item>
        <Stack.Item grow>
          <WidgetConfigRenderer widget={configuredWidget} onUpdateConfig={onUpdateConfig} editmode={true} />
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default EditWidgetPanel;
