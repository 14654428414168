import { IDropdownOption } from '@fluentui/react';
import { TFunction } from 'i18next';
import KPI, { KPIAggregationIntervals, KPIAggregationMethods, KPITypes } from 'models/kpi/kpi';
import { sortOnString } from 'utils/sorting';

//
// Helpers
//
export const getTypeOptions = (
  currentType: KPITypes,
  t: TFunction<string[]>,
  checkConversion: boolean,
): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    {
      key: KPITypes.Text,
      text: t('kpis:Types.Text'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Text),
    },
    {
      key: KPITypes.TextMultiline,
      text: t('kpis:Types.TextMultiline'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.TextMultiline),
    },
    {
      key: KPITypes.Html,
      text: t('kpis:Types.Html'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Html),
    },
    {
      key: KPITypes.Number,
      text: t('kpis:Types.Number'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Number),
    },
    {
      key: KPITypes.SuccesError,
      text: t('kpis:Types.SuccesError'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.SuccesError),
    },
    {
      key: KPITypes.Choice,
      text: t('kpis:Types.Choice'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Choice),
    },
    {
      key: KPITypes.Date,
      text: t('kpis:Types.Date'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Date),
    },
    {
      key: KPITypes.Expression,
      text: t('kpis:Types.Expression'),
      disabled: checkConversion && !isConvertableType(currentType, KPITypes.Expression),
    },
  ];

  return options;
};

export const isConvertableType = (currentType: KPITypes, newType: KPITypes): boolean => {
  switch (currentType) {
    case KPITypes.Text:
      if (newType === KPITypes.Html || newType === KPITypes.TextMultiline) return true;
      break;
    case KPITypes.Html:
      if (newType === KPITypes.Text || newType === KPITypes.TextMultiline) return true;
      break;
    case KPITypes.TextMultiline:
      if (newType === KPITypes.Html || newType === KPITypes.Text) return true;
      break;
    case KPITypes.SuccesError:
    case KPITypes.Number:
      break;
  }

  return false;
};

export const getRequiredOptionalOptions = (t: TFunction<string[]>): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    { key: 0, text: t('kpis:RequiredOptional.None') },
    { key: 1, text: t('kpis:RequiredOptional.Required') },
    { key: -1, text: t('kpis:RequiredOptional.Optional') },
  ];

  return options.sort((a, b) => sortOnString(a.text, b.text));
};

export const getAttachmentCountOptions = (t: TFunction<string[]>): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    { key: 1, text: '1' },
    { key: 2, text: '2' },
    { key: 3, text: '3' },
    { key: 4, text: '4' },
    { key: 5, text: '5' },
  ];

  return options;
};

export const getAggregationMethodOptions = (t: TFunction<string[]>): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    { key: KPIAggregationMethods.Count, text: t('kpis:AggregationMethods.Count') },
    { key: KPIAggregationMethods.Sum, text: t('kpis:AggregationMethods.Sum') },
    { key: KPIAggregationMethods.Min, text: t('kpis:AggregationMethods.Min') },
    { key: KPIAggregationMethods.Max, text: t('kpis:AggregationMethods.Max') },
    { key: KPIAggregationMethods.Avg, text: t('kpis:AggregationMethods.Avg') },
  ];

  return options.sort((a, b) => sortOnString(a.text, b.text));
};

export const getAggregationIntervalOptions = (t: TFunction<string[]>): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    { key: KPIAggregationIntervals.Day, text: t('kpis:AggregationIntervals.Day') },
    { key: KPIAggregationIntervals.Week, text: t('kpis:AggregationIntervals.Week') },
    { key: KPIAggregationIntervals.Month, text: t('kpis:AggregationIntervals.Month') },
    { key: KPIAggregationIntervals.Quarter, text: t('kpis:AggregationIntervals.Quarter') },
    { key: KPIAggregationIntervals.Year, text: t('kpis:AggregationIntervals.Year') },
  ];

  return options.sort((a, b) => sortOnString(a.text, b.text));
};

export const validateMinValue = (
  kpi: KPI,
  prevValue: string | undefined,
  newValue: string | undefined,
): string | undefined => {
  if (!newValue) return undefined;
  const def = Number.parseFloat(newValue);
  if (Number.isNaN(def)) return prevValue;
  if (kpi.minValue) {
    const min = Number.parseFloat(kpi.minValue);
    if (def < min) return prevValue;
  }

  return def.toFixed(kpi.decimalCount);
};

export const validateMaxValue = (
  kpi: KPI,
  prevValue: string | undefined,
  newValue: string | undefined,
): string | undefined => {
  if (!newValue) return undefined;
  const def = Number.parseFloat(newValue);
  if (Number.isNaN(def)) return prevValue;
  if (kpi.maxValue) {
    const max = Number.parseFloat(kpi.maxValue);
    if (def > max) return prevValue;
  }

  return def.toFixed(kpi.decimalCount);
};

export const validateDefMaxValue = (
  kpi: KPI,
  prevValue: string | undefined,
  maxValue: string | undefined,
): string | undefined => {
  if (!maxValue) return undefined;
  const max = Number.parseFloat(maxValue);
  if (Number.isNaN(max)) return prevValue;
  if (kpi.defValue) {
    const def = Number.parseFloat(kpi.defValue);
    if (max < def) return prevValue;
  }

  return max.toFixed(kpi.decimalCount);
};

export const validateDefMinValue = (
  kpi: KPI,
  prevValue: string | undefined,
  minValue: string | undefined,
): string | undefined => {
  if (!minValue) return undefined;
  const min = Number.parseFloat(minValue);
  if (Number.isNaN(min)) return prevValue;
  if (kpi.defValue) {
    const def = Number.parseFloat(kpi.defValue);
    if (min > def) return prevValue;
  }

  return min.toFixed(kpi.decimalCount);
};
