export default class UserRoleDTO {
  userId: string;

  roleId: string;

  constructor() {
    this.userId = '';
    this.roleId = '';
  }
}

export class UserSystemRoleDTO {
  userId: string;

  roleId: number;

  constructor() {
    this.userId = '';
    this.roleId = 0;
  }
}