import { IconButton, Stack } from '@fluentui/react';
import { editIcon } from 'globalStyles';

export interface IHoverEditIconProps {
  left?: string | number | undefined;
  top?: string | number | undefined;
  onClick?: () => void;
}

export const HoverEditIcon = (props: IHoverEditIconProps) => {
  return (
    <Stack.Item
      styles={{
        root: { height: 0 },
      }}
    >
      <IconButton
        iconProps={editIcon}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
        styles={{
          root: {
            left: props.left,
            top: props.top,
            backgroundColor: 'transparent',
            padding: 0,
            margin: 0,
          },
          rootHovered: {
            backgroundColor: 'transparent',
          },
        }}
      />
    </Stack.Item>
  );
};
