import { Fragment, useContext, useState } from 'react';
import {
  Stack,
  FontIcon,
  SelectionMode,
  Label,
  DetailsListLayoutMode,
  ActionButton,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackTokensGapExtraSmall,
  globalStackStylesHeight100,
  newIcon,
  globalStackItemStylesPaddingSceneScroll,
  deleteIcon,
} from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import SubTaskList from '../SubTaskList.';
import SingleTask from '../SingleTask';
import TextInfo from 'components/Notification/Info';
import { newGuid } from 'utils/guid';
import AuthSchemaPicker from 'components/Pickers/AuthSchemaPicker';
import { TaskDetailAssignment } from './Details/TaskDetailAssignment';
import { TaskDetailName } from './Details/TaskDetailName';
import { TaskDetailTags } from './Details/TaskDetailTags';
import { TaskDetailDescription } from './Details/TaskDetailDecription';
import { TaskDetailStatusActive } from './Details/TaskDetailStatusActive';
import { TaskDetailStatus } from './Details/TaskDetailStatus';

interface IEventTemplateTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  windowLevel?: number;
  navigateExternal?: boolean;
  canUpdate: boolean;
}

export const EventTemplateTaskDetail = (props: IEventTemplateTaskDetail) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation']);
  const [newSubTask, setNewSubTask] = useState<Task | undefined>(undefined);
  const [editSubTask, setEditSubTask] = useState<Task | undefined>(undefined);
  const [selectedSubTasks, setSelectedSubTasks] = useState<Task[]>([]);

  const iconFontSize = { fontSize: '20px', marginTop: '5px' };

  //
  // Subtasks
  //
  const addSubTask = () => {
    const newSubTask = new Task();
    newSubTask.taskType = TaskTypes.EventTemplate;
    newSubTask.taskTypeId = props.task.taskTypeId;
    newSubTask.taskMasterId = props.task.taskId;
    newSubTask.taskStates = appContext.globalDataCache.taskStates.items;
    newSubTask.taskStateId = newSubTask.getFirstState();
    newSubTask.id = newGuid();
    newSubTask.creatorId = appContext.user.id;
    newSubTask.creator = appContext.user;
    newSubTask.ownerId = appContext.user.id;
    newSubTask.owner = appContext.user;
    setNewSubTask(newSubTask);
  };

  const onAddSubTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      if (isNew) {
        const newTask = props.task.clone();
        if (!newTask.instances) newTask.instances = [];
        newTask.instances.push(task);
        props.onUpdate(newTask);
        setNewSubTask(undefined);
      }
    }
  };

  const onEditSubTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      onUpdateSubTask(task);
    }
  };

  const onRemoveSubTask = (task: Task) => {
    //not implemented, removing is done through the subtask list
  };

  const onUpdateSubTask = (task: Task) => {
    const newTask = props.task.clone();
    if (!newTask.instances) return;
    newTask.instances = newTask.instances.map((i) => (i.id === task.id ? task : i));
    props.onUpdate(newTask);
  };

  const removeSelectedSubTasks = () => {
    const newTask = props.task.clone();
    if (!newTask.instances) return;
    newTask.instances = newTask.instances.filter(
      (instance) => !selectedSubTasks.some((selectedSubTask) => selectedSubTask.id === instance.id),
    );
    props.onUpdate(newTask);
    if (newTask.instances.length === 0) {
      setSelectedSubTasks([]);
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <TaskDetailDescription
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
            {props.task.isSeries() && (
              <Fragment>
                <Stack
                  horizontal
                  tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                  verticalAlign="start"
                >
                  <Stack.Item>
                    {!appContext.isMobileView && <FontIcon iconName="FavoriteStarFill" style={iconFontSize} />}
                  </Stack.Item>
                  <Stack.Item>
                    <AuthSchemaPicker
                      selectedAuthSchemaId={props.task.authSchemaId}
                      onSelect={(schema) => {
                        const copyTask = props.task.clone();
                        copyTask.authSchemaId = schema?.authSchemaId;
                        props.onUpdate(copyTask);
                      }}
                    />
                  </Stack.Item>
                  <TaskDetailStatusActive
                    orgTask={props.orgTask}
                    task={props.task}
                    canUpdate={props.canUpdate}
                    onUpdate={props.onUpdate}
                    checkOnly={true}
                  />
                </Stack>
                <Stack.Item>
                  <Stack
                    horizontal
                    tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
                    verticalAlign="start"
                    styles={globalStackStylesHeight100}
                  >
                    <Stack.Item>
                      {!appContext.isMobileView && <FontIcon iconName="WorkFlow" style={iconFontSize} />}
                    </Stack.Item>
                    <Stack.Item>
                      <Stack>
                        <Label>{t('task:Subtasks.Label')}</Label>
                        <Stack horizontal tokens={globalStackTokensGapSmall}>
                          <ActionButton
                            onClick={() => addSubTask()}
                            iconProps={newIcon}
                            text={t('task:Subtasks.AddTask')}
                          />
                          <ActionButton
                            iconProps={deleteIcon}
                            text={t('tasks:TabAllTasks.Commands.Delete')}
                            disabled={selectedSubTasks.length === 0}
                            onClick={() => removeSelectedSubTasks()}
                          />
                          <TextInfo message={t('task:Subtasks.TemplateInfo')}>
                            <ActionButton
                              onClick={() => {
                                const copyTask = props.task.clone();
                                copyTask.instancesEnabled = !copyTask.instancesEnabled;
                                props.onUpdate(copyTask);
                              }}
                              iconProps={
                                props.task.instancesEnabled ? { iconName: 'WorkFlow' } : { iconName: 'DisableUpdates' }
                              }
                              text={t('task:Subtasks.InstancesButton', {
                                enabled: props.task.instancesEnabled
                                  ? t('task:Subtasks.InstancesButtonEnabled')
                                  : t('task:Subtasks.InstancesButtonDisabled'),
                              })}
                            />
                          </TextInfo>
                        </Stack>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item grow>
                  <SubTaskList
                    subTasks={props.task.instances ?? []}
                    selectionMode={SelectionMode.multiple}
                    isLoading={props.isActionPending}
                    onSubTaskClick={(task) => {
                      setEditSubTask(task);
                    }}
                    updateSubTasks={(newInstances) => {
                      const newTask = props.task.clone();
                      newTask.instances = newInstances;
                      props.onUpdate(newTask);
                    }}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    hideColumns={['deadline']}
                    onChangeSelection={setSelectedSubTasks}
                  />
                </Stack.Item>
              </Fragment>
            )}
            {newSubTask && (
              <SingleTask
                isOpen={newSubTask !== undefined}
                task={newSubTask}
                close={() => setNewSubTask(undefined)}
                onSave={onAddSubTask}
                onRemove={() => setNewSubTask(undefined)}
                onUpdate={onUpdateSubTask}
                windowLevel={(props.windowLevel ?? 0) + 1}
                disallowSave={true}
                navigateExternal={props.navigateExternal}
              />
            )}
            {editSubTask && (
              <SingleTask
                isOpen={editSubTask !== undefined}
                task={editSubTask}
                close={() => setEditSubTask(undefined)}
                onSave={onEditSubTask}
                onRemove={onRemoveSubTask}
                onUpdate={onUpdateSubTask}
                windowLevel={(props.windowLevel ?? 0) + 1}
                disallowSave={true}
                navigateExternal={props.navigateExternal}
              />
            )}
            {!props.task.isSeries() && (
              <Fragment>
                <TaskDetailAssignment
                  orgTask={props.orgTask}
                  task={props.task}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  getErrorMessage={props.getErrorMessage}
                />
                <TaskDetailStatus
                  task={props.task}
                  orgTask={props.orgTask}
                  canUpdate={props.canUpdate}
                  onUpdate={props.onUpdate}
                  onChangeCompletionDate={props.onChangeCompletionDate}
                  showCompleted={false}
                />
              </Fragment>
            )}
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
