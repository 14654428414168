import {
  Stack,
  Spinner,
  SpinnerSize,
  IStackStyles,
  Text,
  getTheme,
  Checkbox,
  Link,
  ComboBox,
  IComboBoxOption,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import DialogConfirmGeneric from 'components/Dialogs/DialogConfirmGeneric';
import { DialogOk } from 'components/Dialogs/DialogOk';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import OverlayLoader from 'components/Loading/OverlayLoader';
import StoreItem from 'components/Store/StoreItem';
import { globalAppName } from 'globalConstants';
import {
  globalBoxBorder,
  globalBoxBorderRadius,
  globalBoxShadow,
  globalPaddingScene,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
} from 'globalStyles';
import AdminTenant, { StoreMgmtStates } from 'models/adminTenant';
import Language from 'models/language';
import Package, {
  PackageCategories,
  PackageContent,
  PackageInstallPrecheckResult,
  PackageTenantStates,
} from 'models/package';
import { DefLanguageCode } from 'models/setting';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';
import { apiGetLanguages } from 'services/Api/languageService';
import {
  apiArchivePackage,
  apiDeInstallPackage,
  apiFinishInstallPackage,
  apiGetInstallPackageContent,
  apiGetInstallPackageProgress,
  apiGetPackageImports,
  apiGetRelatedPackagePrecheckResult,
  apiInstallPackage,
  apiInstallTestPackage,
  apiOrderPackage,
  apiRejectPackage,
  apiReleasePackage,
  apiWithdrawPackage,
} from 'services/Api/packageService';
import { apiGetTenants } from 'services/Api/tenantAdminService';
import { apiAddProcessLog } from 'services/Api/userService';
import { apiRequest } from 'services/Auth/authConfig';
import { ISite } from 'services/Graph/SharepointInterfaces';
import AppError from 'utils/appError';
import { navigateToExternalUrl } from 'utils/url';
import { getProgressLabelInstall, getProgressLabelInstallMaxSteps } from './InstallPackageHelpers';
import PackageInstallResultModal, { PackageInstallResultModes } from './PackageInstallResultModal';
import StoreFilter, { FilterStoreAllKey, FilterStoreGroupKeys } from './StoreFilter';
import StoreItemDetailsModal from './StoreItemDetailsModal';
import StoreItemSharePointInstallModal from './StoreItemSharePointInstallModal';
import { uploadContent } from './UploadContentToSharePoint';
import { toBool } from 'utils/string';
import { deleteContent } from './DeleteContentFromSharePoint';
import { getProgressLabelDeInstall } from './DeInstallPackageHelpers';
import { sortOnString } from 'utils/sorting';
import InfoMessage from 'components/Notification/InfoMessage';

interface IStoreProps {
  packages: Package[];
  packagesFilters?: string[];
  updatePackage: (pack: Package) => void;
  removePackage?: (pack: Package) => void;
  updateOrgLang?: (lang: Language) => void;
  invokeActivity?: (pack: Package) => void;
}

export const Store = (props: IStoreProps) => {
  const { t } = useTranslation(['store', 'translation']);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownLoading, setIsDownLoading] = useState<boolean>(false);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);
  const [packagesDisplay, setPackagesDisplay] = useState<Package[]>([]);
  const [packagesFilters, setPackagesFilters] = useState<string[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>(undefined);
  const [showItemDetails, setShowItemDetails] = useState<boolean>(false);
  const [showInstallConfirm, setShowInstallConfirm] = useState<boolean>(false);
  const [showSharePointConfig, setShowSharePointConfig] = useState<boolean>(false);
  const [timerInstallProgress, setTimerInstallProgress] = useState<NodeJS.Timeout | undefined>(undefined);
  const [packageInstallProgress, setPackageInstallProgress] = useState<number | undefined>(undefined);
  const [selectedSharePointSite, setSelectedSharePointSite] = useState<ISite | undefined>(undefined);
  const [isTestPackage, setIsTestPackage] = useState<boolean>(false);
  const [showOrderConfirm, setShowOrderConfirm] = useState<boolean>(false);
  const [showServiceOrderConfirm, setShowServiceOrderConfirm] = useState<boolean>(false);
  const [showOrderConfirmed, setShowOrderConfirmed] = useState<boolean>(false);
  const [showPayInfo, setShowPayInfo] = useState<boolean>(false);
  const [checkedContents, setCheckedContents] = useState<PackageContent[]>([]);
  const [installError, setInstallError] = useState<AppError | undefined>(undefined);
  const [showInstallResult, setShowInstallResult] = useState<PackageInstallResultModes>(PackageInstallResultModes.None);
  const [importedPackage, setImportedPackage] = useState<Package | undefined>(undefined);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedReleaseTenantId, setSelectedReleaseTenantId] = useState<string | undefined>(undefined);
  const [selectedWithdrawTenantId, setSelectedWithdrawTenantId] = useState<string | undefined>(undefined);
  const [showReleasePackage, setShowReleasePackage] = useState<boolean>(false);
  const [showWithdrawPackage, setShowWithdrawPackage] = useState<boolean>(false);
  const [showArchivePackage, setShowArchivePackage] = useState<boolean>(false);
  const [isReleaseTenantsLoading, setIsReleaseTenantsLoading] = useState<boolean>(false);
  const [isWithdrawTenantsLoading, setIsWithdrawTenantsLoading] = useState<boolean>(false);
  const [releaseTenants, setReleaseTenants] = useState<AdminTenant[]>([]);
  const [withdrawTenants, setWithdrawTenants] = useState<AdminTenant[]>([]);
  const [showRejectPackage, setShowRejectPackage] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [relatedPackagePrecheckResult, setRelatedPackagePrecheckResult] = useState<PackageInstallPrecheckResult>(
    PackageInstallPrecheckResult.None,
  );
  const [relatedPackagePrecheckMsg, setRelatedPackagePrecheckMsg] = useState<string>('');
  const [showRelatedPackagePrecheckMsg, setShowRelatedPackagePrecheckMsg] = useState<boolean>(false);
  const [removeProgress, setRemoveProgress] = useState<number>(0);
  const [removeProgressLabel, setRemoveProgressLabel] = useState<string | undefined>(undefined);
  const [showSharePointPackDeletionError, setShowSharePointPackDeletionError] = useState<boolean>(false);
  const [showSharePointPackDeletionConfirmation, setShowSharePointPackDeletionConfirmation] = useState<boolean>(false);
  const [timerDeInstallProgress, setTimerDeInstallProgress] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isDeInstalling, setIsDeInstalling] = useState<boolean>(false);

  const theme = getTheme();

  //
  // Styles
  //
  const stackStylesStoreItem: IStackStyles = {
    root: {
      paddingLeft: globalPaddingScene,
      paddingRight: globalPaddingScene,
      paddingTop: globalPaddingScene,
      paddingBottom: globalPaddingScene,
    },
  };

  const getStoreItemStyle = () => {
    return {
      root: {
        border: globalBoxBorder,
        borderRadius: globalBoxBorderRadius,
        boxShadow: globalBoxShadow,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 0,
        height: 400,
        width: 250,
        '&:hover': {
          background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighterAlt,
          cursor: 'pointer',
        },
      },
    };
  };

  //
  // Load data
  //
  useEffect(() => {
    loadData();

    return () => {
      clearInstallProgressTimer();
      clearDeInstallProgressTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.packagesFilters && props.packagesFilters.length > 0) {
      setPackagesFilters(props.packagesFilters)
      applyFilters(props.packagesFilters, searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.packagesFilters]);

  useEffect(() => {
    applyFilters(packagesFilters, searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.packages]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const langCol = await apiGetLanguages(accessToken);
      setLanguages(langCol.languages);
      setPackagesDisplay(props.packages);
      setFilterFromURLParams();
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setFilterFromURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('search')) {
      const query = queryParams.get('search');
      if (query) {
        setSearchQuery(query);
        applyFilters(packagesFilters, query);
      }
    }
  };

  const loadReleaseTenants = async () => {
    try {
      setIsReleaseTenantsLoading(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const tenants = await apiGetTenants(accessToken);
      const tenantsWithoutPackageAndLiveStoreState = tenants.filter(
        (t) => t.storeMgmtState === StoreMgmtStates.Live && t.package === undefined,
      );
      setReleaseTenants(tenantsWithoutPackageAndLiveStoreState);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsReleaseTenantsLoading(false);
    }
  };

  const loadWithdrawTenants = async () => {
    try {
      setIsWithdrawTenantsLoading(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const tenants = await apiGetTenants(accessToken);
      const tenantsWithoutPackageAndCreatorStoreState = tenants.filter(
        (t) => t.storeMgmtState === StoreMgmtStates.Creator && t.package === undefined,
      );
      setWithdrawTenants(tenantsWithoutPackageAndCreatorStoreState);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsWithdrawTenantsLoading(false);
    }
  };

  //
  // Helpers
  //
  const updateFilter = (filters: string[], query: string | undefined) => {
    setPackagesFilters(filters);
    setSearchQuery(query);
    applyFilters(filters, query);
  };

  const applyFilters = (filters: string[], query: string | undefined) => {
    let newPackages: Package[] = [];
    const languageFilterList: string[] = [];
    const freeFilterList: string[] = [];

    filters.forEach((filter: string) => {
      const filterGroup = filter.split('$');
      const filterType = filterGroup[0] + '$';
      const filterValue = filterGroup[1];

      switch (filterType) {
        case FilterStoreGroupKeys.language:
          languageFilterList.push(filterValue);
          break;
        case FilterStoreGroupKeys.freePreview:
          freeFilterList.push(filterValue);
          break;
        default:
          break;
      }
    });

    props.packages.forEach((item: Package) => {
      let languageFilterCheck = true;
      let freeFilterListCheck = true;

      if (
        languageFilterList.length > 0 &&
        languageFilterList[0] !== FilterStoreAllKey &&
        languageFilterList[0] !== item.primaryLanguage
      ) {
        languageFilterCheck = false;
      }

      if (freeFilterList.length > 0) {
        const isFreeOrPreview = toBool(freeFilterList[0]);
        if (
          isFreeOrPreview === true &&
          ((item.isPreview !== true && item.price > 0) || item.categoryId === PackageCategories.Service)
        ) {
          freeFilterListCheck = false;
        }
      }

      if (languageFilterCheck && freeFilterListCheck) {
        newPackages.push(item);
      }
    });

    if (query) {
      const queryLower = query.toLowerCase();
      newPackages = newPackages.filter(
        (p) => p.name.toLowerCase().includes(queryLower) || p.description.toLowerCase().includes(queryLower),
      );
    }

    setPackagesDisplay(newPackages);
  };

  const isPackageInstalled = (pack: Package): boolean => {
    if (pack.categoryId === PackageCategories.Service) {
      return false;
    } else {
      if (pack.tenants && pack.tenants.length > 0) {
        //customer has started the purchase process, show the next action
        const tenant = pack.tenants[0];
        switch (tenant.state) {
          case PackageTenantStates.Available:
          case PackageTenantStates.Ordered:
          case PackageTenantStates.Paid:
            return false;
          case PackageTenantStates.Activated:
            return true;
          case PackageTenantStates.Installing:
            //installation was aborted server-side due to an error and before calling API '/packages/install/finish'
            setInstallError(new AppError(t('store:TabStore.Install.Aborted')));

            return true;
          case PackageTenantStates.ContentUpload:
            //installation was aborted client-side due to an error / manually and before calling API '/packages/install/finish'
            setInstallError(new AppError(t('store:TabStore.Install.Aborted')));

            return true;
          default:
            return false;
        }
      } else {
        //customer has not yet started the purchase process
        return false;
      }
    }
  };

  const updatePackageStateInStore = (updatePack: Package | undefined) => {
    if (!updatePack) return;
    props.updatePackage(updatePack);
  };

  //
  // Install process
  //
  useEffect(() => {
    if (isInstalling) {
      resetInstallProgressTimer();
    } else {
      clearInstallProgressTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstalling]);

  useEffect(() => {
    let msg = '';
    switch (relatedPackagePrecheckResult) {
      case PackageInstallPrecheckResult.PackageForAddOnNotInstalled:
        msg = t('store:TabStore.Install.PrecheckErrors.PackageForAddOnNotInstalled');
        break;
      case PackageInstallPrecheckResult.PackageForPreviewAlreadyInstalled:
        msg = t('store:TabStore.Install.PrecheckErrors.PackageForPreviewAlreadyInstalled');
        break;
      default:
        break;
    }
    if (msg) {
      setRelatedPackagePrecheckMsg(msg);
      setShowRelatedPackagePrecheckMsg(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedPackagePrecheckResult]);

  //
  // Progress install
  //
  const resetInstallProgressTimer = () => {
    clearInstallProgressTimer();
    const timer = setTimeout(() => getInstallProgress(), 500);
    setTimerInstallProgress(timer);
  };

  const clearInstallProgressTimer = () => {
    if (timerInstallProgress) {
      clearTimeout(timerInstallProgress);
      setTimerInstallProgress(undefined);
    }
  };

  const getInstallProgress = async () => {
    try {
      if (!selectedPackage) return;

      //determine the target tenant Id for this installation
      let tenantId: string = '';
      if (selectedWithdrawTenantId) {
        tenantId = selectedWithdrawTenantId;
      } else if (selectedReleaseTenantId) {
        tenantId = selectedReleaseTenantId;
      } else {
        tenantId = appContext.user.login.tenantId;
      }

      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const progress = await apiGetInstallPackageProgress(selectedPackage, tenantId, accessToken);
      setPackageInstallProgress(progress);
      if (progress) {
        resetInstallProgressTimer();
      } else {
        setPackageInstallProgress(getProgressLabelInstallMaxSteps);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  //
  // Progress de-install
  //
  useEffect(() => {
    if (isDeInstalling) {
      resetDeInstallProgressTimer();
    } else {
      clearDeInstallProgressTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeInstalling]);

  const resetDeInstallProgressTimer = () => {
    clearDeInstallProgressTimer();
    const timer = setTimeout(() => getDeInstallProgress(), 350);
    setTimerDeInstallProgress(timer);
  };

  const clearDeInstallProgressTimer = () => {
    if (timerDeInstallProgress) {
      clearTimeout(timerDeInstallProgress);
      setTimerDeInstallProgress(undefined);
    }
  };

  const getDeInstallProgress = async () => {
    try {
      if (!selectedPackage) return;

      //determine the target tenant Id for this installation
      const tenantId = appContext.user.login.tenantId;

      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const progress = await apiGetInstallPackageProgress(selectedPackage, tenantId, accessToken);
      if (progress) {
        setRemoveProgress(((progress ?? 0) / 10) * 100);
        setRemoveProgressLabel(getProgressLabelDeInstall(progress + 2, '', t));
        resetDeInstallProgressTimer();
      } else {
        setRemoveProgress(0);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  //
  // Entry point for installing
  //
  const invokeBuyButton = async (pack: Package) => {
    try {
      setIsTestPackage(false);
      setSelectedPackage(pack);

      if (pack.categoryId === PackageCategories.Service) {
        //service packages can be ordered directly
        //this will set the package to status activated and the user is re-directed to the web url of the package
        if (pack.notificationEmail) {
          setShowServiceOrderConfirm(true);
        } else {
          startOrder();
        }
      } else {
        const isInstalled = isPackageInstalled(pack);
        if (isInstalled) {
          setShowInstallResult(PackageInstallResultModes.ReInstall);
        } else {
          let state: PackageTenantStates = PackageTenantStates.Available;
          let canInstall: boolean = false;
          if (pack.tenants.length === 1) {
            state = pack.tenants[0].state;
          }
          switch (state) {
            case PackageTenantStates.Available:
              if (pack.price === 0) {
                canInstall = true;
              } else {
                setShowOrderConfirm(true);
              }
              break;
            case PackageTenantStates.Ordered:
              //wait for payment when price > 0
              if (pack.price === 0) {
                canInstall = true;
              } else {
                setShowPayInfo(true);
              }
              break;
            case PackageTenantStates.Paid:
            case PackageTenantStates.Activated:
            case PackageTenantStates.ContentUpload:
            case PackageTenantStates.Installing:
              //in these statusses, the customer can activate or re-activate the package
              canInstall = true;
              break;
          }

          if (canInstall) {
            const result = await getRelatedPackagePrecheckResult(pack);
            if (result) {
              //cannot install due to conflicts with related packages
              setRelatedPackagePrecheckResult(result);
            } else {
              setIsDownLoading(true);
              let needSharePoint: boolean = false;
              if (!pack.isPreview) {
                needSharePoint = await getSharePointInstallableContent(pack);
              }
              setShowSharePointConfig(needSharePoint);
              if (!needSharePoint) {
                setShowInstallConfirm(true);
              }
            }
          }
        }
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsDownLoading(false);
    }
  };

  const invokeReleasePackageButton = async (pack: Package) => {
    try {
      setSelectedPackage(pack);

      if (pack.categoryId === PackageCategories.Service) {
        //service packages are released into their own tenant so no need to select a tenant
        setShowReleasePackage(true);
      } else {
        //load available release tenants and show the selection dialog
        await loadReleaseTenants();
        setShowReleasePackage(true);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const invokeWithdrawPackageButton = async (pack: Package) => {
    try {
      setSelectedPackage(pack);

      if (pack.categoryId === PackageCategories.Service) {
        //service packages are withdrawed into their own tenant so no need to select a tenant
        setShowWithdrawPackage(true);
      } else {
        await loadWithdrawTenants();
        setShowWithdrawPackage(true);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const invokeArchivePackageButton = async (pack: Package) => {
    try {
      setSelectedPackage(pack);
      setShowArchivePackage(true);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const releasePackage = async () => {
    try {
      setShowReleasePackage(false);
      if (!selectedPackage) return;
      let releaseTenant: string | undefined = selectedReleaseTenantId;
      if (selectedPackage.categoryId === PackageCategories.Service) {
        //for service packages, the release tenant must be the package tenant
        releaseTenant = selectedPackage.tenantId;
      } else {
        //for other package categories, the release tenant must be selected
        if (!releaseTenant) return;
      }
      setIsInstalling(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const newPack = await apiReleasePackage(selectedPackage, releaseTenant, accessToken);
      if (props.removePackage) props.removePackage(newPack);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsInstalling(false);
      setSelectedReleaseTenantId(undefined);
    }
  };

  const withdrawPackage = async () => {
    try {
      setShowWithdrawPackage(false);
      if (!selectedPackage) return;
      let withdrawTenant: string | undefined = selectedWithdrawTenantId;
      if (selectedPackage.categoryId === PackageCategories.Service) {
        //for service packages, the withdraw tenant must be the package tenant
        withdrawTenant = selectedPackage.tenantId;
      } else {
        //for other package categories, the withdraw tenant must be selected
        if (!withdrawTenant) return;
      }
      setIsInstalling(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const newPack = await apiWithdrawPackage(selectedPackage, withdrawTenant, accessToken);
      if (props.updatePackage) props.updatePackage(newPack);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsInstalling(false);
      setSelectedWithdrawTenantId(undefined);
    }
  };

  const archivePackage = async () => {
    try {
      if (!selectedPackage) return;
      setShowArchivePackage(false);
      setIsLoading(true);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const newPack = await apiArchivePackage(selectedPackage, accessToken);
      if (props.updatePackage) props.updatePackage(newPack);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const invokeRejectPackageButton = (pack: Package) => {
    setSelectedPackage(pack);
    setShowRejectPackage(true);
  };

  const rejectPackage = async () => {
    try {
      if (!selectedPackage) return;
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const newPack = await apiRejectPackage(selectedPackage, accessToken);
      if (props.removePackage) props.removePackage(newPack);
      setShowRejectPackage(false);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsDownLoading(false);
    }
  };

  const invokeInstallTestPackageButton = async (pack: Package) => {
    try {
      setSelectedPackage(pack);
      setIsTestPackage(true);
      setIsDownLoading(true);

      let needSharePoint: boolean = false;
      if (!pack.isPreview) {
        needSharePoint = await getSharePointInstallableContent(pack);
      }

      setShowSharePointConfig(needSharePoint);
      if (!needSharePoint) {
        installTestPackage(pack, pack.isPreview);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsDownLoading(false);
    }
  };

  const invokeReInstallPackageButton = async (pack: Package | undefined) => {
    try {
      if (!pack) return;
      setShowInstallResult(PackageInstallResultModes.None);
      setSelectedPackage(pack);
      setIsTestPackage(false);
      setIsDownLoading(true);

      let needSharePoint: boolean = false;
      if (!pack.isPreview) {
        needSharePoint = await getSharePointInstallableContent(pack);
      }

      setShowSharePointConfig(needSharePoint);
      if (!needSharePoint) {
        installPackage(pack, pack.isPreview);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsDownLoading(false);
    }
  };

  const setRemovePackageProgress = (progress: number) => {
    setRemoveProgress((progress / 10) * 100);
    setRemoveProgressLabel(getProgressLabelDeInstall(progress, '', t));
  };

  const setRemovePackageProgressLabelCallback = (label: string) => {
    setRemoveProgressLabel(getProgressLabelDeInstall(2, label, t));
  };

  const removePackage = async (pack: Package | undefined) => {
    try {
      if (!pack) return;
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      setRemovePackageProgress(1);

      //get package content
      pack = await apiGetInstallPackageContent(pack, accessToken);
      pack.imports = await apiGetPackageImports(pack, accessToken);

      //remove package from SharePoint
      setRemovePackageProgress(2);
      const hasError = await deleteContent(
        pack,
        pack.imports,
        pack.contents,
        appContext,
        setRemovePackageProgressLabelCallback,
        t,
      );

      //remove package from back-end
      setIsDeInstalling(true);
      pack = await apiDeInstallPackage(pack, accessToken);
      await appContext.globalDataCache.refresh();

      //update package state in Store
      updatePackageStateInStore(pack);

      //update state in step
      if (hasError) {
        setShowSharePointPackDeletionError(true);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setRemoveProgress(0);
      setIsDeInstalling(false);
    }
  };

  const getRelatedPackagePrecheckResult = async (pack: Package): Promise<PackageInstallPrecheckResult> => {
    const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);

    return await apiGetRelatedPackagePrecheckResult(pack, accessToken);
  };

  const getSharePointInstallableContent = async (pack: Package): Promise<boolean> => {
    const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
    const newPack = await apiGetInstallPackageContent(pack, accessToken);
    setSelectedPackage(newPack);
    if (newPack && newPack.sharePointLinks.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //this is called when the customer does order the package
  const startOrder = async () => {
    try {
      if (!selectedPackage) return;
      setShowOrderConfirm(false);
      setShowServiceOrderConfirm(false);

      if (selectedPackage.categoryId === PackageCategories.Service) {
        if (selectedPackage.webURL) {
          navigateToExternalUrl(selectedPackage.webURL, '', '');
        } else {
          throw new AppError('Package has no webURL: ' + selectedPackage.name);
        }
      }

      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const newPack = await apiOrderPackage(selectedPackage, accessToken);
      updatePackageStateInStore(newPack);

      if (selectedPackage.categoryId !== PackageCategories.Service) {
        setShowOrderConfirmed(true);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  //this is called after confirmation
  const startInstall = async () => {
    try {
      if (!selectedPackage) return;
      setShowInstallConfirm(false);
      setShowSharePointConfig(false);

      if (isTestPackage) {
        await installTestPackage(selectedPackage, selectedPackage.isPreview);
      } else {
        await installPackage(selectedPackage, selectedPackage.isPreview);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const installPackage = async (pack: Package | undefined, isVirtual: boolean) => {
    if (!pack) return;

    try {
      appContext.showContentLoader();
      setShowInstallConfirm(false);
      setIsInstalling(true);
      setInstallError(undefined);

      let accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const packageToImport = await apiInstallPackage(pack, isVirtual, accessToken);

      clearInstallProgressTimer();
      setImportedPackage(packageToImport);
      await appContext.globalDataCache.refresh();

      if (packageToImport) {
        setPackageInstallProgress(getProgressLabelInstallMaxSteps);
        const log = await uploadContent(packageToImport, checkedContents, selectedSharePointSite, appContext);
        accessToken = await appContext.getAccessToken(apiRequest.scopes); //request new access token because the operations take some time
        if (log) {
          await apiAddProcessLog(log, accessToken);
          if (log.appError) {
            throw log.appError;
          }
        }
        pack = await apiFinishInstallPackage(packageToImport, accessToken);
        updatePackageStateInStore(pack);
      } else {
        throw new AppError('Could not install package for unknown reason');
      }
    } catch (err) {
      setInstallError(err as AppError);
    } finally {
      setIsInstalling(false);
      appContext.hideContentLoader();
      setShowInstallResult(PackageInstallResultModes.AfterInstall);
      setPackageInstallProgress(undefined);
    }
  };

  const installTestPackage = async (pack: Package | undefined, isVirtual: boolean) => {
    if (!pack) return;

    try {
      appContext.showContentLoader();
      setIsInstalling(true);
      setInstallError(undefined);

      let accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const packageToImport = await apiInstallTestPackage(pack, isVirtual, accessToken);

      clearInstallProgressTimer();
      setPackageInstallProgress(8);
      setImportedPackage(packageToImport);
      await appContext.globalDataCache.refresh();

      if (packageToImport) {
        const log = await uploadContent(packageToImport, checkedContents, selectedSharePointSite, appContext);
        accessToken = await appContext.getAccessToken(apiRequest.scopes); //request new access token because the operations take some time
        //for now, always upload the client log
        if (log) {
          // && log.level === DBProcesLoggerLogLevel.Error
          await apiAddProcessLog(log, accessToken);
          if (log.appError) {
            throw log.appError;
          }
        }
        pack = await apiFinishInstallPackage(packageToImport, accessToken);
        updatePackageStateInStore(pack);
      } else {
        throw new AppError('Could not install package for unknown reason');
      }
    } catch (err) {
      setInstallError(err as AppError);
    } finally {
      setIsInstalling(false);
      appContext.hideContentLoader();
      setShowInstallResult(PackageInstallResultModes.AfterInstall);
    }
  };

  //
  // Render helpers
  //
  const openGeneralSettings = () => {
    const url = '/admin/settings/general';
    history.push(url);
  };

  const getShowReleasePackageJSX = (): JSX.Element | undefined => {
    if (selectedPackage && selectedPackage.categoryId === PackageCategories.Service) {
      return undefined;
    }

    if (isReleaseTenantsLoading) {
      return <Spinner size={SpinnerSize.large} />;
    }

    const releaseTenantOptions: IComboBoxOption[] = releaseTenants
      .map((r) => {
        return {
          key: r.tenantId,
          text: r.name,
        };
      })
      .sort((a, b) => sortOnString(a.text, b.text));

    return (
      <Stack tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text>
            {
              'Select the tenant the package will be released to in a Live state. Only tenants with the store management role Live are available. All tenant data will be removed before releasing!'
            }
          </Text>
        </Stack.Item>
        <Stack.Item>
          <ComboBox
            onChange={(ev, option) => setSelectedReleaseTenantId(option?.key as string)}
            options={releaseTenantOptions}
            useComboBoxAsMenuWidth
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getShowWithdrawPackageJSX = (): JSX.Element | undefined => {
    if (selectedPackage && selectedPackage.categoryId === PackageCategories.Service) {
      return undefined;
    }

    if (isWithdrawTenantsLoading) {
      return <Spinner size={SpinnerSize.large} />;
    }

    const withdrawTenantOptions: IComboBoxOption[] = withdrawTenants
      .map((r) => {
        return {
          key: r.tenantId,
          text: r.name,
        };
      })
      .sort((a, b) => sortOnString(a.text, b.text));

    return (
      <Stack tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text>
            {
              'Select the tenant the package will be withdrawn to from the Live state. Only tenants with the store management role Creator are available. Note that only the package itself is withdrawn and not the content. When the target tenant does not contain the package contents, you should create a new version from this live package before you withdraw this one.'
            }
          </Text>
        </Stack.Item>
        <Stack.Item>
          <ComboBox
            onChange={(ev, option) => setSelectedWithdrawTenantId(option?.key as string)}
            options={withdrawTenantOptions}
            useComboBoxAsMenuWidth
          />
        </Stack.Item>
      </Stack>
    );
  };

  const getShowOrderConfirmJSX = (): JSX.Element | undefined => {
    if (!selectedPackage) return undefined;

    //determine the language of the organization and the package
    const defLang = appContext.globalDataCache.settings.get(DefLanguageCode) as string;
    const defLanguage = defLang && languages.find((l) => l.code === defLang.toLowerCase());
    const packageLanguage = languages.find((l) => l.code === selectedPackage.primaryLanguage.toLowerCase());
    let displayLangOptions: boolean = false;

    if (defLang && defLanguage && packageLanguage) {
      if (defLang.toLowerCase() !== selectedPackage.primaryLanguage.toLowerCase()) {
        displayLangOptions = true; //when they differ, show a warning and options
      }
    }

    return (
      <Stack tokens={globalStackTokensGapSmall}>
        {appContext.user.login.isInTrial() && (
          <Stack.Item>
            <Text>{t('store:TabStore.Install.Dialogs.OrderConfirm.TrialInfo', { appName: globalAppName })}</Text>
          </Stack.Item>
        )}
        {displayLangOptions && packageLanguage && defLanguage && (
          <Fragment>
            <Stack.Item>
              <Text>
                {t('store:TabStore.Install.Dialogs.OrderConfirm.OrgLangInfo', {
                  packageLang: packageLanguage.name,
                  orgLang: defLanguage.name,
                })}
              </Text>
            </Stack.Item>
            <Stack.Item>
              {!props.updateOrgLang && (
                <Link onClick={() => openGeneralSettings()}>
                  {t('store:TabStore.Install.Dialogs.OrderConfirm.OrgLangInfoSettingLink')}
                </Link>
              )}
            </Stack.Item>
            <Stack.Item>
              {props.updateOrgLang && (
                <Checkbox
                  onChange={() => {
                    if (props.updateOrgLang) props.updateOrgLang(packageLanguage);
                  }}
                  label={t('store:TabStore.Install.Dialogs.OrderConfirm.OrgLangInfoCheckbox', {
                    packageLang: packageLanguage.name,
                  })}
                />
              )}
            </Stack.Item>
          </Fragment>
        )}
        <Stack.Item>
          <Text>{t('store:TabStore.Install.Dialogs.OrderConfirm.Confirm')}</Text>
        </Stack.Item>
      </Stack>
    );
  };

  const getShowServiceOrderConfirmJSX = (): JSX.Element | undefined => {
    if (!selectedPackage) return undefined;

    return (
      <Stack tokens={globalStackTokensGapSmall}>
        <ul>
          <li>{t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.Data1')}</li>
          <li>{t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.Data2')}</li>
          <li>{t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.Data3')}</li>
        </ul>
        <Stack.Item>
          <Text>{t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.Confirm')}</Text>
        </Stack.Item>
      </Stack>
    );
  };

  const afterOrderConfirm = () => {
    setShowOrderConfirmed(false);
    if (appContext.user.login.isInTrial()) {
      history.push('/admin/subscription');
    }
  };

  //
  // Main Render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <StoreFilter
          packages={props.packages}
          filters={packagesFilters}
          searchQuery={searchQuery}
          updateFilters={(filters) => updateFilter(filters, searchQuery)}
          updateTextFilters={(searchQuery) => updateFilter(packagesFilters, searchQuery)}
          languages={languages}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal wrap styles={stackStylesStoreItem} tokens={globalStackTokensGapMedium}>
          {isInstalling && (
            <OverlayLoader
              text={t('store:TabStore.Install.Progress')}
              showProgress={true}
              progressPercent={(1 / getProgressLabelInstallMaxSteps) * (packageInstallProgress || 0)}
              progressLabel={getProgressLabelInstall(packageInstallProgress, t as TFunction<string[]>)}
            />
          )}
          {removeProgress > 0 && (
            <OverlayLoader
              progressPercent={removeProgress / 100}
              showProgress={true}
              text={t('store:TabStore.Install.Dialogs.DeinstallPreview.Progress')}
              progressLabel={removeProgressLabel}
            />
          )}
          {isDownLoading && <OverlayLoader text={t('store:TabStore.Install.Download')} />}
          {packagesDisplay.map((pack) => {
            return (
              <Stack.Item
                key={pack.packageId}
                styles={getStoreItemStyle()}
                onClick={(ev) => {
                  const element = ev.target as HTMLElement;
                  if (element) {
                    const className = element.className.toLowerCase();
                    if (!(className.indexOf('button') >= 0 || className.indexOf('link') >= 0)) {
                      setSelectedPackage(pack);
                      setShowItemDetails(true);
                    }
                  }
                }}
              >
                <StoreItem
                  pack={pack}
                  invokeInstallTestPackageButton={invokeInstallTestPackageButton}
                  invokeRejectPackageButton={invokeRejectPackageButton}
                  invokeReleasePackageButton={invokeReleasePackageButton}
                  invokeBuyButton={invokeBuyButton}
                  invokeWithdrawPackageButton={invokeWithdrawPackageButton}
                  invokeArchivePackageButton={invokeArchivePackageButton}
                  invokeActivity={props.invokeActivity}
                />
              </Stack.Item>
            );
          })}
          {selectedPackage && (
            <StoreItemDetailsModal
              pack={selectedPackage}
              isOpen={showItemDetails}
              onDismiss={() => setShowItemDetails(false)}
              onInstall={(pack) => {
                setShowItemDetails(false);
                invokeBuyButton(pack);
              }}
            />
          )}
          {showSharePointConfig && selectedPackage && (
            <StoreItemSharePointInstallModal
              isOpen={showSharePointConfig}
              pack={selectedPackage}
              selectedSite={selectedSharePointSite}
              onSelectSite={setSelectedSharePointSite}
              onDismiss={() => {
                setShowSharePointConfig(false);
              }}
              onInstall={(contents) => {
                setCheckedContents(contents);
                setShowInstallConfirm(true);
              }}
              onUpdatePackage={updatePackageStateInStore}
            />
          )}
          <DialogConfirmGeneric
            hidden={!showInstallConfirm}
            title={t('store:TabStore.Install.Dialogs.InstallConfirm.Title')}
            subText={t('store:TabStore.Install.Dialogs.InstallConfirm.SubText')}
            onNo={() => setShowInstallConfirm(false)}
            onYes={() => startInstall()}
            confirmText={t('store:TabStore.Install.Dialogs.InstallConfirm.ConfirmText')}
            hideWarning={selectedPackage?.isPreview}
            optionalJSX={
              selectedPackage?.isPreview && showSharePointConfig === false ? (
                <Stack tokens={globalStackTokensGapSmall}>
                  <Stack.Item>
                    <InfoMessage message={t('store:TabStore.Install.Dialogs.InstallConfirm.InstallSharePointInfo')}>
                      <Link
                        styles={{ root: { paddingTop: 10 } }}
                        onClick={() => {
                          setShowInstallConfirm(false);
                          setShowSharePointConfig(true);
                        }}
                      >
                        {t('store:TabStore.Install.Dialogs.InstallConfirm.InstallSharePoint')}
                      </Link>
                    </InfoMessage>
                  </Stack.Item>
                </Stack>
              ) : undefined
            }
            optionalJSXHeight={180}
          />
          <DialogYesNo
            hidden={!showOrderConfirm}
            title={t('store:TabStore.Install.Dialogs.OrderConfirm.Title')}
            subText={t('store:TabStore.Install.Dialogs.OrderConfirm.SubText')}
            onNo={() => setShowOrderConfirm(false)}
            onYes={() => startOrder()}
            optionalJSX={getShowOrderConfirmJSX()}
            optionalJSXGrow={true}
          />
          <DialogYesNo
            hidden={!showServiceOrderConfirm}
            title={t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.Title')}
            subText={t('store:TabStore.Install.Dialogs.ServiceOrderConfirm.SubText')}
            onNo={() => setShowServiceOrderConfirm(false)}
            onYes={() => startOrder()}
            optionalJSX={getShowServiceOrderConfirmJSX()}
            optionalJSXGrow={true}
          />
          <DialogConfirmGeneric
            hidden={!showReleasePackage}
            title={'Release package'}
            subText={'Are you sure you want to continu?'}
            onNo={() => setShowReleasePackage(false)}
            onYes={() => releasePackage()}
            optionalJSX={getShowReleasePackageJSX()}
            optionalJSXHeight={220}
          />
          <DialogConfirmGeneric
            hidden={!showWithdrawPackage}
            title={'Withdraw package'}
            subText={'Are you sure you want to continu?'}
            onNo={() => setShowWithdrawPackage(false)}
            onYes={() => withdrawPackage()}
            optionalJSX={getShowWithdrawPackageJSX()}
            optionalJSXHeight={220}
          />
          <DialogConfirmGeneric
            hidden={!showArchivePackage}
            title={'Archive package'}
            subText={
              'This package will be archived. That means that this package is not available in the Store anymore, except for customers who have activated the package. Are you sure you want to continu?'
            }
            onNo={() => setShowArchivePackage(false)}
            onYes={() => archivePackage()}
          />
          <DialogYesNo
            hidden={!showRejectPackage}
            title={'Reject package'}
            subText={'Are you sure you want to continu?'}
            onNo={() => setShowRejectPackage(false)}
            onYes={() => rejectPackage()}
          />
          <DialogOk
            hidden={!showOrderConfirmed}
            title={t('store:TabStore.Install.Dialogs.OrderConfirmed.Title')}
            subText={t('store:TabStore.Install.Dialogs.OrderConfirmed.SubText', { email: appContext.user.email })}
            onOk={() => afterOrderConfirm()}
          />
          <DialogOk
            hidden={!showPayInfo}
            title={t('store:TabStore.Install.Dialogs.PayInfo.Title')}
            subText={t('store:TabStore.Install.Dialogs.PayInfo.SubText', { email: appContext.user.email })}
            onOk={() => setShowPayInfo(false)}
          />
          <DialogOk
            hidden={!showRelatedPackagePrecheckMsg}
            title={t('store:TabStore.Install.Dialogs.RelatedPackagePrecheckMsg.Title')}
            subText={relatedPackagePrecheckMsg}
            onOk={() => {
              setShowRelatedPackagePrecheckMsg(false);
              setRelatedPackagePrecheckResult(PackageInstallPrecheckResult.None);
            }}
          />
          <PackageInstallResultModal
            mode={showInstallResult}
            isOpen={showInstallResult !== PackageInstallResultModes.None}
            pack={selectedPackage}
            importedPack={importedPackage}
            installErr={installError}
            onDismiss={() => {
              setInstallError(undefined);
              setImportedPackage(undefined);
              setShowInstallResult(PackageInstallResultModes.None);
            }}
            onReInstall={() => invokeReInstallPackageButton(selectedPackage)}
            onRemove={() => setShowSharePointPackDeletionConfirmation(true)}
          />
          <DialogOk
            hidden={!showSharePointPackDeletionError}
            title={t('store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesErrorDialogTitle')}
            subText={t('store:TabStore.Install.Dialogs.SharePointConfig.RemovePreviewPackagesErrorDialogSubText')}
            onOk={() => setShowSharePointPackDeletionError(false)}
          />
          <DialogYesNo
            hidden={!showSharePointPackDeletionConfirmation}
            title={t('store:TabStore.Install.Dialogs.RemoveConfirm.Title')}
            subText={t('store:TabStore.Install.Dialogs.RemoveConfirm.SubText')}
            onNo={() => setShowSharePointPackDeletionConfirmation(false)}
            onYes={() => {
              setShowSharePointPackDeletionConfirmation(false);
              setShowInstallResult(PackageInstallResultModes.None);
              removePackage(selectedPackage);
            }}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
