import { useContext, useEffect, useState } from 'react';
import {
  PrimaryButton,
  Text,
  DefaultButton,
  DialogFooter,
  Modal,
  Stack,
  IconButton,
  SelectedPeopleList,
  IExtendedPersonaProps,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, cancelIcon } from 'globalStyles';
import Dashboard, { DashboardTypes } from 'models/dashboard';
import User from 'models/user';
import UserPicker from 'components/Pickers/UserPicker';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import AppContext from 'App/AppContext';
import { DialogOk } from 'components/Dialogs/DialogOk';

interface IShareDashboardModalProps {
  currentDashboard: Dashboard | undefined;
  isOpen: boolean;
  onSave: (dashboard: Dashboard) => void;
  onClose: () => void;
}

const ShareDashboardModal = (props: IShareDashboardModalProps) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined);
  const [showCannotDeleteSharedUser, setShowCannotDeleteSharedUser] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      setDashboard(props.currentDashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentDashboard, props.isOpen]);

  const hasError = (): boolean => {
    return false;
  };

  const onSave = () => {
    if (!dashboard) return;
    props.onSave(dashboard);
  };

  const getPersonaProps = (): IExtendedPersonaProps[] => {
    if (!dashboard || !dashboard.users) return [];
    const personaProps: IExtendedPersonaProps[] = [];

    for (let user of dashboard.users) {
      if (dashboard.dashboardType === DashboardTypes.user) {
        if (user.id === dashboard.createdById) {
          //filter the creator for user dashboards because the creator is always present in the user list
          continue;
        }
      }

      let personaProp: IExtendedPersonaProps = {
        text: user.name,
        secondaryText: user.email,
        key: user.id,
        isValid: true,
      };

      personaProps.push(personaProp);
    }

    return personaProps;
  };

  const onItemsDeleted = (personaProps: IExtendedPersonaProps[]) => {
    if (!dashboard) return [];
    const newDashboard = dashboard.clone();
    const newUsers = newDashboard.users?.filter((user) => !personaProps.some((p) => p.key === user.id));
    newDashboard.users = newUsers;
    setDashboard(newDashboard);
  };

  const onSelectUser = (item: User | undefined) => {
    if (!dashboard || !item) return [];
    const newDashboard = dashboard.clone();
    newDashboard.users?.push(item);
    setDashboard(newDashboard);
  };

  const onCanRemoveItem = (item: IExtendedPersonaProps): boolean => {
    if (props.currentDashboard?.isSharedWithUser(appContext.user.id)) {
      //can only remove yourself on a shared dashboard
      if (item.key === appContext.user.id) {
        return true;
      } else {
        setShowCannotDeleteSharedUser(true);

        return false;
      }
    }

    return true;
  };

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onClose}>
      <Stack verticalFill styles={{ root: { padding: 20, maxWidth: 600 } }} tokens={globalStackTokensGapMedium}>
        <Stack horizontal horizontalAlign={'space-between'}>
          <Text variant="xLarge">{t('dashboard:Share.Title')}</Text>
          <IconButton iconProps={cancelIcon} onClick={props.onClose} />
        </Stack>
        <Stack.Item>
          <Text>
            {t('dashboard:Share.SubText', {
              creator:
                dashboard?.createdById !== appContext.user.id
                  ? dashboard?.createdBy?.name
                  : t('dashboard:Share.CreatedByYou'),
              created: toLocaleDateTimeMedium(dashboard?.created),
            })}
          </Text>
        </Stack.Item>
        {dashboard?.isShared() && (
          <Stack.Item>
            <Text>{t('dashboard:Share.Selected')}</Text>
          </Stack.Item>
        )}
        <Stack.Item>
          <SelectedPeopleList
            selectedItems={getPersonaProps()}
            onItemsDeleted={onItemsDeleted}
            canRemoveItem={onCanRemoveItem}
          />
        </Stack.Item>
        <Stack.Item>
          <UserPicker
            onSelect={onSelectUser}
            users={[]}
            placeHolder={t('dashboard:Share.Placeholder')}
            clearAfterSelect={true}
            usersToExclude={dashboard?.users}
          />
        </Stack.Item>
        <DialogOk
          hidden={!showCannotDeleteSharedUser}
          onOk={() => setShowCannotDeleteSharedUser(false)}
          title={t('dashboard:Share.Dialogs.PreventRemove.Title')}
          subText={t('dashboard:Share.Dialogs.PreventRemove.SubText')}
        ></DialogOk>
        <DialogFooter>
          <PrimaryButton onClick={() => onSave()} disabled={hasError()}>
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton onClick={props.onClose}>{t('translation:General.Button.Cancel')}</DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default ShareDashboardModal;
