import { Label, Separator, Stack, Toggle } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import GenericModal from 'components/Dialogs/GenericModal';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import { useContext, useState } from 'react';
import AppContext from 'App/AppContext';
import {
  NotificationsEmailTaskAssign,
  NotificationsEmailTaskOverDeadline,
  NotificationsEmailTaskUpcoming,
  UserSettings,
} from 'models/userSetting';
import { globalStackTokensGapMedium } from 'globalStyles';
import Accordion from 'components/Accordion/Accordion';
import { apiSetUserSetting } from 'services/Api/settingService';
import { apiRequest } from 'services/Auth/authConfig';

interface IGlobalLogModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SettingsNotificationsModal = (props: IGlobalLogModalProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);
  const [settings, setSettings] = useState<UserSettings>(appContext.globalDataCache.userSettings);

  const updateSetting = async (settingName: string, settingValue: string) => {
    try {
      //set cache and state
      appContext.globalDataCache.userSettings.set(settingName, settingValue);
      const newSettings = settings.clone();
      newSettings.set(settingName, settingValue);
      setSettings(newSettings);

      //update back-end
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiSetUserSetting(settingName, settingValue, accessToken);
    } catch (err) {
      appContext.setError(err);
    }
  };

  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={t('translation:Settings.Notifications.Title')}
      subTitle={t('translation:Settings.Notifications.SubTitle')}
      height={'80vh'}
      width={600}
      maxWidth={'80vw'}
    >
      <Separator />
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <ScrollableStackItem isOnPanel={false}>
          <Accordion title={t('translation:Settings.Notifications.Email.Label')} defaultActive={true} showBorder={true}>
            <Stack verticalFill tokens={globalStackTokensGapMedium} styles={{ root: { padding: 20 } }}>
              <Stack.Item>
                <Label>{t('translation:Settings.Notifications.Email.TaskAssign')}</Label>
                <Toggle
                  checked={settings.get(NotificationsEmailTaskAssign) as boolean}
                  onText={t('translation:General.Toggle.On')}
                  offText={t('translation:General.Toggle.Off')}
                  onChange={(ev, checked) => {
                    updateSetting(NotificationsEmailTaskAssign, (checked ?? false).toString());
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                <Label>{t('translation:Settings.Notifications.Email.TaskOverDeadline')}</Label>
                <Toggle
                  checked={settings.get(NotificationsEmailTaskOverDeadline) as boolean}
                  onText={t('translation:General.Toggle.On')}
                  offText={t('translation:General.Toggle.Off')}
                  onChange={(ev, checked) => {
                    updateSetting(NotificationsEmailTaskOverDeadline, (checked ?? false).toString());
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                <Label>{t('translation:Settings.Notifications.Email.TaskUpcoming')}</Label>
                <Toggle
                  checked={settings.get(NotificationsEmailTaskUpcoming) as boolean}
                  onText={t('translation:General.Toggle.On')}
                  offText={t('translation:General.Toggle.Off')}
                  onChange={(ev, checked) => {
                    updateSetting(NotificationsEmailTaskUpcoming, (checked ?? false).toString());
                  }}
                />
              </Stack.Item>
            </Stack>
          </Accordion>
        </ScrollableStackItem>
      </Stack>
    </GenericModal>
  );
};

export default SettingsNotificationsModal;
