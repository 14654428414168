import { FunctionComponent } from 'react';
import {
  Text,
  Stack,
  Spinner,
  SpinnerSize,
  IconButton,
  IIconProps,
  IPersonaStyles,
  Persona,
  PersonaSize,
  TooltipHost,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnDate } from 'utils/sorting';
import SingleTask from 'components/Tasks/SingleTask';
import { collapseIcon, globalStackItemStylesScroll, globalTextStylesBold } from 'globalStyles';
import Activity, { ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  apiDismissActivity,
  apiDismissAllActivities,
  apiGetActivitiesForDashboard,
} from 'services/Api/dashboardService';
import { apiGetSingleTask } from 'services/Api/taskService';
import { toLocaleDateShortNoYear, toLocaleTimeNoSeconds } from 'utils/datetime';
import { getRandomId, toNameInitial } from 'utils/string';
import { SkypeCircleCheckIcon } from 'components/Checklist/CheckListStyles';
import InfiniteList from 'components/Utils/InifiniteList';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { IWidgetCustomAction } from 'models/widget';
import {
  DateDividerListItem,
  DataGroup,
  DateDivider,
  insertDividersAndGroupsInList,
  DataGroupComparer,
  DataGroupListItem,
  DataGroupGetName,
  DateComparer,
} from 'components/Utils/ListItemDateDividers';
import { apiGetLink } from 'services/Api/linkService';
import ResourceLink from 'models/resourceLink';
import { LinkPreviewModalOrUrl } from 'components/Links/Preview/LinkPreviewModalOrUrl';
import EntityDTO from 'models/dto/entityDTO';
import { navigateToExternalUrl } from 'utils/url';

interface IWidgetMyActionsProps extends IWidgetRendererProps {}

const WidgetMyActions: FunctionComponent<IWidgetMyActionsProps> = (props: IWidgetMyActionsProps) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const [actionData, setActionData] = React.useState<Activity[] | undefined>(undefined);
  const [actionDataDisplay, setActionDataDisplay] = React.useState<
    (Activity | DateDivider | DataGroup<Activity>)[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);
  const [selectedLink, setSelectedLink] = React.useState<ResourceLink | undefined>(undefined);
  const [showIcon, setShowIcon] = React.useState<number | undefined>(undefined);
  const [hasMoreData, setHasMoreData] = React.useState<boolean>(true);
  const [expandedSignatures, setEpandedSignatures] = React.useState<Activity[]>([]);
  const [reRender, setReRender] = React.useState<string>('');

  const dismissIcon: IIconProps = { iconName: 'RemoveFilter' };
  const colors: string[] = ['lightblue', 'blue', 'green', 'purple', 'red'];
  const actionTypeList = [
    ActivityType.OverDeadline,
    ActivityType.Refused,
    ActivityType.RefusedInstance,
    ActivityType.Assigned,
    ActivityType.Upcoming,
    ActivityType.LibraryNewVersionOwner,
    ActivityType.LinkedControlUpdated,
  ];

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget, props.sharedData]);

  const loadData = async (refresh?: boolean) => {
    try {
      if (props.onRefreshSharedData && props.widget.dashboard && refresh) {
        await props.onRefreshSharedData();

        return;
      }

      if (isLoading) return;
      setIsLoading(true);
      setHasMoreData(true);
      setEpandedSignatures([]);

      const activities = props.sharedData?.activities;

      setActionData(
        activities
          ?.filter((activity: Activity) => actionTypeList.includes(activity.typeOfActivity))
          .sort((a, b) => -1 * sortOnDate(a.created, b.created)),
      );
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.onRegisterCustomActions) {
      props.onRegisterCustomActions(props.widget, getCustomActions());
    }
    if (actionData && actionData?.length > 0) {
      const newData = insertDividersAndGroupsInList(actionData, getCompareDate, compareItemsForGroup, getGroupName);
      setActionDataDisplay(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, expandedSignatures]);

  const getCompareDate: DateComparer<Activity> = (a) => {
    return a.created;
  };

  const getGroupName: DataGroupGetName<Activity> = (a) => {
    return a.getPrimaryText(appContext.globalDataCache);
  };

  const compareItemsForGroup: DataGroupComparer<Activity> = (a, b) => {
    return a.typeOfActivity === b.typeOfActivity && !expandedSignatures.some((e) => e.activityId === a.activityId);
  };

  const getCustomActions = () => {
    const customActions: IWidgetCustomAction[] = [
      {
        name: t('widgets:MyActions.CustomActions.CollapseGroups'),
        iconProps: collapseIcon,
        disabled: !actionData || actionData?.length === 0,
        callback: collapseAll,
      },
      {
        name: t('widgets:MyActions.CustomActions.DismissAll'),
        iconProps: dismissIcon,
        disabled: !actionData || actionData?.length === 0,
        callback: dismissAll,
      },
    ];

    return customActions;
  };

  const collapseAll = async () => {
    setEpandedSignatures([]);
  };

  const dismissAll = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiDismissAllActivities(actionTypeList, accessToken);
      setActionData([]);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newActivities = await apiGetActivitiesForDashboard(
        actionTypeList,
        actionData?.length ?? 0,
        50,
        accessToken,
      );

      if (newActivities.length === 0) {
        setHasMoreData(false);
        //force last re-render of whole list, otherwise InfiniteList keeps sort of hanging on the last render page
        setActionData([...(actionData ?? [])]);
      } else {
        const newData = [...(actionData ?? [])];
        //add activities that not exist yet
        //for failsafe when fetch more returns the same data
        for (let idx = 0; idx < newActivities.length; idx++) {
          if (!newData?.some((a) => a.activityId === newActivities[idx].activityId)) {
            newData.push(newActivities[idx]);
          }
        }
        newData.sort((a, b) => -1 * sortOnDate(a.created, b.created));
        setActionData(newData);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      await loadData(true);
    }
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
    await loadData(true);
  };

  const updateTask = async (task: Task) => {};

  const getTaskDetail = async (taskId: number) => {
    if (!taskId) {
      return;
    }

    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(taskId, false, accessToken, appContext.globalDataCache);
      if (tasks && tasks.tasks && tasks.tasks.length > 0) {
        const task = tasks.tasks[0];
        setSelectedTask(task);
      } else {
        appContext.showNotification(t('dashboard:Errors.ItemHasBeenDeleted'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const getLinkDetail = async (linkId: number) => {
    if (!linkId) {
      return;
    }

    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const link = await apiGetLink(linkId, accessToken, appContext.globalDataCache);
      if (link) {
        setSelectedLink(link);
      } else {
        appContext.showNotification(t('dashboard:Errors.ItemHasBeenDeleted'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onItemClickHandler = (activity: Activity) => {
    switch (activity.entity.typeOfEntity) {
      case EntityTypes.Requirement:
        history.push(`/theme/${activity.entity.entityId}`);
        break;
      case EntityTypes.Control:
        if (activity.typeOfActivity === ActivityType.LinkedControlUpdated && activity.refData) {
          const entity: EntityDTO = JSON.parse(activity.refData);
          if (entity && entity.entityId && entity.entityOrgUnitId) {
            navigateToExternalUrl(
              `/control/${entity.entityId}`,
              appContext.user.tenant.azureTenantId,
              entity.entityOrgUnitId,
            );
          }
        } else {
          history.push(`/control/${activity.entity.entityId}`);
        }
        break;
      case EntityTypes.Task:
        getTaskDetail(activity.entity.entityId);
        break;
      case EntityTypes.Risk:
        history.push(`/risk/${activity.entity.entityId}`);
        break;
      case EntityTypes.Link:
        getLinkDetail(activity.entity.entityId);
        break;
      default:
        break;
    }
  };

  const dismissActivity = async (activityId: number) => {
    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiDismissActivity(activityId, accessToken);
      setActionData(actionData?.filter((a) => a.activityId !== activityId));
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  const getTooltipContent = (activity: Activity): JSX.Element => {
    if (!activity.tooltipContent) {
      activity.tooltipContent = (
        <Stack>
          <Stack.Item>
            <Text variant="medium" styles={globalTextStylesBold}>
              {activity.primaryText}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="small">{activity.secondaryText}</Text>
          </Stack.Item>
        </Stack>
      );
    }

    return activity.tooltipContent;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill styles={{ root: { paddingTop: 5 } }}>
      <Stack.Item grow styles={globalStackItemStylesScroll}>
        {actionData?.length === 0 && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <IconButton iconProps={SkypeCircleCheckIcon} />
          </Stack>
        )}
        {actionData && actionData.length > 0 && (
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <InfiniteList
              onFetchMore={fetchMoreData}
              hasMoreData={hasMoreData}
              items={actionDataDisplay}
              pageSize={7}
              listHeight={330}
              reRender={reRender}
              onRenderCell={(activity: Activity | DateDivider | DataGroup<Activity>) => {
                if (!activity) return null;

                if (activity instanceof DateDivider) {
                  return <DateDividerListItem {...activity} />;
                }

                if (activity instanceof DataGroup) {
                  return (
                    <DataGroupListItem
                      {...activity}
                      onExpand={(items) => {
                        setEpandedSignatures([...expandedSignatures, ...items]);
                      }}
                    />
                  );
                }

                if (!activity.primaryText) activity.primaryText = activity.getPrimaryText(appContext.globalDataCache);
                if (!activity.secondaryText)
                  activity.secondaryText = activity.getSecondaryText(appContext.globalDataCache);

                return (
                  <Stack
                    key={activity.activityId}
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    onMouseOver={() => {
                      setShowIcon(activity.activityId);
                      setReRender(getRandomId());
                    }}
                    onMouseLeave={() => {
                      setShowIcon(undefined);
                      setReRender(getRandomId());
                    }}
                    styles={{ root: { paddingRight: 5 } }}
                  >
                    <Stack.Item styles={{ root: { width: '85%' } }}>
                      <TooltipHost content={getTooltipContent(activity)}>
                        <Persona
                          styles={personaStyle}
                          size={PersonaSize.size40}
                          text={activity.primaryText}
                          secondaryText={activity.secondaryText}
                          imageInitials={toNameInitial(appContext.globalDataCache.users.get(activity.userId).name)}
                          initialsColor={colors[activity.priority]}
                          onClick={() => onItemClickHandler(activity)}
                          showOverflowTooltip={false}
                        />
                      </TooltipHost>
                    </Stack.Item>
                    {!(showIcon === activity.activityId && activity.dismissed === undefined) && (
                      <Stack.Item styles={{ root: { minWidth: 40 } }}>
                        <Text block variant="small">
                          {toLocaleDateShortNoYear(activity.created)}
                        </Text>
                        <Text variant="small">{toLocaleTimeNoSeconds(activity.created)}</Text>
                      </Stack.Item>
                    )}
                    {showIcon === activity.activityId && activity.dismissed === undefined && (
                      <Stack.Item>
                        <TooltipHost content={t('dashboard:ActivityDismiss.label')}>
                          <IconButton iconProps={dismissIcon} onClick={() => dismissActivity(activity.activityId)} />
                        </TooltipHost>
                      </Stack.Item>
                    )}
                  </Stack>
                );
              }}
            ></InfiniteList>
          </ScrollablePane>
        )}
      </Stack.Item>
      {actionData?.length === 0 && (
        <Stack.Item>
          <Text variant="medium">{t('dashboard:Action.NoItems')}</Text>
        </Stack.Item>
      )}
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
      {selectedLink && (
        <LinkPreviewModalOrUrl isOpen={true} onClose={() => setSelectedLink(undefined)} link={selectedLink} />
      )}
    </Stack>
  );
};

export default WidgetMyActions;
