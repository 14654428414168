import React, { useContext, useEffect, useState } from 'react';
import {
  getTheme,
  mergeStyleSets,
  Modal,
  Stack,
  Label,
  Dropdown,
  IDropdownOption,
  DefaultButton,
  FontIcon,
  Text,
  DialogFooter,
  PrimaryButton,
  IDropdownStyles,
  IconButton,
} from '@fluentui/react';
import {
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalTextStylesBold,
  cancelIcon,
  globalStackTokensGapLargePlus5,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import LocalizedDatePicker from '../Pickers/LocalizedDatePicker';
import RecurringPattern, {
  absoluteYearCycle,
  relativeYearCycle,
  repeatIntervalType,
  weekly,
} from 'models/recurringPattern';
import { getAuditStart, weekDays1 } from 'utils/datetime';
import AppContext from 'App/AppContext';
import { RecurringPatternSummaryStyle, RecurringSummary } from './RecurringSummary';
import RecurringRelativeOptions from './RecurringRelativeOptions';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    minWidth: '390px',
  },
  daySelectionContainer: {
    width: '2rem',
    height: '2rem',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.themeLight,
    borderColor: theme.palette.themeLight,
    color: theme.palette.themeDark,
    '&:hover': {
      cursor: 'default',
    },
  },
  active: {
    backgroundColor: theme.palette.themeDark,
    borderColor: theme.palette.themeDark,
    color: theme.palette.themeLight,
  },
});

const repeatOptions: IDropdownOption[] = [
  { key: 1, text: '1' },
  { key: 2, text: '2' },
  { key: 3, text: '3' },
  { key: 4, text: '4' },
  { key: 5, text: '5' },
  { key: 6, text: '6' },
  { key: 7, text: '7' },
  { key: 8, text: '8' },
  { key: 9, text: '9' },
  { key: 10, text: '10' },
  { key: 11, text: '11' },
  { key: 12, text: '12' },
];

interface IDaySelection {
  key: number;
  text: string;
  value: string;
}

interface IRecurrenceModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  recurring?: RecurringPattern;
  changeRecurring: (recurring: RecurringPattern) => void;
  allowRemove: boolean;
  initToAuditYear: boolean;
}

const RecurrenceModal = (props: IRecurrenceModalProps) => {
  const { t } = useTranslation(['translation', 'control']);
  const appContext = useContext(AppContext);

  const getRepeatIntervalOptionsKey = (type: repeatIntervalType): string => {
    switch (type) {
      case 'weekly':
        return 'week';
      case 'absoluteMonthly':
      case 'relativeMonthly':
        return 'month';
      case 'absoluteYearly':
      case 'relativeYearly':
        return 'year';
      case 'absoluteYearCycle':
      case 'relativeYearCycle':
        return 'cycle';
    }
  };

  const getRepeatIntervalFromOptionsKey = (key: string): repeatIntervalType => {
    switch (key) {
      case 'week':
        return 'weekly';
      case 'month':
        return 'absoluteMonthly';
      case 'year':
        return 'absoluteYearly';
      case 'cycle':
        return 'absoluteYearCycle';
      default:
        return 'weekly';
    }
  };

  const repeatIntervalOptions: IDropdownOption[] = [
    { key: 'week', text: t('control:TabDetails.Fields.Recurring.Weeks') },
    { key: 'month', text: t('control:TabDetails.Fields.Recurring.Months') },
    { key: 'year', text: t('control:TabDetails.Fields.Recurring.Years') },
    { key: 'cycle', text: t('control:TabDetails.Fields.Recurring.YearCycle') },
  ];

  const daySelectionOptions: IDaySelection[] = weekDays1.map((day, idx) => {
    let text = ' ';
    if (day === 'Monday') text = t('control:TabDetails.Fields.Recurring.Monday');
    if (day === 'Tuesday') text = t('control:TabDetails.Fields.Recurring.Tuesday');
    if (day === 'Wednesday') text = t('control:TabDetails.Fields.Recurring.Wednesday');
    if (day === 'Thursday') text = t('control:TabDetails.Fields.Recurring.Thursday');
    if (day === 'Friday') text = t('control:TabDetails.Fields.Recurring.Friday');
    if (day === 'Saturday') text = t('control:TabDetails.Fields.Recurring.Saturday');
    if (day === 'Sunday') text = t('control:TabDetails.Fields.Recurring.Sunday');

    return { key: idx, text: text[0], value: day };
  });

  const [recurring, setRecurring] = useState<RecurringPattern>(new RecurringPattern());

  useEffect(() => {
    // initialize a new Recurrence pattern when there is non or a non-active
    let recurringClone: RecurringPattern = new RecurringPattern();

    if (!props.recurring || props.recurring.isActive === false) {
      recurringClone.initRecurringPatternWeek();

      if (props.initToAuditYear) {
        //set the start date to the audit start date for the current year
        const auditStart: Date = getAuditStart(appContext);
        recurringClone.startDate = auditStart;
      } else if (props.recurring) {
        //set start date to start of the recurrence pattern in props
        recurringClone.startDate = props.recurring.startDate;
      }
    } else {
      recurringClone = props.recurring.clone();
    }

    setRecurring(recurringClone);
  }, [props.recurring, appContext.globalDataCache.settings, props.initToAuditYear, appContext]);

  const dateChangeHandler = (date: Date | undefined) => {
    if (date) {
      recurring.startDate = date;
      setRecurring(recurring.clone());
    }
  };

  const dropDownChangeHandler = (selectedOption: IDropdownOption | undefined, field: string) => {
    let key: string = selectedOption?.key as string;
    const newRecurring = recurring.clone();
    switch (field) {
      case 'repeat': {
        newRecurring.repeat = Number.parseInt(key);
        break;
      }
      case 'repeatInterval': {
        if (key === 'cycle') {
          newRecurring.repeat = 3;
          newRecurring.setYearSelect(0, true);
        }
        const repeatInterval = getRepeatIntervalFromOptionsKey(key);
        newRecurring.repeatInterval = repeatInterval;
        break;
      }
    }
    setRecurring(newRecurring);
  };

  const handleDaySelect = (item: string) => {
    let clonedDaySelect: string[] = [...recurring.daySelect];
    if (recurring.daySelect.includes(item)) {
      if (recurring.daySelect.length > 1) {
        clonedDaySelect = clonedDaySelect.filter((day: string) => day !== item);
      }
    } else {
      clonedDaySelect.push(item);
    }
    recurring.daySelect = clonedDaySelect;
    setRecurring(recurring.clone());
  };

  const renderDays = (item: IDaySelection) => {
    return (
      <div
        key={item.key}
        className={[
          contentStyles.daySelectionContainer,
          recurring.daySelect.length > 0 && recurring.daySelect.includes(item.value) ? contentStyles.active : '',
        ].join(' ')}
        onClick={() => handleDaySelect(item.value)}
      >
        {item.text}
      </div>
    );
  };

  const handleSubmit = () => {
    props.changeRecurring(recurring);
    props.toggleModal();
  };

  const handleRemove = () => {
    const emptyPattern = new RecurringPattern();
    setRecurring(emptyPattern);
    props.changeRecurring(emptyPattern);
    props.toggleModal();
  };

  const handleCancel = () => {
    props.toggleModal();
  };

  const dropdownStylesRepeat: Partial<IDropdownStyles> = {
    dropdown: { minWidth: '60px' },
  };
  const dropdownStylesTimeOptions: Partial<IDropdownStyles> = { dropdown: { minWidth: '125px' } };

  const getMaxDate = (): Date => {
    let oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    return oneYearFromNow;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      isBlocking={false}
      containerClassName={contentStyles.container}
      onDismiss={props.toggleModal}
    >
      <Stack
        verticalFill
        styles={{ root: { height: 490, width: 400, padding: 20 } }}
        tokens={globalStackTokensGapMedium}
      >
        <Stack.Item grow>
          <Stack horizontal horizontalAlign={'space-between'}>
            <Text styles={globalTextStylesBold} variant="xLarge">
              {t('control:TabDetails.Fields.Recurring.ModalTitle')}
            </Text>
            <IconButton iconProps={cancelIcon} onClick={props.toggleModal} />
          </Stack>
          <Stack styles={{ root: { paddingTop: 20 } }} horizontal tokens={globalStackTokensGapMedium}>
            <Stack.Item styles={{ root: { width: 50 } }}>
              <Stack tokens={globalStackTokensGapLargePlus5}>
                <Label>{t('control:TabDetails.Fields.Recurring.Start')}</Label>
                <FontIcon iconName="Sync" />
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <LocalizedDatePicker
                    showMonthPickerAsOverlay={false}
                    showWeekNumbers={true}
                    value={recurring.startDate}
                    onDateChange={dateChangeHandler}
                    maxDate={getMaxDate()}
                  />
                </Stack.Item>
                <Stack horizontal tokens={globalStackTokensGapSmall}>
                  <Label>{t('control:TabDetails.Fields.Recurring.Repeat')}</Label>
                  <Dropdown
                    label={''}
                    options={repeatOptions}
                    styles={dropdownStylesRepeat}
                    calloutProps={{ calloutMaxHeight: 250 }}
                    disabled={
                      recurring.repeatInterval === absoluteYearCycle || recurring.repeatInterval === relativeYearCycle
                    }
                    selectedKey={recurring.repeat}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                      dropDownChangeHandler(option, 'repeat')
                    }
                  />
                  <Dropdown
                    label={''}
                    options={repeatIntervalOptions}
                    styles={dropdownStylesTimeOptions}
                    selectedKey={getRepeatIntervalOptionsKey(recurring.repeatInterval)}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                      dropDownChangeHandler(option, 'repeatInterval')
                    }
                  />
                </Stack>
                {recurring.repeatInterval === weekly && (
                  <Stack horizontal tokens={globalStackTokensGapSmall}>
                    {daySelectionOptions.map((day: IDaySelection) => renderDays(day))}
                  </Stack>
                )}
                {(recurring.repeatInterval === absoluteYearCycle || recurring.repeatInterval === relativeYearCycle) && (
                  <RecurringSummary
                    stackStyles={{ root: { maxWidth: 300 } }}
                    variant="small"
                    pattern={recurring}
                    onChange={(year, checked) => {
                      const newRecurring = recurring.clone();
                      newRecurring.setYearSelect(year, checked);
                      setRecurring(newRecurring);
                    }}
                    summaryStyle={RecurringPatternSummaryStyle.interactive}
                  />
                )}
                {recurring.repeatInterval !== weekly && (
                  <RecurringRelativeOptions recurring={recurring} onChange={setRecurring} />
                )}
                <RecurringSummary
                  textStyles={{ root: { maxWidth: 300 } }}
                  variant="small"
                  pattern={recurring}
                  summaryStyle={RecurringPatternSummaryStyle.text}
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <DialogFooter>
            <PrimaryButton onClick={handleSubmit} text={t('control:TabDetails.Fields.Recurring.Save')} />
            {props.allowRemove && (
              <DefaultButton onClick={handleRemove} text={t('control:TabDetails.Fields.Recurring.Remove')} />
            )}
            {!props.allowRemove && (
              <DefaultButton onClick={handleCancel} text={t('control:TabDetails.Fields.Recurring.Cancel')} />
            )}
          </DialogFooter>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default RecurrenceModal;
