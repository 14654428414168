import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, IBreadcrumbItem, IBreadcrumbStyles } from '@fluentui/react';
import { overflow } from 'utils/string';
import AppContext from 'App/AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import { darkTheme, lightTheme } from 'globalThemes';
import { useTranslation } from 'react-i18next';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import logger from 'services/Logging/logService';
import { isValidGuid } from 'utils/guid';

export const dontShowBreadCrumbs: string[] = ['admin'];

export interface IBreadCrumbsProps {}

export type breadCrumbClickHandler = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: IBreadcrumbItem) => void;

const BreadCrumbs = withBreadcrumbs()(({ breadcrumbs }) => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation(['url']);

  const [translationMapping] = useState<Record<string, string>>({
    Home: t('url:Home'),
    Admin: t('url:Admin'),
    Users: t('url:Users'),
    Licensing: t('url:Licensing'),
    Roles: t('url:Roles'),
    Settings: t('url:Settings'),
    Monitoring: t('url:Monitoring'),
    Controls: t('url:Controls'),
    Control: t('url:Control'),
    Risks: t('url:Risks'),
    Risk: t('url:Risk'),
    Norms: t('url:Norms'),
    Board: t('url:Board'),
    Norm: t('url:Norm'),
    Analytics: t('url:Analytics'),
    Overview: t('url:Overview'),
    Tasks: t('url:Tasks'),
    Task: t('url:Task'),
    Myrequests: t('url:Myrequests'),
    Alltasks: t('url:Alltasks'),
    admin: t('url:admin'),
    Details: t('url:Details'),
    Mytasks: t('url:Mytasks'),
    Templates: t('url:Templates'),
    Groups: t('url:Groups'),
    Links: t('url:Links'),
    Organization: t('url:Organization'),
    Tags: t('url:Tags'),
    Integration: t('url:Integration'),
    Library: t('url:Library'),
    Lists: t('url:Lists'),
    Theme: t('url:Requirement'),
    Themes: t('url:Requirements'),
    Schedule: t('url:Schedule'),
    Store: t('url:Store'),
    Content: t('url:Content'),
    Package: t('url:Package'),
    Kb: t('url:KB'),
    Me: t('url:Me'),
    Team: t('url:Team'),
    Org: t('url:Org'),
    Process: t('url:Process'),
    Processes: t('url:Processes'),
    Objective: t('url:Objective'),
    Objectives: t('url:Objectives'),
    Subscription: t('url:Subscription'),
    Orgunits: t('url:Orgunits'),
    Asset: t('url:Asset'),
    Assets: t('url:Assets'),
    Classifications: t('url:Classifications'),
    General: t('url:General'),
    Partner: t('url:Partner'),
    Customer: t('url:Customer'),
    Events: t('url:Events'),
    Types: t('url:Types'),
    Kpis: t('url:KPIs'),
    Auth: t('url:Auth'),
  });

  useEffect(() => {
    //log all location changes
    let strLocation = location.pathname;
    if (location.hash) {
      strLocation += location.hash;
    }
    if (location.search) {
      strLocation += ' ' + location.search;
    }
    logger.debug('Location: ' + strLocation);
  }, [location]);

  const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
    root: {
      marginTop: 8,
    },
    itemLink: {
      fontSize: 12,
      textDecoration: 'none',
      selectors: {
        ':hover': {
          textDecoration: 'underline',
          outline: 'none !important',
          backgroundColor: appContext.useDarkMode ? darkTheme.palette?.neutralLight : lightTheme.palette?.themeLight,
        },
        ':active': {
          outline: 'none !important',
        },
        ':focus': {
          outline: 'none !important',
        },
      },
    },
    item: {
      fontSize: 12,
      textDecoration: 'none',
      selectors: {
        ':hover': {
          textDecoration: 'underline',
          outline: 'none !important',
          backgroundColor: appContext.useDarkMode ? darkTheme.palette?.neutralLight : lightTheme.palette?.themeLight,
        },
        ':active': {
          outline: 'none !important',
        },
        ':focus': {
          outline: 'none !important',
        },
      },
    },
  };

  const modifyBreadCrumbs = (key: string): string => {
    let output = key;
    if (output === '/control') output = '/controls';
    if (output === '/risk') output = '/risks';
    if (output === '/theme') output = '/themes';
    if (output === '/objective') output = '/organization/objectives';
    if (output === '/process') output = '/organization/processes';
    if (output === '/asset') output = '/assets';
    if (output === '/kpi') output = '/organization/kpis';
    //if (output === '/partner/customer') output = '/partner/customers';
    if (output === '/partner') output = '/partner/customers';

    return output;
  };

  const handleClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: IBreadcrumbItem): void => {
    const url = item?.key;

    if (url) {
      history.push(url);
    }
  };

  let breadCrumbItems: IBreadcrumbItem[] = breadcrumbs.map(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item: any,
    ) => {
      const textKey: string = item.breadcrumb.props.children;
      const key = translationMapping[textKey];

      return {
        key: modifyBreadCrumbs(item.key),
        text: key ? key : textKey,
        onClick: handleClick,
      };
    },
  );

  breadCrumbItems = breadCrumbItems.filter((_breadCrumbItem_) => {
    return Number.isNaN(+_breadCrumbItem_.text) && !isValidGuid(_breadCrumbItem_.text);
  });

  breadCrumbItems = breadCrumbItems.filter((_breadCrumbItem_) => {
    return !dontShowBreadCrumbs.includes(_breadCrumbItem_.text.toLowerCase());
  });

  return <Breadcrumb items={breadCrumbItems} maxDisplayedItems={8} styles={breadcrumbStyles} />;
});

export default BreadCrumbs;

//
// Class for each item
//
export class BreadCrumb {
  public items: IBreadcrumbItem[];

  private clickHandler: breadCrumbClickHandler | undefined;

  constructor() {
    this.items = [];
  }

  public setClickHandler(onClick: breadCrumbClickHandler) {
    this.clickHandler = onClick;
  }

  public Add(displayName: string, url: string) {
    const text = overflow(displayName, 30);
    const newItem: IBreadcrumbItem = { text: text, key: url, onClick: this.clickHandler };
    if (this.items.filter((item: IBreadcrumbItem) => item.key === newItem.key).length === 0) {
      this.items.push(newItem);
    }
  }

  public Replace(selected: IBreadcrumbItem) {
    this.items = this.items.map((item: IBreadcrumbItem) => {
      if (item.key === selected.key) {
        item.key = selected.key;
        item.text = selected.text;
      }

      return item;
    });
  }

  public RemoveChildBreadCrumbs = (parent: IBreadcrumbItem) => {
    this.items
      .filter(
        (breadCrumbItem: IBreadcrumbItem, index: number) =>
          index > this.items.findIndex((bItem: IBreadcrumbItem) => bItem.key === parent.key),
      )
      .forEach((itemToRemove: IBreadcrumbItem) => this.Remove(itemToRemove));
  };

  public Remove(itemToRemove: IBreadcrumbItem) {
    this.items = this.items.filter((item: IBreadcrumbItem) => item.key !== itemToRemove.key);
  }

  public Clear() {
    this.items = [];
  }
}
