export class ControlThemeInheritInfoDTO {
  orgUnitId: string;

  id: number;

  copyImplementation?: boolean;

  overwriteImplementation?: boolean;

  copyStatus?: boolean;

  copyLibraryItems?: boolean;

  copyApplicability?: boolean;

  copyCustomStandards?: boolean;

  constructor() {
    this.orgUnitId = '';
    this.id = 0;
  }
}
