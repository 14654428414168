import Control from 'models/control';
import Objective from 'models/objective/objective';

export default class KPIContext {
  objectives: Objective[];

  controls: Control[];

  constructor() {
    this.objectives = [];
    this.controls = [];
  }
}
