export class ProcessLinkDTO {
  processId: number;

  linkId: number;

  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    this.processId = -1;
    this.linkId = -1;
    this.pinned = false;
    this.linkURLFragment = '';
  }
}
