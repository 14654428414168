import { Checkbox, Dropdown, IDropdownOption, SearchBox, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalFilterDelay } from 'globalConstants';
import { globalSearchBoxStyles, globalStackStylesPaddingScene, globalStackTokensGapMedium } from 'globalStyles';
import Language from 'models/language';
import Package from 'models/package';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IStoreFilterProps {
  packages: Package[];
  filters: string[];
  searchQuery: string | undefined;
  updateFilters: (filters: string[]) => void;
  updateTextFilters: (searchQuery: string | undefined) => void;
  languages: Language[];
}

export const FilterStoreAllKey = '-';

export enum FilterStoreGroupKeys {
  language = 'language$',
  freePreview = 'freePreview$',
}

export const getStoreFilterType = (filter: string): FilterStoreGroupKeys | undefined => {
  const group = filter.split('$');

  switch (group[0] + '$') {
    case FilterStoreGroupKeys.language:
      return FilterStoreGroupKeys.language;
    case FilterStoreGroupKeys.freePreview:
      return FilterStoreGroupKeys.freePreview;
  }

  return undefined;
};

export const getStoreFilterValue = (filter: string): string | undefined => {
  const group = filter.split('$');
  if (group.length < 2) return undefined;

  return group[1];
};

const StoreFilter = (props: IStoreFilterProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['store', 'translation']);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  //
  // Effects
  //
  useEffect(() => {
    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterLanguageOptions = (): IDropdownOption[] => {
    const distinctLanguages = [...new Set(props.packages.map((p) => p.primaryLanguage))];
    const options: IDropdownOption[] = [
      {
        key: FilterStoreAllKey,
        text: t('store:TabStore.Filter.Language'),
      },
    ];

    options.push(
      ...distinctLanguages.map((lang) => {
        return {
          key: lang,
          text: props.languages.find((l) => l.code === lang)?.name || 'unknown',
        };
      }),
    );

    return options;
  };

  const getValueFromFilters = (filterKey: FilterStoreGroupKeys): string | undefined => {
    for (let idx = 0; idx < props.filters.length; idx++) {
      const filter = props.filters[idx];
      if (getStoreFilterType(filter) === filterKey) {
        return getStoreFilterValue(filter);
      }
    }

    return undefined;
  };

  const setValueInFilters = (filterKey: FilterStoreGroupKeys, value: string): string[] => {
    const newFilters = [...props.filters];

    //update filter value
    for (let idx = 0; idx < newFilters.length; idx++) {
      const filter = newFilters[idx];
      if (getStoreFilterType(filter) === filterKey) {
        const newFilter = filterKey + value;
        newFilters[idx] = newFilter;

        return newFilters;
      }
    }

    //add filter
    newFilters.push(filterKey + value);

    return newFilters;
  };

  const changeLanguage = (langCode: string | undefined) => {
    if (!langCode) return;
    const newFilters = setValueInFilters(FilterStoreGroupKeys.language, langCode);
    props.updateFilters(newFilters);
  };

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    props.updateTextFilters(filterText);
  };

  const setFreePreview = (checked: boolean) => {
    const newFilters = setValueInFilters(FilterStoreGroupKeys.freePreview, checked.toString());
    props.updateFilters(newFilters);
  };

  const getLanguageFromFilter = (): string => {
    const currentValue = getValueFromFilters(FilterStoreGroupKeys.language);
    const allValues = getFilterLanguageOptions();
    if (currentValue && allValues.map((a) => a.key).includes(currentValue)) {
      return currentValue;
    } else {
      //when the language value in the filter does not match with any package, change the filter to all languages
      const newFilter = setValueInFilters(FilterStoreGroupKeys.language, FilterStoreAllKey);
      props.updateFilters(newFilter);

      return FilterStoreAllKey;
    }
  };

  //
  // Main render
  //
  return (
    <Stack
      horizontal
      wrap
      tokens={globalStackTokensGapMedium}
      styles={globalStackStylesPaddingScene}
      verticalAlign="center"
    >
      <Stack.Item>
        <SearchBox
          autoFocus={!appContext.isMobileView}
          underlined
          placeholder={t('translation:General.Filter.Placeholder')}
          id="search"
          value={props.searchQuery}
          onChange={(ev, newValue) => {
            if (searchTimer) {
              clearTimeout(searchTimer);
            }
            setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
          }}
          styles={globalSearchBoxStyles}
        />
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          placeholder={t('store:TabStore.Filter.Language')}
          options={getFilterLanguageOptions()}
          selectedKey={getLanguageFromFilter()}
          styles={{ root: { width: 250 } }}
          onChange={(ev, option) => changeLanguage(option?.key as string)}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={getValueFromFilters(FilterStoreGroupKeys.freePreview) === 'true'}
          label={t('store:TabStore.Filter.FreePreview')}
          onChange={(ev, checked) => setFreePreview(checked ?? false)}
        />
      </Stack.Item>
    </Stack>
  );
};

export default StoreFilter;
