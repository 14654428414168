import React, { Fragment, useContext, useEffect } from 'react';
import {
  Checkbox,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Link,
  PrimaryButton,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import {
  globalComboContainerHeight,
  globalStackStylesHeight100PaddingScene,
  globalStackTokensGapMedium,
  globalTextStylesBold,
} from 'globalStyles';
import { useHistory } from 'react-router-dom';
import Package, { PackageTenantStates } from 'models/package';
import { Store } from 'components/Store/Store';
import { TenantOnboarding } from 'models/tenant';
import { globalAppName, globalHelpLink, supportedUILanguages } from 'globalConstants';
import Language from 'models/language';
import { MsConsentButton } from 'components/Buttons/MsButtons';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { navigateToExternalUrl } from 'utils/url';

interface ITrialWelcomeOptionsWithPackageProps {
  onboardingState: TenantOnboarding;
  packages: Package[];
  updatePackage: (updatedPackage: Package) => void;
  updateOnboardingState: (onboardingState: TenantOnboarding) => void;
  updateOrgLang: (langCode: string) => void;
  languages: Language[];
  adminConsentResult: boolean | undefined;
  selectedLang: Language | undefined;
}

const TrialWelcomeOptionsWithPackage = (props: ITrialWelcomeOptionsWithPackageProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const history = useHistory();
  //const [startLinks, setStartLinks] = useState<ResourceLink[]>([]);
  //const [linkToShow, setLinkToShow] = React.useState<ResourceLink | undefined>(undefined);

  useEffect(() => {
    if (props.adminConsentResult !== undefined) {
      onStep3(props.adminConsentResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminConsentResult]);

  React.useEffect(() => {
    //getActivatedPackageStartLinks(props.packages);

    const hasActivatedPackage = props.packages.some((p) => {
      if (p.tenants && p.tenants.length > 0) {
        if (p.tenants[0].state === PackageTenantStates.Activated) {
          return true;
        }
      }

      return false;
    });

    if (hasActivatedPackage) {
      let newState = props.onboardingState;
      if (newState.onboardingSteps.step1 === false) {
        newState.onboardingSteps.step1 = true;
        props.updateOnboardingState(newState);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.packages]);

  // const getActivatedPackageStartLinks = async (packages: Package[]) => {
  //   try {
  //     const startLinks: ResourceLink[] = [];

  //     for (let idx = 0; idx < packages.length; idx++) {
  //       const p = packages[idx];
  //       if (p.tenants && p.tenants.length > 0) {
  //         if (p.tenants[0].state === PackageTenantStates.Activated) {
  //           if (p.startLinkId && p.startLinkId > 0) {
  //             const accessToken = await appContext.getAccessToken(apiRequest.scopes);
  //             const importItem = await apiGetPackageImport(
  //               p.packageId,
  //               EntityTypes.Link,
  //               p.startLinkId,
  //               0,
  //               accessToken,
  //             );
  //             if (importItem && importItem.targetEntityId) {
  //               const link = await apiGetLink(importItem.targetEntityId, accessToken, appContext.globalDataCache);
  //               if (link) {
  //                 startLinks.push(link);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     setStartLinks(startLinks);
  //   } catch (err) {
  //     appContext.setError(err);
  //   }
  // };

  const onStep1 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step1 = checked;
    props.updateOnboardingState(newState);
  };

  const onStep2 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step2 = checked;
    props.updateOnboardingState(newState);
  };

  const onStep3 = (checked: boolean) => {
    const newState = props.onboardingState.clone();
    newState.onboardingSteps.step3 = checked;
    props.updateOnboardingState(newState);
  };

  const updatePackageStateInStore = (updatePack: Package | undefined) => {
    if (!updatePack) return;
    props.updatePackage(updatePack);
  };

  const navigateToStore = () => {
    const url = '/admin/store/content';
    history.push(url);
  };

  const navigateToLibrary = () => {
    const url = '/library';
    history.push(url);
  };

  const openGeneralSettings = () => {
    const url = '/admin/settings/general';
    history.push(url);
  };

  const openIntegrationSettings = () => {
    const url = '/admin/settings/integration';
    history.push(url);
  };

  const getLanguagesOptions = (): IComboBoxOption[] => {
    return props.languages
      .filter((l) => supportedUILanguages.has(l.code))
      .map((l) => {
        return {
          key: l.code,
          text: l.name,
        };
      });
  };

  const setOrgLang = (key: string | undefined) => {
    if (!key) return;
    props.updateOrgLang(key);
    onStep2(true);
  };

  const updateOrgLang = async (lang: Language) => {
    try {
      props.updateOrgLang(lang.code);
      onStep2(true);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const navigateToKB = () => {
    navigateToExternalUrl(globalHelpLink, '', '', true);
  };

  //
  // Main render
  //

  return (
    <Stack horizontal styles={globalStackStylesHeight100PaddingScene} tokens={globalStackTokensGapMedium}>
      <Stack.Item styles={{ root: { maxWidth: 800 } }}>
        <Stack verticalFill tokens={globalStackTokensGapMedium}>
          <Stack.Item>
            <Stack horizontal verticalAlign="center">
              <Stack.Item>
                <Checkbox
                  disabled={!hasUserFeatureGenericManager(appContext)}
                  onChange={(ev, checked) => onStep2(checked ?? false)}
                  checked={props.onboardingState.onboardingSteps?.step2}
                ></Checkbox>
              </Stack.Item>
              <Stack.Item>
                <Text variant="large" styles={globalTextStylesBold}>
                  {t('dashboard:TrialWelcome.Packages.Step2')}
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Text variant="medium">
              {t('dashboard:TrialWelcome.Packages.Info2', {
                appName: globalAppName,
                currentLang: props.selectedLang?.name,
              })}
              &nbsp;
            </Text>
            <Link onClick={() => openGeneralSettings()}>{t('dashboard:TrialWelcome.Packages.Info2a')}</Link>
          </Stack.Item>
          <Stack.Item>
            <ComboBox
              className="redlab-usetiful-welcome-language"
              disabled={!hasUserFeatureGenericManager(appContext)}
              allowFreeInput
              options={getLanguagesOptions()}
              selectedKey={props.selectedLang?.code}
              onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) =>
                setOrgLang(option?.key as string | undefined)
              }
              styles={globalComboContainerHeight}
              useComboBoxAsMenuWidth
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal verticalAlign="center">
              <Stack.Item>
                <Checkbox
                  disabled={!hasUserFeatureGenericManager(appContext)}
                  onChange={(ev, checked) => onStep1(checked ?? false)}
                  checked={props.onboardingState.onboardingSteps?.step1}
                ></Checkbox>
              </Stack.Item>
              <Stack.Item>
                <Text variant="large" styles={globalTextStylesBold}>
                  {t('dashboard:TrialWelcome.Packages.Step1')}
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Text variant="medium">
              {t('dashboard:TrialWelcome.Packages.Info1')}
              &nbsp;
            </Text>
          </Stack.Item>
          {!appContext.user.login.isOrgUnit && (
            <Fragment>
              <Stack.Item>
                <Stack horizontal verticalAlign="center">
                  <Stack.Item>
                    <Checkbox
                      onChange={(ev, checked) => onStep3(checked ?? false)}
                      checked={props.onboardingState.onboardingSteps?.step3}
                    ></Checkbox>
                  </Stack.Item>
                  <Stack.Item>
                    <Text variant="large" styles={globalTextStylesBold}>
                      {t('dashboard:TrialWelcome.Packages.Step3')}
                    </Text>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <Text variant="medium">
                  {t('dashboard:TrialWelcome.Packages.Info3', { appName: globalAppName })}&nbsp;
                </Text>
                <Link onClick={() => openIntegrationSettings()}>{t('dashboard:TrialWelcome.Packages.Info3a')}</Link>
              </Stack.Item>
              <Stack.Item className="redlab-usetiful-welcome-authorization">
                <MsConsentButton onClick={() => appContext.adminConsent('dashboard/welcome')}></MsConsentButton>
              </Stack.Item>
            </Fragment>
          )}
          <Stack.Item>
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.Packages.Step4')}
            </Text>
          </Stack.Item>
          {/* {startLinks.length > 0 && (
            <Stack.Item>
              <Text block variant="medium">
                {t('dashboard:TrialWelcome.Packages.Info4a')}
              </Text>
              {startLinks.map((link) => {
                return (
                  <li>
                    <Link onClick={() => setLinkToShow(link)}>{link.linkName}</Link>
                  </li>
                );
              })}
              <LinkPreviewModalOrUrl
                isOpen={linkToShow !== undefined}
                onClose={() => setLinkToShow(undefined)}
                link={linkToShow}
                confirmOpen={false}
                enableLinks={true}
              />
            </Stack.Item>
          )} */}
          <Stack.Item>
            <Text variant="medium">{t('dashboard:TrialWelcome.Packages.Info4', { appName: globalAppName })}&nbsp;</Text>
            <Link onClick={() => navigateToKB()}>{t('dashboard:TrialWelcome.KBLink')}</Link>&nbsp;
          </Stack.Item>
          <Stack.Item styles={{ root: { paddingBottom: 30 } }}>
            <PrimaryButton onClick={() => navigateToLibrary()}>{t('dashboard:TrialWelcome.LibraryLink')}</PrimaryButton>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Separator vertical />
      <Stack.Item>
        <Stack verticalFill tokens={globalStackTokensGapMedium}>
          <Stack.Item>
            <Store
              packages={props.packages}
              updatePackage={updatePackageStateInStore}
              updateOrgLang={updateOrgLang}
              packagesFilters={props.selectedLang ? [`language$${props.selectedLang.code}`] : undefined}
            />
          </Stack.Item>
          <Stack.Item>
            <Text variant="small">{t('dashboard:TrialWelcome.InfoStore')}&nbsp;</Text>
            <Text variant="small">
              <Link onClick={() => navigateToStore()}>{t('dashboard:TrialWelcome.InfoStoreLink')}</Link>
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default TrialWelcomeOptionsWithPackage;
