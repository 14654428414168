import {
  HoverCard,
  HoverCardType,
  IButtonStyles,
  IPlainCardProps,
  IconButton,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Stack,
  Text,
  TooltipHost,
  getTheme,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapExtraSmall,
  globalStackTokensGapSmall,
  globalTextStylesBold,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { Role } from 'models/auth/role';
import { overflow } from 'utils/string';
import { IUserRoleGroup, getUserRoleGroupItem, renderUserRoleGroupItem } from 'components/Pickers/UserRoleGroupPicker';
import { appRoleSets, appRoleSetTypes } from 'services/Auth/appRoles';
import { sortOnString } from 'utils/sorting';
import { useContext } from 'react';
import AppContext from 'App/AppContext';

interface IRoleTagProps {
  role: Role;
  compact?: boolean;
  allowHoverCard: boolean;
  isMaxSystemRoles?: boolean;
  hideBackground?: boolean;
  onRemove?: (item: Role) => void;
}

const RoleTag = (props: IRoleTagProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();
  const { t } = useTranslation(['translation', 'adminAuth']);
  const size = props.compact ? PersonaSize.size24 : PersonaSize.size40;
  const name =
    props.role.name + (props.allowHoverCard ? ` (${props.role.users.length + props.role.groups.length})` : '');
  const cannotAddLicensedRole =
    props.isMaxSystemRoles && appRoleSets[appRoleSetTypes.Licensed].includes(props.role.systemRoleId ?? 0);

  const tagButtonStyles: IButtonStyles = {
    root: {
      borderRadius: '10px',
      height: props.compact ? '24px' : '40px',
      width: props.compact ? '24px' : '40px',
    },
  };

  const onRenderRoleCard = (item: Role): JSX.Element => {
    const count = item.users.length + item.groups.length;
    const items: IUserRoleGroup[] = [];

    if (count > 0) {
      items.push(...item.users.map((u) => getUserRoleGroupItem(u)));
      items.push(...item.groups.map((u) => getUserRoleGroupItem(u)));
    }

    items.sort((a, b) => sortOnString(a.name, b.name));

    return (
      <Stack
        styles={{
          root: { width: 300, minHeight: 150, height: 300, maxHeight: '50vh', padding: 20 },
        }}
      >
        <Stack
          verticalFill
          tokens={globalStackTokensGapSmall}
          styles={{
            root: { overflow: 'scroll' },
          }}
        >
          <Stack.Item>
            <Text styles={globalTextStylesBold}>
              {item.systemRoleId ? t('adminAuth:SystemRole.Members') : t('adminAuth:CustomRole.Members')}
            </Text>
          </Stack.Item>
          {count > 0 &&
            items.map((item) => {
              return renderUserRoleGroupItem(item, true, undefined, true, 0, props.hideBackground);
            })}
          {count === 0 && (
            <Text>{item.systemRoleId ? t('adminAuth:SystemRole.NoMembers') : t('adminAuth:CustomRole.NoMembers')}</Text>
          )}
        </Stack>
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderRoleCard,
    renderData: props.role,
  };

  return (
    <HoverCard
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={props.allowHoverCard}
      shouldBlockHoverCard={() => !props.allowHoverCard}
    >
      <Stack
        horizontal
        tokens={globalStackTokensGapExtraSmall}
        verticalAlign="center"
        styles={
          props.hideBackground !== true
            ? {
                root: {
                  background: appContext.useDarkMode ? theme.palette.neutralSecondary : theme.palette.neutralLight,
                  borderRadius: '10px',
                  height: 1 + (props.compact ? 24 : 40),
                },
              }
            : undefined
        }
      >
        <Stack.Item styles={cannotAddLicensedRole ? { root: { opacity: 0.5 } } : undefined}>
          <TooltipHost
            content={cannotAddLicensedRole ? t('adminAuth:TabRoles.Dialogs.MaxLicensesReached.SubText') : undefined}
          >
            <Persona
              text={name}
              secondaryText={overflow(props.role.description, 50)}
              size={size}
              hidePersonaDetails={false}
              imageInitials={props.role.systemRoleId ? 'S' : undefined}
              initialsColor={props.role.systemRoleId ? PersonaInitialsColor.lightRed : undefined}
            />
          </TooltipHost>
        </Stack.Item>
        {props.onRemove && (
          <Stack.Item>
            <IconButton
              onClick={() => {
                if (props.onRemove) {
                  props.onRemove(props.role);
                }
              }}
              styles={tagButtonStyles}
              iconProps={cancelIcon}
            />
          </Stack.Item>
        )}
      </Stack>
    </HoverCard>
  );
};

export default RoleTag;
