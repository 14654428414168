import { GroupMembersDTO } from "../groupMembersDTO";
import ResourceLinkDTO from "../resourceLinkDTO";

export class Widget61DTO
{
    link?: ResourceLinkDTO;

    activityId?: number;

    groups?: string[];

    groupMembers?: GroupMembersDTO[];

    userIdsNotResponded?: string[];

    groupCountTotal?: number[];

    groupCountVersion?: number[];
}