import LanguageDTO from '../languageDTO';

export class StringValue_TranslationDTO {
  stringValueId: number;

  languageId: number;

  value: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.stringValueId = -1;
    this.languageId = -1;
    this.value = '';
  }
}
