import { Stack, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { globalStackItemStylesPaddingSceneScroll, globalStackTokensGapSmall } from 'globalStyles';
import Task from 'models/tasks/task';
import Tag from 'models/tag';
import { TaskDetailAssignment } from './Details/TaskDetailAssignment';
import { TaskState } from 'models/tasks/taskHelperClasses';
import { TaskDetailName } from './Details/TaskDetailName';
import { TaskDetailTags } from './Details/TaskDetailTags';
import { TaskDetailDescription } from './Details/TaskDetailDecription';
import { TaskDetailStatus } from './Details/TaskDetailStatus';
import { TaskDetailStart } from './Details/TaskDetailStart';
import { TaskDetailDeadline } from './Details/TaskDetailDeadline';
import { TaskDetailRecurrence } from './Details/TaskDetailRecurrence';

interface INormalTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  taskStates: TaskState[];
  isActionPending: boolean;
  loadRescheduleRange: () => void;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  canUpdate: boolean;
  compactView: boolean | undefined;
}

export const NormalTaskDetail = (props: INormalTaskDetail) => {
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall} styles={{ root: { paddingRight: 10 } }}>
            <TaskDetailName
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
              compactView={props.compactView}
            />
            <TaskDetailTags
              orgTask={props.orgTask}
              task={props.task}
              tags={props.tags}
              canUpdate={props.canUpdate}
              addTagToTaskState={props.addTagToTaskState}
              removeTagFromTaskState={props.removeTagFromTaskState}
              isActionPending={props.isActionPending}
            />
            <TaskDetailDescription
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
            <TaskDetailAssignment
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailStatus
              task={props.task}
              orgTask={props.orgTask}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              onChangeCompletionDate={props.onChangeCompletionDate}
              showCompleted={true}
            />
            <TaskDetailStart
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
              loadRescheduleRange={props.loadRescheduleRange}
            />
            <TaskDetailDeadline
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
              getErrorMessage={props.getErrorMessage}
            />
            <TaskDetailRecurrence
              orgTask={props.orgTask}
              task={props.task}
              canUpdate={props.canUpdate}
              onUpdate={props.onUpdate}
            />
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
