import ResourceLink from 'models/resourceLink';
import { ISitePage } from 'services/Graph/SharepointInterfaces';

export enum SitePageHyperLinkTypes {
  SharePoint = 1,
  App = 2,
  External = 3,
}

export class SitePageHyperLink {
  page?: ISitePage;

  url?: string;

  type?: SitePageHyperLinkTypes;

  valid?: boolean;

  error?: string;

  link?: ResourceLink;

  notSupported?: boolean;
}
