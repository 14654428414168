import { useContext, useEffect, useState } from 'react';
import { FontIcon, TooltipHost } from '@fluentui/react';
import { linkIcon, validIcon } from 'globalStyles';
import { getExternalUrl } from 'utils/url';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';

interface ICopyLinkProps {
  url: string;
}

const CopyLink = (props: ICopyLinkProps) => {
  const { t } = useTranslation('translation');
  const appContext = useContext(AppContext);
  const [copied, setCopied] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyUrl = () => {
    const externalUrl = getExternalUrl(props.url, appContext.user.tenant.azureTenantId, appContext.user.login.tenantId);
    navigator.clipboard.writeText(externalUrl);
    setCopied(true);
    setTimer(setTimeout(() => setCopied(false), 2000));
  };

  return (
    <TooltipHost
      content={t('translation:CopyLink.Tooltip')}
      styles={{
        root: {
          selectors: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      }}
    >
      {!copied && <FontIcon {...linkIcon} onClick={copyUrl}></FontIcon>}
      {copied && <FontIcon {...validIcon}></FontIcon>}
    </TooltipHost>
  );
};

export default CopyLink;
