import Language from './language';

export default class ISOControl_Translation {
  isoControlId: number;

  languageId: number;

  name: string;

  description?: string;

  instruction?: string;

  lang?: Language;

  packageInstructions?: PackageInstruction[];

  constructor() {
    this.name = '';
    this.languageId = 0;
    this.isoControlId = 0;
  }
}

export class PackageInstruction {
  instruction: string;

  packageId: number;

  packageName: string;

  constructor() {
    this.instruction = '';
    this.packageId = 0;
    this.packageName = '';
  }
}
