import Objective from './objective/objective';
import Process from './process/process';
import Theme from './theme';

export class RiskContext {
  themes: Theme[];

  processes: Process[];

  objectives: Objective[];

  constructor() {
    this.objectives = [];
    this.themes = [];
    this.processes = [];
  }
}
