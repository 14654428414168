import User from 'models/user';
import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { newGuid } from 'utils/guid';

//
// Teams
//
export async function graphSendChatOneToOne(
  client: Client,
  selectedUsers: User[],
  fromUser: User,
  content: string,
): Promise<boolean> {
  try {
    selectedUsers.forEach(async (toUser: User) => {
      //each message which sending adaptive card should have uniqueId
      const attachmentId = newGuid();
      const chatMessage = {
        body: {
          contentType: 'html',
          content: `<attachment id="${attachmentId}"></attachment>`,
        },
        attachments: [
          {
            id: `${attachmentId}`,
            contentType: 'application/vnd.microsoft.card.adaptive',
            content: content,
          },
        ],
      };

      const chat = {
        chatType: 'oneOnOne',
        members: [
          {
            '@odata.type': '#microsoft.graph.aadUserConversationMember',
            roles: ['owner'],
            'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${fromUser.id}')`,
          },
          {
            '@odata.type': '#microsoft.graph.aadUserConversationMember',
            roles: ['owner'],
            'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${toUser.id}')`,
          },
        ],
      };

      const resp = await client.api('/chats').post(chat);
      await client.api(`/chats/${resp.id}/messages`).post(chatMessage);
    });

    return true;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
}

export async function graphSendChatChannel(
  client: Client,
  teamId: string,
  channelId: string,
  content: string,
): Promise<boolean> {
  try {
    //each message which sending adaptive card should have uniqueId
    const attachmentId = newGuid();
    const chatMessage = {
      body: {
        contentType: 'html',
        content: `<attachment id="${attachmentId}"></attachment>`,
      },
      attachments: [
        {
          id: `${attachmentId}`,
          contentType: 'application/vnd.microsoft.card.adaptive',
          content: content,
        },
      ],
    };

    await client.api(`teams/${teamId}/channels/${channelId}/messages`).post(chatMessage);

    return true;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
}
