import { Stack, SearchBox, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import QuickFilter, { QuickFilterContext } from 'components/Filter/QuickFilter';
import { globalFilterDelay, globalMinFilterChars } from 'globalConstants';
import { globalStackTokensGapMedium, globalSearchBoxStyles } from 'globalStyles';
import Task from 'models/tasks/task';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import FilterTasks, { QuickFilterTasksRenderer } from './FilterTasks';
import { filterTasks } from './FilterTasksHelper';
import { TaskViewMemberFilter } from './TaskViewMemberFilter';

interface ITaskViewFiltersProps {
  selectedFilter: string[];
  isLoading?: boolean;
  tasks: Task[];
  selectedTasks: Task[];
  showGroupMemberFilter?: boolean;
  memberFilter?: string[];
  isLoadingMembership?: boolean;
  showFilterDialog?: boolean;
  filterStorageKey: LocalStorageKeys;
  hideQuickFilter?: boolean;
  quickFilterContext?: QuickFilterContext;
  maxQuickFilters?: number;
  onUpdateSelectedFilter: (filters: string[]) => void;
  onUpdateTasksDisplay: (newTasks: Task[]) => void;
  onLoad: (filter: string[]) => void;
  onUpdateMemberFilter: (filter: string[]) => void;
  onLoadWithMembers: (memberFilter: string[]) => void;
  onLoadMembership: () => void;
  toggleFilterDialog?: () => void; //if this is not set, filter is not shown
}

export const TaskViewFilters = (props: ITaskViewFiltersProps) => {
  const { t } = useTranslation(['translation', 'tasks', 'adminGroups']);
  const appContext = useContext(AppContext);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  //
  // Effects
  //
  useEffect(() => {
    loadFilterFromStorage();

    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitializing) return;
    
    //we need the internal filter only to determine whether certain changes have been made to the filter
    // - reloadOnCompletedFilterChange
    // - reloadOnSharedFilterChange
    updateSelectedFilterInternal(props.selectedFilter);

    let newTasks: Task[] = [...props.tasks];
    if (props.selectedFilter && props.selectedFilter.length > 0) {
      newTasks = filterTasks(props.selectedFilter, newTasks, appContext);
    }
    if (searchText && searchText.length >= globalMinFilterChars) {
      newTasks = applyTextFilter(newTasks, searchText);
    }

    updateTasksDisplay(newTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedFilter, props.tasks, searchText]);

  const updateTasksDisplay = (tasks: Task[]) => {
    setFilterCount(tasks.length);
    props.onUpdateTasksDisplay(tasks);
  };

  const updateSelectedFilter = (filter: string[]) => {
    props.onUpdateSelectedFilter(filter);
  };

  const updateSelectedFilterInternal = (filter: string[]) => {
    saveFilterToStorage(filter);
  };

  const saveFilterToStorage = (filter: string[]) => {
    setLocalStorageData(appContext, props.filterStorageKey, filter.join(','));
  };

  const loadFilterFromStorage = async () => {
    //Always signal to parent that the filter is loaded by calling onUpdateSelectedFilter
    if (props.selectedFilter && props.selectedFilter.length > 0) {
      //an initial filter is set, so we can use that, probably from URL
      props.onUpdateSelectedFilter(props.selectedFilter);
    } else {
      const filterString = getLocalStorageData(appContext, props.filterStorageKey);
      if (filterString) {
        const filter = filterString.split(',');
        props.onUpdateSelectedFilter(filter);
      } else {
        props.onUpdateSelectedFilter([]);
      }
    }

    setIsInitializing(false);
  };

  const applyTextFilter = (tasks: Task[], text: string): Task[] => {
    const searchLower = text.toLowerCase();

    return tasks.filter(
      (item: Task) =>
        item.name.toLowerCase().includes(searchLower) ||
        (item.description && item.description.toLowerCase().includes(searchLower)) ||
        item.taskId.toString() === searchLower,
    );
  };

  const setQuickFilter = (quickFilter: string, checked: boolean) => {
    let newFilter = [...(props.selectedFilter ?? [])];
    if (!checked) {
      newFilter = newFilter.filter((f) => f !== quickFilter);
    } else {
      newFilter.push(quickFilter);
    }

    updateSelectedFilter(newFilter);
  };

  //
  // Main render
  //
  return (
    <Stack horizontal wrap verticalAlign="center" tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <SearchBox
          autoFocus={!appContext.isMobileView}
          underlined={true}
          styles={globalSearchBoxStyles}
          placeholder={t('translation:General.Filter.Placeholder')}
          onChange={(ev, newValue) => {
            if (searchTimer) {
              clearTimeout(searchTimer);
            }
            setSearchTimer(setTimeout(() => setSearchText(newValue), globalFilterDelay));
          }}
        />
      </Stack.Item>
      {props.showGroupMemberFilter && (
        <TaskViewMemberFilter
          isLoadingMembership={props.isLoadingMembership}
          onUpdateFilter={props.onUpdateMemberFilter}
          filter={props.memberFilter ?? []}
          onLoadMembership={props.onLoadMembership}
        />
      )}
      {props.toggleFilterDialog && (
        <FilterTasks
          toggleDialog={props.toggleFilterDialog}
          isOpen={props.showFilterDialog ?? false}
          updateSelectedFilter={props.onUpdateSelectedFilter}
          selectedFilter={props.selectedFilter}
          hideClear={!props.hideQuickFilter && !appContext.isMobileView}
        />
      )}
      {!props.hideQuickFilter && !appContext.isMobileView && (
        <Stack.Item>
          <QuickFilter
            context={props.quickFilterContext ?? QuickFilterContext.generic}
            filters={props.selectedFilter ?? []}
            onChange={setQuickFilter}
            onFilterRender={QuickFilterTasksRenderer}
            enabled={!props.isLoading}
            maxFilters={props.maxQuickFilters}
          />
        </Stack.Item>
      )}
      {!props.isLoading && !appContext.isMobileView && (
        <Stack.Item>
          <Text variant="small">
            {t('translation:General.List.FilterCountWithSelection', {
              total: props.tasks.length,
              filtered: filterCount,
              selected: props.selectedTasks.length,
            })}
          </Text>
        </Stack.Item>
      )}
    </Stack>
  );
};
