import { getTheme, IconButton, Label, Stack, Text, TextField } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { allowedMimeTypesForUpload } from 'globalConstants';
import { deleteIcon, globalStackTokensGapSmall } from 'globalStyles';
import { useContext, useEffect, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { bytesToSize } from 'utils/string';

interface IFilePickerProps {
  label?: string;
  onSelect: (item: File | undefined) => void;
  onChangeName: (item: File, newName: string | undefined) => void;
  onFileTypeError: () => void;
  selectedFile: File | undefined;
  selectedFileName: string | undefined;
  fileNameError: string | undefined;
  actionPending: boolean;
}

const FilePicker = (props: IFilePickerProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [files, setFiles] = useState<File[]>(props.selectedFile ? [props.selectedFile] : []);
  const [dragOverTextField, setDragOverTextField] = useState<boolean>(false);

  useEffect(() => {
    if (props.selectedFile) {
      setFiles([props.selectedFile]);
    } else {
      setFiles([]);
    }
  }, [props.selectedFile]);

  const theme = getTheme();

  const selectFile = (files: File[]) => {
    setFiles(files);
    if (files.length > 0) {
      props.onSelect(files[0]);
      props.onChangeName(files[0], files[0].name);
    } else {
      props.onSelect(undefined);
    }
  };

  return (
    <Stack verticalFill>
      {props.label && (
        <Stack.Item>
          <Label>{props.label}</Label>
        </Stack.Item>
      )}
      {files.length !== 0 && (
        <Stack.Item>
          <Stack
            verticalFill
            verticalAlign="center"
            tokens={globalStackTokensGapSmall}
            styles={{
              root: {
                border: '2px solid grey',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: appContext.useDarkMode ? theme.palette.themeLight : theme.palette.themeDark,
                backgroundColor: appContext.useDarkMode ? theme.palette.themeDark : theme.palette.themeLight,
                minHeight: 100,
                padding: 20,
              },
            }}
          >
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Stack.Item grow>
                  <TextField
                    autoFocus={!appContext.isMobileView}
                    required
                    maxLength={512}
                    readOnly={props.actionPending}
                    defaultValue={files[0].name}
                    onChange={(ev, newValue) => props.onChangeName(files[0], newValue)}
                    errorMessage={props.fileNameError}
                  ></TextField>
                </Stack.Item>
                <Stack.Item>
                  <IconButton disabled={props.actionPending} onClick={() => selectFile([])} iconProps={deleteIcon} />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Text>{bytesToSize(files[0].size)}</Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
      {files.length === 0 && (
        <Stack.Item>
          <Dropzone
            noDragEventsBubbling={true}
            multiple={false}
            maxFiles={1}
            disabled={props.actionPending}
            accept={allowedMimeTypesForUpload}
            onDrop={(acceptedFiles: File[], fileRejection: FileRejection[], event: DropEvent) => {
              setDragOverTextField(false);
              if (fileRejection.length > 0) {
                props.onFileTypeError();
              } else if (acceptedFiles.length > 0) {
                selectFile(acceptedFiles);
              }
            }}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Stack
                horizontal
                horizontalAlign="center"
                verticalAlign="center"
                styles={{
                  root: {
                    border:
                      isDragActive || dragOverTextField
                        ? `2px dashed ${appContext.useDarkMode ? theme.palette.themeLight : theme.palette.themeDark}`
                        : '1px dashed',
                    background:
                      isDragActive || dragOverTextField
                        ? appContext.useDarkMode
                          ? theme.palette.themeDark
                          : theme.palette.themeLight
                        : undefined,
                    minHeight: 80,
                    maxHeight: 80,
                    padding: 5,
                  },
                }}
                {...getRootProps()}
              >
                <Stack.Item
                  styles={{ root: { width: '100%' } }}
                  onDragEnter={() => setDragOverTextField(true)}
                  onDragOver={() => setDragOverTextField(true)}
                  onDragLeave={() => setDragOverTextField(false)}
                  onDragEnd={() => setDragOverTextField(false)}
                >
                  <TextField
                    autoFocus={!appContext.isMobileView}
                    borderless
                    multiline
                    readOnly={true}
                    styles={{
                      fieldGroup: {
                        background:
                          isDragActive || dragOverTextField
                            ? appContext.useDarkMode
                              ? theme.palette.themeDark
                              : theme.palette.themeLight
                            : undefined,
                      },
                      field: {
                        resize: 'none',
                        overflow: 'hidden',
                        height: 60,
                        color: theme.palette.themePrimary,
                        textDecoration: 'underline',
                      },
                    }}
                    defaultValue={
                      t('translation:General.FilePicker.PasteOrDrop') + t('translation:General.FilePicker.ChooseFile')
                    }
                  />
                  <Text>
                    <input {...getInputProps()} />
                  </Text>
                </Stack.Item>
              </Stack>
            )}
          </Dropzone>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FilePicker;
