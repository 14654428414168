import Joi from 'joi';
import Objective from 'models/objective/objective';
import { PDCAState } from 'models/pdca';
import Task from 'models/tasks/task';
import { TFunction } from 'i18next';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { Process_Translation } from './process_Translation';
import { getPDCAStateText } from 'globalFunctions';
import Dashboard from 'models/dashboard';
import { sortOnChapter } from 'utils/sorting';
import { IOwner } from 'models/owner';
import { sortOnCode } from 'utils/sorting';

export default class Process implements IOwner {
  processId: number;

  authSchemaId?: number;

  code: string;

  parentProcessId?: number;

  state: PDCAState;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  commentTrailId: number;

  auditTrailId: number;

  objectives: Objective[];

  trans?: Process_Translation[];

  tasks: Task[];

  dashboard?: Dashboard;

  tagIds: number[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  constructor() {
    this.processId = -1;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.tasks = [];
    this.tagIds = [];
    this.objectives = [];
  }

  static getProcessStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  isEqual(item: Process) {
    if (item.processId !== this.processId) return false;
    if (item.parentProcessId !== this.parentProcessId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (item.authSchemaId !== this.authSchemaId) return false;
    if (item.ownerRoleId !== this.ownerRoleId) return false;

    return true;
  }

  clone(): Process {
    const newItem = new Process();
    newItem.processId = this.processId;
    newItem.parentProcessId = this.parentProcessId;
    newItem.code = this.code;
    newItem.name = this.name;
    newItem.description = this.description;
    newItem.state = this.state;
    newItem.groupId = this.groupId;
    newItem.ownerId = this.ownerId;
    newItem.commentTrailId = this.commentTrailId;
    newItem.auditTrailId = this.auditTrailId;
    newItem.transIdx = this.transIdx;
    newItem.trans = this.trans ? [...this.trans] : undefined;
    newItem.tasks = [...this.tasks];
    newItem.tagIds = [...this.tagIds];
    newItem.objectives = [...this.objectives];
    newItem.dashboard = this.dashboard?.clone();
    newItem.authSchemaId = this.authSchemaId;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  static getDefAuthSchema(items: Process[], parent: Process | undefined): number | undefined {
    if (parent) return parent.authSchemaId;
    if (items.length > 0) {
      const sortedItems = [...items];
      sortedItems.sort((a, b) => sortOnChapter(a.code, b.code));

      return sortedItems[sortedItems.length - 1].authSchemaId;
    }

    return undefined;
  }
  
  static isChildOf = (processes: Process[], parent: Process, process: Process | undefined): boolean => {
    let current: Process | undefined = process;
    let max: number = 0;

    while (current !== undefined && current.parentProcessId !== undefined && max < 100) {
      if (current.parentProcessId === parent.processId) {
        return true;
      }

      // eslint-disable-next-line no-loop-func
      current = processes.find((c) => c.processId === current?.parentProcessId);
      max++;
    }

    return false;
  };

  static sortOnLevelAndCode = (allEntities: Process[], entities: Process[]): Process[] => {
    //create a has table for fast lookup
    const itemDict: Record<number, Process> = {};

    allEntities.forEach((item) => {
      itemDict[item.processId] = item;
    });

    //show all items without parent or items with parent which is not found
    const allParents = entities.filter((item) => {
      const parentId = item.parentProcessId;
      if (!parentId || !itemDict[parentId]) return true;

      return false;
    });

    //add all sorted children recursively to the root items
    const output = this.addAllSortedChildren(allEntities, allParents);

    return output;
  };

  private static addAllSortedChildren = (
    allEntities: Process[],
    allParents: Process[],
  ): Process[] => {
    let output: Process[] = [];
    const newItems = allParents.slice().sort((a, b) => {
      return sortOnCode(a.code, b.code);
    });

    for (let i = 0; i < newItems.length; i++) {
      const aItem = newItems[i];
      const children = this.getChildren(allEntities, aItem).sort((a, b) => sortOnCode(a.code, b.code));
      output.push(newItems[i]);
      const sortedChildren = this.addAllSortedChildren(allEntities, children);
      output.push(...sortedChildren);
    }

    return output;
  };

  private static getChildren = (allEntities: Process[], item: Process): Process[] => {
    return allEntities.filter((_item) => _item.parentProcessId === item.processId);
  };
}
