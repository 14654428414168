import LanguageDTO from "../languageDTO";

export class AuthSchema_TranslationDTO {
  authSchemaId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.authSchemaId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
