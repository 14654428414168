import { EntityTypes } from 'models/entity';

export default class EntityDTO {
  entityId: number;

  typeOfEntity: EntityTypes;

  entityCode?: string;

  entityName: string;

  entityOrgUnitId?: string;

  data?: string | undefined;

  constructor() {
    this.entityId = 0;
    this.typeOfEntity = EntityTypes.NotSet;
    this.entityName = '';
  }
}
