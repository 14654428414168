import Activity from './activity';
import Task from './tasks/task';

export default class DashboardScene {
  activities: Activity[];

  tasks: Task[];

  constructor() {
    this.activities = [];
    this.tasks = [];
  }
}

//
// Helper classes for various widget data
//
export class NormCoverage {
  normId: number;

  isoNormId?: number;

  normName: string;

  controlCount: number;

  coveredCount: number;

  outOfScopeCount: number;

  notPlannedCount: number;

  notImplemented: number;

  constructor() {
    this.normId = 0;
    this.normName = '';
    this.controlCount = 0;
    this.coveredCount = 0;
    this.outOfScopeCount = 0;
    this.notPlannedCount = 0;
    this.notImplemented = 0;
  }
}

export class UserTaskStat {
  userId: string;

  taskCount: number;

  constructor() {
    this.userId = '';
    this.taskCount = 0;
  }
}

export class GroupStat {
  groupId: string;

  controlCount: number;

  riskCount: number;

  constructor() {
    this.groupId = '';
    this.controlCount = 0;
    this.riskCount = 0;
  }
}
