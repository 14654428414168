import { TFunction } from 'i18next';

export default class Webhook {
  webhookId: number;

  workflowName?: string;

  created: Date;

  eventType: string;

  eventTypeId?: number;

  runCount: number;

  failedCount: number;

  lastRun?: Date;

  active: boolean;

  error?: string;

  constructor() {
    this.webhookId = 0;
    this.eventType = '';
    this.runCount = 0;
    this.failedCount = 0;
    this.active = true;
    this.created = new Date();
  }

  static getWebhookTypeText(type: string, t: TFunction<string>) {
    switch (type) {
      case 'event-created':
        return t('webhook:Types.EventCreated');
      case 'event-updated':
        return t('webhook:Types.EventUpdated');
      case 'event-deleted':
        return t('webhook:Types.EventDeleted');
      case 'task-created':
        return t('webhook:Types.TaskCreated');
      case 'task-updated':
        return t('webhook:Types.TaskUpdated');
      case 'task-deleted':
        return t('webhook:Types.TaskDeleted');
      case 'risk-created':
        return t('webhook:Types.RiskCreated');
      case 'risk-updated':
        return t('webhook:Types.RiskUpdated');
      case 'risk-deleted':
        return t('webhook:Types.RiskDeleted');
      case 'monitoringtask-started':
        return t('webhook:Types.MonitoringtaskStarted');
    }
  }

  static isTaskType(type: string) {
    switch (type) {
      case 'event-created':
      case 'event-updated':
      case 'event-deleted':
      case 'task-created':
      case 'task-updated':
      case 'task-deleted':
      case 'monitoringtask-started':
        return true;
      default:
        return false;
    }
  }
}

export class WebhookRun {
  webhookId: number;

  webhookRunId: number;

  created: Date;

  workflowId?: string;

  workflowRunId?: string;

  entityId?: number;

  webhook?: Webhook;

  constructor() {
    this.webhookId = 0;
    this.webhookRunId = 0;
    this.created = new Date();
  }
}
