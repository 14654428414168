import { HoverCard, HoverCardType, IPlainCardProps, IStackItemStyles, Stack, Text, getTheme } from '@fluentui/react';
import AppContext from 'App/AppContext';
import KanbanCardTaskGenericWithState from 'components/Kanban/Cards/KanbanCardTaskGenericWithState';
import Task from 'models/tasks/task';
import { useContext } from 'react';
import { TaskViewScheduleLevel } from './TasksView';
import { toLocaleDateShortNoYear } from 'utils/datetime';

interface ITaskDateLinkWithHoverCardProps {
  task: Task;
  level: TaskViewScheduleLevel;
  onTaskClick: (task: Task) => void;
}

export const TaskDateLinkWithHoverCard = (props: ITaskDateLinkWithHoverCardProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const onRenderCompactCardSingle = (item: Task): JSX.Element => {
    return (
      <Stack styles={{ root: { width: 210, borderRadius: '3px', border: '1px solid lightgrey', margin: 3 } }}>
        <KanbanCardTaskGenericWithState
          key={item.taskId.toString()}
          task={item}
          onClick={() => props.onTaskClick(item)}
        />
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderCompactCardSingle,
    renderData: props.task,
  };

  const getDayLinkStyleProps = (): IStackItemStyles => {
    let backgroundColor = appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt;
    if (props.task.completed) {
      backgroundColor = appContext.useDarkMode ? '#52854e' : '#9bcc97';
    } else {
      const isOverDeadline = props.task.completed ? false : props.task.getMinutesOverDeadline(new Date()) > 0;
      if (isOverDeadline) {
        backgroundColor = appContext.useDarkMode ? '#a1574f' : '#fc8d81';
      }
    }

    return {
      root: {
        background: backgroundColor,
        color: appContext.useDarkMode ? theme.palette.themeLight : theme.palette.themeSecondary,
        height: 22,
        width: props.level === TaskViewScheduleLevel.Month ? 30 : 70,
        borderRadius: 4,
        borderColor: theme.palette.themeSecondary,
        cursor: 'pointer',
      },
    };
  };

  //
  // Main render
  //
  let text: string = '';
  switch (props.level) {
    case TaskViewScheduleLevel.Month:
      text = props.task.startDateTime.getDate().toString();
      break;
    case TaskViewScheduleLevel.Quarter:
    case TaskViewScheduleLevel.Year:
      text = toLocaleDateShortNoYear(props.task.startDateTime);
      break;
  }

  return (
    <HoverCard
      cardOpenDelay={300}
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={false}
    >
      <Stack.Item
        styles={getDayLinkStyleProps()}
        onClick={() => {
          props.onTaskClick(props.task);
        }}
      >
        <Stack horizontalAlign="center" verticalAlign="center">
          <Text variant="mediumPlus">{text}</Text>
        </Stack>
      </Stack.Item>
    </HoverCard>
  );
};
