import React, { useContext, useEffect, useState } from 'react';
import { IImageProps, ImageFit, Image, Link, Stack, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import Package from 'models/package';
import Config from 'services/Config/configService';
import { apiGetPackagesForStore, apiGetPackagesForStoreId } from 'services/Api/packageService';
import { apiRequest } from 'services/Auth/authConfig';
import { globalAppName } from 'globalConstants';
import TrialWelcomeOptionsWithPackage from './TrialWelcomeOptionsWithPackage';
import TrialWelcomeOptionsWithoutPackage from './TrialWelcomeOptionsWithoutPackage';
import { TenantOnboarding } from 'models/tenant';
import { apiGetTenantOnboardingState, apiUpdateTenantTrialOnboarding } from 'services/Api/tenantService';
import { apiGetLanguages } from 'services/Api/languageService';
import Language from 'models/language';
import { apiSetSetting } from 'services/Api/settingService';
import {
  AzureAD,
  DefLanguageCode,
  OrgLanguageCodes,
  OutlookTasksAdvanced,
  SharepointLibraries,
  SharepointLists,
  SharepointPages,
} from 'models/setting';
import UserLanguage from 'models/userLanguage';
import { apiUpdateUser } from 'services/Api/userService';
import OverlayLoader from 'components/Loading/OverlayLoader';

interface ITrialWelcomeProps {}

const TrialWelcome = (props: ITrialWelcomeProps) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);
  const [isLoadingLang, setIsLoadingLang] = useState<boolean>(false);
  const [packages, setPackages] = useState<Package[]>([]);
  const [onboardingState, setOnboardingState] = useState<TenantOnboarding>(new TenantOnboarding());
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLang, setSelectedLang] = useState<Language | undefined>(undefined);
  const [adminConsentResult, setAdminConsentResult] = useState<boolean | undefined>(undefined);

  const imagePropsTrialWelcome: IImageProps = {
    src: `${Config.getImageURL()}/trialwelcome.png`,
    imageFit: ImageFit.contain,
    width: 128,
    height: 128,
  };

  const getAdminConsentResultFromUrlParams = (): boolean | undefined => {
    const queryParams = new URLSearchParams(window.location.search);
    const param = 'admin_consent';
    if (queryParams.has('error') || queryParams.has('error_subcode')) {
      return false;
    }
    if (queryParams.has(param)) {
      const val = queryParams.get(param);

      return val?.toLocaleLowerCase() === 'true';
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingDone === true) {
      const adminConsentResult = getAdminConsentResultFromUrlParams();
      setAdminConsentResult(adminConsentResult);
      if (adminConsentResult) {
        enableAllAuth();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDone]);

  const enableAllAuth = async () => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      const azureAd = await apiSetSetting(AzureAD, true, accessToken);
      if (azureAd.settingValue) {
        appContext.globalDataCache.settings.set(AzureAD, true);
      }
      const outlookAdvanced = await apiSetSetting(OutlookTasksAdvanced, true, accessToken);
      if (outlookAdvanced.settingValue) {
        appContext.globalDataCache.settings.set(OutlookTasksAdvanced, true);
      }
      const pages = await apiSetSetting(SharepointPages, true, accessToken);
      if (pages.settingValue) {
        appContext.globalDataCache.settings.set(SharepointPages, true);
      }
      const lists = await apiSetSetting(SharepointLists, true, accessToken);
      if (lists.settingValue) {
        appContext.globalDataCache.settings.set(SharepointLists, true);
      }
      const libraries = await apiSetSetting(SharepointLibraries, true, accessToken);
      if (libraries.settingValue) {
        appContext.globalDataCache.settings.set(SharepointLibraries, true);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      setIsLoading(false);
    }
  };

  const getOrgLang = (): Language => {
    const langCode = appContext.globalDataCache.settings.get(DefLanguageCode) as string;
    let language = languages.find((l) => l.code === langCode);

    if (!language) {
      language = languages.find((l) => l.code === UserLanguage.getFallBack());
      if (!language) {
        language = new Language();
        language.code = UserLanguage.getFallBack();
        language.languageId = 1;
        language.name = 'English';
      }
    }

    return language;
  };

  const loadData = async () => {
    if (isLoading || isLoadingDone) return;

    try {
      setIsLoading(true);
      appContext.showContentLoader();
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);

      const langCol = await apiGetLanguages(accessToken);
      setLanguages(langCol.languages);
      setSelectedLang(getOrgLang());

      const onboardingState = await apiGetTenantOnboardingState(accessToken);
      setOnboardingState(onboardingState);

      //load specific packages
      const packages = await apiGetPackagesForStoreId(
        onboardingState.packageIds.map((id) => Number.parseInt(id)),
        accessToken,
      );

      //load all preview packages
      let previewPackages = await apiGetPackagesForStore(false, accessToken);
      previewPackages = previewPackages.filter((p) => p.isPreview);

      setPackages([...packages, ...previewPackages]);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      setIsLoading(false);
      setIsLoadingDone(true);
    }
  };

  const updateOnboardingState = async (onboardingState: TenantOnboarding) => {
    if (isLoading) return;

    try {
      appContext.showContentLoader();
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateTenantTrialOnboarding(onboardingState, accessToken);
      setOnboardingState(onboardingState);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const openHelpPanel = () => {
    appContext.setHelpPanel(true);
  };

  const updatePackageStateInStore = (updatePack: Package | undefined) => {
    if (!updatePack) return;
    const newPackages = [...packages];
    const existingPack = packages.find((p) => p.packageId === updatePack.packageId);
    if (existingPack) {
      existingPack.state = updatePack.state;
      setPackages(newPackages);
    }
  };

  const setUserLanguage = async (newLang: string): Promise<void | undefined> => {
    try {
      setIsLoadingLang(true);
      if (!UserLanguage.getSupportedLanguages().has(newLang)) {
        newLang = UserLanguage.getFallBack();
      }

      const newLanguage = languages.find((l) => l.code === newLang);
      setSelectedLang(newLanguage);
      if (appContext.user.language.code === newLang) return;

      //update the user language in the application
      newLang = await appContext.changeUserLanguage(newLang);

      //override the default lanauge of the user
      appContext.user.login.userLanguageCode = newLang;
      setIsLoadingLang(false);

      //update the user preference in the back-end
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateUser(appContext.user, accessToken);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingLang(false);
    }
  };

  const updateOrgLang = async (langCode: string) => {
    try {
      appContext.showContentLoader();
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);

      //add the selected language to the organizational language when it is not present
      const orgLangs = appContext.globalDataCache.settings.get(OrgLanguageCodes) as string[];
      if (!orgLangs.includes(langCode)) {
        orgLangs.push(langCode);
        await apiSetSetting(OrgLanguageCodes, orgLangs, accessToken);
        appContext.globalDataCache.settings.set(OrgLanguageCodes, orgLangs);
      }

      //set the default language setting
      await apiSetSetting(DefLanguageCode, langCode, accessToken);
      appContext.globalDataCache.settings.set(DefLanguageCode, langCode);

      //Change the user language
      setUserLanguage(langCode);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  //
  // Main render
  //
  if (isLoading || !isLoadingDone) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      {isLoadingLang && <OverlayLoader />}
      <Stack horizontal tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Image {...imagePropsTrialWelcome}></Image>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <Text block variant="xLarge">
                {t('dashboard:TrialWelcome.Info1', { appName: globalAppName })}
              </Text>
              <Text block variant="xLarge">
                {t('dashboard:TrialWelcome.Info2')}
              </Text>
            </Stack.Item>
            {!appContext.isMobileView && (
              <Stack.Item styles={{ root: { paddingTop: 10 } }}>
                <Text variant="small">{t('dashboard:TrialWelcome.InfoOpen')}&nbsp;</Text>
                <Text variant="small">
                  <Link onClick={() => openHelpPanel()}>{t('dashboard:TrialWelcome.InfoOpenLink')}</Link>
                </Text>
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
      {packages.length > 0 && (
        <Stack.Item>
          <TrialWelcomeOptionsWithPackage
            onboardingState={onboardingState}
            packages={packages}
            updatePackage={updatePackageStateInStore}
            updateOnboardingState={updateOnboardingState}
            languages={languages}
            updateOrgLang={updateOrgLang}
            adminConsentResult={adminConsentResult}
            selectedLang={selectedLang}
          />
        </Stack.Item>
      )}
      {packages.length === 0 && (
        <Stack.Item>
          <TrialWelcomeOptionsWithoutPackage
            onboardingState={onboardingState}
            updateOnboardingState={updateOnboardingState}
            languages={languages}
            adminConsentResult={adminConsentResult}
            updateOrgLang={updateOrgLang}
            selectedLang={selectedLang}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default TrialWelcome;
