import { FunctionComponent, useContext, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Text, Label, Checkbox } from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { FluentUIPieChart, IPieChartDataPoint } from 'components/Charts/PieChart';
import { apiGetWidgetDataRiskStatus } from 'services/Api/dashboardService';
import RiskState, { RiskStateId } from 'models/riskState';
import { createColorGradient } from 'utils/color';
import Tag from 'models/tag';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { IWidgetRendererProps } from '../WidgetRenderer';

export class WidgetRiskStatusConfig {
  tagIds: number[];

  showTags: boolean;

  tags: Tag[];

  constructor() {
    this.tagIds = [];
    this.showTags = false;
    this.tags = [];
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.tagIds = newRawConfig.tagIds ?? [];
        this.showTags = newRawConfig.showTags ?? false;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetRiskStatusConfig {
    const newConfig = new WidgetRiskStatusConfig();
    newConfig.tagIds = [...this.tagIds];
    newConfig.tags = [...this.tags];
    newConfig.showTags = this.showTags;

    return newConfig;
  }
}

interface IWidgetRiskStatusProps extends IWidgetRendererProps {}

const WidgetRiskStatus: FunctionComponent<IWidgetRiskStatusProps> = (props: IWidgetRiskStatusProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [data, setData] = React.useState<RiskState[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [riskCount, setRiskCount] = React.useState<number>(0);
  const [config, setConfig] = React.useState<WidgetRiskStatusConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        await appContext.globalDataCache.tags.getItems();
        const config = loadConfig();
        setConfig(config);

        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const data = await apiGetWidgetDataRiskStatus(config.tags, accessToken);
        setData(data.sort((a, b) => a.sortOrder - b.sortOrder));
        setRiskCount(data.reduce((sum, current) => sum + (current.riskCount ?? 0), 0));
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetRiskStatusConfig => {
    let newConfig = new WidgetRiskStatusConfig();
    newConfig.load(props.widget.widgetConfig);
    newConfig.tags = appContext.globalDataCache.tags.getItemsForId(newConfig.tagIds).filter((t) => t.tagId !== 0);

    return newConfig;
  };

  const getRiskStateColor = (idx: number): string => {
    const startColor = '#9e44e3';
    const endColor = '#44e364';
    const colors = createColorGradient(startColor, endColor, data.length);

    return colors[idx];
  };

  const getData = (): IPieChartDataPoint[] => {
    if (data.length === 0) return [];

    const config = loadConfig();
    const tagStr = `&tags=${config.tags.map((t) => t.tagId.toString()).join(',')}`;

    const graphData: IPieChartDataPoint[] = data.map((s, idx) => {
      const state: string = RiskStateId[s.riskStateId];

      return {
        name: s.state,
        value: s.riskCount ?? 0,
        color: getRiskStateColor(idx),
        link: `/risks/overview?status=${state}${config.tags.length > 0 ? tagStr : ''}`,
      };
    });

    return graphData;
  };

  if (!data || isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (riskCount === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:RiskStatus.NoRisks')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      {config && config.tags.length > 0 && config.showTags && (
        <Stack.Item styles={{ root: { maxHeight: 44, overflowY: 'hidden' } }}>
          <KeyValueTags tags={config.tags} compact tagMaxWidth={200} />
        </Stack.Item>
      )}
      <Stack.Item grow>
        <FluentUIPieChart
          data={getData()}
          height={config && config.tags.length > 0 && config.showTags ? 190 : 250}
          width={280}
          label={riskCount.toString()}
        ></FluentUIPieChart>
      </Stack.Item>
    </Stack>
  );
};

export default WidgetRiskStatus;

//
// Config
//

interface IWidgetConfigWidgetRiskStatusProps extends IWidgetConfigRendererProps {}

export const WidgetConfigRiskStatus: FunctionComponent<IWidgetConfigWidgetRiskStatusProps> = (
  props: IWidgetConfigWidgetRiskStatusProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [showTags, setShowTags] = useState<boolean>(false);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetRiskStatusConfig => {
    let newRawConfig = new WidgetRiskStatusConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
      newRawConfig.tags = appContext.globalDataCache.tags
        .getItemsForId(newRawConfig.tagIds)
        .filter((t) => t.tagId !== 0);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const config = loadConfig();

      if (config.tags) {
        setSelectedTags(config.tags);
      }

      setShowTags(config.showTags);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (tags: Tag[], showTags: boolean): WidgetRiskStatusConfig => {
    const config = new WidgetRiskStatusConfig();
    config.tagIds = tags.map((t) => t.tagId);
    config.showTags = showTags;

    return config;
  };

  const onAdd = (tag: Tag) => {
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onRemove = (tag: Tag) => {
    const newTags = selectedTags.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetShowTags = (checked: boolean) => {
    setShowTags(checked);
    const config = getConfig(selectedTags, checked);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Label>{t('widgets:RiskStatus.Config.Label')}</Label>
        <KeyValueTagPicker
          itemLimit={5}
          selectedTags={selectedTags}
          onAdd={onAdd}
          onRemove={onRemove}
          isLoading={isLoading}
          allowCreate={false}
        ></KeyValueTagPicker>
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showTags}
          label={t('widgets:RiskStatus.Config.LabelShowTags')}
          onChange={(ev, checked) => {
            onSetShowTags(checked ?? false);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
