import Language from '../language';

export class Process_Translation {
  processId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.processId = -1;
    this.languageId = -1;
    this.name = '';
  }

  clone(): Process_Translation {
    const output = new Process_Translation();
    output.processId = this.processId;
    output.languageId = this.languageId;
    output.name = this.name;
    output.description = this.description;
    output.lang = this.lang?.clone();

    return output;
  }
}
