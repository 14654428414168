import { sortOnArray, sortOnNumber, sortOnString } from 'utils/sorting';
import { IColumn } from '@fluentui/react';
import KPI from 'models/kpi/kpi';
import { IAppContext } from 'App/AppContext';

const getAscendingSortValueKey = (appContext: IAppContext, a: KPI, b: KPI, key: string): number => {
  if (key === 'name') return sortOnString(a.name, b.name);
  if (key === 'description') return sortOnString(a.description, b.description);
  if (key === 'type') return sortOnNumber(a.type, b.type);
  if (key === 'tags') return sortOnArray(a.tagIds, b.tagIds);
  if (key === 'usage') {
    const countA = (a.taskTypeIds.length ?? 0) + (a.dashboardIds.length ?? 0);
    const countB = (b.taskTypeIds.length ?? 0) + (b.dashboardIds.length ?? 0);

    return sortOnNumber(countA, countB);
  }
  if (key === 'alerts') {
    const countA = a.alerts.length;
    const countB = b.alerts.length;

    return sortOnNumber(countA, countB);
  }
  if (key === 'permissions') {
    const a1 = appContext.globalDataCache.authSchemas.get(a.authSchemaId);
    const b1 = appContext.globalDataCache.authSchemas.get(b.authSchemaId);

    return sortOnString(a1.name, b1.name);
  }

  return 1;
};

export const copyAndSortKPIs = (appContext: IAppContext, items: KPI[], column: IColumn, isSortedDescending: boolean | undefined): KPI[] => {
  const key = column.key;

  return items.slice().sort((a: KPI, b: KPI) => {
    const tmp = getAscendingSortValueKey(appContext, a, b, key);

    return isSortedDescending ? -tmp : tmp;
  });
};
