import { useContext } from 'react';
import { getTheme, Stack, Persona, PersonaSize, Text, TooltipHost } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapSmall } from 'globalStyles';
import Activity from 'models/activity';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { useTranslation } from 'react-i18next';

interface ISingleActivity {
  activity: Activity;
}

export const SingleActivity = (props: ISingleActivity) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['activity']);
  const theme = getTheme();
  const message = props.activity.getActivityTabText(appContext.globalDataCache);

  return (
    <Stack>
      <Stack
        horizontal
        tokens={globalStackTokensGapSmall}
        styles={{
          root: {
            borderWidth: 1,
            borderColor: appContext.useDarkMode ? theme.palette.themeDark : theme.palette.themeLight,
            background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighter,
            borderStyle: 'solid',
            borderRadius: '3px',
            marginBottom: 10,
            padding: 2,
          },
        }}
      >
        <Stack.Item>
          <Persona
            text={props.activity.getUserName(appContext.globalDataCache)}
            hidePersonaDetails={true}
            size={PersonaSize.size24}
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack>
            <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
              <Stack.Item>
                <Text block nowrap variant="small">
                  {toLocaleDateTimeMedium(props.activity.created)}
                </Text>
              </Stack.Item>
              {props.activity.dismissed && (
                <Stack.Item styles={{ root: { marginTop: 0 } }}>
                  <TooltipHost
                    content={t('activity:DismissedTooltip', {
                      date: toLocaleDateTimeMedium(props.activity.dismissed),
                    })}
                  >
                    <Text variant="small">
                      <u>{t('activity:Dismissed')}</u>
                    </Text>
                  </TooltipHost>
                </Stack.Item>
              )}
            </Stack>
            <Stack.Item>
              <TooltipHost content={message}>
                <Text block>{message}</Text>
              </TooltipHost>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
