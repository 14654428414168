import { FontIcon } from '@fluentui/react';
import { validIcon, errorIcon, unknownIcon } from 'globalStyles';

interface IValidationIconProps {
  status: boolean | undefined;
}

const ValidationIcon = (props: IValidationIconProps) => {
  if (props.status !== undefined) {
    if (props.status === true) {
      return <FontIcon {...validIcon} style={{ fontSize: 20, color: 'green' }} />;
    } else {
      return <FontIcon {...errorIcon} style={{ fontSize: 20, color: 'red' }} />;
    }
  } else {
    return <FontIcon {...unknownIcon} style={{ fontSize: 20 }} />;
  }
};

export default ValidationIcon;
