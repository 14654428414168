import {
  TextField,
  PrimaryButton,
  Text,
  DefaultButton,
  DialogFooter,
  Modal,
  Stack,
  ITextFieldStyles,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium } from 'globalStyles';
import AppContext from 'App/AppContext';
import { useContext } from 'react';

interface IModifyTextDialogProps {
  isActionPending?: boolean;
  value?: string;
  isOpen: boolean;
  title: string;
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  width?: number | string;
  maxLength?: number;
  required?: boolean;
  textFieldStyles?: Partial<ITextFieldStyles>;
  onClose: () => void;
  onUpdate: (newValue: string) => void;
  onSave: () => void;
  onGetErrorMessage?: (value: string) => string | undefined;
}

const ModifyTextDialog = (props: IModifyTextDialogProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);

  const cannotSave = (): boolean => {
    if (props.isActionPending) return true;
    if (props.onGetErrorMessage && props.onGetErrorMessage(props.value || '') !== undefined) return true;
    if (props.required && (!props.value || props.value?.trim() === '')) return true;

    return false;
  };

  return (
    <Modal isBlocking={false} isOpen={props.isOpen} onDismiss={props.onClose}>
      <Stack
        styles={{ root: { padding: 20, minWidth: 300, width: props.width ?? 300 } }}
        tokens={globalStackTokensGapMedium}
      >
        <Text variant="xLarge">{props.title}</Text>
        <TextField
          autoFocus={!appContext.isMobileView}
          required={props.required}
          styles={props.textFieldStyles}
          label={props.label}
          placeholder={props.placeholder}
          value={props.value}
          multiline={props.multiline}
          maxLength={props.maxLength}
          rows={10}
          onGetErrorMessage={props.onGetErrorMessage}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.onUpdate(newValue ? newValue : '');
          }}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={cannotSave()}
            onClick={() => {
              props.onSave();
              props.onClose();
            }}
            text={t('translation:General.Button.Save')}
          />
          <DefaultButton
            disabled={props.isActionPending}
            onClick={() => {
              props.onClose();
            }}
            text={t('translation:General.Button.Cancel')}
          ></DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default ModifyTextDialog;
