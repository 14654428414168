export class AzureSubscription {
  id: string;

  displayName: string;

  status: string;

  constructor() {
    this.id = '';
    this.displayName = '';
    this.status = '';
  }
}
