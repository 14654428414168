import {
  TagPicker,
  ITag,
  IPickerItemProps,
  ISuggestionItemProps,
  IBasePickerSuggestionsProps,
  IInputProps,
} from '@fluentui/react';
import { useState } from 'react';
import Entity, { EntityTypes } from 'models/entity';
import { useTranslation } from 'react-i18next';
import { sortOnString } from 'utils/sorting';
import { globalSuggestionsMaxRecords } from 'globalConstants';
import { ISingleTag, SingleTag } from 'components/Tags/SingleTag';

interface IEntityTagPickerProps {
  entityType: EntityTypes;
  tagColor: string;
  entities: Entity[];
  isLoading?: boolean;
  onAddEntity: (item: Entity) => void;
  onRemoveEntity: (item: Entity) => void;
  loadData?: () => Promise<Entity[]>;
  isUpdating?: number;
  suggestionLimit?: number;
  selectedEntities?: Entity[];
  placeholder?: string;
  itemLimit?: number;
  disabled?: boolean;
  maxTagWidth?: number;
}

const EntityTagPicker = (props: IEntityTagPickerProps) => {
  const { t } = useTranslation(['translation']);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const getITag = (entity: Entity): ITag => {
    return {
      name: entity.entityName,
      key: entity.entityId.toString(),
    };
  };

  const getITags = (entities: Entity[] | undefined): ITag[] | undefined => {
    return entities?.map((t) => getITag(t));
  };

  const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
    if (filter) {
      let data = props.entities;
      if (!isLoaded && !props.isLoading && props.loadData && props.entities.length === 0) {
        setIsLoaded(true);
        data = await props.loadData();
      }

      const filterLower = filter.toLowerCase();

      return data
        .filter(
          (entity: Entity) =>
            !props.selectedEntities?.some((t) => t.entityId === entity.entityId) &&
            entity.entityName.toLowerCase().includes(filterLower),
        )
        .sort((a, b) => {
          return sortOnString(a.entityName, b.entityName);
        })
        .map((entity: Entity): ITag => {
          return getITag(entity);
        });
    }

    return [];
  };

  const removeSelectedItem = (item: ISingleTag) => {
    //remove from selected items
    if (props.onRemoveEntity) {
      const entity = new Entity(item.tagId as number, props.entityType, item.tagName);
      props.onRemoveEntity(entity);
    }
  };

  const onRenderItem = (pickerProps: IPickerItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps.item;
    if (!props.selectedEntities) return <span />;

    const entity = props.selectedEntities.find((t) => t.entityId.toString() === tagItem.key);
    if (!entity) return <span />;

    return (
      <SingleTag
        key={entity.entityId}
        tag={getISingleTag(entity)}
        onRemove={removeSelectedItem}
        maxWidth={props.maxTagWidth ?? 260}
      />
    );
  };

  const getISingleTag = (entity: Entity): ISingleTag => {
    return { tagId: entity.entityId, tagName: entity.entityName, tagColor: props.tagColor };
  };

  const onRenderSuggestionsItem = (pickerProps: ITag, itemProps: ISuggestionItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps;
    if (!props.selectedEntities) return <span />;

    const entity = props.entities.find((t) => t.entityId.toString() === tagItem.key);
    if (!entity) return <span />;

    return <SingleTag key={entity.entityId} tag={getISingleTag(entity)} maxWidth={props.maxTagWidth ?? 500} />;
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    noResultsFoundText: t('translation:General.Suggestions.NoData'),
    onRenderNoResultFound: undefined,
  };

  const inputProps: IInputProps = {
    placeholder: props.placeholder,
  };

  const onSelectItem = (item?: ITag | undefined) => {
    if (item) {
      const id = item.key as string;
      const entity = props.entities?.find((t) => t.entityId.toString() === id);
      if (entity) {
        const newSelectedTags = props.selectedEntities?.slice(0) || [];
        newSelectedTags.push(entity);
        if (props.onAddEntity) {
          props.onAddEntity(entity);
        }
      }

      return item;
    }

    return null;
  };

  //
  // Main render
  //
  return (
    <TagPicker
      itemLimit={props.itemLimit}
      onResolveSuggestions={onResolveSuggestions}
      pickerSuggestionsProps={suggestionProps}
      selectedItems={getITags(props.selectedEntities)}
      onRenderSuggestionsItem={onRenderSuggestionsItem}
      disabled={props.disabled}
      onRenderItem={onRenderItem}
      onItemSelected={onSelectItem}
      inputProps={inputProps}
    />
  );
};

export default EntityTagPicker;
