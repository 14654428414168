export class KPIGraphDataPointDTO {
  date: string | undefined;

  value: number | undefined;
}

export class KPIGraphDataStackedPoint {
  date: string | undefined;

  values: { [key: string]: number } | undefined;
}

export default class KPIGraphDataDTO {
  points: KPIGraphDataPointDTO[];

  stackedPoints: KPIGraphDataStackedPoint[];

  constructor() {
    this.points = [];
    this.stackedPoints = [];
  }
}

export class KPIGraphDataConfigDTO {
  kpiId: number;

  periodStart: string | undefined;

  periodEnd: string | undefined;

  aggregationMethod: number;

  aggregationPeriod: number | undefined;

  aggregationInterval: number | undefined;

  chartType: number;

  contextFilter?: KPIContextFilterDTO;

  constructor() {
    this.kpiId = 0;
    this.periodStart = undefined;
    this.periodEnd = undefined;
    this.aggregationMethod = 0;
    this.chartType = 0;
  }
}

//
// KPI Context filter
//

export class KPIContextFilterDTO {
  group: KPIContextFilterGroupDTO;

  constructor() {
    this.group = new KPIContextFilterGroupDTO();
  }
}

export class KPIContextFilterGroupDTO {
  items: KPIContextFilterItemDTO[];

  operator: number;

  constructor() {
    this.items = [];
    this.operator = 0;
  }
}

export class KPIContextFilterItemDTO {
  group: KPIContextFilterGroupDTO | undefined;

  entityType: number;

  entityId?: number;

  entityGuid?: string;

  entityText?: string;

  entityDateTime?: string;

  constructor() {
    this.entityType = 0;
  }
}
