import { Process_TranslationDTO } from './process_TranslationDTO';
import { ObjectiveDTO } from '../Objective/objectiveDTO';
import IdListDTO from '../IdListDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { PDCAState } from 'models/pdca';
import DashboardDTO from '../dashboardDTO';

export class ProcessDTO {
  processId: number;

  authSchemaId?: number;
  
  code: string;

  parentProcessId: number | undefined;

  commentTrailId: number;

  auditTrailId: number;

  groupId: string | undefined;

  ownerId: string | undefined;

  ownerRoleId?: string;

  state: PDCAState;

  tasks: TaskDTO[] | undefined | undefined;

  tagIds: IdListDTO;

  objectives: ObjectiveDTO[] | undefined | undefined;

  dashboard?: DashboardDTO;

  trans: Process_TranslationDTO[];

  constructor() {
    this.processId = -1;
    this.code = '';
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.state = PDCAState.Act;
    this.tasks = [];
    this.tagIds = new IdListDTO([]);
    this.objectives = [];
    this.trans = [];
    this.groupId = undefined;
    this.ownerId = undefined;
    this.parentProcessId = undefined;
  }
}
