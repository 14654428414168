import { useContext, useEffect, useState } from 'react';
import {
  PrimaryButton,
  DefaultButton,
  Text,
  Modal,
  Separator,
  IconButton,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalStackTokensGapSmall,
  globalStackStylesPanel,
  globalStackTokensGapMedium,
  cancelIcon,
} from 'globalStyles';
import ResourceLink, { ILinkRow } from 'models/resourceLink';
import LibraryPicker from 'components/Pickers/LibraryPicker';
import AppContext from 'App/AppContext';
import ResourceList from 'models/resourceList';
import { apiGetLinksForList } from 'services/Api/linkService';
import { apiRequest } from 'services/Auth/authConfig';
import InfoMessage from 'components/Notification/InfoMessage';

interface IVirtualContentPickerProps {
  list: ResourceList;
  isOpen: boolean;
  existingLinks: ResourceLink[];
  onSelect: (links: ILinkRow[]) => void;
  onClose: () => void;
}

const VirtualContentPicker = (props: IVirtualContentPickerProps) => {
  const { t } = useTranslation(['library', 'translation']);
  const appContext = useContext(AppContext);
  const [selectedLinks, setSelectedLinks] = useState<ResourceLink[]>([]);
  const [links, setLinks] = useState<ResourceLink[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      await appContext.globalDataCache.lists.getItems(); //make sure the lists are loaded
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const links = await apiGetLinksForList(props.list.listId, accessToken, appContext.globalDataCache);

      setLinks(links);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelect = () => {
    const selectedLinkRows = selectedLinks.map((item: ResourceLink) => {
      return {
        item: item,
        isBusy: false,
      };
    });
    props.onSelect(selectedLinkRows);
    setLinks([...links]);
  };

  const onCancel = () => {
    props.onClose();
  };

  return (
    <Modal isBlocking={true} isOpen={props.isOpen} onDismiss={onCancel}>
      <Stack
        verticalFill
        styles={{ root: { height: '90vh', width: 600, minWidth: 320, maxWidth: '90vw', padding: 20 } }}
      >
        {/*Header*/}
        <Stack.Item>
          <Stack horizontal horizontalAlign='space-between'>
            <Stack.Item>
              <Text variant="xLarge">{t('library:ApprovalTasks.AddContent.Label')}</Text>
            </Stack.Item>
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Separator />
        <Stack.Item>
          <InfoMessage message={t('library:VirtualPicker.Info')}/>
        </Stack.Item>
        {/*Body */}
        <Stack.Item grow>
          {isLoading ? (
            <Stack verticalFill horizontalAlign="center" verticalAlign="center">
              <Spinner size={SpinnerSize.large} />
            </Stack>
          ) : (
            <Stack verticalFill styles={globalStackStylesPanel} tokens={globalStackTokensGapMedium}>
              <LibraryPicker
                multiSelect={true}
                onSelect={setSelectedLinks}
                links={links}
                lists={appContext.globalDataCache.lists.items}
                isLoading={isLoading}
                expandGroups={true}
                existingLinks={props.existingLinks}
              />
              <Stack.Item>
                <Text>
                  {t('library:ApprovalTasks.AddContent.SelectedCount', {
                    total: links.length,
                    selected: selectedLinks.length,
                  })}
                </Text>
              </Stack.Item>
            </Stack>
          )}
        </Stack.Item>

        {/*Footer*/}
        <Stack.Item>
          <Stack horizontal styles={globalStackStylesPanel} tokens={globalStackTokensGapSmall} horizontalAlign="end">
            <Stack.Item>
              <PrimaryButton onClick={() => onSelect()} disabled={selectedLinks.length === 0}>
                {t('translation:General.Button.Add')}
              </PrimaryButton>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton onClick={props.onClose} disabled={isLoading}>
                {t('translation:General.Button.Cancel')}
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default VirtualContentPicker;
