import User from 'models/user';
import { useTranslation } from 'react-i18next';
import { Label, Persona, PersonaSize, Stack } from '@fluentui/react';
import UserRoleGroupPicker, {
  getUserRoleGroupItem,
  IUserRoleGroup,
  renderUserRoleGroupItem,
} from './UserRoleGroupPicker';
import { useContext, useEffect, useState } from 'react';
import AppContext from 'App/AppContext';
import { Role } from 'models/auth/role';
import { IOwner, Owner } from 'models/owner';

interface IOwnerPickerProps {
  onSelect?: (item: User | Role | undefined, owner: IOwner | undefined) => void;
  selectedItem?: IOwner;
  selectedItemId?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  disallowRoles?: boolean;
}

export const OwnerPicker = (props: IOwnerPickerProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [owner, setOwner] = useState<Owner | undefined>(undefined);

  useEffect(() => {
    if (props.selectedItem) {
      setOwner(new Owner(props.selectedItem, appContext));
    } else if (props.selectedItemId) {
      const owner = new Owner();
      owner.appContext = appContext;
      owner.setId(props.selectedItemId);
      setOwner(owner);
    } else {
      setOwner(new Owner(undefined, appContext));
    }
  }, [appContext, props.selectedItem, props.selectedItemId]);

  //
  // Main render
  //
  if (!owner) {
    return null;
  }

  return (
    <Stack>
      {!props.hideLabel && <Label>{t('translation:General.OwnerPicker.Label')}</Label>}
      <UserRoleGroupPicker
        allowedItems={[]}
        itemLimit={1}
        selectedItems={owner.itemAsArray}
        onSelect={(items) => {
          const newOwner = new Owner();
          const newItem = items[0] as User | Role | undefined;
          newOwner.fromItem(newItem);
          if (props.onSelect) props.onSelect(newItem, newOwner);
        }}
        disabled={props.disabled}
        placeholder={
          props.disallowRoles === true
            ? t('translation:General.OwnerPicker.PlaceholderUser')
            : t('translation:General.OwnerPicker.Placeholder')
        }
        allowGroups={false}
        allowRoles={props.disallowRoles === true ? false : true}
        allowUsers={true}
        onRemove={() => {
          if (props.onSelect) props.onSelect(undefined, undefined);
        }}
      />
    </Stack>
  );
};

interface IOwnerItemProps {
  owner?: IOwner;
  allowHoverCard?: boolean;
  showNoOwner?: boolean;
}

export const OwnerItem = (props: IOwnerItemProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);

  const getItem = (): IUserRoleGroup | undefined => {
    const owner = new Owner(props.owner, appContext);
    const item = owner.item;

    return item ? getUserRoleGroupItem(item) : undefined;
  };

  //
  // Main render
  //
  const item = getItem();

  if (item && item.id) {
    return renderUserRoleGroupItem(getItem(), true, undefined, props.allowHoverCard ?? false, 0, undefined, true);
  } else if (props.showNoOwner === true) {
    if (props.showNoOwner === true) {
      return (
        <Persona
          size={PersonaSize.size24}
          text={t('translation:General.OwnerPicker.NoOwner')}
          coinProps={{ showUnknownPersonaCoin: true }}
        />
      );
    }
  }

  return null;
};
