import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react';
import App from 'App/App';
import AppLoader from './components/Loading/AppLoader';
import localizationService from './services/Localization/i18n';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import './index.css';

localizationService.init();

initializeIcons();
initializeFileTypeIcons();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<AppLoader isLoading={true} />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
