import Asset from 'models/asset/asset';
import Task from '../models/tasks/task';
import Control from 'models/control';
import Objective from 'models/objective/objective';
import Process from 'models/process/process';
import Theme from 'models/theme';
import KPI from 'models/kpi/kpi';
import Risk from 'models/risk';

export const getUniqueTagIds = (
  items: Task[] | Asset[] | Risk[] | Process[] | Objective[] | Control[] | Theme[] | KPI[],
): number[] => {
  let ids: number[] = [];

  for (let idx = 0; idx < items.length; idx++) {
    const task = items[idx];
    if (task.tagIds) {
      for (let idx2 = 0; idx2 < task.tagIds.length; idx2++) {
        const id = task.tagIds[idx2];
        if (!ids.includes(id)) {
          ids.push(id);
        }
      }
    }
  }

  ids = [...new Set(ids)];

  return ids;
};
