import ReportRequestDTO from './ReportRequestDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { TFunction } from 'i18next';

export class ReportRequest16DTO extends ReportRequestDTO {
  tasks: TaskDTO[];

  taskColumns: string[];

  chartSeriesLabels: string[];

  chartYAxisLabel: string;

  unAssignedUserName: string;

  constructor(report: ReportRequestDTO, t?: TFunction<string[]>) {
    super(report);
    this.tasks = [];
    this.taskColumns = [];
    this.chartSeriesLabels = [];
    this.chartYAxisLabel = '';
    this.unAssignedUserName = '';

    if (t) {
      this.taskColumns = [
        t('translation:General.EntityType.Task'),
        t('tags:General.Name'),
        t('task:Assignment.Label'),
        t('task:Status.Label'),
        t('task:Start.Label'),
        t('task:Deadline.Label'),
        t('task:Pivot.Checklist'),
      ];

      this.chartSeriesLabels = [
        t('tasks:Reports.TaskStatusCompleted'),
        t('tasks:Reports.TaskStatusNotCompleted'),
        t('tasks:Reports.TaskStatusOverDeadline'),
      ];

      this.chartYAxisLabel = t('tasks:Reports.TaskCount');
      this.unAssignedUserName = t('tasks:Reports.UnAssignedUserName');
    }
  }

  clone(): ReportRequest16DTO {
    const output = new ReportRequest16DTO(this);
    output.tasks = [...this.tasks];
    output.taskColumns = [...this.taskColumns];
    output.chartSeriesLabels = [...this.chartSeriesLabels];
    output.chartYAxisLabel = this.chartYAxisLabel;

    return output;
  }
}
