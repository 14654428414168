import { getTheme, IButtonStyles, IconButton, Persona, PersonaSize, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { cancelIcon, globalStackTokensGapExtraSmall } from 'globalStyles';
import User from 'models/user';
import { useContext } from 'react';

interface IUserTagProps {
  user: User;
  compact?: boolean;
  hideBackground?: boolean;
  onRemove?: (item: User) => void;
}

const UserTag = (props: IUserTagProps) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();
  const size = props.compact ? PersonaSize.size24 : PersonaSize.size40;

  const tagButtonStyles: IButtonStyles = {
    root: {
      borderRadius: '10px',
      height: props.compact ? '24px' : '40px',
      width: props.compact ? '24px' : '40px',
    },
  };

  return (
    <Stack
      horizontal
      tokens={globalStackTokensGapExtraSmall}
      verticalAlign="center"
      styles={
        props.hideBackground !== true
          ? {
              root: {
                background: appContext.useDarkMode ? theme.palette.neutralSecondary : theme.palette.neutralLight,
                borderRadius: '10px',
                height: 1 + (props.compact ? 24 : 40),
              },
            }
          : undefined
      }
    >
      <Stack.Item>
        <Persona text={props.user.name} secondaryText={props.user.email} size={size} hidePersonaDetails={false} />
      </Stack.Item>
      {props.onRemove && (
        <Stack.Item>
          <IconButton
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.user);
              }
            }}
            styles={tagButtonStyles}
            iconProps={cancelIcon}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default UserTag;
