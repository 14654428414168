import { AzureSubscription } from 'models/azure/azureSubscription';
import { AzureSubscriptionDTO } from 'models/dto/azure/azureSubscriptionDTO';

export function mapFromAzureSubscriptions(azureSubscriptionDTOs: AzureSubscriptionDTO[]): AzureSubscription[] {
  return azureSubscriptionDTOs.map((item) => {
    return mapFromAzureSubscription(item);
  });
}

export function mapFromAzureSubscription(azureSubscriptionDTO: AzureSubscriptionDTO): AzureSubscription {
  const newItem = new AzureSubscription();

  newItem.id = azureSubscriptionDTO.id;
  newItem.displayName = azureSubscriptionDTO.displayName;
  newItem.status = azureSubscriptionDTO.status;

  return newItem;
}
