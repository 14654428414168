import { ISOControlISOControl } from './../models/isoControl';
import { mapFromISONorm } from 'mappings/dataMapping';
import ISOControl, { ISOControlTransMapping, ISOControlTransMappingItem } from 'models/isoControl';
import ISOControl_Translation, { PackageInstruction } from 'models/isoControl_Translation';
import ISOControlDTO, {
  ISOControlISOControlDTO,
  ISOControlTransMappingDTO,
  ISOControlTransMappingItemDTO,
} from 'models/dto/isoControlDTO';
import ISOControl_TranslationDTO, { PackageInstructionDTO } from 'models/dto/isoControl_TranslationDTO';
import { mapFromLanguage, mapToLanguage, mapFromControl } from './dataMapping';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';

export function mapFromISOControl(isoControlDTO: ISOControlDTO, globalDataCache: GlobalDataCache): ISOControl {
  const newisoControl = new ISOControl();
  let trans_idx: number = -1;

  newisoControl.isoControlId = isoControlDTO.isoControlId;
  newisoControl.parentISOControlId = isoControlDTO.parentISOControlId;
  newisoControl.code = isoControlDTO.code;
  newisoControl.isoNormId = isoControlDTO.isoNormId;
  newisoControl.changedAfterUpgrade = isoControlDTO.changedAfterUpgrade;
  newisoControl.controlType = isoControlDTO.controlType;
  newisoControl.trans = mapFromISOControl_Translation(isoControlDTO.trans);
  newisoControl.control = mapFromControl(isoControlDTO.control, globalDataCache);
  newisoControl.isoNorm = isoControlDTO.isoNorm ? mapFromISONorm(isoControlDTO.isoNorm) : undefined;
  newisoControl.isoControlISOControls = isoControlDTO.isoControlISOControls?.map((i) => mapFromISOControlISOControl(i));

  if (newisoControl.trans && newisoControl.trans.length > 0) {
    trans_idx = 0;
    newisoControl.transIdx = trans_idx;
    newisoControl.name = newisoControl.trans[trans_idx].name;
    newisoControl.description = newisoControl.trans[trans_idx].description;
    newisoControl.instruction = newisoControl.trans[trans_idx].instruction;
    newisoControl.packageInstructions = newisoControl.trans[0].packageInstructions;
  }

  return newisoControl;
}

export function mapFromISOControls(isoControlDTOs: ISOControlDTO[], globalDataCache: GlobalDataCache): ISOControl[] {
  let isoControlList: ISOControl[] = [];

  for (let isoControlDTO of isoControlDTOs) {
    const newisoControl = mapFromISOControl(isoControlDTO, globalDataCache);
    isoControlList.push(newisoControl);
  }

  return isoControlList;
}

export function mapFromISOControl_Translation(
  isoControl_TranslationDTOs: ISOControl_TranslationDTO[] | undefined,
): ISOControl_Translation[] | undefined {
  if (!isoControl_TranslationDTOs) {
    return undefined;
  }

  let isoControl_TranslationList: ISOControl_Translation[] = [];

  for (let isoControl_TranslationDTO of isoControl_TranslationDTOs) {
    const newISOControl_Translation = new ISOControl_Translation();
    newISOControl_Translation.isoControlId = isoControl_TranslationDTO.isoControlId;
    newISOControl_Translation.languageId = isoControl_TranslationDTO.languageId;
    newISOControl_Translation.name = isoControl_TranslationDTO.name;
    newISOControl_Translation.description = isoControl_TranslationDTO.description;
    newISOControl_Translation.instruction = isoControl_TranslationDTO.instruction;
    newISOControl_Translation.lang = mapFromLanguage(isoControl_TranslationDTO.lang);
    newISOControl_Translation.packageInstructions = mapFromPackageInstructions(
      isoControl_TranslationDTO.packageInstructions,
    );
    isoControl_TranslationList.push(newISOControl_Translation);
  }

  return isoControl_TranslationList;
}

export function mapFromPackageInstructions(
  packageInstrunctionDtos: PackageInstructionDTO[] | undefined,
): PackageInstruction[] | undefined {
  return packageInstrunctionDtos?.map((p) => mapFromPackageInstruction(p));
}

export function mapFromPackageInstruction(packageInstrunctionDto: PackageInstructionDTO): PackageInstruction {
  const output = new PackageInstruction();

  output.packageId = packageInstrunctionDto.packageId;
  output.packageName = packageInstrunctionDto.packageName;
  output.instruction = packageInstrunctionDto.instruction;

  return output;
}

export function mapToISOControl(isoControl: ISOControl): ISOControlDTO {
  const newISOControlDTO = new ISOControlDTO();

  newISOControlDTO.isoControlId = isoControl.isoControlId;
  newISOControlDTO.parentISOControlId = isoControl.parentISOControlId;
  newISOControlDTO.code = isoControl.code;
  newISOControlDTO.isoNormId = isoControl.isoNormId;
  newISOControlDTO.changedAfterUpgrade = isoControl.changedAfterUpgrade;
  newISOControlDTO.controlType = isoControl.controlType;
  newISOControlDTO.trans = mapToISOControl_Translation(isoControl.trans);

  if (newISOControlDTO.trans && isoControl.transIdx >= 0) {
    newISOControlDTO.trans[isoControl.transIdx].name = isoControl.name;
    newISOControlDTO.trans[isoControl.transIdx].description = isoControl.description;
    newISOControlDTO.trans[isoControl.transIdx].instruction = isoControl.instruction;
  }

  return newISOControlDTO;
}

export function mapToISOControl_Translation(
  isoControl_Translations: ISOControl_Translation[] | undefined,
): ISOControl_TranslationDTO[] | undefined {
  if (!isoControl_Translations) {
    return undefined;
  }

  let isoControl_TranslationList: ISOControl_TranslationDTO[] = [];

  for (let isoControl_Translation of isoControl_Translations) {
    const newIISOControl_TranslationDTO = new ISOControl_TranslationDTO();
    newIISOControl_TranslationDTO.isoControlId = isoControl_Translation.isoControlId;
    newIISOControl_TranslationDTO.languageId = isoControl_Translation.languageId;
    newIISOControl_TranslationDTO.name = isoControl_Translation.name;
    newIISOControl_TranslationDTO.description = isoControl_Translation.description;
    newIISOControl_TranslationDTO.instruction = isoControl_Translation.instruction;
    newIISOControl_TranslationDTO.lang = mapToLanguage(isoControl_Translation.lang);
    isoControl_TranslationList.push(newIISOControl_TranslationDTO);
  }

  return isoControl_TranslationList;
}

export function mapToISOControlISOControl(isoControlISOControl: ISOControlISOControl): ISOControlISOControlDTO {
  const newISOControlISOControlDTO = new ISOControlISOControlDTO();

  newISOControlISOControlDTO.isoControlIdFrom = isoControlISOControl.isoControlIdFrom;
  newISOControlISOControlDTO.isoControlIdTo = isoControlISOControl.isoControlIdTo;

  return newISOControlISOControlDTO;
}

export function mapFromISOControlTransMapping(
  isoControlTransMappingDTOs: ISOControlTransMappingDTO[],
): ISOControlTransMapping[] {
  const output = isoControlTransMappingDTOs.map((t) => {
    return {
      langCode: t.langCode,
      mapping: mapFromISOControlTransMappingItems(t.mapping),
    };
  });

  return output;
}

export function mapFromISOControlTransMappingItems(
  isoControlTransMappingItemDTOs: ISOControlTransMappingItemDTO[] | undefined,
): ISOControlTransMappingItem[] {
  if (!isoControlTransMappingItemDTOs) return [];

  return isoControlTransMappingItemDTOs.map((t) => mapFromISOControlTransMappingItem(t));
}

export function mapFromISOControlTransMappingItem(
  isoControlTransMappingDTO: ISOControlTransMappingItemDTO,
): ISOControlTransMappingItem {
  const output = new ISOControlTransMappingItem();

  output.sourceCode = isoControlTransMappingDTO.sourceCode;
  output.sourceName = isoControlTransMappingDTO.sourceName;
  output.sourceId = isoControlTransMappingDTO.sourceId;
  output.targetCode = isoControlTransMappingDTO.targetCode;
  output.targetName = isoControlTransMappingDTO.targetName;
  output.targetId = isoControlTransMappingDTO.targetId;

  return output;
}

export function mapFromISOControlISOControl(isoControlISOControlDto: ISOControlISOControlDTO): ISOControlISOControl {
  const newISOControlISOControl = new ISOControlISOControl();

  newISOControlISOControl.isoControlIdFrom = isoControlISOControlDto.isoControlIdFrom;
  newISOControlISOControl.isoControlIdTo = isoControlISOControlDto.isoControlIdTo;
  newISOControlISOControl.status = isoControlISOControlDto.status;

  return newISOControlISOControl;
}
