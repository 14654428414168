export class ProcessControlDTO {
  processId: number;

  controlId: number;

  constructor(processId?: number, controlId?: number) {
    this.processId = processId ?? -1;
    this.controlId = controlId ?? -1;
  }
}
