import TimeZoneDTO from 'models/dto/timeZoneDTO';
import TimeZone from 'models/timeZone';

export function mapFromTimeZones(items: TimeZoneDTO[]): TimeZone[] {
  return items.map((i) => mapFromTimeZone(i));
}

export function mapFromTimeZone(item: TimeZoneDTO): TimeZone {
  const newItem = new TimeZone();
  newItem.timeZoneId = item.timeZoneId;
  newItem.timeZoneCode = item.timeZoneCode;
  newItem.timeZoneName = item.timeZoneName;

  return newItem;
}
