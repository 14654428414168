import http from './httpService';
import AppError from 'utils/appError';
import { mapFromAdminTenant, mapFromAdminTenants, mapToAdminTenant } from 'mappings/adminTenantMapping';
import AdminTenant from 'models/adminTenant';
import AdminTenantDTO from 'models/dto/adminTenantDTO';
import { mapFromUser, mapFromUsers, mapToUser } from 'mappings/userMapping';
import IdListDTO from 'models/dto/IdListDTO';
import UserDTO from 'models/dto/userDTO';
import { appRoles } from 'services/Auth/appRoles';
import User from 'models/user';
import { UserSystemRoleDTO } from 'models/dto/auth/userRoleDTO';
import { RoleDTO } from 'models/dto/auth/roleDTO';
import { mapFromRoles } from 'mappings/authMapping';
import { Role } from 'models/auth/role';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';

export async function apiGetTenants(accessToken: string): Promise<AdminTenant[]> {
  try {
    const ar = await http.get<AdminTenantDTO[]>(`tenants`, http.getRequestConfig(accessToken));

    return mapFromAdminTenants(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTenant(tenant: AdminTenant, accessToken: string): Promise<AdminTenant> {
  try {
    const tenantDto = mapToAdminTenant(tenant);
    const ar = await http.put<AdminTenantDTO>(`tenants`, tenantDto, http.getRequestConfig(accessToken));

    return mapFromAdminTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTenant(tenant: AdminTenant, accessToken: string): Promise<AdminTenant> {
  try {
    const tenantDto = mapToAdminTenant(tenant);
    const ar = await http.post<AdminTenantDTO>(`tenants`, tenantDto, http.getRequestConfig(accessToken));

    return mapFromAdminTenant(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopyTenantToTest(tenant: AdminTenant, accessToken: string): Promise<void> {
  try {
    await http.post<AdminTenantDTO>(`tenants/copytest/${tenant.tenantId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTenant(tenant: AdminTenant, mode: number, accessToken: string): Promise<void> {
  try {
    await http.post<AdminTenantDTO>(
      `tenants/delete/${tenant.tenantId}?mode=${mode}`,
      null,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetUsersForTenant(
  tenantId: string,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<User[]> {
  try {
    const ar = await http.get<UserDTO[]>(`/users/tenant/${tenantId}`, http.getRequestConfig(accessToken));

    return mapFromUsers(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRolesForTenant(tenantId: string, accessToken: string): Promise<Role[]> {
  try {
    const ar = await http.get<RoleDTO[]>(`/auth/roles/tenant/${tenantId}`, http.getRequestConfig(accessToken));

    return mapFromRoles(ar.data, undefined);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddUserForTenant(
  tenantId: string,
  user: User,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<User | undefined> {
  try {
    const userDTO = mapToUser(user);
    const ar = await http.post<UserDTO>(`/users/tenant/${tenantId}`, userDTO, http.getRequestConfig(accessToken));

    return mapFromUser(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetUserRoleForTenant(
  tenantId: string,
  userId: string,
  roleId: appRoles,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const userRoleDTO = new UserSystemRoleDTO();
    userRoleDTO.userId = userId;
    userRoleDTO.roleId = roleId;
    await http.post<IdListDTO>(
      `/auth/tenant/${tenantId}/roles/users?add=${add}`,
      userRoleDTO,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
