import http from './httpService';
import AuditTrail from 'models/auditTrail';
import AuditTrailDTO from 'models/dto/auditTrailDTO';
import AuditTrailAction from 'models/auditTrailAction';
import AuditTrailActionDTO from 'models/dto/auditTrailActionDTO';
import { mapFromAuditTrail, mapFromAuditTrailActions } from 'mappings/dataMapping';
import AppError from 'utils/appError';
import { toApiDateTimeRequired } from 'utils/datetime';

export async function apiGetAudits(
  auditTrailId: number,
  user: string | undefined,
  action: number | undefined,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
): Promise<AuditTrail> {
  try {
    const url = `/audits/${auditTrailId}`;
    let params = '?';

    if (user) {
      params += `user=${user}`;
    }
    if (action) {
      if (params.length > 1) params += '&';
      params += `action=${action}`;
    }
    if (start) {
      if (params.length > 1) params += '&';
      params += `start=${toApiDateTimeRequired(start)}`;
    }
    if (end) {
      if (params.length > 1) params += '&';
      params += `end=${toApiDateTimeRequired(end)}`;
    }
    if (params.length === 1) params = '';

    const ar = await http.get<AuditTrailDTO>(url + params, http.getRequestConfig(accessToken));

    return mapFromAuditTrail(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAuditActions(accessToken: string): Promise<AuditTrailAction[]> {
  try {
    const ar = await http.get<AuditTrailActionDTO[]>(`/audits/actions`, http.getRequestConfig(accessToken));

    return mapFromAuditTrailActions(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
