import {
  IconButton,
  IModalStyleProps,
  IModalStyles,
  IStackStyles,
  IStyleFunctionOrObject,
  ITheme,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import Task from 'models/tasks/task';
import { TaskContext } from '../TaskContext';
import { TFunction } from 'i18next';
import { LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { IAppContext } from 'App/AppContext';

export const onRenderTaskContext = (item: Task): JSX.Element => {
  return (
    <Stack verticalFill styles={{ root: { width: 400, height: 300, padding: 20 } }}>
      <TaskContext task={item} allowEdit={false} hideHeaders={true} navigateInPlace={true} />
    </Stack>
  );
};

export const getTaskViewModalStyles = (
  windowLevel: number | undefined,
): IStyleFunctionOrObject<IModalStyleProps, IModalStyles> | undefined => {
  const offset = windowLevel ? windowLevel * 20 : undefined;

  if (offset) {
    return {
      main: {
        left: offset,
        top: offset,
      },
    };
  } else {
    return undefined;
  }
};

export const getTaskViewModalStackStylesFormMode = (
  theme: ITheme,
  isMobile: boolean,
  useDarkMode: boolean,
): IStackStyles | undefined => {
  return {
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: isMobile ? '92vh' : '80vh',
      minHeight: 516,
      width: '92vw',
      minWidth: isMobile ? '92vw' : 800,
      maxWidth: 1400,
      padding: '20px',
      background: useDarkMode ? theme.palette.neutralDark : theme.palette.white,
      zIndex: 999999999,
    },
  };
};

export const getTaskViewModalStackStyles = (
  isMobile: boolean,
  isCompact: boolean | undefined,
): IStackStyles | undefined => {
  return {
    root: {
      height: '92vh',
      width: isCompact ? '46vw' : '92vw',
      minWidth: isMobile ? '92vw' : isCompact ? 400 : 800,
      maxWidth: 1400,
      padding: '20px',
      transition: 'width 0.6s',
    },
  };
};

export const getCompactViewSwitch = (
  task: Task,
  isMobile: boolean,
  isCompact: boolean | undefined,
  t: TFunction<string[]>,
  setCompactView: (newValue: boolean) => void,
  appContext: IAppContext | undefined,
): JSX.Element | null => {
  if (isMobile || task.taskId === -1) return null;

  return (
    <Stack.Item>
      <TooltipHost content={isCompact ? t('task:CompactView.Expand') : t('task:CompactView.Collapse')}>
        <IconButton
          iconProps={{ iconName: isCompact ? 'DoubleChevronLeft8' : 'DoubleChevronRight8' }}
          onClick={() => {
            const newCompact = !isCompact;
            setCompactView(newCompact);
            setLocalStorageData(appContext, LocalStorageKeys.TaskCompactView, newCompact ? '1' : '0');
          }}
          styles={{ root: { height: 45 } }}
        ></IconButton>
      </TooltipHost>
    </Stack.Item>
  );
};
