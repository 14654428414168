import { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { FeatureTypes, hasUserFeature, hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import AppContext from 'App/AppContext';
import { globalNotAuthorizedURL } from 'globalConstants';

interface ProtectedRouteProps extends RouteProps {
  featureType: FeatureTypes;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  //There is no user or login information
  const user = appContext.user;
  if (!user || !user.login) {
    history.replace(globalNotAuthorizedURL);
  }

  //Determine a special case for when the trial period / subscription has ended
  //-> allow users with a role to access routes
  const tenantIsRegisteredAndUserHasRole =
    user.login.tenantId &&
    user.login.tenantLicensed === false &&
    user.login.userLicensed === false &&
    hasUserFeatureGenericManager(appContext);

  //No licensed user
  if (!user.login.userLicensed && !tenantIsRegisteredAndUserHasRole) {
    history.replace(globalNotAuthorizedURL);
  }

  //No valid feature for user
  if (!hasUserFeature(appContext, props.featureType)) {
    history.replace(globalNotAuthorizedURL);
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
