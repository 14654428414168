import { useContext, useEffect, useState } from 'react';
import {
  Callout,
  Checkbox,
  CommandBarButton,
  DirectionalHint,
  IconButton,
  Label,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Entity, { EntityTypes } from 'models/entity';
import AppContext from 'App/AppContext';
import {
  KPIContextFilter,
  KPIContextFilterGroup,
  KPIContextFilterGroupOperator,
  KPIContextFilterItem,
} from 'models/kpi/kpiDataContext';
import KPI, { KPITypesSystem } from 'models/kpi/kpi';
import AppError from 'utils/appError';
import Accordion from 'components/Accordion/Accordion';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import {
  filterICon,
  cancelIcon,
  globalStackStylesHeight100PaddingSmall,
  globalStackTokensGapSmall,
  globalStackTokensGapExtraSmall,
  globalSceneBarItemStyles,
} from 'globalStyles';
import Tag from 'models/tag';
import User from 'models/user';
import MultiSelectUserPicker from 'components/Pickers/MultiSelectUserPicker';
import EntityTagPicker from 'components/Pickers/EntityTagPicker';
import Process from 'models/process/process';
import { apiGetProcesses } from 'services/Api/processService';
import { apiRequest } from 'services/Auth/authConfig';
import { getEntity } from 'globalFunctions';
import Objective from 'models/objective/objective';
import { apiGetObjectives } from 'services/Api/objectiveService';
import Theme from 'models/theme';
import Risk from 'models/risk';
import Control from 'models/control';
import Asset from 'models/asset/asset';
import { apiGetThemes } from 'services/Api/themeService';
import { apiGetControls } from 'services/Api/controlService';
import { apiGetAssets } from 'services/Api/assetService';
import { apiGetRisks } from 'services/Api/riskService';
import { TaskState } from 'models/tasks/taskHelperClasses';

interface IKPIDataContextFilterProps {
  kpi: KPI;
  filter: KPIContextFilter;
  disabled?: boolean;
  onUpdate: (filter: KPIContextFilter) => void;
}

enum KPIDataContextFilterGroup {
  Processes = 1,
  Objectives = 2,
  Requirements = 3,
  Assets = 4,
  Risks = 5,
  Controls = 6,
  Tags = 7,
  Tasks = 8,
}

const KPIDataContextFilter = (props: IKPIDataContextFilterProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'kpi', 'tasks']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [controls, setControls] = useState<Control[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [risks, setRisks] = useState<Risk[]>([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [items, setItems] = useState<KPIContextFilterItem[]>([]);
  const defSpecificItemTagColor = '#339e80';

  useEffect(() => {
    const filterItems = props.filter.getItems();

    for (let idx = 0; idx < filterItems.length; idx++) {
      const item = filterItems[idx];
      if (item.entityType === EntityTypes.Risk) {
        loadRisks();
      } else if (item.entityType === EntityTypes.Objective) {
        loadObjectives();
      } else if (item.entityType === EntityTypes.Process) {
        loadProcesses();
      } else if (item.entityType === EntityTypes.Asset) {
        loadAssets();
      } else if (item.entityType === EntityTypes.Control) {
        loadControls();
      } else if (item.entityType === EntityTypes.Requirement) {
        loadThemes();
      }
    }

    setItems(filterItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRisks = async (): Promise<Entity[]> => {
    try {
      setRisks([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const riskCol = await apiGetRisks(accessToken, appContext.globalDataCache);
      setRisks(riskCol.risks);

      return riskCol.risks.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadAssets = async (): Promise<Entity[]> => {
    try {
      setAssets([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const assets = await apiGetAssets(accessToken, appContext.globalDataCache);
      setAssets(assets);

      return assets.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadControls = async (): Promise<Entity[]> => {
    try {
      setControls([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const controls = await apiGetControls(accessToken, appContext.globalDataCache);
      setControls(controls);

      return controls.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadThemes = async (): Promise<Entity[]> => {
    try {
      setThemes([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const themes = await apiGetThemes(accessToken, appContext.globalDataCache);
      setThemes(themes);

      return themes.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadProcesses = async (): Promise<Entity[]> => {
    try {
      setProcesses([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const processes = await apiGetProcesses(accessToken, appContext.globalDataCache);
      setProcesses(processes);

      return processes.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadObjectives = async (): Promise<Entity[]> => {
    try {
      setObjectives([]);
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const objectives = await apiGetObjectives(accessToken, appContext.globalDataCache);
      setObjectives(objectives);

      return objectives.map((p) => getEntity(p));
    } catch (err) {
      appContext.setError(err);

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const isGroupEnabled = (group: KPIDataContextFilterGroup): boolean => {
    if (props.kpi.systemKPIType) {
      switch (props.kpi.systemKPIType) {
        case KPITypesSystem.Task:
          if (group === KPIDataContextFilterGroup.Assets || group === KPIDataContextFilterGroup.Tags) {
            return false;
          }
          break;
        default:
          throw new AppError('System filter type not implemented');
      }
    }

    return true;
  };

  const handleFilterClear = () => {
    setItems([]);
    props.onUpdate(new KPIContextFilter());
    setIsOpen(false);
  };

  const getSelectedFilterCount = (type: EntityTypes): number => {
    switch (type) {
      case EntityTypes.Asset:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Asset ||
            i.entityType === EntityTypes.AssetTag ||
            i.entityType === EntityTypes.AssetUser,
        ).length;
      case EntityTypes.Risk:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Risk ||
            i.entityType === EntityTypes.RiskTag ||
            i.entityType === EntityTypes.RiskUser,
        ).length;
      case EntityTypes.Control:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Control ||
            i.entityType === EntityTypes.ControlTag ||
            i.entityType === EntityTypes.ControlUser,
        ).length;
      case EntityTypes.Requirement:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Requirement ||
            i.entityType === EntityTypes.ThemeTag ||
            i.entityType === EntityTypes.ThemeUser,
        ).length;
      case EntityTypes.Task:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.TaskTag ||
            i.entityType === EntityTypes.TaskUser ||
            i.entityType === EntityTypes.TaskStatus,
        ).length;
      case EntityTypes.Objective:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Objective ||
            i.entityType === EntityTypes.ObjectiveTag ||
            i.entityType === EntityTypes.ObjectiveUser,
        ).length;
      case EntityTypes.Process:
        return items.filter(
          (i) =>
            i.entityType === EntityTypes.Process ||
            i.entityType === EntityTypes.ProcessTag ||
            i.entityType === EntityTypes.ProcessUser,
        ).length;
      case EntityTypes.Tag:
        return items.filter((i) => i.entityType === EntityTypes.Tag).length;
      default:
        return 0;
    }
  };

  const addFilter = (newItem: KPIContextFilterItem, removeTypes?: EntityTypes[]) => {
    let newItems = [...items, newItem];
    newItems = removeFilters(newItems, removeTypes);
    setItems(newItems);
    const filter = createFilter(newItems);
    props.onUpdate(filter);
  };

  const removeFilter = (item: KPIContextFilterItem) => {
    const newItems = items.filter(
      (i) => !(i.entityType === item.entityType && getFilterItemValue(i) === getFilterItemValue(item)),
    );
    setItems(newItems);
    const filter = createFilter(newItems);
    props.onUpdate(filter);
  };

  const removeFilters = (items: KPIContextFilterItem[], types?: EntityTypes[]): KPIContextFilterItem[] => {
    if (!types || types.length === 0) return items;

    return items.filter((i) => !types.some((t) => t === i.entityType));
  };

  const getFilterItemValue = (item: KPIContextFilterItem): number | string | Date | undefined => {
    switch (item.entityType) {
      case EntityTypes.AssetUser:
      case EntityTypes.RiskUser:
      case EntityTypes.ControlUser:
      case EntityTypes.ThemeUser:
      case EntityTypes.TaskUser:
      case EntityTypes.ObjectiveUser:
      case EntityTypes.ProcessUser:
        return item.entityGuid;
      default:
        return item.entityId;
    }
  };

  const getFilterItem = (type: EntityTypes, id: number | string | Date): KPIContextFilterItem => {
    const newItem = new KPIContextFilterItem();
    newItem.entityType = type;

    if (typeof id === 'number') {
      newItem.entityId = id as number;
    } else if (typeof id === 'string') {
      newItem.entityGuid = id as string;
    } else if (id instanceof Date) {
      newItem.entityDateTime = id as Date;
    } else {
      throw new AppError('Not implemented KPI context filter entity data type');
    }

    return newItem;
  };

  const createFilter = (items: KPIContextFilterItem[]): KPIContextFilter => {
    const filter = new KPIContextFilter();

    filter.group.operator = KPIContextFilterGroupOperator.OR;

    //process filters
    const processItem = new KPIContextFilterItem();
    processItem.group = new KPIContextFilterGroup();
    processItem.group.operator = KPIContextFilterGroupOperator.AND;

    const processFilters = items.filter((i) => i.entityType === EntityTypes.Process);
    if (processFilters.length > 0) {
      const processItems = new KPIContextFilterItem();
      processItems.group = new KPIContextFilterGroup();
      processItems.group.operator = KPIContextFilterGroupOperator.OR;
      processItems.group.items.push(...processFilters);
      processItem.group.items.push(processItems);
    }

    const processTagFilters = items.filter((i) => i.entityType === EntityTypes.ProcessTag);
    if (processTagFilters.length > 0) {
      const processTagItems = new KPIContextFilterItem();
      processTagItems.group = new KPIContextFilterGroup();
      processTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      processTagItems.group.items.push(...processTagFilters);
      processItem.group.items.push(processTagItems);
    }

    const processUserFilters = items.filter((i) => i.entityType === EntityTypes.ProcessUser);
    if (processUserFilters.length > 0) {
      const processUserItems = new KPIContextFilterItem();
      processUserItems.group = new KPIContextFilterGroup();
      processUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      processUserItems.group.items.push(...processUserFilters);
      processItem.group.items.push(processUserItems);
    }

    if (processItem.count() > 0) filter.group.items.push(processItem);

    //objective filters
    const objectiveItem = new KPIContextFilterItem();
    objectiveItem.group = new KPIContextFilterGroup();
    objectiveItem.group.operator = KPIContextFilterGroupOperator.AND;

    const objectiveFilters = items.filter((i) => i.entityType === EntityTypes.Objective);
    if (objectiveFilters.length > 0) {
      const objectiveItems = new KPIContextFilterItem();
      objectiveItems.group = new KPIContextFilterGroup();
      objectiveItems.group.operator = KPIContextFilterGroupOperator.OR;
      objectiveItems.group.items.push(...objectiveFilters);
      objectiveItem.group.items.push(objectiveItems);
    }

    const objectiveTagFilters = items.filter((i) => i.entityType === EntityTypes.ObjectiveTag);
    if (objectiveTagFilters.length > 0) {
      const objectiveTagItems = new KPIContextFilterItem();
      objectiveTagItems.group = new KPIContextFilterGroup();
      objectiveTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      objectiveTagItems.group.items.push(...objectiveTagFilters);
      objectiveItem.group.items.push(objectiveTagItems);
    }

    const objectiveUserFilters = items.filter((i) => i.entityType === EntityTypes.ObjectiveUser);
    if (objectiveUserFilters.length > 0) {
      const objectiveUserItems = new KPIContextFilterItem();
      objectiveUserItems.group = new KPIContextFilterGroup();
      objectiveUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      objectiveUserItems.group.items.push(...objectiveUserFilters);
      objectiveItem.group.items.push(objectiveUserItems);
    }

    if (objectiveItem.count() > 0) filter.group.items.push(objectiveItem);

    //theme filters
    const themeItem = new KPIContextFilterItem();
    themeItem.group = new KPIContextFilterGroup();
    themeItem.group.operator = KPIContextFilterGroupOperator.AND;

    const themeFilters = items.filter((i) => i.entityType === EntityTypes.Requirement);
    if (themeFilters.length > 0) {
      const themeItems = new KPIContextFilterItem();
      themeItems.group = new KPIContextFilterGroup();
      themeItems.group.operator = KPIContextFilterGroupOperator.OR;
      themeItems.group.items.push(...themeFilters);
      themeItem.group.items.push(themeItems);
    }

    const themeTagFilters = items.filter((i) => i.entityType === EntityTypes.ThemeTag);
    if (themeTagFilters.length > 0) {
      const themeTagItems = new KPIContextFilterItem();
      themeTagItems.group = new KPIContextFilterGroup();
      themeTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      themeTagItems.group.items.push(...themeTagFilters);
      themeItem.group.items.push(themeTagItems);
    }

    const themeUserFilters = items.filter((i) => i.entityType === EntityTypes.ThemeUser);
    if (themeUserFilters.length > 0) {
      const themeUserItems = new KPIContextFilterItem();
      themeUserItems.group = new KPIContextFilterGroup();
      themeUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      themeUserItems.group.items.push(...themeUserFilters);
      themeItem.group.items.push(themeUserItems);
    }

    if (themeItem.count() > 0) filter.group.items.push(themeItem);

    //control filters
    const controlItem = new KPIContextFilterItem();
    controlItem.group = new KPIContextFilterGroup();
    controlItem.group.operator = KPIContextFilterGroupOperator.AND;

    const controlFilters = items.filter((i) => i.entityType === EntityTypes.Control);
    if (controlFilters.length > 0) {
      const controlItems = new KPIContextFilterItem();
      controlItems.group = new KPIContextFilterGroup();
      controlItems.group.operator = KPIContextFilterGroupOperator.OR;
      controlItems.group.items.push(...controlFilters);
      controlItem.group.items.push(controlItems);
    }

    const controlTagFilters = items.filter((i) => i.entityType === EntityTypes.ControlTag);
    if (controlTagFilters.length > 0) {
      const controlTagItems = new KPIContextFilterItem();
      controlTagItems.group = new KPIContextFilterGroup();
      controlTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      controlTagItems.group.items.push(...controlTagFilters);
      controlItem.group.items.push(controlTagItems);
    }

    const controlUserFilters = items.filter((i) => i.entityType === EntityTypes.ControlUser);
    if (controlUserFilters.length > 0) {
      const controlUserItems = new KPIContextFilterItem();
      controlUserItems.group = new KPIContextFilterGroup();
      controlUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      controlUserItems.group.items.push(...controlUserFilters);
      controlItem.group.items.push(controlUserItems);
    }

    if (controlItem.count() > 0) filter.group.items.push(controlItem);

    //asset filters
    const assetItem = new KPIContextFilterItem();
    assetItem.group = new KPIContextFilterGroup();
    assetItem.group.operator = KPIContextFilterGroupOperator.AND;

    const assetFilters = items.filter((i) => i.entityType === EntityTypes.Asset);
    if (assetFilters.length > 0) {
      const assetItems = new KPIContextFilterItem();
      assetItems.group = new KPIContextFilterGroup();
      assetItems.group.operator = KPIContextFilterGroupOperator.OR;
      assetItems.group.items.push(...assetFilters);
      assetItem.group.items.push(assetItems);
    }

    const assetTagFilters = items.filter((i) => i.entityType === EntityTypes.AssetTag);
    if (assetTagFilters.length > 0) {
      const assetTagItems = new KPIContextFilterItem();
      assetTagItems.group = new KPIContextFilterGroup();
      assetTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      assetTagItems.group.items.push(...assetTagFilters);
      assetItem.group.items.push(assetTagItems);
    }

    const assetUserFilters = items.filter((i) => i.entityType === EntityTypes.AssetUser);
    if (assetUserFilters.length > 0) {
      const assetUserItems = new KPIContextFilterItem();
      assetUserItems.group = new KPIContextFilterGroup();
      assetUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      assetUserItems.group.items.push(...assetUserFilters);
      assetItem.group.items.push(assetUserItems);
    }

    if (assetItem.count() > 0) filter.group.items.push(assetItem);

    //risk filters
    const riskItem = new KPIContextFilterItem();
    riskItem.group = new KPIContextFilterGroup();
    riskItem.group.operator = KPIContextFilterGroupOperator.AND;

    const riskFilters = items.filter((i) => i.entityType === EntityTypes.Risk);
    if (riskFilters.length > 0) {
      const riskItems = new KPIContextFilterItem();
      riskItems.group = new KPIContextFilterGroup();
      riskItems.group.operator = KPIContextFilterGroupOperator.OR;
      riskItems.group.items.push(...riskFilters);
      riskItem.group.items.push(riskItems);
    }

    const riskTagFilters = items.filter((i) => i.entityType === EntityTypes.RiskTag);
    if (riskTagFilters.length > 0) {
      const riskTagItems = new KPIContextFilterItem();
      riskTagItems.group = new KPIContextFilterGroup();
      riskTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      riskTagItems.group.items.push(...riskTagFilters);
      riskItem.group.items.push(riskTagItems);
    }

    const riskUserFilters = items.filter((i) => i.entityType === EntityTypes.RiskUser);
    if (riskUserFilters.length > 0) {
      const riskUserItems = new KPIContextFilterItem();
      riskUserItems.group = new KPIContextFilterGroup();
      riskUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      riskUserItems.group.items.push(...riskUserFilters);
      riskItem.group.items.push(riskUserItems);
    }

    if (riskItem.count() > 0) filter.group.items.push(riskItem);

    //task filters
    const taskItem = new KPIContextFilterItem();
    taskItem.group = new KPIContextFilterGroup();
    taskItem.group.operator = KPIContextFilterGroupOperator.AND;

    const taskStatusFilters = items.filter((i) => i.entityType === EntityTypes.TaskStatus);
    if (taskStatusFilters.length > 0) {
      const taskItems = new KPIContextFilterItem();
      taskItems.group = new KPIContextFilterGroup();
      taskItems.group.operator = KPIContextFilterGroupOperator.OR;
      taskItems.group.items.push(...taskStatusFilters);
      taskItem.group.items.push(taskItems);
    }

    const taskTagFilters = items.filter((i) => i.entityType === EntityTypes.TaskTag);
    if (taskTagFilters.length > 0) {
      const taskTagItems = new KPIContextFilterItem();
      taskTagItems.group = new KPIContextFilterGroup();
      taskTagItems.group.operator = KPIContextFilterGroupOperator.OR;
      taskTagItems.group.items.push(...taskTagFilters);
      taskItem.group.items.push(taskTagItems);
    }

    const taskUserFilters = items.filter((i) => i.entityType === EntityTypes.TaskUser);
    if (taskUserFilters.length > 0) {
      const taskUserItems = new KPIContextFilterItem();
      taskUserItems.group = new KPIContextFilterGroup();
      taskUserItems.group.operator = KPIContextFilterGroupOperator.OR;
      taskUserItems.group.items.push(...taskUserFilters);
      taskItem.group.items.push(taskUserItems);
    }

    if (taskItem.count() > 0) filter.group.items.push(taskItem);

    //tag filters
    const tagFilters = items.filter((i) => i.entityType === EntityTypes.Tag);
    if (tagFilters.length > 0) {
      const tagTagItem = new KPIContextFilterItem();
      tagTagItem.group = new KPIContextFilterGroup();
      tagTagItem.group.operator = KPIContextFilterGroupOperator.OR;
      tagTagItem.group.items.push(...tagFilters);
      filter.group.items.push(tagTagItem);
    }

    return filter;
  };

  const getNameOfEntity = (item: KPIContextFilterItem): string | undefined => {
    switch (item.entityType) {
      case EntityTypes.Control:
        return controls.find((p) => p.controlId === item.entityId)?.name;
      case EntityTypes.Risk:
        return risks.find((p) => p.riskId === item.entityId)?.name;
      case EntityTypes.Asset:
        return assets.find((p) => p.assetId === item.entityId)?.name;
      case EntityTypes.Requirement:
        return themes.find((p) => p.themeId === item.entityId)?.name;
      case EntityTypes.Objective:
        return objectives.find((p) => p.objectiveId === item.entityId)?.name;
      case EntityTypes.Process:
        return processes.find((p) => p.processId === item.entityId)?.name;
      default:
        return undefined;
    }
  };

  const getEntitiesFromFilter = (type: EntityTypes): Entity[] => {
    const filterItems = items.filter((i) => i.entityType === type);

    return filterItems.map((f) => new Entity(f.entityId, f.entityType, getNameOfEntity(f)));
  };

  const getUsersFromFilter = (type: EntityTypes): User[] => {
    const filterItems = items.filter((i) => i.entityType === type);

    return appContext.globalDataCache.users.getItemsForId(filterItems.map((f) => f.entityGuid ?? ''));
  };

  const getTagsFromFilter = (type: EntityTypes): Tag[] => {
    const filterItems = items.filter((i) => i.entityType === type);

    return appContext.globalDataCache.tags.getItemsForId(filterItems.map((f) => f.entityId ?? 0));
  };

  const getTaskStatusFromFilter = (status: number): boolean => {
    return items.find((i) => i.entityType === EntityTypes.TaskStatus && i.entityId === status) !== undefined;
  };

  const addEntityToFilter = (item: Entity, removeTypes?: EntityTypes[]) => {
    const newItem = new KPIContextFilterItem();
    newItem.entityType = item.typeOfEntity;
    newItem.entityId = item.entityId;
    addFilter(newItem, removeTypes);
  };

  const removeEntityFromFilter = (item: Entity) => {
    const newItems = items.filter(
      (i) => !(i.entityType === item.typeOfEntity && getFilterItemValue(i) === item.entityId),
    );
    setItems(newItems);
    const filter = createFilter(newItems);
    props.onUpdate(filter);
  };

  //
  // Main render
  //

  return (
    <Stack horizontal verticalAlign="center">
      <CommandBarButton
        iconProps={filterICon}
        disabled={props.disabled}
        className="calloutTargetFilterButton"
        text={t('kpi:ContextFilter.Button', { count: props.filter.count() })}
        onClick={() => setIsOpen(!isOpen)}
        styles={globalSceneBarItemStyles}
      />
      {props.filter.count() > 0 && (
        <IconButton styles={globalSceneBarItemStyles} onClick={() => handleFilterClear()} iconProps={cancelIcon} />
      )}
      <Callout
        alignTargetEdge={true}
        gapSpace={0}
        target=".calloutTargetFilterButton"
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomLeftEdge}
        hidden={!isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <Stack styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Stack horizontal horizontalAlign={'space-between'} styles={globalStackStylesHeight100PaddingSmall}>
              <Text variant={'mediumPlus'}>{t('kpi:ContextFilter.Header')}</Text>
              <Link onClick={() => handleFilterClear()} underline>
                {t('kpi:ContextFilter.Clear')}
              </Link>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { height: 500, width: 300, position: 'relative' } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {isGroupEnabled(KPIDataContextFilterGroup.Processes) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Processes.Label', {
                    count: getSelectedFilterCount(EntityTypes.Process),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Processes.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Process}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={processes.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Process)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.ProcessTag, EntityTypes.ProcessUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadProcesses}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Processes.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Processes.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.ProcessTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.ProcessTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.ProcessTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Processes.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.ProcessUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.ProcessUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.ProcessUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.ProcessUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Objectives) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Objectives.Label', {
                    count: getSelectedFilterCount(EntityTypes.Objective),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Objectives.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Objective}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={objectives.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Objective)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.ObjectiveTag, EntityTypes.ObjectiveUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadObjectives}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Objectives.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Objective).length > 0}>
                        {t('kpi:ContextFilter.Groups.Objectives.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.ObjectiveTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.ObjectiveTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.ObjectiveTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Objective).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Objectives.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.ObjectiveUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.ObjectiveUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.ObjectiveUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.ObjectiveUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Objective).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Requirements) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Themes.Label', {
                    count: getSelectedFilterCount(EntityTypes.Requirement),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Themes.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Requirement}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={themes.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Requirement)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.ThemeTag, EntityTypes.ThemeUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadThemes}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Themes.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Requirement).length > 0}>
                        {t('kpi:ContextFilter.Groups.Themes.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.ThemeTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.ThemeTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.ThemeTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Requirement).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Themes.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.ThemeUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.ThemeUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.ThemeUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.ThemeUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Requirement).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Assets) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Assets.Label', {
                    count: getSelectedFilterCount(EntityTypes.Asset),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Assets.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Asset}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={assets.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Asset)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.AssetTag, EntityTypes.AssetUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadAssets}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Assets.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Asset).length > 0}>
                        {t('kpi:ContextFilter.Groups.Assets.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.AssetTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.AssetTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.AssetTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Asset).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Assets.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.AssetUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.AssetUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.AssetUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.AssetUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Asset).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Risks) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Risks.Label', {
                    count: getSelectedFilterCount(EntityTypes.Risk),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Risks.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Risk}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={risks.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Risk)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.RiskTag, EntityTypes.RiskUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadRisks}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Risks.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Risk).length > 0}>
                        {t('kpi:ContextFilter.Groups.Risks.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.RiskTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.RiskTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.RiskTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Risk).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Risks.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.RiskUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.RiskUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.RiskUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.RiskUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Risk).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Controls) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Controls.Label', {
                    count: getSelectedFilterCount(EntityTypes.Control),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Controls.Filters.Item')}</Label>
                      <EntityTagPicker
                        entityType={EntityTypes.Control}
                        tagColor={defSpecificItemTagColor}
                        suggestionLimit={undefined}
                        entities={controls.map((p) => getEntity(p))}
                        selectedEntities={getEntitiesFromFilter(EntityTypes.Control)}
                        onAddEntity={(item) => {
                          addEntityToFilter(item, [EntityTypes.ControlTag, EntityTypes.ControlUser]);
                        }}
                        onRemoveEntity={(item) => {
                          removeEntityFromFilter(item);
                        }}
                        loadData={loadControls}
                        isLoading={isLoading}
                        placeholder={t('kpi:ContextFilter.Groups.Controls.Filters.ItemPlaceholder')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Control).length > 0}>
                        {t('kpi:ContextFilter.Groups.Controls.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.ControlTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.ControlTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.ControlTag, item.tagId))}
                        disabled={getEntitiesFromFilter(EntityTypes.Control).length > 0}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Controls.Filters.Owner')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.ControlUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.ControlUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.ControlUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.ControlUser, u.id));
                            }
                          });
                        }}
                        disabled={getEntitiesFromFilter(EntityTypes.Control).length > 0}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Tasks) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Tasks.Label', {
                    count: getSelectedFilterCount(EntityTypes.Task),
                  })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={{ root: { padding: 10, paddingBottom: 20 } }}>
                    <Stack.Item>
                      <Label>{t('kpi:ContextFilter.Groups.Tasks.Filters.Status')}</Label>
                      <Stack tokens={globalStackTokensGapExtraSmall}>
                        <Checkbox
                          key={'open'}
                          label={t('tasks:Filter.StatusOpen')}
                          checked={getTaskStatusFromFilter(-1)}
                          onChange={(ev, checked) => {
                            if (checked === true) {
                              addFilter(getFilterItem(EntityTypes.TaskStatus, -1));
                            } else {
                              removeFilter(getFilterItem(EntityTypes.TaskStatus, -1));
                            }
                          }}
                        />
                        <Separator styles={{ root: { height: 1 } }} />
                        {appContext.globalDataCache.taskStates.items.map((taskState: TaskState) => (
                          <Checkbox
                            key={taskState.taskStateId}
                            label={taskState.state}
                            checked={getTaskStatusFromFilter(taskState.taskStateId)}
                            onChange={(ev, checked) => {
                              if (checked === true) {
                                addFilter(getFilterItem(EntityTypes.TaskStatus, taskState.taskStateId));
                              } else {
                                removeFilter(getFilterItem(EntityTypes.TaskStatus, taskState.taskStateId));
                              }
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Task).length > 0}>
                        {t('kpi:ContextFilter.Groups.Tasks.Filters.Tags')}
                      </Label>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.TaskTag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.TaskTag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.TaskTag, item.tagId))}
                        disabled={false}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label disabled={getEntitiesFromFilter(EntityTypes.Process).length > 0}>
                        {t('kpi:ContextFilter.Groups.Tasks.Filters.Assignee')}
                      </Label>
                      <MultiSelectUserPicker
                        users={[]}
                        itemLimit={10}
                        selectedUsers={getUsersFromFilter(EntityTypes.TaskUser)}
                        onMultipleSelect={(items: User[] | undefined) => {
                          const currentUsers = getUsersFromFilter(EntityTypes.TaskUser);
                          //add items
                          items?.forEach((u) => {
                            const currentUser = appContext.globalDataCache.users.items.find((c) => c.id === u.id);
                            if (currentUser) {
                              addFilter(getFilterItem(EntityTypes.TaskUser, u.id));
                            }
                          });
                          //remove items
                          currentUsers?.forEach((u) => {
                            const currentUser = items?.find((c) => c.id === u.id);
                            if (!currentUser) {
                              removeFilter(getFilterItem(EntityTypes.TaskUser, u.id));
                            }
                          });
                        }}
                        disabled={false}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
              {isGroupEnabled(KPIDataContextFilterGroup.Tags) && (
                <Accordion
                  title={t('kpi:ContextFilter.Groups.Tags.Label', { count: getSelectedFilterCount(EntityTypes.Tag) })}
                >
                  <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                    <Stack.Item>
                      <KeyValueTagPicker
                        maxTagWidth={250}
                        selectedTags={getTagsFromFilter(EntityTypes.Tag)}
                        onAdd={(item: Tag) => addFilter(getFilterItem(EntityTypes.Tag, item.tagId))}
                        onRemove={(item: Tag) => removeFilter(getFilterItem(EntityTypes.Tag, item.tagId))}
                        disabled={false}
                      />
                    </Stack.Item>
                  </Stack>
                </Accordion>
              )}
            </ScrollablePane>
          </Stack.Item>
        </Stack>
      </Callout>
    </Stack>
  );
};

export default KPIDataContextFilter;
