import { mergeStyleSets } from '@fluentui/react';
import { IStackItemStyles } from '@fluentui/react';
import { globalNavBarHeight } from 'globalConstants';

export const navBarItemStyles: IStackItemStyles = mergeStyleSets({
  root: {
    height: globalNavBarHeight,
    width: globalNavBarHeight,
    justifyContent: 'center',
  },
});
