import React, { useContext, useEffect, useState } from 'react';
import {
  IColumn,
  SearchBox,
  SelectionMode,
  Stack,
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  Checkbox,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalSearchBoxStyles,
  globalStackItemStylesPaddingSceneScroll,
  globalStackTokensGapSmall,
} from 'globalStyles';
import { globalFilterDelay } from 'globalConstants';
import AppContext from 'App/AppContext';
import { AuthSchema } from 'models/auth/authSchema';
import BaseTableList from 'components/TableList/BaseTableList';
import { LocalStorageKeys } from 'utils/localstorage';
import { getRandomId } from 'utils/string';
import { sortOnBoolean, sortOnString } from 'utils/sorting';

interface IAuthSchemaListProps {
  authSchemas: AuthSchema[];
  isLoading?: boolean;
  showSearch?: boolean;
  onSelectAuthSchema: (item: AuthSchema | undefined) => void;
  onChooseAuthSchema: (item: AuthSchema | undefined) => void;
}

const AuthSchemaList = (props: IAuthSchemaListProps) => {
  const { t } = useTranslation(['translation', 'adminAuth']);
  const appContext = useContext(AppContext);
  const [authSchemasDisplay, setAuthSchemasDisplay] = useState<AuthSchema[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [selectionResetKey] = useState<string>(getRandomId());
  //
  // Effects
  //
  useEffect(() => {
    setAuthSchemasDisplay(filterActive(props.authSchemas));

    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      setAuthSchemasDisplay(filterActive(props.authSchemas));
    } else {
      const queryLower = searchQuery.toLowerCase();
      const filtered = props.authSchemas.filter((entry) => {
        return Object.values(entry).some((val) => {
          if (typeof val === 'string') {
            if (entry.name && entry.name.toLowerCase().includes(queryLower)) {
              return true;
            }
            if (entry.description && entry.description.toLowerCase().includes(queryLower)) {
              return true;
            }
          }

          return false;
        });
      });

      if (filtered) {
        setAuthSchemasDisplay(filterActive(filtered));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authSchemas, searchQuery]);

  //
  // Columns list view
  //
  const getColumns = (): IColumn[] => {
    const allColumns: IColumn[] = [
      {
        key: 'name',
        name: t('adminAuth:AuthSchemaPicker.Columns.Name'),
        minWidth: 150,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: 'description',
        name: t('adminAuth:AuthSchemaPicker.Columns.Description'),
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isMultiline: true,
        isCollapsible: true,
      },
    ];

    if (appContext.user.isAdmin()) {
      allColumns.push({
        key: 'active',
        name: t('adminAuth:AuthSchemaPicker.Columns.Active'),
        minWidth: 80,
        maxWidth: 80,
        isMultiline: true,
        isCollapsible: true,
      });
    }

    return allColumns;
  };

  const [columnsList, setColumnsList] = useState<IColumn[]>(getColumns());

  const onRenderCol = (item: AuthSchema, column: IColumn): JSX.Element | null => {
    if (!item) return null;

    switch (column.key) {
      case 'name':
        return <Text>{item.name}</Text>;
      case 'description':
        return <Text>{item.description}</Text>;
      case 'active':
        return <Checkbox checked={item.active}></Checkbox>;
      default:
        return null;
    }
  };

  const onCompare = (a: AuthSchema, b: AuthSchema, columnKey: string): number => {
    switch (columnKey) {
      case 'name':
        return sortOnString(a.name, b.name);
      case 'description':
        return sortOnString(a.description, b.description);
      case 'active':
        return sortOnBoolean(a.active, b.active);
      default:
        return 0;
    }
  };

  const onChangeText = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string): void => {
    if (filterText) {
      setSearchQuery(filterText.toLowerCase());
    } else {
      setSearchQuery('');
    }
  };

  const filterActive = (schemas: AuthSchema[]): AuthSchema[] => {
    if (appContext.user.isAdmin()) {
      return schemas;
    } else {
      return schemas.filter((a) => a.active);
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {props.showSearch && (
        <Stack.Item>
          <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <SearchBox
                autoFocus={!appContext.isMobileView}
                underlined
                placeholder={t('translation:General.Filter.Placeholder')}
                id="search"
                onChange={(ev, newValue) => {
                  if (searchTimer) {
                    clearTimeout(searchTimer);
                  }
                  setSearchTimer(setTimeout(() => onChangeText(ev, newValue), globalFilterDelay));
                }}
                styles={globalSearchBoxStyles}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <BaseTableList
            compact
            isLoading={props.isLoading}
            items={authSchemasDisplay}
            columns={columnsList}
            selectionMode={SelectionMode.single}
            onRenderCol={onRenderCol}
            compare={onCompare}
            setColumns={setColumnsList}
            cacheKeySort={LocalStorageKeys.AuthSchemaListSort}
            updateSelection={(items) => {
              props.onSelectAuthSchema(items.length > 0 ? items[0] : undefined);
            }}
            selectionResetKey={selectionResetKey}
            onRowDbClick={(item) => props.onChooseAuthSchema(item)}
          />
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default AuthSchemaList;
