import ActivityDTO from 'models/dto/activityDTO';
import { EntityTypes } from 'models/entity';
import { CommentTrailDTO } from 'models/dto/commentsDTO';
import AuditTrailEntryDTO from './auditTrailEntryDTO';

export default class ActivityTrailDTO {
  typeOfEntity: EntityTypes;

  entityId: number;

  commentTrailId: number;

  auditTrailId: number;

  activityCount?: number;

  activities: ActivityDTO[];

  commentTrail: CommentTrailDTO;

  auditTrailEntries: AuditTrailEntryDTO[];

  constructor() {
    this.entityId = 0;
    this.typeOfEntity = EntityTypes.NotSet;
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.activityCount = 5;
    this.activities = [];
    this.commentTrail = new CommentTrailDTO();
    this.auditTrailEntries = [];
  }
}
