import IdListDTO from '../IdListDTO';
import AuditTrailDTO from '../auditTrailDTO';
import KPIDataDTO from '../kpi/kpiDataDTO';
import LanguageDTO from '../languageDTO';
import { TaskTaskDTO } from './taskTaskDTO';

export class TaskStateDTO {
  taskStateId: number;

  completed: boolean;

  sortOrder: number;

  taskCount?: number;

  trans: TaskState_TranslationDTO[];

  constructor() {
    this.taskStateId = 0;
    this.completed = false;
    this.sortOrder = 0;
    this.trans = [];
  }
}

export class TaskState_TranslationDTO {
  state: string;

  lang?: LanguageDTO;

  constructor() {
    this.state = '';
  }
}

export class ControlTaskDTO {
  controlId: number;

  taskId: number;

  constructor() {
    this.controlId = 0;
    this.taskId = 0;
  }
}

export default class TaskDTO {
  taskId: number;

  taskMasterId: number | undefined;

  authSchemaId?: number;

  name: string;

  description?: string;

  taskStateId: number;

  taskType: number;

  taskTypeId?: number;

  sortOrder: number;

  creatorId: string;

  created: string;

  completed?: string;

  userId?: string;

  ownerId: string;

  commentTrailId: number;

  auditTrailId: number;

  duration: number;

  startDateTime: string;

  endDateTime: string;

  checkList?: string;

  eventId?: string;

  followUp: boolean;

  recurringPattern?: string;

  recurringPatternSummary?: string;

  deadline?: string;

  controlIds?: IdListDTO;

  themeIds?: IdListDTO;

  processIds?: IdListDTO;

  objectiveIds?: IdListDTO;

  assetIds?: IdListDTO;

  riskIds?: IdListDTO;

  normIds?: IdListDTO;

  instances?: TaskDTO[];

  instancesEnabled?: boolean;

  approved?: boolean | undefined;

  hidden?: boolean;

  webhookStatus?: number;

  templateId?: number;

  relations?: TaskTaskDTO[];

  tagIds?: IdListDTO;

  resourceLinkIds?: IdListDTO;

  auditTrail?: AuditTrailDTO;

  kpiData?: KPIDataDTO[];

  constructor() {
    this.taskId = 0;
    this.taskMasterId = 0;
    this.name = '';
    this.taskStateId = 0;
    this.sortOrder = 0;
    this.creatorId = '';
    this.ownerId = '';
    this.created = '';
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.startDateTime = '';
    this.endDateTime = '';
    this.duration = 8;
    this.followUp = false;
    this.taskType = 0;
    this.taskTypeId = 0;
  }
}
