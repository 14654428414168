export default class WebhookDTO {
  webhookId: number;

  workflowName?: string;

  created: string;

  eventType: string;

  eventTypeId?: number;

  runCount: number;

  failedCount: number;

  lastRun?: string;

  active: boolean;

  error?: string;

  constructor() {
    this.webhookId = 0;
    this.eventType = '';
    this.runCount = 0;
    this.failedCount = 0;
    this.active = true;
    this.created = '';
  }
}

export class WebhookRunDTO {
  webhookId: number;

  webhookRunId: number;

  created: string;

  workflowId?: string;

  workflowRunId?: string;

  entityId?: number;

  webhook?: WebhookDTO;

  constructor() {
    this.webhookId = 0;
    this.webhookRunId = 0;
    this.created = '';
  }
}
