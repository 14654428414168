import React from 'react';
import { IVerticalBarChartDataPoint, VerticalBarChart } from '@fluentui/react-charting';

interface IUserTasksProps {
  userTasksData: IUserChart[];
  height?: number;
  width?: number;
  onClick?: (data: IUserChart) => void;
}

export interface IUserChart {
  userId: string;
  user: string;
  taskCount: number;
}

const UserTasksChart = (props: IUserTasksProps) => {
  const onClick = (data: IUserChart) => {
    if (props.onClick) {
      props.onClick(data);
    }
  };

  const getData = (): IVerticalBarChartDataPoint[] => {
    if (!props.userTasksData) return [];
    const data: IVerticalBarChartDataPoint[] = props.userTasksData.map((d) => {
      const item: IVerticalBarChartDataPoint = {
        x: d.user,
        y: d.taskCount,
        onClick: () => onClick(d),
      };

      return item;
    });

    return data;
  };

  return (
    <VerticalBarChart
      height={props.height}
      width={props.width}
      data={getData()}
      wrapXAxisLables={true}
      hideLegend={true}
    />
  );
};

export default UserTasksChart;
