import { useContext, useEffect, useState } from 'react';
import RichTextEditorMCE from './RichTextEditorMCE';
import RichTextReadOnly from './RichTextReadOnly';
import AppContext from 'App/AppContext';
import { getEntityUrl } from 'utils/url';
import Entity, { EntityTypes } from 'models/entity';
import { useHistory } from 'react-router-dom';
import { ControlTypes } from 'models/control';
import { apiGetControlForISOControl } from 'services/Api/isoControlService';
import { apiRequest } from 'services/Auth/authConfig';
import { useTranslation } from 'react-i18next';

export enum IRichTextEditorSpecialLinkType {
  None = 0,
  ISOControl = 1,
}

export interface IRichTextEditorProps {
  html?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  height?: string | number;
  maxHeight?: number | undefined;
  maxLength?: number | undefined;
  readOnly?: boolean;
  onBadgeClick?: (badgeText: string) => void;
  onSpecialLinkClick?: (type: IRichTextEditorSpecialLinkType, text: string, href: string, success: boolean) => void;
  onStartEdit?: () => void;
  unFocusKey?: string;
  directEdit?: boolean;
  hideBorder?: boolean;
  stripParagraph?: boolean;
  disallowFullscreen?: boolean;
  compactToolbar?: boolean;
  hideRemainingChars?: boolean;
}

const RichTextEditor = (props: IRichTextEditorProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setFocus(false);
  }, [props.unFocusKey]);

  const onFocus = () => {
    setFocus(true);
    if (props.onStartEdit) {
      props.onStartEdit();
    }
  };

  const onSpecialLinkClick = async (
    type: IRichTextEditorSpecialLinkType,
    text: string,
    href: string,
  ): Promise<void> => {
    let success: boolean = false;

    try {
      if (type === IRichTextEditorSpecialLinkType.ISOControl) {
        const isoControlIdStr = href.substring('isocontrol://'.length);
        if (isoControlIdStr) {
          const isoControlId = Number.parseInt(isoControlIdStr);
          if (!isNaN(isoControlId)) {
            const accessToken = await appContext.getAccessToken(apiRequest.scopes);
            const controlOrTheme = await apiGetControlForISOControl(
              isoControlId,
              accessToken,
              appContext.globalDataCache,
            );
            if (controlOrTheme) {
              const entity = new Entity(
                controlOrTheme.controlId,
                controlOrTheme.controlType === ControlTypes.Measure ? EntityTypes.Control : EntityTypes.Requirement,
                controlOrTheme.name,
                controlOrTheme.code,
              );
              const url = getEntityUrl(entity);
              if (url) {
                history.push(url);
                success = true;
              }
            }
          }
        }
      }
    } catch (err) {
      success = false;
    }

    if (props.onSpecialLinkClick) props.onSpecialLinkClick(type, text, href, success);
    if (!success) {
      window.alert(t('translation:General.Notifications.ISOLinkNotFound', { id: href?.substring(13) }));
    }
  };

  if (props.directEdit === true || (!props.readOnly && focus)) {
    return <RichTextEditorMCE {...props} onSpecialLinkClick={onSpecialLinkClick} />;
  } else {
    return (
      <RichTextReadOnly
        onClick={props.readOnly ? undefined : () => onFocus()}
        {...props}
        onSpecialLinkClick={onSpecialLinkClick}
      />
    );
  }
};

export default RichTextEditor;
