import { AuthSchemaLineDTO } from './authSchemaLineDTO';
import { AuthSchema_TranslationDTO } from './authSchema_TranslationDTO';

export class AuthSchemaDTO {
  authSchemaId: number;

  auditTrailId: number;

  active: boolean;

  trans: AuthSchema_TranslationDTO[];

  lines?: AuthSchemaLineDTO[];

  constructor() {
    this.authSchemaId = 0;
    this.auditTrailId = 0;
    this.trans = [];
    this.active = false;
  }
}
