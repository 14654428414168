import DirectoryExplorer, { ParentFolderInformation } from './DirectoryExplorer';
import { IDriveItem } from '../../services/Graph/SharepointInterfaces';
import ResourceLink from 'models/resourceLink';

interface ISharepointFilePicker {
  invalidAuthToken: () => void;
  onSelect: (files: IDriveItem[] | undefined) => void;
  parentFolder?: ParentFolderInformation;
  viewMode?: boolean;
  existingLinks?: ResourceLink[];
  linkToEdit?: ResourceLink;
}

const SharepointFilePicker = (props: ISharepointFilePicker) => {
  return (
    <DirectoryExplorer
      parentFolder={props.parentFolder}
      invalidAuthToken={props.invalidAuthToken}
      onSelectItems={(items?: IDriveItem[]) => {
        props.onSelect(items);
      }}
      viewMode={props.viewMode}
      existingLinks={props.existingLinks}
      linkToEdit={props.linkToEdit}
    />
  );
};

export default SharepointFilePicker;
