export class ObjectiveKPIDTO {
  kpiId: number;

  objectiveId: number;

  constructor(_objectiveId: number = -1, _kpiId: number = -1) {
    this.kpiId = _kpiId;
    this.objectiveId = _objectiveId;
  }
}
