export class CommentTrail {
  commentTrailId: number;

  entries: CommentTrailEntry[];

  constructor() {
    this.commentTrailId = 0;
    this.entries = [];
  }
}

export class CommentTrailEntry {
  commentTrailId: number;

  commentTrailEntryId: number;

  comment: string;

  created: Date;

  createdById: string;

  createdBy?: string;

  modified?: Date;

  modifiedById?: string;

  modifiedBy?: string;

  constructor() {
    this.commentTrailId = 0;
    this.commentTrailEntryId = 0;
    this.comment = '';
    this.created = new Date();
    this.createdById = '';
  }
}
