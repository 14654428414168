import ResourceLink from 'models/resourceLink';
import { LinkPreviewVirtual } from './LinkPreviewVirtual';
import { LinkPreviewSharePoint } from './LinkPreviewSharePoint';
import { ICommandBarItemProps, Stack, Text } from '@fluentui/react';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import Activity, { ActivityType } from 'models/activity';
import NewVersionNotification from '../NewVersionNotification';

export interface ILinkPreviewProps {
  link?: ResourceLink;
  confirmOpen?: boolean;
  enableLinks?: boolean;
  dontUseIFrame?: boolean;
  hideHelp?: boolean;
  hideId?: boolean;
  hideCommandbar?: boolean;
  extraCommandBarButtons?: ICommandBarItemProps[];
  title?: string;
  onClose?: () => void;
}

export const getPageHeaderDoc = (
  id: string,
  title: string,
  activity: Activity | undefined,
  useDarkMode: boolean,
): JSX.Element => {
  const showActivity = activity && activity.typeOfActivity === ActivityType.LibraryNewVersion;

  return (
    <Stack.Item
      className={useDarkMode ? 'sharePointPageTitleDark' : 'sharePointPageTitle'}
      styles={{ root: { paddingTop: showActivity ? 10 : 30, paddingBottom: 10 } }}
      key={id}
    >
      <Stack styles={{ root: { paddingLeft: 20 } }} tokens={globalStackTokensGapSmall}>
        {showActivity && <NewVersionNotification />}
        <Stack.Item>
          <Text variant="large">
            {title}
          </Text>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};

export const getPageHeaderPage = (
  id: string,
  title: string,
  activity: Activity | undefined,
  useDarkMode: boolean,
): JSX.Element => {
  const showActivity = activity && activity.typeOfActivity === ActivityType.LibraryNewVersion;

  return (
    <Stack.Item
      className={useDarkMode ? 'sharePointPageTitleDark' : 'sharePointPageTitle'}
      styles={{ root: { paddingTop: showActivity ? 20 : 60, paddingBottom: 20 } }}
      key={id}
    >
      <Stack styles={{ root: { paddingLeft: 20 } }} tokens={globalStackTokensGapMedium}>
        {showActivity && <NewVersionNotification />}
        <Stack.Item>
          <Text variant="xxLarge">
            {title}
          </Text>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};

export const LinkPreview = (props: ILinkPreviewProps) => {
  return (
    <Stack verticalFill>
      {props.title && (
        <Stack.Item styles={{ root: { paddingBottom: 20, paddingLeft: 20 } }}>
          <Text block variant="large" styles={globalTextStylesBold}>
            {props.title}
          </Text>
        </Stack.Item>
      )}
      {props.link?.list.isVirtual ? <LinkPreviewVirtual {...props} /> : <LinkPreviewSharePoint {...props} />}
    </Stack>
  );
};
