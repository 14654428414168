import { DashboardTypes } from 'models/dashboard';
import LanguageDTO from './languageDTO';
import WidgetDTO from './widgetDTO';

export default class DashboardDTO {
  dashboardId: number;

  name: string;

  dashboardType: DashboardTypes;

  created?: string;

  createdById?: string;

  sortOrder: number;

  url?: string;

  users?: DashboardUserDTO[];

  groups?: DashboardGroupDTO[];

  widgets?: WidgetDTO[];

  trans: Dashboard_TranslationDTO[];

  constructor() {
    this.dashboardId = -1;
    this.sortOrder = -1;
    this.name = '';
    this.dashboardType = DashboardTypes.user;
    this.trans = [];
  }
}

export class Dashboard_TranslationDTO {
  dashboardId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.dashboardId = -1;
    this.languageId = -1;
    this.name = '';
  }
}

export class DashboardUserDTO {
  userId: string;

  constructor(id: string) {
    this.userId = id;
  }
}

export class DashboardGroupDTO {
  groupId: string;

  constructor(id: string) {
    this.groupId = id;
  }
}
