import LanguageDTO from './languageDTO';
export default class Risk_TranslationDTO {
  riskId: number;

  languageId: number;

  name: string;

  asset?: string;

  description?: string;

  analysis?: string;

  strategy?: string;

  lang?: LanguageDTO;

  constructor() {
    this.riskId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
