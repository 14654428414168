export default class Partner {
  tenantId: string;

  tenantName: string;

  userId: string;

  name: string;

  email: string;

  constructor() {
    this.tenantId = '';
    this.userId = '';
    this.tenantName = '';
    this.name = '';
    this.email = '';
  }

  clone() {
    const newPartner = new Partner();
    newPartner.tenantId = this.tenantId;
    newPartner.tenantName = this.tenantName;
    newPartner.userId = this.userId;
    newPartner.name = this.name;
    newPartner.email = this.email;

    return newPartner;
  }
}
