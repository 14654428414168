import { ActionButton, IconButton, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { NavigateForwardIcon, CircleRingIcon } from './CheckListStyles';

interface ICheckListFollowUpProps {
  followUp: boolean;
  toggle: () => void;
}

export const CheckListFollowUp = (props: ICheckListFollowUpProps) => {
  const { t } = useTranslation(['task', 'translation']);

  return (
    <Stack horizontal verticalAlign="center">
      <IconButton
        iconProps={props.followUp ? NavigateForwardIcon : CircleRingIcon}
        onClick={() => {
          props.toggle();
        }}
      ></IconButton>
      <ActionButton
        onClick={() => {
          props.toggle();
        }}
      >
        {t('task:CheckList.FollowUp')}
      </ActionButton>
    </Stack>
  );
};
