import LanguageDTO from '../languageDTO';

export class Asset_TranslationDTO {
  assetId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.assetId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
