export default class GroupRoleDTO {
  groupId: string;

  roleId: string;

  constructor() {
    this.groupId = '';
    this.roleId = '';
  }
}
