import http from './httpService';
import AppError from 'utils/appError';
import Process from 'models/process/process';
import { ProcessDTO } from 'models/dto/Process/processDTO';
import { mapFromProcess, mapFromProcessContext, mapFromProcesses, mapToProcess } from 'mappings/processMapping';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { ProcessControlDTO } from 'models/dto/Process/processControlDTO';
import { ProcessThemeDTO } from 'models/dto/Process/processThemeDTO';
import { ProcessObjectiveDTO } from 'models/dto/Process/processObjectiveDTO';
import { ProcessContextDTO } from 'models/dto/Process/processContextDTO';
import { ProcessContext } from 'models/process/processContext';
import { mapFromDashboard } from 'mappings/dashboardMapping';
import Dashboard from 'models/dashboard';
import DashboardDTO from 'models/dto/dashboardDTO';

const base = 'processes';

export const apiGetProcesses = async (accessToken: string, globalCache: GlobalDataCache): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiUpdateProcess = async (
  editProcess: Process,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process> => {
  try {
    const processDTO = mapToProcess(editProcess);

    const ar = await http.put<ProcessDTO>(`/${base}`, processDTO, http.getRequestConfig(accessToken));

    return mapFromProcess(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiAddProcess = async (
  addProcess: Process,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process> => {
  try {
    const processDTO = mapToProcess(addProcess);

    const ar = await http.post<ProcessDTO>(`/${base}`, processDTO, http.getRequestConfig(accessToken));

    return mapFromProcess(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcess = async (
  processId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process | undefined> => {
  try {
    const ar = await http.get<ProcessDTO>(`/${base}/${processId}`, http.getRequestConfig(accessToken));
    if (!ar.data) return undefined;
    
    return mapFromProcess(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetChildProcesses = async (
  processId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/${processId}/children`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessScene = async (accessToken: string, globalCache: GlobalDataCache) => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/scene`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiRemoveProcess = async (processId: number, accessToken: string): Promise<boolean> => {
  try {
    await http.post(`/${base}/delete/${processId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessesForRisk = async (
  riskId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/risk/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessContext = async (
  processId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<ProcessContext> => {
  try {
    const ar = await http.get<ProcessContextDTO>(`/${base}/context/${processId}`, http.getRequestConfig(accessToken));

    return mapFromProcessContext(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessesForTheme = async (
  themeId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/theme/${themeId}`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessesForControl = async (
  controlId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/control/${controlId}`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetProcessesForTask = async (
  taskId: number,
  accessToken: string,
  globalCache: GlobalDataCache,
): Promise<Process[]> => {
  try {
    const ar = await http.get<ProcessDTO[]>(`/${base}/task/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromProcesses(ar.data, globalCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

//Control
export const apiRemoveControlFromProcess = async (
  controlId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(
    false,
    new ProcessControlDTO(processId, controlId),
    accessToken,
    'control',
  );
};

export const apiAddControlToProcess = async (
  controlId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(true, new ProcessControlDTO(processId, controlId), accessToken, 'control');
};

//Theme
export const apiRemoveThemeFromProcess = async (
  themeId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(false, new ProcessThemeDTO(processId, themeId), accessToken, 'theme');
};

export const apiAddThemeToProcess = async (
  themeId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(true, new ProcessThemeDTO(processId, themeId), accessToken, 'theme');
};

//Objective
export const apiRemoveObjectiveFromProcess = async (
  objectiveId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(
    false,
    new ProcessObjectiveDTO(processId, objectiveId),
    accessToken,
    'objective',
  );
};

export const apiAddObjectiveToProcess = async (
  objectiveId: number,
  processId: number,
  accessToken: string,
): Promise<boolean> => {
  return await apiAddRemoveEntiryFromProcess(
    true,
    new ProcessObjectiveDTO(processId, objectiveId),
    accessToken,
    'objective',
  );
};

async function apiAddRemoveEntiryFromProcess<TItem>(
  add: boolean,
  data: TItem,
  accessToken: string,
  type: 'theme' | 'objective' | 'control',
): Promise<boolean> {
  try {
    await http.post(`/${base}/${type}?add=${add}`, data, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetProcessCode(accessToken: string, code: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/${base}/code`, { valueString: code }, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddProcessToObjective(
  processIdFrom: number,
  objectiveIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessObjectiveDTO(processIdFrom, objectiveIdTo);

    await http.post<ProcessObjectiveDTO>(`/processes/objective?add=${true}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveProcessFromObjective(
  processIdFrom: number,
  objectiveIdTo: number,
  accessToken: string,
): Promise<boolean> {
  try {
    const body = new ProcessObjectiveDTO(processIdFrom, objectiveIdTo);

    await http.post<ProcessObjectiveDTO>(`/processes/objective?add=${false}`, body, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateProcessDashboard(
  processId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard> {
  try {
    const ar = await http.post<DashboardDTO>(
      `/processes/${processId}/dashboard`,
      null,
      http.getRequestConfig(accessToken),
    );

    return mapFromDashboard(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
