import { Stack, Separator, Checkbox, IColumn } from '@fluentui/react';
import { globalStackTokensGapMedium } from 'globalStyles';
import GenericModal from './GenericModal';
import { useContext, useEffect, useState } from 'react';
import { LocalStorageKeys } from 'utils/localstorage';
import { loadColumnsFromLocalStorage, saveColumnsToLocalStorage } from 'utils/columns';
import { useTranslation } from 'react-i18next';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import AppContext from 'App/AppContext';

interface ISelectColumnsDialogProps {
  columns: IColumn[];
  isOpen: boolean;
  cacheKey: LocalStorageKeys;
  onClose: () => void;
  onSave: () => void;
}

const SelectColumnsDialog = (props: ISelectColumnsDialogProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [selectedColumns, setSelectedColumns] = useState<boolean[]>(Array.from(props.columns.map((c) => true)));

  useEffect(() => {
    loadFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const loadFromLocalStorage = () => {
    const keys = props.columns.map((c) => c.key);
    const columns = loadColumnsFromLocalStorage(appContext, props.cacheKey, keys);
    const newSelectedColumns = [...selectedColumns];
    columns.forEach((col) => {
      const idx = keys.findIndex((c) => c === col);
      if (idx >= 0) {
        newSelectedColumns[idx] = false;
      }
    });
    setSelectedColumns(newSelectedColumns);
  };

  const saveToLocalStorage = () => {
    const keys = props.columns.map((c) => c.key);
    const columns = keys.filter((val, index) => selectedColumns[index] === false);
    saveColumnsToLocalStorage(appContext, props.cacheKey, columns);
  };

  const save = () => {
    saveToLocalStorage();
    props.onSave();
  };

  //
  // Main render
  //
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      saveDisabled={!selectedColumns.some((s) => s === true)}
      title={t('translation:General.ColumnPicker.Title')}
      subTitle={t('translation:General.ColumnPicker.SubTitle')}
      onSave={save}
      width={300}
      height={'80vh'}
    >
      <Separator />
      <Stack verticalFill>
        <ScrollableStackItem>
          <Stack verticalFill tokens={globalStackTokensGapMedium}>
            {props.columns.map((col, idx) => {
              return (
                <Checkbox
                  key={col.key}
                  label={col.name}
                  checked={selectedColumns[idx] === true}
                  onChange={(ev, checked) => {
                    const newSelectedColumns = [...selectedColumns];
                    newSelectedColumns[idx] = checked ?? false;
                    setSelectedColumns(newSelectedColumns);
                  }}
                />
              );
            })}
          </Stack>
        </ScrollableStackItem>
      </Stack>
    </GenericModal>
  );
};

export default SelectColumnsDialog;
