import { IAppContext } from 'App/AppContext';
import AppError from 'utils/appError';
import User from './user';
import { Role } from './auth/role';
import { sortOnString } from 'utils/sorting';

export interface IOwner {
  ownerId?: string | undefined;

  ownerRoleId?: string | undefined;
}

export class Owner implements IOwner {
  ownerId: string | undefined;

  ownerRoleId: string | undefined;

  appContext?: IAppContext;

  constructor(item?: IOwner | undefined, appContext?: IAppContext) {
    if (item) this.setItem(item);
    this.appContext = appContext;
  }

  public get id(): string | undefined {
    return this.ownerId ?? this.ownerRoleId;
  }

  public setItem(item: IOwner | undefined) {
    this.ownerId = item?.ownerId;
    this.ownerRoleId = item?.ownerRoleId;
  }

  public fromItem(item: User | Role | undefined) {
    if (!item) {
      this.ownerId = undefined;
      this.ownerRoleId = undefined;
    } else if (item instanceof User) {
      this.ownerId = item?.id;
      this.ownerRoleId = undefined;
    } else if (item instanceof Role) {
      this.ownerId = undefined;
      this.ownerRoleId = item.roleId;
    }
  }

  public fromName(name: string | undefined): string | undefined {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }

    if (!name) {
      this.ownerId = undefined;
      this.ownerRoleId = undefined;

      return undefined;
    } else {
      const nameLower = name.toLowerCase();
      const user = this.appContext.globalDataCache.users.items.find(
        (u) => u.hasLicense && u.name.toLowerCase() === nameLower,
      );
      if (user) {
        this.ownerId = user.id;
        this.ownerRoleId = undefined;

        return this.ownerId;
      }
      const role = this.appContext.globalDataCache.roles.items.find((u) => u.name.toLowerCase() === nameLower);
      if (role) {
        this.ownerId = undefined;
        this.ownerRoleId = role.roleId;

        return this.ownerRoleId;
      }

      return undefined;
    }
  }

  public static applyOwner(item: IOwner, newOwner: IOwner | undefined) {
    item.ownerId = newOwner?.ownerId;
    item.ownerRoleId = newOwner?.ownerRoleId;
  }

  public static applyItem(item: IOwner, newOwner: User | Role | undefined) {
    if (newOwner instanceof User) {
      item.ownerId = newOwner?.id;
      item.ownerRoleId = undefined;
    } else if (newOwner instanceof Role) {
      item.ownerRoleId = newOwner?.roleId;
      item.ownerId = undefined;
    } else {
      item.ownerRoleId = undefined;
      item.ownerId = undefined;
    }
  }

  public static sort(a: IOwner | undefined, b: IOwner | undefined, appContext: IAppContext): number {
    return sortOnString(new Owner(a, appContext).name, new Owner(b, appContext).name);
  }

  public setId(id: string | undefined) {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }

    if (!id) {
      this.ownerId = undefined;
      this.ownerRoleId = undefined;
    } else if (this.appContext.globalDataCache.users.has(id)) {
      this.ownerId = id;
      this.ownerRoleId = undefined;
    } else if (this.appContext.globalDataCache.roles.has(id)) {
      this.ownerRoleId = id;
      this.ownerId = undefined;
    }
  }

  public get item(): User | Role | undefined {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }
    if (this.ownerId) return this.appContext.globalDataCache.users.get(this.ownerId);
    if (this.ownerRoleId) return this.appContext.globalDataCache.roles.get(this.ownerRoleId);

    return undefined;
  }

  public get itemAsArray(): (User | Role)[] {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }
    if (this.ownerId) return [this.appContext.globalDataCache.users.get(this.ownerId)];
    if (this.ownerRoleId) return [this.appContext.globalDataCache.roles.get(this.ownerRoleId)];

    return [];
  }

  public get name(): string | undefined {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }
    if (this.ownerId) return this.appContext.globalDataCache.users.get(this.ownerId).name;
    if (this.ownerRoleId) return this.appContext.globalDataCache.roles.get(this.ownerRoleId).name;

    return undefined;
  }

  public get email(): string | undefined {
    if (!this.appContext) {
      throw new AppError('AppContext of Owner not initialized');
    }
    if (this.ownerId) return this.appContext.globalDataCache.users.get(this.ownerId).email;

    return undefined;
  }
}
