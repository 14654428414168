import AdminTenant, { StoreMgmtStates } from 'models/adminTenant';
import AdminTenantDTO from 'models/dto/adminTenantDTO';
import {
  fromApiDateTimeOptional,
  fromApiDateTimeRequired,
  toApiDateTimeOptional,
  toApiDateTimeRequired,
} from 'utils/datetime';
import { mapFromPackage } from './packageMapping';
import { mapFromOnboardingState, mapToOnboardingState } from './tenantMapping';
import { mapFromNorms } from './normMapping';

export function mapFromAdminTenants(tenantDTOs: AdminTenantDTO[]): AdminTenant[] {
  return tenantDTOs.map((t) => mapFromAdminTenant(t));
}

export function mapFromAdminTenant(tenantDTO: AdminTenantDTO): AdminTenant {
  const newTenant = new AdminTenant();
  newTenant.tenantId = tenantDTO.tenantId;
  newTenant.parentId = tenantDTO.parentId;
  newTenant.topLevelParentId = tenantDTO.topLevelParentId;
  newTenant.created = fromApiDateTimeRequired(tenantDTO.created);
  newTenant.name = tenantDTO.name;
  newTenant.enableEventSubscriptions = tenantDTO.enableEventSubscriptions;
  newTenant.suspended = tenantDTO.suspended;
  newTenant.licenses = tenantDTO.licenses;
  newTenant.mgmtLicenses = tenantDTO.mgmtLicenses;
  newTenant.trialStart = fromApiDateTimeOptional(tenantDTO.trialStart);
  newTenant.storeMgmtState = tenantDTO.storeMgmtState || StoreMgmtStates.Customer;
  newTenant.onboardingState = mapFromOnboardingState(tenantDTO.onboardingState);
  newTenant.package = mapFromPackage(tenantDTO.package);
  newTenant.usedLicenses = tenantDTO.usedLicenses;
  newTenant.usedMgmtLicenses = tenantDTO.usedMgmtLicenses;
  newTenant.subscriptionType = tenantDTO.subscriptionType;
  newTenant.azureTenantId = tenantDTO.azureTenantId;
  newTenant.leadSource = tenantDTO.leadSource;
  newTenant.partnerId = tenantDTO.partnerId;
  newTenant.norms = mapFromNorms(tenantDTO.norms ?? []);
  newTenant.subscriptionEnd = fromApiDateTimeOptional(tenantDTO.subscriptionEnd);
  newTenant.stripeCustomerId = tenantDTO.stripeCustomerId;

  return newTenant;
}

export function mapToAdminTenant(tenant: AdminTenant): AdminTenantDTO {
  const newTenantDto = new AdminTenantDTO();
  newTenantDto.tenantId = tenant.tenantId;
  newTenantDto.created = toApiDateTimeRequired(tenant.created);
  newTenantDto.name = tenant.name;
  newTenantDto.enableEventSubscriptions = tenant.enableEventSubscriptions;
  newTenantDto.suspended = tenant.suspended;
  newTenantDto.licenses = tenant.licenses;
  newTenantDto.mgmtLicenses = tenant.mgmtLicenses;
  newTenantDto.trialStart = toApiDateTimeOptional(tenant.trialStart);
  newTenantDto.storeMgmtState = tenant.storeMgmtState;
  newTenantDto.onboardingState = mapToOnboardingState(tenant.onboardingState);
  newTenantDto.subscriptionType = tenant.subscriptionType;
  newTenantDto.azureTenantId = tenant.azureTenantId;
  newTenantDto.leadSource = tenant.leadSource;
  newTenantDto.partnerId = tenant.partnerId;

  return newTenantDto;
}
