export class ProcessObjectiveDTO {
  processId: number;

  objectiveId: number;

  constructor(processId?: number, objectiveId?: number) {
    this.processId = processId ?? -1;
    this.objectiveId = objectiveId ?? -1;
  }
}
