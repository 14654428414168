import { newGuidNil } from "utils/guid";
import LanguageDTO from "../languageDTO";

export class Role_TranslationDTO {
  roleId: string;

  languageId: number;

  name: string;

  description?: string;

  lang?: LanguageDTO;

  constructor() {
    this.roleId = newGuidNil();
    this.languageId = -1;
    this.name = '';
  }
}
