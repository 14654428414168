import { useContext, useEffect, useState } from 'react';
import {
  DetailsList,
  Text,
  IColumn,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  DetailsListLayoutMode,
  Link,
  FontIcon,
  Persona,
  PersonaSize,
} from '@fluentui/react';
import {
  globalStackItemStylesPaddingSceneScroll,
  globalStackStylesHeight100,
  globalStackTokensGapSmall,
  globalTextStylesBold,
} from 'globalStyles';
import { onRenderDetailsHeaderGlobal } from 'globalFunctions';
import { apiRequest } from 'services/Auth/authConfig';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Task from 'models/tasks/task';
import { apiGetTaskInstancesByCount } from 'services/Api/taskService';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import './TaskInstanceTabRowStyle.css';
import SingleTask from './SingleTask';

interface ITaskInstanceTabProps {
  taskId: number;
  activeTaskId: number | undefined;
  windowLevel?: number;
  navigateExternal?: boolean;
}

export const TaskInstanceTab = (props: ITaskInstanceTabProps) => {
  const { t } = useTranslation(['translation', 'task']);
  const appContext = useContext(AppContext);
  const [instances, setInstances] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTask, setShowTask] = useState<ITaskStartDateTimeProps | undefined>(undefined);

  interface ITaskStartDateTimeProps {
    startDateTime: Date;
    taskId: number;
    task: Task;
  }

  const TaskStartDateTime = (DateTimeProps: ITaskStartDateTimeProps) => {
    return (
      <Stack horizontal>
        {DateTimeProps.taskId === props.activeTaskId && <span className="play-button"></span>}
        <Persona size={PersonaSize.size24} text={DateTimeProps.task.user?.name} hidePersonaDetails={true} />
        <Stack.Item styles={{ root: { paddingLeft: 5 } }}>
          <Text styles={DateTimeProps.taskId === props.activeTaskId ? globalTextStylesBold : undefined}>
            <Link
              disabled={DateTimeProps.taskId === props.activeTaskId}
              onClick={() => {
                setShowTask(DateTimeProps);
              }}
            >
              {toLocaleDateTimeMedium(DateTimeProps.startDateTime)}
            </Link>
          </Text>
        </Stack.Item>
        {DateTimeProps.task.eventId && (
          <Stack.Item styles={{ root: { paddingLeft: 5 } }}>
              <FontIcon
                iconName="Calendar"
                style={appContext.useDarkMode ? { color: 'lightblue' } : { color: 'blue' }}
              />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.taskId]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetTaskInstancesByCount(props.taskId, 365, accessToken, appContext.globalDataCache);
      setInstances(tasks);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'date',
      name: t('task:Start.Label'),
      isResizable: true,
      minWidth: 150,
      maxWidth: 250,
      onRender: (item?: Task, index?: number, column?: IColumn) => {
        if (!item) return;

        return <TaskStartDateTime startDateTime={item.startDateTime} taskId={item.taskId} task={item} />;
      },
    },
    {
      key: 'state',
      name: t('task:Status.Label'),
      isResizable: true,
      minWidth: 100,
      maxWidth: 250,
      onRender: (item?: Task, index?: number, column?: IColumn) => {
        if (!item) return;

        const state = item.taskStates?.find((s) => s.taskStateId === item.taskStateId);
        if (!state) return;

        return (
          <Text styles={item.taskId === props.activeTaskId ? globalTextStylesBold : undefined}>{state.state}</Text>
        );
      },
    },
    {
      key: 'completed',
      name: t('task:Completed.Label'),
      isResizable: true,
      isCollapsible: true,
      minWidth: 120,
      maxWidth: 250,
      onRender: (item?: Task, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Text styles={item.taskId === props.activeTaskId ? globalTextStylesBold : undefined}>
            {toLocaleDateTimeMedium(item.completed)}
          </Text>
        );
      },
    },
    {
      key: 'score',
      name: t('task:Score.Label'),
      isResizable: true,
      isCollapsible: true,
      minWidth: 50,
      maxWidth: 150,
      onRender: (item?: Task, index?: number, column?: IColumn) => {
        if (!item) return;

        return (
          <Text styles={item.taskId === props.activeTaskId ? globalTextStylesBold : undefined}>
            {item.getScore().toString()}
          </Text>
        );
      },
    },
  ];

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) await loadData();
  };

  const removeTask = async (task: Task) => {
    await loadData();
  };

  const updateTask = async (task: Task) => {};

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  } else if (!props.taskId) {
    return <p />;
  }

  return (
    <Stack verticalFill styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            items={instances}
            columns={columns}
            compact
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderDetailsHeader={onRenderDetailsHeaderGlobal}
          />
        </ScrollablePane>
      </Stack.Item>
      {showTask && (
        <SingleTask
          task={showTask.task}
          isOpen={showTask !== undefined}
          close={() => {
            setShowTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
          windowLevel={(props.windowLevel ?? 0) + 1}
          navigateExternal={props.navigateExternal}
        />
      )}
    </Stack>
  );
};

export default TaskInstanceTab;
