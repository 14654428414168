import { StringValue_TranslationDTO } from './stringValue_TranslationDTO';

export default class StringValueDTO {
  stringValueId: number;

  stringValueKey?: number;

  stringTypeId: number;

  stringTypeEntityId?: number;

  sortOrder?: number;

  trans: StringValue_TranslationDTO[];

  constructor() {
    this.stringValueId = 0;
    this.stringTypeId = 0;
    this.trans = [];
  }
}
