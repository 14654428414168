export default class NormControlDTO {
  normId: number;

  controlId: number;

  constructor(normId?: number, controlId?: number) {
    this.normId = normId || 0;
    this.controlId = controlId || 0;
  }
}
