export enum AuditTrailActions {
  Record_created = 1,
  Record_updated = 2,
  Record_deleted = 3,
  Control_moved = 4,
  Control_recurring_pattern_updated = 5,
  Control_state_updated = 6,
  Control_code_updated = 7,
  Control_description_updated = 8,
  Control_applicabilityreason_updated = 9,
  Control_name_updated = 10,
  Control_outofscopereason_updated = 11,
  Task_name_updated = 12,
  Task_description_updated = 13,
  Task_start_updated = 14,
  Task_checklist_updated = 15,
  Task_followup_updated = 16,
  Control_group_updated = 17,
  Task_duration_updated = 18,
  Task_deadline_updated = 19,
  Task_end_updated = 20,
  Task_event_created = 21,
  Task_recurring_pattern_updated = 22,
  Risk_state_updated = 23,
  Risk_group_updated = 24,
  Risk_chance_start_updated = 25,
  Risk_code_updated = 26,
  Risk_owner_updated = 27,
  Risk_chance_current_updated = 28,
  Risk_chance_goal_updated = 29,
  Risk_control_link_added = 30,
  Risk_control_link_removed = 31,
  Risk_tag_added = 32,
  Risk_tag_removed = 33,
  Task_state_updated = 34,
  Task_assignee_updated = 35,
  User_added = 36,
  User_removed = 37,
  User_license_assigned = 38,
  User_license_removed = 39,
  Risk_impact_start_updated = 40,
  Risk_impact_current_updated = 41,
  Risk_impact_goal_updated = 42,
  Risk_strategy_updated = 43,
  Risk_method_updated = 44,
  Risk_feature1_updated = 45,
  Risk_feature2_updated = 46,
  Risk_feature3_updated = 47,
  Risk_feature4_updated = 48,
  Risk_feature5_updated = 49,
  Risk_feature6_updated = 50,
  Risk_name_updated = 51,
  Risk_description_updated = 52,
  Risk_analysis_updated = 53,
  Risk_link_added = 54,
  Risk_link_removed = 55,
  Risk_matrix_updated = 56,
  Task_event_removed = 57,
  Task_outlook_reschedule = 58,
  Task_outlook_removed = 59,
  Control_implementation_updated = 60,
  Control_owner_updated = 61,
  Objective_moved = 62,
  Objective_state_updated = 63,
  Objective_code_updated = 64,
  Objective_description_updated = 65,
  Objective_name_updated = 66,
  Objective_group_updated = 67,
  Objective_owner_updated = 68,
  Process_moved = 69,
  Process_state_updated = 70,
  Process_code_updated = 71,
  Process_description_updated = 72,
  Process_name_updated = 73,
  Process_group_updated = 74,
  Process_owner_updated = 75,
  Control_background_updated = 76,
  Risk_asset_updated = 77,
  Task_completed_updated = 78,
  Package_version_updated = 79,
  Package_code_updated = 80,
  Package_price_updated = 81,
  Package_state_updated = 82,
  Package_submitted = 83,
  Package_content_upload_finished = 84,
  Package_activated = 85,
  Subscription_seats_updated = 86,
  Subscription_type_updated = 87,
  Organizational_unit_added = 88,
  Organizational_unit_updated = 89,
  Organizational_unit_removed = 90,
  Asset_state_updated = 91,
  Asset_owner_updated = 92,
  Asset_name_updated = 93,
  Asset_description_updated = 94,
  Asset_risk_added = 95,
  Asset_risk_removed = 96,
  Asset_code_updated = 97,
  Asset_unique_id_updated = 98,
  Asset_container_added = 99,
  Asset_container_removed = 100,
  Asset_classification_added = 101,
  Asset_classification_removed = 102,
  Asset_lifecyclestart_updated = 103,
  Asset_lifecycleend_updated = 104,
  Asset_primary_container_updated = 105,
  KPI_name_updated = 106,
  KPI_description_updated = 107,
  KPI_expectedresult_updated = 108,
  KPI_instruction_updated = 109,
  KPI_defvalue_updated = 110,
  KPI_type_updated = 111,
  KPI_minvalue_updated = 112,
  KPI_maxvalue_updated = 113,
  KPI_decimalcount_updated = 114,
  KPI_required_updated = 115,
  KPI_choices_updated = 116,
  KPI_aggregationmethod_updated = 117,
  KPI_commentmode_updated = 118,
  Task_approved_updated = 119,
  Risk_classification_added = 120,
  Risk_classification_removed = 121,
  AuthSchema_name_updated = 122,
  AuthSchema_line_added = 123,
  AuthSchema_line_updated = 124,
  AuthSchema_line_removed = 125,
  RolePermission_updated = 126,
  Asset_auth_updated = 127,
  KPI_auth_updated = 128,
  Objective_auth_updated = 129,
  Process_auth_updated = 130,
  Risk_auth_updated = 131,
  Task_auth_updated = 132,
}
