import Control from '../control';
import Risk from '../risk';
import Theme from '../theme';
import Objective from './objective';

export default class ObjectiveContext {
  parent: Objective | undefined;

  children: Objective[];

  risks: Risk[];

  controls: Control[];

  themes: Theme[];

  constructor() {
    this.risks = [];
    this.controls = [];
    this.themes = [];
    this.children = [];
  }
}
