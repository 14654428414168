import { FunctionComponent } from 'react';
import {
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Spinner,
  SpinnerSize,
  List,
  Persona,
  PersonaSize,
  IPersonaStyles,
} from '@fluentui/react';
import React from 'react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { sortOnDate } from 'utils/sorting';
import Activity, { ActivityType } from 'models/activity';
import { globalStackItemStylesPaddingSceneScroll } from 'globalStyles';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { toNameInitial } from 'utils/string';
import { useTranslation } from 'react-i18next';
import Task from 'models/tasks/task';
import SingleTask from 'components/Tasks/SingleTask';
import { apiGetSingleTask } from 'services/Api/taskService';
import { EntityTypes } from 'models/entity';
import { useHistory } from 'react-router-dom';
import { IWidgetRendererProps } from '../WidgetRenderer';
import DateDividerListItem, {
  DataGroup,
  DateComparer,
  DateDivider,
  insertDividersAndGroupsInList,
} from 'components/Utils/ListItemDateDividers';

interface IWidgetMyRecentItemsProps extends IWidgetRendererProps {}

const WidgetMyRecentItems: FunctionComponent<IWidgetMyRecentItemsProps> = (props: IWidgetMyRecentItemsProps) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const [recentItemsData, setRecentItemsData] = React.useState<Activity[] | undefined>(undefined);
  const [recentItemsDataDisplay, setRecentItemsDataDisplay] = React.useState<
    (Activity | DateDivider | DataGroup<Activity>)[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget, props.sharedData]);

  React.useEffect(() => {
    if (recentItemsData && recentItemsData?.length > 0) {
      const newData = insertDividersAndGroupsInList(recentItemsData, getCompareDate, undefined, undefined);
      setRecentItemsDataDisplay(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentItemsData]);

  const loadData = async (refresh?: boolean) => {
    try {
      if (props.onRefreshSharedData && props.widget.dashboard && refresh) {
        await props.onRefreshSharedData();

        return;
      }

      if (isLoading) return;
      setIsLoading(true);

      const activities = props.sharedData?.activities;

      setRecentItemsData(
        activities
          ?.filter((activity: Activity) =>
            [ActivityType.RecentlyChanged, ActivityType.RecentlyViewed].includes(activity.typeOfActivity),
          )
          .sort((a, b) => -1 * sortOnDate(a.created, b.created)),
      );
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompareDate: DateComparer<Activity> = (a) => {
    return a.created;
  };

  const getTaskDetail = async (taskId: number) => {
    if (!taskId) {
      return;
    }

    try {
      appContext.showContentLoader();
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(taskId, false, accessToken, appContext.globalDataCache);
      if (tasks && tasks.tasks && tasks.tasks.length > 0) {
        const task = tasks.tasks[0];
        setSelectedTask(task);
      } else {
        appContext.showNotification(t('dashboard:Errors.ItemHasBeenDeleted'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
    }
  };

  const onEntityClickHandler = (activity: Activity) => {
    switch (activity.entity.typeOfEntity) {
      case EntityTypes.Requirement:
        history.push({ pathname: `/theme/${activity.entity.entityId}` });
        break;
      case EntityTypes.Control:
        history.push({ pathname: `/control/${activity.entity.entityId}` });
        break;
      case EntityTypes.Task:
        getTaskDetail(activity.entity.entityId);
        break;
      case EntityTypes.Risk:
        history.push({ pathname: `/risk/${activity.entity.entityId}` });
        break;
      case EntityTypes.Objective:
        history.push({ pathname: `/objective/${activity.entity.entityId}` });
        break;
      case EntityTypes.Process:
        history.push({ pathname: `/process/${activity.entity.entityId}` });
        break;
      case EntityTypes.KPI:
        history.push({ pathname: `/kpi/${activity.entity.entityId}` });
        break;
      case EntityTypes.Asset:
        history.push({ pathname: `/asset/${activity.entity.entityId}` });
        break;
      default:
        break;
    }
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      await loadData(true);
    }
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
    await loadData(true);
  };

  const updateTask = async (task: Task) => {};

  return (
    <Stack verticalFill styles={{ root: { paddingTop: 20 } }}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <List
            items={recentItemsDataDisplay}
            onRenderCell={(activity) => {
              if (!activity) return null;

              if (activity instanceof DateDivider) {
                return <DateDividerListItem {...activity} />;
              }

              if (activity instanceof DataGroup) {
                return null;
              }

              return (
                <Persona
                  styles={personaStyle}
                  size={PersonaSize.size40}
                  text={activity.entity.entityName}
                  secondaryText={toLocaleDateTimeMedium(activity.created)}
                  imageInitials={toNameInitial(appContext.globalDataCache.users.get(activity.userId).name)}
                  onClick={() => onEntityClickHandler(activity)}
                />
              );
            }}
          />
        </ScrollablePane>
      </Stack.Item>
      {recentItemsData?.length === 0 && (
        <Stack.Item>
          <Text variant="medium">{t('dashboard:RecentItems.NoItems')}</Text>
        </Stack.Item>
      )}
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
    </Stack>
  );
};

export default WidgetMyRecentItems;
