import {
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
  ComboBox,
  ISelectableOption,
  Spinner,
  Text,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import GroupTag from 'components/Utils/GroupTag';
import { AuthSchema } from 'models/auth/authSchema';
import Group from 'models/group';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { isValidGuid } from 'utils/guid';
import { LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';

interface ITaskViewMemberFilterProps {
  isLoadingMembership?: boolean;
  filter: string[];
  onUpdateFilter: (filter: string[]) => void;
  onLoadMembership: () => void;
}

export const memberFilterSelectAllKey: string = 'selectAll';
export const memberFilterCompletedKey: string = 'showCompleted';
export const memberFilterGeneralOptionCount = 3; //for selectAll, current user and completed tasks

export const TaskViewMemberFilter = (props: ITaskViewMemberFilterProps) => {
  const { t } = useTranslation(['translation', 'tasks', 'adminGroups', 'adminAuth']);
  const appContext = useContext(AppContext);
  const currentUserId = appContext.user.id;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([currentUserId]);
  const [membership, setMembership] = useState<Group[]>([]);
  const [schemas] = useState<AuthSchema[]>(
    appContext.globalDataCache.authSchemas.getItemsForMember(appContext.user.id),
  );
  const [hasRBAC] = useState<boolean>(hasUserFeature(appContext, FeatureTypes.RBAC));

  //
  // Effects
  //
  useEffect(() => {
    const getKeysFromFilter = (filter: string[], newMembership: Group[]): string[] => {
      const keys = [...filter];

      if (keys.length === newMembership.length + schemas.length + memberFilterGeneralOptionCount - 1) {
        keys.push(memberFilterSelectAllKey);
      }

      return keys;
    };

    const newMembership = appContext.globalDataCache.groups.getCurrentUserGroups();
    setMembership(newMembership);
    const keys = getKeysFromFilter(props.filter, newMembership);
    setSelectedKeys(keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter, props.isLoadingMembership]);

  //
  // Group Member filter
  //
  const saveMemberFilterToStorage = (filter: string[]) => {
    setLocalStorageData(appContext, 
      LocalStorageKeys.MemberFilterTasks,
      filter.filter((s) => s !== memberFilterSelectAllKey).join(','),
    );
  };

  const getGroupMemberFilterOptions = (memberShipInfo: Group[], isLoadingMembership: boolean): IComboBoxOption[] => {
    const output: IComboBoxOption[] = [
      {
        key: memberFilterSelectAllKey,
        text: t('tasks:TabMyTasks.SelectAll'),
        itemType: SelectableOptionMenuItemType.SelectAll,
        data: undefined,
      },
      {
        key: 'general',
        itemType: SelectableOptionMenuItemType.Header,
        text: t('tasks:TabMyTasks.General'),
        data: undefined,
      },
      {
        key: currentUserId,
        text: t('tasks:TabMyTasks.TasksAssignedToMe'),
        disabled: isLoadingMembership,
        data: undefined,
      },
      {
        key: memberFilterCompletedKey, //this is a special key to show completed tasks
        text: t('tasks:TabMyTasks.ShowCompletedTasks'),
        disabled: isLoadingMembership,
        data: undefined,
      },
    ];

    if (hasRBAC) {
      if (schemas.length > 0) {
        output.push({
          key: 'sharedWith',
          itemType: SelectableOptionMenuItemType.Header,
          text: t('adminAuth:AuthSchemaPicker.Label'),
          data: undefined,
        });

        schemas.forEach((schema: AuthSchema) => {
          output.push({
            key: schema.authSchemaId.toString(),
            text: schema.name,
            data: schema,
          });
        });
      }
    }

    if (appContext.globalDataCache.groups.items.filter((g) => g.taskFilterEnabled).length > 0) {
      output.push({
        key: 'groupTasks',
        itemType: SelectableOptionMenuItemType.Header,
        text: t('tasks:TabMyTasks.GroupTasks'),
        data: undefined,
      });

      if (isLoadingMembership) {
        output.push({
          key: 'loading',
          itemType: SelectableOptionMenuItemType.Header,
          text: t('translation:General.Notifications.Loading'),
          data: undefined,
        });
      } else {
        if (memberShipInfo.length > 0) {
          memberShipInfo.forEach((group) => {
            output.push({
              key: group.id,
              text: group.name,
              disabled: isLoadingMembership,
              data: group,
            });
          });
        } else {
          output.push({
            key: 'nodata',
            itemType: SelectableOptionMenuItemType.Header,
            text: '',
            data: undefined,
          });
        }
      }
    }

    return output;
  };

  const onChangeGroupMembership = (event: FormEvent<IComboBox>, option?: IComboBoxOption): void => {
    if (!option) return;

    let newSelectedKeys: string[] = [];
    let newSelectedGroups: string[] = [];
    let selectedGroup: Group | undefined;

    if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
      const selectAllState =
        selectedKeys.length === membership.length + schemas.length + memberFilterGeneralOptionCount;

      if (!selectAllState) {
        newSelectedKeys.push(currentUserId);
        for (let group of membership) {
          newSelectedGroups.push(group.id);
        }
        for (let schema of schemas) {
          newSelectedGroups.push(schema.authSchemaId.toString());
        }
        newSelectedKeys.push(memberFilterSelectAllKey);
        newSelectedKeys.push(memberFilterCompletedKey);
      }
    } else {
      if (option.key === currentUserId) {
        if (option.selected) {
          newSelectedKeys = [currentUserId, ...selectedKeys];
        } else {
          if (selectedKeys.length > 1) {
            newSelectedKeys = selectedKeys.filter((s) => s !== currentUserId);
          } else {
            //cannot unselect current user when no other groups are selected
            //return without reloading
            return;
          }
        }
      } else if (option.key === memberFilterCompletedKey) {
        newSelectedKeys = option.selected
          ? [...selectedKeys, memberFilterCompletedKey]
          : selectedKeys.filter((s) => s !== memberFilterCompletedKey);
      } else {
        const id = option.key;
        if (!isNaN(Number(id))) {
          //shared with
          newSelectedGroups = option.selected
            ? [...selectedKeys, id.toString()]
            : selectedKeys.filter((_filter) => _filter !== id);
        } else {
          //group
          selectedGroup = membership.find((_group) => _group.id === option.key);
          if (!selectedGroup) return;

          newSelectedGroups = option.selected
            ? [...selectedKeys, selectedGroup.id]
            : selectedKeys.filter((_filter) => _filter !== selectedGroup?.id);
        }
      }
    }

    newSelectedKeys.push(...newSelectedGroups);

    //when all groups are unselected, add the current user
    if (getGroupCount(newSelectedKeys) === 0) {
      newSelectedKeys.push(currentUserId);
    }

    saveMemberFilterToStorage(newSelectedKeys);
    props.onUpdateFilter(newSelectedKeys);
  };

  const getGroupCount = (keys: string[]): number => {
    return keys.filter((key) => !isNaN(Number(key)) || isValidGuid(key)).length;
  };

  const renderMemberOption = (
    itemProps?: ISelectableOption,
    defaultRender?: (itemProps?: ISelectableOption) => JSX.Element | null,
  ): JSX.Element | null => {
    if (!defaultRender || !itemProps) return null;
    if (itemProps.data instanceof Group) {
      return <GroupTag group={itemProps.data} allowHoverCard={true} compact />;
    } else if (itemProps.key === 'loading') {
      return <Spinner />;
    } else if (itemProps.key === 'nodata') {
      return <Text>{t('tasks:TabMyTasks.NoGroup')}</Text>;
    } else {
      return defaultRender(itemProps);
    }
  };

  const getText = (): string => {
    let text = '';
    const keys = selectedKeys.filter((s) => s !== memberFilterSelectAllKey);
    let groupCount = keys.length;

    if (keys.includes(currentUserId)) {
      text = t('tasks:TabMyTasks.TasksAssignedToMe');
      groupCount--;
    }

    if (keys.includes(memberFilterCompletedKey)) {
      groupCount--;
    }

    if (groupCount > 0) {
      text += ' ' + t('translation:General.Words.And').toLowerCase() + ' ';
      text += t('tasks:TabMyTasks.MemberFilterText', { count: groupCount });
    }

    return text;
  };

  //
  // Main render
  //
  return (
    <ComboBox
      styles={{
        root: {
          minWidth: 270,
          maxWidth: 270,
          selectors: {
            '&:after': {
              borderLeft: 'none',
              borderRight: 'none',
              borderTop: 'none',
              borderBottom: '1px solid',
            },
          },
        },
        optionsContainer: {
          maxHeight: 600,
        },
      }}
      options={getGroupMemberFilterOptions(membership, props.isLoadingMembership ?? false)}
      multiSelect
      selectedKey={selectedKeys}
      onChange={onChangeGroupMembership}
      onMenuOpen={() => props.onLoadMembership()}
      onRenderOption={renderMemberOption}
      text={getText()}
      disabled={props.isLoadingMembership}
    />
  );
};
