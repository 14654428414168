export class KPIControlDTO {
  kpiId: number;

  controlId: number;

  constructor(kpiId?: number, controlId?: number) {
    this.kpiId = kpiId ?? -1;
    this.controlId = controlId ?? -1;
  }
}
