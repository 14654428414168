import { globalAppPortalUrl } from 'globalConstants';
import { TFunction } from 'i18next';
import Task from 'models/tasks/task';

export const adaptiveCardChatTaskComment = (task: Task, comment: string, t: TFunction): string => {
  return `{
    "type": "AdaptiveCard",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.3",
    "msTeams": {
        "width": "full"
    },
    "body": [
        {
            "type": "ColumnSet",
            "columns": [
                {
                    "type": "Column",
                    "width": "75px",
                    "items": [
                        {
                            "type": "TextBlock",
                            "wrap": true,
                            "id": "lblName",
                            "text": "${t('task:Name.Label')}:",
                            "weight": "Bolder"
                        }
                    ],
                    "id": "colName"
                },
                {
                    "type": "Column",
                    "width": "stretch",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "${task.name}",
                            "wrap": true,
                            "spacing": "Large",
                            "id": "lblNameValue"
                        }
                    ],
                    "id": "colNameValue"
                }
            ],
            "id": "CSName"
        },
        {
            "type": "ColumnSet",
            "columns": [
                {
                    "type": "Column",
                    "width": "75px",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "${t('commentTrail:Message')}:",
                            "wrap": true,
                            "id": "lblStatus",
                            "weight": "Bolder"
                        }
                    ],
                    "id": "colStatus",
                    "verticalContentAlignment": "Bottom",
                    "horizontalAlignment": "Left",
                    "spacing": "None"
                },
                {
                    "type": "Column",
                    "width": "stretch",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "${comment}",
                            "wrap": true,
                            "id": "lblStatusValue"
                        }
                    ],
                    "id": "colStatusValue"
                }
            ],
            "id": "CSStatue"
        },
        {
            "type": "ColumnSet",
            "columns": [
                {
                    "type": "Column",
                    "width": "75px",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "${t('task:Deadline.Label')}:",
                            "wrap": true,
                            "weight": "Bolder",
                            "id": "lblDeadline"
                        }
                    ],
                    "id": "colDeadline"
                },
                {
                    "type": "Column",
                    "width": "stretch",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "${task.deadline}",
                            "wrap": true,
                            "id": "lblDeadlineValue"
                        }
                    ],
                    "id": "colDeadlineValue"
                }
            ],
            "id": "CSDeadline"
        },
    ],
    "actions": [
        {
            "type": "Action.OpenUrl",
            "title": "View",
            "id": "actView",
            "url": "${globalAppPortalUrl}/tasks/${task.taskId}",
            "style": "positive"
        }
    ]
}`;
};
