import { ActivityType, ActivityPriority } from 'models/activity';
import EntityDTO from 'models/dto/entityDTO';

export default class ActivityDTO {
  activityId: number;

  entity: EntityDTO;

  typeOfActivity: ActivityType;

  created: string;

  refDateTime?: string;

  priority: ActivityPriority;

  userId: string;

  refUserId?: string;

  userEmail?: string;

  refUserEmail?: string;

  dismissed?: string;

  refData?: string;

  constructor() {
    this.activityId = 0;
    this.entity = new EntityDTO();
    this.typeOfActivity = ActivityType.NotSet;
    this.created = '';
    this.refDateTime = '';
    this.priority = ActivityPriority.Informational;
    this.userId = '';
  }
}
